import request from '@/router/axios';
const urlPrefix = 'ykyj';

/**
 * 获取当前登录人所负责客户的雇员列表
 * @param obj
 */
export function getEmpList(obj) {
  return request({
    url:urlPrefix+ '/empList',
    method: 'post',
    data: obj
  })
}

/**
 * 获取当前登录人所负责客户的客户列表
 * @param obj
 */
export function getCusList(obj) {
  return request({
    url:urlPrefix+ '/cusList',
    method: 'post',
    data: obj
  })
}

/**
 * 下载当前选择和玉预扣预缴雇员信息
 * @param obj
 */
export function downloadExcel(obj) {
  return request({
    url:urlPrefix+ '/download',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
