<template>
  <div>
    <div class="items" >
      <draggable
        tag="el-row"
        :gutter="40"
        :list="itemLists"
        class="itemLists"
        group="setItems"
        forceFallback="true"
        :options="{ghostClass:'ghostClass',fallbackClass:'fallbackClass'}"
        handle=".drag"
      >
      <el-col class="itemBox" v-for="emp in itemLists" :key="emp.id">
        <div class="itemTitle" >
          <p >{{emp.name}}/{{emp.enName}}</p>
        </div>
          <draggable
            tag="el-row"
            :gutter="40"
            :list="emp.itemList"
            class="itemLists"
            :group="'setItem'+emp.id"
            forceFallback="true"
            :options="{ghostClass:'ghostClass',fallbackClass:'fallbackClass'}"
          >
            <el-col :span="24"
                    class="itemList"
                    v-for="element in emp.payrollItemParam"
                    :key="element.id"
            >
              <div class="itemListBox">
                <p>{{element.name}}/{{element.enName}}</p>
                <p>{{element.result}}</p>
              </div>
            </el-col>
          </draggable>
      </el-col>
      </draggable>
    </div>
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  /*import {getDemoPayroll} from '@/api/demo/payroll'*/

  export default {
    name: "paySettingItem",
    components: { draggable},
    created(){
      this.getResult();
    },

    data() {
      return {
        show: true,
        dialogStatus: 'add', // 弹框状态
        dialogFormVisible: false,
        listForm:{},
        addWageItemIndex:0,
        addWageIndex:0,
        dialogheaderVisible: false,
        dialogItemVisible: false,
        deleteList:[],
        itemLists:[],
        dynamicTags: [
        ],
        param:{
          salaryDetailId:'',
          type:'',
        },
        chooseLists: [
        ]
      };
    },
    methods: {
      getResult(){
        getDemoPayroll(this.param).then(res => {
          console.log(res)
          this.itemLists = res.data.payrollSets;


        })
      },
    }
  };
</script>

<style scoped  lang='scss'>
  .itemBox {
    padding: 0 10px;
  }

  .itemLists {
    margin-top: 20px;
  }

  .itemList {
    margin-bottom: 20px;
    padding: 0 20px;
    cursor: move;
  }

  .itemListBox {
    background-color: #f3f3f3;
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    padding: 0 20px;
    border-radius: 2px;
  }

  .itemList p {
    color: #666666;
    font-size: 12px;
  }

  .itemTitle p.drag {
    flex: 1;
    cursor: move;
  }

  .itemList p + p {
    color: #000000;
    font-size: 14px;
  }

  .itemTitle {
    background: #FFEDBE;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
  }

  .itemTitle p {
    color: #000000;
    font-size: 14px;
    line-height: 30px;
  }

  .itemTitle p + p {
    color: #AA5401;
    font-size: 12px;
    cursor: pointer;
  }

  .itemTitle p .icon {
    margin-right: 5px;
  }

</style>
