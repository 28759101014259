<template>
    <div>
         <div class="demo-input-suffix" >
            <el-form class="ruleForm" v-if="this.detailVisible=='false'" label-width="80px">
              <el-row :gutter="10">
                <el-col :span="5">
                  <el-form-item label="客户名称:">
                    <el-select v-model="cusname" placeholder="请选择" clearable filterable>
                      <el-option
                        v-for="item in cusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="人员名称:">
                    <el-input v-model="username" placeholder="请输入内容" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="证件号码:">
                    <el-input v-model="idcard" placeholder="请输入内容" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="税金所属月份:" label-width="120px">
                    <el-date-picker
                      value-format="yyyy-MM"
                      v-model="months"
                      type="monthrange"
                      range-separator="至"
                      start-placeholder="开始月份"
                      end-placeholder="结束月份">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item label="计税类型:">
                    <el-select
                      clearable
                      filterable
                      v-model="checkedTaxtypes"
                      multiple
                      collapse-tags
                      placeholder="请选择">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <!-- <div style="margin:30px 0;width:100%;height:100px"  class="el-upload-dragger"  >
                  <el-checkbox :indeterminate="isIndeterminate" style="margin-left:-1200px" v-model="checkAll" @change="handleCheckAllChange">计税类型</el-checkbox>

                    <el-checkbox-group style="margin: 15px 0;" v-model="checkedTaxtypes" @change="handleCheckedTaxtypesChange">
                    <el-checkbox v-for="city in taxtypes" :label="city" :key="city">{{city}}</el-checkbox>
                </el-checkbox-group>
            </div> -->

            <div class="divButtonRigth">
                <el-button type="primary" size="small"  fixed="right" v-if="this.detailVisible=='false'" @click="__getAverageWageList" >查询</el-button>
                <el-button type="primary" size="small"  fixed="right" v-if="this.detailVisible=='false'"  plain @click="dialogTableVisible = true" >设置展示项</el-button>
                <el-button type="primary" size="small"  fixed="right" v-if="this.detailVisible=='false'" @click="showDetails()" >计算</el-button>
                <el-button type="primary" size="small"  fixed="right" v-if="this.detailVisible=='true'" @click="exportDetails" >导出</el-button>
                <el-button type="primary" size="small"  fixed="right" v-if="this.detailVisible=='true'" @click="rtn()" >返回</el-button>
            </div>
        </div>

        <vxe-grid v-if="this.detailVisible=='false'"
            resizable
            extension="true"
            v-loading="loading"
            :ciic-data-filter.sync="listQuery"
            @select-change="selectChange"
            @select-all="selectAll"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            height="600"
            border
            ref="dttable"
            :columns="tableColumn"
            :data="list"
            :row-style="{height:'47px'}"
            :cell-style="{padding:'0px'}"
            :scroll-x="{gt: 20}"
            :scroll-y="{gt: 40}"
            :showOverflow="true"
            :showHeaderOverflow="true"
            >
        </vxe-grid>

          <!--明细列表-->
        <detailImportAverageWageDetil v-if="this.detailVisible=='true'"
         :detailList="this.detailList"
         :multipleSelections="this.multipleSelection"
         :checkedTaxtypes="this.showcolumns"
         refname="detailImportAverageWageDetil"
         ref="detailWage"
         >

        </detailImportAverageWageDetil>

      <el-dialog title="展示项" :visible.sync="dialogTableVisible" width="65%">
        <el-form ref="form" :model="form" label-width="80px">
          <el-row>
            <el-checkbox-group v-model="showcolumns">
              <el-col :span="4" v-for="(item,index) in tableColumn" :class="index > 6?'':'hide'" :key="field" style="margin-bottom: 5px">
                  <el-checkbox :label="item.title"></el-checkbox>
              </el-col>
            </el-checkbox-group>
          </el-row>
        </el-form>
        <div class="dialog-footer" slot="footer">
          <el-button @click="changeColumns" >确定</el-button>
        </div>
      </el-dialog>
    </div>



</template>
<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import detailImportAverageWageDetil from "@/views/entrust/detailImport/detailImportAverageWageDetil";
  import {downloadFile,downloadPdfFile} from '@/util/util';
  import {averageWageList,averageWageDetil,exportAverageWageDetails,selectCusNameByUserId} from '@/api/entrust/detailImport';
  import {mapGetters} from 'vuex';

  const cityOptions =['工资、补贴', '年终奖', '劳务费', '离职金','工资补发、补贴补发、月度多次发放','劳务费月度多次发放',
        '工资+年终奖','年终奖补发','偶然所得'];

  export default {
    name: 'detailImportAverageWage',
    inject:['reload'],
    components: {
      BspTable,
      BspTableColumn,
      detailImportAverageWageDetil,
    },

    props: {
      currentTab: {
        type: String,
        default: ''
      },
    },

    created() {
       // this.__getAverageWageList();
       this.selectCusNameByUserId()
    },

    data() {
      return {
             tableColumn: [
                { type: 'checkbox', width: 50 ,fixed:"left"},
                { type: 'seq',title: '序号', width: 50 ,fixed:"left"},
                { field: 'cusname', title: '客户名称',width: 120,fixed:"left",visible:true  },
                { field: 'username', title: '人员名称',width: 120,fixed:"left",visible:true},
                { field: 'cardid', title: '证件号码' ,width: 120,fixed:"left",visible:true},
                { field: 'months', title: '税金所属月份',width: 120,fixed:"left",visible:true},
                { field: 'taxtype', title: '计税类型' ,width: 120,fixed:"left", visible:true,formatter:this.formatTaxType},
                { field: 'amountpayable', title: '应发金额' ,width: 120, visible:true},
                { field:      'subsidypayable', title: '应发补贴' ,width: 120, visible:true},
                { field: 'otpbt', title: '税前其他计税部分' ,width: 120, visible:true},
                { field: 'pcitcp', title: '税前商保计税部分' ,width: 120, visible:true},
                { field: 'otfpbt', title: '税前其他免税部分' ,width: 120, visible:true},
                { field: 'nzjyqkk', title: '税后应客户要求扣款' ,width: 120, visible:true, formatter:this.formatDeduction},
                { field: 'shqtnsbt', title: '税后增加其他免税补贴' ,width: 120, visible:true},
                { field: 'actualamount', title: '实发金额' ,width: 120,  visible:true},
                { field: 'remarks', title: '备注' ,width: 120,  visible:true},
                { field:  'e204368', title: '财务转款时间' ,width: 120,visible:false},
                { field:  'e204201', title: '支出单编号' ,width: 120,visible:false},
                { field:  'e204203', title: '导入批次ID' ,width: 120,visible:false},
                { field:  'e204608', title: '任职受雇日期' ,width: 120, visible:false},
                { field:  'e204618', title: '离职日期' ,width: 120,visible:false},
                { field:  'e204262', title: '税金' ,width: 120,visible:false},
                { field:  'e204592', title: '累计应纳税所得额' ,width: 120,visible:false},
                { field:  'e204601', title: '累计应纳税所得额不减专' ,width: 120,visible:false},
                { field:  'e204611', title: '累计专项' ,width: 120,visible:false},
                { field:  'e204612', title: '累计税金' ,width: 120,visible:false},
                { field:  'e204593', title: '累计薪资收入额' ,width: 120,visible:false},
                { field:  'e204645', title: '累计其他扣除项目合计' ,width: 120,visible:false},
                { field:  'e204641', title: '累计三险一金合计' ,width: 120,visible:false},
                { field:  'e204594', title: '累计起征点扣除' ,width: 120,visible:false},
                { field:  'e204597', title: '累计应税金额调增项',width: 120,visible:false },
                { field:  'e204259', title: '起征点',width: 120,visible:false },
                { field:  'e204402', title: '税前五险免税合计',width: 120, visible:false},
                { field:  'e204224', title: '应纳税所得额调增项',width: 120,visible:false },
                { field:  'e204227', title: '应纳税所得额调减项' ,width: 120,visible:false},
                { field:  'e204409', title: '本次应纳税所得额',width: 120,visible:false },
                { field:  'e204365', title: '以往应纳税所得额',width: 120,visible:false },
                { field:  'e204410', title: '本次实际应税金额',width: 120,visible:false },
                { field:  'e204264', title: '工资速算扣除数',width: 120,visible:false },
                { field:  'e204263', title: '税率' ,width: 120,visible:false},
                { field:  'e204236', title: '税后应客户要求扣款工资',width: 120, visible:false,formatter:this.formatDeductionTax},
                { field:  'e204363', title: '以往已交税金合计',width: 120,visible:false },
                { field:  'e204235', title: '税后减项',width: 120, visible:false},
                { field:  'e204238', title: '税后加项' ,width: 120,visible:false},
                { field:  'e204602', title: '累计子女教育专项',width: 120, visible:false},
                { field:  'e204603', title: '累计继续教育专项',width: 120,visible:false },
                { field:  'e204605', title: '累计住房贷款利息专项',width: 120,visible:false },
                { field:  'e204606', title: '累计住房租金专项',width: 120, visible:false},
                { field:  'e204607', title: '累计赡养老人专项',width: 120, visible:false},
                { field:  'e204604', title: '累计大病医疗专项',width: 120,visible:false},
                { field:  'codeitemname', title: '报税名义',width: 120,visible:false},
              ],
      options: [{
          value: '工资、补贴',
          label: '工资、补贴'
        }, {
          value: '年终奖',
          label: '年终奖'
        }, {
          value: '劳务费',
          label: '劳务费'
        }, {
          value: '离职金',
          label: '离职金'
        }, {
          value: '工资补发、补贴补发、月度多次发放',
          label: '工资补发、补贴补发、月度多次发放'
        }, {
          value: '劳务费月度多次发放',
          label: '劳务费月度多次发放'
        }, {
          value: '工资+年终奖',
          label: '工资+年终奖'
        }, {
          value: '年终奖补发',
          label: '年终奖补发'
        }, {
          value: '偶然所得',
          label: '偶然所得'
        }],
        value: '',
        cusOptions:[],//客户选择
        checkedTaxtypes: ['工资、补贴', '年终奖', '劳务费', '离职金','工资补发、补贴补发、月度多次发放','劳务费月度多次发放',
        '工资+年终奖','年终奖补发','偶然所得'],
        showcolumns:["应发金额","应发补贴","税前其他计税部分","税前商保计税部分","税前其他免税部分","税后应客户要求扣款","税后增加其他免税补贴","实发金额","备注"],
        dialogTableVisible: false,
        checkAll: true,
        taxtypes: cityOptions,
        isIndeterminate: true,
        cusname: '',
        username: '',
        idcard: '',
        taxtype: '',
        months:[],
        // 遮罩
        loading: false,
        // 用于接收后台返回的分页数据
        list: [],
        detailVisible: 'false',

        dataList: [],
        //平均工资详情
        detailList:'',

        listQuery: {
          cusname:"",
          username: '',
          idcard: '',
          taxtypes: [],
          months:[],
          multipleSelection: [],
        },
        detailImportCusList:[],
        //多选数组
        multipleSelection: [],

        isDisabled: false,
        parameter: {
          batchImportIds:'',
          subIds:'',//明细选择导出
          subIdFlag:'',//明细导出标识

        },

        taxType : {
                "20040001":"工资、补贴",
                "20040002":"工资补发、补贴补发、月度多次发放",
                "20040003":"年终奖",
                "20040004":"劳务费",
                "20040005":"离职金",
                "20040007":"个人股权激励收入",
                "20040008":"年终奖补发",
                "20040010":"劳务费月度多次发放",
                "20040012":"利息股息红利所得",
                "20040017":"偶然所得",
                "20040018":"稿酬",
                "20040019":"特许权使用费",
                "20040020":"财产转让所得",
                "20040021":"其他财产租赁所得",
                "20040022":"其他连续劳务报酬所得",
                "20040023":"工资倒算(输入税金)",
                "20040024":"劳务费倒算",
                "20040025":"年终奖倒算",
                "20040026":"倒算类型",
                "20040027":"工资倒算(输入实发)",
                "20040028":"企业年金",
                "20040029":"股票期权",
                "20040030":"股权红利",
                "20040031":"工资+年终奖",
                "20040032":"代报销"
             }
        }
    },

    methods: {
      // 导出
      exportDetails(){
        this.$refs.detailWage.exportDetails();
      },
      // 切换列头
      changeColumns(e){
        this.dialogTableVisible =false
        setTimeout(()=>{
        for(let i = 6;i < this.tableColumn.length;i++){
          if(this.showcolumns.indexOf(this.tableColumn[i].title) > -1){
            this.$set(this.tableColumn[i],"visible",true)
            this.$refs.dttable.showColumn(this.$refs.dttable.getColumnByField(this.tableColumn[i].field))
          }else{
            this.$refs.dttable.hideColumn(this.$refs.dttable.getColumnByField(this.tableColumn[i].field))
            this.$set(this.tableColumn[i],"visible",false)
          }
        }
        this.$forceUpdate();
        this.$nextTick(() => {
          this.$refs.dttable.refreshColumn()
        })
        },100)
      },
      showDetails(){
        if(this.multipleSelection.length<=0){
              this.$message.error("请选择要计算的数据！");
              return;
        }

        //创建json,用于存储批次ID的状态
        var jsonStr="";
        const tipInfos = [];
        const h = this.$createElement;
        //将选中的id组装为string
        this.multipleSelection.forEach(item=> {
          debugger
          //是统筹账号,但是状态不是审核通过的状态，不允许提交
          if(item.e204229!=null||item.e204241!=null){
            jsonStr = item.username+'（身份证号'+ item.cardid+'）';
              tipInfos.push(h('p', null, jsonStr));
          }
        });
        tipInfos.push(h('b', { style: 'color: #E6A23C' }, '税前其他免税部分/税后增加其他免税补贴不为0，可能为生育津贴等项目，请线下计算'));
        jsonStr = jsonStr.substring(0, jsonStr.length-1) ;
        if(jsonStr.length>10){
          this.$msgbox({
            dangerouslyUseHTMLString: true,
            message: h('p', null, tipInfos),
            showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(action => {
            this.__getAverageWageDetil();
          })
        }else{
            this.__getAverageWageDetil();
        }


      },

      //复选框全选事件
      selectAll({checked, selection}) {

        if(checked){
          this.multipleSelection= selection
        }else{
          this.multipleSelection=[];
        }
        console.log("this.multipleSelection:"+this.multipleSelection);
      },

      selectChange(value) {
        if (value.selection) {
          this.multipleSelection = [];
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index];
            this.multipleSelection.push(element);
          }
        }
      },

      __getAverageWageList: function () {
        if(this.cusname.length<=0){
           this.$message.error("请选择客户名称！");
              return;
        }
        if(this.months.length<=0){
            this.$message.error("请选择工资所属月份！");
              return;
        }
        this.loading  =true;
        //组装请求参数
        this.listQuery.cusname = this.cusname
        this.listQuery.username = this.username
        this.listQuery.idcard = this.idcard
        this.listQuery.taxtypes = this.checkedTaxtypes
        this.listQuery.months = this.months

        averageWageList(this.listQuery).then(res=>{
            this.list = res.data;
            this.loading  =false;
        })
      },

      __getAverageWageDetil: function () {
        this.listQuery.multipleSelection = this.multipleSelection
        averageWageDetil(this.listQuery).then(res=>{

          this.detailList = res.data;
          this.detailVisible='true'
        })
      },

      menuChange(item){
        let flag = true
        for(var i=0;i<this.checkArr.length;i++){
          if(this.checkArr[i] === item.propName){
            flag = false
            break
          }
        }

        if(!flag){
          this.tableColumnList.push(item)
        }
        if(flag){
          Array.prototype.contains = function(obj) {
            var j = this.length;
            while (j--) {
              if (this[j] === obj) {
              return j; // 返回的这个 i 就是元素的索引下标，
              }
            }
            return false
          }
          this.tableColumnList.splice(this.tableColumnList.contains(item),1)
        }
      },

      handleCheckAllChange(val) {
        this.checkedTaxtypes = val ? cityOptions : [];
        this.isIndeterminate = false;
      },
      handleCheckedTaxtypesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.taxtypes.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.taxtypes.length;
      },
      //返回
      rtn(){
        this.multipleSelection = [];
        this.detailVisible = 'false';
      },

      selectCusNameByUserId: function (){

          selectCusNameByUserId().then(res=>{
          this.cusOptions = res.data;
        })
      },
      //年终奖税后应客户要求扣款
      formatDeduction ({ cellValue, row, column }) {
        if(row.taxtype == null || row.taxtype == ""){
          return null;
        }
        //计税类型 2004003(年终奖)、2004008(年终奖补发) 年终奖税后应客户要求扣款 其他类型是税后应客户要求扣款(工资)
        if(row.taxtype == "2004003" || row.taxtype =="2004008"){
           return cellValue;
        }
        return null;
      },

      // 税后应客户要求扣款(工资)
      formatDeductionTax ({ cellValue, row, column }){
        if(row.taxtype == null || row.taxtype == ""){
          return null;
        }
        //计税类型 2004003(年终奖)、2004008(年终奖补发) 年终奖税后应客户要求扣款 其他类型是税后应客户要求扣款(工资)
        if(row.taxtype != "2004003" && row.taxtype != "2004008"){
          return cellValue;
        }
        return null;
      },
      formatTaxType ({ cellValue, row, column }){
        if(cellValue == null){
            return null;
        }
        return this.taxType[cellValue]
      },
    }
  }

</script>


<style scoped>
  .hide{
    display: none;
  }
  .copy-btn{
    cursor: pointer;
  }
  .ciic-table-operate{
    align-items: center;
  }
  .editForm {
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

  }

  .editForm .el-form-item {
    margin: 0;
  }
  .editForm .el-form-item{
    /*width: 30%;*/
    padding: 10px 4px;
    display: flex;
  }

  .editForm .el-form-item:first-child,.editForm .el-form-item:nth-child(3){
    flex:1;
  }

  .editForm .el-form-item >>> .el-form-item__label{
    flex-shrink: 0;
  }
  @media (min-width:1367px){
    .editForm .el-form-item:nth-child(2){
      flex:1;
    }
    .editForm .el-form-item:nth-child(4){
      flex:2;
    }
  }
  @media (max-width:1367px){www
    .editForm .el-form-item:nth-child(2){
      width: 130px;
    }
    .editForm .el-form-item:nth-child(4){
      width: 290px;
    }
    .editForm .el-form-item >>> .el-form-item__label{
      padding-right: 8px;
    }
    .editForm .el-form-item  >>> .el-radio__label{
      padding-left: 4px;
    }
    .editForm .el-form-item  >>> .el-radio{
      margin-right: 4px;
    }
  }
</style>
