<template>
	<el-form ref="listData" :model="listData" :rules="listRules" label-width="120px">
    <div class="editForm">
      <el-row>
        <el-col :span="12">
          <el-form-item label="客户名称" prop="cusName">
            <el-input v-model.trim="listData.cusName" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系人" prop="contacts">
            <el-input v-model.trim="listData.contacts"/>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="角色" prop="role">
            <bsp-dicts-select v-model="listData.role" dic-num="3009"  ></bsp-dicts-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="职务" props="post">
            <el-input v-model.trim="listData.post"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号码" prop="phone">
            <el-input v-model.trim="listData.phone"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电子邮箱" prop="email">
            <el-input v-model.trim="listData.email"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="微信账号" prop="wechat">
            <el-input v-model.trim="listData.wechat"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="线索来源" prop="leadsSource">
            <bsp-dicts-select v-model="listData.leadsSource" :selectValue.sync="listData.leadsSource" dic-num="3011"  ></bsp-dicts-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="线索状态" prop="leadsStatus">
              <bsp-dicts-select v-model="listData.leadsStatus" :selectValue.sync="listData.leadsStatus" @selectChange="leadsStatusChange" dic-num="3013"  ></bsp-dicts-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="归属人员" prop="ascriptionPer">
            <el-select clearable v-model="listData.ascriptionPer" placeholder="请选择" disabled="true">
              <el-option
                v-for="item in ascriptionList"
                :key="item.id"
                :value="item.id"
                :label="item.ascriptionName"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item label="最新跟进" prop="followTime">
            <el-date-picker
              v-model="listData.followTime"
              type="date"
              format="yyyy-MM-dd"
              value-format ="yyyy-MM-dd"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="创建时间" prop="createTime">
            <el-date-picker
              readonly
              disabled="true"
              v-model="listData.createTime"
              type="date"
              format="yyyy-MM-dd"
              value-format ="yyyy-MM-dd"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
         <el-col :span="24">
              <el-form-item label="备注信息" prop="remark">
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="listData.remark"
                  maxlength="500"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
      </el-row>
    </div>
  </el-form>
</template>

<script>
import { isvalidatemobile, validateEmail } from '@/util/validate'
import bspDictsSelect from '@/components/bsp/bsp-dicts-select/bsp-dicts-select'
import { checkCusName } from '@/api/cus/leads'
import { mapGetters } from 'vuex'

// 客户名称校验是否重复
let validateCusName = (rule, value, callback) => {
	console.log('客户名称是校验', rule, value, callback)
	// 值不能空，为空不校验
	if (value) {
		checkCusName({ cusName: value, id: '' })
			.then(res => {
				console.log('客户名称是否重复2222', res)
				let isExist = res.data.data
				//isExist =true;
				if (isExist) {
					callback(new Error('该客户已存在不能重复添加！'))
				} else {
					callback()
				}
			})
			.catch(e => {
				console.log('校验出错了', e)
				callback()
			})
	} else {
		callback()
	}
}
// 手机号码校验
let validatePhone = (rule, value, callback) => {
	var phoneVal = value
	console.log('手机号校验', rule, value, callback)
	// 值不能空，为空不校验
	if (phoneVal) {
		if (isvalidatemobile(phoneVal)[0]) {
			callback(new Error(isvalidatemobile(phoneVal)[1]))
		} else {
			callback()
		}
	} else {
		callback()
	}
}
// 邮箱
let validateEmails = (rule, value, callback) => {
	var emailVal = value
	if (emailVal) {
		if (!validateEmail(emailVal)) {
			callback(new Error('请输入正确的邮箱地址！'))
		} else {
			callback()
		}
	} else {
		callback()
	}
}
export default {
	components: {
		bspDictsSelect
	},
	computed: {
		...mapGetters(['userInfo'])
	},
	name: 'addLeads',
	props: {
		listData: {
			type: Object,
			default: () => {
				return {
					cusName: '',
					contacts: '',
					role: '',
					post: '',
					phone: '',
					email: '',
					wechat: '',
					leadsSource: '',
          leadsStatus: '',
					ascriptionPer: '',
          followTime: new Date(),
					createTime: '',
					remark: ''
				}
			}
		},
		cusIsExist: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			// 角色列表
			rolesList: [
				{ id: '1', roleName: 'HR' },
				{ id: '2', roleName: '采购' },
				{ id: '3', roleName: '决策者' },
				{ id: '4', roleName: '其他' }
			],
			// 线索来源
			clueSourceList: [
				{ id: '1', clueSourceName: '全部' },
				{ id: '2', clueSourceName: '电话营销' },
				{ id: '3', clueSourceName: '主动来电' },
				{ id: '4', clueSourceName: '客户介绍' },
				{ id: '5', clueSourceName: '朋友介绍' },
				{ id: '6', clueSourceName: '独立开发' },
				{ id: '7', clueSourceName: '网络搜索' },
				{ id: '8', clueSourceName: '广告杂志' },
				{ id: '9', clueSourceName: '展会促销' },
				{ id: '10', clueSourceName: '其它途径' }
			],
			// 线索状态
			clueStateList: [
				{ id: '1', roleName: '初访' },
				{ id: '2', roleName: '意向' },
				{ id: '3', roleName: '跟进' },
				{ id: '4', roleName: '签约' },
				{ id: '5', roleName: '流失' }
			],
			// 归属人员
			ascriptionList: [],
			// 校验规则
			listRules: {
				cusName: [
					{
						required: true,
						message: '客户名称不能为空!',
						trigger: 'blur'
					},
					{ validator: validateCusName, trigger: 'blur' },
					{
						min: 1,
						max: 50,
						message: '长度在 1 到 50 个字符',
						trigger: 'blur'
					}
				],
				contacts: [
					{
						required: true,
						message: '联系人不能为空!',
						trigger: 'blur'
					},
					{
						min: 1,
						max: 30,
						message: '长度在 1 到 30 个字符',
						trigger: 'blur'
					}
				],
				phone: [
					{
						required: true,
						message: '手机号码不能为空!',
						trigger: 'blur'
					},
					{ validator: validatePhone, trigger: 'blur' }
				],
				email: [{ validator: validateEmails, trigger: 'blur' }],
				post: [
					{
						min: 1,
						max: 30,
						message: '长度在 1 到 30 个字符',
						trigger: 'blur'
					}
				],
				wechat: [
					{
						min: 1,
						max: 30,
						message: '长度在 1 到 30 个字符',
						trigger: 'blur'
					}
				],
				leadsSource: [
					{
						required: true,
						message: '线索来源不能为空!',
						trigger: 'change'
					}
				],
				leadsStatus: [
					{
						required: true,
						message: '线索状态不能为空!',
						trigger: 'change'
					}
				],
				followTime: [
					{
						required: true,
						message: '最新跟进不能为空!',
						trigger: 'change'
					}
				],
				createTime: [
					{
						required: true,
						message: '创建时间不能为空!',
						trigger: 'change'
					}
				]
			}
		}
	},
	created() {
		console.log('当前用户信息', this.userInfo)
		this.ascriptionList.push({
			id: this.userInfo.userId,
			ascriptionName: this.userInfo.name
		})
		this.listData.ascriptionPer = this.userInfo.userId
		let myDate = new Date()
		let year=myDate.getFullYear() //获取完整的年份(4位,1970-????)
		let month= myDate.getMonth()+1 > 9?myDate.getMonth()+1:"0"+(myDate.getMonth()+1) //获取当前月份(0-11,0代表1月) // 所以获取当前月份是myDate.getMonth()+1;
    let date = myDate.getDate() //获取当前日(1-31)
    myDate = year+"-"+month+"-"+date;

    this.$set(this.listData,"createTime",myDate)
    this.$set(this.listData,"followTime",myDate)
	},
	methods: {
		// 校验客户名称是否重复
		getCheckName() {
			let cusName = this.listData.cusName
			checkCusName({ cusName: cusName }).then(res => {
				console.log('客户名称是否重复', res)
				this.cusIsExist = res.data.data
				this.cusIsExist = true
				this.$emit('update:cusIsExist', this.cusIsExist)

				if (this.cusIsExist) {
					this.$message.error('该客户已存在不能重复添加！')
				}
			})
		},
    // leadsStatusChange(val){
		//   debugger
    //   this.listData.leadsStatus = val;
    //   this.forceUpdate();
    // }
	}
}
</script>

<style scoped>
</style>
