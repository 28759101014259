<template>
  <div>
    <el-row :gutter="12">
        <el-col :span="12" >
            <el-upload
        class="upload-demo"
        ref="upload"
        v-bind="$attrs"
        v-on="$listeners"
        :action="uploadUrl"
        :headers="headers"
        multiple
        drag
        :auto-upload="false"
        :file-list="fileList"
        :before-upload="beforeUpload"
        :on-change="fileLoad"
        :accept="accept"
        :show-file-list="false"
        >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>
          <br/>
          已选择{{fileList.length}}个附件,点击确定上传完成
        </div>
        </el-upload>
        </el-col>
        <el-col :span="12" style="padding-left: 12px">
            <div style="padding-left: 12px">
              <el-button size="small" type="success" @click="submitUpload">确定上传</el-button>
            </div>
            <div class="uploadTip-title">
              <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-tishi"></use>
              </svg>
              温馨提示
            </div>
        <div class="uploadTip-lists">
            <div class="uploadTip-list">
              支持文件后缀 {{accept}}文件 且不超过10M;
            </div>
        </div>
        </el-col>
    </el-row>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "payOrder",
    data(){
      return{
          uploadUrl: "/api/notice/upload",
          fileList: [],
      }
    },
    computed: {
      headers() {
        return {
          Authorization: `Bearer ${this.accessToken}`,
        };
      },
      ...mapGetters(['accessToken', 'userInfo']),
    },
    props:{
        accept:{
            type: String,
            default: '.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx',
        },
        checkFlag:{
            type: Boolean,
            default: true,
        }
      },
    methods: {
      resetFileList(){
        this.fileList = []
      },
        beforeUpload: function (file) {
          console.log("this.checkFla",this.checkFlag)
          if(this.checkFlag){
              let type = file.type;
              console.log("beforeUpload："+type)
              console.log("beforeUpload2："+file.name)
              let doc = file.name.lastIndexOf('.doc');
              let docx = file.name.lastIndexOf('.docx');
              let xls = file.name.lastIndexOf('.xls');
              let xlsx = file.name.lastIndexOf('.xlsx');
              console.log("beforeUpload3："+doc)
              console.log("beforeUpload4："+docx)
              const isIMG = file.type.includes('image');


              if (!isIMG && xls ==-1 && xlsx==-1  && doc==-1 && docx==-1 ) {
                  this.$message.error('上传截图只支持图片、word、excel格式!')
              }
              if(isIMG || xlsx!=-1 || xls!=-1 ||  doc!=-1 || docx!=-1 ){
                  return true;
              }
              return isIMG
          } else {
              //文件校验.jpg,.jpeg,.png,.pdf
              var fileForma = this.accept.split(',');
              if(fileForma.length > 0){
                  fileForma = fileForma.filter(item => {
                      return file.name.lastIndexOf(item) >= 0;
                  });
                  if (fileForma.length == 0) {
                      this.$message.error('文件上传失败，文件格式不正确（仅支持' + this.accept + '）');
                      this.fileList = [];
                      return;
                  }
              }
          }
        },
        // 文件提交
        submitUpload() {
            this.$refs.upload.submit();
        },
        // 文件发生变化
        fileLoad(file, fileList) {
          if (file.status != 'success') {
            this.fileList = fileList;
          }
        },
    },
  };
</script>

<style scoped rel="stylesheet/scss">
  .uploadBox {
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .uploadContent {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .uploadContent .iconfont {
    font-size: 80px;
    color: #cccccc;
  }

  .uploadBox >>> .el-upload {
    width: 100%;
    height: 100%;
  }

  .uploadBox >>> .el-upload-dragger {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .uploadBox >>> .el-upload-dragger .el-icon-upload {
    margin-top: 0;
  }

  .uploadText {
    color: #aaaaaa;
  }


.uploadTip-title{
    margin-top: 20px;
    box-sizing: border-box;
    padding-left: 20px;
}
  .uploadTip-lists {
    padding-left: 20px;
    font-size: 12px;
  }


  .uploadTip-list .uploadTip-button {
    color: #269cff;
    text-decoration: underline;
    cursor: pointer;
  }

  .next-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

</style>
