<template>
  <div class="printBox">
  <div>
    <div>支付单编号：{{this.payCode}}</div>
    <div>
      <div style="text-align: center">
        <div>
          <span style="float: right">
            网银批次号：_______________________
          </span>
        </div>
      </div>
    </div>
    <div style="text-align: center; font-weight: bolder">中智薪锐技术服务有限公司 对公支付单</div>
    <div>
      <table border="1" style="width: 100%">
        <tr>
          <th colspan="1" rowspan="2">负责人</th>
          <th colspan="1" rowspan="2">客户名称</th>
          <th colspan="11" >支出项目</th>
          <th colspan="1" rowspan="2">备注</th>
        </tr>
          <tr>
          <th>个税税金（借）</th>
          <th>工资往来</th>
          <th>代报销（借）</th>
          <th>委托费成本</th>
          <th>注册服务成本</th>
          <th>财税服务成本</th>
          <th>薪酬服务成本</th>
          <th>残保金</th>
          <th>其他往来</th>
          <th>其他成本</th>
          <th>合计</th>
        </tr>
        <tr v-for="(item,idx) in this.data.publicPayments" :key="idx" style="text-align: center">
          <td>{{item.businessLeaderName }}</td>
          <td>{{item.cusName}}</td>
          <td>{{item.paymentProjectTaxD | moneyFilterStr}}</td>
          <td>{{item.paymentProjectWage | moneyFilterStr}}</td>
          <td>{{item.paymentProjectReimburse | moneyFilterStr}}</td>
          <td>{{item.paymentProjectTrustCost | moneyFilterStr}}</td>
          <td>{{item.paymentProjectRegisterCost | moneyFilterStr}}</td>
          <td>{{item.paymentProjectFinanceCost | moneyFilterStr}}</td>
          <td>{{item.paymentProjectWageCost | moneyFilterStr}}</td>
          <td>{{item.paymentProjectDisability | moneyFilterStr}}</td>
          <td>{{item.paymentProjectOther | moneyFilterStr}}</td>
          <td>{{item.paymentProjectOtherCost | moneyFilterStr}}</td>
          <td>{{item.paymentProjectWage| sum(item.paymentProjectTaxD,item.paymentProjectWage,item.paymentProjectReimburse,
            item.paymentProjectTrustCost,item.paymentProjectRegisterCost,item.paymentProjectFinanceCost,
            item.paymentProjectWageCost,item.paymentProjectDisability,item.paymentProjectOther,
            item.paymentProjectOtherCost) | moneyFilterStr }}</td>
          <td></td>
        </tr>
        <tr style="text-align: center">
          <td></td>
          <td>合计</td>
          <td>{{(this.sums.paymentProjectTaxD / 100) | moneyFilter}}</td>
          <td>{{(this.sums.paymentProjectWage / 100) | moneyFilter}}</td>
          <td>{{(this.sums.paymentProjectReimburse / 100) | moneyFilter}}</td>
          <td>{{(this.sums.paymentProjectTrustCost / 100) | moneyFilter}}</td>
          <td>{{(this.sums.paymentProjectRegisterCost / 100) | moneyFilter}}</td>
          <td>{{(this.sums.paymentProjectFinanceCost / 100) | moneyFilter}}</td>
          <td>{{(this.sums.paymentProjectWageCost / 100) | moneyFilter}}</td>
          <td>{{(this.sums.paymentProjectDisability / 100) | moneyFilter}}</td>
          <td>{{(this.sums.paymentProjectOther / 100) | moneyFilter}}</td>
          <td>{{(this.sums.paymentProjectOtherCost / 100) | moneyFilter}}</td>
          <td>{{(this.sums.total / 100) | moneyFilter}}</td>
          <td></td>
        </tr>
      </table>
    </div>
    <!-- <div style="text-align: center">[{{this.data.paymentBillsBO.payCode}}]</div> -->
    <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right" @click="print">打印</el-button>
      <el-button type="primary" size="big" fixed="right" @click="close">关闭</el-button>
    </div>
  </div>
  </div>
</template>

<script>


  import {
    printFinancialPayment,printPublicPayment
  } from '@/api/payment/payment';

  import {
   floatSum
  } from '@/util/util';

  export default {
    name: "printEmployeeDetail",
    created() {
      this.load();
    },
    mounted(){
      const that = this;
      this.$nextTick(()=>{
        document.getElementsByTagName("body")[0].classList.add("print");
      })

      if (window.matchMedia) {
        var mediaQueryList = window.matchMedia('print');

        //为印添加事件
        mediaQueryList.addListener(function(mql) {
          if (mql.matches) {
          } else {
            that.afterPrint();
          }
        });
      }
      //打印后事件
      window.onafterprint = that.afterPrint;

    },
    data() {
      return {
        // 页面数据
        data: {
          paymentBillsBO: [],
          cusList: [],
          bankList: [],
          publicPayments: []
        },
        //分页查询对象
        listQuery: {
          paymentId: this.$route.query.paymentId,
        },
        payCode: this.$route.query.payCode,
        sums: {
          paymentProjectTaxD: 0,
          paymentProjectWage: 0,
          paymentProjectReimburse: 0,
          paymentProjectTrustCost: 0,
          paymentProjectRegisterCost: 0,
          paymentProjectFinanceCost: 0,
          paymentProjectWageCost: 0,
          total: 0,
          paymentProjectDisability: 0,
          paymentProjectOther: 0,
          paymentProjectOtherCost: 0
        }
      }
    },
    filters: {
      sum(a,...maps){
        let ans = parseFloat(a);
        for(let m = 0;m < maps.length;m++){
          if(maps[m]){
            ans += parseFloat(maps[m]);
          }
        }
        return ans.toFixed(2)
      }
    },
    methods: {
      load() {
        printPublicPayment(this.listQuery).then(res => {
          this.data = res.data.data;
          if(res.data.resCode!=200){
            alert("查询接口错误！请联系管理员")
            return false
          }

          for (let cus in this.data.publicPayments) {
            this.sums.paymentProjectTaxD += Number(this.data.publicPayments[cus].paymentProjectTaxD) * 100;
            this.sums.paymentProjectWage += Number(this.data.publicPayments[cus].paymentProjectWage) * 100;
            this.sums.paymentProjectReimburse += Number(this.data.publicPayments[cus].paymentProjectReimburse) * 100;
            this.sums.paymentProjectTrustCost += Number(this.data.publicPayments[cus].paymentProjectTrustCost) * 100;
            this.sums.paymentProjectRegisterCost += Number(this.data.publicPayments[cus].paymentProjectRegisterCost) * 100;
            this.sums.paymentProjectFinanceCost += Number(this.data.publicPayments[cus].paymentProjectFinanceCost) * 100;
            this.sums.paymentProjectWageCost += Number(this.data.publicPayments[cus].paymentProjectWageCost) * 100;
            this.sums.paymentProjectDisability += Number(this.data.publicPayments[cus].paymentProjectDisability) * 100;
            this.sums.paymentProjectOther += Number(this.data.publicPayments[cus].paymentProjectOther) * 100;
            this.sums.paymentProjectOtherCost += Number(this.data.publicPayments[cus].paymentProjectOtherCost) * 100;
          }
          this.sums.total += Number(this.sums.paymentProjectTaxD + this.sums.paymentProjectWage +this.sums.paymentProjectReimburse+ this.sums.paymentProjectTrustCost + this.sums.paymentProjectRegisterCost
            + this.sums.paymentProjectFinanceCost + this.sums.paymentProjectWageCost + this.sums.paymentProjectDisability + this.sums.paymentProjectOther + this.sums.paymentProjectOtherCost)
        })
      },
      print() {
        this.hideBtn = true;
        this.$nextTick(()=>{
          window.print();
        })
      },
      close() {
        window.close();
      },
      afterPrint(){
        this.hideBtn = false;
        console.log("打开",this.hideBtn);
      }
    },
    beforeDestroy(){
      document.getElementsByTagName("body")[0].classList.remove("print");
    }
  }
</script>

<style scoped>
  .tt{
    width: 100%;
  }
  .theader{
    text-align: center
  }
  .tbd{
    text-align: center
  }
  .divButtonRigth {
    margin-bottom: 10px;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .printBox{
    padding-bottom: 60px!important;
  }
  .hideBtn{
    display: none;
  }
</style>
<style>

  .print #app,.print #app .pull-height,.print #app .index,.print #app .index>div,.print .index .main{
    height:auto;
    overflow-y: auto;
    min-height: auto;
    min-width: 100%!important;
    max-width: 100%!important;
  }
  .print .index .main .router{
    padding: 0;
  }


</style>
