<template>
  <div class="importType">
    <el-row :gutter="100" class="importType-row">
      <el-col :span="8" :offset="3" class="importType-box mult">
        <p class="importType-title">批量客户导入</p>
        <p class="importType-explain">同一批次导入多家客户的薪资明细数据，适用于薪资项目均一致的客户</p>
        <div class="importType-icon">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jichu"></use>
          </svg>
        </div>
        <el-button type="primary" @click="nextStep('batch')" class="importType-btn">选择导入</el-button>
      </el-col>
      <el-col :span="8" :offset="2" class="importType-box single">
        <p class="importType-title">单一客户导入</p>
        <p class="importType-explain">同一批次仅能导入一家客户的薪资明细数据，适用于存在自定义项目的客户</p>
        <div class="importType-icon ">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-diandeng"></use>
          </svg>
        </div>
        <el-button type="primary" @click="nextStep('single')" class="importType-btn">选择导入</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>

  export default {

    data() {
      return {

      };
    },
    methods: {
      nextStep(type) {
        if(type == "batch"){
          this.$store.state.impData.importType = "batch";
        }else{
          this.$store.state.impData.importType = "single";
        }
        this.$emit("onupload"); // 跳转下一步
      },
      // goback() {
      //   this.$emit("goTopage"); // 返回
      // },
    }
  };
</script>
<style scoped rel="stylesheet/scss">
  .stepContent {
  }

  .download {
    display: flex;
    justify-content: flex-end;
  }

  .download p {
    margin-left: 20px;
    line-height: 50px;
    color: #269cff;
    cursor: pointer;
  }

  .download p svg {
    margin-right: 10px;
  }

  .download p:hover {
    text-decoration: underline;
  }

  .uploadBox {
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .uploadContent {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .uploadContent .iconfont {
    font-size: 80px;
    color: #cccccc;
  }

  .uploadContent {
    height: 140px;
  }

  .uploadBox >>> .el-upload {
    width: 100%;
    height: 100%;
  }

  .uploadBox >>> .el-upload-dragger {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .uploadBox >>> .el-upload-dragger .el-icon-upload {
    margin-top: 0;
  }

  .uploadText {
    color: #aaaaaa;
  }

  .uploadTip-lists {
    padding-left: 20px;
  }

  .uploadTip-list .uploadTip-button {
    color: #269cff;
    text-decoration: underline;
    cursor: pointer;
  }

  .next-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
</style>
