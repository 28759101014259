<template>
  <div v-loading="loading" class="stepContent">
    <div class="matchBox">
      <div class="matchTitle">
      </div>
      <div class="infoForm">

        <el-form :model="batchInfo" ref="batchInfo" :rules="batchInfoRules" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="客户名称" prop="cusId">
                <p>盈创投资管理有限公司</p>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="matchList">
        <div class="showAll">
          <el-checkbox v-model="checked">显示全部表头</el-checkbox>
        </div>
        <div class="matchTable">
          <el-table
            :data='checked?tableData:filteredTableData'
            style="width: 100%">
            <el-table-column
              type="index"
              label="序号"
            >
            </el-table-column>
            <el-table-column
              prop="excel"
              label="Excel表头字段"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="系统字段"
            >
              <template slot-scope="scope">
                <el-select @change="changeData" v-if="checked" v-model="tableData[scope.$index].sys"
                           :class="{nodata:checked?!tableData[scope.$index].sys:!filteredTableData[scope.$index].sys}" placeholder="未匹配">
                  <el-option-group
                    v-for="group in options"
                    :key="group.value"
                    :label="group.label">
                    <el-option
                      v-for="item in group.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-option-group>
                </el-select>
                <el-select @change="changeData" v-else v-model="filteredTableData[scope.$index].sys"
                           :class="{nodata:checked?!tableData[scope.$index].sys:!filteredTableData[scope.$index].sys}" placeholder="未匹配">
                  <el-option-group
                    v-for="group in options"
                    :key="group.label"
                    :label="group.label">
                    <el-option
                      v-for="item in group.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-option-group>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="recommend"
              label="智能推荐字段">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="uploadTip">
      <div class="uploadTip-title">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-tishi"></use>
        </svg>
        温馨提示
      </div>
      <div class="uploadTip-lists">
        <div class="uploadTip-list">
          1、当Excel导入的信息项目与系统薪资项名称完全一致时，系统自动匹配
        </div>
        <div class="uploadTip-list">
          2、未匹配的薪资项下的数据将不予导入
        </div>
        <div class="uploadTip-list">
          3、系统自动记录对应关系，如导入模板未发生变化，无需重复匹配
        </div>
      </div>
    </div>
    <div v-if="next" class="next-btn">
      <el-button type="primary" @click="goback" size="mini">上一步</el-button>
      <el-button type="primary" @click="nextStep" size="mini">下一步</el-button>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        checked: false,
        next: true,
        filteredTableData: [],//未匹配
        tableData: [{//全部数据
          excel: "王小虎1",//表头字段
          recommend: "1上海市普陀区金沙江路 1518 弄",//推荐字段
          sys: "11"//系统字段
        }, {
          excel: "王小虎2",
          sys: "",
          recommend: "2上海市普陀区金沙江路 1518 弄"
        }, {
          excel: "王小虎3",
          sys: "22",
          recommend: "2上海市普陀区金沙江路 1518 弄"
        }, {
          excel: "王小虎4",
          sys: "",
          recommend: "2上海市普陀区金沙江路 1518 弄"
        }, {
          excel: "王小虎5",
          sys: "",
          recommend: "2上海市普陀区金沙江路 1518 弄"
        }, {
          excel: "王小虎6",
          sys: "",
          recommend: "2上海市普陀区金沙江路 1518 弄"
        }],
        options: [{
          label: "一组",
          options: [{
            value: "11",
            label: "上海"
          }, {
            value: "12",
            label: "北京"
          }]
        }, {
          label: "二组",
          options: [{
            value: "21",
            label: "成都"
          }, {
            value: "22",
            label: "深圳"
          }, {
            value: "23",
            label: "广州"
          }, {
            value: "24",
            label: "大连"
          }]
        }]
      };
    },
    created(){
      this.filteredTableData = this.tableData.filter(function(data,index) {
        if (!data.sys) {
          return data;
        }
      });
    },
    mounted() {

    },
    watch: {
      checked:function(val){
        // 不选中时更新未匹配列表
        if(!val){
          this.filteredTableData = this.filteredTableData.filter(function(data,index) {
            if (!data.sys) {
              data.inx = index
              return data;
            }
          });
        }
        console.log(this.filteredTableData)
      }
    },
    methods: {
      nextStep() {
        this.$emit("next"); // 跳转下一步
      },
      goback() {
        this.$emit("goback"); // 返回
      }
    }
  };
</script>
<style scoped rel="stylesheet/scss">
  .stepContent {
  }

  .next-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .matchTable {
    margin-bottom: 20px;
  }

  .nodata >>> input::-webkit-input-placeholder {
    color: red;
  }

 /* .nodata >>> input::-moz-input-placeholder {
    color: red;
  }*/

  .nodata >>> input::-ms-input-placeholder {
    color: red;
  }




  .uploadTip-lists {
    padding-left: 20px;
  }


  .uploadTip-list .uploadTip-button {
    color: #269cff;
    text-decoration: underline;
    cursor: pointer;
  }
</style>
