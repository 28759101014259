import request from '@/router/axios';
const urlPrefix = '';

// 
export function forwardImportTemplateOfOrg(obj) {
  return request({
    url:urlPrefix + '/template/forwardImportTemplateOfOrg',
    method: 'post',
    data: obj
  })
}

// 根据模板id获取模板项信息
export function getSysTemplate(obj) {

  return request({
    url:urlPrefix + '/template/getSysTemplate',
    method: 'post',
    data: obj
  })
}

// 保存模板更名信息
export function saveTemplateRename(obj) {
  
  return request({
    url:urlPrefix + '/template/saveWageTemplateRename',
    method: 'post',
    data: obj
  })
}