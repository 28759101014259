<!-- 树状选择器 -->
<template>
  <el-popover
    ref="popover"
    placement="bottom-start"
    trigger="click"
    @show="onShowPopover"
    @hide="onHidePopover">
    <el-tree
      ref="tree"
      :style="`min-width: ${treeWidth}`"
      :data="data"
      :props="props"
      :expand-on-click-node="false"
      :filter-node-method="filterNode"
      :default-expand-all="true"
      class="select-tree"
      size="mini"
      highlight-current
      @node-click="onClickNode"/>
    <el-input
      slot="reference"
      ref="input"
      v-model="labelModel"
      :style="`width: ${width}px;height: ${height}px;line-height: ${height}px;`"
      :class="{ 'rotate': showStatus }"
      :placeholder="placeholder"
      :size="size"
      clearable
      readonly
      suffix-icon="el-icon-arrow-down"/>
  </el-popover>
</template>

<script>

export default {
  name: 'Pagination',
  // 设置绑定参数
  model: {
    prop: 'value',
    event: 'selected'
  },
  props: {
    // 接收绑定参数
    superId: {
      type: String,
      default: ''
    },
    // 接收绑定参数
    value: {
      type: String,
      default: ''
    },
    // 输入框宽度
    width: {
      type: String,
      default: '350'
    },
    // 输入框高度
    height: {
      type: String,
      default: '28'
    },
    // input框的大小
    size: {
      type: String,
      default: ''
    },
    // 输入框占位符
    placeholder: {
      type: String,
      required: false,
      default: '请选择'
    },
    // 树节点配置选项
    props: {
      type: Object,
      required: false,
      default: () => ({
        parent: 'superId',
        value: 'id',
        label: 'orgName',
        children: 'children'
      })
    }
  },
  data() {
    return {
      // 树状菜单显示状态
      showStatus: false,
      // 菜单宽度
      treeWidth: 'auto',
      // 输入框显示值
      labelModel: '',
      // 实际请求传值
      valueModel: '',
      // 选项数据
      options: []
    }
  },
  computed: {
    // 是否为树状结构数据
    dataType() {
      const jsonStr = JSON.stringify(this.options)
      if (jsonStr) {
        return jsonStr.indexOf(this.props.children) != -1
      } else {
        return true
      }
    },
    // 若非树状结构，则转化为树状结构数据
    data() {
      return this.dataType ? this.options : this.switchTree()
    }
  },
  watch: {
    labelModel(val) {
      if (!val) {
        this.valueModel = ''
      }
      this.$refs.tree.filter(val)
    },
    value(val) {
      this.labelModel = this.queryTree(this.data, val)
    }
  },
  created() {
    // 检测输入框原有值并显示对应 label
    if (this.value) {
      this.labelModel = this.queryTree(this.data, this.value)
    }
    // 获取输入框宽度同步至树状菜单宽度
    this.$nextTick(() => {
      this.treeWidth = `${(this.width || this.$refs.input.$refs.input.clientWidth) - 24}px`
    })
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      var that = this
      that.$set(that, 'options', [{
        "branchId": "HUB001",
        "children": [{
          "branchId": "HUB001",
          "children": [],
          "createBy": null,
          "createTime": null,
          "cusId": "0e5adece2a9940fda2b7c65deeb74f78",
          "formation": null,
          "id": "44a79fd1c3fb11e9806b00163e026f59",
          "keyword": null,
          "leader": null,
          "orgFormation": null,
          "orgName": "中智智人",
          "orgNumber": null,
          "orgType": null,
          "page": 0,
          "perId": null,
          "perName": null,
          "perNumber": null,
          "remark": null,
          "size": 0,
          "supId": "441d5d60c3fb11e9806b00163e026f59",
          "supName": null,
          "updateBy": null,
          "updateTime": null
        }],
        "createBy": null,
        "createTime": null,
        "cusId": "0e5adece2a9940fda2b7c65deeb74f78",
        "formation": null,
        "id": "441d5d60c3fb11e9806b00163e026f59",
        "keyword": null,
        "leader": null,
        "orgFormation": "500",
        "orgName": "交付中心",
        "orgNumber": null,
        "orgType": null,
        "page": 0,
        "perId": "",
        "perName": null,
        "perNumber": null,
        "remark": null,
        "size": 0,
        "supId": "-1",
        "supName": null,
        "updateBy": null,
        "updateTime": null
      }])
      // 赋值
      if (that.value) {
        that.labelModel = that.queryTree(that.data, that.value)
      }
      /*// 获取随机数  防止被后台重复提交拦截
      var randomNum = new Date().getTime() + '' + Math.floor(Math.random() * 900000) + 100
      that.getAxios('/employee/basic/loadOrg/' + that.superId + '/' + randomNum, {}, { showLoading: false }).then(resp => {
        that.$set(that, 'options', resp.data)
        // 赋值
        if (that.value) {
          that.labelModel = that.queryTree(that.data, that.value)
        }
      })*/
    },
    // 单击节点
    onClickNode(node) {
      this.labelModel = node[this.props.label]
      this.valueModel = node[this.props.value]
      this.onCloseTree()
    },
    // 偏平数组转化为树状层级结构
    switchTree() {
      return this.cleanChildren(this.buildTree(this.options, '0'))
    },
    // 隐藏树状菜单
    onCloseTree() {
      this.$refs.popover.showPopper = false
    },
    // 显示时触发
    onShowPopover() {
      this.showStatus = true
      this.$refs.tree.filter(false)
    },
    // 隐藏时触发
    onHidePopover() {
      this.showStatus = false
      if (this.valueModel !== '') {
        this.$emit('selected', this.valueModel)
      }
    },
    // 树节点过滤方法
    filterNode(query, data) {
      if (!query) return true
      return data[this.props.label].indexOf(query) !== -1
    },
    // 搜索树状数据中的 ID
    queryTree(tree, id) {
      let stark = []
      stark = stark.concat(tree)
      while (stark.length) {
        const temp = stark.shift()
        if (temp[this.props.children]) {
          stark = stark.concat(temp[this.props.children])
        }
        if (temp[this.props.value] === id) {
          return temp[this.props.label]
        }
      }
      return ' '
    },
    // 将一维的扁平数组转换为多层级对象
    buildTree(data, id = '0') {
      const fa = (parentId) => {
        const temp = []
        for (let i = 0; i < data.length; i++) {
          const n = data[i]
          if (n[this.props.parent] === parentId) {
            n.children = fa(n.id)
            temp.push(n)
          }
        }
        return temp
      }
      return fa(id)
    },
    // 清除空 children项
    cleanChildren(data) {
      const fa = (list) => {
        list.map((e) => {
          if (e.children.length) {
            fa(e.children)
          } else {
            delete e.children
          }
          return e
        })
        return list
      }
      return fa(data)
    }
  }
}
</script>

<style>
  .el-input.el-input--suffix {
    cursor: pointer;
    overflow: hidden;
  }

  .el-input.el-input--suffix.rotate .el-input__suffix {
    transform: rotate(180deg);
  }

  .select-tree {
    max-height: 350px;
    overflow-y: scroll;
  }

  /* 菜单滚动条 */
  .select-tree::-webkit-scrollbar {
    z-index: 11;
    width: 6px;
  }

  .select-tree::-webkit-scrollbar-track,
  .select-tree::-webkit-scrollbar-corner {
    background: #fff;
  }

  .select-tree::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 6px;
    background: #b4bccc;
  }

  .select-tree::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 6px;
  }
</style>
