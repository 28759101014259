import request from '@/router/axios';
const urlPrefix = ''

/**
 * 获取批次信息
 * @param obj
 */
export  function getContractPage(obj) {
  return request({
    url:urlPrefix+ '/contract/listPage',
    method: 'post',
    data: obj
  })
}

/**
 * 获取批次信息
 * @param obj
 */
export  function deleteContract(id) {
  return request({
    url:urlPrefix+ '/contract/'+id,
    method: 'delete'
  })
}

/**
 * 创建合同
 * @param obj
 */
export  function addContract(obj) {
  return request({
    url:urlPrefix+ '/contract',
    method: 'post',
    data: obj
  })
}

/**
 * 修改合同
 * @param obj
 */
export  function updateContract(id,obj) {
  return request({
    url:urlPrefix+ '/contract/'+id,
    method: 'put',
    data: obj
  })
}

/**
 * 创建合同
 * @param obj
 */
export  function getContract(id) {
  return request({
    url:urlPrefix+ '/contract/'+id,
    method: 'get'
  })
}


/**
 * 下载标准合同
 * @param obj
 */
export  function downloadStandardCont(obj) {
  return request({
    url:urlPrefix+ '/contract/download/standard',
    method: 'post',
    data: obj,
    responseType: 'arraybuffer',
  })
}

/**
 * 下载客户生成的合同
 * @param obj
 */
export  function downloadCusCont(obj) {
  return request({
    url:urlPrefix+ '/contract/file/download/cus/'+obj,
    method: 'get',
    //data: obj,
    responseType: 'arraybuffer',
  })
}



/**
 * 下载客户生成的合同
 * @param obj
 */
export  function downloadCont(obj) {
  return request({
    url:urlPrefix+ '/vx/contracttempl/file/'+obj,
    method: 'get',
    //data: obj,
    responseType: 'arraybuffer',
  })
}

