<template>
	<el-form ref="policyInfo" :model="policyInfo" :rules="listRules" label-width="120px">
		<div class="editForm">
			<el-row>
				<el-col :span="12">
					<el-form-item label="政策标题" prop="title">
						<el-input v-model.trim="policyInfo.title" />
					</el-form-item>
				</el-col>

				<el-col :span="24" :style="{height:uploadTipHeight}">
					<div class="uploadTip">
						<el-form-item label="标题图片" prop="titleImg">
							<el-upload
								class="avatar-uploader"
								action="/api/file/upload"
								:show-file-list="false"
								:on-success="handleAvatarSuccess"
								:before-upload="beforeAvatarUpload"
								name="uploadFile"
								:headers="headers"
                accept=".jpg,.png"
							>
								<img v-if="imageUrl" :src="imageUrl" class="avatar" style="height: 125px; width:345px ;" />
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							<p style="color: red;">* 最好上传图片的为345*125或该比例的(仅支持.jpg,.png)</p>
						</el-form-item>
					</div>
				</el-col>

				<el-col :span="12">
					<el-form-item label="政策名称" prop="policyName">
						<el-input v-model.trim="policyInfo.policyName" />
					</el-form-item>
				</el-col>

				<el-col :span="12">
					<el-form-item label="内容简介" prop="policyIntroduce">
						<el-input v-model.trim="listData.policyIntroduce" />
						<!-- <bsp-dicts-select v-model="listData.policyIntroduce" dic-num="3009"  ></bsp-dicts-select> -->
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="政策状态" props="policyStatus">
						<bsp-dicts-select
							v-model="policyInfo.policyStatus"
							dic-num="3016"
							:select-value.sync="policyInfo.policyStatus"
						></bsp-dicts-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="政策发布时间" prop="polcyCreateTime">
						<el-date-picker
							v-model="policyInfo.polcyCreateTime"
							type="date"
							format="yyyy-MM-dd"
							value-format="yyyy-MM-dd"
							placeholder="选择日期"
						></el-date-picker>
					</el-form-item>
				</el-col>

				<el-col :span="12">
					<el-form-item label="是否置顶" prop="top">
						<bsp-dicts-select v-model="policyInfo.top" dic-num="0090" :select-value.sync="policyInfo.top"></bsp-dicts-select>
					</el-form-item>
				</el-col>

				<el-col :span="24">
					<el-form-item label="政策内容" prop="policyContent" style="height: 500px;">
						<ciic-tinymce-editor
							flag="policyContent"
							tinymceId="policyContent"
							:value.sync="policyInfo.policyContent"
							height="500"
						></ciic-tinymce-editor>
						<!-- <el-select clearable v-model="listData.ascriptionPer" placeholder="请选择" disabled="true">
              <el-option
                v-for="item in ascriptionList"
                :key="item.id"
                :value="item.id"
                :label="item.ascriptionName"
              >
              </el-option>
						</el-select>-->
					</el-form-item>
				</el-col>
			</el-row>
		</div>
	</el-form>
</template>

<script>
import { isvalidatemobile, validateEmail } from '@/util/validate'
import bspDictsSelect from '@/components/bsp/bsp-dicts-select/bsp-dicts-select'
import ciicTinymceEditor from '@/components/ciic/ciic-tinymce-editor/ciic-tinymce-editor'
import { checkCusName } from '@/api/cus/leads'
import { mapGetters } from 'vuex'
export default {
	components: {
		bspDictsSelect,
		ciicTinymceEditor
	},
	computed: {
		...mapGetters(['userInfo'])
	},
	name: 'addPolicy',
	props: {
		listData: {
			type: Object,
			default: () => {
				{
				}
			}
		}
	},
	computed: {
		headers() {
			return {
				Authorization: `Bearer ${this.accessToken}`
			}
		},
		...mapGetters(['accessToken'])
	},
	watch: {
		listData: {
			handler(newValue, oldeValue) {
				console.log('得到传来的信息222', newValue, this.listData)
				if (this.listData.titleImg) {
					this.imageUrl = '/api/file/' + this.listData.titleImg
				}
				console.log('this.imageUrl1111', this.imageUrl)
				this.$forceUpdate()
			},
			immediate: true,
			deep: true
		}
	},
	data() {
		return {
			imageUrl: '',
			policyInfo: {
				title: '',
				titleImg: '',
				titleImgPath: '',
				policyName: '',
				policyIntroduce: '',
				policyStatus: '',
				policyContent: '',
				policyStatus: '3016000063',
				top: '00900',
				polcyCreateTime: new Date()
			},
			// 校验规则
			listRules: {
				title: [
					{
						required: true,
						message: '标题不能为空!',
						trigger: 'blur'
					}
				],
				policyName: [
					{
						required: true,
						message: '政策名称不能为空!',
						trigger: 'blur'
					}
				],
				policyStatus: [
					{
						required: true,
						message: '政策状态不能为空!',
						trigger: 'blur'
					}
				],
				polcyCreateTime: [
					{
						required: true,
						message: '政策发布时间不能为空!',
						trigger: 'blur'
					}
				],
				top: [
					{
						required: true,
						message: '是否置顶不能为空!',
						trigger: 'change'
					}
				]
			},
      uploadTipHeight:"30px"
		}
	},
	created() {
		console.log('当前用户4444444信息', this.listData)
		this.policyInfo = this.listData
		console.log('this.imageUrl1113333333333333331', this.imageUrl)
    if(this.imageUrl){
      this.uploadTipHeight = "135px"
    }
	},
	methods: {
		//上传成功后的处理
		handleAvatarSuccess(res, file) {
			console.log('res', res)
      if(res.data.success=="false"){
        this.$message.error(res.data.msg);
      }
			this.listData.titleImg = res.data.id
			this.listData.titleImgPath = res.data.filePath

			console.log('filefileres', file)
			this.imageUrl = '/api/file/' + res.data.id // URL.createObjectURL(file.raw)
      this.uploadTipHeight = "135px"
		},
		// 上传前的处理
		beforeAvatarUpload(file) {
			const img = '.jpg|.jpeg|.png|'
			const isLt2M = file.size / 1024 / 1024 < 2
			const extName = file.name
				.substring(file.name.lastIndexOf('.'))
				.toLowerCase()
			if (img.indexOf(extName + '|') == '-1') {
				this.$message.error('上传图片只能是 .jpg|.jpeg|.png| 格式!')
				return false
			}
			if (!isLt2M) {
				this.$message.error('上传图片大小不能超过 2MB!')
				return false
			}
		}
	}
}
</script>

<style scoped>
</style>
