<template>
  <div>
    <template>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="代发薪大库报税" name="31278300703"></el-tab-pane>
        <el-tab-pane label="代发薪客户名义报税" name="31278300704"></el-tab-pane>
      </el-tabs>
    </template>
    <template>
      <div v-if="isBranch">
        <el-form :inline="true" :model="listQuery" class="editForm">
          <el-form-item label="状态" style="margin-right: 50px">
             <el-select class="status-select" v-model="listQuery.status"  @change="loadList()">
                <el-option label="未提交" value="0"></el-option>
                <el-option label="已提交" value="1"></el-option>
                <el-option label="已发放" value="2"></el-option>
             </el-select>
          </el-form-item>

          <el-form-item style="float: right;">
            <el-button v-if="listQuery.status === '0' && !this.isFinance" @click="updateBankInfo" size="medium" icon="el-icon-position" >
              同步雇员银行卡信息
            </el-button>
          </el-form-item>

          <el-form-item style="float: right;">
            <el-button v-if="listQuery.status === '0' && !this.isFinance" @click="commitRTicket" size="medium" icon="el-icon-position" >
              提交
            </el-button>
          </el-form-item>
          <el-form-item style="float: right;">
            <el-button v-if="listQuery.status === '0' && !this.isFinance" @click="saveRTicket" size="medium" icon="el-icon-s-claim" >
              保存
            </el-button>
          </el-form-item>
          <el-form-item style="float: right;">
            <el-button v-if="listQuery.status === '0' && !this.isFinance" @click="exportRTicket" size="medium" icon="el-icon-upload2">
              导出
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <bsp-table
      v-if="refresh"
      ref="publicTable"
      :data.sync="list"
      :tree-config="true"
      @select-change="selectChange"
      @select-all="selectChange"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="loadList"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
      height="88%"
    >
      <vxe-table-column type="selection" width="60" fixed="left"/>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <div v-if="!isBranch">
        <bsp-table-column title="支出单编号" field="pubPayoutBillsCode" filter-type="input"  width="200">
          <template slot-scope="scope"><span>{{ scope.row.pubPayoutBillsCode}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="客户名称" field="cusName" filter-type="input" set-id='0100' width="200">
          <template slot-scope="scope"><span>{{ scope.row.cusName }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员名称" field="empName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{ scope.row.empName }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="证件类型" field="empCertifType" width="120" >
          <template slot-scope="scope"><span>{{ scope.row.empCertifType | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="证件号码" field="empCertifCode" width="150">
          <template slot-scope="scope"><span>{{ scope.row.empCertifCode }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开会行全称" field="bankName" filter-type="input" width="150">
          <template slot-scope="scope"><span>{{ scope.row.bankName }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户名" field="bankEmpName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{ scope.row.bankEmpName }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户地区" field="bankArea" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{ scope.row.bankArea }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="银行账号" field="bankAccount" filter-type="input" width="150">
          <template slot-scope="scope"><span>{{ scope.row.bankAccount }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="状态" field="status" width="80">
          <template slot-scope="scope"><span>{{ scope.row.status }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="备注" field="remarks" width="150">
          <template slot-scope="scope"><span>{{ scope.row.remarks }}</span></template>
        </bsp-table-column>
      </div>

      <div v-if="isBranch">
        <bsp-table-column title="合作机构" field="orgName"  width="150">
          <template slot-scope="scope"><span>{{ scope.row.orgName }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="客户名称" field="cusName"  width="150">
          <template slot-scope="scope"><span>{{ scope.row.cusName }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="退票时间" field="refundTime"  width="120">
          <template slot-scope="scope"><span>{{ scope.row.refundTime }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员名称" field="empName"  width="100">
          <template slot-scope="scope"><span>{{ scope.row.empName }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="证件类型" field="empCertifType" width="120">
          <template slot-scope="scope"><span>{{ scope.row.empCertifType | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="证件号码" field="empCertifCode" width="160">
          <template slot-scope="scope"><span>{{ scope.row.empCertifCode }}</span></template>
        </bsp-table-column>

        <div v-if="!isFinance">
          <bsp-table-column title="银行账号" field="bankAccount" width="160" :edit-render="{name: 'input'}">
            <template slot-scope="scope"><span>{{ scope.row.bankAccount }}</span></template>
          </bsp-table-column>
          <bsp-table-column title="开户行全称" field="bankName" :edit-render="{name: 'input'}" width="150">
            <template slot-scope="scope"><span>{{ scope.row.bankName }}</span></template>
          </bsp-table-column>
          <bsp-table-column title="开户名" field="bankEmpName" width="100" :edit-render="{name: 'input'}">
            <template slot-scope="scope"><span>{{ scope.row.bankEmpName }}</span></template>
          </bsp-table-column>
          <bsp-table-column title="开户地区" field="bankArea" width="100" :edit-render="{name: 'input'}">
            <template slot-scope="scope"><span>{{ scope.row.bankArea }}</span></template>
          </bsp-table-column>
        </div>
        <div v-else>
          <bsp-table-column title="银行账号" field="bankAccount" width="160">
            <template slot-scope="scope"><span>{{ scope.row.bankAccount }}</span></template>
          </bsp-table-column>
          <bsp-table-column title="开户行全称" field="bankName" width="150">
            <template slot-scope="scope"><span>{{ scope.row.bankName }}</span></template>
          </bsp-table-column>
          <bsp-table-column title="开户名" field="bankEmpName" width="100" >
            <template slot-scope="scope"><span>{{ scope.row.bankEmpName }}</span></template>
          </bsp-table-column>
          <bsp-table-column title="开户地区" field="bankArea" width="100" >
            <template slot-scope="scope"><span>{{ scope.row.bankArea }}</span></template>
          </bsp-table-column>
        </div>
        <bsp-table-column title="状态" field="status" width="100">
          <template slot-scope="scope"><span>{{ scope.row.status }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="导入批次号" field="batchCode" width="150">
          <template slot-scope="scope"><span>{{ scope.row.batchCode }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="备注" field="remarks" width="150">
          <template slot-scope="scope"><span>{{ scope.row.remarks }}</span></template>
        </bsp-table-column>
      </div>
    </bsp-table>
  </div>
</template>

<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {searchCiicRTicketList,searchBranchList,upadteRTicket,commitReturndTicket,exportReturndTicket,updateBankInfo} from '@/api/entrust/packedRTicket';
  import {mapGetters} from 'vuex';

  export default {
    name: "mitationLogin",
    inject: ['reload'],
    components: {
      BspTable,
      BspTableColumn
    },
    computed: {
      ...mapGetters([
        'dicts', "userInfo"
      ]),
    },
    created() {
      this.isBranch = this.userInfo.userType  !== '1';
      let wtRefudInquiry = this.userInfo.onlyLookAuthorMap.WTRefundInquiry;
      this.isFinance =  wtRefudInquiry != null &&  wtRefudInquiry != 0;
      // console.log(this.isFinance);
      this.loadList();
    },

    data() {
      return {
        // 用于接收后台返回的分页数据
        activeName: '31278300703',
        loading: false,
        isBranch: false, //是否分支机构
        isFinance: false, //是否财务账号
        list: [],
        //分页查询对象
        listQuery: {
          limit: 20,
          page: 1,
          total: 0,
          bizType:"",
          status:"0"
        },
        refresh: true,
        //多选数组
        multipleSelection:[]
      }
    },

    methods: {
      handleClick(tab, event) {
        this.refresh = false;
        this.loadList();
      },
      loadList() {
        this.multipleSelection = []
        this.loading = true;
        this.listQuery.bizType = this.activeName;
        if (this.isBranch) {
          // this.listQuery.status = this.status;
          searchBranchList(this.listQuery).then(res => {
            this.list = res.data.data.records;
            this.listQuery.total = res.data.data.total
            this.refresh = true;
            this.loading = false;
          })
        } else {
          searchCiicRTicketList(this.listQuery).then(res => {
            this.list = res.data.data.records;
            this.listQuery.total = res.data.data.total;
            this.refresh = true;
            this.loading = false;
          })
        }
      },
      // 点选/全选复选框
      selectChange(checked) {
        if (checked.checked) {
          const list = checked.selection;
          this.multipleSelection = [];
          for (let index = 0; index < list.length; index++) {
            const element = list[index];
            this.multipleSelection.push(element);
          }
        } else {
          this.multipleSelection = [];
        }
      },
      searchEmp(cusId) {
        this.$router.push({path: '/cus/empList', query: {cusId: cusId}})
      },
      saveRTicket(){
        // console.log("查看编辑后的选择数据=======>",this.multipleSelection);
        if (this.multipleSelection.length === 0) {
          this.$message.warning('请至少选择一条数据！');
        } else {
          this.loading = true;
          const data = this.multipleSelection;
          upadteRTicket(data).then(res => {
             console.log("接口调用结果====>",res);
              if (res.data.success == true) {
                this.$message.success('保存成功');
                this.loading = false;
              } else {
                this.$message.error("保存失败," + res.data.msg);
                this.loading = false;
              }
              this.loadList();
          })
        }
      },
      commitRTicket(){
        if (this.multipleSelection.length === 0) {
          this.$message.warning('请至少选择一条数据！');
        } else {
          this.loading = true;
          const data = this.multipleSelection;
          commitReturndTicket(data).then(res => {
             console.log("接口调用结果====>",res);
              if (res.data.success == true) {
                this.$message.success('提交成功');
                this.loading = false;
              } else {
                this.$message.error("提交失败" + res.data.msg);
                this.loading = false;
              }
              // this.__queryPendingSubsidies();
              this.loadList();
          })
        }
      },
      updateBankInfo(){
        if (this.multipleSelection.length === 0) {
          this.$message.warning('请至少选择一条数据！');
        } else {
          this.loading = true;
          const data = this.multipleSelection;
          updateBankInfo(data).then(res => {
            console.log("接口调用结果====>",res);
            if (res.data.success == true) {
              if(res.data.msg){
                this.$message.info(res.data.msg);
              }else{
                this.$message.success('同步成功');
              }
              this.loading = false;
            } else {
              this.$message.error("同步失败" + res.data.msg);
              this.loading = false;
            }
            // this.__queryPendingSubsidies();
            this.loadList();
          })
        }
      },

      exportRTicket(){
        if (this.multipleSelection.length === 0) {
          this.$message.warning('请选择要导出的未提交批次！');
        } else {
          this.loading = true;
          const data = this.multipleSelection;
          exportReturndTicket(data).then(res => {

              const url = window.URL.createObjectURL(res.data);
              const link = document.createElement('a');
              link.href = url;
              const fileName = `${res.headers['content-disposition'].replace('attachment;filename=','')}`;
              let channelUrl=decodeURIComponent(fileName);
              link.setAttribute('download', channelUrl);
              link.click()
              this.loading = false;
          })
        }
      }
    }
  }
</script>

<style scoped>

</style>
