<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right" @click="batchImport()">批量导入</el-button>
    </div>

    <bsp-table
      :data.sync="list"
      :tree-config="true"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="loadEmpList"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
    >

      <bsp-table-column title="雇员姓名" field="empName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.empName}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="雇员证件号" field="idCard" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.idCard}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="所属客户名称" field="cusName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="年份" field="payYear" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.payYear}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="是否启用预扣预缴" field="flag" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.flag}}</span></template>
      </bsp-table-column>
<!--      <bsp-table-column  title="操作" fixed="right" align="center">-->
<!--        <template slot-scope="scope">-->
<!--          <div class="ciic-table-operate">-->
<!--            <el-button  type="text" @click="searchDetailInfo(scope.row.empId)" >查看</el-button>-->
<!--          </div>-->
<!--        </template>-->
<!--      </bsp-table-column>-->
    </bsp-table>
  </div>
</template>

<script>
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {getEmpList} from '@/api/cus/ykyj';
  import {mapGetters} from 'vuex';
    export default {
      name: "ykyjList",
      inject: ['reload'],
      components: {
        BspTable,
        BspTableColumn,
      },
      computed: {
        ...mapGetters([
          'dicts'
        ]),
      },
      props: {
        currentTab: {
          type: String,
          default: ''
        },
      },
      created() {
        this.loadEmpList();
      },
      data() {
        return {
          list: [],
          //分页查询对象
          listQuery: {
            limit: 50,
            page: 1,
            total: 0,
            status:'0',
            flag: '0'
          },
        }
      },
      methods: {
        loadEmpList() {
          getEmpList(this.listQuery).then(res => {
            if (res.status !== 200) {
              alert("请求失败！");
              return;
            }
            if (res.data.code !== 0) {
              alert(res.data.msg);
              return;
            }
            this.list = res.data.data.records;
            this.listQuery.total = res.data.data.total;
          })
        },

        batchImport(){
          // if (confirm('大库报税员工适用6万规则需要提供发放说明，如有问题请与薪税公司业务员联系。')) {
          //   this.$router.push({path: '/ykyj/ykyjImport'});
          // }

          const tipInfos = [];
          const h = this.$createElement;
          tipInfos.push(h('b', { style: 'color: #FE2B22' }, '大库报税员工适用6万规则需要提供发放说明，如有问题请与薪税公司业务员联系。'));
            this.$msgbox({
              dangerouslyUseHTMLString: true,
              message: h('p', null, tipInfos),
              showCancelButton: true,
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(action => {
              this.$router.push({path: '/ykyj/ykyjImport'});
            })
        }
      }
    }
</script>

<style scoped>

</style>
