<template>
  <div class="ciic-tables-operate">
    <el-button size='mini' type="primary" class="singleButton" v-if="opetatAuthorization.length === 1"  @click="btnsEvent({id:opetatAuthorization[0],row:tableRowData})">
      {{operationButton[opetatAuthorization[0]]}}</el-button>
    <el-dropdown v-if="opetatAuthorization.length >1" size='mini'
                 @click="btnsEvent({id:opetatAuthorization[0],row:tableRowData})" type="primary" plain split-button @command="btnsEvent">
      {{operationButton[opetatAuthorization[0]]}}
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="(menu,index) in subOperationArr" :key="index" :command="{id:menu,row:tableRowData}" >{{operationButton[menu]}}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
  export default {
    name: "ciicTablesOperate",
    props:{
      tableRowData: Object,
      operationButton: Object,
      opetatAuthorization: Array
    },
    computed: {
      subOperationArr() {
        return this.opetatAuthorization.slice(1)
      }
    },
    created(){
    },
    methods:{
      btnsEvent(comand){
        this.$emit('handleCommand', comand)
      },
    }
  }
</script>

<style lang="scss">
  .ciic-tables-operate {
    .el-button-group .el-button--primary:first-child{
      width: 80px;
    }
    .singleButton {
      width: 80px;
    }
  }


  /*.ciic-tables-operate .el-dropdown .el-dropdown__caret-button::before {*/
  /*  content: '';*/
  /*  position: absolute;*/
  /*  display: block;*/
  /*  width: 0px;*/
  /*  top: 5px;*/
  /*  bottom: 5px;*/
  /*  left: 0;*/
  /*  background: rgba(255,255,255,.5);*/
  /*}*/
</style>
