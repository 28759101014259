<template>
    <div class="newLogin">

        <img class="logo-form" src="../../assets/img/login-formbg.png" alt="">

<div class="logo-form">
  <img class="logo" src="../../assets/img/logo-new2.png" alt="">
  <img class="logo-form1" src="../../assets/img/login-formbg.png" alt="" style="visibility: hidden">
  <div   id="box2">
    <h2>请登录您的账户</h2>
    <p>Login to access your account</p>
    <pwd-login :loginMethod.sync="loginMethod"></pwd-login>
  </div>
</div>

			<img class="bg" src="../../assets/img/login-bg.png" alt="">

    <!--  <div  class="login-top">
        <div class="top-logo">
          <el-row>
            <el-col :span="8">&nbsp;</el-col>
            <el-col :span="8">
              <el-row>
                <el-col style="margin-top: 10px" :span="24">
                 <div class="animated fadeInRight welcome">欢迎登录</div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="8">&nbsp;</el-col>
          </el-row>
        </div>
      </div>
      <div class="login-main animated fadeInRight" >
        <el-row>
          <el-col :span="8">&nbsp;</el-col>
          <el-col :span="8">
            <div class="login-box">
              <div class="login-left">
                <div v-if="loginMethod =='code'" class="animated fadeIn" key="box1">
                  <code-login :loginMethod.sync="loginMethod" ></code-login>
                </div>
                <div v-else key="box2" class="animated fadeIn">
                  <pwd-login :loginMethod.sync="loginMethod"></pwd-login>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">&nbsp;</el-col>
        </el-row>
      </div>
    -->
		</div>
</template>

<script>
	import {setRem} from '@/util/commen.js'
  import pwdLogin from './pwdlogin'

  export default {
    name: 'codelogin',
    components: {pwdLogin},
    data() {
      return {
        loginMethod: 'code'
      };
    },
    created() {
			setRem();
    },
    mounted() {},
    computed: {
    },
    props: [],
    methods: {
    },
  };
</script>
<style scoped>
  body{
    background: #ffffff!important;
  }
  .newLogin{
    background: #ffffff;
    height: 100%;
  }
  .newLogin >>> .el-input .el-input__inner{
    color:#606266!important;
  }
	.bg{
		position: fixed;
		/*right: 0;*/
		/*bottom: 0;*/
		height: 100%;
    width: 100%;
		/*margin: 0;*/
	}
	.logo-form{
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: 0.725rem;
    margin-top: 3.5%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 99;
    width: 17.60rem;
	}
  .logo-form1{
    width: 17.6rem;
  }
  .logo{
    position: absolute;
    left: 0.6rem;
    top: 0.54rem;
    z-index: 9999;
    width: 4.41rem;
  }
	#box2{
    position: absolute;
    left: 0.7rem;
    top: 2rem;
    z-index: 9999;
	}
	#box2 h2{
    font-size: .40rem;
    font-weight: 500;
    color: #5A6096;
    /*margin-bottom: .16rem;*/
	}
	#box2 p{
		font-size:.24rem;
		font-weight:400;
		color:#646363;
		}

</style>
<!-- <style lang="scss">
  .newLogin{
    height: 100%;
    .login-top{
      height: 40%;
      background: #1ea0ff;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      .top-logo{
        color: #fff;
        font-size: 20px;

      }
    }
    .login-box{
      display: flex;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      padding: 50px 40px;
      box-sizing: border-box;
      margin-top: -18%;
      background-color: #fff;
      z-index: 5;
      .login-left{
        flex: 1;
        box-sizing: border-box;
        .el-input__inner{
          background-color: #FAFAFA !important;
          height: 50px;
          line-height: 50px;
        }
        .el-input-group__append{
          background-color: #008cc9;
          color: #fff;
        }
        .change-method{
          color: #008cc9;
          text-align: right;
          font-size: 13px;
          font-weight: 500;
          margin-bottom: 40px;
          cursor: pointer;
        }
        .el-button--primary{
          width: 100%;
          height: 50px;
          font-size: 16px;
          background-color: #008cc9;
        }
        .agreement{
          color: #008cc9;
          font-size: 13px;
          text-align: center;
        }
        .fade-enter, .fade-leave-to {
          opacity: 0
        }
        .fade-leave, .fade-enter-to {
          opacity: 1
        }
        .fade-enter-active, .fade-leave-active {
          transition: all .2s
        }
      }
      .login-right{
        flex: 1;
        padding-left: 50px;
        box-sizing: border-box;
        .title{
          color: #1ea0ff;
          font-size: 20px;
          letter-spacing: 3px;
          font-weight: 600;
          margin-bottom: 20px;
        }
        .attract{
          display: flex;
          align-items: center;
          color: #5E5E5E;
          margin-bottom: 25px;
          span{
            line-height: 25px;
          }
          .icon{
            font-size: 30px;
            margin-right: 20px;
          }
          .icon-zhilianganquan{
            color: #DE6266;
          }
          .icon-jingzhunpipei{
            color: #FB8524;
          }
          .icon-kuaijiejiancha{
            color: #1EB5FE;
          }
        }
        .connect{
          color: #c0c0c0;
          font-size: 13px;
          line-height: 30px;
          display: flex;
          align-items: center;
          margin-top: 30px;
          .phone-number{
            color: #fb7b11;
            font-size: 30px;
            font-weight: 600;
            margin-left: 10px;
          }
        }

      }
    }
  }

</style> -->
