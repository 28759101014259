<template>
  <el-row class="ciicFormContain">
    <el-col>
        <el-form ref="form"  class="editFrom" :model="form" label-width="170px">
          <el-form-item label='单行文本框' >
              <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label='密码框'>
              <el-input type="password" v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item label='单选'>
              <el-radio-group v-model="form.resource" size="medium">
              <el-radio  label="线上品牌商赞助"></el-radio>
              <el-radio  label="线下场地免费"></el-radio>
              </el-radio-group>
          </el-form-item>
          <el-form-item label='多选2'>
              <el-checkbox-group v-model="form.type">
              <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
              <el-checkbox label="地推活动" name="type"></el-checkbox>
              <el-checkbox label="线下主题活动" name="type"></el-checkbox>
              <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
              </el-checkbox-group>
          </el-form-item>
          <el-form-item label='下拉文本框1'>
              <!-- 这边用v-if是因为等数据加载完后再去渲染这个组件，否则serverDictsList获取不到 -->
              <bsp-dicts-select v-if="serverDictsList.length>0" :optionsList="serverDictsList" ></bsp-dicts-select>
          </el-form-item>
          <el-form-item label='下拉文本框2'>
            <bsp-dicts-select @selectChange="selectChange" dic-num="111" remNum="1110001" filterable ></bsp-dicts-select>
          </el-form-item>
          <el-form-item label='日期控件1'>
             <el-col :span="11">
              <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
              </el-col>
              <el-col class="line center" :span="2">-</el-col>
              <el-col :span="11">
              <el-time-picker placeholder="选择时间" v-model="form.date2" style="width: 100%;"></el-time-picker>
              </el-col>
          </el-form-item>
          <el-form-item label='日期时间选择器'>
              <el-date-picker
              v-model="form.date3"
              type="datetime"
              placeholder="选择日期时间">
              </el-date-picker>
          </el-form-item>
       </el-form>
    </el-col>
    <h2>表单验证</h2>
    <el-row>
      <el-col>
        <el-form ref="form2" :span="12" class="editFrom" :rules="formRules" :model="validateForm" label-width="120px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名:" prop="name">
                <el-input v-model="validateForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号:" prop="phone">
                <el-input v-model="validateForm.phone" type="number"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="密码:" prop="password">
                <el-input v-model="validateForm.password"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="确认密码:" prop="repassword">
                <el-input v-model="validateForm.repassword"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="身份证号:" prop="idCard">
                <el-input v-model="validateForm.idCard"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证件类型:">
                <bsp-dicts-select v-model="validateForm.idType" dic-num="0034"  remNum="00340004,00340001"></bsp-dicts-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <el-row >
        <el-col :span="12" :offset="6">
          <div class="center marginTop20">
            <el-button type="primary" @click="submitHandler" size="mini">提交</el-button>
          </div>
        </el-col>
    </el-row>

  </el-row>

</template>
<script>
import bspDictsSelect from "@/components/bsp/bsp-dicts-select/bsp-dicts-select";
import { isvalidatemobile, cardid } from '@/util/validate';
import {getServerDictsList} from '@/api/sys/code'
  export default {
    created(){
        this.getServerDictsList()
    },
    data() {
      const validatePass2 = (rule, value, callback) => {
          if (!value) {
              callback(new Error('请再次输入密码'))
          } else if (value !== this.validateForm.password) {
              callback(new Error('两次输入密码不一致!'))
          } else {
              callback()
          }
      }
      const validatePhone = (rule, value, callback) => {
            if (isvalidatemobile(value)[0]) {
                callback(new Error(isvalidatemobile(value)[1]));
            } else {
                callback();
            }
      };
      const validateIdcard = (rule, value, callback) => {
          if(cardid(value)[0]) {
              callback(new Error(cardid(value)[1]));
          } else {
              callback();
          }
      }
      return {
        serverDictsList: [],
        form: {
          name: '',
          password: '',
          date1: '',
          date2: '',
          date3:'',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
            value1:'2019'
        },
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
      },
        validateForm:{},
        formRules: {
            name: [
                { required: true, message: '请输入用户名', trigger: 'blur' },
            ],
            password: [
                { required: true, message: '请输入密码', trigger: 'blur' },
                { min: 6, message: '密码长度最少为6位', trigger: 'blur' },
                {pattern: '', message: ''}  //pattern里可以写一些正则表达式，message是一些提示的消息
            ],
            repassword: [
                { required: true, validator: validatePass2, trigger: 'blur' }
            ],
            phone: [
                {required: true, validator: validatePhone, trigger: 'blur' }
            ],
            idCard: [
                {required: true, validator: validateIdcard, trigger: 'blur'}
            ]
        }
      }
    },
    components:{
        bspDictsSelect
    },
    methods: {
        submitHandler(){
            this.$refs.form2.validate((valid) => {
                //做一些数据提交处理
              this.$alert("保存成功", "提示", {
                confirmButtonText: '确定',
              })
            })
        },
        selectChange(val){
            console.log(val)
        },
        getServerDictsList(){
            getServerDictsList().then( res => {
                console.log(res)
                this.serverDictsList = res.data.data['3048']
            })
        },
    }
  }
</script>
