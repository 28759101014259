import request from '@/router/axios';
const urlPrefix = ''

/**
 * 获取计税区域
 * @param obj
 */
export function getTaxAreaList(obj) {
  return request({
    url:urlPrefix+ '/orgTaxArea/listPage',
    method: 'post',
    data: obj
  })
}


/**
 * 设置启用禁用
 * @param obj
 */
export function settingStatus(obj) {
  return request({
    url:urlPrefix+ '/orgTaxArea/settingStatus',
    method: 'post',
    data: obj
  })
}
