<template>
  <div class="batchBox">
    <div class="backBox">
<!--      <span v-if="activeIndex === 1 || activeIndex === 2" class="goback" @click="goback">-->
<!--        <svg class="icon" aria-hidden="true">-->
<!--          <use xlink:href="#icon-fanhui"></use>-->
<!--        </svg>-->
<!--        返回</span>-->
      <span class="goback" @click="goToTable">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-fanhui"></use>
        </svg>
        返回</span>
      <span v-if="activeIndex === 3" class="goback" @click="goToTable"><span class="iconfont">&#xe602;</span>完成</span>
    </div>
    <div class="batchBoxTitle">{{ title }}</div>
    <div class="batchSteps">
      <div :class="activeIndex >= 0? 'active':''" class="batchStep">
        <div class="stepIndex">1</div>
        <span>上传文件</span>
      </div>
      <div :class="activeIndex > 0? 'active':''" class="batchStep">
        <div class="stepIndex">2</div>
        <span>导入完成</span>
      </div>
    </div>
    <step1 v-if="activeIndex === 0" :type-id="typeId" @onupload="addstep"/>
    <step2 v-else-if="activeIndex === 1" @next="addstep" />
    <step3 v-else-if="activeIndex === 2" @next="goToTable"/>
  </div>
</template>
<script>
import step1 from './step1'
import step2 from './step2'
export default {
  components: { step1, step2 },
  data() {
    return {
      activeIndex: 0,
      typeId: '',
      title: '导入人员'
    }
  },
  mounted() {
    // 加载参数
    //this.typeId = this.$route.params.typeId
    //console.log(this.typeId)
    //if (this.typeId === '1') {
      //this.title = '批量增员'
   // } else {
      //this.title = ''
    //}
  },
  methods: {
    // 下一步
    addstep() {
      this.activeIndex = this.activeIndex + 1
    },
    goback() {
      this.activeIndex = this.activeIndex - 1
    },
    goToTable() {
      // 返回数据页面
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped  rel="stylesheet/scss">
  .backBox{
    /*margin-bottom: 20px;*/
  }
  .goback{
    width: 70px;
    padding:8px;
    border:1px solid #cccccc;
    color:#cccccc;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    line-height: 16px;
    font-size: 14px;
    cursor: pointer;
  }
  .goback:hover{
    border:1px solid #269cff;
    color:#269cff;
  }
  .batchBoxTitle{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
  }

</style>
