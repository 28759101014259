import request from '@/router/axios';
const urlPrefix = ''

/**
 * 获取批次信息
 * @param obj
 */
export  function getSocialSecurityBath(obj) {
  return request({
    url:urlPrefix+ '/socialSecurityBatch/listPage',
    method: 'post',
    data: obj
  })
}

export  function deleteSocialSecurityBatch(obj) {
  return request({
    url:urlPrefix+ '/socialSecurityBatch/deletes',
    method: 'post',
    data: obj
  })
}

export  function importSocialSecurity(obj) {
  return request({
    url:urlPrefix+ '/socialSecurityImport',
    method: 'post',
    data: obj
  })
}

/**
 * 获取社保信息
 * @param obj
 */
export  function getSocialSecurity(obj) {
  return request({
    url: urlPrefix + '/socialSecurity/listPage',
    method: 'post',
    data: obj
  })
}

export  function downloadSocialSecurity() {
  return request({
    url: urlPrefix + '/socialSecurityImport/downTemplate',
    method: 'get',
    responseType: 'arraybuffer'
  })
}

export  function copySocialSecurity(obj) {
  return request({
    url: urlPrefix + '/socialSecurityImport/copySocialSecurity',
    method: 'post',
    data: obj
  })
}






