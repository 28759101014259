<template>
  <div class="outer-layer">
    <bsp-tabs :tabList="tabList" :tabIndex="tabIndex" @changeTab="changeTab">
      <component v-if="isRouterAlive" ref="pub" :is="currentTab" :currentTab='currentTab'
                 :batchStatus="batchStatus"></component>
    </bsp-tabs>
  </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import DistributionBatchList from "@/views/entrust/distributionbatch/distributionBatchList.vue";
    import bspTabs from "@/components/bsp/bsp-tabs/bsp-tabs";

    export default {
        name: "distributionBatchIndex",
        components: {
            DistributionBatchList,
            bspTabs,
            31278300701: DistributionBatchList,
            31278300702: DistributionBatchList,
            31278300703: DistributionBatchList,
            31278300704: DistributionBatchList,
            31278300707: DistributionBatchList,
        },
        computed: {
            ...mapGetters(['userInfo']),
        },
        created() {
            console.log("当前用户 机构id：" + this.userInfo.orgId);
            //TODO 此处增加对业务类型显示 测试
            //if(this.userInfo.orgId=="1060352" || this.userInfo.orgId=="297ecd7072e4c4280172e4e2534a000b" || this.userInfo.orgId=="1060348"){
            if (this.userInfo.userType == '2') {
                this.tabList = [{
                    title: '代发薪大库报税',
                    name: '31278300703'
                }, {
                    title: '代发薪客户名义报税',
                    name: '31278300704'
                }, {
                    title: '客户名义报税',
                    name: '31278300702'
                }, {
                    title: '代报销',
                    name: '31278300707'
                }];
                this.tabIndex = this.$route.query.currentTab ? this.$route.query.currentTab : '31278300703';
                this.currentTab = this.tabIndex;
            } else {
                this.tabList = [{
                    title: '大库报税',
                    name: '31278300701'
                }, {
                    title: '客户名义报税',
                    name: '31278300702'
                }, {
                    title: '代发薪大库报税',
                    name: '31278300703'
                }, {
                    title: '代发薪客户名义报税',
                    name: '31278300704'
                }, {
                    title: '代报销',
                    name: '31278300707'
                }];
                this.tabIndex = this.$route.query.currentTab ? this.$route.query.currentTab : '31278300701';
                this.currentTab = this.tabIndex;
            }
        },
        data() {
            return {
                impDialogVisible: false,
                copyDialogVisible: false,
                loading: false,
                list: [],
                listQuery: {
                    limit: 50,
                    page: 1,
                    total: 0,
                },
                //多选数组
                multipleSelection: [],
                tabList: [],
                tabIndex: "",
                currentTab: "",
                isRouterAlive: true,
            }
        },
        provide() {
            return {
                reload: this.reload
            }
        },
        methods: {
            changeTab(tab, event) {
                this.currentTab = tab.name;
                this.reload();
                console.log("hello everybody~~~this.currentTab==");
                console.log(this.currentTab);
            },
            reload() {
                this.isRouterAlive = false
                this.$nextTick(function () {
                    this.isRouterAlive = true
                })
            }
        }
    }
</script>

<style scoped>

</style>
