<!-- 下拉选择器 -->
<template>
  <el-select
    v-model="selectedData"
    v-bind="{...$attrs}"
    v-on="{...$listeners}"
    ref="select"
    :disabled="disabled"
    @change="selectChange"
    placeholder="请选择">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
  import { mapGetters } from "vuex";
  import {getDataByUrl } from  "@/api/common/common";
  import { deepCopy } from "../../../util/util";

  export default {
    name: "bsp-dicts-ref",
    props: {
      //当前下拉选的值
      selectValue: {
        type: String,
        default: ""
      },
      disabled: {
        type: Boolean,
        default: false
      },
      // 代码集setId
      dicNum: {
        type: String,
        default: ""
      },
      // 要移除的 itemId,隔开多个
      remNum: {
        type: String,
        default: ""
      },
      // 引用类型数据引用的地址
      dataUrl: {
        type: String,
        default: ""
      },
      // 输入框占位符
      placeholder: {
        type: String,
        required: false,
        default: "请选择"
      },
      // 下拉选数据
      optionsList: {
        type: Array,
        default: () => (
          []
        )
      },
      changeE: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        // 下拉选数据
        options: [],
        selectedData: ""
      };
    },
    computed: {
      ...mapGetters([
        "dicts"
      ])
    },
    watch: {
      selectValue(va) {
        //console.log("jianting :"+va)
        //this.selectedData=this.selectValue();
        this.dataFormatRef(va);
      }
    },
    created() {
      this.initRefData();
     // console.info(this.selectValue,"引用类型的选择值dkdkdkdkddkdkdddkdkdkdkdkdk");
    },
    mounted() {
    },
    methods: {
      /**
       *初始化表单中有引用类型的字段
       * 进行翻译时所需要的数据
       */
      async initRefData() {
        if(this.dataUrl){
          //判断url中没有没有指定的获取参数
          let url  =this.dataUrl;
          //console.log("动态表单中反回的引用数据this.dataUrl",this.dataUrl);
          let key,name,istr;
          if(url.indexOf("&")>0){
            let split = url.split("&");
            url = split[0];
            key = split[1];
            name = split[2];
            istr= true;
          }else {
            istr =false;
          }
          await getDataByUrl(url).then(res => {
            let dataMap = res.data.data;
            // 判断是不是对象类型 如果是对象类型则转换成化集合
            if (dataMap instanceof Array) {
              // 取出指定的数据
              if(istr){
                for (let m in dataMap){
                  this.optionsList.push({'value':dataMap[m][key], 'label':dataMap[m][name]});
                }
                this.options = this.optionsList;
              }else {
                this.options = dataMap;
              }
            } else {
              if(istr){
                  this.optionsList.push({'value': dataMap[key], 'label': dataMap[name]});
              }else {
                for (let k in  dataMap) {
                  this.optionsList.push({'value': k, 'label': dataMap[k]});
                }
              }

              this.options = this.optionsList;
            }

            this.dataFormatRef(this.selectValue);
          });
        }
      },
      dataFormatRef(va) {
        if (!va) {
          this.selectedData = '';
          return;
        }
        if (this.options) {
          for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].value === '' + va) {
              this.selectedData = this.options[i].label;
            }
          }
          //console.log("设置默认的值",this.selectedData)

        }
      },
      selectChange(val) {
        this.$emit("update:selectValue", val);
        this.$emit("selectChange", val);
      },

    }
  }
</script>

<style scoped>
  .el-input.el-input--suffix {
    cursor: pointer;
    overflow: hidden;
  }

  .el-input.el-input--suffix.rotate .el-input__suffix {
    transform: rotate(180deg);
  }

  .select-tree {
    max-height: 350px;
    overflow-y: scroll;
  }

  /* 菜单滚动条 */
  .select-tree::-webkit-scrollbar {
    z-index: 11;
    width: 6px;
  }

  .select-tree::-webkit-scrollbar-track,
  .select-tree::-webkit-scrollbar-corner {
    background: #fff;
  }

  .select-tree::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 6px;
    background: #b4bccc;
  }

  .select-tree::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 6px;
  }

  .el-form-item__content {
    height: 28px;
  }
</style>
