<template>
  <div class="set-user">
    <div class="set-left">
      <div class="tabs">
        <ul>
          <li v-for="(item, index) in tabList" :key="index"
              @click="tabHander(index, item)"
              :class="{'isActive': currentab.index == index}">{{item}}</li>
        </ul>
      </div>
    </div>
    <div class="set-right">
      <div class="tab-title">
        <span>{{currentab.name}}</span>
      </div>
      <!--  基本设置      -->
      <div class="base-set" v-loading="baseLoading" v-if="currentab.index == 0">
        <el-form :model="form"  :rules="formRules" ref="form" label-width="80px"  class="editform">
          <el-form-item label="头像:" prop="photo"  class="pictery">
            <ciic-img-upload  @handleUploadSuccess="handleUploadSuccess"
                              v-model="showCropAvatar">
            </ciic-img-upload>
            <div @click="toggleShowCropAvatar" style="width: 146px; border-radius: 50%">
              <img v-if="form.photo" :src="form.photo | filePathFormat" class="avatar" >
              <i v-else class="el-icon-plus avatar-uploader-icon avatar-uploader-color avatar"></i>
            </div>
          </el-form-item>
          <el-form-item   label="用户名:" prop="username"  class="inputclass  editspan">
            <el-input class="editinput" @blur="getUserNameNum" v-model="form.username" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item   label="姓名:" prop="nameCn"  class="editspan">
            <el-input v-model="form.nameCn" class="editinput" placeholder="请输入姓名" ></el-input>
          </el-form-item>
          <el-form-item   label="性别:" prop="gender" class="editspan" >
            <el-select v-model="form.gender" placeholder="请选择性别" class="editinput">
              <el-option v-for="item in codeList[1]"   :key="item.codeId" :label="item.name" :value="item.codeId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item   label="出生日期:" prop="birthday"   class="editspan ">
            <el-date-picker  class="editinput" :disabled="disabled.birthday"  ref="itembirthday" v-model="form.birthday" type="date" placeholder="请输入出生日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item   label="证件类型:" prop="cardType" class="editspan" >
            <el-select v-model="form.cardType" :disabled="disabled.cardType" placeholder="请选择证件" class="editinput">
              <el-option v-for="item in codeList[2]"   :key="item.codeId" :label="item.name" :value="item.codeId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item   label="证件号码:" prop="cardNum"  class="editspan">
            <el-input v-model="form.cardNum" :disabled="disabled.cardNum" class="editinput" placeholder="请输入证件号码" ></el-input>
          </el-form-item>
          <el-form-item   label="联系地址:" prop="address" class="editspan">
            <el-input v-model="form.address" class="editinput" placeholder="请输入联系地址"></el-input>
          </el-form-item>
        </el-form>
        <el-button  type="primary"  class="btustyle" :loading="buttonLoading" @click="updateBaseInfo()">更新基本信息</el-button>
      </div>
      <!--    安全设置    -->
      <div class="security-set" v-if="currentab.index == 1">
        <div class="security-item">
          <div class="item-left">
            <div class="item-top">账户密码</div>
            <div class="item-bottom">{{havePwd ? '当前密码: ******' : '您还没有设置秘密'}}</div>
          </div>
          <div class="item-right">
            <el-button type="text" @click="showEditForm('pwd')">{{havePwd ? '修改' : '设置'}}</el-button>
          </div>
        </div>
        <div class="security-item">
          <div class="item-left">
            <div class="item-top">密保手机</div>
            <div class="item-bottom">当前手机: {{currentPhone}}</div>
          </div>
          <div class="item-right">
            <el-button type="text" @click="showEditForm('phone')">修改</el-button>
          </div>
        </div>
        <div class="security-item">
          <div class="item-left">
            <div class="item-top">密保邮箱</div>
            <div class="item-bottom">已绑定邮箱: {{currentEmail}}</div>
          </div>
          <div class="item-right">
            <el-button type="text" @click="showEditForm('email')">修改</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible"

               :close-on-click-modal="false"
               :close-on-press-escape="false"
               width="35%">
      <div v-if="dialogStatus === 'pwd'">
        <el-form :model="formPassword" :rules="passRules" ref="formPassword" label-width="120px">
          <el-form-item  label="原始密码"  prop="password" >
            <el-input  class="securityInput" type="password"  v-model="formPassword.password" placeholder="请输入旧密码" ></el-input>
          </el-form-item>
          <el-form-item :label="havePwd ? '新密码:' : '密码:'" prop="newPassword" >
            <el-input  class="securityInput" type="password" v-model="formPassword.newPassword" :placeholder="havePwd?'请输入新密码': '请输入密码'" ></el-input>
          </el-form-item>
          <el-form-item label="确认密码:" prop="changepsd" >
            <el-input  class="securityInput" type="password" v-model="formPassword.changepsd" placeholder="请重新输入新密码" ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="dialogStatus === 'email'">
        <el-form :model="formEmail" :rules="emailRules" ref="formEmail" label-width="120px">
          <el-form-item label="邮箱:" prop="email" >
            <el-input class="securityInput" @blur="getUserEmailNum" v-model="formEmail.email" placeholder="请输入邮箱,如:xx@163.com"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="dialogStatus === 'phone'">
        <el-form :model="formPhone" :rules="phoneRules" ref="formPhone" label-width="120px">
          <el-form-item  prop="mobile" label="手机号:"  class="itemphone">
            <el-input class="securityInput" @blur="getUserPhoneNum" @keyup.enter.native="saveSecurity" v-model="formPhone.mobile" auto-complete="off"   placeholder="请输入手机号码">
              <svg slot="prefix" style="margin-top: 8px" class="icon" aria-hidden="true">
                <use xlink:href="#icon-shouji"></use>
              </svg>
            </el-input>
          </el-form-item>
          <el-form-item label="验证码:"  prop="code"  class="itemphone">
            <el-input  class="securityInput" @keyup.enter.native="saveSecurity" v-model="formPhone.code" auto-complete="off"  placeholder="请输入验证码">
              <template slot="append">
                <span @click="handleSend" class="msg-text" :class="[{display:msgKey}]">{{msgText}}</span>
              </template>
              <svg slot="prefix" style="margin-top: 8px" class="icon" aria-hidden="true">
                <use xlink:href="#icon-yanzhengma"></use>
              </svg>
            </el-input>
          </el-form-item>
        </el-form>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" :loading="buttonLoading" @click="saveSecurity">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
    import {getMyInfo, changePassword} from '@/api/user/user';
    import CiicImgUpload from "@/components/ciic/ciic-img-upload/ciic-img-upload";
    import { mapGetters } from "vuex"
    import {deepCopy} from "@/util/util";
    import { isvalidatemobile } from '@/util/validate';
    const MSGINIT = '发送验证码';
    const MSGERROR = '验证码发送失败';
    const MSGSCUCCESS = '${time}秒后重发';
    const MSGTIME = 60;
    export default {
        name: "setUser",
        components: {CiicImgUpload},
        computed: {
            ...mapGetters(['dicts']),
            ...mapGetters(['userInfo'])
        },
        filters: {
            codeFilter(val, map, setId) {
                return map[setId][val]
            },
        },
        created(){
            // this.getUserInfo()
        },
        data(){
          const validatePass = (rule, value, callback) => {
            if (value === undefined) {
              return callback(new Error('请输入密码'));
            }  else if (!/(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,20}/.test(value)) {
              return callback(new Error('密码中必须包含字母、数字、特殊字符,且大于8位'));
            }
            return callback();
          };
            const validatePass2 = (rule, value, callback) => {
                if (value === undefined) {
                    return callback(new Error('请再次输入密码'));
                }else if (!/(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,20}/.test(value)) {
                    return callback(new Error('密码中必须包含字母、数字、特殊字符,且大于8位'));
                }
                if (value !== this.formPassword.newPassword) {
                    return callback(new Error('两次输入密码不一致!'));
                }
                return callback();
            };
            const validatePhone = (rule, value, callback) => {
                if (isvalidatemobile(value)[0]) {
                    return callback(new Error(isvalidatemobile(value)[1]));
                }
                return callback();
            };
            const validateCode = (rule, value, callback) => {
                if (value && value.length !== 4) {
                    callback(new Error('请输入4位数的验证码'));
                } else {
                    callback();
                }
            };
            return{
                showCropAvatar: false,
                tabList: ['基本设置', '安全设置'],
                codeList:{
                    1: [
                        {codeId: '1', name: '男'},
                        {codeId: '2', name: '女'},
                    ],
                    2: [
                        {codeId: '68', name: '身份证'},
                        {codeId: '69', name: '护照'}
                    ]
                },
                codeMap:{
                    1: {
                        1: "女",
                        2: "男",
                    },
                    2: {
                        68: "身份证",
                        69: "护照"
                    },
                },
                // 禁用标识
                disabled: {
                    name: false,
                    sex: false,
                    birthday: false,
                    cardType: false,
                    cardNum: false,
                },
                //基本设置表单
                form: {},
                formRules: {
                    username:[
                        {required: true, message: '请输入用户名',trigger: 'blur'},
                        {min: 3,max: 50,message: '长度在 3 到 50 个字符'},
                        {pattern: /^[A-Za-z0-9_\-\u4e00-\u9fa5]*(?=[A-Za-z\-\u4e00-\u9fa5]+)[A-Za-z0-9_\-\u4e00-\u9fa5]*$/, message: '可以使用中文汉字，英文字母，数字，下划线，必须包含英文字母或中文汉字'}
                    ]
                },
                initBaseForm: {},
                baseLoading: true,
                //当前tab
                currentab: {
                    index: 0,
                    name: '基本设置'
                },
                dialogFormVisible: false,
                textMap: {
                    phone: '修改手机号',
                    pwd: '修改密码',
                    email: '修改邮箱'
                },
                dialogStatus: 'pwd',
                // 保存手机号
                formPhone: {},
                currentPhone: '',
                // 保存邮箱
                formEmail: {},
                currentEmail: '',
                // 保存修改密码
                formPassword: {},
                // 修改密码验证Rules
                passRules: {
                    password: [{ validator: validatePass, trigger: 'blur' }],
                    newPassword: [{ validator: validatePass, trigger: 'blur' }],
                    changepsd: [{ validator: validatePass2, trigger: 'blur' }],
                },
                // 手机号验证
                phoneRules: {
                    mobile: [{ required: true, trigger: 'blur', validator: validatePhone }],
                    code: [{ required: true, trigger: 'blur', validator: validateCode }],
                },
                // 邮箱验证
                emailRules: {
                    email: [
                        { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                        { type: 'email', message: '邮箱格式不正确', trigger: ['blur', 'change'] },
                    ],
                },
                //表单保存时保存按钮是否显示加载
                buttonLoading: false,
                msgKey: false,
                msgText: MSGINIT,
                msgTime: MSGTIME,
                havePwd: null,
              // 用户账号密码，修改密码时传参
              pubUser: {
                  loginName: '',
                  loginPwd: '',
              }
            }
        },
        methods: {
            //切换tab选项
            tabHander(index,item){
                this.currentab.index = index
                this.currentab.name = item
            },
            //上传头像
            toggleShowCropAvatar() {
                this.showCropAvatar = !this.showCropAvatar;
            },
            //上传头像成功事件
            handleUploadSuccess(res,field) {
                this.form.photo = res.fileId;
            },
            //获取用户信息
            getUserInfo(){

                this.baseLoading = true
                getMyInfo().then(res => {
                    this.baseLoading = false
                    let data = res.data.data
                    data.gender = data.gender + ''
                    data.cardType = data.cardType + ''
                    this.form = data
                    if(this.form.cardType == 'null'){
                        this.form.cardType = '68'
                    }
                    if(this.form.gender == 'null'){
                        this.form.gender = ''
                    }
                    this.initBaseForm = deepCopy(data)
                    this.$store.commit('SET_USER_INFO', JSON.parse(JSON.stringify(data)))
                    this.havePwd = data.password;
                    this.currentPhone = this.form.phone
                    this.currentEmail = this.form.email
                })
            },
            //修改用户基本信息
            updateBaseInfo(){
                let obj = {}
                for(let i in this.initBaseForm) {
                    if(this.form[i] != this.initBaseForm[i] && this.form[i] != ''){
                        obj[i] = this.form[i]
                    }
                }
                if(JSON.stringify(obj) == "{}"){
                    this.$message.warning('用户信息没有改变或修改后的表单为空')
                }else {
                    this.baseLoading = true
                    edituser(obj).then(res => {
                        this.buttonLoading = false
                        this.$message.success('基本信息修改成功');
                        this.getUserInfo()
                    }).catch( fail => {
                        this.buttonLoading = false
                    })
                }
            },
            showEditForm(status){
                this.dialogFormVisible = true
                this.dialogStatus = status
                switch(this.dialogStatus) {
                    case 'pwd':
                        this.resetForm('formPassword')
                        break;
                    case 'email':
                        this.resetForm('formEmail')
                        break;
                    case 'phone':
                        this.resetForm('formPhone')
                        break;
                    default:
                        return
                }
            },
            //保存安全设置
            saveSecurity(){
                switch(this.dialogStatus) {
                    case 'pwd':
                        this.savePwd('formPassword')
                        break;
                    case 'email':
                        this.saveEmail('formEmail')
                        break;
                    case 'phone':
                        this.savePhone('formPhone')
                        break;
                    default:
                        return
                }
            },
            //保存密码
            savePwd(formName) {
                const set = this.$refs;
                set[formName].validate((valid) => {
                    if (valid) {
                        // this.buttonLoading = true
                        // let password = this.havePwd ?
                        //     {
                        //         password: this.formPassword.password,
                        //         newPassword: this.formPassword.newPassword
                        //     }
                        //     :
                        //     {
                        //         newPassword: this.formPassword.newPassword
                        //     }
                        this.pubUser.oldPwd = this.formPassword.password;
                        this.pubUser.loginPwd = this.formPassword.newPassword
                        this.pubUser.loginName = this.userInfo.loginName;
                        changePassword(this.pubUser).then((res) => {
                            this.dialogFormVisible = false;
                            this.dialogFormVisible = false
                            this.buttonLoading = false
                            if (res.data.code === 0) {
                                this.$message.success('修改密码成功,请重新登录');
                                this.$refs[formName].resetFields();
                                this.formPassword = {};
                                const pathStr =
                                    `/login`;
                                this.$store.dispatch('LogOut').then(() => {
                                    this.$router.push({ path: pathStr });
                                });
                            } else {
                                this.$message.warning(res.data.msg);
                            }

                        }).catch(fail => {
                            this.buttonLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            // 保存手机号
            savePhone() {
                this.$refs.formPhone.validate((valid) => {
                    if (valid) {
                        this.buttonLoading = true
                        let obj = {
                            phone: this.formPhone.mobile,
                            code:  this.formPhone.code
                        }
                        changePhone(obj).then((res) => {
                            this.DialogFormVisible = false;
                            this.buttonLoading = false
                            if (res.data.code === 0 && res.data.data) {
                                this.$message.success('修改手机号成功,请重新登录!');
                                this.formPhone = {}
                                const pathStr = `/login`;
                                this.$store.dispatch('LogOut').then(() => {
                                    this.$router.push({ path: pathStr });
                                });
                            } else {
                                this.$message.warning(res.data.msg);
                            }
                        }).catch(fail => {
                            this.buttonLoading = false
                        })
                    }
                });
            },
            // 修改邮箱
            saveEmail(formName) {
                const set = this.$refs;
                set[formName].validate((valid) => {
                    if (valid) {
                        this.buttonLoading = true
                        changeEmail(this.formEmail.email).then((res) => {
                            this.dialogFormVisible = false
                            this.buttonLoading = false
                            if (res.data.code === 0 && res.data.data) {
                                this.$message.success('修改邮箱成功,请重新登录');
                                this.formEmail = {};
                                const pathStr = `/login`
                                this.$store.dispatch('LogOut').then(() => {
                                    this.$router.push({ path: pathStr });
                                });
                            } else {
                                this.$message.warning(res.data.msg);
                            }
                        }).catch(fail => {
                            this.buttonLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            //发送验证码
            handleSend() {
                if (this.msgKey) return;
                if (!this.formPhone.mobile) {
                    this.$message.warning('请输入手机号码');
                } else if (!/^1[3456789]\d{9}$/.test(this.formPhone.mobile)) {
                    this.$message.warning('手机号格式不正确');
                } else {
                    let obj = {
                        mobile: this.formPhone.mobile,
                        type: 4
                    }
                    sendSmsCode(obj).then( res=> {
                        if (res.data.data) {
                            this.timer()
                            this.$message.success('验证码发送成功');
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                }
            },
            timer() {
                this.msgText = MSGSCUCCESS.replace('${time}', this.msgTime);
                this.msgKey = true;
                const time = setInterval(() => {
                    this.msgTime--;
                    this.msgText = MSGSCUCCESS.replace('${time}', this.msgTime);
                    if (this.msgTime == 0) {
                        this.msgTime = MSGTIME;
                        this.msgText = MSGINIT;
                        this.msgKey = false;
                        clearInterval(time);
                    }
                }, 1000);
            },
            // 查询用户名数量
            getUserNameNum(){
                if(this.form.username){
                    let obj={
                        name: this.form.username,
                    }
                    getUserNum(obj).then(res => {
                        if(res.data.data > 0){
                            // 手动操作校验、展示登录错误信息
                            this.formRules.username.push({ // js新增一个自定义校验
                                validator: (rule, value, callback) => {
                                    callback(new Error('该用户名已存在'))
                                },
                                trigger: 'blur'
                            })
                            this.$refs['form'].validateField('username') // 手动校验
                            this.formRules.username = this.formRules.username.slice(0, 1) // 删除校验，这个看自己之前写了几个校验来定split也可
                        }
                    })
                }
            },
            // 查询手机数量
            getUserPhoneNum(){
                if(this.formPhone.mobile){
                    let obj={
                        name: this.formPhone.mobile,
                    }
                    getUserNum(obj).then(res => {
                        if(res.data.data > 0){
                            // 手动操作校验、展示登录错误信息
                            this.phoneRules.mobile.push({ // js新增一个自定义校验
                                validator: (rule, value, callback) => {
                                    callback(new Error('该手机号已存在'))
                                },
                                trigger: 'blur'
                            })
                            this.$refs['formPhone'].validateField('mobile') // 手动校验
                            this.phoneRules.mobile = this.phoneRules.mobile.slice(0, 1) // 删除校验，这个看自己之前写了几个校验来定split也可
                        }
                    })
                }
            },
            // 查询邮箱数量
            getUserEmailNum(){
                if(this.formEmail.email){
                    let obj={
                        name: this.formEmail.email,
                    }
                    getUserNum(obj).then(res => {
                        if(res.data.data > 0){
                            // 手动操作校验、展示登录错误信息
                            this.emailRules.email.push({
                                validator: (rule, value, callback) => {
                                    callback(new Error('该邮箱已存在'))
                                },
                                trigger: 'blur'
                            })
                            this.$refs['formEmail'].validateField('email') // 手动校验
                            this.emailRules.email = this.emailRules.email.slice(0, 1) // 删除校验，这个看自己之前写了几个校验来定split也可
                        }
                    })
                }
            },
            resetForm(formName) {
                if(this.$refs[formName]){
                    this.$refs[formName].resetFields();
                }
            }
        }
    }
</script>

<style lang="scss">
  .set-user{
    display: flex;
    min-height: 80vh;
    .set-left{
      width: 224px;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      margin-right: 40px;
      li{
        height: 40px;
        line-height: 40px;
        padding-left: 24px;
        margin-bottom: 8px;
        margin-right: -1px;
        cursor: pointer;
      }
      li:hover{
        color: #1890ff;
      }
      .isActive{
        background-color: #e6f7ff;
        color: #1890ff;
        border-right: 2px solid #1890ff;
      }
    }
    .set-right{
      flex: 1;
      .tab-title{
        margin-bottom: 30px;
        color: rgba(0,0,0,.85);
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
      }
      .base-set{
        .editform{
          .editinput{
            width: 30%;
          }

        }
        .editWarning{
          display: inline-block;
          margin-left: 20px;
          color: #e6a23c;
          font-size: 13px;
          .icon{
            font-size: 15px;
            padding-right: 5px;
            vertical-align: middle;
          }
        }
        .avatar-uploader .el-upload {
          display: block;
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          border-radius: 50%;
        }
        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }
        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 144px;
          height: 144px;
          line-height: 144px;
          text-align: center;
          border: 1px dashed #d9d9d9;
          border-radius: 50%;
        }
        .avatar{
          width: 144px;
          height: 144px;
          border-radius: 50%;
        }
      }
      .security-set{
        width: 95%;

        .security-item{
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #e8e8e8;
          padding: 14px 0;
          color: rgba(0,0,0,.65);
          .item-bottom{
            color: rgba(0,0,0,.45);
          }
        }
      }
    }
    .securityInput{
      width: 80%;
    }
    .msg-text {
      display: block;
      width: 60px;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
    }
    .msg-text.display {
      color: #ccc;
    }
  }
</style>
