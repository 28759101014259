<template>
<!--    <bsp-tabs :tabList="tabList" :tabIndex="tabIndex" @changeTab="changeTab">-->
<!--          <component  :is="currentTab" :currentTab='currentTab'></component>-->
<!--    </bsp-tabs>-->
<div  class="outer-layer">
  <el-tabs v-model="tabIndex"  @tab-click="changeTab">
    <el-tab-pane
      :key="item.name"
      v-for="item in tabList"
      :label="item.title"
      :name="item.name"
      lazy="true"
    >
      <component  :is="item.name"></component>
    </el-tab-pane>
  </el-tabs>
</div>
</template>
<script>
 import bspTabs from "@/components/bsp/bsp-tabs/bsp-tabs";
  import tabson1 from '@/views/demo/tabs/tabson/tabson1';
  import tabson2 from '@/views/demo/tabs/tabson/tabson2';
  import tabson3 from '@/views/demo/tabs/tabson/tabson3';
  export default {
    data() {
      return {
        tabList: [{
          title: '用户管理',
          name: 'tabson1'
        }, {
          title: '配置管理',
          name: 'tabson2'
        }, {
          title: '角色管理配置',
          name: 'tabson3'
        }, {
          title: '角色管理',
          name: 'tabson4'
        }],
        tabIndex: 'tabson1',
        // currentTab:'tabson1',
      }
    },
    components:{
        bspTabs,
        tabson1,
        tabson2,
        tabson3,
    },
    methods: {
      changeTab(tab, event) {
          // this.currentTab = tab.name;
      },
    }
  }
</script>
