<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right" @click="searchPaymentsByConditions">查询</el-button>
    </div>
    <el-form  :inline="true" :model="listQuery" class="editForm ">

        <el-form-item label="支付单编号:">
          <el-input v-model="listQuery.paymentNo" placeholder="请输入内容" ></el-input>
        </el-form-item>
       <el-form-item label="状态:">
          <el-select  class="status-select" v-model="listQuery.paymentStatus" >
            <el-option label="全部" value=""></el-option>
            <el-option label="新建" value="1"></el-option>
            <el-option label="待核算" value="2"></el-option>
            <el-option label="待销账" value="3"></el-option>
            <el-option label="已销账" value="4"></el-option>
          </el-select>
        </el-form-item>
      <el-form-item label="核算月份:" >
          <el-date-picker
            style="width: 100%"
            v-model="listQuery.accountingMonth"
            type="month"
            value-format="yyyy-MM"
            clearable
            placeholder="选择月份">
          </el-date-picker>
      </el-form-item>
      <el-form-item label="创建时间:" >
        <div class="block" >
          <el-date-picker
            style="width: 100%"
            v-model="startAndEndDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="起始日期"
            end-placeholder="截止日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:ss:mm"
            clearable
            @click="parseStartAndEndDate">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item  >
        <template >
          <el-radio v-model="listQuery.paymentType" label="0"  style="margin-outside: 0px" v-if="false">全部</el-radio>
          <el-radio v-model="listQuery.paymentType" label="2"  style="margin-outside: 0px" v-if="false">薪资类</el-radio>
          <el-radio v-model="listQuery.paymentType" label="1"  style="margin-outside: 0px" v-if="false">非薪资类</el-radio>
        </template>
      </el-form-item>
    </el-form>
    <bsp-table
      :data.sync="list"
      :tree-config="true"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="searchPaymentsByConditions"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
    >

<!--      <vxe-table-column type="selection" width="60" fixed="left"/>-->
<!--      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>-->

      <bsp-table-column  title="操作" fixed="right" align="center" width="100">
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button  type="text" @click="searchDetailInfo(scope.row)" >查看</el-button>
          </div>
        </template>
      </bsp-table-column>

      <bsp-table-column title="支付单编号" field="payCode" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.payCode}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="付款账号" field="accountStr" filter-type="input" fixed="left" >
        <template slot-scope="scope"><span>{{scope.row.accountStr}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="付款类型" field="accountType" filter-type="input" fixed="left" >
        <template slot-scope="scope"><span>{{scope.row.accountType}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="支付类别" field="payClass" filter-type="select" fixed="left" set-id = "3301">
        <template slot-scope="scope"><span>{{scope.row.payClass | dictFilter(dicts)}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="发放方式" field="sendType" filter-type="select" fixed="left"  set-id = "3083">
        <template slot-scope="scope"><span>{{scope.row.sendType | dictFilter(dicts)}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="薪资类别" field="payWageClass" filter-type="input" fixed="left" >
        <template slot-scope="scope"><span>{{scope.row.payWageClass}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="创建方式" field="createType" filter-type="select" set-id ="3300">
        <template slot-scope="scope"><span>{{scope.row.createType | dictFilter(dicts)}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="创建时间" field="createTime" filter-type="datePicker" >
        <template slot-scope="scope"><span>{{scope.row.createTime}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="办理状态" field="status" filter-type="select" set-id = "3302">
        <template slot-scope="scope"><span>{{scope.row.status | dictFilter(dicts)}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="支付人" field="creator" >
        <template slot-scope="scope"><span>{{scope.row.creator}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="支付完成时间" field="completionTime" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.completionTime}}</span></template>
      </bsp-table-column>

<!--      <bsp-table-column title="回收发票" field="invoiceBack" filter-type="input" width="100">-->
<!--        <template slot-scope="scope"><span>{{scope.row.invoiceBack}}</span></template>-->
<!--      </bsp-table-column>-->
      <bsp-table-column title="备注" field="remark" filter-type="input" >
        <template slot-scope="scope"><span>{{scope.row.remark}}</span></template>
      </bsp-table-column>
    </bsp-table>

  </div>
</template>
<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {
    searchPaymentsByConditions,
    searchDetailInfo
  } from '@/api/payment/payment';
  import {downloadFile, downloadPdfFile} from '@/util/util';
  import {mapGetters} from 'vuex';

  export default {
    name: 'paymentDetail',
    inject: ['reload'],
    components: {
      BspTable,
      BspTableColumn,
    },
    computed: {
      ...mapGetters([
        'dicts'
      ]),
    },
    props: {
      currentTab: {
        type: String,
        default: ''
      },
      batchStatus: {
        type: String,
        default: ''
      },
      payClass: {
        type: String,
        default: ''
      },
    },
    created() {

      this.initDate();
      this.searchPaymentsByConditions();

    },
    data() {
      return {
        // 下拉选中的银行类别
        bankTypes: [],
        // 根据银行类别选取的银行信息
        bankInfos: [],
        // 用于接收后台返回的分页数据
        list: [],
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          paymentNo: '',
          paymentStatus: '',
          accountingMonth: '',
          paymentType: '0',
          payClass:''
        },
        loading: false,

        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        startAndEndDate: '',
      }
    },
    methods: {
      parseStartAndEndDate() {
        let date1 = this.startAndEndDate;
        this.listQuery.startDate = null;
        this.listQuery.endDate = null;
        if (date1 !== null && date1.length > 0) {
          if (date1[0] !== undefined) {
            this.listQuery.startDate = date1[0];
          }
          if (date1[1] !== undefined) {
            this.listQuery.endDate = date1[1];
          }
        }
      },
      // 初始化时间选择框
      initDate() {
        let now = new Date();
        let year = now.getFullYear(); //得到年份
        let month = now.getMonth(); //得到月份
        let date = now.getDate(); //得到日期
        month = month + 1;
        month = month.toString().padStart(2, "0");
        date = date.toString().padStart(2, "0");
        let defaultDate = `${year}-${month}-${date}`;
        this.startAndEndDate = [defaultDate + ' 00:00:00', defaultDate + ' 23:59:59'];
      },

      // 根据条件查询
      searchPaymentsByConditions: function () {
        this.loading  =true;
        this.listQuery.payClass = this.payClass;
        let llQuery = this.$route.query.listQuery;
        if (llQuery !== null && llQuery !== undefined){
          this.listQuery = llQuery;
          if (llQuery.startDate === null && llQuery.endDate === null) {
            this.startAndEndDate = null;
          } else {
            this.startAndEndDate = [this.listQuery.startDate, this.listQuery.endDate];
          }
        }
        this.$route.query.listQuery = null;

        this.parseStartAndEndDate();
        if (this.listQuery.startDate !== null && this.listQuery.endDate !== null && this.listQuery.startDate === this.listQuery.endDate){
          this.listQuery.endDate = `${this.listQuery.endDate.replace('00:00:00', '23:59:59')}`;
        }
        searchPaymentsByConditions(this.listQuery).then(res => {

          this.list = res.data.records;
          this.listQuery.total = res.data.total;
          this.loading  =false;
        })
      },

      // 查看支付单明细
      searchDetailInfo: function (obj) {

        this.$router.push({path: '/wt/paymentDetailInfo', query: {accountType:obj.accountType,paymentId: obj.payId, payWageClass: obj.payWageClass, createType: obj.createType, listQuery: this.listQuery,payClass: this.payClass,payCode: obj.payCode,currentTab: this.currentTab}})
      }

    }
  }
</script>
<style scoped>
  .copy-btn {
    cursor: pointer;
  }

  .ciic-table-operate {
    align-items: center;
  }

  .editForm {
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

  }

  .editForm .el-form-item {
    margin: 0;
  }
  .editForm .el-form-item{
    /*width: 30%;*/
    padding: 10px 4px;
    display: flex;
  }

  .editForm .el-form-item:first-child,.editForm .el-form-item:nth-child(3){
    flex:1;
  }

  .editForm .el-form-item >>> .el-form-item__label{
    flex-shrink: 0;
  }
  @media (min-width:1367px){
    .editForm .el-form-item:nth-child(2){
      flex:1;
    }
    .editForm .el-form-item:nth-child(4){
      flex:2;
    }
  }
  @media (max-width:1367px){
    .editForm .el-form-item:nth-child(2){
      width: 130px;
    }
    .editForm .el-form-item:nth-child(4){
      width: 290px;
    }
    .editForm .el-form-item >>> .el-form-item__label{
      padding-right: 8px;
    }
    .editForm .el-form-item  >>> .el-radio__label{
      padding-left: 4px;
    }
    .editForm .el-form-item  >>> .el-radio{
      margin-right: 4px;
    }
  }

</style>
