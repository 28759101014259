<template>
  <div class="outer-layer">
    <bsp-tabs :tabList="tabList" :tabIndex="tabIndex" @changeTab="changeTab">
      <component  ref="pub" :is="currentTab" :currentTab='currentTab' :batchStatus="batchStatus" :payClass="payClass"></component>
    </bsp-tabs>
  </div>
</template>

<script>
  import bspTabs from "@/components/bsp/bsp-tabs/bsp-tabs";
  import paymentDetailList from "@/views/payment/paymentDetail";
  import {mapGetters} from "vuex";
  export default {
    computed: {
      ...mapGetters(['userInfo']),
    },
    created(){
      console.log("当前用户 机构id："+this.userInfo.orgId);
      //TODO 此处增加对业务类型显示
      this.tabList= [{
        title: '查询对私支付单明细',
        name: '3333333333'
      },{
        title: '查询对公支付单明细',
        name: 'duigong'
      }];

      this.tabIndex = this.$route.query.currentTab ? this.$route.query.currentTab : '3333333333';

      this.currentTab = this.tabIndex;
      this.batchStatus = "3333333333";
      let currentTab = this.$route.query.currentTab;
      if(currentTab == null || currentTab == "" || currentTab == "3333333333"){
        this.payClass = "1";
      }else if(currentTab == "duigong"){
        this.payClass = "2"
      }
    },
    data() {

      return {
        tabList:[],
        tabIndex: "",
        currentTab:this.$route.query.currentTab ? this.$route.query.currentTab : '3333333333',
        isRouterAlive:true,
        batchStatus:"",
        payClass:""
      }
    },
    provide(){
      return{
        reload:this.reload
      }
    },
    components:{
      bspTabs,
      3333333333: paymentDetailList,
      duigong: paymentDetailList,
    },
    methods: {
      changeTab(tab, event) {
        this.currentTab = tab.name;
        this.batchStatus = tab.name;

        this.payClass = tab.name=="3333333333"?'1':'2';
        this.reload();
        console.log("hello everybody~~~this.currentTab==");
        console.log(this.currentTab);
        console.log(this.payClass);
      },
      reload (){
        this.isRouterAlive = false
        this.$nextTick(function(){
          this.isRouterAlive = true
        })
      }
    }
  }
</script>

<style scoped>

</style>
