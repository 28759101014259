<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" size="small"   @click="$router.go(-1)">返回</el-button>
    </div>
    <bsp-table
      :data.sync="list"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="_getListPage"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
    >
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <bsp-table-column v-for="(item,index) in headList " :title="item.NAME" :field="item.CODE" width="100"  :fixed="index<3?'left':''"  :filter-type="item.SETID?'select':'input'" :set-id = 'item.SETID'>
        <template slot-scope="scope" >
          <span v-if="!item.SETID" >
          {{scope.row[item.CODE] }}
        </span>
          <span v-if="item.SETID" >
          {{scope.row[item.CODE] |  dictFilter(dicts) }}
        </span>
        </template>
      </bsp-table-column>

    </bsp-table>
  </div>
</template>

<script>
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {findPayMentDetail ,getHead  } from '@/api/entrust/paymentBills'
  import {mapGetters} from 'vuex';
  export default {
    components: {
      BspTable,
      BspTableColumn
    },
    computed: {
      ...mapGetters([
        'dicts'
      ]),
    },
    data() {
      return {
        loading:false,
        cusId:this.$route.query.cusId,
        headList:[],
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          batchId: this.$route.query.batchId,
        }
      }
    },
    created() {
      this._getHeadList();
      this._getListPage();
    },
    methods:{
      async _getListPage() {
        // 查询账单列表
        this.loading = true
        let res = await findPayMentDetail(this.listQuery);
        this.listQuery.total = res.data.total
        this.list = res.data.records
        this.loading = false
      },
      async _getHeadList() {
        // 查询账单列表
        let res = await getHead(this.cusId);
        this.headList = res.data.data
        console.log("===================");
        console.log(this.headList);
      }
    }
  }
</script>

<style scoped>

</style>
