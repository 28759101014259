<template>
  <div class="stepContent">
    <div class="uploadTip">
      <div class="uploadTip-title">
        导入结果
      </div>
      <div class="uploadTip-lists">
        <div v-if="success" class="uploadTip-list">
          导入成功
        </div>
        <div v-if="error" class="uploadTip-list">
          导入失败 <span class="uploadFail">{{ errorCount }}</span> 条
          <span v-if="errorCount>0">
            , 下载<span class="uploadTip-button" @click="exportErrData">《错误数据信息表》</span>
          </span>
        </div>
      </div>
    </div>
    <el-button class="import" type="primary" @click="over">完成</el-button>
  <!--  <el-button class="import" type="primary" @click="next">下一步</el-button>
    <el-button class="import" type="primary" @click="rematch">重新匹配</el-button>-->
  </div>
</template>
<script>
  import {downloadErrorExcle} from "@/api/demo/demo"
  import { downloadFile } from '@/util/util';
export default {
  data() {
    return {
      successCount: 0,
      error: false,
      success: true,
      errorCount: 0,
      data:{
        tableNames: '',
        fileName: '',
        tmplIds: '',
      }
    }
  },
  mounted() {
    this.successCount = this.$store.state.impData.successCount
    this.errorCount = this.$store.state.impData.errorCount
    this.success = this.$store.state.impData.success
    this.data.tableNames = this.$store.state.impData.tableNames
    this.data.tmplIds = this.$store.state.impData.tmplIds
    this.data.fileName = this.$store.state.impData.fileName
    if (this.success) {
      this.error = false
    } else {
      this.error = true
    }
  },
  methods: {
    next() {
      this.$emit('next') // 跳转下一步
    },
    rematch() {
      this.$emit('rematch') // 跳转上一步
    },
    over() {
      this.$router.push({
        path: '/serve/table'
      })
    },
    //  下载上传的错误信息
    exportErrData() {
      downloadErrorExcle(this.data).then(res=>{
        downloadFile(res)
      })
    }
  }
}
</script>
<style scoped  rel="stylesheet/scss">

  .uploadContent .iconfont{
    font-size: 80px;
    color:#cccccc;
  }
  .uploadTip-lists{
    padding-left:20px;
  }
  .uploadTip-list .uploadTip-button{
    color:#269cff;
    text-decoration: underline;
    cursor: pointer;
  }
  .uploadSuccess{
    font-weight: bold;
    font-size: 16px;
    color: #3c763d;
  }
  .uploadFail{
    font-weight: bold;
    font-size: 16px;
    color: red;
  }
  .import{
    margin-top: 20px;
  }
</style>
