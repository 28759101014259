<template>
  <div class="outer-layer">
    <bsp-tabs :tabList="tabList" :tabIndex="tabIndex" @changeTab="changeTab">
      <component  v-if="isRouterAlive" ref="pub" :is="currentTab" :currentTab='currentTab' :batchStatus="batchStatus"></component>
    </bsp-tabs>
  </div>
</template>
<script>
  import bspTabs from "@/components/bsp/bsp-tabs/bsp-tabs";
  import detailBatchList from "@/views/entrust/detailImport/detailImportBatchList";
  import {mapGetters} from "vuex";
  export default {
    computed: {
      ...mapGetters(['userInfo']),
    },
    created(){
      console.log("当前用户 机构id："+this.userInfo.orgId);
      //TODO 此处增加对业务类型显示 测试
      //if(this.userInfo.orgId=="1060352" || this.userInfo.orgId=="297ecd7072e4c4280172e4e2534a000b" || this.userInfo.orgId=="1060348"){
      if(this.userInfo.userType=='2'){
        this.tabList= [{
          title: '代发薪大库报税',
          name: '31278300703'
        },{
          title: '代发薪客户名义报税',
          name: '31278300704'
        },{
          title: '客户名义报税',
          name: '31278300702'
        },{
          title: '代报销',
          name: '31278300707'
        }];
        this.tabIndex = this.$route.query.currentTab ? this.$route.query.currentTab : '31278300703';
        this.currentTab = this.tabIndex;
      }else{
        this.tabList= [{
          title: '大库报税',
          name: '31278300701'
        },{
          title: '客户名义报税',
          name: '31278300702'
        },{
          title: '代发薪大库报税',
          name: '31278300703'
        },{
          title: '代发薪客户名义报税',
          name: '31278300704'
        },{
          title: '代报销',
          name: '31278300707'
        }];
        this.tabIndex = this.$route.query.currentTab ? this.$route.query.currentTab : '31278300701';
        this.currentTab = this.tabIndex;
      }
      /*else if(this.userInfo.orgId=="1060348"){
              this.tabList= [{
                title: '代发薪大库报税',
                name: '31278300703'
              },{
                title: '代发薪客户名义报税',
                name: '31278300704'
              },{
                title: '客户名义报税',
                name: '31278300702'
              },{
                title: '代报销',
                name: '31278300707'
              }];
              this.tabIndex = this.$route.query.currentTab ? this.$route.query.currentTab : '31278300703';
              this.currentTab = this.tabIndex;
            }*/
    },
    data() {

      return {
        tabList:[],
        tabIndex: "",
        currentTab:"",
        isRouterAlive:true,
      }
    },
    provide(){
      return{
        reload:this.reload
      }
    },
    components:{
      bspTabs,
      31278300701: detailBatchList,
      31278300702: detailBatchList,
      31278300703: detailBatchList,
      31278300704: detailBatchList,
      31278300707: detailBatchList,
    },
    methods: {
      changeTab(tab, event) {
        this.currentTab = tab.name;
        this.reload();
        console.log("hello everybody~~~this.currentTab==");
        console.log(this.currentTab);
      },
      reload (){
        this.isRouterAlive = false
        this.$nextTick(function(){
          this.isRouterAlive = true
        })
      }
    }
  }
</script>
