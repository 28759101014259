<template>
  <vxe-table-column
    v-bind="{...tableColumnAttrs,...$attrs}"
    v-on="$listeners"
  >
    <!-- 表头分组-->
    <template v-if="grounpColumns.length > 0">
      <!--     用于表头分组     -->
      <vxe-table-column
        v-for="(grounpColumn,i) in grounpColumns"
        :key="i"
        v-bind="{...grounpColumn}"
        :filter-render="renderFilter(Column, grounpColumn.filterType)"
        :edit-render="renderFilter(Column, grounpColumn.editType)"
      >
        <template v-slot="{ row }">
          <span>{{ row[grounpColumn.field] | bspTableFilter({'1':grounpColumn.dataType,'2':dicts}) }}</span>
        </template>
        <vxe-table-column
          v-for="(Column,index) in grounpColumn.grounpColumns"
          :key="index"
          v-bind="{...Column}"
          :filter-render="renderFilter( Column, Column.filterType)"
          :edit-render="renderFilter(Column, Column.editType)"
        >
          <template v-slot="{ row }">
            <span>{{ row[Column.field] | bspTableFilter({'1':Column.dataType, '2':dicts}) }}</span>
          </template>
        </vxe-table-column>
      </vxe-table-column>
    </template>

    <!-- 正常表格列 -->
    <template v-slot="{ row }">
      <slot :row="row" />
    </template>
    <!--    用于自定义编辑框    -->
    <template
      v-slot:edit="{ row }"
      v-if="slotEdit"
    >
      <slot
        name="edit"
        :row="row"
      />
    </template>
    <!--    用于自定义过滤框    -->
    <template
      v-slot:filters="{ column, context}"
      v-if="slotFilter"
    >
      <slot
        name="filters"
        :row="{ column, context }"
      />
    </template>
  </vxe-table-column>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        name: "CiicTableColumn",
        props: {
            //过滤规则
            filterRule: {
                type: String,
                default: 'like'
            },
            //过滤类型，input、select
            filterType: {
                type: String,
                default: ''
            },
            //编辑类型
            editType: {
                type: String,
                default: ''
            },
            //下拉选择的列表
            optionsList: {
                type: Array,
                default: () => (
                    []
                )
            },
            //下拉选择的options选项
            optionProps: {
                type: Object,
                default: () => (
                    { value: 'codeItemId', label: 'codeItemName' }
                )
            },
            //下拉选择的setId
            setId: {
                type: String,
                default:""
            },
            // 要移除的 itemId,隔开多个
            remNum: {
                type: String,
                default: ''
            },
            //下拉选择是否可以多选
            multiple: {
                type: Boolean,
                default:false
            },
            //下拉选择是否支持搜索
            filterable: {
                type: Boolean,
                default: true,
            },
            //时间格式
            timeFormat: {
                type: String,
                default:'yyyy-MM-DD'
            },
            //日期选择框的类型
            datePickerType: {
                type: String,
                default:'daterange'
            },
            //分组数据
            grounpColumns:{
                type: Array,
                default: () => (
                    []
                )
            },
            //是否需要自定义编辑框
            slotEdit:{
                type: Boolean,
                default:false
            },
            //是否需要自定义过滤框
            slotFilter: {
                type: Boolean,
                default:false
            },
        },
        computed: {
            ...mapGetters([
                'dicts',
            ]),
        },
        inject: ['$filtersStore','$tableColums'],
        created(){
            if(this.filterType == 'select' ||this.filterType == 'dict') {
                this.filterRule = 'in'
            }
            if(this.filterType == 'datePicker'){
                this.filterRule='between'
            }
            if(this.filterType == 'input' ){
                this.filterRule='like'
            }
            if(this.setId){
                this.optionsList = this.filterSelctList(this.setId,this.remNum);
            }
            if(this.$attrs.field){
                this.$filtersStore[this.$attrs.field] = {
                    operator: this.filterRule,
                };
                this.$tableColums.push({...this.$attrs}); //获取表格的表头集合

            }else if(this.grounpColumns.length>0){ //表头分组
                let colIndex = 0; //第一级表头的colIndex；
                this.grounpColumns.forEach(function(grounpColumn){ //两级表头
                    if(grounpColumn.grounpColumns){ //三级表头
                        grounpColumn.grounpColumns.forEach(function(column){
                            this.$filtersStore[column.field] = {
                                operator: this.filterRule,
                            };
                            column.colIndex = 1;
                        }.bind(this));
                        grounpColumn.colIndex = grounpColumn.grounpColumns.length;
                    }else{
                        this.$filtersStore[grounpColumn.field] = {
                            operator: this.filterRule,
                        };
                        grounpColumn.colIndex = 1;
                    }
                    colIndex+=grounpColumn.colIndex;
                }.bind(this));
                let grounpColumn = {
                    title:this.$attrs.title,
                    grounpColumns:this.grounpColumns,
                    colIndex,
                };
                this.$tableColums.push(grounpColumn);  //获取表格的表头集合
            }
            this.editTypeHandler()
            this.filterTypeHandler()
        },
        data(){
            return {
                // 表格列属性
                tableColumnAttrs: {
                },
                selectList:[]
            }
        },
        methods:{
            renderFilter(column, renderType){
                if(renderType){
                    if(renderType == 'input'){
                        return {name: 'ElInput', props: {placeholder: '请输入' + column.title}}
                    }else if(renderType == 'select' ||renderType == 'dict'){
                        let optionsList = []
                        // select列表过滤
                        optionsList = column.optionList ? column.optionList : this.filterSelctList(column.setId, column.remNum)
                        return {name: 'ElSelect', optionProps: this.optionProps, options: optionsList, props: {multiple: column.multiple, placeholder: '请选择'}}
                    }else if(renderType == 'datePicker'){
                        return {name: 'ElDatePicker', props: {type: 'daterange', format: "yyyy 年 MM 月 dd 日", valueFormat:"yyyy-MM-dd", rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                    }else if(this.editType == 'inputNumber'){
                        return {name: 'ElInputNumber'}
                    }
                }
            },
            //  判断表头筛选的类型
            filterTypeHandler(){
                if(this.filterType){
                    this.tableColumnAttrs.filters = [{data: ''}]
                    if(this.filterType == 'input'){
                        this.tableColumnAttrs.filterRender= {name: 'input', props: {placeholder: '请输入' + this.$attrs.prop},events: {keyup: this.enterFilterEvent}}
                    }else if(this.filterType == 'select' ||this.filterType == 'dict'){
                        this.tableColumnAttrs.filterRender={name: 'ElSelect', optionProps: this.optionProps, options: this.optionsList, props: {multiple: this.multiple, placeholder: '请选择'}}
                    }else if(this.filterType == 'datePicker'){
                        this.tableColumnAttrs.filterRender={name: 'ElDatePicker', props: {type: this.datePickerType, format: this.timeFormat, valueFormat: "yyyy-MM-dd", rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                    }
                }
            },
            // 判断编辑类型
            editTypeHandler(){
                if(this.editType){
                    if(this.editType == 'input'){
                        this.tableColumnAttrs.editRender= {name: 'ElInput'}
                    }else if(this.editType == 'inputNumber'){
                        this.tableColumnAttrs.editRender= {name: 'ElInputNumber'}
                    } else if(this.editType == 'select'||this.editType == 'dict'){
                        this.tableColumnAttrs.editRender={name: 'ElSelect', optionProps: this.optionProps, options:this.optionsList, props: {multiple: this.multiple}}
                    }else if(this.editType == 'datePicker'){
                        this.tableColumnAttrs.editRender={name: 'ElDatePicker', props: {type: 'date', format: this.timeFormat, valueFormat: "yyyy-MM-dd"}}
                    }
                }
            },
            //过滤下拉列表字段
            filterSelctList(setId,remNum){
                if(setId && setId!=''&&this.dicts){
                    return remNum?
                        this.dicts.codeList[setId].filter((item) => {
                            return remNum.indexOf(item.codeItemId) < 0;
                        }):
                        this.dicts.codeList[setId];
                }else{
                    return this.optionsList
                }
            },
            //转换下拉框下的字段
            dataTransform(data) {
                if(JSON.stringify(this.fileType)==="{}"){
                    return data
                }
                let _data = [];
                for (let i = 0; i < data.length; i++) {
                    _data[i] = {};
                    _data[i].label = data[i][this.fileType.label];
                    _data[i].value = data[i][this.fileType.value];
                }
                return _data;
            },
          // 回车提交
          enterFilterEvent ({ context, column }, event) {
            if (event.keyCode === 13) {
              context.confirmFilter()
            }
          },

        }
    }
</script>

<style scoped>

</style>
