/** 工资条 */
import request from '@/router/axios';
const urlPrefix = '';

// 查询报税截止期
export function getIsDeadlineForTax(obj) {
  
  return request({
    url:urlPrefix + '/wt/batchCopy/getIsDeadlineForTax',
    method: 'post',
    data: obj
  })
}

// 复制接口
export function copySalaryBaseDetail(obj) {
  return request({
    url:urlPrefix + '/wt/batchCopy/copySalaryBaseDetail',
    method: 'post',
    data: obj
  })
}

// 是否是可导入的计税类型
export function getIsMayCopyTaxType(obj) {
  return request({
    url:urlPrefix + '/wt/batchCopy/getIsMayCopyTaxType',
    method: 'post',
    data: obj
  })
}

// 导入校验第一步
export function copySalaryStepOne(obj){
  return request({
    url:urlPrefix + '/wt/batchCopy/copySalaryStepOne',
    method: 'post',
    data: obj
  })
}

// 获取第一步校验结果
export function getCopyOneResult(obj){
  return request({
    url:urlPrefix + '/wt/batchCopy/getCopyOneResult',
    method: 'post',
    data: obj
  })
}

// 获取表头信息
export function getTableColumn(obj){
  return request({
    url:urlPrefix + '/wt/batchCopy/getTableColumn',
    method: 'post',
    data: obj
  })
}

// 复制明细下一步
export function copySalaryStepTwo(obj){
  return request({
    url:urlPrefix + '/wt/batchCopy/copySalaryStepTwo',
    method: 'post',
    data: obj
  })
}

// 获取表明细
export function getTableData(obj){
  return request({
    url:urlPrefix + '/wt/batchCopy/getTableData',
    method: 'post',
    data: obj
  })
}

// 更新复制明细
export function updateCopyDetail(obj){
  return request({
    url:urlPrefix + '/wt/batchCopy/updateCopyDetail',
    method: 'post',
    data: obj
  })
}


// 删除明细数据
export function delCopyDetail(obj){
  return request({
    url:urlPrefix + '/wt/batchCopy/delCopyDetail',
    method: 'post',
    data: obj
  })
}

// 复制批次点完成
export function copyConfirm(obj){
  return request({
    url:urlPrefix + '/wt/batchCopy/copyConfirm',
    method: 'post',
    data: obj
  })
}

// 点返回或上一步按钮删除数据的锁定
export function delLock(obj){
  return request({
    url:urlPrefix + '/wt/batchCopy/delLock',
    method: 'post',
    data: obj
  })
}

// 校验临时批次是否生成正式批次
export function checkCopyEnd(obj){
  return request({
    url:urlPrefix + '/wt/batchCopy/checkCopyEnd',
    method: 'post',
    data: obj
  })
}
