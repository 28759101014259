<template>
  <div>
    <div class="customer-info">
      <div class="customer-info-item">
        <div class="customer-info-title">
          <!--图标-->
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-qiyexinxi"></use>
          </svg>
          <span>项目设置</span>
        </div>
        <div class="customer-info-button">
          <!--展开-->
          <svg v-if="show" class="icon" aria-hidden="true" @click="changeShow">
            <use xlink:href="#icon-zhankaizhuangtai"></use>
          </svg>
          <!--收起-->
          <svg v-else class="icon" aria-hidden="true" @click="changeShow">
            <use xlink:href="#icon-shouqizhuangtai"></use>
          </svg>
        </div>
      </div>
    </div>
    <div class="items" v-show="show">
      <el-row class="itemLists">
        <el-col class="itemBox" v-for="emp in itemLists" :key="emp.id">
          <div class="itemTitle">
            <p >{{emp.itemName}}/{{emp.itemEnName}}</p>
            <p @click="settingItem">
              <!--设置-->
              <svg class="icon">
                <use xlink:href="#icon-setting"></use>
              </svg>
              设置项目
            </p>
          </div>
          <!--<el-row >-->
          <draggable
            tag="el-row"
            :gutter="40"
            :list="emp.itemList"
            class="itemLists"
            :group="'setItem'+emp.id"
            forceFallback="true"
            :options="{ghostClass:'ghostClass',fallbackClass:'fallbackClass'}"
          >
            <el-col :span="emp.id === 3?24:12"
                    class="itemList"
                    v-for="element in emp.itemList"
                    :key="element.id"
            >
              <div class="itemListBox">
                <p>{{element.name}}/{{element.enName}}</p>
                <p>{{element.money}}</p>
              </div>

            </el-col>
          </draggable>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      ref=""
      width="700px"
      id="choose"

      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="设置显示项目"
      :visible.sync="dialogheaderVisible"
    >
      <chooseBox :dynamic-tags="dynamicTags" :choose-lists="chooseLists"></chooseBox>
      <div
        class="marginTop30"
        style="text-align: center"
      >
        <el-button
          type="primary"
          @click="saveChoose()"
        >
          确认
        </el-button>
        <el-button @click="dialogheaderVisible = false">
          取消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import chooseBox from "../chooseBox/chooseBox";

  export default {
    name: "paySquenceItem",
    components: { draggable, chooseBox },
    props: {
      idx: {
        type: Number,
        default: 0
      },
      itemLists: {
        type: Array,
        default: function() {
          return [];
        }
      }
    },
    data() {
      return {
        show: true,
        dialogheaderVisible: false,
        dynamicTags: [
          { index: 0, chooseList: { i: 0, name: "雇员姓名" } }, {
            index: 4,
            chooseList: { i: 0, name: "雇员姓名" }
          }, { index: 4, chooseList: { i: 1, name: "雇员姓名41" } }
        ],
        chooseLists: [
          { index: 0, type: "雇员信息", chooseList: [{ i: 1, name: "雇员信息" }] },
          { index: 1, type: "职位", chooseList: [{ i: 0, name: "雇员姓名1" }, { i: 1, name: "雇员信息1" }] },
          { index: 2, type: "雇员信息2", chooseList: [{ i: 0, name: "雇员姓名2" }, { i: 1, name: "雇员信息2" }] },
          { index: 3, type: "雇员信息3", chooseList: [{ i: 0, name: "雇员姓名3" }, { i: 1, name: "雇员信息3" }] },
          { index: 3, type: "雇员信息3", chooseList: [] }
        ]
      };
    },
    methods: {
      changeShow() {
        debugger;
        this.show === true ? this.show = false : this.show = true;
      },
      settingItem() {
        debugger;
        this.dialogheaderVisible = true;
      },
      saveChoose() {
        console.log("保存项目");
      }
    }
  };
</script>

<style scoped lang='scss'>
  .itemBox {
    padding: 0 10px;
  }

  .itemLists {
    margin-top: 20px;
  }

  .itemList {
    margin-bottom: 20px;
    padding: 0 20px;
    cursor: move;
  }

  .itemListBox {
    background-color: #f3f3f3;
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    padding: 0 20px;
    border-radius: 2px;
  }

  .itemList p {
    color: #666666;
    font-size: 12px;
  }

  .itemTitle p.drag {
    flex: 1;
    cursor: move;
  }

  .itemList p + p {
    color: #000000;
    font-size: 14px;
  }

  .itemTitle {
    background: #FFEDBE;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
  }

  .itemTitle p {
    color: #000000;
    font-size: 14px;
    line-height: 30px;
  }

  .itemTitle p + p {
    color: #AA5401;
    font-size: 12px;
    cursor: pointer;
  }

  .itemTitle p .icon {
    margin-right: 5px;
  }
</style>
