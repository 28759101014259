<template>
  <div>
    <el-button type="primary" @click="dialogVisible = true">确 定</el-button>
  <bsp-dialog
    :dialogRef="dialogRef"
    width="30%"
    title="弹框"
    :dialogVisible.sync="dialogVisible"
    @close="closeDialog"
  >
    <div slot="body">
      <span>这是一段信息</span>
    </div>
    <span class="dialog-footer" slot="footer">
      <el-button type="primary" @click="closeDialog">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </span>
  </bsp-dialog>
  </div>
</template>

<script>
  import BspDialog from "@/components/bsp/bsp-dialog/bsp-dialog"
  export default {
    name: "bsp-dialog-demo",
    components: {
      BspDialog
    },
    data(){
      return {
        dialogRef: "dialogRef",
        dialogVisible: false
      }
    },
    methods:{
      // 关闭弹框
      closeDialog() {
        console.log("false -----------------");
        this.dialogVisible = false;
      }
    }
  };
</script>

<style scoped>

</style>
