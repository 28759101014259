import request from '@/router/axios';
//const urlPrefix = process.env.VUE_APP_LOCAL_DEVELOPMENT == 'true' ? '' : '/sys'
//const urlPrefix = '/sys'

export function getLeadsListPagte(obj) {
  return request({
    url: '/leads/page',
    method: 'POST',
    data: obj,
  });
}

export function savetLeadsBo(obj) {
    return request({
      url: '/leads',
      method: 'POST',
      data: obj,
    });
}
export function checkCusName(obj) {
    return request({
      url: '/leads/check-cus-name',
      method: 'get',
      params: obj,
    });
}

export function deleteLeadsBo(obj) {
    return request({
      url: '/leads/'+obj,
      method: 'delete',
      //data: obj,
    });
}

export function querLeadsBoById(obj) {
    return request({
      url: '/leads/'+obj,
      method: 'get',
      //data: obj,
    });
}

export function updateLeadsBo(obj) {
    return request({
      url: '/leads',
      method: 'put',
      data: obj,
    });
}
// 转为客户
export function saveTranCus(obj) {
    return request({
      url: '/leads/trans/cus',
      method: 'post',
      data: obj,
    });
}
