import request from '@/router/axios';
const urlPrefix = '';

// 北京中智退票列表
export function searchCiicRTicketList(obj) {
  return request({
    url:urlPrefix + '/returnedTicket/ciic/list',
    method: 'post',
    data: obj
  })
}

// 分支机构退票列表
export function searchBranchList(obj) {
  return request({
    url:urlPrefix + '/returnedTicket/branch/list',
    method: 'post',
    data: obj
  })
}

// 分支机构退票保存
export function upadteRTicket(obj) {
  return request({
    url:urlPrefix + '/returnedTicket/update',
    method: 'post',
    data: obj
  })
}
// 分支机构退票提交
export function commitReturndTicket(obj) {
  return request({
    url:urlPrefix + '/returnedTicket/commit',
    method: 'post',
    data: obj
  })
}

// 分支机构退票提交
export function updateBankInfo(obj) {
  return request({
    url:urlPrefix + '/returnedTicket/updateBankInfo',
    method: 'post',
    data: obj
  })
}

// 导出明细
export function exportReturndTicket(obj) {
  return request({
    url: urlPrefix + "/returnedTicket/export",
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
