<template>
  <div>
    <bsp-table
      :data.sync="list"
      :tree-config="true"
      :ciic-data-filter.sync="listQuery"
      @select-change="selectChange"
      @select-all="selectChange"
      @ciic-data-refresh="loadList"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
    >
      <vxe-table-column type="selection" width="60" fixed="left"/>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>

      <bsp-table-column title="业务主键" field="bizId" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.bizId}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="雇员/客户名称" field="name" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.name}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="操作人名称" field="userName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.userName}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="操作时间" field="createtime" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.createTime}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="修改项" field="modifyItem" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.modifyItem}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="修改前" field="beforeModify" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.beforeModify}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="修改后" field="afterModify" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.afterModify}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="模块" field="mouduleType" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.mouduleType}}</span></template>
      </bsp-table-column>
    </bsp-table>

  </div>

</template>

<script>

import BspTable from "@/components/bsp/bsp-table/bsp-table";
import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
import {batchUpdateCusCaclWay, checkCusIsImported, searchCusList} from '@/api/cus/payableConf';
import {queryersonList} from "@/api/person/person";
import {operationHistoryList} from "@/api/audit/list";
import {mapGetters} from 'vuex';

export default {
  name: "cusList",
  inject: ['reload'],
  components: {
    BspTable,
    BspTableColumn,
  },
  computed: {
    ...mapGetters([
      'dicts'
    ]),
  },
  props: {
    currentTab: {
      type: String,
      default: ''
    },
  },

  created() {
    this.loadList();
  },

  data() {
    return {
      // 用于接收后台返回的分页数据
      list: [],
      //分页查询对象
      listQuery: {
        limit: 50,
        page: 1,
        total: 0,
        paymentNo: '',
        paymentStatus: '',
        accountingMonth: '',
        paymentType: '0'
      },
      batchDialogTitle: '修改操作人-客户转移',
      multipleSelection: [],
      batchDialogVisible: false,
      operatorList:[],
      operator: "",
      updateoperatorList:[],
      updateoperator:{
        cusId:'',
        beforeOperator:'',
        afterOperator:''
      }
    }
  },

  methods: {


    batchCancel() {
      this.batchDialogVisible = false;
    },
    selectChange(value) {
      console.log(value)
      if (value.selection) {
        this.multipleSelection = []
        for (let index = 0; index < value.selection.length; index++) {
          const element = value.selection[index]
          this.multipleSelection.push(element)
        }
        console.log('this.multipleSelection:' + this.multipleSelection)
      }
    },
    loadList() {
      this.loading = true;
      operationHistoryList(this.listQuery).then(res => {
        this.list = res.data.records;
        this.listQuery.total = res.data.total;
        this.loading = false;
        this.multipleSelection = []
      })
    },



  }
}
</script>

<style scoped>

</style>
