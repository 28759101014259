<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right" v-if="this.listQuery.batchStatus=='00900' && detailVisible=='false'"  @click="exportDetails(null)" :disabled="isDisabled">批量导出</el-button>
<!--      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900'"  @click="importDetail">导入</el-button>-->
<!--      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900'"  @click="recalculate">重新计算</el-button>-->
      <el-button type="primary" size="small" fixed="right" v-if="this.listQuery.batchStatus === '00900' && detailVisible=='false'"  @click="commitBatch" :disabled="isDisabled">提交</el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='false'" @click="deleteSubsidiesBatchList" :disabled="isDisabled">删除</el-button>
<!--      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='true' " @click="downEmpSalary">下载工资条</el-button>-->
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='true' && this.listQuery.batchStatus=='00900' " @click="saveEvent" :disabled="isDisabled">保存</el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='true'"  @click="exportDetailsTwo" :disabled="isDisabled">导出</el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='true'"  @click="goback" :disabled="isDisabled">返回</el-button>

    </div>
    <el-form :inline="true" :model="listQuery" class="editForm" label-width="50px" v-if="detailVisible=='false'">
      <el-form-item label="状态:">
        <el-select clearable class="status-select" v-model="listQuery.batchStatus" @change="__querySubsidiesBatchList()">
          <el-option label="未提交" value="00900"></el-option>
          <el-option label="已提交" value="00901"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!--批次列表-->
    <bsp-table
      :data.sync="list"
      @select-change="selectChange"
      @select-all="selectAll"
      :tree-config="true"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="__querySubsidiesBatchList"
      show-header-overflow show-overflowwidth  show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
      v-if="detailVisible=='false'"
    >

      <vxe-table-column type="selection" width="60" fixed="left"/>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <bsp-table-column  title="导入批次编号" field="batchCode"  filter-type="input"  width="100"  fixed="left"  >
        <template slot-scope="scope">   <span>{{scope.row.batchCode}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column v-if="this.currentTab=='31278300703' || this.currentTab=='31278300704' || this.currentTab=='31278300707' "
                        title="客户名称"  field="cusName"    filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.cusName}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="受托方"  field="orgName"    filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.orgName}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="计税区域" v-if="this.currentTab!='31278300707'" field="taxArea"   width="100" filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.taxArea}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="税金所属月份"  v-if="this.currentTab!='31278300707'"  field="taxMonth"   width="120" filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.taxMonth}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="实际操作人"  field="operatorName"     filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.operatorName}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="创建时间"  field="operateTime"   width="120" filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.operateTime}}</span>  </template>
      </bsp-table-column>


      <bsp-table-column   :title="textMap[taxTotalStatus]"  field="taxTotal"     filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.taxTotal }}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="服务总金额"  field="serviceTotal"   filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.serviceTotal }}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="状态"  field="statusName"    fixed="left" filter-type="input" ><!-- filter-type="select"    filter-rule="in"   set-id="3004"-->
        <template slot-scope="scope">  <span>{{scope.row.statusName }}</span>  </template><!--statusName | dictFilter(dicts)-->
      </bsp-table-column>


      <bsp-table-column  title="操作"  fixed="right" align="center" >
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button  type="text" @click="showDetails(scope.row)" >查看</el-button>
            <el-button type="text"     @click="exportDetails(scope.row)" >导出</el-button>
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>


<!--    &lt;!&ndash;大库/客户名义报税查看增加客户列表&ndash;&gt;-->
    <detailImportCusList v-if="detailVisible=='detailCus'"
                      :detailImportCusList.sync="detailImportCusList"
                      :cuslistQuery.sync="cuslistQuery"
                      refname="detailImportCusList"
                      @lookDetail="lookDetail"
                      @rtnDetail="rtnDetail"
                      @submitData="submitData"
                      v-loading="loading"
    >
    </detailImportCusList>
    <!--明细列表-->
    <detailImportList v-if="detailVisible=='true'"
      :tableColumn.sync="tableColumn"
      :dataList.sync="dataList"
      :sumItem.sync="sumItem"
      :noChangeList.sync="noChangeList"
      refname="detailImportList"
      @getSelectData="getSelectData"
      v-loading="loading"
                      ref="detailImportList"
    >
    </detailImportList>



    <!--  提交录入发票信息开始-->
    <el-dialog v-dialogDrag  width="40%"
               :title="textMap['titleInfo']"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :visible.sync="billDialogVisible">
      <el-form  ref="form2" class="editFrom" :model="validateForm" label-width="168px" :rules="rules">
      <el-row>
        <el-col :span="20">
          <el-form-item label="发票抬头" prop="billTitle">
            <el-select clearable v-model="parameter.billsTitle" label-width="350px" placeholder="请选择" @change="billChange">
              <el-option
                v-for="item in billsInfoList"
                :key="item.BILLSTITLE"
                :label="item.BILLSTITLENAME"
                :value="item.BILLSTITLE"><!--BILLSTITLE -->
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="纳税人识别号">
              <el-input v-model.trim="parameter.taxNum"  disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="地址">
              <el-input v-model.trim="parameter.taxAdress"  disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="电话">
              <el-input v-model.trim="parameter.taxPhone"  disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="收件人" prop="billTitle">
              <el-select clearable v-model="parameter.recipients" label-width="350px" placeholder="请选择" @change="reciChange">
                <el-option
                  v-for="item in receAddrList"
                  :key="item.ID"
                  :label="item.RECEIVEDEPT"
                  :value="item.ID"><!-- ID-->
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="20">
            <el-form-item label="邮寄地址、邮编">
              <el-input v-model.trim="parameter.emailAddress"  disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="marginTop30"  style="text-align: center"  >
        <el-button type="primary"  @click="billSave" >  保存  </el-button>
        <el-button @click="billDialogVisible=false">  取消 </el-button>
      </div>
    </el-dialog>
    <!-- 提交录入发票信息结束-->

  </div>
</template>
<script>
  import detailImportCusList from "@/views/entrust/detailImport/detailImportCusList";
  import detailImportList from "@/views/entrust/detailImport/detailImportList";
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {querySubsidiesBatchList, deleteSubsidiesBatchList, getBillsInfo, commitBatch, exportDetails, getdynamicTable,getdynamicList, getDetailImportBatchCusList} from '@/api/entrust/buTie';
  import {downloadFile,downloadPdfFile} from '@/util/util';
  import {updateDetail  } from '@/api/entrust/detailImport';
  import {mapGetters} from 'vuex';

  export default {
    name: 'buTie',
    inject:['reload'],
    components: {
      BspTable,
      BspTableColumn,
      detailImportList,
      detailImportCusList
    },
    computed: {
      ...mapGetters([
        'dicts'
      ]),
    },
    props: {
      currentTab: {
        type: String,
        default: '31278300703'
      },

    },
    created() {
      //列表显示项描述不一样
      if (this.currentTab == '31278300701' || this.currentTab == '31278300702') {
        this.taxTotalStatus = "dkTaxTotal";
      } else if (this.currentTab == '31278300707') {
        this.taxTotalStatus = "dbxTaxTotal";
      } else {
        this.taxTotalStatus = "pdkTaxTotal";
      }
      this.__querySubsidiesBatchList();
    },
    data() {
      return {

        // billDialogVisible:false,
        // // 遮罩
        loading: false,
        isDisabled: false,
        // 用于接收后台返回的分页数据
        list: [],
         detailVisible: 'false',
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          bizType: '31278300703',
          source: '1',
          batchStatus: '00900'//默认为未提交
        },
        parameter: {
          batchImportIds:'',
          bizType:this.currentTab,
          cusId:'',
          orgId:'',
          billsTitle:'',//BILLSTITLENAME
          taxNum:'',//F204204
          taxAdress:'',//F204205
          taxPhone:'',//F204206
          recipients:'',//RECEIVEDEPT
          emailAddress:'',//postcode
          subIds:'',//明细选择导出
          subIdFlag:'',//明细导出标识
        },
          //多选数组
        multipleSelection: [],
        textMap: {
          dkTaxTotal: '税金总金额',
          pdkTaxTotal: '工资往来总金额',
          dbxTaxTotal: '代报销总金额',
          titleInfo:'选择发票信息',
        },
        taxTotalStatus:'dkTaxTotal',
        detailInfo:{
          batchImportId:'',//批次id
          bizType:'',//业务类型
          status:'',//提交状态，未提交/已提交
          cusId:'',
        },
        dataList: [],
        tableColumn: [], //显示表头
        sumItem:[],//需要合计的列
        detailImportCusList:[],
        cuslistQuery: {
          limit: 50,
          page: 1,
          total: 0,
          bizType: '',
          batchStatus: '00900',//默认为未提交
          batchImportId:'',
        },
        billsInfoList:[],//发票信息列表
        receAddrList:[],//收件人信息列表
        updateDetailList:{
          updList:[],
          batchImportId:'',
          noChangeList:[],
        },//编辑后的数据
        rowVariable:null,
      }
    },
    methods: {
      __querySubsidiesBatchList: function () {
        querySubsidiesBatchList(this.listQuery).then(res => {
          console.log("传递的参数为 =====>> ");
          console.log(this.listQuery);
          this.listQuery.bizType = this.currentTab;
          this.loading = true;
            if (res.status !== 0) {
              this.listQuery.total = res.data.total;
              this.list = res.data.records;
              console.log("list =====>> ");
              console.log(this.list);
              this.loading = false
            } else {
              this.loading = false
            }
        })
      },

      selectChange(value) {
        console.log(value);
        debugger
        if (value.selection) {
          this.multipleSelection = [];
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index];
            this.multipleSelection.push(element);
          }
        }
      },

      //复选框全选事件
      selectAll({checked, selection}) {
        debugger
        if (checked) {
          this.multipleSelection = selection
        } else {
          this.multipleSelection = [];
        }
        console.log("this.multipleSelection:" + this.multipleSelection);
      },

      /*// 复选框选择事件
      selectChange(checked) {
        console.log('复选框点点点。。。');
        if (checked.checked) {
          const list = checked.selection;
          console.log(list);
          this.multipleSelection = [];
          for (let index = 0; index < list.length; index++) {
            const element = list[index];
            // const element = list[index].batchCode;
            this.multipleSelection.push(element);
          }
        } else {
          this.multipleSelection=[];
        }
      },*/

      // 底部合计
      footerMethod({columns, data}) {
        return [
          columns.map((column, columnIndex) => {
            if (columnIndex === 0) {  return '合计' }
            if (['taxTotal','serviceTotal'].includes(column.property)) {
              return this.$options.filters['moneyFilter'](this.$utils.sum(data, column.property))
            }
            return null
          })
        ]
      },

      //单条导出
      exportDetails(row){
        this.loading=true;
        this.parameter.batchImportIds="";
        if(row!=null && row.id.length>0){
          console.log("单次操作");
          this.parameter.batchImportIds=row.id;//对应批次id
        }else{
          if(this.multipleSelection.length<=0){ this.$message.error("请选择要导出的批次！");this.loading=false;return; }
          if(this.multipleSelection.length>0){
            //将选中的id组装为string
            this.multipleSelection.forEach(item=> {
              this.parameter.batchImportIds=this.parameter.batchImportIds+","+item.id;
            });
          }
        }

        console.log("批次id："+this.parameter.batchImportIds);
        this.parameter.bizType=this.currentTab;//对应业务类型 todo 怎么从tables里面直接获取相关值
        exportDetails(this.parameter).then(res => {
          this.loading=false,
          downloadFile(res);
        })
      },

      //删除批次信息
      //TODO 需要测试多种情况下的删除，删除后将当前批次中的补贴数据流回到补贴明细列表
      deleteSubsidiesBatchList: function() {
        console.log('点击删除补贴批次');
        console.log(this.multipleSelection)
        const length = this.multipleSelection.length;
        if (length === 0) {
          alert('请至少选择一条数据');
        } else {
          let batchCodes = [];
          this.multipleSelection.forEach(item=> {
            batchCodes.push(item.batchCode);
          });
          console.log('传递的参数为：');
          console.log(batchCodes);
          deleteSubsidiesBatchList(batchCodes).then(res => {
            if (res.status == 200) {
              alert(res.data.msg);
              //
              //
              // console.log('响应成功');
              // if (res.data.statusCode === '1') {
              //   alert('接口返回成功！！！');
              //   // console.log('共校验：' + res.data.novalidatePass + ' 条数据');
              //   // console.log('通过：' + res.data.validatePass + ' 条数据');
              //   // console.log('提示信息：' + res.data.tipInfo);
              //   // console.log('错误信息：' + res.data.errorInfo);
              //   // console.log('结果信息：' + res.data.resultInfo);
              // } else {
              //   alert(res.msg);
              // }
            } else {
              alert("删除失败")
            }
            this.__querySubsidiesBatchList();
          })
        }
      },

      //查看详情
      showDetails(row) {
        console.log("批次id："+row.id+";业务类型："+row.bizType+";   this.listQuery.batchStatus --> " + this.listQuery.batchStatus);
        this.rowVariable = row;
        if((row.bizType=="31278300701" || row.bizType=="31278300703") && this.listQuery.batchStatus=="00901"){
          this.detailVisible='detailCus';
          this.cuslistQuery.batchImportId=row.id;
          this.cuslistQuery.bizType=row.bizType;
          this.__getDetailImportBatchCusList();
        }else {
            this.detailVisible='true';
            this.detailInfo.batchImportId=row.id;
            this.detailInfo.bizType=row.bizType;
            this.detailInfo.status=row.status;
            this.detailInfo.cusId=row.cusid;
            this._getdynamicTable();
            this._getdynamicList();
        }
      },

      //动态表头
      _getdynamicTable:function(){
        console.log("查看对应的批次id："+this.detailInfo.batchImportId);
        getdynamicTable(this.detailInfo).then(res => {
          console.log("查询动态表头的返回数据：：：");
          console.log(res.data);
          if(res.data.data.success==="true"){
            this.tableColumn=res.data.data.tableColumn;
            this.sumItem=res.data.data.sumItem;
            this.noChangeList=res.data.data.noChangeList;
            // 处理数据 不能编辑的能编辑的
            console.log("==========================");
          if(this.listQuery.batchStatus==='00900'){
            for (let i = 0; i < this.tableColumn.length; i++) {
               if(this.noChangeList.indexOf(this.tableColumn[i].field)==-1){
                  if(this.tableColumn[i].setId && this.dicts.codeList[this.tableColumn[i].setId]){
                    this.tableColumn[i].editRender =  {
                        name:"$select",
                      options:this.dicts.codeList[this.tableColumn[i].setId],
                      optionProps:{
                        value:"codeItemName",
                        label:"codeItemName",
                        disabled:false
                      }
                    }
                  } else{
                    this.tableColumn[i].editRender = {name: 'input'}
                  }
               }
              }
          }
          }
        })
      },
      //动态数据
      _getdynamicList(){
        console.log("访问查询动态数据的方法：：：：：：：：：：")
        getdynamicList(this.detailInfo).then(res => {
          this.listQuery.total = res.data.total
          this.dataList = res.data.records
          console.log(this.dataList)
        })
      },
      //明细导出
      exportDetailsTwo(id){
        this.loading=true;
        this.parameter.subIds='';
        this.parameter.batchImportIds='';
        if(this.multipleDetailSelection.length>0){
          this.multipleDetailSelection.forEach(item=> {
            this.parameter.subIds=this.parameter.subIds+","+item.SUBID;
          });
          console.log("选中的subid:"+this.parameter.subIds);
        }else{
            this.parameter.batchImportIds= this.detailInfo.batchImportId;//对应批次id
        }
        console.log("批次id："+this.parameter.batchImportIds+"====");
        this.parameter.bizType=this.currentTab;//对应业务类型 todo 怎么从tables里面直接获取相关值
        this.parameter.cusId=this.detailInfo.cusId;
        exportDetails(this.parameter).then(res => {
          this.loading=false;
          downloadFile(res);
        })
      },
      rtnDetail(){this.detailVisible='false';},
      //
      // //重新计算
      // recalculate(){
      //   this.loading=true;
      //   //todo 添加加载
      //   //获取选中的批次信息
      //   if(this.multipleSelection.length<=0){ this.$message.error("请选择要重新计算的批次！");this.loading=false; return; }
      //   //将选中的id组装为string
      //   this.multipleSelection.forEach(item=> {
      //     this.parameter.batchImportIds=this.parameter.batchImportIds+","+item.id;
      //   });
      //   //调用重新计算的方法
      //   recalculate(this.parameter).then(res => {
      //     this.loading=false;
      //     if(res.data.data.success=="true"){
      //       this.$message.success("重新计算完成！");
      //     }else{
      //       this.$message.error(res.data.data.msg);
      //     }
      //   })
      // },

      //提交
      commitBatch(){
        this.loading = true;
        this.parameter.batchImportIds='';
        let flag='';
        if(this.multipleSelection.length<=0){ this.$message.error("请选择要提交的批次！");this.loading = false;return; }
        //将选中的id组装为string
        this.multipleSelection.forEach(item=> {
          // if(item.stOrgid!='1060344'){
          //   flag='true';
          // }
          this.parameter.orgId=item.orgid;
          this.parameter.batchImportIds=this.parameter.batchImportIds+","+item.id;
        });
        this.parameter.bizType=this.currentTab;

        console.log("this.parameter ==========>> ")
        console.log(this.parameter)
        // if(flag=='true' && this.parameter.bizType!='31278300701' && this.parameter.bizType!='31278300702'){
        //     //查询发票抬头信息 收件人信息
        //     getBillsInfo(this.parameter).then(res => {
        //       if(res.data.data.success=="true"){
        //           this.billsInfoList=res.data.data.billsInfoList;//发票信息
        //           this.receAddrList=res.data.data.receAddrList;//收件人信息
        //       }
        //     })
        //     this.billDialogVisible=true;
        // }else{ this.commonCommit();}
        this.commonCommit();
        this.loading = false;
      },
      // //发票信息保存
      // billSave(){
      //   console.log("billsTitle:"+this.parameter.billsTitle+";recipients:"+this.parameter.recipients);
      //   if(this.parameter.billsTitle==""){
      //     this.$message.error("请选择发票信息！");
      //     return;
      //   }
      //   if(this.parameter.recipients==""){
      //     this.$message.error("请选择收件人！");
      //     return;
      //   }
      //   this.commonCommit();
      //   this.billDialogVisible=false;
      // },
      //公共提交方法
      commonCommit(){
        this.isDisabled = true;
        this.loading=true;
        commitBatch(this.parameter).then(res => {
          this.loading=false;
          this.isDisabled = false;
          if(res.data.data.msg==""){
            this.loading = false;
            this.$message.success("提交成功！");
          }else{
            this.loading = false;
            this.$message.error(res.data.data.msg);
          }
          this.__querySubsidiesBatchList();
        })
      },
      //
      //大库/客户名义点击批次中查看
      __getDetailImportBatchCusList() {
        this.cuslistQuery.bizType = this.currentTab;
        getDetailImportBatchCusList(this.cuslistQuery).then(res => {
          console.log("__getDetailImportBatchCusList 方法接受的返回结果：")
          console.log(res.data)
          if (res.data.code != 0) {
            this.cuslistQuery.total = res.data.total
            this.detailImportCusList = res.data.records
          } else {
          }
        })
      },
      //返回
      goback(){
        if(this.listQuery.batchStatus == "00900"){
          this.detailVisible='false';
        }else{
          this.showDetails(this.rowVariable);
        }
      },
      //查看详情
      lookDetail(row){
        console.log(row.bizType+"==="+row.cusid)
        this.detailVisible='true';
        this.detailInfo.batchImportId=row.importBatchId;
        this.detailInfo.bizType=row.bizType;
        this.detailInfo.status=row.status;
        this.detailInfo.cusId=row.cusid;
        this._getdynamicTable();
        this._getdynamicList();
      },
      //客户列表里面返回
      // rtnDetail(){this.detailVisible='false';},
      //提交之后重新刷新列表
      // submitData(){this.__getDetailImportBatchCusList();},
      // //发票抬头改变
      // billChange(val){
      //   console.log("发票抬头改变-val=",val);
      //   this.billsInfoList.forEach(item=> {
      //     if(val==item.BILLSTITLE){
      //       this.parameter.billsTitle=item.BILLSTITLENAME;
      //       this.parameter.taxNum=item.F204204;//F204204
      //       this.parameter.taxAdress=item.F204205;//F204205
      //       this.parameter.taxPhone=item.F204206;//F204206
      //       return;
      //     }
      //   });
      // },
      // reciChange(val){
      //   console.log("收件人信息改变-val=",val);
      //   this.receAddrList.forEach(item=> {
      //     if(val==item.ID){
      //       this.parameter.recipients=item.RECEIVEDEPT;
      //       this.parameter.emailAddress=item.POSTCODE;
      //       return;
      //     }
      //   });
      // },

      //明细选中的数据
      getSelectData(multipleDetailSelection){
        console.log(multipleDetailSelection)
        this.multipleDetailSelection=multipleDetailSelection;
      },
      //在线编辑保存
      saveEvent(){
        this.loading = true;
        console.log(this.$refs.detailImportList);
        let  test= this.$refs.detailImportList.$refs.xGrid.getRecordset();
        this.updateDetailList.updList=test.updateRecords;
        this.updateDetailList.batchImportId=this.detailInfo.batchImportId;
        this.updateDetailList.noChangeList=this.noChangeList;
        console.log("======================"+this.updateDetailList.updList);
        console.log(test);
        if(this.updateDetailList.updList==null ||this.updateDetailList.updList.length<=0){
          this.$message.error("请修改后再进行批量保存!");
          this.loading = false;
          return;
        }
        this.$confirm('您确认要保存修改数据吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading=true;
          updateDetail(this.updateDetailList).then(res => {
            console.log(res.data.data.info);
            if (res.data.data.info == "true") {
              this.$message.success("保存成功！");
              this._getdynamicList();
              this.loading = false;
              return;
            } else {
              this.$message.error({
                iconClass: "iconMessage",
                customClass: "errMessage",
                dangerouslyUseHTMLString: true,
                message: res.data.data.info,
                showClose: true,
                duration: 0
              });
              //this.$message.error(res.data.data.info);
              this.loading = false;
              return;
            }
          })
        }).catch(() => {
          this.loading = false;
          return;
        });
      } ,
      //工资单下载
      // downEmpSalary(){
      //   this.loading=true;
      //   if(this.multipleDetailSelection.length<=0){ this.$message.error("请选择一条数据！"); this.loading=false;return; }
      //   if(this.multipleDetailSelection.length>1){ this.$message.error("只能选择一条数据！"); this.loading=false;return; }
      //   this.multipleDetailSelection.forEach(item=> {
      //     this.salaryParam.subId=item.SUBID;
      //   });
      //   getEmpSalaryPath(this.salaryParam).then(res => {
      //     if(res.data.data.success=="true"){
      //       this.salaryParam.filePath=res.data.data.path;
      //       this.salaryParam.fileName=res.data.data.fileName;
      //       downEmpSalary(this.salaryParam).then(res => {
      //         console.log("工资单下载");
      //         if(this.salaryParam.fileName.indexOf(".pdf")!=-1){downloadPdfFile(res);}else{downloadFile(res);}
      //         this.loading=false;
      //       })
      //     }
      //   })
      // },

    }
  }
</script>
<style scoped>
  .copy-btn{
    cursor: pointer;
  }
  .ciic-table-operate{
    align-items: center;
  }
  .editForm{
    margin: 0;
  }
  .editForm .el-form-item{
    margin: 0;
  }
</style>
