<template>
  <div>

    <vxe-grid
      ref="xGrid"
      keep-source
      resizable
      show-footer
      show-footer-overflow
      height="600"
      :footer-method="footerMethodDetail"
      :columns="tableColumn"
      :data="dataList"
      :loading="loading"
      @select-change="selectDetailChange"
      @select-all="selectDetailAll"
      :custom-config="{storage: true}"
      :edit-config="{trigger: 'click', mode: 'row', showStatus: true}"
    ><!--:edit-config="{trigger: 'click', mode: 'cell'}"-->
   </vxe-grid>

  </div>
</template>
<script>
  import {mapGetters} from 'vuex';

  export default {
    name: 'detailImportList',
    inject:['reload'],
    components: {
    },
    computed: {
      ...mapGetters([
        'dicts'
      ]),
    },
    props: {
      currentTab: {
        type: String,
        default: ''
      },
      tableColumn:{
        type: Array,
        default () {
          return []
        }
      },
      dataList:{
        type: Array,
        default () {
          return []
        }
      },
      sumItem:{
        type: Array,
        default () {
          return []
        }
      },
      noChangeList:{
        type: Array,
        default () {
          return []
        }
      },

    },

    created() {
      console.log(this.tableColumn+"进行明细页面"+this.noChangeList);
      /*if(this.tableColumn!=null && this.tableColumn.length>0){
        this.tableColumn.forEach((item) => {
          //=="E204356"
          console.log(item.field+"是否包含："+this.noChangeList.indexOf(item.field));
          if(item.field=="" || this.noChangeList.indexOf(item.field)!=-1){
            item.editRender=null;
          }else{
            debugger
            console.log(item.field+"==="+item.title+"=="+item.editRender+"===");
            item.editRender['name']='$select';
            item.editRender['options']=this.cjrList;
            item.editRender['optionProps']= {value: 'codeId', label: 'codeName'};
            item.editRender['immediate']=true;
          }
         })
      }*/
      this.footerMethodDetail();
    },
    data() {
      return {
        // tableColumn: [
        //          {
        //            dataType: "text",
        //            field: "E204399" ,
        //            minWidth: "100px",
        //            title: "所属客户" ,
        //            type: "" ,
        //            visible: "visible",
        //            editRender:{
        //              name: 'input'
        //            }
        //          }
        //        ],
        //cjrList:[{codeId: '00901', codeName: '是'},{codeId: '00900', codeName: '否'}],
        // 遮罩
        loading: false,
        dialogVisible: false,
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          bizType: '',
          batchStatus: '00900'//默认为未提交
        },
        listForm: {},
        //多选数组
        multipleDetailSelection: [],
        params:{
          batchImportId:this.$route.batchId,
          bizType:this.$route.bizType,
          cusId:this.$route.cusId,
        },
      }
    },
    methods: {
      //合计
      footerMethodDetail({columns, data}) {
        return [
          columns.map((column, columnIndex) => {
            if (columnIndex === 0) {
              return '合计'
            }
            if (this.sumItem.includes(column.property)) {
              return this.$options.filters['moneyFilter'](this.$utils.sum(data, column.property))
            }
            return null
          })
        ]
      },

      selectDetailChange(value) {
        this.multipleDetailSelection = [];
        console.log(value);
        if (value.selection) {
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index];
            console.log(element.SUBID);
            this.subId=element.SUBID;
            this.multipleDetailSelection.push(element);
          }
        }
        this.$emit("getSelectData",this.multipleDetailSelection);
      },
      //复选框全选事件
      selectDetailAll({checked, selection}) {
        if(checked){
          this.multipleDetailSelection= selection
        }else{
          this.multipleDetailSelection=[];
        }
        this.$emit("getSelectData",this.multipleDetailSelection);
      },

      //返回
      rtn(){
        this.$router.go(-1);
      }
    }
  }
</script>
<style scoped>
  .copy-btn{
    cursor: pointer;
  }
  .ciic-table-operate{
    align-items: center;
  }
</style>
