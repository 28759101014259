<template>
  <div class="batchBox">
    <!--<div class="backBox">-->
      <!--<span v-if="activeIndex === 1" class="goback" @click="goback"><span class="iconfont">&#xe602;</span>返回</span>-->
      <!--<span v-if="activeIndex === 2" class="goback" @click="goTopage"><span class="iconfont">&#xe602;</span>完成</span>-->
    <!--</div>-->
    <div class="batchSteps">
      <div :class="activeIndex >= 0? 'active':''" class="batchStep">
        <div class="stepIndex">
          <svg class="icon" aria-hidden="true" @click="handleLock">
            <use xlink:href="#icon-shujuzhunbei_A"></use>
          </svg>
        </div>
        <p>数据准备</p>
        <p>设置批次相关信息并上传雇员明细数据</p>
      </div>
      <div class="stepSplit">
        <svg class="icon" aria-hidden="true" @click="handleLock">
          <use xlink:href="#icon-you"></use>
        </svg>
      </div>
      <div :class="activeIndex > 0? 'active':''" class="batchStep">
        <div class="stepIndex" v-if="activeIndex > 0">
          <svg class="icon" aria-hidden="true" @click="handleLock">
            <use xlink:href="#icon-biaotoupipei_A"></use>
          </svg>
        </div>
        <div class="stepIndex" v-else>
          <svg class="icon" aria-hidden="true" @click="handleLock">
            <use xlink:href="#icon-biaotoupipei_B"></use>
          </svg>
        </div>
        <p>表头匹配</p>
        <p>与所选客户薪资模板进行匹配</p>
      </div>
      <div class="stepSplit">
        <svg class="icon" aria-hidden="true" @click="handleLock">
          <use xlink:href="#icon-you"></use>
        </svg>
      </div>
      <div :class="activeIndex > 1? 'active':''" class="batchStep">
        <div class="stepIndex" v-if="activeIndex > 1">
          <svg class="icon" aria-hidden="true" @click="handleLock">
            <use xlink:href="#icon-daoruwancheng_A"></use>
          </svg>
        </div>
        <div class="stepIndex" v-else>
          <svg class="icon" aria-hidden="true" @click="handleLock">
            <use xlink:href="#icon-daoruwancheng_B"></use>
          </svg>
        </div>
        <p>查看结果</p>
        <p>上传数据并查看导入结果</p>
      </div>
    </div>
    <step1 v-if="activeIndex === 0" @onupload="addstep" @goback="goback" />
    <step2 v-else-if="activeIndex === 1" @next="addstep" @goback="goback"/>
    <step3 v-else-if="activeIndex === 2" @goback="goback" @next="goToTable"/>
  </div>
</template>
<script>
import step1 from './step1'
import step2 from './step2'
import step3 from './step3'
/* import step4 from './step4'*/
export default {
  components: { step1, step2, step3 },
  data() {
    return {
      activeIndex: 0
    }
  },
  methods: {
    // 下一步
    addstep() {
      this.activeIndex +=1
    },
    handleLock(){

    },
    goback() {
      this.activeIndex = this.activeIndex - 1
    },
    goTopage() {
      // 返回数据页面
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped  rel="stylesheet/scss">
  .backBox{
    /*margin-bottom: 20px;*/
  }
  .goback{
    width: 70px;
    padding:8px;
    border:1px solid #cccccc;
    color:#cccccc;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    line-height: 16px;
    font-size: 14px;
    cursor: pointer;
  }
  .goback:hover{
    border:1px solid #269cff;
    color:#269cff;
  }
  .batchBoxTitle{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
  }
</style>
