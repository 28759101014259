<template>
  <div>
    <el-form v-loading="loading" ref="listForm" class="editFrom" :model="listForm" label-width="100px" :rules="rules">
      <el-row>
        <el-col :span="24">
          <el-form-item label="原因：" prop="backReason">
            <el-input v-model="listForm.backReason" type="textarea"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import {downloadFile} from '@/util/util';
  import {mapGetters} from 'vuex';
  import {auditBackApply} from '@/api/agreement/agreement';

  export default {
    name: "agreementOprator",
    inject: ['reload'],
    props:{
      listForm:{
        type: Object,
        default () {
          return {
          }
        }
      },
    },
    computed: {
      ...mapGetters([
        'dicts', "userInfo"
      ]),
    },
    created() {
      console.log(this.listForm);
    },

    data() {
      return {
        operType: '',
          // 遮罩
        loading: false,
        rules: {
          backReason: [
            {required: true, message: '请选择填写退回原因', trigger: 'blur'}
          ],
        },
      }
    },

    methods: {
      //确定
      async auditBack(obj){
        this.loading = true;
        this.$refs["listForm"].validate( async (valid) => {
          if(valid){
            debugger
            obj.reason = this.listForm.backReason;
            console.log(this.listForm.backReason)
            auditBackApply(obj).then(res => {
              if(res.data.code == 0){
                this.$message.success('协议审核驳回成功');
                this.$emit("goback");
              } else {
                this.$message.error('协议审核驳回失败，请联系运维');
              }
            })
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
      },
    }
  }
</script>

<style scoped>

</style>
