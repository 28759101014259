<template>
  <div class="printBox">
    <div style="float: right; position: relative">支付单编号：{{this.data.paymentBillsBO.payCode}}</div>
    <div v-if="this.data.zjgzList  != null  && this.data.zjgzList.length > 0">
      <div>
        中籍工资
      </div>
      <div>
        <table border="1" style="width: 100%">
          <tr class="theader">
            <th>客户名称</th>
            <th>雇员姓名</th>
            <th>开发票所属月份</th>
            <th>应发金额</th>
            <th>应发补贴</th>
            <th>税前捐赠免税部分</th>
            <th>税前扣除社保个人部分</th>
            <th>税前医疗个人免税部分</th>
            <th>税前公积金个人免税部分</th>
<!--            <th>应税金额</th>-->
            <th>税率</th>
            <th>税金</th>
            <th>税后应客户要求扣款（工资）</th>
            <th>税后扣除社保个人部分</th>
            <th>税后扣除医疗个人计税部分</th>
            <th>税后扣除公积金个人计税部分</th>
            <th>其他免税补贴</th>
            <th>实发金额</th>
            <th>企业年金税前免税部分</th>
            <th>企业年金税后免税部分</th>
            <th>备注</th>
          </tr>
          <tr v-for="(item,idx) in this.data.zjgzList" :key="idx" style="text-align: center">
            <td>{{item.f001200}}</td>
            <td>{{item.e204344}}</td>
            <td>{{item.e204358}}</td>
            <td>{{item.e204256 | moneyFilterStr}}</td>
            <td>{{item.e204257 | moneyFilterStr}}</td>
            <td>{{item.e204228 | moneyFilterStr}}</td>
            <td>{{(Number(item.e204216) + Number(item.e204217)).toFixed(2) | moneyFilterStr}}</td>
            <td>{{item.e204218 | moneyFilterStr}}</td>
            <td>{{item.e204219 | moneyFilterStr}}</td>

<!--            <td>{{item.e204261 | moneyFilterStr}}</td>-->
            <td>{{item.e204263}}</td>
            <td>{{item.e204262 | moneyFilterStr}}</td>
            <td>{{item.e204236 | moneyFilterStr}}</td>
            <td>{{item.e203211 | moneyFilterStr}}</td>
            <td>{{item.e204233 | moneyFilterStr}}</td>
            <td>{{item.e204234 | moneyFilterStr}}</td>
            <td>{{item.e204241 | moneyFilterStr}}</td>
            <td>{{item.e204258 | moneyFilterStr}}</td>
            <td>{{item.e204497 | moneyFilterStr}}</td>
            <td>{{item.e204589 | moneyFilterStr}}</td>
            <td>{{item.e204260}}</td>
          </tr>
          <tr style="text-align: center">
            <td>合计</td>
            <td></td>
            <td></td>
            <td>{{this.sums.zjgzList.e204256.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjgzList.e204257.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjgzList.e204228.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjgzList.税前扣除社保个人部分.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjgzList.e204218.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjgzList.e204219.toFixed(2) | moneyFilterStr}}</td>
<!--            <td>{{this.sums.zjgzList.e204261.toFixed(2) | moneyFilterStr}}</td>-->
            <td></td>
            <td>{{this.sums.zjgzList.e204262.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjgzList.e204236.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjgzList.e203211.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjgzList.e204233.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjgzList.e204234.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjgzList.e204241.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjgzList.e204258.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjgzList.e204497.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjgzList.e204589.toFixed(2) | moneyFilterStr}}</td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="this.data.wjgzList  != null && this.data.wjgzList.length > 0">
      外籍工资
      <div>
        <table border="1" style="width: 100%">
          <tr>
            <th>客户名称</th>
            <th>雇员姓名</th>
            <th>开发票所属月份</th>
            <th>应发金额</th>
            <th>应发补贴</th>
            <th>税前捐赠免税部分</th>
            <th>税前扣除社保个人部分</th>
            <th>税前医疗个人免税部分</th>
            <th>税前公积金个人免税部分</th>
<!--            <th>应税金额</th>-->
            <th>税率</th>
            <th>税金</th>
            <th>税后应客户要求扣款（工资）</th>
            <th>税后扣除社保个人部分</th>
            <th>税后扣除医疗个人计税部分</th>
            <th>税后扣除公积金个人计税部分</th>
            <th>其他免税补贴</th>
            <th>实发金额</th>
            <th style="width: 8%">备注</th>
          </tr>
          <tr v-for="(item,idx) in this.data.wjgzList" :key="idx" style="text-align: center">
            <td>{{item.f001200}}</td>
            <td>{{item.e204344}}</td>
            <td>{{item.e204358}}</td>
            <td>{{item.e204257 | moneyFilterStr}}</td>
            <td>{{item.e204225 | moneyFilterStr}}</td>
            <td>{{item.e204228 | moneyFilterStr}}</td>
            <td>{{(Number(item.e204216) + Number(item.e204217)).toFixed(2) | moneyFilterStr}}</td>
            <td>{{item.e204218 | moneyFilterStr}}</td>
            <td>{{item.e204219 | moneyFilterStr}}</td>
<!--            <td>{{item.e204261 | moneyFilterStr}}</td>-->
            <td>{{item.e204263}}</td>
            <td>{{item.e204236 | moneyFilterStr}}</td>
            <td>{{item.e203211 | moneyFilterStr}}</td>
            <td>{{item.e204233 | moneyFilterStr}}</td>
            <td>{{item.e204234 | moneyFilterStr}}</td>
            <td>{{item.e204241 | moneyFilterStr}}</td>
            <td>{{item.e204258 | moneyFilterStr}}</td>
            <td>{{item.e204260}}</td>
          </tr>
          <tr style="text-align: center">
            <td>合计</td>
            <td></td>
            <td></td>
            <td>{{this.sums.wjgzList.e204257.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzList.e204225.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzList.e204228.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzList.税前扣除社保个人部分.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzList.e204218.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzList.e204219.toFixed(2) | moneyFilterStr}}</td>
<!--            <td>{{this.sums.wjgzList.e204261.toFixed(2) | moneyFilterStr}}</td>-->
            <td>{{this.sums.wjgzList.e204263}}</td>
            <td>{{this.sums.wjgzList.e204236.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzList.e203211.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzList.e204233.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzList.e204234.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzList.e204241.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzList.e204258.toFixed(2) | moneyFilterStr}}</td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="this.data.nzjList  != null && this.data.nzjList.length > 0">
      年终奖
      <div>
        <table border="1" style="width: 100%">
          <tr>
            <th>客户名称</th>
            <th>客户ID</th>
            <th>雇员姓名</th>
            <th>年终奖年度</th>
            <th>年终奖应发金额</th>
            <th>年终奖税前免税金额</th>
            <th>年终奖税前其他免税补贴</th>
            <th>年终奖税金</th>
            <th>年终奖税率</th>
            <th>年终奖税后应客户要求扣款</th>
            <th>税后扣除开户费</th>
            <th>年终奖实发金额</th>
            <th>备注</th>
          </tr>
          <tr v-for="(item,idx) in this.data.nzjList" :key="idx" style="text-align: center">
            <td>{{item.f001200}}</td>
            <td>{{item.e204399}}</td>
            <td>{{item.e204344}}</td>
            <td>{{item.e204245 | wageFormat("year")}}</td>
            <td>{{item.e204246 | moneyFilterStr}}</td>
            <td>{{item.e204250 | moneyFilterStr}}</td>
            <td>{{item.e204255 | moneyFilterStr}}</td>
            <td>{{item.e204253 | moneyFilterStr}}</td>
            <td>{{item.e204252}}</td>
            <td>{{item.e204386 | moneyFilterStr}}</td>
            <td>{{item.e204237 | moneyFilterStr}}</td>
            <td>{{item.e204254 | moneyFilterStr}}</td>
            <td>{{item.e204260}}</td>
          </tr>
          <tr style="text-align: center">
            <td>合计</td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{this.sums.nzjList.e204246.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.nzjList.e204250.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.nzjList.e204255.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.nzjList.e204253.toFixed(2) | moneyFilterStr}}</td>
            <td></td>
            <td>{{this.sums.nzjList.e204386.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.nzjList.e204237.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.nzjList.e204254.toFixed(2) | moneyFilterStr}}</td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="this.data.lwfList  != null && this.data.lwfList.length > 0">
      劳务费
      <div>
        <table border="1" style="width: 100%">
          <tr>
            <th>客户名称</th>
            <th>客户ID</th>
            <th>雇员姓名</th>
            <th>工资劳务费年份</th>
            <th>工资劳务费月份</th>
            <th>应发金额</th>
            <th>应发补贴</th>
            <th>税前商保计税部分</th>
            <th>税前捐赠免税部分</th>
            <th>税前扣除社保个人部分</th>
            <th>税前医疗个人免税部分</th>
            <th>税前公积金个人免税部分</th>
<!--            <th>应税金额</th>-->
<!--            <th>应税金额（报税）</th>-->
            <th>税率</th>
            <th>税金</th>
            <th>税后应客户要求扣款(工资)</th>
            <th>税后扣除养老个人计税部分</th>
            <th>税后扣除失业个人计税部分</th>
            <th>税后扣除医疗个人计税部分</th>
            <th>税后扣除公积金个人计税部分</th>
            <th>税后扣除开户费</th>
            <th>税后增加独生子女补贴</th>
            <th>税后增加煤火费补贴</th>
            <th>税后增加其他免税补贴</th>
            <th>实发金额</th>
            <th>备注</th>
          </tr>
          <tr v-for="(item,idx) in this.data.lwfList" :key="idx" style="text-align: center">
            <td>{{item.f001200}}</td>
            <td>{{item.e204399}}</td>
            <td>{{item.e204344}}</td>
            <td>{{item.e204212 | wageFormat("year")}}</td>
            <td>{{item.e204212 | wageFormat("month")}}</td>
            <td>{{item.e204256 | moneyFilterStr}}</td>
            <td>{{item.e204257 | moneyFilterStr}}</td>
            <td>{{item.e204225 | moneyFilterStr}}</td>
            <td>{{item.e204228 | moneyFilterStr}}</td>
            <td>{{(Number(item.e204216) + Number(item.e204217)).toFixed(2) | moneyFilterStr}}</td>
            <td>{{item.e204218 | moneyFilterStr}}</td>
            <td>{{item.e204219 | moneyFilterStr}}</td>
<!--            <td>{{item.e204261 | moneyFilterStr}}</td>-->
<!--            <td>{{item.e204480 | moneyFilterStr}}</td>-->
            <td>{{item.e204263}}</td>
            <td>{{item.e204262 | moneyFilterStr}}</td>
            <td>{{item.e204236 | moneyFilterStr}}</td>
            <td>{{item.e204231 | moneyFilterStr}}</td>
            <td>{{item.e204232 | moneyFilterStr}}</td>
            <td>{{item.e204233 | moneyFilterStr}}</td>
            <td>{{item.e204234 | moneyFilterStr}}</td>
            <td>{{item.e204237 | moneyFilterStr}}</td>
            <td>{{item.e204239 | moneyFilterStr}}</td>
            <td>{{item.e204240 | moneyFilterStr}}</td>
            <td>{{item.e204241 | moneyFilterStr}}</td>
            <td>{{item.e204258 | moneyFilterStr}}</td>
            <td>{{item.e204260}}</td>
          </tr>
          <tr style="text-align: center">
            <td>合计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{this.sums.lwfList.e204256 .toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.e204257.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.e204225.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.e204228.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.税前扣除社保个人部分.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.e204218.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.e204219.toFixed(2) | moneyFilterStr}}</td>
<!--            <td>{{this.sums.lwfList.e204261.toFixed(2) | moneyFilterStr}}</td>-->
<!--            <td>{{this.sums.lwfList.e204480.toFixed(2) | moneyFilterStr}}</td>-->
            <td></td>
            <td>{{this.sums.lwfList.e204262.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.e204236.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.e204231.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.e204232.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.e204233.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.e204234.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.e204237.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.e204239.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.e204240.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.e204241.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfList.e204258.toFixed(2) | moneyFilterStr}}</td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="this.data.lzjList  != null && this.data.lzjList.length > 0">
      离职金
      <div>
        <table border="1" style="width: 100%">
          <tr>
            <th>客户名称</th>
            <th>客户ID</th>
            <th>雇员姓名</th>
            <th>工资劳务费年份</th>
            <th>工资劳务费月份</th>
            <th>应发金额</th>
            <th>应发补贴</th>
            <th>税前商保计税部分</th>
            <th>税前捐赠免税部分</th>
            <th>税前扣除社保个人部分</th>
            <th>税前医疗个人免税部分</th>
            <th>税前公积金个人免税部分</th>
<!--            <th>离职年限</th>-->
            <th>离职金免税金额</th>
<!--            <th>应税金额</th>-->
<!--            <th>应税金额（报税）</th>-->
            <th>税率</th>
            <th>税金</th>
            <th>税后应客户要求扣款(工资)</th>
            <th>税后扣除养老个人计税部分</th>
            <th>税后扣除失业个人计税部分</th>
            <th>税后扣除医疗个人计税部分</th>
            <th>税后扣除公积金个人计税部分</th>
            <th>税后扣除开户费</th>
            <th>税后增加独生子女补贴</th>
            <th>税后增加煤火费补贴</th>
            <th>税后增加其他免税补贴</th>
            <th>实发金额</th>
            <th>备注</th>
          </tr>
          <tr v-for="(item,idx) in this.data.lzjList" :key="idx" style="text-align: center">
            <td>{{item.f001200}}</td>
            <td>{{item.e204399}}</td>
            <td>{{item.e204344}}</td>
            <td>{{item.e204212 | wageFormat("year",e204212)}}</td>
            <td>{{item.e204212 | wageFormat("month",e204212)}}</td>
            <td>{{item.e204256 | moneyFilterStr}}</td>
            <td>{{item.e204257 | moneyFilterStr}}</td>
            <td>{{item.e204225 | moneyFilterStr}}</td>
            <td>{{item.e204228 | moneyFilterStr}}</td>
            <td>{{(Number(item.e204216) + Number(item.e204217)).toFixed(2) | moneyFilterStr}}</td>
            <td>{{item.e204218 | moneyFilterStr}}</td>
            <td>{{item.e204219 | moneyFilterStr}}</td>
<!--            <td>{{item.e204211}}</td>-->
            <td>{{item.e204369 | moneyFilterStr}}</td>
<!--            <td>{{item.e204261 | moneyFilterStr}}</td>-->
<!--            <td>{{item.e204480 | moneyFilterStr}}</td>-->
            <td>{{item.e204263}}</td>
            <td>{{item.e204262 | moneyFilterStr}}</td>
            <td>{{item.e204236 | moneyFilterStr}}</td>
            <td>{{item.e204231 | moneyFilterStr}}</td>
            <td>{{item.e204232 | moneyFilterStr}}</td>
            <td>{{item.e204233 | moneyFilterStr}}</td>
            <td>{{item.e204234 | moneyFilterStr}}</td>
            <td>{{item.e204237 | moneyFilterStr}}</td>
            <td>{{item.e204239 | moneyFilterStr}}</td>
            <td>{{item.e204240 | moneyFilterStr}}</td>
            <td>{{item.e204241 | moneyFilterStr}}</td>
            <td>{{item.e204258 | moneyFilterStr}}</td>
            <td>{{item.e204260}}</td>
          </tr>
          <tr style="text-align: center">
            <td>合计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{this.sums.lzjList.e204256.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.e204257.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.e204225.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.e204228.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.税前扣除社保个人部分.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.e204218.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.e204219.toFixed(2) | moneyFilterStr}}</td>
<!--            <td></td>-->
            <td>{{this.sums.lzjList.e204369.toFixed(2) | moneyFilterStr}}</td>
<!--            <td>{{this.sums.lzjList.e204261.toFixed(2) | moneyFilterStr}}</td>-->
<!--            <td>{{this.sums.lzjList.e204480.toFixed(2) | moneyFilterStr}}</td>-->
            <td></td>
            <td>{{this.sums.lzjList.e204262.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.e204236.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.e204231.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.e204232.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.e204233.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.e204234.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.e204237.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.e204239.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.e204240.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.e204241.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lzjList.e204258.toFixed(2) | moneyFilterStr}}</td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>


    <div v-if="this.data.zjfzbfList  != null && this.data.zjfzbfList.length > 0">
      中籍工资补发
      <div>
        <table border="1" style="width: 100%">
          <tr>
            <th>客户名称</th>
            <th>客户ID</th>
            <th>雇员姓名</th>
            <th>工资劳务费年份</th>
            <th>工资劳务费月份</th>
            <th>税前商保计税部分</th>
            <th>税前捐赠免税部分</th>
            <th>税前扣除社保个人部分</th>
            <th>税前医疗个人免税部分</th>
            <th>税前公积金个人免税部分</th>
<!--            <th>离职年限</th>-->
            <th>离职金免税金额</th>
<!--            <th>应税金额</th>-->
<!--            <th>应税金额（报税）</th>-->
            <th>税率</th>
            <th>税金</th>
            <th>税后应客户要求扣款(工资)</th>
            <th>税后扣除养老个人计税部分</th>
            <th>税后扣除失业个人计税部分</th>
            <th>税后扣除医疗个人计税部分</th>
            <th>税后扣除公积金个人计税部分</th>
            <th>税后扣除开户费</th>
            <th>税后增加独生子女补贴</th>
            <th>税后增加煤火费补贴</th>
            <th>税后增加其他免税补贴</th>
            <th>实发金额</th>
            <th>备注</th>
          </tr>
          <tr v-for="(item,idx) in this.data.zjfzbfList" :key="idx" style="text-align: center">
            <td>{{item.f001200}}</td>
            <td>{{item.e204399}}</td>
            <td>{{item.e204344}}</td>
            <td>{{item.e204212 | wageFormat("year",e204212)}}</td>
            <td>{{item.e204212 | wageFormat("month",e204212)}}</td>
            <td>{{item.e204225 | moneyFilterStr}}</td>
            <td>{{item.e204228 | moneyFilterStr}}</td>
            <td>{{(Number(item.e204216) + Number(item.e204217)).toFixed(2) | moneyFilterStr}}</td>
            <td>{{item.e204218 | moneyFilterStr}}</td>
            <td>{{item.e204219 | moneyFilterStr}}</td>
<!--            <td>{{item.e204211}}</td>-->
            <td>{{item.e204369 | moneyFilterStr}}</td>
<!--            <td>{{item.e204261 | moneyFilterStr}}</td>-->
<!--            <td>{{item.e204480 | moneyFilterStr}}</td>-->
            <td>{{item.e204263}}</td>
            <td>{{item.e204262 | moneyFilterStr}}</td>
            <td>{{item.e204236 | moneyFilterStr}}</td>
            <td>{{item.e204231 | moneyFilterStr}}</td>
            <td>{{item.e204232 | moneyFilterStr}}</td>
            <td>{{item.e204233 | moneyFilterStr}}</td>
            <td>{{item.e204234 | moneyFilterStr}}</td>
            <td>{{item.e204237 | moneyFilterStr}}</td>
            <td>{{item.e204239 | moneyFilterStr}}</td>
            <td>{{item.e204240 | moneyFilterStr}}</td>
            <td>{{item.e204241 | moneyFilterStr}}</td>
            <td>{{item.e204258 | moneyFilterStr}}</td>
            <td>{{item.e204260}}</td>
          </tr>
          <tr style="text-align: center">
            <td>合计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{this.sums.zjfzbfList.e204225.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjfzbfList.e204228.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjfzbfList.税前扣除社保个人部分.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjfzbfList.e204218.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjfzbfList.e204219.toFixed(2) | moneyFilterStr}}</td>
            <td></td>
<!--            <td>{{this.sums.zjfzbfList.e204369 | moneyFilter}}</td>-->
            <td>{{this.sums.zjfzbfList.e204369.toFixed(2) | moneyFilterStr}}</td>
<!--            <td>{{this.sums.zjfzbfList.e204261.toFixed(2) | moneyFilterStr}}</td>-->
<!--            <td>{{this.sums.zjfzbfList.e204480 | moneyFilter}}</td>-->
<!--            <td>{{this.sums.zjfzbfList.e204480.toFixed(2) | moneyFilterStr}}</td>-->
<!--            <td></td>-->
            <td>{{this.sums.zjfzbfList.e204262.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjfzbfList.e204236.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjfzbfList.e204231.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjfzbfList.e204232.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjfzbfList.e204233.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjfzbfList.e204234.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjfzbfList.e204237.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjfzbfList.e204239.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjfzbfList.e204240.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjfzbfList.e204241.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.zjfzbfList.e204258.toFixed(2) | moneyFilterStr}}</td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>

    <div v-if="this.data.wjgzbfList  != null && this.data.wjgzbfList.length > 0">
      外籍工资补发
      <div>
        <table border="1" style="width: 100%">
          <tr>
            <th>客户名称</th>
            <th>客户ID</th>
            <th>雇员姓名</th>
            <th>工资劳务费年份</th>
            <th>工资劳务费月份</th>
            <th>应发金额</th>
            <th>应发补贴</th>
            <th>税前商保计税部分</th>
            <th>税前捐赠免税部分</th>
            <th>税前扣除社保个人部分</th>
            <th>税前医疗个人免税部分</th>
            <th>税前公积金个人免税部分</th>
<!--            <th>应税金额</th>-->
<!--            <th>应税金额（报税）</th>-->
            <th>税率</th>
            <th>税金</th>
            <th>税后应客户要求扣款(工资)</th>
            <th>税后扣除养老个人计税部分</th>
            <th>税后扣除失业个人计税部分</th>
            <th>税后扣除医疗个人计税部分</th>
            <th>税后扣除公积金个人计税部分</th>
            <th>税后扣除开户费</th>
            <th>税后增加独生子女补贴</th>
            <th>税后增加煤火费补贴</th>
            <th>税后增加其他免税补贴</th>
            <th>实发金额</th>
            <th>备注</th>
          </tr>
          <tr v-for="(item,idx) in this.data.wjgzbfList" :key="idx" style="text-align: center">
            <td>{{item.f001200}}</td>
            <td>{{item.e204399}}</td>
            <td>{{item.e204344}}</td>
            <td>{{item.e204212 | wageFormat("year",e204212)}}</td>
            <td>{{item.e204212 | wageFormat("month",e204212)}}</td>
            <td>{{item.e204256 | moneyFilterStr}}</td>
            <td>{{item.e204257 | moneyFilterStr}}</td>
            <td>{{item.e204225 | moneyFilterStr}}</td>
            <td>{{item.e204228 | moneyFilterStr}}</td>
            <td>{{(Number(item.e204216) + Number(item.e204217)).toFixed(2) | moneyFilterStr}}</td>
            <td>{{item.e204218 | moneyFilterStr}}</td>
            <td>{{item.e204219 | moneyFilterStr}}</td>
<!--            <td>{{item.e204261 | moneyFilterStr}}</td>-->
<!--            <td>{{item.e204480 | moneyFilterStr}}</td>-->
            <td>{{item.e204263}}</td>
            <td>{{item.e204262 | moneyFilterStr}}</td>
            <td>{{item.e204236 | moneyFilterStr}}</td>
            <td>{{item.e204231 | moneyFilterStr}}</td>
            <td>{{item.e204232 | moneyFilterStr}}</td>
            <td>{{item.e204233 | moneyFilterStr}}</td>
            <td>{{item.e204234 | moneyFilterStr}}</td>
            <td>{{item.e204237 | moneyFilterStr}}</td>
            <td>{{item.e204239 | moneyFilterStr}}</td>
            <td>{{item.e204240 | moneyFilterStr}}</td>
            <td>{{item.e204241 | moneyFilterStr}}</td>
            <td>{{item.e204258 | moneyFilterStr}}</td>
            <td>{{item.e204260}}</td>
          </tr>
          <tr style="text-align: center">
            <td>合计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{this.sums.wjgzbfList.e204256.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.e204257.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.e204225.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.e204228.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.税前扣除社保个人部分.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.e204218.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.e204219.toFixed(2) | moneyFilterStr}}</td>
<!--            <td>{{this.sums.wjgzbfList.e204261.toFixed(2) | moneyFilterStr}}</td>-->
<!--            <td>{{this.sums.wjgzbfList.e204480.toFixed(2) | moneyFilterStr}}</td>-->
            <td></td>
            <td>{{this.sums.wjgzbfList.e204262.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.e204236.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.e204231.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.e204232.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.e204233.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.e204234.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.e204237.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.e204239.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.e204240.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.e204241.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.wjgzbfList.e204258.toFixed(2) | moneyFilterStr}}</td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>

    <div v-if="this.data.lwfbfList  != null  && this.data.lwfbfList.length > 0">
      劳务费补发
      <div>
        <table border="1" style="width: 100%">
          <tr>
            <th>客户名称</th>
            <th>客户ID</th>
            <th>雇员姓名</th>
            <th>工资劳务费年份</th>
            <th>工资劳务费月份</th>
            <th>应发金额</th>
            <th>应发补贴</th>
            <th>税前商保计税部分</th>
            <th>税前捐赠免税部分</th>
            <th>税前扣除社保个人部分</th>
            <th>税前医疗个人免税部分</th>
            <th>税前公积金个人免税部分</th>
<!--            <th>应税金额</th>-->
            <th>税率</th>
            <th>税金</th>
            <th>税后应客户要求扣款(工资)</th>
            <th>税后扣除养老个人计税部分</th>
            <th>税后扣除失业个人计税部分</th>
            <th>税后扣除医疗个人计税部分</th>
            <th>税后扣除公积金个人计税部分</th>
            <th>税后扣除开户费</th>
            <th>税后增加独生子女补贴</th>
            <th>税后增加煤火费补贴</th>
            <th>税后增加其他免税补贴</th>
            <th>实发金额</th>
            <th>备注</th>
          </tr>
          <tr v-for="(item,idx) in this.data.lwfbfList" :key="idx" style="text-align: center">
            <td>{{item.f001200}}</td>
            <td>{{item.e204399}}</td>
            <td>{{item.e204344}}</td>
            <td>{{item.e204212 | wageFormat("year",e204212)}}</td>
            <td>{{item.e204212 | wageFormat("month",e204212)}}</td>
            <td>{{item.e204256 | moneyFilterStr}}</td>
            <td>{{item.e204257 | moneyFilterStr}}</td>
            <td>{{item.e204225 | moneyFilterStr}}</td>
            <td>{{item.e204228 | moneyFilterStr}}</td>
            <td>{{(Number(item.e204216) + Number(item.e204217)).toFixed(2) | moneyFilterStr}}</td>
            <td>{{item.e204218 | moneyFilterStr}}</td>
            <td>{{item.e204219 | moneyFilterStr}}</td>
<!--            <td>{{item.e204261 | moneyFilterStr}}</td>-->
            <td>{{item.e204263}}</td>
            <td>{{item.e204262 | moneyFilterStr}}</td>
            <td>{{item.e204236 | moneyFilterStr}}</td>
            <td>{{item.e204231 | moneyFilterStr}}</td>
            <td>{{item.e204232 | moneyFilterStr}}</td>
            <td>{{item.e204233 | moneyFilterStr}}</td>
            <td>{{item.e204234 | moneyFilterStr}}</td>
            <td>{{item.e204237 | moneyFilterStr}}</td>
            <td>{{item.e204239 | moneyFilterStr}}</td>
            <td>{{item.e204240 | moneyFilterStr}}</td>
            <td>{{item.e204241 | moneyFilterStr}}</td>
            <td>{{item.e204258 | moneyFilterStr}}</td>
            <td>{{item.e204260}}</td>
          </tr>
          <tr style="text-align: center">
            <td>合计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{this.sums.lwfbfList.e204256.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.e204257.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.e204225.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.e204228.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.税前扣除社保个人部分.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.e204218.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.e204219.toFixed(2) | moneyFilterStr}}</td>
<!--            <td>{{this.sums.lwfbfList.e204261.toFixed(2) | moneyFilterStr}}</td>-->
            <td></td>
            <td>{{this.sums.lwfbfList.e204262.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.e204236.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.e204231.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.e204232.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.e204233.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.e204234.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.e204237.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.e204239.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.e204240.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.e204241.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.lwfbfList.e204258.toFixed(2) | moneyFilterStr}}</td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>

    <div v-if="this.data.gpqqList  != null && this.data.gpqqList.length > 0">
      股票期权
      <div>
        <table border="1" style="width: 100%">
          <tr>
            <th>客户名称</th>
            <th>客户ID</th>
            <th>雇员姓名</th>
            <th>股票期权年度</th>
<!--            <th>规定月份数</th>-->
            <th>应发金额</th>
            <th>应发补贴</th>
<!--            <th>应税金额</th>-->
            <th>税率</th>
            <th>税金</th>
            <th>税后扣除开户费</th>
            <th>实发金额</th>
            <th>备注</th>
          </tr>
          <tr v-for="(item,idx) in this.data.gpqqList" :key="idx" style="text-align: center">
            <td>{{item.f001200}}</td>
            <td>{{item.e204399}}</td>
            <td>{{item.e204344}}</td>
            <td>{{item.e204389 | wageFormat("year")}}</td>
<!--            <td>{{item.e204390}}</td>-->
            <td>{{item.e204256 | moneyFilterStr}}</td>
            <td>{{item.e204257 | moneyFilterStr}}</td>
<!--            <td>{{item.e204261 | moneyFilterStr}}</td>-->
            <td>{{item.e204263}}</td>
            <td>{{item.e204262 | moneyFilterStr}}</td>
            <td>{{item.e204237 | moneyFilterStr}}</td>
            <td>{{item.e204258 | moneyFilterStr}}</td>
            <td>{{item.e204260}}</td>
          </tr>
          <tr style="text-align: center">
            <td>合计</td>
<!--            <td></td>-->
            <td></td>
            <td></td>
            <td></td>
            <td>{{this.sums.gpqqList.e204256.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.gpqqList.e204257.toFixed(2) | moneyFilterStr}}</td>
<!--            <td>{{this.sums.gpqqList.e204261.toFixed(2) | moneyFilterStr}}</td>-->
            <td></td>
            <td>{{this.sums.gpqqList.e204262.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.gpqqList.e204237.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.gpqqList.e204258.toFixed(2) | moneyFilterStr}}</td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>

    <div v-if="this.data.gqhlList != null && this.data.gqhlList.length > 0">
      股权红利
      <div>
        <table border="1" style="width: 100%">
          <tr>
            <th>客户名称</th>
            <th>客户ID</th>
            <th>雇员姓名</th>
            <th>工资劳务费年份</th>
            <th>工资劳务费月份</th>
<!--            <th>规定月份数</th>-->
            <th>应发金额</th>
<!--            <th>应税金额</th>-->
            <th>税率</th>
            <th>税金</th>
            <th>税后扣除开户费</th>
            <th>实发金额</th>
            <th>备注</th>
          </tr>
          <tr v-for="(item,idx) in this.data.gqhlList" :key="idx" style="text-align: center">
            <td>{{item.f001200}}</td>
            <td>{{item.e204399}}</td>
            <td>{{item.e204344}}</td>
            <td>{{item.e204212 | wageFormat("year",e204212)}}</td>
            <td>{{item.e204212 | wageFormat("month",e204212)}}</td>
<!--            <td>{{item.e204390}}</td>-->
            <td>{{item.e204256 | moneyFilterStr}}</td>
<!--            <td>{{item.e204261 | moneyFilterStr}}</td>-->
            <td>{{item.e204263}}</td>
            <td>{{item.e204262 | moneyFilterStr}}</td>
            <td>{{item.e204237 | moneyFilterStr}}</td>
            <td>{{item.e204258 | moneyFilterStr}}</td>
            <td>{{item.e204260}}</td>
          </tr>
          <tr style="text-align: center">
            <td>合计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
<!--            <td></td>-->
            <td>{{this.sums.gqhlList.e204256.toFixed(2) | moneyFilterStr}}</td>
<!--            <td>{{this.sums.gqhlList.e204261.toFixed(2) | moneyFilterStr}}</td>-->
            <td></td>
            <td>{{this.sums.gqhlList.e204262.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.gqhlList.e204237.toFixed(2) | moneyFilterStr}}</td>
            <td>{{this.sums.gqhlList.e204258.toFixed(2) | moneyFilterStr}}</td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>

<!--    <div v-if="this.data.orsdList  != null">-->
<!--      偶然所得-->
<!--      <div>-->
<!--        <table border="1" style="width: 100%">-->
<!--          <tr>-->
<!--            <th>客户名称</th>-->
<!--            <th>客户ID</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->

<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->

<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->

<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->

<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->

<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->

<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--            <th>雇员姓名</th>-->
<!--          </tr>-->
<!--          <tr v-for="(item,idx) in this.data.orsdList" :key="idx" style="text-align: center">-->
<!--            <td>{{item.f001200}}</td>-->
<!--            <td>{{item.e204399}}</td>-->
<!--            <td>{{item.e204344}}</td>-->
<!--            <td>{{item.e204209}}</td>-->
<!--            <td>{{item.e204208}}</td>-->
<!--            <td>{{item.e204210}}</td>j-->
<!--            <td>{{item.e204355}}</td>-->
<!--            <td>{{item.e204343}}</td>-->
<!--            <td>{{item.e204266}}</td>-->
<!--            <td>{{item.e204420}}</td>-->
<!--            <td>{{item.e204356}}</td>-->
<!--            <td>{{item.e204348}}</td>-->
<!--            <td>{{item.e204349}}</td>-->
<!--            <td>{{item.e204345}}</td>-->
<!--            <td>{{item.e204346}}</td>-->
<!--            <td>{{item.e204350}}</td>-->
<!--            <td>{{item.e204347}}</td>-->
<!--            <td>{{item.e204265}}</td>-->
<!--            <td>{{item.e204267}}</td>-->
<!--            <td>{{item.e204269}}</td>-->
<!--            <td>{{item.e204268}}</td>-->
<!--            <td>{{item.e204270}}</td>-->
<!--            <td>{{item.e204212}}</td>-->
<!--            <td>{{item.e204213}}</td>-->
<!--            <td>{{item.e204256}}</td>-->
<!--            <td>{{item.e204257}}</td>-->
<!--            <td>{{item.e204225}}</td>-->
<!--            <td>{{item.e204226}}</td>-->
<!--            <td>{{item.e204228}}</td>-->
<!--            <td>{{item.e204229}}</td>-->
<!--            <td>{{item.e204483}}</td>-->
<!--            <td>{{item.e204243}}</td>-->
<!--            <td>{{item.e204244}}</td>-->
<!--            <td>{{Number(item.e204216) + Number(item.e204217)}}</td>-->
<!--            <td>{{item.e204220}}</td>-->
<!--            <td>{{item.e204221}}</td>-->
<!--            <td>{{item.e204218}}</td>-->
<!--            <td>{{item.e204222}}</td>-->
<!--            <td>{{item.e204219}}</td>-->
<!--            <td>{{item.e204223}}</td>-->
<!--            <td>{{item.e204215}}</td>-->
<!--            <td>{{item.e204263}}</td>-->
<!--            <td>{{item.e204261}}</td>-->
<!--            <td>{{item.e204262}}</td>-->
<!--            <td>{{item.e204236}}</td>-->
<!--            <td>{{item.e204237}}</td>-->
<!--            <td>{{item.e204231}}</td>-->
<!--            <td>{{item.e204232}}</td>-->
<!--            <td>{{item.e204233}}</td>-->
<!--            <td>{{item.e204234}}</td>-->
<!--            <td>{{item.e204239}}</td>-->
<!--            <td>{{item.e204240}}</td>-->
<!--            <td>{{item.e204241}}</td>-->
<!--            <td>{{item.e204258}}</td>-->
<!--            <td>{{item.e204368}}</td>-->
<!--            <td>{{item.e204272}}</td>-->
<!--            <td>{{item.e204398}}</td>-->
<!--            <td>{{item.e204370}}</td>-->
<!--            <td>{{item.e204260}}</td>-->
<!--            <td>{{item.e204397}}</td>-->
<!--            <td>{{item.e204353}}</td>-->
<!--            <td>{{item.e204354}}</td>-->
<!--            <td>{{item.e204201}}</td>-->
<!--            <td>{{item.e204616}}</td>-->
<!--          </tr>-->
<!--          <tr style="text-align: center">-->
<!--            <td>合计</td>-->
<!--          </tr>-->
<!--        </table>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div>-->
<!--      其他连续劳务报酬-->
<!--      <div>-->
<!--        <table border="1" style="width: 100%">-->
<!--          <tr>-->
<!--            <th></th>-->
<!--          </tr>-->
<!--          <tr v-for="(item,idx) in this.data.lxlwbcList" :key="idx" style="text-align: center">-->

<!--          </tr>-->
<!--          <tr style="text-align: center">-->
<!--            <td>合计</td>-->
<!--          </tr>-->
<!--        </table>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div>-->
    <div v-if="this.data.privateDetailList  != null && this.data.privateDetailList.length > 0">
      <div style="text-align: center">支付单明细</div>
      <div>
        <table border="1" style="width: 100%">
          <tr>
            <th>序号</th>
            <th>雇员姓名</th>
            <th>开户名</th>
            <th>开户银行</th>
            <th>银行账号</th>
            <th>开户地区</th>
            <th>支出金额</th>
          </tr>
          <tr v-for="(item,idx) in this.data.privateDetailList" :key="idx" style="text-align: center">
            <td>{{item.num}}</td>
            <td>{{item.name}}</td>
            <td>{{item.accountName}}</td>
            <td>{{item.bankName}}</td>
            <td>{{item.accountNo}}</td>
            <td>{{item.bankArea}}</td>
            <td>{{item.money | moneyFilterStr}}</td>
          </tr>
          <tr style="text-align: center">
            <td>合计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{this.sums.privateDetailList.money.toFixed(2) | moneyFilterStr}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="divButtonRigth" :class="{'hideBtn':hideBtn}">
      <el-button type="primary" size="small" fixed="right" @click="print()">打印</el-button>
      <el-button type="primary" size="big" fixed="right" @click="close()">关闭</el-button>
    </div>
  </div>
</template>

<script>

  import {
    printEmployeeDetail
  } from '@/api/payment/payment';

  export default {
    name: "printEmployeeDetail",
    created() {
      this.load();
    },
    mounted(){
      const that = this;
      this.$nextTick(()=>{
        document.getElementsByTagName("body")[0].classList.add("print");
      })

      if (window.matchMedia) {
        var mediaQueryList = window.matchMedia('print');

        //为印添加事件
        mediaQueryList.addListener(function(mql) {
          if (mql.matches) {
          } else {
            that.afterPrint();
          }
        });
      }
      //打印后事件
      window.onafterprint = that.afterPrint;

    },
    data() {
      return {
        hideBtn:false,
        // 页面数据
        data: {
          paymentBillsBO: [],
          zjgzList: {},
          wjgzList: {},
          nzjList: {},
          lwfList: {},
          lzjList: {},
          zjfzbfList: {},
          wjgzbfList: {},
          lwfbfList: {},
          gpqqList: {},
          gqhlList: {},
          orsdList: {},
          lxlwbcList: {},
          privateDetailList: {}
        },
        sums: {
          zjgzList: {
            e204257: 0.00,
            e204256: 0.00,
            e204228: 0.00,
            税前扣除社保个人部分: 0.00,
            e204218: 0.00,
            e204219: 0.00,
            e204218: 0.00,
            e204219: 0.00,
            // e204261: 0.00,
            e204262: 0.00,
            e204236: 0.00,
            e203211: 0.00,
            e204233: 0.00,
            e204234: 0.00,
            e204241: 0.00,
            e204258: 0.00,
            e204497: 0.00,
            e204589: 0.00,
          },
          wjgzList: {
            e204257: 0.00,
            e204225: 0.00,
            e204228: 0.00,
            税前扣除社保个人部分: 0.00,
            e204218: 0.00,
            e204219: 0.00,
            e204218: 0.00,
            e204219: 0.00,
            // e204261: 0.00,
            e204236: 0.00,
            e203211: 0.00,
            e204233: 0.00,
            e204234: 0.00,
            e204241: 0.00,
            e204258: 0.00,
          },
          nzjList: {
            e204246: 0.00,
            e204250: 0.00,
            e204255: 0.00,
            e204253: 0.00,
            e204386: 0.00,
            e204237: 0.00,
            e204254: 0},
          lwfList: {
            e204256: 0.00,
            e204257: 0.00,
            e204225: 0.00,
            e204228: 0.00,
            税前扣除社保个人部分: 0.00,
            e204218: 0.00,
            e204219: 0.00,
            // e204261: 0.00,
            // e204480: 0.00,
            e204262: 0.00,
            e204236: 0.00,
            e204231: 0.00,
            e204232: 0.00,
            e204233: 0.00,
            e204234: 0.00,
            e204237: 0.00,
            e204239: 0.00,
            e204240: 0.00,
            e204241: 0.00,
            e204258: 0.00,
          },
          lzjList: {
            // f001200: 0.00,
            // e204399: 0.00,
            // e204344: 0.00,
            // e204212: 0.00,
            // e204213: 0.00,
            e204256: 0.00,
            e204257: 0.00,
            e204225: 0.00,
            e204228: 0.00,
            // e204216: 0.00,
            // e204217: 0.00,
            税前扣除社保个人部分: 0.00,
            e204218: 0.00,
            e204219: 0.00,
            // e204211: 0.00,
            e204369: 0.00,
            // e204261: 0.00,
            // e204480: 0.00,
            // e204263: 0.00,
            e204262: 0.00,
            e204236: 0.00,
            e204231: 0.00,
            e204232: 0.00,
            e204233: 0.00,
            e204234: 0.00,
            e204237: 0.00,
            e204239: 0.00,
            e204240: 0.00,
            e204241: 0.00,
            e204258: 0.00,
            // e204260: 0.00,
          },
          zjfzbfList: {
            // f001200: 0.00,
            // e204399: 0.00,
            // e204344: 0.00,
            // e204212: 0.00,
            // e204213: 0.00,
            e204256: 0.00,
            e204257: 0.00,
            e204225: 0.00,
            e204228: 0.00,
            // e204216: 0.00,--
            // e204217: 0.00,--
            税前扣除社保个人部分: 0.00,
            e204218: 0.00,
            e204219: 0.00,
            // e204261: 0.00,
            // e204263: 0.00,
            e204262: 0.00,
            e204236: 0.00,
            e204231: 0.00,
            e204232: 0.00,
            e204233: 0.00,
            e204234: 0.00,
            e204237: 0.00,
            e204239: 0.00,
            e204240: 0.00,
            e204241: 0.00,
            e204258: 0.00,
            // e204260: 0.00,
          },
          wjgzbfList: {
            // f001200: 0.00,
            // e204399: 0.00,
            // e204344: 0.00,
            // e204212: 0.00,
            // e204213: 0.00,
            e204256: 0.00,
            e204257: 0.00,
            e204225: 0.00,
            e204228: 0.00,
            // e204216: 0.00,--
            // e204217: 0.00,--
            税前扣除社保个人部分: 0.00,
            e204218: 0.00,
            e204219: 0.00,
            // e204261: 0.00,
            // e204480: 0.00,
            // e204263: 0.00,
            e204262: 0.00,
            e204236: 0.00,
            e204231: 0.00,
            e204232: 0.00,
            e204233: 0.00,
            e204234: 0.00,
            e204237: 0.00,
            e204239: 0.00,
            e204240: 0.00,
            e204241: 0.00,
            e204258: 0.00,
            // e204260: 0.00,
          },
          lwfbfList: {
            // f001200: 0.00,
            //   e204399: 0.00,
            //   e204344: 0.00,
            //   e204212: 0.00,
            //   e204213: 0.00,
              e204256: 0.00,
              e204257: 0.00,
              e204225: 0.00,
              e204228: 0.00,
              // e204216: 0.00,
              // e204217: 0.00,
              税前扣除社保个人部分: 0.00,
              e204218: 0.00,
              e204219: 0.00,
              // e204261: 0.00,
              // e204263: 0.00,
              e204262: 0.00,
              e204236: 0.00,
              e204231: 0.00,
              e204232: 0.00,
              e204233: 0.00,
              e204234: 0.00,
              e204237: 0.00,
              e204239: 0.00,
              e204240: 0.00,
              e204241: 0.00,
              e204258: 0.00,
              // e204260: 0.00,
          },
          gpqqList: {
            // f001200: 0.00,
            //   e204399: 0.00,
            //   e204344: 0.00,
            //   e204389: 0.00,
            //   e204390: 0.00,
              e204256: 0.00,
              e204257: 0.00,
              // e204261: 0.00,
              // e204263: 0.00,
              e204262: 0.00,
              e204237: 0.00,
              e204258: 0.00,
              // e204260: 0.00,
          },
          gqhlList: {
            // f001200: 0.00,
            //   e204399: 0.00,
            //   e204344: 0.00,
            //   e204212: 0.00,
            //   e204213: 0.00,
            //   e204390: 0.00,
              e204256: 0.00,
              // e204261: 0.00,
              // e204263: 0.00,
              e204262: 0.00,
              e204237: 0.00,
              e204258: 0.00,
              // e204260: 0.00,
          },
          orsdList: {
            // f001200: 0.00,
            //   e204399: 0.00,
              // e204344: 0.00,
              e204209: 0.00,
              e204208: 0.00,
              e204210: 0.00,
              e204355: 0.00,
              e204343: 0.00,
              e204266: 0.00,
              e204420: 0.00,
              e204356: 0.00,
              e204348: 0.00,
              e204349: 0.00,
              e204345: 0.00,
              e204346: 0.00,
              e204350: 0.00,
              e204347: 0.00,
              e204265: 0.00,
              e204267: 0.00,
              e204269: 0.00,
              e204268: 0.00,
              e204270: 0.00,
              // e204212: 0.00,
              // e204213: 0.00,
              e204256: 0.00,
              e204257: 0.00,
              e204225: 0.00,
              e204226: 0.00,
              e204228: 0.00,
              e204229: 0.00,
              e204483: 0.00,
              e204243: 0.00,
              e204244: 0.00,
              // e204216: 0.00,
              // e204217: 0.00,
              税前扣除社保个人部分: 0.00,
              e204220: 0.00,
              e204221: 0.00,
              e204218: 0.00,
              e204222: 0.00,
              e204219: 0.00,
              e204223: 0.00,
              e204215: 0.00,
              e204263: 0.00,
              // e204261: 0.00,
              e204262: 0.00,
              e204236: 0.00,
              e204237: 0.00,
              e204231: 0.00,
              e204232: 0.00,
              e204233: 0.00,
              e204234: 0.00,
              e204239: 0.00,
              e204240: 0.00,
              e204241: 0.00,
              e204258: 0.00,
              e204368: 0.00,
              e204272: 0.00,
              e204398: 0.00,
              e204370: 0.00,
              e204260: 0.00,
              e204259: 0.00,
              e204397: 0.00,
              e204353: 0.00,
              e204354: 0.00,
              e204201: 0.00,
              e204616: 0.00,
          },
          lxlwbcList: {
            // f001200: 0.00,
            //   e204399: 0.00,
              e204209: 0.00,
              e204208: 0.00,
              e204210: 0.00,
              e204355: 0.00,
              e204343: 0.00,
              e204344: 0.00,
              e204266: 0.00,
              e204420: 0.00,
              e204356: 0.00,
              e204348: 0.00,
              e204349: 0.00,
              e204345: 0.00,
              e204346: 0.00,
              e204350: 0.00,
              e204347: 0.00,
              e204265: 0.00,
              e204267: 0.00,
              e204269: 0.00,
              e204268: 0.00,
              e204270: 0.00,
              // e204212: 0.00,
              // e204213: 0.00,
              e204256: 0.00,
              e204257: 0.00,
              e204225: 0.00,
              e204226: 0.00,
              e204228: 0.00,
              e204229: 0.00,
              e204483: 0.00,
              e204243: 0.00,
              e204244: 0.00,
              // e204216: 0.00,
              // e204217: 0.00,
              税前扣除社保个人部分: 0.00,
              e204220: 0.00,
              e204221: 0.00,
              e204218: 0.00,
              e204222: 0.00,
              e204219: 0.00,
              e204223: 0.00,
              e204215: 0.00,
              // e204263: 0.00,
              // e204261: 0.00,
              e204262: 0.00,
              e204236: 0.00,
              e204237: 0.00,
              e204231: 0.00,
              e204232: 0.00,
              e204233: 0.00,
              e204234: 0.00,
              e204239: 0.00,
              e204240: 0.00,
              e204241: 0.00,
              e204258: 0.00,
              e204368: 0.00,
              e204272: 0.00,
              e204398: 0.00,
              e204370: 0.00,
              // e204260: 0.00,
              e204259: 0.00,
              e204397: 0.00,
              e204353: 0.00,
              e204354: 0.00,
              e204201: 0.00,
              e204616: 0.00,
          },
          privateDetailList: {
            money: 0.00,
          }
        },
        //分页查询对象
        listQuery: {
          paymentId: this.$route.query.paymentId,
          payWageClass: this.$route.query.payWageClass
        },
      }
    },
    methods: {
      load() {
        //printPrivateDetail
        printEmployeeDetail(this.listQuery).then(res => {
          this.data = res.data;
          this.sum();
        });
      },
      sum() {
        // 中籍工资求和
        if (this.data.zjgzList !== null && this.data.zjgzList.length > 0) {
          let zjgzList = this.data.zjgzList;
          for (let i in this.data.zjgzList) {
            this.sums.zjgzList.e204257 += Number(zjgzList[i].e204257);
            this.sums.zjgzList.e204256 += Number(zjgzList[i].e204256);
            this.sums.zjgzList.e204228 += Number(zjgzList[i].e204228);
            this.sums.zjgzList.税前扣除社保个人部分 += Number(zjgzList[i].e204216);
            this.sums.zjgzList.税前扣除社保个人部分 += Number(zjgzList[i].e204217);
            this.sums.zjgzList.e204218 += Number(zjgzList[i].e204218);
            // this.sums.zjgzList.e204219 += Number(zjgzList[i].e204219);
            // this.sums.zjgzList.e204218 += Number(zjgzList[i].e204218);
            this.sums.zjgzList.e204219 += Number(zjgzList[i].e204219);
            // this.sums.zjgzList.e204261 += Number(zjgzList[i].e204261);
            this.sums.zjgzList.e204262 += Number(zjgzList[i].e204262);
            this.sums.zjgzList.e204236 += Number(zjgzList[i].e204236);
            this.sums.zjgzList.e203211 += Number(zjgzList[i].e203211);
            this.sums.zjgzList.e204233 += Number(zjgzList[i].e204233);
            this.sums.zjgzList.e204234 += Number(zjgzList[i].e204234);
            this.sums.zjgzList.e204241 += Number(zjgzList[i].e204241);
            this.sums.zjgzList.e204258 += Number(zjgzList[i].e204258);

            this.sums.zjgzList.e204497 += Number(zjgzList[i].e204497);
            this.sums.zjgzList.e204589 += Number(zjgzList[i].e204589);
          }
        }

        // 外籍工资求和
        if (this.data.wjgzList !== null && this.data.wjgzList.length > 0) {
          let wjgzList = this.data.wjgzList;
          for (let i in this.data.wjgzList) {
            this.sums.wjgzList.e204257 += Number(wjgzList[i].e204257);
            this.sums.wjgzList.e204225 += Number(wjgzList[i].e204225);
            this.sums.wjgzList.e204228 += Number(wjgzList[i].e204228);
            this.sums.wjgzList.税前扣除社保个人部分 += Number(wjgzList[i].e204216);
            this.sums.wjgzList.税前扣除社保个人部分 += Number(wjgzList[i].e204217);
            this.sums.wjgzList.e204218 += Number(wjgzList[i].e204218);
            this.sums.wjgzList.e204219 += Number(wjgzList[i].e204219);
            this.sums.wjgzList.e204218 += Number(wjgzList[i].e204218);
            this.sums.wjgzList.e204219 += Number(wjgzList[i].e204219);
            // this.sums.wjgzList.e204261 += Number(wjgzList[i].e204261);
            this.sums.wjgzList.e204236 += Number(wjgzList[i].e204236);
            this.sums.wjgzList.e203211 += Number(wjgzList[i].e203211);
            this.sums.wjgzList.e204233 += Number(wjgzList[i].e204233);
            this.sums.wjgzList.e204234 += Number(wjgzList[i].e204234);
            this.sums.wjgzList.e204241 += Number(wjgzList[i].e204241);
            this.sums.wjgzList.e204258 += Number(wjgzList[i].e204258);
          }
        }

        // 年终奖求和
        if (this.data.nzjList !== null && this.data.nzjList.length > 0) {
          let nzjList = this.data.nzjList;
          for (let i in this.data.nzjList) {
            this.sums.nzjList.e204246 += Number(nzjList[i].e204246);
            this.sums.nzjList.e204250 += Number(nzjList[i].e204250);
            this.sums.nzjList.e204255 += Number(nzjList[i].e204255);
            // this.sums.nzjList.e204251 += Number(nzjList[i].e204251);
            this.sums.nzjList.e204386 += Number(nzjList[i].e204386);
            this.sums.nzjList.e204237 += Number(nzjList[i].e204237);
            this.sums.nzjList.e204254 += Number(nzjList[i].e204254);
            this.sums.nzjList.e204253 += Number(nzjList[i].e204253);
          }
        }

        // 劳务费求和
        if (this.data.lwfList !== null && this.data.lwfList.length > 0) {
          let lwfList = this.data.lwfList;
          for (let i in this.data.lwfList) {
            this.sums.lwfList.e204256 += Number(lwfList[i].e204256);
            this.sums.lwfList.e204257 += Number(lwfList[i].e204257);
            this.sums.lwfList.e204225 += Number(lwfList[i].e204225);
            this.sums.lwfList.e204228 += Number(lwfList[i].e204228);
            this.sums.lwfList.税前扣除社保个人部分 += Number(lwfList[i].e204216);
            this.sums.lwfList.税前扣除社保个人部分 += Number(lwfList[i].e204217);
            this.sums.lwfList.e204218 += Number(lwfList[i].e204218);
            this.sums.lwfList.e204219 += Number(lwfList[i].e204219);
            // this.sums.lwfList.e204261 += Number(lwfList[i].e204261);
            // this.sums.lwfList.e204480 += Number(lwfList[i].e204480);
            this.sums.lwfList.e204262 += Number(lwfList[i].e204262);
            this.sums.lwfList.e204236 += Number(lwfList[i].e204236);
            this.sums.lwfList.e204231 += Number(lwfList[i].e204231);
            this.sums.lwfList.e204232 += Number(lwfList[i].e204232);
            this.sums.lwfList.e204233 += Number(lwfList[i].e204233);
            this.sums.lwfList.e204234 += Number(lwfList[i].e204234);
            this.sums.lwfList.e204237 += Number(lwfList[i].e204237);
            this.sums.lwfList.e204239 += Number(lwfList[i].e204239);
            this.sums.lwfList.e204240 += Number(lwfList[i].e204240);
            this.sums.lwfList.e204241 += Number(lwfList[i].e204241);
            this.sums.lwfList.e204258 += Number(lwfList[i].e204258);
          }
        }

        // 离职金求和
        if (this.data.lzjList != null && this.data.lzjList.length > 0) {
          let lzjList = this.data.lzjList;
          for (let i in lzjList) {
            this.sums.lzjList.e204256 += Number(lzjList[i].e204256);
            this.sums.lzjList.e204257 += Number(lzjList[i].e204257);
            this.sums.lzjList.e204225 += Number(lzjList[i].e204225);
            this.sums.lzjList.e204228 += Number(lzjList[i].e204228);
            this.sums.lzjList.税前扣除社保个人部分 += Number(lzjList[i].e204216);
            this.sums.lzjList.税前扣除社保个人部分 += Number(lzjList[i].e204217);
            this.sums.lzjList.e204218 += Number(lzjList[i].e204218);
            this.sums.lzjList.e204219 += Number(lzjList[i].e204219);
            this.sums.lzjList.e204369 += Number(lzjList[i].e204369);
            // this.sums.lzjList.e204261 += Number(lzjList[i].e204261);
            // this.sums.lzjList.e204480 += Number(lzjList[i].e204480);
            this.sums.lzjList.e204262 += Number(lzjList[i].e204262);
            this.sums.lzjList.e204236 += Number(lzjList[i].e204236);
            this.sums.lzjList.e204231 += Number(lzjList[i].e204231);
            this.sums.lzjList.e204232 += Number(lzjList[i].e204232);
            this.sums.lzjList.e204233 += Number(lzjList[i].e204233);
            this.sums.lzjList.e204234 += Number(lzjList[i].e204234);
            this.sums.lzjList.e204237 += Number(lzjList[i].e204237);
            this.sums.lzjList.e204239 += Number(lzjList[i].e204239);
            this.sums.lzjList.e204240 += Number(lzjList[i].e204240);
            this.sums.lzjList.e204241 += Number(lzjList[i].e204241);
            this.sums.lzjList.e204258 += Number(lzjList[i].e204258);
          }
        }

        // 中籍工资补发
        if (this.data.zjfzbfList  != null && this.data.zjfzbfList.length > 0) {
          let zjfzbfList = this.data.zjfzbfList;
          for (let i in zjfzbfList) {
            this.sums.zjfzbfList.e204256 += Number(zjfzbfList[i].e204256);
            this.sums.zjfzbfList.e204257 += Number(zjfzbfList[i].e204257);
            this.sums.zjfzbfList.e204225 += Number(zjfzbfList[i].e204225);
            this.sums.zjfzbfList.e204228 += Number(zjfzbfList[i].e204228);
            this.sums.zjfzbfList.税前扣除社保个人部分 += Number(zjfzbfList[i].e204216);
            this.sums.zjfzbfList.税前扣除社保个人部分 += Number(zjfzbfList[i].e204217);
            this.sums.zjfzbfList.e204218 += Number(zjfzbfList[i].e204218);
            this.sums.zjfzbfList.e204219 += Number(zjfzbfList[i].e204219);
            // this.sums.zjfzbfList.e204261 += Number(zjfzbfList[i].e204261);
            this.sums.zjfzbfList.e204262 += Number(zjfzbfList[i].e204262);
            this.sums.zjfzbfList.e204236 += Number(zjfzbfList[i].e204236);
            this.sums.zjfzbfList.e204231 += Number(zjfzbfList[i].e204231);
            this.sums.zjfzbfList.e204232 += Number(zjfzbfList[i].e204232);
            this.sums.zjfzbfList.e204233 += Number(zjfzbfList[i].e204233);
            this.sums.zjfzbfList.e204234 += Number(zjfzbfList[i].e204234);
            this.sums.zjfzbfList.e204237 += Number(zjfzbfList[i].e204237);
            this.sums.zjfzbfList.e204239 += Number(zjfzbfList[i].e204239);
            this.sums.zjfzbfList.e204240 += Number(zjfzbfList[i].e204240);
            this.sums.zjfzbfList.e204241 += Number(zjfzbfList[i].e204241);
            this.sums.zjfzbfList.e204258 += Number(zjfzbfList[i].e204258);
            this.sums.zjfzbfList.e204369 += Number(zjfzbfList[i].e204369);
            // this.sums.zjfzbfList.e204480 += Number(zjfzbfList[i].e204480);
          }
        }

        // 外籍工资补发
        if (this.data.wjgzbfList  != null && this.data.wjgzbfList.length > 0) {
          let wjgzbfList = this.data.wjgzbfList;
          for (let i in wjgzbfList) {
            this.sums.wjgzbfList.e204256 += Number(wjgzbfList[i].e204256);
            this.sums.wjgzbfList.e204257 += Number(wjgzbfList[i].e204257);
            this.sums.wjgzbfList.e204225 += Number(wjgzbfList[i].e204225);
            this.sums.wjgzbfList.e204228 += Number(wjgzbfList[i].e204228);
            this.sums.wjgzbfList.税前扣除社保个人部分 += Number(wjgzbfList[i].e204216);
            this.sums.wjgzbfList.税前扣除社保个人部分 += Number(wjgzbfList[i].e204217);
            this.sums.wjgzbfList.e204218 += Number(wjgzbfList[i].e204218);

            this.sums.wjgzbfList.e204219 += Number(wjgzbfList[i].e204219);
            // this.sums.wjgzbfList.e204261 += Number(wjgzbfList[i].e204261);
            // this.sums.wjgzbfList.e204480 += Number(wjgzbfList[i].e204480);
            this.sums.wjgzbfList.e204262 += Number(wjgzbfList[i].e204262);
            this.sums.wjgzbfList.e204236 += Number(wjgzbfList[i].e204236);
            this.sums.wjgzbfList.e204231 += Number(wjgzbfList[i].e204231);
            this.sums.wjgzbfList.e204232 += Number(wjgzbfList[i].e204232);
            this.sums.wjgzbfList.e204233 += Number(wjgzbfList[i].e204233);
            this.sums.wjgzbfList.e204234 += Number(wjgzbfList[i].e204234);
            this.sums.wjgzbfList.e204237 += Number(wjgzbfList[i].e204237);
            this.sums.wjgzbfList.e204239 += Number(wjgzbfList[i].e204239);
            this.sums.wjgzbfList.e204240 += Number(wjgzbfList[i].e204240);
            this.sums.wjgzbfList.e204241 += Number(wjgzbfList[i].e204241);
            this.sums.wjgzbfList.e204258 += Number(wjgzbfList[i].e204258);
          }
        }

        // 劳务费补发
        if (this.data.lwfbfList  != null && this.data.lwfbfList.length > 0) {
          let lwfbfList = this.data.lwfbfList;
          for (let i in lwfbfList) {
            this.sums.lwfbfList.e204256 += Number(lwfbfList[i].e204256);
            this.sums.lwfbfList.e204257 += Number(lwfbfList[i].e204257);
            this.sums.lwfbfList.e204225 += Number(lwfbfList[i].e204225);
            this.sums.lwfbfList.e204228 += Number(lwfbfList[i].e204228);
            this.sums.lwfbfList.税前扣除社保个人部分 += Number(lwfbfList[i].e204216);
            this.sums.lwfbfList.税前扣除社保个人部分 += Number(lwfbfList[i].e204217);
            this.sums.lwfbfList.e204218 += Number(lwfbfList[i].e204218);

            this.sums.lwfbfList.e204219 += Number(lwfbfList[i].e204219);
            // this.sums.lwfbfList.e204261 += Number(lwfbfList[i].e204261);
            this.sums.lwfbfList.e204262 += Number(lwfbfList[i].e204262);
            this.sums.lwfbfList.e204236 += Number(lwfbfList[i].e204236);
            this.sums.lwfbfList.e204231 += Number(lwfbfList[i].e204231);
            this.sums.lwfbfList.e204232 += Number(lwfbfList[i].e204232);
            this.sums.lwfbfList.e204233 += Number(lwfbfList[i].e204233);
            this.sums.lwfbfList.e204234 += Number(lwfbfList[i].e204234);
            this.sums.lwfbfList.e204237 += Number(lwfbfList[i].e204237);
            this.sums.lwfbfList.e204239 += Number(lwfbfList[i].e204239);
            this.sums.lwfbfList.e204240 += Number(lwfbfList[i].e204240);
            this.sums.lwfbfList.e204241 += Number(lwfbfList[i].e204241);
            this.sums.lwfbfList.e204258 += Number(lwfbfList[i].e204258);
          }
        }
        // 股票期权
        if (this.data.gpqqList  != null && this.data.gpqqList.length > 0) {
          let gpqqList = this.data.gpqqList;
          for (let i in gpqqList) {
            this.sums.gpqqList.e204256 += Number(gpqqList[i].e204256);
            this.sums.gpqqList.e204257 += Number(gpqqList[i].e204257);
            // this.sums.gpqqList.e204261 += Number(gpqqList[i].e204261);
            this.sums.gpqqList.e204262 += Number(gpqqList[i].e204262);
            this.sums.gpqqList.e204237 += Number(gpqqList[i].e204237);
            this.sums.gpqqList.e204258 += Number(gpqqList[i].e204258);
          }
        }

        // 股权红利
        if (this.data.gqhlList != null && this.data.gqhlList.length > 0) {
          let gqhlList = this.data.gqhlList;
          for (let i in gqhlList) {
            this.sums.gqhlList.e204256 += Number(gqhlList[i].e204256);
            // this.sums.gqhlList.e204261 += Number(gqhlList[i].e204261);
            this.sums.gqhlList.e204262 += Number(gqhlList[i].e204262);
            this.sums.gqhlList.e204237 += Number(gqhlList[i].e204237);
            this.sums.gqhlList.e204258 += Number(gqhlList[i].e204258);
          }

        }
        // xxxx
        if (this.data.privateDetailList  != null && this.data.privateDetailList.length > 0) {
          let privateDetailList = this.data.privateDetailList;
          for (let i in privateDetailList) {
            this.sums.privateDetailList.money += Number(privateDetailList[i].money);
          }
        }
      },
      print() {
        this.hideBtn = true;
        this.$nextTick(()=>{
          window.print();
        })
      },
      close() {
        window.close();
      },
      afterPrint(){
        this.hideBtn = false;
        console.log("打开",this.hideBtn);
      }
    },
    beforeDestroy(){
      document.getElementsByTagName("body")[0].classList.remove("print");
    },
    filters: {
      wageFormat(wageMonth,type){
        if(wageMonth == null || wageMonth == ''){
          return wageMonth;
        }
        if(type == 'year'){
          return wageMonth.substring(0, wageMonth.indexOf("-"));
        }else{
         return  wageMonth.substring(wageMonth.indexOf("-")+1);
        }
      }
    }
  }
</script>

<style scoped>
  .tt{
    width: 100%;
  }
  .theader{
    text-align: center
  }
  .tbd{
    text-align: center
  }
  .divButtonRigth {
    margin-bottom: 10px;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .printBox{
    padding-bottom: 60px!important;
  }
  .hideBtn{
    display: none;
  }
</style>
<style>

  .print #app,.print #app .pull-height,.print #app .index,.print #app .index>div,.print .index .main{
    height:auto;
    overflow-y: auto;
    min-height: auto;
    min-width: 100%!important;
    max-width: 100%!important;
  }
  .print .index .main .router{
    padding: 0;
  }


</style>
