<template>
	<div>
		<div class="divButtonRigth">
			<el-button type="primary" size="small" fixed="right" @click="addPolicy">添加</el-button>
			<el-button type="primary" size="small" fixed="right" @click="updatePolicyStatus('3016000064')">发布</el-button>
			<el-button type="primary" size="small" fixed="right" @click="updatePolicyStatus('3016000065')">禁用</el-button>
		</div>

		<!--政策列表-->
		<bsp-table
			v-if="this.detailVisible=='false'"
			:data.sync="list"
			:tree-config="true"
			:ciic-data-filter.sync="listQuery"
			@ciic-data-refresh="__getPolicyList"
			@select-change="selectChange"
			@select-all="selectAll"
			show-header-overflow
			show-overflowwidth
			show-overflow
			v-loading="loading"
			element-loading-text="拼命加载中"
			element-loading-spinner="el-icon-loading"
			show-footer-overflow
			show-footer
			:footer-method="footerMethod"
		>
			<vxe-table-column type="selection" width="60" fixed="left" />

			<vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>

			<bsp-table-column title="标题" field="title" filter-type="input">
				<template slot-scope="scope">
					<span>{{scope.row.title}}</span>
				</template>
			</bsp-table-column>

			<bsp-table-column title="政策名称" field="policyName" filter-type="input">
				<template slot-scope="scope">
					<span>{{scope.row.policyName}}</span>
				</template>
			</bsp-table-column>

			<bsp-table-column title="政策简介" field="policyIntroduce" filter-type="input">
				<template slot-scope="scope">
					<span>{{scope.row.policyIntroduce}}</span>
				</template>
			</bsp-table-column>

			<bsp-table-column title="政策状态" field="policyStatus" filter-type="select" setId="3016">
				<template slot-scope="scope">
					<span>{{scope.row.policyStatus | dictFilter(dicts)}}</span>
				</template>
			</bsp-table-column>

			<bsp-table-column title="是否置顶" field="top" filter-type="select" setId="0090">
				<template slot-scope="scope">
					<span>{{scope.row.top | dictFilter(dicts)}}</span>
				</template>
			</bsp-table-column>

			<bsp-table-column title="政策创建时间" field="polcyCreateTime" filter-type="input">
				<template slot-scope="scope">
					<span>{{scope.row.polcyCreateTime}}</span>
				</template>
			</bsp-table-column>

			<bsp-table-column title="操作" fixed="right" align="center">
				<template slot-scope="scope">
					<div class="ciic-table-operate">
						<!-- <el-button type="text" @click="showDetails(scope.row)">跟进</el-button> -->
						<el-button type="text" @click="editPolicy(scope.row)">编辑</el-button>
						<el-button type="text" @click="deletePolicy(scope.row)">删除</el-button>
					</div>
				</template>
			</bsp-table-column>
		</bsp-table>

		<!--添加弹框-->
		<bsp-dialog
			dialogRef="dialogAdd"
			width="60%"
			height="80%"
			:title="dialogTitle"
			:dialog-visible.sync="adddialogVisible"
			@close="closeDialog"
			v-if="adddialogVisible"
		>
			<div slot="body">
				<addPolicy ref="dialogAdd" :list-data.sync="listForm"></addPolicy>
			</div>
			<span class="dialog-footer" slot="footer">
				<el-button type="primary" @click="savePolicy">确 定</el-button>
				<el-button @click="cancelDialog">取 消</el-button>
			</span>
		</bsp-dialog>
	</div>
</template>
<script>
import addPolicy from './addPolicy'
import BspTable from '@/components/bsp/bsp-table/bsp-table'
import BspDialog from '@/components/bsp/bsp-dialog/bsp-dialog'
import BspTableColumn from '@/components/bsp/bsp-table-column/bsp-table-column'
import {
	querypolicyList,
	deletePolicyBo,
	savePolicyBo,
	updatePolicyBo,
	updatePolicyStatusIds
} from '@/api/policy/policy'
import { downloadFile } from '@/util/util'
import { mapGetters } from 'vuex'

export default {
	name: 'leadsList',
	inject: ['reload'],
	components: {
		BspTable,
		BspTableColumn,
		BspDialog,
		addPolicy
	},
	computed: {
		...mapGetters(['dicts'])
	},
	props: {},

	created() {
		this.__getPolicyList()
	},
	data() {
		return {
			// 遮罩
			loading: false,
			// 用于接收后台返回的分页数据
			list: [],
			isUpdate: false,
			dialogTitle: '添加政策',
			detailVisible: 'false',
			//分页查询对象
			listQuery: {
				limit: 50,
				page: 1,
				total: 0
			},
			listForm: {},
			parameter: {
				batchImportIds: '',
				bizType: this.currentTab
			},
			//多选数组
			multipleSelection: [],
			// 新增
			adddialogVisible: false
		}
	},
	methods: {
		//查询政策列表信息
		__getPolicyList: function() {
			this.listQuery.isCus = this.currentTab
			console.log('列表转入的参数', this.listQuery)
			querypolicyList(this.listQuery).then(res => {
				if (res.data.code != 0) {
					this.listQuery.total = res.data.total
					this.list = res.data.records
					this.loading = false
				} else {
					this.loading = false
				}
			})
		},

		selectChange(value) {
			console.log(value)
			if (value.selection) {
				this.multipleSelection = []
				for (let index = 0; index < value.selection.length; index++) {
					const element = value.selection[index]
					this.multipleSelection.push(element)
				}
			}
		},
		//复选框全选事件
		selectAll({ checked, selection }) {
			if (checked) {
				this.multipleSelection = selection
			} else {
				this.multipleSelection = []
			}
			console.log('this.multipleSelection:' + this.multipleSelection)
		},
		//保存政策信息
		savePolicy() {
			let formData = this.$refs.dialogAdd.policyInfo
			let childForm = this.$refs.dialogAdd.$refs.policyInfo

			childForm.validate(valid => {
				if (valid) {
					if (this.isUpdate) {
						updatePolicyBo(formData).then(res => {
							if (res.data.msg == 'success') {
								this.$message.success('保存成功')
								this.adddialogVisible = false
								this.__getPolicyList()
							} else {
								this.$message.error('保存失败')
							}
						})
					} else {
						savePolicyBo(formData).then(res => {
							if (res.data.msg == 'success') {
								this.$message.success('保存成功')
								this.adddialogVisible = false
								this.__getPolicyList()
							} else {
								this.$message.error('保存失败')
							}
						})
					}
				}
			})
		},
		//删除线索
		deletePolicy(row) {
			deletePolicyBo(row.id).then(res => {
				if (res.data.data) {
					this.$message.success('删除成功')
					this.__getLeadsLis()
				} else {
					this.$message.error('删除失败')
				}
			})
		},

		// 新增
		addPolicy() {
			this.dialogTitle = '添加政策'
      this.listForm = [];
			this.adddialogVisible = true
		},

		// 关闭弹框
		closeDialog() {
			console.log('list -----------------', this.addListForm)
			// 清空表单
			this.$refs.dialogAdd.$refs.policyInfo.resetFields()
			this.adddialogVisible = false
		},
		// 取消
		cancelDialog() {
			console.log('list -----------------', this.addListForm)
			// 清空表单
			this.$refs.dialogAdd.$refs.policyInfo.resetFields()
			this.adddialogVisible = false
		},

		// 修改政策
		editPolicy(row) {
			// const query = {
			// 	//getDynamicForm参数
			// 	leadsId: row.id,
			// 	orgId: row.orgId,
			// 	currentTab: this.currentTab,
			// 	isEdit: true
			// }
			console.log('要修改的对象', row)

			this.listForm = row
			console.log('要修改的对象this.listForm', this.listForm)
			this.isUpdate = true
			this.dialogTitle = '修改政策'
			this.adddialogVisible = true
			//this.$router.push({ path: '/cus/leadsDetailsEdit', query })
		},

		// 修改政策状态
		updatePolicyStatus(status) {
			if (!this.multipleSelection.length > 0) {
				this.$message.success('请要选择数据 ！')
				return
			}
			let ids = []
			this.multipleSelection.forEach(item => {
				ids.push(item.id)
			})
			ids = ids.join(',')
			updatePolicyStatusIds({ ids: ids, status: status }).then(res => {
				if (res.data.data) {
					this.$message.success('修改成功！')
					this.__getLeadsLis()
				} else {
					this.$message.success('修改失败！')
				}
			})
		}
	}
}
</script>
<style scoped>
.copy-btn {
	cursor: pointer;
}

.ciic-table-operate {
	align-items: center;
}
</style>
