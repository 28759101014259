<template>
	<div ref="customerBox" class="customerBox">
		<!--头部-->
		<!--收起时为头部占位-->
		<div class="customerfixed" v-show="!fold" style="height:74px;" />
		<!--收起时补充头部上方空白-->
		<div
			class="customerfixed customerfixed1"
			v-show="!fold"
			:class="!fold?'fixed-top-bg':''"
			:style="!fold?boxWidth:''"
		/>
		<!--收起时tab导航-->
		<div class="customerfixed" v-show="!fold">
			<el-tabs v-model="tabIndex" @tab-click="changeTab" :style="!fold?tabWidth:''">
				<el-tab-pane
					:key="item.name"
					v-for="item in tabList"
					:label="item.title"
					:name="item.name"
					lazy="true"
				></el-tab-pane>
			</el-tabs>
		</div>
		<div class="customerInfo" :class="!fold?'fixed-top':''">
			<div class="customerInfo-head" :style="!fold?boxWidth:''">
				<div class="customerInfo-name">
					<span v-if="leadsInfo.status ==10090004" style="color: red">{{ leadsInfo.cusName }}</span>
					<span v-else>{{ leadsInfo.cusName }}</span>
					<el-tooltip placement="right-start">
						<div slot="content">{{leadsInfo.status | dictFilter(dicts)}}</div>
						<svg class="icon" aria-hidden="true">
							<use xlink:href="#icon-gongsibiaoshi" />
						</svg>
					</el-tooltip>
				</div>
				<div class="customerInfo-btns" v-show="!fold">
					<div class="states">
						<bsp-dicts-select
							v-model="leadsStatus"
							dic-num="3013"
							:select-value.sync="leadsStatus"
							@change="updateLeadsStatus"
						></bsp-dicts-select>
					</div>
					<div  class="states" v-if="this.leadsInfo.isCus!= '00901'">
						<el-button @click="tranCus()">
							<span>转为客户</span>
						</el-button>
					</div>
					<!-- <el-button @click="addFollow()">
            <span>写新跟进</span>
					</el-button>-->
				</div>

				<span class="customeInfo-pageGroup" @click="goBack">返回</span>
			</div>
			<div class="customerInfo-body" v-show="fold">
				<div class="customerInfo-body-detail">
					<p>
						<span>线索归属：</span>
						<span v-if="leadsInfo.ascriptionName">{{ leadsInfo.ascriptionName }}</span>
						<span v-else>--</span>
					</p>
					<p class="interval">/</p>

					<p>
						<span>联系人姓名：</span>
						<span v-if="leadsInfo.contacts">{{ leadsInfo.contacts }}</span>
						<span v-else>--</span>
					</p>
					<p class="interval">/</p>

					<p>
						<span>下次跟进：</span>
						<span v-if="leadsInfo.nextFollowTime">{{ leadsInfo.nextFollowTime | dictFilter(dicts) }}</span>
						<span v-else>--</span>
					</p>
				</div>
				<div class="customerInfo-btns">
					<div class="states">
						<bsp-dicts-select
							v-model="leadsStatus"
							dic-num="3013"
							:select-value.sync="leadsStatus"
							@change="updateLeadsStatus"
						></bsp-dicts-select>
					</div>
					<div class="states" v-if="this.leadsInfo.isCus!= '00901'">
						<el-button @click="tranCus()">
							<span>转为客户</span>
						</el-button>
					</div>
					<!-- <el-button @click="addFollow()">
            <span>写新跟进</span>
					</el-button>-->
				</div>
			</div>
		</div>
		<div class="customerDetail">
			<el-tabs v-model="tabIndex" @tab-click="changeTab">
				<el-tab-pane label="概况信息" name="leadsInfo" lazy="true">
					<leadsInfo is="leadsInfo" :leadsInfo="leadsInfo" :oldleadsInfo="oldleadsInfo"></leadsInfo>
				</el-tab-pane>
				<!-- <el-tab-pane label="跟进信息" name="followInfo" lazy="true">
          <followInfo :cusId="parameter.cusId" :tentanId="parameter.tentanId" :isEdit="parameter.isEdit"
                   @reflushCusContacts="reflushCusContacts"></followInfo>
        </el-tab-pane>
        <el-tab-pane label="相关附件" name="leadsEncl" lazy="true">
          <component is="leadsEncl" :cusId="parameter.cusId" :tentanId="parameter.tentanId"
                     :isEdit="parameter.isEdit"></component>
				</el-tab-pane>-->
			</el-tabs>
		</div>
		<!--转为客户-->
		<bsp-dialog
			dialogRef="dialogTurn"
			width="700px"
			title="转为客户"
			:dialogVisible.sync="turndialogVisible"
			@close="closeTurnDialog"
			v-if="turndialogVisible"
		>
			<div slot="body">
				<tranCus ref="dialogTurn" :list-data="turnListForm"></tranCus>
			</div>
			<span class="dialog-footer" slot="footer">
				<el-button @click="cancelTurnDialog">取 消</el-button>
				<el-button type="primary" @click="saveTransCus">确 定</el-button>
			</span>
		</bsp-dialog>
	</div>
</template>
<script>
import leadsInfo from './leads-form-edit'
import followInfo from './follow-form-edit'
import leadsEncl from './encl-form-edit'
import tranCus from './tranCus'
import { querLeadsBoById, updateLeadsBo, saveTranCus } from '@/api/cus/leads'
import { mapGetters } from 'vuex'
import bspDictsSelect from '@/components/bsp/bsp-dicts-select/bsp-dicts-select'
import BspDialog from '@/components/bsp/bsp-dialog/bsp-dialog'

export default {
	data() {
		return {
			// 线索状态
			clueStateList: [
				{ id: '1', roleName: '初访' },
				{ id: '2', roleName: '意向' },
				{ id: '3', roleName: '跟进' },
				{ id: '4', roleName: '签约' },
				{ id: '5', roleName: '流失' }
			],
			leadsStatus: '',
			// 转为客户
			turndialogVisible: false,
			turnListForm: {
				leadsId: '',
				cusName: '',
				country: '',
				industry: '',
				industryBig: '',
				industrySmall: '',
				nature: '',
				state: '',
				officeAddr: '',
				registerAddr: '',
				contactPerson: '',
				operator: ''
			},

			fold: true, //折叠头部信息
			boxWidth: 0, //折叠头的宽
			tabWidth: 0, //tab的宽
			tindex: {},
			buttonStyle: false,
			parameter: {
				//getDynamicForm参数
				id: this.$route.query.leadsId //cusId
			},
			leadsInfo: {
				cusName: ''
			},
			oldleadsInfo: {},
			tabs: {
				type: '10320001'
			},
			tabList: [
				{
					title: '概况信息',
					name: 'leadsInfo'
				},
				{
					title: '跟进信息',
					name: 'followInfo'
				},
				{
					title: '相关附件',
					name: 'leadsEncl'
				}
			],
			tabIndex: 'leadsInfo'
		}
	},
	computed: {
		...mapGetters(['dicts', 'pms', 'isCollapse', 'memberInfo'])
	},
	components: {
		leadsInfo,
		followInfo,
		tranCus,
		BspDialog,
		bspDictsSelect
	},
	watch: {
		isCollapse: function() {
			this.handleScroll('collapse')
		}
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll, true)
	},
	created() {
		this.getleadsInfo()
	},

	destroyed() {
		// 销毁滚动事件
		window.removeEventListener('scroll', this.handleScroll, true)
	},

	provide() {
		return {
			getleadsInfo: this.getleadsInfo
		}
	},
	inject: ['reload'],
	methods: {
		getleadsInfo() {
			querLeadsBoById(this.parameter.id).then(res => {
				console.log('得到销售线索的详情', res)
				this.leadsInfo = res.data.data
				this.oldleadsInfo = JSON.parse(JSON.stringify(res.data.data))
				this.leadsStatus = this.leadsInfo.leadsStatus
				console.log('得到销售线索的详情leadsState', this.leadsStatus)
				this.$forceUpdate
			})
		},
		// 改变页签事件
		changeTab(tab, event) {
			this.$forceUpdate()
			console.log('页签点击：' + tab.name)
			this.currentTab = tab.name
			this.fold = true
			// 回到顶部
			this.$emit('toTop')
		},
		handleScroll(type) {
			// 获取组件距顶距离
			let scrollTop = this.$refs.customerBox.getBoundingClientRect().top
			// 获取组件高度
			let height = this.$refs.customerBox.clientHeight
			//获取组件父级高度
			let parentHeight = window.innerHeight - 65
			this.boxWidth =
				'width:' + (this.$refs.customerBox.clientWidth - 70) + 'px'

			if (type == 'collapse') {
				if (this.isCollapse) {
					this.tabWidth =
						'width:' +
						(this.$refs.customerBox.clientWidth - 112) +
						'px'
					// this.boxWidth = "width:" + (this.$refs.customerBox.clientWidth +180 - 72 - 112 ) + 'px';
					// this.tabWidth = "width:" + (this.$refs.customerBox.clientWidth +180 - 72 - 62 - 20) + 'px';
				} else {
					// this.boxWidth = "width:" + (this.$refs.customerBox.clientWidth -180 + 72 - 112) + 'px';
					// this.tabWidth = "width:" + (this.$refs.customerBox.clientWidth -180 + 72 - 62 - 20) + 'px';
				}
			} else {
				// 如果收起后组件高度小于父组件高度，不收起
				if (scrollTop === 85) {
					this.fold = true
					return false
				} else {
					if (scrollTop < 20) {
						if (this.fold) {
							if (height - 136 > parentHeight) {
								this.fold = false
								// this.boxWidth = "width:" + (this.$refs.customerBox.clientWidth - 112) + 'px';
								// this.tabWidth = "width:" + (this.$refs.customerBox.clientWidth - 62 - 20) + 'px';
							}
						}
					} else {
						this.fold = true
					}
				}
			}
		},
		goBack() {
			this.$router.go(-1) //返回上一层
		},
		//修改客户线索状态
		updateLeadsStatus() {
			this.leadsInfo.leadsStatus = this.leadsStatus
			this.oldleadsInfo.leadsStatus = this.leadsStatus
			updateLeadsBo(this.leadsInfo).then(res => {
				if (res.data.msg == 'success') {
					this.$message.success('修改成功')
				} else {
					this.$message.error('修改失败')
				}
			})
			this.customerAuditVisible = true
		},

		//转为客户
		tranCus() {
			if (this.leadsInfo) {
				console.log(this.leadsInfo)
				if (this.leadsInfo.isCus == '00901') {
					this.$message.error('已经转为客户，不能在转为客户！')
					return
				}
				this.turnListForm.cusName = this.leadsInfo.cusName
				this.turnListForm.leadsId = this.leadsInfo.id
				this.turndialogVisible = true
			} else {
				this.$message.error('请选择一条数据！')
			}
		},
		closeTurnDialog() {
			console.log('list -----------------', this.turnListForm)
			this.$refs.dialogTurn.$refs.listData.resetFields()
			this.turndialogVisible = false
		},
		// 保存转为客户
		saveTransCus() {
			console.log('list -----------------', this.turnListForm)

			let childForm = this.$refs.dialogTurn.$refs.listData
			childForm.validate(valid => {
				if (valid) {
					let formData = this.$refs.dialogTurn.listData
					console.log(
						'list ---转客户提交参数1：--------------',
						formData
					)
					let operator = formData.operator
					let industry = formData.industry
					//  let conts ="";
					//  contactPerson.forEach(item=>{
					//    conts+=item+","
					//  })
					if (industry instanceof Array) {
						formData.industry = industry.join(',')
					}
					if (operator instanceof Array) {
						formData.operator = operator.join(',')
					}

					console.log(
						'list ---转客户提交参数2：--------------',
						formData
					)
					saveTranCus(formData).then(res => {
						if (res.data.data) {
							this.$message.success('保存成功')
							this.turndialogVisible = false
							this.getleadsInfo()
						} else {
							this.$message.error('保存失败')
						}
					})
				}
			})
			//
		},

		cancelTurnDialog() {
			console.log('list -----------------', this.turnListForm)
			// 清空表单
			this.$refs.dialogTurn.$refs.listData.resetFields()
			this.turndialogVisible = false
		},
		//写跟进
		addFollow() {}
	}
}
</script>
<style scoped>
.customerInfo-btns {
	display: flex;
	align-items: center;
}
.customerInfo-btns >>> .el-input__inner {
	height: 30px;
	border: 1px solid #4fa1ed;
	color: #ffffff;
	background: #3385ff;
}
.customerInfo-btns >>> .el-select .el-input .el-select__caret {
	color: #ffffff;
}
.states {
	width: 100px;
	display: inline-flex;
	height: 30px;
	margin-right: 10px;
}
.states >>> .el-select {
}
</style>
