<template>
  <div style="display: inline-flex">
    <div style="width: 250px">
      <fieldset style="border-color: #E2ded6;border-width: 0px;border-style: dotted;">
        <legend style="color:#333333;font-size: 1em;font-weight: bold;">
          <span>Excel智能解析匹配推荐次数</span>
        </legend>
        <el-input-number  style="width: 200px" v-model="matchNumber" @change="handleChange" :min="1" :max="10" label="描述文字"></el-input-number>
      </fieldset>
    </div>

    <div style="width: 250px">
      <fieldset style="border-color: #E2ded6;border-width: 0px;border-style: dotted;">
        <legend style="color:#333333;font-size: 1em;font-weight: bold;">
          <span>应发公式角色ID(","分割)</span>
        </legend>
        <el-input type="textarea" style="width: 200px" v-model="payableRoleList" @change="handleChangePayable" label="描述文字"></el-input>
      </fieldset>
    </div>
    <!--<div style="width: 250px">
      <fieldset style="border-color: #E2ded6;border-width: 0px;border-style: dotted;">
        <legend style="color:#333333;font-size: 1em;font-weight: bold;">
          <span>Excel智能解析匹配推荐次数</span>
        </legend>
        <el-input-number  style="width: 200px" v-model="num" @change="handleChange" :min="1" :max="10" label="描述文字"></el-input-number>
      </fieldset>
    </div>-->
  </div>
</template>
<script>
  import {updateColumnMatchNumber, getColumnMatchNumber, getPayableRoleList, updatePayableRoleList} from '@/api/sys/sysconf';
  export default {
    created() {
      this.__getColumnMatchNumber();
      this.__getPayableRoleList();
    },

    data() {
      return {
        matchNumber: 1,
        payableRoleList: '',
      };
    },
    methods: {
      handleChange(value) {
        console.log(value);
        updateColumnMatchNumber({matchNumber: value}).then(res => {
          debugger;
          this.$message.success("Excel智能解析匹配推荐次数修改成功！");
        })
      },

      handleChangePayable(value) {
        console.log(value);
        updatePayableRoleList({payableRoleList: value}).then(res => {
          debugger;
          this.$message.success("应发配置修改成功！");
        })
      },

      __getColumnMatchNumber: function () {
        getColumnMatchNumber().then(res => {
          debugger;
          if(res.data.code === 0){
            this.matchNumber = res.data.data;
          }
        })
      },

      __getPayableRoleList: function () {
        getPayableRoleList().then(res => {
          debugger;
          if(res.data.code === 0){
            this.payableRoleList = res.data.data;
          }
        })
      }
    }
  };
</script>
