<template>

  <div>
      <div>
          <salaryTop :step="step" @setStep="setStep"></salaryTop>
      </div>
      <router-view>

      </router-view>
  </div>
 
</template>

<script>
 
  import salaryTop from "@/views/demo/salarySet/template/salaryTop.vue";
  export default {
    name: "salarySet",
    data(){
      return{
        step:2,
     
        // dialogheaderVisible:false,
      }
    },
    mounted(){
      
    },
    components: {salaryTop},
    methods:{
         setStep(id){
          this.step = id;
      },
    }
  };
</script>
