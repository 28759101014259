import request from '@/router/axios';
const urlPrefix = ''

/**
 * 分支财务确认
 * @param obj
 * @param batchImportIds
 */
export function financialConfirmation(financeId) {
  return request({
    url: urlPrefix + '/wt/detailImport/financial-confirmation',
    method: 'post',
    data: {
      financeId
    }
  });
}

/**
 * 回滚财务批次状态为待财务确认
 * @param financeId
 */
export function rollbackFinancialBatch(financeId) {
  return request({
    url: urlPrefix + '/wt/detailImport/rollback-financialBatch',
    method: 'post',
    data: {
      financeId
    }
  });
}
/**
 * 分支财务退回
 * @param obj
 * @param batchImportIds
 */
export function financialSendBack(financeId,remark,submmiterName) {
  return request({
    url: urlPrefix + '/wt/detailImport/financial-send-back',
    method: 'post',
    data: {
      financeId,remark,submmiterName
    }
  });
}

/**
 * 分支财务确认列表
 * @param obj
 * @param batchImportIds
 */
export function getTransOrgIsAudit(orgId) {
  return request({
    url: urlPrefix + '/wt/tranfer/get-transorg-is-audit',
    method: 'post',
    data: {
      orgId
    }
  });
}

export function getIsMaintain() {
  return request({
    url: urlPrefix + '/wt/tranfer/getIsMaintain',
    method: 'post'
  });
}

/**
 * 分支财务确认列表
 * @param obj
 * @param batchImportIds
 */
export function getDistributionBatchList(obj, batchImportIds) {
  return request({
    url: urlPrefix + '/wt/detailImport/get-financial-confirmation-list',
    method: 'post',
    data: {
      obj
    }
  });
}

/**
 * 分支财务关联批次
 * @param obj
 * @param batchImportIds
 */
export function queryFinancialRelBatchPageQuery(obj) {
  return request({
    url: urlPrefix + '/wt/detailImport/financial-rel-batch-list',
    method: 'post',
    data: {
      obj
    }
  });
}

/**
 * 查询统筹账户余额
 * @param obj
 */
export function searchAccountBalanceByOrgId(obj) {
  return request({
    url: urlPrefix + '/wt/detailImport/search-account-balance-byorg',
    method: 'post',
    data: {
      obj
    }
  });
}


export function getSysCalendar(pms) {
  return request({
    url:urlPrefix +  '/sysCalendar/getSysCalendar/' + pms,
    method: 'get',
  })
}

