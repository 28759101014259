<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-button type="primary" size="small" @click="showAddList">新增</el-button>
      <el-button type="primary" size="small" @click="exportTable">导出</el-button>
    </div>
    <bsp-table :data.sync="list" ref="accountTable"
               :ciic-data-filter.sync="listQuery"
               @ciic-data-refresh="_getListpage">

      <vxe-table-column type="selection" width="60"></vxe-table-column>
       <bsp-table-column title="基本信息" :grounpColumns="grounpColumns"></bsp-table-column>
      <bsp-table-column field="id" title="id" filter-type="input">
        <template slot-scope="scope">
          <span>{{ scope.row.id}}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column field="idType" filter-type="select"  title="证件类型" setId="111" multiple="true" remNum="1110002,1110001">
        <template slot-scope="scope">
          <span>{{ scope.row.idType | dictFilter(dicts)}}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column field="icCard" title="身份证" sortable filter-type="select" :optionsList="sexList" :optionProps="{value: 'value', label: 'label'}">
        <template slot-scope="scope">
          <span>{{ scope.row.icCard}}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column field="birthday" title="出身日期"  filter-rule="between" filter-type="datePicker">
        <template slot-scope="scope">
          <span>{{ scope.row.birthday }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="操作" align="center">
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button size="small" @click="showDialog(scope.row)">编辑</el-button>
            <el-button size="small" type="success" @click="deleteRow(scope.row)">删除</el-button>
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>
  </div>
</template>

<script>
    import bspTable from "@/components/bsp/bsp-table/bsp-table";
    import bspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
    import { getListpage,updateListpage,addListpage,deleteData } from  "@/api/demo/demo"
    import exportMethod from "@/util/export"
    import demoForm from "./demo-form";
    import { mapGetters } from 'vuex';
    export default {
        name: "table",
        components: {demoForm,bspTableColumn, bspTable},
        created(){
          this._getListpage()
          this.idcardList = this.dicts.codeList[1001]

        },
        mounted() {

        },
      computed: {
        ...mapGetters([
          'dicts',
        ]),
      },
        data(){
            return{
                dialogFormVisible: false,
                showTable: false,
                list: [],
                idcardList: [],
                grounpColumns:[
                    {
                      field:"name",
                      title:"姓名",
                      grounpColumns:[
                        {
                            field:"name",
                            title:"姓名1",
                            filters:[{data: ''}],
                            setId:"1001",
                            remNum:"10010002,10010001",
                            filterType:'select',
                            props:{multiple: true},
                            sortable:'',
                            editType:'select'
                        },
                        {
                          field:"sex",
                          title:"性别1",
                          filters:[{data: ''}],
                          filterType:'datePicker',
                        }
                      ]
                    },
                    {
                      field:"gender",
                      title:"性别",
                      filters:[{data: ''}],
                      filterType:'datePicker',
                    }
                ],
                sexList: [{
                    value: '1',
                    label: '已提交'
                }, {
                    value: '2',
                    label: '审核中'
                },{
                    value: '3',
                    label: '已完成'
                }],
                listQuery:{
                    page: 1,
                    limit: 50,
                    total:0,
                },
                listForm: {},
                id:'',

                textMap: {
                    add: '新增',
                    update: '编辑'
                },
                dialogStatus: 'add'
            }
        },
        methods: {
            test(context){
                console.log(context)
            },
            //获取列表数据
            _getListpage(){
                getListpage(this.listQuery).then(res => {
                console.log(res)
                console.log(res.data.list)
                this.showTable = true
                this.listQuery.total = res.data.total
                // this.list = res.data.list
              })
            },
            //新增表格
            showAddList(){
                // if(this.$refs['listForm']){
                //     this.$refs['listForm'].resetFields();
                // }
                this.listForm = {}
                this.dialogFormVisible = true
                this.dialogStatus='add'
            },
            //展示编辑表单框
            showDialog(row){
                this.dialogFormVisible = true
                this.dialogStatus='update'
                this.listForm = row

            },
            //删除数据
            deleteRow(row){
                this.id = row.id;
                deleteData(this.id).then(res=>{
                  this.$message.success("删除成功")
                  this._getListpage();
                })
            },
            //导出表格
            exportTable(){
              exportMethod({columns:this.$refs['accountTable'].tableColums,...this.listQuery});

            }
        }
    }
</script>

<style scoped>

</style>
