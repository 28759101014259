<template>
	<div class="agreementBody">
		<el-form :model="form" ref="form" :rules="rules" label-width="150px" class="editForm">
			<div class="contractHead">合同基本信息</div>
			<el-row>
				<el-col :span="12">
					<el-form-item label="合同名称" prop="contractName">
						<el-input v-model="form.contractName" placeholder="请输入合同名称"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="合同编号">
						<el-input v-model="form.contractCode" placeholder="请输入合同名称"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="签约客户" prop="cusId">
						<el-select
							v-model="form.cusId"
							filterable
							clearable
							placeholder="请选择签约客户"
							@change="changeCus()"
						>
							<el-option v-for="item in cusList" :key="item.id" :label="item.cusName" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-show="form.id">
					<el-form-item label="合同状态">
						<bsp-dicts-select
							disabled
							v-model="form.status"
							dic-num="3010"
							:select-value.sync="form.status"
						/>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="合同类型" prop="contractType">
						<bsp-dicts-select v-model="form.contractType" dic-num="3012" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="合同语言" prop="contractLang">
						<bsp-dicts-select
							v-model="form.contractLang"
							dic-num="3014"
							:select-value.sync="form.contractLang"
						/>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="服务套餐" prop="contractPagckage">
						<div slot="label" class="serviceDialog" v-if="isShowPagckage">
							<p>服务套餐</p>
							<div class="serviceIconBox">
								<div class="serviceBox">
									<span class="serviceIcon">
										<svg class="icon" aria-hidden="true">
											<use xlink:href="#icon-tishi" />
										</svg>
									</span>
									<div class="serviceTable">
										<bsp-table
											height="400px"
											:data.sync="planDetailList"
											:tree-config="true"
											:ciic-data-filter.sync="listQuery"
											@ciic-data-refresh="queryServiceDetailListById(form.contractPagckage)"
											show-header-overflow
											v-loading="loading"
											show-footer-overflow
											show-footer
										>
											<bsp-table-column title="服务项目" field="serviceItem" filter-type="input">
												<template slot-scope="scope">
													<span>{{scope.row.serviceItem}}</span>
												</template>
											</bsp-table-column>
											<bsp-table-column title="服务说明" field="serviceDesc" filter-type="input">
												<template slot-scope="scope">
													<span v-html="scope.row.serviceDesc">></span>
												</template>
											</bsp-table-column>
											<bsp-table-column title="服务约定" field="serviceAgreement" filter-type="input">
												<template slot-scope="scope">
													<span v-html="scope.row.serviceAgreement"></span>
												</template>
											</bsp-table-column>
											<bsp-table-column title="服务费用" field="serviceFee" filter-type="input">
												<template slot-scope="scope">
													<span v-html="scope.row.serviceFee"></span>
												</template>
											</bsp-table-column>
										</bsp-table>
									</div>
								</div>
							</div>
						</div>
						<el-select v-model="form.contractPagckage" @change="changeService()">
							<el-option
								v-for="item in serviceList"
								:key="item.id"
								:label="item.planName"
								:value="item.id"
							></el-option>
						</el-select>
						<!-- <bsp-dicts-select
							v-model="form.contractPagckage"
							:select-value.sync="form.contractPagckage"
							dic-num="3014"
						/>-->
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="一次性服务">
						<bsp-dicts-select v-model="form.oneceService" dic-num="3015" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="签订地点" prop="signedPlace">
						<!-- <bsp-dicts-select
							v-model="form.signedPlace"
							:select-value.sync="form.signedPlace"
							dic-num="3014"
						/>-->
						<bsp-dicts-cascader
							v-model="form.signedPlace"
							:cascader-value.sync="form.signedPlace"
							:options-list.sync="options"
							@change="changeSignedPlace()"
						></bsp-dicts-cascader>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="签订时间" prop="signedTime">
						<el-date-picker
							v-model="form.signedTime"
							type="date"
							placeholder="选择日期"
							format="yyyy-MM-dd"
							value-format="yyyy-MM-dd"
						></el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>

			<div class="contractHead">甲方联系人信息</div>
			<el-row>
				<el-col :span="12">
					<el-form-item label="经办人" prop="firstPerson">
						<el-input v-model="form.firstPerson" placeholder="请输入甲方经办人"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="经办人Tel" prop="firstPhone">
						<el-input v-model="form.firstPhone" placeholder="请输入经办人Tel"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="经办人邮箱" prop="firstEmail">
						<el-input v-model="form.firstEmail" placeholder="请输入经办人邮箱"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="甲方办公地址" prop="firstCusPlace">
						<el-input v-model="form.firstCusPlace" placeholder="请输入甲方办公地址"></el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<div class="contractHead">乙方联系人信息</div>
			<el-row>
				<el-col :span="12">
					<el-form-item label="经办人" prop="secondPerson">
						<el-input v-model="form.secondPerson" placeholder="请输入乙方经办人"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="经办人Tel" prop="secondPhone">
						<el-input v-model="form.secondPhone" placeholder="请输入经办人Tel"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="经办人邮箱" prop="secondEmail">
						<el-input v-model="form.secondEmail" placeholder="请输入经办人邮箱"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="乙方办公地址" prop="secondCusPlace">
						<el-input v-model="form.secondCusPlace" placeholder="请输入乙方办公地址"></el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<div class="contractHead">其他信息</div>
			<el-row>
				<el-col :span="24" v-if="form.contractType == '3012000049'">
					<el-form-item label="合同附件" class="only hetongfujian" prop="filePath">
						<el-upload
							ref="upload"
							v-loading="loading"
							how-file-list="true"
							:file-list="fileList"
							:on-success="fileUploadSuccess"
							:on-error="fileUploadError"
							:before-upload="beforeFileUpload"
							:headers="headers"
							:data="uploadParam"
							name="uploadFile"
							action="/api/contract/file/upload/contract"
							class="upload-demo"
							:limit="1"
							accept=".doc,.docx"
						>
							<el-button size="small" type="primary">点击上传</el-button>
							<div slot="tip" class="el-upload__tip" @click="downloadCont">标准合同下载</div>
						</el-upload>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="创建人">
						<el-input v-model="form.createrName" disabled></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="创建时间">
						<el-date-picker
							v-model="form.bizCreateTime"
							disabled
							format="yyyy-MM-dd"
							type="date"
							placeholder="选择日期"
							value-format="yyyy-MM-dd"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="备注信息" prop="remarke" class="only">
						<el-input v-model="form.remarke" :rows="3" type="textarea" placeholder="请输入内容"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>

		<div style="padding:20px 0px  30px  0px">
			<div style="float:right">
				<el-button @click="_close">取 消</el-button>
				<el-button @click="_save">确 定</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import BspTable from '@/components/bsp/bsp-table/bsp-table'
import BspTableColumn from '@/components/bsp/bsp-table-column/bsp-table-column'
import { getCusByUser, getCusContacts } from '@/api/sys/cus'
import bspDictsSelect from '@/components/bsp/bsp-dicts-select/bsp-dicts-select'
import { mapGetters } from 'vuex'
import { downloadFile } from '@/util/util'
import { init, codeList, getCodeNames } from '@/components/bsp/code/frontCode'
import bspDictsCascader from '@/components/bsp/bsp-dicts-cascader/bsp-dicts-cascader'
import { isvalidatemobile, validateEmail } from '@/util/validate'
import {
	addContract,
	getContract,
	downloadStandardCont,
	updateContract
} from '@/api/cus/contract.js'
import {
	getServiceProductList,
	getPlanDetailList,
	getPlanDetailListById
} from '@/api/serviceProduct/serviceProduct'

export default {
	components: {
		bspDictsSelect,
		BspTable,
		BspTableColumn,
		bspDictsCascader
	},
	computed: {
		headers() {
			return {
				Authorization: `Bearer ${this.accessToken}`
			}
		},
		...mapGetters(['accessToken', 'dicts', 'userInfo'])
	},
	data() {
		// 邮箱
		let validateEmails = (rule, value, callback) => {
			var emailVal = value
			if (emailVal) {
				if (!validateEmail(emailVal)) {
					callback(new Error('请输入正确的邮箱地址！'))
				} else {
					callback()
				}
			} else {
				callback()
			}
		}
		return {
			token: ' Authorization: `Bearer ${this.accessToken}`',
			uploadUrl: '/api/wt/detailImport/upload-file', //导入地址
			cusList: [],
			options: [],
			fileList:[],
			serviceList: [],
			isShowPagckage: false, // 标识服务套餐的具体项目浮框显不显示
			planDetailList: [], // 套餐下的具体项目
			isUpdate: false,
			id: '',
			uploadParam: {
				contractName: '',
				cusId: '',
				cusName: ''
			},
			form: {
				contractName: '',
				contractCode: '',
				cusId: '',
				status: '',
				contractType: '',
				contractLang: '',
				signedPlace: '',
				oneceService: '',
				signedTime: '',
				firstPerson: '',
				firstPhone: '',
				firstEmail: '',
				firstCusPlace: '',
				secondPerson: '',
				secondPhone: '',
				secondEmail: '',
				secondCusPlace: '',
				remarke: '',
				bizCreateId: '',
				createrName: '',
				bizCreateTime: new Date(),
				signedPlaceName: '',
				filePath: ''
			},
			oneceServices: [],
			rules: {
				contractName: [
					{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}
				],
				cusId: [
					{
						required: true,
						message: '请选择签约客户',
						trigger: 'change'
					}
				],
				contractType: [
					{
						required: true,
						message: '请选择合同类型',
						trigger: 'change'
					}
				],
				contractLang: [
					{
						required: true,
						message: '请选择合同语言',
						trigger: 'change'
					}
				],
				contractPagckage: [
					{
						required: true,
						message: '请选择服务套餐',
						trigger: 'change'
					}
				],
				firstEmail: [{ validator: validateEmails, trigger: 'blur' }],
				secondEmail: [{ validator: validateEmails, trigger: 'blur' }],
				signedPlace: [
					{
						required: true,
						message: '请选择签订的地点',
						trigger: 'change'
					}
				],
				signedTime: [
					{
						required: true,
						message: '请输入签订时间',
						trigger: 'blur'
					}
				],
				firstPerson: [
					{ required: true, message: '请输入经办人', trigger: 'blur' }
				],
				firstPhone: [
					{
						required: true,
						message: '请输入经办人Tel',
						trigger: 'blur'
					}
				],
				secondPerson: [
					{ required: true, message: '请输入经办人', trigger: 'blur' }
				],
				secondPhone: [
					{
						required: true,
						message: '请输入经办人Tel',
						trigger: 'blur'
					}
				]
			}
		}
	},
	created() {
		this.getService()
		init('city')
		this.options = codeList
		this.form.bizCreateId = this.userInfo.userId
		this.form.createrName = this.userInfo.name
		this.initData("");
	},
	watch: {
		oneceServices(val) {
			this.form.oneceService = ''
			for (let i = 0; i < val.length; i++) {
				if (i === val.length - 1) {
					this.form.oneceService = this.form.oneceService + val[i]
				} else {
					this.form.oneceService =
						this.form.oneceService + val[i] + ','
				}
			}
		}
	},
	methods: {
		/**
		 * 得到服务套餐
		 */
		getService() {
			getServiceProductList().then(res => {
				this.serviceList = res.data.records
			})
		},

		/**
		 * 服务套餐值改变时
		 */
		changeService(v, a) {
			console.log('套餐值的改变', v, a, this.form.contractPagckage)
			if (this.form.contractPagckage) {
				this.isShowPagckage = true
				// 查询套餐具体的服务项目
				this.queryServiceDetailListById(this.form.contractPagckage)
			}
		},
		/**
		 * 查询套餐的具体项目
		 */
		queryServiceDetailListById(id) {
			getPlanDetailListById(id).then(res => {
				console.log('得到的具体的项目', res)
				this.planDetailList = res.data.data
				this.$forceUpdate()
			})
		},

		async initData(id) {
			if (id) {
				this.id = id
				this.isUpdate = true
				// 查询对应信息
				let res = await getContract(id)
				this.form = res.data.data
				if (this.form.oneceService) {
					this.oneceServices = this.form.oneceService.split(',')
				}
				if (this.form.signedPlace) {
					this.form.signedPlace = this.form.signedPlace.split(',')
				}
			} else {
				this.id = ''
				this.isUpdate = false
			}
			this.token = 'Authorization:' + `Bearer ${this.accessToken}`
			this._getCusByUser()
		},
		async _getCusByUser() {
			let res = await getCusByUser()
			this.cusList = res.data.data
		},
		_close() {
			this.$refs['form'].resetFields()
			this.$emit('hidden')
		},
		_save() {
			console.log("附件id",this.form.filePath);
			if (this.form.contractType == '3012000049') {
				if (!this.form.filePath) {
					this.$message.error('合同附件不能为空！请让传合同附件')
					return
				}
			}
			this.$refs['form'].validate(async valid => {
				if (valid) {
					this.form.signedPlace = this.form.signedPlace.join(',')
					console.log('是否是修改：====', this.isUpdate)
					// 修改
					if (this.isUpdate) {
						let res = await updateContract(this.id, this.form)
						if (res.data.code === 0) {
							this.$message.success('修改成功！')
							this._close()
							this.$emit('loadList')
						} else {
							this.$message.error(res.data.msg)
						}
					} else {
						//新增保存
						//1. 校验参数
						let res = await addContract(this.form)
						if (res.data.code === 0) {
							this.$message.success('创建成功！')
							this._close()
							this.$emit('loadList')
						} else {
							this.$message.error(res.data.msg)
						}
					}
				} else {
					console.log('error submit!!')
					return false
				}
			})
			// console.log(this.$store.state.impData.filePath);
		},

		beforeFileUpload(file) {

			this.uploadParam.contractName = this.form.contractName;
			this.$set(this, 'loading', true)
			const wordf = '.doc|.docx|'
			const extName = file.name
				.substring(file.name.lastIndexOf('.'))
				.toLowerCase()

			const isLtM = file.size / 1024 / 1024 < 10
			let isWord = wordf.indexOf(extName + '|') == '-1'
			if (isWord) {
				this.$message.error('导入文件只能是 doc,docx 格式!')
				this.$set(this, 'loading', false)
			}
			if (!isLtM) {
				this.$message.error('导入文件大小不能超过 10MB!')
				this.$set(this, 'loading', false)
			}
			//校验必要的参数
			if (!this.uploadParam.contractName) {
				this.$message.error('合同名称不能为空!')
				return false;
			}
			if (!this.uploadParam.cusName) {
				this.$message.error('签约客户不能为空!')
				return false;
			}
			console.log("上传前返回的结果",!isWord && isLtM);
			return !isWord && isLtM
		},

		fileUploadSuccess(res, file, fileList) {
			this.$set(this, 'loading', false)
			console.log("上传附件结果",res);
			if (res) {
				if (res.data.success==true) {
					this.form.filePath = res.data.id
					this.$message.success("上传成功");
					console.log("上传附件结果的附件id=",this.form.filePath);
				} else {
					this.$message.error(res.data.msg)
				}
			}
		},

		fileUploadError(err, file, fileList) {
			this.$set(this, 'loading', false)
			this.$message.error('导入失败!')
		},

		/**
		 * 下载标准的合同
		 */
		downloadCont() {
			if (!this.form.contractLang) {
				this.$message.error('合同语言不能为空！')
				return
			}

			if (!this.form.contractPagckage) {
				this.$message.error('服务套餐不能为空！')
				return
			}
			downloadStandardCont(this.form).then(res => {
				downloadFile(res)
			})
		},

		/**
		 * 签约客户改变时 查询出客户的联系人信息
		 */
		changeCus() {
			if (this.form.cusId) {
				getCusContacts(this.form.cusId).then(res => {
					if (res.data.data) {
						console.log('客户联系人信息返回的：', res)
						this.form.firstPerson = res.data.data.name
						this.form.firstPhone = res.data.data.officePhone
						this.form.firstEmail = res.data.data.emial
						this.form.firstCusPlace = res.data.data.unitAddress
					}
				})
				this.uploadParam.cusId = this.form.cusId
				this.cusList.forEach(item => {
					if (this.form.cusId ==item.id) {
						this.uploadParam.cusName =  item.cusName;
						console.log("得到的客户名称",this.uploadParam.cusName);
					}
				})
			}
		},
		/**
		 * 当签定地址改变时
		 */
		changeSignedPlace() {
			if (this.form.signedPlace) {
				console.log('得到地点的名称111', this.form.signedPlace)
				let fignedPlaceName = getCodeNames(
					this.form.signedPlace.join(',')
				)
				this.form.signedPlaceName = fignedPlaceName
				console.log('得到地点的名称', fignedPlaceName)
			}
		}
	}
}
</script>

<style scoped>
.agreementBody {
	margin-left: 50px;
}

.contractHead {
	padding: 0px 10px;
	margin: 20px 0px;
	font-weight: 700;
	border-left: 2px solid red;
}


.serviceDialog {
	display: flex;
}
.serviceIconBox {
	width: 15px;
	height: 30px;
	position: relative;
}
.serviceBox {
	position: absolute;
	text-align: left;
}
.serviceBox:hover .serviceTable {
	display: block;
}
.serviceTable {
	display: none;
	width: 1000px;
	border: 1px solid #eee;
	background: #ffffff;
	position: relative;
	z-index: 1;
}
.serviceTable >>> .vxe-cell {
	height: auto !important;
}
</style>
