<template>
    <div class="flex">
        <div class="left">
            <el-tree 
                :data="data"
                show-checkbox
                node-key="id"
                :default-expanded-keys="[2, 3]"
                :default-checked-keys="[5]"
                :props="defaultProps">
                </el-tree>
        </div>
        <div class="right">
            <Table></Table>
        </div>
    </div>
 
</template>

<script>
  import Table from "@/views/demo/serve/table.vue";
  export default {
    name: "selectPerson",
    props:{
        title: {
            type: String,
            default: () => (
              ''
            )
        },
    },
    components:{Table},
    data() {
      return {
        data: [{
          id: 1,
          label: '一级 1',
          children: [{
            id: 4,
            label: '二级 1-1',
            children: [{
              id: 9,
              label: '三级 1-1-1'
            }, {
              id: 10,
              label: '三级 1-1-2'
            }]
          }]
        }, {
          id: 2,
          label: '一级 2',
          children: [{
            id: 5,
            label: '二级 2-1'
          }, {
            id: 6,
            label: '二级 2-2'
          }]
        }, {
          id: 3,
          label: '一级 3',
          children: [{
            id: 7,
            label: '二级 3-1'
          }, {
            id: 8,
            label: '二级 3-2'
          }]
        }],
        defaultProps: {
          children: 'children',
          label: 'label'
        }
      };
    },
    methods:{
    
    }
  };
</script>

<style scoped>
    .flex{
        display: flex;
    }
    .flex>div{
        height: 800px;
    }
     .flex>div:nth-of-type(1){
         width: 250px;
         border-right: 1px solid #ccc;
         padding-right: 20px;
         margin-right:20px; 
         overflow: auto;
     }
       .flex>div:nth-of-type(2){
           width: calc(100% - 255px);
     }
</style>
