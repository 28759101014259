<template>
  <div class="outer-layer">
    <bsp-tabs :tabList="tabList" :tabIndex="tabIndex" @changeTab="changeTab">
      <component  v-if="isRouterAlive" ref="pub" :is="currentTab" :currentTab='currentTab'></component>
    </bsp-tabs>
  </div>
</template>
<script>
import bspTabs from "@/components/bsp/bsp-tabs/bsp-tabs";
import taxAreaList from "@/views/taxArea/taxAreaList";
import {mapGetters} from "vuex";
export default {
  computed: {
    ...mapGetters(['userInfo']),
  },
  created(){
    this.tabList= [{
      title: '计税区域',
      name: '0'
    },{
      title: '整转机构计税区域',
      name: '1'
    }];
    this.tabIndex = this.$route.query.currentTab ? this.$route.query.currentTab : '0';
    this.currentTab = this.tabIndex;
  },
  data() {
    return {
      tabList:[],
      tabIndex: "",
      currentTab:"",
      isRouterAlive:true,
    }
  },
  provide(){
    return{
      reload:this.reload
    }
  },
  components:{
    bspTabs,
    1: taxAreaList,
    0: taxAreaList,

  },
  methods: {
    changeTab(tab, event) {
      this.currentTab = tab.name;
      this.reload();
      console.log("hello everybody~~~this.currentTab==");
      console.log(this.currentTab);
    },
    reload (){
      this.isRouterAlive = false
      this.$nextTick(function(){
        this.isRouterAlive = true
      })
    }
  }
}
</script>
