<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right"   @click="saveNotice()">新增公告
      </el-button>
    </div>
    <bsp-table
      :data.sync="list"
      :tree-config="true"
      :ciic-data-filter.sync="listQuery"
      @select-change="selectChange"
      @select-all="selectChange"
      @ciic-data-refresh="loadList"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
    >
      <vxe-table-column type="selection" width="60" fixed="left"/>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <bsp-table-column title="公告id" field="id" filter-type="input" fixed="left" v-if="false">
        <template slot-scope="scope"><span>{{scope.row.id}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="标题" field="title" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.title}}</span></template>
      </bsp-table-column>
<!--      <bsp-table-column title="内容" field="message" filter-type="input" fixed="left">-->
<!--        <template slot-scope="scope"><span v-html="scope.row.message"></span></template>-->
<!--      </bsp-table-column>-->
      <bsp-table-column title="创建人" field="creator"  fixed="left"  width="80px">
        <template slot-scope="scope"><span>{{scope.row.createName}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="角色权限" field="roleNames" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.roleNames}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="创建时间" field="createTime" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.createTime}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="操作" fixed="left" align="center" width="260px">
        <template v-slot = "scope">
          <div class="ciic-table-operate">
            <el-button type="text" @click="noticeDetail(scope.row)">详情</el-button>
            <el-button type="text" @click="uploadFile(scope.row)">上传文件</el-button>
            <el-button type="text" @click="selectFile(scope.row)">文件列表</el-button>
            <el-button type="text" @click="noticeUpdate(scope.row)">修改</el-button>
            <el-button type="text" @click="noticeDelete(scope.row)"> <span v-if="scope.row.status == '0'">关闭公告</span><span v-else>开启公告</span></el-button>
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>

    <el-dialog
      v-dialogDrag
      width="740px"
      top="25vh"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="公告附件"
      :visible.sync="checkFileVisible"
      append-to-body
    >
      <vxe-grid v-bind="gridOptions" :columns="columns">
        <template #fileName="{ row }">
          <div class="ciic-table-operate">
            <el-button @click="downloadfile(row)" type="text" size="mini">{{ row.fileName }}</el-button>
          </div>
        </template>
        <template #operate="{ row }">
          <div class="ciic-table-operate">
            <el-button @click="deletefile(row)" type="text" size="mini"
            >删除</el-button
            >
          </div>
        </template>
      </vxe-grid>
    </el-dialog>



    <el-dialog v-dialogDrag
               width="52%"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               title="协议附件上传"
               :visible.sync="fileDialogVisible"
               @close="resetbefroeclose"
               append-to-body
    >
      <screen-upload ref="screenUpload"  :on-success="onsuccess" :on-error="onerror" :checkFlag = "false"></screen-upload>
    </el-dialog>

    <el-dialog  v-dialogDrag width="60%"
               :title="batchDialogTitle"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :visible.sync="batchDialogVisible"

    >
      <el-form ref="form2" class="formHeight" :model="validateForm" label-width="100px" >
        <el-row>
          <el-col :span="20">
            <el-form-item label="标题:">
              <span v-if="detail">{{this.notice.title}}</span>
              <el-input  v-else v-model="title" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="角色权限:" prop="message" >
              <span v-if="detail">{{this.notice.roleNames}}</span>
              <el-select v-else  v-model="role" @change="selectAreaName" :multiple="true" filterable
                         placeholder="请选择" id="region">
                <el-option
                  v-for="item in roleList"
                  :key="item.ROLEID"
                  :label="item.ROLENAME"
                  :value="item.ROLEID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公告内容:" prop="message" >
<!--              <span v-if="detail" style="white-space: pre-line" v-html="notice.message"></span>-->

<!--              <el-input v-else v-model="message"  resize="none" type="textarea"  :autosize="{ minRows: 18, maxRows: 18}" />-->
              <!-- 富文本编辑器 -->
              <quill-editor :aria-disabled = "detail"  v-model="message"></quill-editor>

            </el-form-item>

          </el-col>
        </el-row>
      </el-form>
      <div class="marginTop20" style="text-align: center">
        <el-button type="primary" @click="batchSave"  :disabled="saveDisabled"  v-if="!detail"> 保存</el-button>
        <el-button @click="batchCancel" v-if="!detail"> 取消</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>

import BspTable from "@/components/bsp/bsp-table/bsp-table";
import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
import {saveNotice, noticelist,roleList,addUploadNoticeFile,getDownloadFile,deleteFile,getByNoticeId,noticeDelete} from "@/api/sys/notice";
import ScreenUpload from '@/views/sysMaintenance/ScreenUpload.vue';
import {downloadPdfFile} from '@/util/util';
import {mapGetters} from 'vuex';
import {downLoadFinTaxAgreement} from "@/api/agreement/agreement";
// 导入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// 注册富文本组件
Vue.use(VueQuillEditor)

export default {
  name: "cusList",
  inject: ['reload'],
  components: {
    BspTable,
    BspTableColumn,
    ScreenUpload
  },
  computed: {
    ...mapGetters([
      'dicts'
    ],['accessToken']),
    headers() {
      return {
        Authorization: `Bearer ${this.accessToken}`
      }
    },
  },
  props: {
    currentTab: {
      type: String,
      default: ''
    },
  },

  created() {
    this.loadList();
    this._roleList();
  },

  data() {
    return {
      // 用于接收后台返回的分页数据
      list: [],
      fileForm:{
        filePath:[]
      },
      //分页查询对象
      listQuery: {
        limit: 50,
        page: 1,
        total: 0,

      },
      notice:{
        title:'',
        message:'',
        roleNames:'',
        roleIds:'',
      },
      saveDisabled:false,
      batchDialogTitle:  '公告信息',
      fileDialogTitle:  '文件上传',
      multipleSelection: [],
      batchDialogVisible: false,
      checkFileVisible: false,
      fileDialogVisible: false,
      detail: false,
      operatorList:[],
      operator: "",
      updateoperatorList:[],
      updateoperator:{
        cusId:'',
        beforeOperator:'',
        afterOperator:''
      },
      message:'',
      title:'',
      roleList:[],
      role: [],
      id:'',
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        maxHeight: 300,
        align: "center",
        data: [],
      },
      columns: [
        {type: "seq", width: 50, title: "序号"},
        {field: "fileName", title: "文件", slots: {default: "fileName"}},
        {field: "creteTime", title: "上传时间"},
        {title: "操作",width: 100, showOverflow: true, slots: {default: "operate"}},
      ],
    }
  },

  methods: {
    async downloadfile({id}) {
      // let res = await getDownloadFile(id);
      // downloadPdfFile(res);
      getDownloadFile(id).then(res=>{
        debugger
        downloadPdfFile(res);
      })
    },

    async selectFile(row) {
      try {
        let res = await getByNoticeId(row.id);
        this.gridOptions.data = res.data.data;
        this.checkFileVisible = true;
      } catch (err) {
        console.log(err);
      }
    },

    async deletefile({id,noticeId}) {
      try {
        debugger
        let res = await deleteFile(id);
        if (res.data.code == 0) {
          let tres = await getByNoticeId(noticeId);
          this.gridOptions.data = tres.data.data || [];
          if (this.gridOptions.data.length == 0) {
            this.checkFileVisible = false;
          }
          this.$message.success("删除成功");
          this.getData();
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },
    onerror(err, file, fileList) {
      this.$message.error(err)
    },

    onsuccess(res, file, fileList) {
      if (fileList.every(it => it.status == 'success')) {
        fileList.map(item => {
          if (item.response) {
            if (item.response.code==0) {
              this.afterUploadScreenFn({
                filePath: item.response.data,
                noticeId: this.id
              })
            } else {
              this.$message.error('文件上传失败');
            }
          } else {
            this.$message.error('文件上传失败');
          }
        })

      }
    },

    async afterUploadScreenFn(data) {
      try {
        let res = await addUploadNoticeFile(data)
        if (res.data.code == 0) {
          this.$message.success('文件上传成功')
          this.fileDialogVisible = false
        }
      } catch (err) {
        console.log(err)
      }
    },


    resetbefroeclose() {
      this.$refs['screenUpload'].resetFileList()
    },
    handleAvatarSuccess(res, file) {
      console.log('res', res)
      if(res.data.success=="false"){
        this.$message.error(res.data.msg);
      }
      this.listData.titleImg = res.data.id
      this.listData.titleImgPath = res.data.filePath

      console.log('filefileres', file)
      this.imageUrl = '/api/file/' + res.data.id // URL.createObjectURL(file.raw)
      this.uploadTipHeight = "135px"
    },
      handleCommand(command) {
        this.$message('click on item ' + command);
      },
    noticeDetail(row){
        this.notice.roleNames = row.roleNames;
        this.notice.message = row.message;
        this.message = row.message;
        this.notice.title = row.title;
        this.notice.id = row.id;
      this.batchDialogVisible = true;
      this.detail = true;

    },

    noticeUpdate(row){
      this.role = [];
      if(row.roleIds){
        let split =  row.roleIds.split(",");
        for (let i = 0; i < split.length; i++) {
          this.role.push(split[i]);
        }
      }
      this.title = row.title;
      this.message = row.message;
      this.id = row.id;
      this.batchDialogVisible = true;
      this.detail = false;
    },

    uploadFile(row){
      this.id = row.id;
      this.fileDialogVisible = true;
    },
    fileList(){

    },
    batchCancel() {
      this.batchDialogVisible = false;
      this.detail = false;
    },
    selectChange(value) {
      console.log(value)
      if (value.selection) {
        this.multipleSelection = []
        for (let index = 0; index < value.selection.length; index++) {
          const element = value.selection[index]
          this.multipleSelection.push(element)
        }
        console.log('this.multipleSelection:' + this.multipleSelection)
      }
    },

    _roleList(){
      roleList().then(res => {
        this.roleList = res.data.data
      })
    },
    loadList() {
      this.loading = true;
      noticelist(this.listQuery).then(res => {
        this.list = res.data.records;
        this.listQuery.total = res.data.total;
        this.loading = false;
        this.multipleSelection = []
      })
    },

    saveNotice() {
      this.title = '';
      this.message = '';
      this.id = '';
      this.role = [];
      this.batchDialogVisible = true;
      this.detail = false;
    },


    batchSave() {
      this.saveDisabled = true
      setTimeout(()=>{
        this.saveDisabled = false;
      },2000)
      var params ={title:this.title,message:this.message,roleId:this.role,id:this.id}
      saveNotice(params).then(res=>{
          if(res.data.code!=0){
            this.$message.error(res.data.msg);
          }else{
            this.$message.success("新增成功");
            this.batchDialogVisible = false;
            this.loadList();
          }

      })
    },
    noticeDelete(row){
      if(row.status == '0'){
        row.status = '1';
      }else if (row.status == '1'){
        row.status = '0';
      }
      noticeDelete(row.id, row.status).then(res => {
        if(res.data.code!=0){
          this.$message.error(res.data.msg);
        }else{
          this.loadList();
          this.$message.success("操作成功");
        }
      })
    }
  }
}
</script>

<style scoped>

.formHeight{
  line-height: 15px;
  height: 600px;
  overflow: auto;
}
.forminputHeight{

  height: 150px;
}

.abow_dialog {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;
.el-dialog {
  margin: 0 auto !important;
  height: 90%;
  overflow: hidden;
.el-dialog__body {
  position: absolute;
  left: 0;
  top: 54px;
  bottom: 0;
  right: 0;
  padding: 0;
  z-index: 1;
  overflow: hidden;
  overflow-y: auto;
}
}
}
</style>
