<template>
  <div class="printBox">
  <div>
     <div>支付单编号：{{this.payCode}}</div> 
    
    </div>
    <div style="text-align: center; font-weight: bolder">中智薪锐技术服务有限公司 对公支付单明细</div>
    <div>
      <table border="1" style="width: 100%">
        
        <tr>
          
          <th>客户名称名称</th>
          <th>付款开户名</th>
          <th>开户银行</th>
          <th>银行账号</th>
          <th>开户行地区</th>
          <th>支出金额</th>
        </tr>
        <tr v-for="(item,idx) in this.data" :key="idx" style="text-align: center">
          
          <td>{{item.cusname}}</td>
          <td>{{item.e206203}}</td>
          <td>{{item.e206205}}</td>
          <td>{{item.e206204}}</td>
          <td>{{item.e206206}}</td>
          <td>{{item.e206209 | moneyFilterStr}}</td>
        
        </tr>
        <tr style="text-align: center">
          <td colspan="2">支出合计</td>
          <td colspan="4">{{(this.sums.e206209 / 100) | moneyFilter}}</td>
          
        </tr>
      </table>
    </div>
    <!-- <div style="text-align: center">[{{this.data.paymentBillsBO.payCode}}]</div> -->
    <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right" @click="print">打印</el-button>
      <el-button type="primary" size="big" fixed="right" @click="close">关闭</el-button>
    </div>
  </div>
  
</template>

<script>

  import {
    
    printPublicPaymentDetail
  } from '@/api/payment/payment';

  export default {
    name: "printEmployeeDetail",
    created() {
      this.load();
    },
    mounted(){
      const that = this;
      this.$nextTick(()=>{
        document.getElementsByTagName("body")[0].classList.add("print");
      })

      if (window.matchMedia) {
        var mediaQueryList = window.matchMedia('print');

        //为印添加事件
        mediaQueryList.addListener(function(mql) {
          if (mql.matches) {
          } else {
            that.afterPrint();
          }
        });
      }
      //打印后事件
      window.onafterprint = that.afterPrint;

    },
    data() {
      return {
        // 页面数据
        data: {
          paymentBillsBO: [],
          cusList: [],
          bankList: [],
          publicPayments: []
        },
        //分页查询对象
        listQuery: {
          paymentId: this.$route.query.paymentId,
        },
        payCode: this.$route.query.payCode,
        sums: {
          e206209: 0,
         
        }
      }
    },
    methods: {
      load() {
        console.log(this.$route.query)
        printPublicPaymentDetail(this.listQuery).then(res => {
          this.data = res.data.data; 
           if(res.data.resCode!=200){
            alert("查询接口错误！请联系管理员")
            return false
          }
          
          for (let cus in this.data) {
            this.sums.e206209 += Number(this.data[cus].e206209) * 100;
            
          }
        })
      },
      print() {
        this.hideBtn = true;
        this.$nextTick(()=>{
          window.print();
        })
      },
      close() {
        window.close();
      },
      afterPrint(){
        this.hideBtn = false;
        console.log("打开",this.hideBtn);
      }
    },
    beforeDestroy(){
      document.getElementsByTagName("body")[0].classList.remove("print");
    }
  }
</script>

<style scoped>
  .tt{
    width: 100%;
  }
  .theader{
    text-align: center
  }
  .tbd{
    text-align: center
  }
  .divButtonRigth {
    margin-bottom: 10px;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .printBox{
    padding-bottom: 60px!important;
  }
  .hideBtn{
    display: none;
  }
</style>
<style>

  .print #app,.print #app .pull-height,.print #app .index,.print #app .index>div,.print .index .main{
    height:auto;
    overflow-y: auto;
    min-height: auto;
    min-width: 100%!important;
    max-width: 100%!important;
  }
  .print .index .main .router{
    padding: 0;
  }


</style>
