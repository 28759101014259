<template>
  <div>
    <div class="divButtonRigth" v-if  ="listQuery.status==='0'">
      <el-button type="primary" size="small" fixed="right" @click="searchByConditions">查询</el-button>
<!--      <el-button type="primary" size="small" fixed="right" @click="refresh">刷新</el-button>-->
      <el-button type="primary" size="small" fixed="right" @click="downloadOnline">在线下载</el-button>
      <el-button type="primary" size="small" fixed="right" @click="exportExcel">导出Excel</el-button>
    </div>
    <el-row>
    <el-form :inline="true" :model="listQuery" class="editForm" label-width="80px">
      <el-col :span="6">
        <el-form-item label="银行类别:" class="shot">
          <el-select  class="status-select" v-model="listQuery.bankType" @change="queryBankInfoByType">
            <template v-for="(item,index) in bankTypes">
              <el-option :label='bankTypes[index].name' :value="bankTypes[index].id" :key="index"></el-option>
            </template>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
      <el-form-item label="账号:" class="shot">
          <el-select
            v-model="listQuery.account"
            multiple
            collapse-tags
            placeholder="请选择">
          <el-option
            v-for="item in bankInfos"
            :key="index"
            :label="item.openBank"
            :value="item.account">
            <span style="float: left">{{item.openBank}} </span>
            <span style="float: right; color: #8492a6; font-size: 13px"> | {{item.account}}</span>
          </el-option>
        </el-select>
      </el-form-item>
      </el-col>
      <el-col :span="12">
      <el-form-item label="选择时间:">
        <div class="block">
          <el-date-picker
            v-model="startAndEndDate"
            type="daterange"
            align="right"
            unlink-panels
            clearable
            range-separator="至"
            start-placeholder="起始日期"
            end-placeholder="截止日期"
            :picker-options="pickerOptions"
            value-format="yyyyMMdd"
            @click="parseStartAndEndDate">
          </el-date-picker>
        </div>
      </el-form-item>
      </el-col>
    </el-form>
    </el-row>
    <bsp-table
      :data.sync="list"
      :tree-config="true"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="refresh"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
    >

<!--      <vxe-table-column type="selection" width="60" fixed="left"/>-->
<!--      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>-->

      <bsp-table-column title="交易日期" field="etydate" filter-type="input" width="100" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.etydate}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="交易时间" field="etytime" filter-type="input" width="100" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.etytime}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="对方账号" field="rpyacc" filter-type="input" width="100" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.rpyacc}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="对方户名" field="rpyname" filter-type="input" width="100" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.rpyname}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="业务类型" field="bustype" filter-type="input" width="100" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.bustype}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="摘要" field="naryur" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.naryur}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="币种" field="ccynbr" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.ccynbr}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="交易金额" field="trsamt" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.trsamt | moneyFilterStr}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="余额" field="trsblv" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.trsblv | moneyFilterStr}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="交易代码" field="trscode" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.trscode}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="交易类型" field="amtcdr" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.amtcdr}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="本方账号" field="accnbr" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.accnbr}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="本方户名" field="accname" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.accname}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="流水号" field="refnbr" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.refnbr}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="备注1" field="remark1" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.remark1}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="备注2" field="remark2" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.remark2}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="导入批次号" field="batchNo" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.batchNo}}</span></template>
      </bsp-table-column>
    </bsp-table>

  </div>
</template>
<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {
    searchBankTypeList,
    queryBankInfoByType,
    searchDetailListByConditions,
    downloadOnline,
    exportExcel
  } from '@/api/bankDirect/bankDirect';
  import {downloadFile, downloadPdfFile} from '@/util/util';
  import {mapGetters} from 'vuex';

  export default {
    name: 'bankDirectDetail',
    inject: ['reload'],
    components: {
      BspTable,
      BspTableColumn,
    },
    computed: {
      ...mapGetters([
        'dicts'
      ]),
    },
    props: {
      currentTab: {
        type: String,
        default: ''
      },

    },
    created() {

      this.searchBankType();
      this.initDate();

    },
    data() {
      return {
        // 下拉选中的银行类别
        bankTypes: [],
        // 根据银行类别选取的银行信息
        bankInfos: [],
        // 用于接收后台返回的分页数据
        list: [],
        // detailVisible: 'false',
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          bizType: '',
          status:'0',
          bankType: '3084821052',
          flag: '0'
          // batchStatus: '00900'//默认为未提交
        },
        parameter: {
          batchImportIds: '',
          bizType: this.currentTab,
          cusId: '',
          orgId: '',
          billsTitle: '',//BILLSTITLENAME
          taxNum: '',//F204204
          taxAdress: '',//F204205
          taxPhone: '',//F204206
          recipients: '',//RECEIVEDEPT
          emailAddress: '',//postcode
          subIds: '',//明细选择导出
          subIdFlag: '',//明细导出标识
        },
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        startAndEndDate: '',
        defaultDate: [new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 7), new Date()],
        dataList: [],
        tableColumn: [], //显示表头
        sumItem: [],//需要合计的列
        detailImportCusList: [],
        cuslistQuery: {
          limit: 50,
          page: 1,
          total: 0,
          bizType: '',
          batchStatus: '00900',//默认为未提交
          batchImportId: '',
        },
      }
    },
    methods: {
      parseStartAndEndDate() {
        let date1 = this.startAndEndDate;
        if (date1 !== null && date1.length > 0) {
          this.listQuery.startDate = date1[0];
          this.listQuery.endDate = date1[1];
        } else {
          this.listQuery.startDate = null;
          this.listQuery.endDate = null;
        }
      },
      // 初始化时间选择框
      initDate() {
        let now = new Date();
        let year = now.getFullYear(); //得到年份
        let month = now.getMonth(); //得到月份
        let date = now.getDate(); //得到日期
        month = month + 1;
        month = month.toString().padStart(2, "0");
        date = date.toString().padStart(2, "0");
        let defaultDate = `${year}-${month}-${date}`;
        this.startAndEndDate = [defaultDate.replace(/-/ig, ''), defaultDate.replace(/-/ig, '')];
      },

      // 查询下拉银行类型列表
      searchBankType: function () {
        searchBankTypeList().then(res => {
          this.bankTypes = res.data;
          this.queryBankInfoByType();
        })
      },

      // 根据银行类型查询银行信息
      queryBankInfoByType: function () {
        this.listQuery.account = null;
        queryBankInfoByType(this.listQuery).then(res => {
          this.bankInfos = res.data;
          if (this.bankInfos.length > 0) {
            for (let i in this.bankInfos) {
              if (this.bankInfos[i].accountType === '600302') {
                this.listQuery.account = [];
                this.listQuery.account[0] = this.bankInfos[i].account;
                break;
              }
            }
          }
        })
      },

      // 根据条件查询
      searchByConditions: function () {
        this.parseStartAndEndDate();
        searchDetailListByConditions(this.listQuery).then(res => {
          this.list = res.data.records;
          this.listQuery.total = res.data.total;
        })
      },

      // 刷新页面
      refresh: function () {
        this.parseStartAndEndDate();
        searchDetailListByConditions(this.listQuery).then(res => {
          this.list = res.data.records;
          this.listQuery.total = res.data.total;
        })
      },

      // 在线下载
      downloadOnline: function () {
        if (this.listQuery.account === null || this.listQuery.account.length === 0) {
          alert("请选择账号！");
          return;
        }
        if (this.listQuery.bankType !== '3084821052') {
          alert("目前只支持招行账户");
          return;
        }
        if (this.listQuery.account.length !== 1) {
          alert("只能选择一个账号！");
          return;
        }
        this.parseStartAndEndDate();
        downloadOnline(this.listQuery).then(res => {
          this.searchByConditions();
        })
      },

      // 导出excel
      exportExcel: function () {
        this.parseStartAndEndDate();
        exportExcel(this.listQuery).then(res => {
          const url = window.URL.createObjectURL(res.data);
          const link = document.createElement('a');
          link.href = url;
          const fileName = `${res.headers['content-disposition'].replace('attachment;filename=','')}`;
          let channelUrl=decodeURIComponent(fileName);
          link.setAttribute('download', channelUrl);
          link.click()
        })
      },
    }
  }
</script>
<style scoped>
  .copy-btn {
    cursor: pointer;
  }

  .ciic-table-operate {
    align-items: center;
  }

  .editForm {
    margin: 0;
    overflow: hidden;
    padding-top: 0;
  }

  .editForm .el-form-item {
    margin: 0;
  }
  .shot >>> .el-form-item__content{
    width: calc(100% - 80px);
  }
</style>
