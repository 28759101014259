<template>
    <div>
      <bsp-table :data.sync="tableData" ref="accountTable">
        <bsp-table-column title="操作" fixed="left" align="center" >
            <template>
              <div class="ciic-table-operate">
                <el-button type="text" @click="lookForm">详情</el-button>
              </div>
            </template>
        </bsp-table-column>
          <bsp-table-column field="name" title="姓名:">
            <template slot-scope="scope">
              <span>{{ scope.row.name}}</span>
            </template>
          </bsp-table-column>
        <bsp-table-column field="date" title="日期:">
          <template slot-scope="scope">
            <span>{{ scope.row.date}}</span>
          </template>
        </bsp-table-column>
        <bsp-table-column field="province" title="省份:">
          <template slot-scope="scope">
            <span>{{ scope.row.province}}</span>
          </template>
        </bsp-table-column>
        <bsp-table-column field="city" title="城市:">
          <template slot-scope="scope">
            <span>{{ scope.row.city}}</span>
          </template>
        </bsp-table-column>
        <bsp-table-column field="address" title="地址:">
          <template slot-scope="scope">
            <span>{{ scope.row.address}}</span>
          </template>
        </bsp-table-column>
      </bsp-table>
      <el-dialog title="表单"

                 :close-on-click-modal="false"
                 :close-on-press-escape="false"
                 :visible.sync="dialogFormVisible">
        <el-row>
          <el-col :span="24" align="right">
            <el-button  @click="disabled = false" v-if="disabled" type="primary" icon="el-icon-edit" size="mini"></el-button></el-col>
        </el-row>
        <el-form :model="form" class="bsp-disable-form" label-width="150px" :disabled="disabled">
          <el-form-item label="输入框:">
            <el-input  v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="下拉选:">
            <el-select   v-model="form.selectValue" placeholder="请选择">
              <el-option
                v-for="item in selectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="级联下拉选:">
            <el-cascader

              v-model="form.value"
              :options="options"
              :props="{ expandTrigger: 'hover' }"
              @change="handleChange"></el-cascader>
          </el-form-item>
          <el-form-item label="时间选择:">
            <el-time-select

              v-model="form.time"
              placeholder="选择时间">
            </el-time-select>
          </el-form-item>
          <el-form-item label="时间范围选择">
            <el-time-picker

              is-range
              v-model="form.time4"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围">
            </el-time-picker>
          </el-form-item>
          <el-form-item label="日期选择:">
            <el-date-picker
              v-model="form.time1"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="日期时间选择:">
            <el-date-picker
              v-model="form.time2"
              type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="日期时间范围">
            <el-date-picker
              v-model="form.time3"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="即时配送">
            <el-switch  v-model="form.swichVal"></el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" v-if="!disabled">
          <el-button @click="resetForm">取 消</el-button>
          <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import BspTable from "../../../components/bsp/bsp-table/bsp-table";
    import BspTableColumn from "../../../components/bsp/bsp-table-column/bsp-table-column";
    export default {
        name: "staticform",
        components: {BspTableColumn, BspTable},
        data() {
            return {
                dialogFormVisible: false,
                form: {
                    name: '张三',
                    selectValue: '选项1',
                    value: ['zhinan', 'shejiyuanze', 'yizhi'],
                    time: '9:01',
                    time4: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
                    time1: '2019-08-01',
                    time2: '2019-08-01 9:01',
                    time3: ['2019-08-01 9:01', '2019-08-02 9:01'],
                    swichVal: true,
                },
                origForm: {
                    name: '张三',
                    selectValue: '选项1',
                    value: ['zhinan', 'shejiyuanze', 'yizhi'],
                    time: '9:01',
                    time4: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
                    time1: '2019-08-01',
                    time2: '2019-08-01 9:01',
                    time3: ['2019-08-01 9:01', '2019-08-02 9:01'],
                    swichVal: true,
                },
                tableData: [
                  {
                    date: '2016-05-02',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1518 弄',
                    zip: 200333
                  }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1517 弄',
                    zip: 200333
                  }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1519 弄',
                    zip: 200333
                  }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    province: '上海',
                    city: '普陀区',
                    address: '上海市普陀区金沙江路 1516 弄',
                    zip: 200333
                  }
                ],
                options: [
                  {
                    value: 'zhinan',
                    label: '指南',
                    children: [
                        {
                          value: 'shejiyuanze',
                          label: '设计原则',
                          children: [
                            {
                              value: 'yizhi',
                              label: '一致'
                            }, {
                              value: 'fankui',
                              label: '反馈'
                            }, {
                              value: 'xiaolv',
                              label: '效率'
                            }, {
                              value: 'kekong',
                              label: '可控'
                            }
                          ]
                        },
                        {
                          value: 'daohang',
                          label: '导航',
                          children: [
                            {
                              value: 'cexiangdaohang',
                              label: '侧向导航'
                            }, {
                              value: 'dingbudaohang',
                              label: '顶部导航'
                            }
                          ]
                    }]
                  }, {
                    value: 'ziyuan',
                    label: '资源',
                    children: [{
                        value: 'axure',
                        label: 'Axure Components'
                    }, {
                        value: 'sketch',
                        label: 'Sketch Templates'
                    }, {
                        value: 'jiaohu',
                        label: '组件交互文档'
                    }]
                  }
                ],
                selectOptions: [{
                    value: '选项1',
                    label: '黄金糕'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
                disabled: false
            }
        },
        methods: {
            handleChange(value) {
                console.log(value);
            },
            updateForm(){
                this.dialogFormVisible = true
                this.disabled = false
            },
            lookForm(){
                this.dialogFormVisible = true
                this.disabled = true
            },
            resetForm(){
                this.disabled = true;
                this.form = this.origForm;
            }
        }
    }
</script>

<style scoped>

</style>
