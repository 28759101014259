<template>
  <div>
    <!--
       给editor加key是因为给tinymce keep-alive以后组件切换时tinymce编辑器会显示异常，
       在activated钩子里改变key的值可以让编辑器重新创建
     -->
    <editor :id="tinymceId" ref="editor" :init="tinymceInit" v-model="content" :key="tinymceFlag"></editor>
  </div>
</template>

<script>
    // import Tinymce from 'tinymce/tinymce'
    // import 'tinymce/themes/silver'
    import Editor from '@tinymce/tinymce-vue'
    // import 'tinymce/icons/default/icons'
    // import 'tinymce/plugins/image'
    // import 'tinymce/plugins/link'
    // import 'tinymce/plugins/code'
    // import 'tinymce/plugins/table'
    // import 'tinymce/plugins/lists'
    // import 'tinymce/plugins/contextmenu'
    // import 'tinymce/plugins/wordcount'
    // import 'tinymce/plugins/colorpicker'
    // import 'tinymce/plugins/textcolor'
    export default {
        name: "ciic-tinymce-editor",
        components: {
            'editor': Editor
        },
        props:{
            height: {
                type:Number,
                default: 300
            },
            value: {
                type: String,
                default: ''
            },
            tinymceId: {
                type: String,
                default: 'tinymceEditor'
            },
            flag:{
                type: String,
                default: ''
            }
        },
        watch:{
            value(newVal, oldVal){
                this.content= newVal
            },
            content(newVal, oldVal){
                if(this.flag == ''){
                    let placeValue = newVal.replace(/\"/g,"'")
                    this.$emit('update:value', placeValue);
                }else {
                    this.$emit('update:value', newVal);
                }

            },
        },
        created () {
            this.content= this.value
            this.tinymceInit = {
                skin_url:`${this.publicPath}tinymce/skins/ui/oxide`,
                language_url: `${this.publicPath}tinymce/langs/zh_CN.js`,
                language: 'zh_CN',
                height: this.height,
                fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 25pt 26pt 30pt 32pt 36pt",
                browser_spellcheck: true, // 拼写检查
                branding: false, // 去水印
                // elementpath: false,  //禁用编辑器底部的状态栏
                statusbar: false, // 隐藏编辑器底部的状态栏
                paste_data_images: true, // 允许粘贴图像
                menubar: false, // 隐藏最上方menu
                plugins: 'link lists image code table colorpicker textcolor wordcount contextmenu',
                toolbar:[
                    'fontselect bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist',
                    'outdent indent blockquote | undo redo | link unlink image | removeformat',
                ],
                branding: false,
                convert_urls: false,  //不覆盖url
                //启用菜单栏并显示如下项 [文件 编辑 插入 格式 表格]
                menubar: 'file edit insert view format table',
                // 配置每个菜单栏的子菜单项（如下是默认配置）
                menu: {
                    file: {title: 'File', items: 'newdocument'},
                    edit: {title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall'},
                    insert: {title: 'Insert', items: 'link media | template hr'},
                    view: {title: 'View', items: 'visualaid'},
                    format: {title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat'},
                    table: {title: 'Table', items: 'inserttable tableprops deletetable | cell row column'},
                }
            }
        },
        activated () {
            this.tinymceFlag++
        },
        mounted () {
            // console.log('测试3',this.value)
            this.content= this.value
        },
        data () {
            return {
                tinymceFlag: 1,
                tinymceInit: {},
                content: '',
                publicPath: process.env.BASE_URL
            }
        },
        methods: {

        },

    }
</script>

<style>


</style>
