<template>
  <div class="stepContent">
    <div class="uploadBox">
      <el-form :inline="true" label-width="80px" :model="form" :rules="rules" ref="form">
        <el-form-item label="所属客户" prop="cusId">
          <el-select v-model="form.cusId" filterable clearable placeholder="请选择客户">
            <el-option
              v-for="item in cusList"
              :key="item.id"
              :label="item.cusName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账单月份" prop="billMonth">
          <el-date-picker v-model="form.billMonth"
                          type="month"
                          value-format ="yyyy-MM"
                          placeholder="选择月份">
          </el-date-picker>
        </el-form-item>

        <el-upload
          ref="upload"
          v-loading="loading"
          :show-file-list="true"
          :on-success="fileUploadSuccess"
          :on-error="fileUploadError"
          :before-upload="beforeFileUpload"
          :headers="headers"
          :action="uploadUrl"
          class="uploadContent"
          :limit="1"
          drag
          accept=".xls,.xlsx">
          <i class="el-icon-upload"/>
          <div class="el-upload__text">点击或将文件拖到此处上传</div>
        </el-upload>

      </el-form>
    </div>
    <div class="download">
      <p @click="exportTemplate">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-xiazai"></use>
        </svg>
        社保导入模板下载
      </p>
    </div>
    <div class="uploadTip">
      <div class="uploadTip-title">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-tishi"></use>
        </svg>
        温馨提示
      </div>
      <div class="uploadTip-lists">
        <div class="uploadTip-list">
          1、支持文件后缀 xls、xlsx文件，且不超过10M
        </div>
        <div class="uploadTip-list">
          2、请将要上传的内容放在第一个sheet中
        </div>
        <div class="uploadTip-list">
          3、第一个sheet中首行必须为表头，且不支持复合表头
        </div>
        <div class="uploadTip-list">
          4、请不要加密模板文件，将导致错误
        </div>
      </div>
    </div>
    <div style="padding:20px 0">
      <div style="float:right">
        <el-button @click="_close">取 消</el-button>
        <el-button @click="_save">确 定</el-button>
      </div>

    </div>


  </div>
</template>
<script>
  import { getCusByUser } from '@/api/sys/cus'
  import { importSocialSecurity , downloadSocialSecurity }  from '@/api/entrust/socialSecurity'
  import {downloadFile} from '@/util/util'
  import {mapGetters} from "vuex";
  export default {
    name: 'socialSecurityImp',
    data() {
      return {
        token:' Authorization: `Bearer ${this.accessToken}`',
        uploadUrl: "/api/wt/detailImport/upload-file",//导入地址
        form: {
          cusId: '',
          billMonth: '',
          filePath: ''
        },
        rules: {
          cusId: [
            {required: true, message: '请选择客户', trigger: 'change'}
          ],
          billMonth: [
            {required: true, message: '请选择账单月份', trigger: 'change'}
          ],
          filePath: [
            {required: true, message: '请上传社保文件', trigger: 'click'}
          ],
        },
        cusList: []
      }
    },
    computed: {
      headers() {
        return {
          Authorization: `Bearer ${this.accessToken}`,
        };
      },
      ...mapGetters(['accessToken'])
    },
    methods: {
      // 初始化方法，在使用这个页面的时候调用
      init() {
        this.token='Authorization:'+ `Bearer ${this.accessToken}`;
        this._getCusByUser();
      },
      // 保存批次
        _save() {
       
        this.$refs["form"].validate( async (valid) => {
          if (valid) {
            this.form.filePath = this.$store.state.impData.filePath;
            //1. 校验参数
            if(this.form.filePath){
              let res  = await importSocialSecurity(this.form);

              if(res.data.code===0){
                this.$message.success('上传成功！');
                this._close();
                this.$emit('loadList');
              }else{
                this.$message.error({ iconClass:"iconMessage",customClass:"errMessage",dangerouslyUseHTMLString: true,message:res.data.msg,showClose:true,duration:0});
                //this.$message.error({customClass:"errMessage",dangerouslyUseHTMLString: true,message:res.data.msg,duration:0});
              }
            }else{
              this.$message.error('请上传社保文件！');
              return false;
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
        // console.log(this.$store.state.impData.filePath);
      },
      _close() {
        this.$refs.upload.clearFiles();
        this.$emit('hiddenImp');
        this.$refs["form"].resetFields();
      },
      async _getCusByUser() {
        let res = await getCusByUser();
        this.cusList = res.data.data;
      },
      exportTemplate() {
          downloadSocialSecurity().then(res => {
            downloadFile(res);
          })
      },
      beforeFileUpload(file) {
        this.$set(this, "loading", true);
        let isExcel = true;
        const isxls = file.type === "application/vnd.ms-excel";
        const isxlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const isLtM = file.size / 1024 / 1024 < 10;

        if (!isxls && !isxlsx) {
          isExcel = false;
          this.$message.error("导入文件只能是 xls,xlsx 格式!");
          this.$set(this, "loading", false);
        }
        if (!isLtM) {
          this.$message.error("导入文件大小不能超过 10MB!");
          this.$set(this, "loading", false);
        }
        return isExcel && isLtM;
      },
      fileUploadSuccess(res, file, fileList) {
        this.$set(this, "loading", false);
        if (res) {
          if (res.code !== 0) {
            this.$message.error(res.msg);
          } else {
            // 赋值数据  用于下一个页面使用
            this.$store.state.impData.filePath = res.data;//上传文件的路径
            this.$store.state.impData.bizType = this.$route.query.bizType;//业务类型
            this.next = true;
          }
        }
      },
      fileUploadError(err, file, fileList) {
        this.$set(this, "loading", false);
        this.$message.error("导入失败!");
      },
    }
  }

</script>
<style scoped rel="stylesheet/scss">
  .stepContent {
    padding: 20px 20px;
  }

  .download {
    display: flex;
    justify-content: flex-end;
  }

  .download p {
    margin-left: 20px;
    line-height: 50px;
    color: #269cff;
    cursor: pointer;
  }

  .download p svg {
    margin-right: 10px;
  }

  .download p:hover {
    text-decoration: underline;
  }

  .uploadBox {
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .uploadContent {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .uploadContent .iconfont {
    font-size: 80px;
    color: #cccccc;
  }

  .uploadContent {
    height: 140px;
  }

  .uploadBox >>> .el-upload {
    width: 100%;
    height: 100%;
  }

  .uploadBox >>> .el-upload-dragger {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .uploadBox >>> .el-upload-dragger .el-icon-upload {
    margin-top: 0;
  }

  .uploadText {
    color: #aaaaaa;
  }


  .uploadTip-lists {
    padding-left: 20px;
  }


  .uploadTip-list .uploadTip-button {
    color: #269cff;
    text-decoration: underline;
    cursor: pointer;
  }

  .next-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

</style>
