
<template>
  
  <div>         

    <bsp-table
      :data.sync="list"
      :showPagination="false"
      @select-change="selectChange"
      @select-all="selectAll">

        <vxe-table-column type="selection" width="60" fixed="left" v-if="this.detailVisible='true'"/>
        <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
         <bsp-table-column  title="姓名"  field="username"   filter-type="input" fixed="left"  >
            <template slot-scope="scope">  <span>{{scope.row.username}}</span>  </template>
        </bsp-table-column>
          <bsp-table-column  title="身份证号"  field="cardid"   filter-type="input" fixed="left"  >
            <template slot-scope="scope">  <span>{{scope.row.cardid}}</span>  </template>
        </bsp-table-column>

          <bsp-table-column  title="起止时间"  field="selecttime"   filter-type="input" fixed="left"  >
            <template slot-scope="scope">  <span>{{scope.row.selecttime}}</span>  </template>
        </bsp-table-column>

        <bsp-table-column  title="计算月份数"  field="monthsnum"   filter-type="input" fixed="left"  >
            <template slot-scope="scope">  <span>{{scope.row.monthsnum}}</span>  </template>
        </bsp-table-column>

        <bsp-table-column  title="税前工资总和"  field="totalpretaxwages"   filter-type="input" fixed="left"  >
            <template slot-scope="scope">  <span>{{scope.row.totalpretaxwages}}</span>  </template>
        </bsp-table-column>

        <bsp-table-column  title="税前平均工资金额"  field="averagesalarybeforetax"   filter-type="input" fixed="left"  >
            <template slot-scope="scope">  <span>{{scope.row.averagesalarybeforetax}}</span>  </template>
        </bsp-table-column>

        <bsp-table-column  title="税后工资总和"  field="totalaftertaxwages"   filter-type="input" fixed="left"  >
            <template slot-scope="scope">  <span>{{scope.row.totalaftertaxwages}}</span>  </template>
        </bsp-table-column>

        <bsp-table-column  title="税后平均工资金额"  field="averagewageaftertax"   filter-type="input" fixed="left"  >
            <template slot-scope="scope">  <span>{{scope.row.averagewageaftertax}}</span>  </template>
        </bsp-table-column>
      </bsp-table>

  </div>

</template>

<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {downloadFile,downloadPdfFile} from '@/util/util';
  import {exportAverageWageDetails} from '@/api/entrust/detailImport';

  export default {
    name:"detailImportAverageWageDetil",
    components: {
      BspTable,
      BspTableColumn,
    
    },

    data() {
      return {
        list: [],
        detailList:[],
        multipleSelection: [],
        multipleSelections: [],
        checkedTaxtypes:[],
        params:{
          detailList:[],
          multipleSelection:[],
          checkedTaxtypes:[],
        }
      }
    },
    props: {
      detailList: {
        type: Array,
        default:function(){
          return ""
        }
      },
      multipleSelections: {
        type: Array,
        default:function(){
          return ""
        }
      },
      checkedTaxtypes: {
        type: Array,
        default:function(){
          return ""
        }
      },
      
   },
   mounted() {
        this.__getAverageWageList();
    },

    methods: {
      __getAverageWageList(){
        
        this.list = this.detailList
      },
       //复选框全选事件
      selectAll({checked, selection}) {
      
        if(checked){
          this.multipleSelection= selection
        }else{  
          this.multipleSelection=[];
        }
        console.log("this.multipleSelection:"+this.multipleSelection);
      },

      selectChange(value) {
        if (value.selection) {
          this.multipleSelection = [];
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index];
            this.multipleSelection.push(element);
          }
        }
      },

     

        //单条导出
      exportDetails(){
        if(this.multipleSelection.length<=0){
           this.$message.error("请选择导出项！");
              return;
        }

        this.params.detailList = this.multipleSelection;
        let card =[];
        this.params.detailList.map(mitem=>{
            let mselect =[];
            mselect= this.multipleSelections.filter(item=>{
              return item.cardid == mitem.cardid
            });
            card.push(...mselect)
        });

        this.params.multipleSelection = card;
        this.params.checkedTaxtypes = this.checkedTaxtypes;
        this.loading=true;
        
        exportAverageWageDetails(this.params).then(res => {
          
          this.loading=false,
          this.isDisabled = false;
          
          downloadFile(res);
        })
      },
      
    },
  
  }
</script>
