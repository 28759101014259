<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right" @click="batchResign">批量离职</el-button>
      <el-button type="primary" size="small" fixed="right" @click="exportExcel">明细导出</el-button>
    </div>
    <bsp-table
      :data.sync="list"
      @select-change="selectChange"
      @select-all="selectChange"
      :tree-config="true"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="loadList"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
      :checkbox-config="{checkMethod: checkBoxMethod}"
    >
      <vxe-table-column type="selection" width="60" fixed="left"/>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"/>


      <bsp-table-column title="雇员编号" field="empCode" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.empCode}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="雇员姓名" field="empName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.empName}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="雇员身份" field="identity" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.identity}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="证件类型" field="cardType" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.cardType}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="证件号码" field="cardNum" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.cardNum}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="雇员性别" field="sex" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.sex}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="是否残疾人" field="isDeformity" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.isDeformity}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="电子邮件" field="email" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.email}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="手机号" field="iphone" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.iphone}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="雇员状态" field="empStatus" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.empStatus}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="离职状态" field="resignStatus" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.resignStatus}}</span></template>
      </bsp-table-column>

    </bsp-table>
  </div>
</template>

<script>
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {
    searchUnpaidEmpList,
    batchResign,
    exportExcel
  } from '@/api/cus/list';
  import {downloadFile, downloadPdfFile} from '@/util/util';
  import {mapGetters} from 'vuex';

    export default {
      name: "empList",
      inject: ['reload'],
      components: {
        BspTable,
        BspTableColumn,
      },
      computed: {
        ...mapGetters([
          'dicts'
        ]),
      },
      props: {
        currentTab: {
          type: String,
          default: ''
        },
      },

      created() {
        this.loadList();
      },

      data() {
        return {
          // 用于接收后台返回的分页数据
          list: [],
          //分页查询对象
          listQuery: {
            cusId: this.$route.query.cusId,
            empIds: [],
            limit: 50,
            page: 1,
            total: 0,
            paymentNo: '',
            paymentStatus: '',
            accountingMonth: '',
            paymentType: '0'
          },
          // 选择的雇员id
        }
      },

      methods: {

        checkBoxMethod({row}) {
          return row.resignStatus === "在职"
        },
        // 点选/全选复选框
        selectChange(checked) {
          if (checked.checked) {
            const list = checked.selection;
            this.listQuery.empIds = [];
            for (let index = 0; index < list.length; index++) {
              const element = list[index].empId;
              this.listQuery.empIds.push(element);
            }
          } else {
            this.listQuery.empIds = [];
          }
        },
        loadList() {
          searchUnpaidEmpList(this.listQuery).then(res => {
            this.list = res.data.data;
            console.log(this.list)
            this.listQuery.total = res.data.total;
          })
        },

        batchResign() {
          // 至少选择一条数据
          console.log(this.listQuery.empIds)
          if (this.listQuery.empIds === null || this.listQuery.empIds.length === 0) {
            alert('至少选择一条数据');
            return;
          }
          batchResign(this.listQuery).then(res => {
            this.listQuery.empIds = [];
            this.loadList();
          });
        },

        exportExcel() {
          exportExcel(this.listQuery).then(res => {
            if (res.data.size === 0) {
              alert("无导出数据");
              return;
            }
            console.log("后端返回数据")
            console.log(res.data)
            const url = window.URL.createObjectURL(res.data);
            const link = document.createElement('a');
            link.href = url;
            const fileName = `${res.headers['content-disposition'].replace('attachment;filename=','')}`;
            let channelUrl=decodeURIComponent(fileName);
            link.setAttribute('download', channelUrl);
            link.click()
          })
        }
      }
    }
</script>

<style scoped>

</style>
