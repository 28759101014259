<template>
  <div>
    
      <div>
          <h2Title title="审批流程">
              <div>
                 <span>返回</span>
              </div>
          </h2Title>
      </div>

    </div>
</template>    
<script>
  import h2Title from "@/views/demo/salarySet/template/h2Title.vue";
  export default {
    name: "step4",
    data(){
      return{
       
      }
    },
    components: {h2Title},
  };
</script> 