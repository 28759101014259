<template>
  <div>
    <el-form v-loading="loading" ref="form2" class="editFrom" :model="listForm" label-width="100px" :rules="rules">
      <el-row>
        <el-col :span="8">
          <el-form-item label="工资月份：" prop="wageMonth">
            <el-date-picker 
              v-model="listForm.wageMonth" 
              type="month"  
              format="yyyy-MM" 
              value-format="yyyy-MM"
              placeholder="选择月"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="税金月份：" prop="taxMonth">
            <el-date-picker
              v-model="listForm.taxMonth"
              type="month"
              format="yyyy-MM"
              value-format="yyyy-MM"
              placeholder="选择月" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="发票月份：" prop="invoiceMonth">
            <el-date-picker
              v-model="listForm.invoiceMonth"
              type="month"
              format="yyyy-MM"
              value-format="yyyy-MM"
              placeholder="选择月" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-alert title="需要注意的事项" type="error">
      <template slot='title'>
        <div class="iconSize">温馨提示:</div>
        <div class="iconSize">1、本次操作不{{listForm.operType}}专项附加扣除数据；</div>
        <div class="iconSize">2、非工资\补贴、劳务费、其他连续劳务报酬计税类型不允许{{listForm.operType}}；</div>
      </template>
    </el-alert>
  </div>
</template>

<script>
  import {getIsDeadlineForTax ,copySalaryBaseDetail,getIsMayCopyTaxType,queryWageMonth ,copySalaryStepOne } from '@/api/entrust/batchCopy';
  import {downloadSalaryTmpFile} from '@/api/entrust/detailImport';
  import {downloadFile} from '@/util/util';
  import {mapGetters} from 'vuex';

  export default {
    name: "CopyBatchMonthPage",
    inject: ['reload'],
    components: {
    },
    props:{
      listForm:{
        type: Object,
        default () {
          return {
            wageMonth:'',
            taxMonth:'',
            billMonth:'',
            bizType:'',
            invoiceMonth:'',
            batchIdList: [],
            operType: '复制',
          }
        }
      },
    },
    computed: {
      ...mapGetters([
        'dicts', "userInfo"
      ]),
    },
    created() {
      console.log(this.listForm);
      // this._queryWageMonth({id : this.listForm.id});
    },

    data() {
      return {
        operType: '',
          // 遮罩
        loading: false,
        rules: {
          wageMonth: [
            {required: true, message: '请选择工资月份', trigger: 'change'}
          ],
          taxMonth: [
            {required: true, message: '请选择税金月份', trigger: 'change'}
          ],
          invoiceMonth: [
            {required: true, message: '请选择发票月份', trigger: 'change'}
          ],
        },
      }
    },

    methods: {
      //复制-确定
      async copyOk(){
        this.loading = true;
        this.$refs["form2"].validate( async (valid) => {
          if(valid){
            await this.copy();
            debugger
            this.$emit("cancelLoadBtn");
            this.loading = false;
          }
        });
      },
      async copy(){
        debugger
        // 校验所选日期是否大于税金截止日期
        let obj = { taxMonth : this.listForm.taxMonth , bizType : this.listForm.bizType, batchImportId: this.listForm.id};
        const IsDeadline =  await getIsDeadlineForTax(obj);
        let copyFlag = true;
        let msg = "";
        console.log(IsDeadline.data.success && IsDeadline.data.data != "" );
        if( IsDeadline.data.data != "" ){
          // this.$message.error("所选税金月份的报税截止期已超过!!!");
          if(!IsDeadline.data.success){
            this.loading = false;
            this.$emit("cancelLoadBtn", '');
            return;
          }
          msg = "所选税金月份已超过报税截止期；"
          copyFlag = false;
        }
        // const IsMayCopy =  await getIsMayCopyTaxType(obj);
        // if(IsMayCopy.data.success && IsMayCopy.data.data == "00900" ){
        //   msg += msg + "批次明细中不存在可复制的明细；"  
        //   // this.$message.error("批次明细中不存在可复制的明细!!!");
        //   copyFlag = false;
        // }
        if(msg != ""){
          this.$message.error(msg);
          this.loading = false;
          this.$emit('cancelLoadBtn', '');
          console.log('子取消加载');
        }
        // 获取参数调用接口复制批次
        if (copyFlag) {
          // this.loading = true;
          const copyRes = await copySalaryBaseDetail(this.listForm);
          console.log("拷贝结果",copyRes);
          debugger
          if(copyRes.data.code == "0"){
            this.$confirm('薪资复制成功是否前往校验；', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
              // 复制完成完成后调用校验接口
              //this.loading = false;
              this.loading = false;
              this.$emit('cancelLoadBtn', '');
              console.log(" 校验第一步",copyRes.data.data)
              copySalaryStepOne(copyRes.data.data ).then((result) => {
                const query = {   //getDynamicForm参数
                    bizType:copyRes.data.data.bizType,//业务类型
                    id:copyRes.data.data.id, // id
                    cusid:copyRes.data.data.cusid
                }
                this.$router.push({ path: '/wt/copyParent',query});
              }).catch((err) => {
                this.$message.error(err);
                this.loading = false;
                this.$emit('cancelLoadBtn', '');
                console.log("校验失败，错误信息",err);
              });
              this.loading = false;
              this.$emit('cancelLoadBtn', '');
            }).catch((err) => {
              // 关闭窗口
              console.log("取消？？");
              this.loading = false;
              // this.$emit('cancelLoadBtn', '');
              this.$emit('hiddenCopyMonth');
            });
          } else {
            this.loading = false;
            this.$emit("cancelLoadBtn");
            this.$message.error("批次复制失败！");
          }
        }
      },
      downloadOk(){
        this.$refs["form2"].validate( async (valid) => {
          if(valid){
            let obj = {isChoiceMonth: '00901', wageMonth : this.listForm.wageMonth , taxMonth : this.listForm.taxMonth, invoiceMonth : this.listForm.invoiceMonth,
              bizType : this.listForm.bizType, batchIdList: this.listForm.batchIdList,copyFlag:'00900'};
            console.log(obj);
            downloadSalaryTmpFile(obj).then(res => {
              downloadFile(res);
            });
            // 调用关闭窗口方法
            this.$emit('hiddenDown')
          }
        });
      },
    }
  }
</script>

<style scoped>

</style>
