<template>
  <div>
      <el-button style="float: right" type="primary" @click="_save">已阅不再提示</el-button>

      <div >
        <div class="noticeTitle" v-html="notice.title"></div>
        <div style="white-space: pre-line ;word-break:break-all"  class="smallContent" v-html="notice.message"></div>
        <ul v-if="this.fileList.length>0">
          <span  class="smallContent" >附件信息:</span>
          <li class="noticeItem" v-for="(item,index) in this.fileList" :key="item.ID">
            <div class="ciic-table-operate">
              <el-button @click="downloadfile(item)" type="text" size="mini">{{ item.fileName }}</el-button>
            </div>
          </li>
        </ul>
      </div>





  </div>

</template>

<script>

import BspTable from "@/components/bsp/bsp-table/bsp-table";
import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
import {getDownloadFile, selectNoticeDetail,unVisibleNotice} from "@/api/sys/notice";
import ScreenUpload from '@/views/sysMaintenance/ScreenUpload.vue';
import {downloadPdfFile} from '@/util/util';
import {mapGetters} from 'vuex';
import {downLoadFinTaxAgreement} from "@/api/agreement/agreement";

export default {
  name: "cusList",
  inject: ['reload'],
  components: {
    BspTable,
    BspTableColumn,
    ScreenUpload
  },
  computed: {
    ...mapGetters([
      'dicts'
    ],['accessToken']),
    headers() {
      return {
        Authorization: `Bearer ${this.accessToken}`
      }
    },
  },
  props: {
    currentTab: {
      type: String,
      default: ''
    },
  },

  created() {
    this.selectNoticeDetail();
  },

  data() {
    return {
      // 用于接收后台返回的分页数据
      list: [],
      noticeForm:{

      },

      fileForm:{
        filePath:[]
      },
      //分页查询对象
      listQuery: {
        limit: 50,
        page: 1,
        total: 0,

      },
      notice:{
        title:'',
        message:'',
        roleNames:'',
        roleIds:'',
      },
      batchDialogTitle:  '公告信息',
      fileDialogTitle:  '文件上传',
      multipleSelection: [],
      batchDialogVisible: false,
      checkFileVisible: false,
      fileDialogVisible: false,
      detail: false,
      operatorList:[],
      operator: "",
      updateoperatorList:[],
      updateoperator:{
        cusId:'',
        beforeOperator:'',
        afterOperator:''
      },
      message:'',
      title:'',
      roleList:[],
      role: [],
      id:'',
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        maxHeight: 300,
        align: "center",
        data: [],
      },
      columns: [
        {type: "seq", width: 50, title: "序号"},
        {field: "fileName", title: "文件"},
        {field: "creteTime", title: "上传时间"},
        {title: "操作", showOverflow: true, slots: {default: "operate"}},
      ],
      fileList:[
      ]
    }
  },

  methods: {
    _save(){
      var id =  this.$route.query.id
      unVisibleNotice(id).then(res=>{
        if(res.data.code==0){
          this.$router.push({path: '/index'});
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    async downloadfile({id}) {
      // let res = await getDownloadFile(id);
      // downloadPdfFile(res);
      getDownloadFile(id).then(res=>{
        debugger
        downloadPdfFile(res);
      })
    },
    async selectNoticeDetail() {

      // let res = await getDownloadFile(id);
      // downloadPdfFile(res);
     var id =  this.$route.query.id
      selectNoticeDetail(id).then(res=>{
        debugger
        this.notice.title = res.data.data.title;
        this.notice.message = res.data.data.message;
        this.fileList  = res.data.data.noticeFileBos;
      })
    },


  }
}
</script>

<style lang="scss" scoped>

.formHeight{
  line-height: 15px;
  height: 500px;
}
.forminputHeight{

  height: 150px;
}
.noticeTitle {
  padding: 0px 10px;
  margin: 5px 0px;
  font-weight: 700;
  font-size: 15px;
  text-align: center;
}


.smallContent {
  font-size: 14px;
  img {
    margin: 20px;
    width: 40vw;
    align: center;
    font-size: 0;
  }
}

</style>
