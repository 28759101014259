<template>
  <div class="stepContent">

    <!--<el-form :model="formListMult" v-if="typeCode.importType== 'single'" ref="batchInfoFrom" :rules="checkRules"
             label-width="120px">
      <div class="editForm">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item label="社保月份" prop="months">
              <el-date-picker
                v-model="formListMult.months"
                type="month"
                format="yyyy-MM"
                value-format="yyyy-MM"
                placeholder="选择月">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="9" :offset="11">
            <div class="download" @click="exportTemplate">
              <p>
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-xiazai"></use>
                </svg>
                下载模板
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-form>-->
  <!--  <el-form v-else :model="formListSingle" ref="batchInfoFrom" :rules="checkRules" label-width="120px">
      <div class="editForm">
        <el-row :gutter="20">
          <el-col :span="9">
            <el-form-item label="所属客户" prop="cusId">
              <el-select v-model="formListSingle.cusId" filterable clearable placeholder="请选择客户">
                <el-option
                  v-for="item in cusList"
                  :key="item.id"
                  :label="item.cusName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="账单月份" prop="cusId">
              <el-date-picker
                v-model="formListSingle.months"
                type="month"
                format="yyyy-MM"
                value-format="yyyy-MM"
                placeholder="选择月">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="9" :offset="11">
            <div class="download" @click="exportTemplate">
              <p>
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-xiazai"></use>
                </svg>
                下载精简模板
              </p>
            </div>
          </el-col>
         &lt;!&ndash; <el-col :span="18">
            <el-form-item label="批次备注" prop="cusId">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="formListMult.remark"
                maxlength="10"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-col>&ndash;&gt;
        </el-row>
      </div>
    </el-form>-->

    <el-form  ref="batchInfoFrom" label-width="120px">
      <div class="editForm">
        <el-row :gutter="20">
          <el-col :span="9" v-if="typeCode.importType== 'single'">
            <el-form-item label="所属客户" prop="cusId">
              <el-select v-model="listForm.cusId" filterable clearable placeholder="请选择客户">
                <el-option
                  v-for="item in cusList"
                  :key="item.id"
                  :label="item.cusName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="账单月份" prop="billMonth">
              <el-date-picker
                v-model="listForm.billMonth"
                type="month"
                format="yyyy-MM"
                value-format="yyyy-MM"
                placeholder="选择月">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4" v-if="typeCode.importType === 'single01' && typeCode.bizType === '31278300703' && (typeCode.orgId === '1060352'
          || typeCode.orgId === '40288ab272dba7a20172f8f49c220957' || typeCode.orgId === '1060348')">
            <el-form-item label="是否分包">
              <el-radio v-model="listForm.isSubcontract" label="00901">是</el-radio>
              <el-radio v-model="listForm.isSubcontract" label="00900">否</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="2" v-if="typeCode.importType === 'single' && typeCode.bizType === '31278300703' && typeCode.orgId === '1060352'">
            <div class="download">
              <p  @click="exportTemplate">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-xiazai"></use>
                </svg>
                下载模板
              </p>
            </div>
          </el-col>
          <el-col :span="6" v-if="typeCode.importType === 'single' && (typeCode.bizType !== '31278300703' || typeCode.orgId !== '1060352')">
            <div class="download">
              <p  @click="exportTemplate">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-xiazai"></use>
                </svg>
                下载模板
              </p>
            </div>
          </el-col>
          <el-col :span="15" v-if="typeCode.importType!= 'single'">
            <div class="download">
              <p  @click="exportTemplate">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-xiazai"></use>
                </svg>
                下载模板
              </p>
            </div>
          </el-col>
          <!-- <el-col :span="18">
             <el-form-item label="批次备注" prop="cusId">
               <el-input
                 type="text"
                 placeholder="请输入内容"
                 v-model="formListMult.remark"
                 maxlength="10"
                 show-word-limit
               >
               </el-input>
             </el-form-item>
           </el-col>-->
        </el-row>
      </div>
    </el-form>
  <div class="uploadBox">
    <el-upload
      v-loading="loading"
      :show-file-list="true"
      :on-success="fileUploadSuccess"
      :on-error="fileUploadError"
      :before-upload="beforeFileUpload"
      :headers="headers"
      :action="uploadUrl"
      class="uploadContent"
      :limit="1"
      drag
      accept=".xls,.xlsx">
      <i class="el-icon-upload"/>
      <div class="el-upload__text">点击或将文件拖到此处上传</div>
    </el-upload>
  </div>
  <div class="uploadTip">
    <div class="uploadTip-title">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-tishi"></use>
      </svg>
      温馨提示
    </div>
    <div class="uploadTip-lists">
      <div class="uploadTip-list">
        1、支持文件后缀 xls、xlsx文件，且不超过10M
      </div>
      <div class="uploadTip-list">
        2、请将要上传的内容放在第一个sheet中
      </div>
      <div class="uploadTip-list">
        3、第一个sheet中首行必须为表头，且不支持复合表头
      </div>
      <div class="uploadTip-list">
        4、请不要加密模板文件，将导致错误
      </div>
    </div>
  </div>
  <div v-if="next" class="next-btn">
    <el-button type="primary" @click="goback" size="mini">返回</el-button>
    <el-button type="primary" @click="nextStep" size="mini" :loading="loading">下一步</el-button>
  </div>
  </div>
</template>
<script>
  /* eslint-disable handle-callback-err */
  import treeTransfer from "el-tree-transfer"; // 引入
  import {getCusByUser} from '@/api/sys/cus'
  import {downloadCusTemp, downloadExcelTmpl, importStepOne, importStepTwo} from "@/api/entrust/detailImport";
  import {downloadFile} from "@/util/util";
  import {mapGetters} from 'vuex';

  export default {
    components: { treeTransfer }, // 注册
    data() {
      return {
        token:' Authorization: `Bearer ${this.accessToken}`',
        dialogVisible: false,
        uploadUrl: "/api/wt/detailImport/upload-file",//导入地址
        loading: false,
        next: true,
        mode: "transfer", // transfer addressList
        typeCode: {
          cusId:"",
          bizType: this.$route.query.bizType,
          filePath: "",
          importType: "",
          billMonth: "",
          orgId: '',
          isSubcontract: '',
          userType: '',
        },
        paramStr:{
          filePath:'',
          bizType:'',
          excelData:[],
          wageItemList:[],
          billMonth:'',
          cusName:'',
          importType:'',
        },
       /* formListSingle: {
          months: "",
          cusName: "",
          remark: ""
        },
        formListMult: {
          months: ""
        },*/
        cusList: [],
        listForm: {
          cusId: "",
          billMonth: "",
          cusName:'',
          isSubcontract: '00900',
          orgId: '',
        },
       /* checkRules: {  //表单校验规则
          billMonth: [
            { required: true, message: "账单月份不能为空", trigger: "blur" }
          ]
        }*/
      };
    },
    computed: {
      headers() {
        return {
          Authorization: `Bearer ${this.accessToken}`,
        };
      },
      ...mapGetters(['accessToken', 'userInfo']),
    },
    created() {
      this.token='Authorization:'+ `Bearer ${this.accessToken}`;
      this.$store.state.impData.filePath="";
      console.log("step1业务类型：" + this.typeCode.bizType + "====" + this.$store.state.impData.bizType);
      console.log("step1导入类型：" + this.$store.state.impData.importType);
      console.log("step1导入机构：" + this.userInfo.orgId);
      this.typeCode.importType = this.$store.state.impData.importType;
      this.listForm.billMonth=this.$store.state.impData.billMonth;
      this.listForm.cusId=this.$store.state.impData.cusId;
      this.typeCode.orgId=this.userInfo.orgId;
      this.typeCode.userType = this.userInfo.userType;
      //单客户点击进入则初始客户列表
      if (this.typeCode.importType == "single") {
        this._getCusByUser();//客户列表
      }
    },
    methods: {
      async _getCusByUser() {
        let res = await getCusByUser();
        this.cusList = res.data.data;
      },
      //模板下载
      exportTemplate() {
        console.log("this.typeCode.bizType:" + this.typeCode.bizType);
        console.log("this.typeCode.importType:" + this.typeCode.importType );
        if (this.typeCode.importType == "single") {
          if(this.listForm.cusId==""){
            this.$message.error("请先选择客户再下载模板!");
            return;
          }
          this.typeCode.cusId= this.listForm.cusId;
          downloadCusTemp(this.typeCode).then(res => {
            downloadFile(res);
          });
        } else {
          downloadExcelTmpl(this.typeCode).then(res => {
            downloadFile(res);
          });
        }
      },

      beforeFileUpload(file) {
        this.$set(this, "loading", true);
        let isExcel = true;
        const isxls = file.type === "application/vnd.ms-excel";
        const isxlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const isLtM = file.size / 1024 / 1024 < 10;

        if (!isxls && !isxlsx) {
          isExcel = false;
          this.$message.error("导入文件只能是 xls,xlsx 格式!");
          this.$set(this, "loading", false);
        }
        if (!isLtM) {
          this.$message.error("导入文件大小不能超过 10MB!");
          this.$set(this, "loading", false);
        }
        return isExcel && isLtM;
      },
      fileUploadSuccess(res, file, fileList) {
        this.$set(this, "loading", false);
        if (res) {
          if (res.code !== 0) {
            this.$message.error(res.msg);
          } else {
            // 赋值数据  用于下一个页面使用
            this.$store.state.impData.filePath = res.data;//上传文件的路径
            this.$store.state.impData.bizType = this.$route.query.bizType;//业务类型
            this.next = true;
          }
        }
      },
      fileUploadError(err, file, fileList) {
        this.$set(this, "loading", false);
        this.$message.error("导入失败!");
      },
      //点击下一步
      nextStep() {
        this.loading=true;
        this.$store.state.impData.importType="";
        //单客户需要选择客户
        if(this.typeCode.importType == "single"){
          if(this.listForm.cusId==""){
            this.loading=false;
            this.$message.error("请先选择客户!");
            return;
          }
          if(this.cusList.length>0){
            for (let index = 0; index <this.cusList.length; index++) {
              if(this.cusList[index].id==this.listForm.cusId){
                this.$store.state.impData.cusName=this.cusList[index].cusName;
                break;
              }
            }
          }
         // this.$store.state.impData.cusName=this.listForm.cusName;
          this.$store.state.impData.importType=this.typeCode.importType ;
          this.$store.state.impData.cusId=this.listForm.cusId;
          this.typeCode.cusId= this.listForm.cusId;
          console.log("单客户：名称="+this.$store.state.impData.cusName+"标识："+this.$store.state.impData.importType);
        }
        if(this.listForm.billMonth==""){
          this.loading=false;
          this.$message.error("请先选择账单月份!");
          return;
        }
        if( this.$store.state.impData.filePath==""){
          this.$message.error("请先上传文件!");
          this.loading=false;
          return;
        }
        this.typeCode.billMonth = this.listForm.billMonth;//账单月份
        this.typeCode.isSubcontract = this.listForm.isSubcontract;//是否分包
        this.$store.state.impData.billMonth = this.typeCode.billMonth;
        this.$store.state.impData.isSubcontract = this.typeCode.isSubcontract;
        console.log("filePath:" + this.$store.state.impData.filePath + ";bizType:" + this.$store.state.impData.bizType);
        console.log("billMonth:" + this.typeCode.billMonth);
        //此方法放在step2中获取匹配头信息存在问题，不能正常匹配，故放在这里获取
        this.typeCode.bizType = this.$store.state.impData.bizType;
        this.typeCode.filePath = this.$store.state.impData.filePath;
        this.$store.state.impData.importType=this.$store.state.impData.importType;//批量还是单客户
        importStepOne(this.typeCode).then(res => {
         if(res.data.data.msg=="err"){
            this.$message.error("导入模板表头存在重复,请核实!");
            this.loading=false;
            return;
          }else if(res.data.data.msg=="error"){
           this.$message.error("批量导入不允许导入免税项目和企业年金相关数据,请在单客户下导入!");
           this.loading=false;
           return;
         }else{//res.data.data.success && res.data.data.msg!="err"
            this.$store.state.impData.excelList = res.data.data.excelList;
            this.$store.state.impData.sysList = res.data.data.sysList;
            this.$store.state.impData.wageItemList = res.data.data.wageItemList;//系统模板对应的信息
            this.$store.state.impData.matchFlag = res.data.data.matchFlag;//matchFlag 为false则标识列全部匹配
            this.$store.state.impData.recommendMap = res.data.data.recommendMap;
           /* if(res.data.data.matchFlag=="true"){
              console.log("全部匹配");
              //传excel表头，sys表头
              this.paramStr.bizType = this.$store.state.impData.bizType;
              this.paramStr.filePath = this.$store.state.impData.filePath;
              this.paramStr.excelData =this.$store.state.impData.excelList;
              this.paramStr.wageItemList = this.$store.state.impData.wageItemList;
              this.paramStr.billMonth = this.$store.state.impData.billMonth;
              this.paramStr.cusName=this.$store.state.impData.cusName;
              console.log("客户名称："+this.paramStr.cusName);
              this.paramStr.importType=this.$store.state.impData.importType;
              importStepTwo(this.paramStr).then(res => {
                if (res.data.data.success=="true") {
                  this.$store.state.impData.importTime = res.data.data.importTime;
                  this.$store.state.impData.batchImportId = res.data.data.batchImportId;
                  this.$store.state.impData.novalidatePass = res.data.data.novalidatePass;
                  this.$store.state.impData.tipInfo = res.data.data.tipInfo;
                  this.$store.state.impData.warnInfo = res.data.data.warnInfo;
                  this.$store.state.impData.validatePass = res.data.data.validatePass;
                  this.$store.state.impData.errorInfo = res.data.data.errorInfo;
                  this.$store.state.impData.resultInfo = res.data.data.resultInfo;
                  this.loading=false;
                  this.$emit("onuploadTwo"); // 跳转下一步
                }else{
                  this.loading=false;
                  this.$message.error(res.data.data.msg);
                }
              })
            }else{
              this.loading=false;
              this.$emit("onupload"); // 跳转下一步
            }*/
            this.loading=false;
            this.$emit("onupload"); // 跳转下一步
          }
        });
      },
      goback() {
        this.$emit("goback"); // 返回
      },
// 监听穿梭框组件添加
      add(fromData, toData, obj) {
// 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的        {keys,nodes,halfKeys,halfNodes}对象
// 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
        console.log("fromData:", fromData);
        console.log("toData:", toData);
        console.log("obj:", obj);
      },
// 监听穿梭框组件移除
      remove(fromData, toData, obj) {
// 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
// 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
        console.log("fromData:", fromData);
        console.log("toData:", toData);
        console.log("obj:", obj);
      }
    }
  };
</script>
<style scoped rel="stylesheet/scss">
  .stepContent {
  }

  .download {
    display: flex;
    justify-content: flex-end;
  }

  .download p {
    margin-left: 20px;
    line-height: 30px;
    color: #269cff;
    cursor: pointer;
  }

  .download p svg {
    margin-right: 10px;
  }

  .download p:hover {
    text-decoration: underline;
  }

  .uploadBox {
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .uploadContent {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .uploadContent .iconfont {
    font-size: 80px;
    color: #cccccc;
  }

  .uploadContent {
    height: 140px;
  }

  .uploadBox >>> .el-upload {
    width: 100%;
    height: 100%;
  }

  .uploadBox >>> .el-upload-dragger {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .uploadBox >>> .el-upload-dragger .el-icon-upload {
    margin-top: 0;
  }

  .uploadText {
    color: #aaaaaa;
  }

  .uploadTip-lists {
    padding-left: 20px;
  }

  .uploadTip-list .uploadTip-button {
    color: #269cff;
    text-decoration: underline;
    cursor: pointer;
  }

  .next-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
</style>
