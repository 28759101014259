<!-- 下拉选择器 -->
<template>
  <el-select
    v-model="selectedData"
    v-bind="{...$attrs}"
    v-on="{...$listeners}"
    ref="select"
    :disabled="disabled"
    @change="selectChange"
    placeholder="请选择">
    <el-option
      ref="commonOption"
      v-for="item in options"
      :key="item.codeItemId"
      :label="item.codeItemName"
      :value="item.codeItemId"
      :disabled="item.disabled">
    </el-option>
  </el-select>
</template>

<script>
  import { mapGetters } from "vuex";
  import { deepCopy } from "../../../util/util";

  export default {
    name: "bsp-dicts-select",
    props: {
      //当前下拉选的值
      selectValue: {
        type: String,
        default: ""
      },
       disabled: {
        type: Boolean,
        default: false
      },
      // 代码集setId
      dicNum: {
        type: String,
        default: ""
      },
      // 要移除的 itemId,隔开多个
      remNum: {
        type: String,
        default: ""
      },
      // 只显示的代码值
      showNum:{
        type:String,
        default:"",
      },
      // 输入框占位符
      placeholder: {
        type: String,
        required: false,
        default: "请选择"
      },
      // 下拉选数据
      optionsList: {
        type: Array,
        default: () => (
          []
        )
      },
      changeE:{
        type: String,
        default: ""
      }
    },
    data() {
      return {
        // 下拉选数据
        options: [],
        selectedData: "",
        disable:false
      };
    },
    computed: {
      ...mapGetters([
        "dicts"
      ])
    },
    watch: {
      selectValue(va){
        //console.log("jianting :"+va)
        //this.selectedData=this.selectValue();
        this.dataFormat(va);
      },
      //监听传过来的数据list
      optionsList(vaList){
        this.initData();
      }
    },
    created() {
      this.initData();
      //console.info(this.$attrs, this.$listeners,"dkdkdkdkddkdkdddkdkdkdkdkdk");
    },
    mounted() {
      console.log("options",this.options)
    },
    methods: {
      initData() {
        //console.log('wfhg:')
        //  从后台获取的dicts
        if (this.optionsList.length > 0) {
          //对list进行处理
          this.handleDictc(this.optionsList, this.remNum,this.showNum);
        } else if (this.dicNum) {
          // let storeOption = this.dicts.codeList.hasOwnProperty(this.dicNum)
          //不是从后台获取的dicts
          let storeOptionList = this.dicts.codeList[this.dicNum];

          //对list进行处理
          this.handleDictc(storeOptionList, this.remNum,this.showNum);
        }
      },
      //  预处理下拉选的list
      handleDictc(list, remNum,showNum) {
        let remNumArrary = [];
        let showNumArrary = [];
        let listInnit = deepCopy(list);
        let listItem = [];
        // 如果有移除 那么先移除 在做只显示的
        if (remNum) {
          remNumArrary = remNum.split(",");
          for (let i = 0; i < remNumArrary.length; i++) {
            for (let j = 0; j < listInnit.length; j++) {
              let index = listInnit[j]["codeItemId"] == remNumArrary[i];
              if (index) {
                listInnit.splice(j, 1);
              }
            }
          }
          // 只显示的
          if(showNum){
            showNumArrary = showNum.split(",");
            for (let i = 0; i < showNumArrary.length; i++) {
              for (let j = 0; j < listInnit.length; j++) {
                let index = listInnit[j]["codeItemId"] == showNumArrary[i];
                if (index) {
                  listItem.push(listInnit[j]);
                }
              }
            }
            listInnit =listItem;
          }
          this.options = listInnit;

        } else {
          // 只显示的
          if(showNum){
            showNumArrary = showNum.split(",");
            for (let i = 0; i < showNumArrary.length; i++) {
              for (let j = 0; j < listInnit.length; j++) {
                let index = listInnit[j]["codeItemId"] == showNumArrary[i];
                if (index) {
                  listItem.push(listInnit[j]);
                }
              }
            }
            this.options = listItem;
          }else {
            this.options = list;
          }
        }
        this.dataFormat(this.selectValue)
      },
      dataFormat(va) {
        if(!va){
          this.selectedData = '';
          return ;
        }
        if(this.options){
          for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].codeItemId === ''+va) {
              this.selectedData = this.options[i].codeItemName;
            }
          }
        }
      },
      selectChange(val) {
        this.$emit("update:selectValue", val);
        this.$emit("selectChange", val);
      },

    }
  };
</script>

<style scoped>
  .el-input.el-input--suffix {
    cursor: pointer;
    overflow: hidden;
  }

  .el-input.el-input--suffix.rotate .el-input__suffix {
    transform: rotate(180deg);
  }

  .select-tree {
    max-height: 350px;
    overflow-y: scroll;
  }

  /* 菜单滚动条 */
  .select-tree::-webkit-scrollbar {
    z-index: 11;
    width: 6px;
  }

  .select-tree::-webkit-scrollbar-track,
  .select-tree::-webkit-scrollbar-corner {
    background: #fff;
  }

  .select-tree::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 6px;
    background: #b4bccc;
  }

  .select-tree::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 6px;
  }

  .el-form-item__content {
    height: 28px;
  }
</style>
