import request from '@/router/axios';
const urlPrefix = ''

/**
 * 获取负责的客户
 * @param obj
 */
export  function getCusByUser(obj) {
  return request({
    url:urlPrefix+ '/cus/getCusByUser',
    method: 'get',
  })
}


/**
 * 获取客户下联系人信息 只取一个
 * @param obj
 */
export  function getCusContacts(obj) {
  return request({
    url:urlPrefix+ '/cus/contacts/'+obj,
    method: 'get',
   // data: obj
  })
}


