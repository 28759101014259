<template>
  <div class="stepContent">
    <div class="uploadBox">
      <el-form :inline="true" label-width="110px" :model="form" :rules="rules" ref="form">
        <fieldset v-if="readonly === 'readonly' && !isSubmitter"
                  style="border-color: #E2ded6;border-width: 0px;border-style: dotted;">
          <el-form-item v-if="readonly === 'readonly'" :class="{ noborder: readonly === 'readonly' }" label="提交人："
                        style="width: 40%;padding-left: 10px">
            <el-input v-model="financeAmount.commitUser" readonly="readonly"
                      oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          </el-form-item>
          <el-form-item v-if="readonly === 'readonly'" :class="{ noborder: readonly === 'readonly' }" label="提交时间："
                        style="width: 40%">
            <el-input v-model="financeAmount.commitTime" readonly="readonly"
                      oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          </el-form-item>
        </fieldset>
        <fieldset v-if="readonly === 'readonly' && !isSubmitter"
                  style="border-color: #E2ded6;border-width: 2px;border-style: dotted;">
          <legend style="color:#333333;font-size: 1em;font-weight: bold;">
            <span>账户信息</span>
          </legend>
          <!--<el-form-item v-if="readonly === 'readonly'" :class="{ noborder: readonly === 'readonly' }" label="付款方账户:" style="width: 40%">
            <el-input v-model="financeAmount.coAccount" readonly="readonly"
                      oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          </el-form-item>
          <el-form-item v-else label="付款方账户：" style="width: 40%">
            <el-select v-model="financeAmount.coAccount" placeholder="请选择付款方账户" @change="searchAccountBalance($event)">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>-->
          <!--<el-form-item class="noborder" label="账户开户行：" style="width: 40%;padding-left: 10px">
            <el-input v-model="accountBalance.accountName" readonly="readonly"
                      oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          </el-form-item>
          <el-form-item class="noborder" label="统筹账户：" style="width: 40%">
            <el-input v-model="accountBalance.account" readonly="readonly"
                      oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          </el-form-item>-->
          <el-form-item class="noborder" label="统筹账户余额：" :formatter="stateFormat" style="width: 55%;padding-left: 10px">
            <el-input v-model="isMoney" readonly="readonly"
                      oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          </el-form-item>
        </fieldset>

        <fieldset class="noborder" style="border-color: #E2ded6;border-width: 2px;border-style: dotted;">
          <legend style="color:#333333;font-size: 1em;font-weight: bold;">
            <span>薪酬相关</span>
          </legend>
          <el-form-item label="应发金额合计：" style="width: 30%;padding-left: 10px">
            <el-input style="width: 150px" v-model="financeAmount.payableAmount" readonly="readonly"
                      oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          </el-form-item>
          <el-form-item border="none" label="实发金额合计：" style="width: 30%">
            <el-input style="width: 150px" v-model="financeAmount.paidInAmount" readonly="readonly"
                      oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          </el-form-item>
          <el-form-item label="税金：" style="width: 30%">
            <el-input style="width: 150px" v-model="financeAmount.taxes" readonly="readonly"
                      oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          </el-form-item>
        </fieldset>

        <fieldset :class="{ noborder: readonly === 'readonly' }"
                  style="border-color: #E2ded6;border-width: 2px;border-style: dotted;">
          <legend style="color:#333333;font-size: 1em;font-weight: bold;">
            <span>其它相关</span>
          </legend>
          <el-form-item label="社保合计：" style="width: 30%;padding-left: 10px">
            <el-input style="width: 100px" v-model="financeAmount.totalSocialSecurity" :readonly="readonly"
                      @input="count"
                      oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          </el-form-item>
          <el-form-item label="服务费合计：" style="width: 30%">
            <el-input style="width: 100px" v-model="financeAmount.totalServiceCharge" :readonly="readonly"
                      @input="count"
                      oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          </el-form-item>
          <el-form-item label="其他费用合计：" style="width: 30%">
            <el-input style="width: 100px" v-model="financeAmount.totalOtherExpenses" :readonly="readonly"
                      @input="count"
                      oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          </el-form-item>
        </fieldset>

        <fieldset :class="{ noborder: readonly === 'readonly' }"
                  style="border-color: #E2ded6;border-width: 2px;border-style: dotted;">
          <legend style="color:#333333;font-size: 1em;font-weight: bold;">
            <span>来款确认</span>
          </legend>
          <el-form-item label="来款金额确认：" style="width: 60%;padding-left: 10px">
            <el-input v-if="readonly === 'readonly'" v-model="amountConfirmationIsMoney" :readonly="readonly"
                      oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
            <el-input v-else v-model="financeAmount.amountConfirmation" :readonly="readonly"
                      oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          </el-form-item>
        </fieldset>

        <div v-if="this.showDetail">
          <fieldset style="border-color: #E2ded6;border-width: 2px;border-style: dotted;">
            <legend style="color:#333333;font-size: 1em;font-weight: bold;">
              <i class="el-icon-chat-line-square">历史消息</i>
            </legend>
            <el-input type="textarea" readonly="readonly" v-model="financeAmount.remarkHis"></el-input>
          </fieldset>
        </div>
      </el-form>
    </div>
    <div class="uploadTip">
      <div class="uploadTip-title" style="color: chocolate">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-tishi"></use>
        </svg>
        温馨提示
      </div>
      <div class="uploadTip-lists">
        <div class="uploadTip-list">
          社保合计、服务费合计、其他费用合计，仅支持录入两位小数，超过两位小数将四舍五入。<br>
          来款确认金额 = (实发金额 + 税金 + 社保 + 服务费 + 其他费用)，但是不做强制限制。<br>
        </div>
      </div>
    </div>
    <div v-if="!this.showDetail">
      <div v-if="this.edit" style="padding:20px 0">
        <div style="float:right">
          <el-button style="background-color: gray" @click="_close">取 消</el-button>
          <el-button v-if="this.financeAmount.status === '3004000044'" @click="_reCommitFinance">重新提交</el-button>
          <el-button v-else @click="_commitFinance" :disabled="isDisabled">提交</el-button>
        </div>
      </div>
      <div v-if="!this.edit" style="padding:20px 0">
        <div style="float:right">
          <el-button @click="_confirmFinance" :disabled="isConfirmDisabled">确 认</el-button>
          <el-button style="background-color: slategray" @click="_senBackOpen" :disabled="isConfirmDisabled">退 回</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      title="退回备注"
      :visible.sync="senBackFinanceDialogVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="40%"
      @close="_senBackClose()"
    >
      <el-form ref="form2" :model="form2">
        <el-form-item><i class="el-icon-chat-line-square" style="font-size: larger;float: left;color: dimgrey;">历史消息</i>
          <el-input min-height="100px" type="textarea" readonly="readonly" v-model="financeAmount.remarkHis"></el-input>
        </el-form-item>
        <el-form-item><i class="el-icon-chat-dot-round" style="font-size: larger;float: left;color: dimgrey;">to
          {{this.financeAmount.submitterName}}</i>
        </el-form-item>
        <el-form-item><i class="el-icon-edit" style="font-size: larger;color: darkslategrey;">退回原因<span
          style="color: red;font-size: smaller;">(*必填)</span></i>
          <el-input type="textarea" v-model="financeAmount.remark"></el-input>
        </el-form-item>
        <el-form-item><i class="el-icon-chat-dot-round" style="font-size: larger;float: right;color: forestgreen;">from
          {{this.userInfo.name}}</i>
        </el-form-item>
        <div style="padding:20px 0">
          <div style="float:right;margin-bottom: 2px">
            <el-button style="background-color: gray" @click="_closeRemark">取 消</el-button>
            <el-button @click="_backFinance">提 交</el-button>
          </div>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog
      title="重新提交备注"
      :visible.sync="reCommitFinanceDialogVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="40%"
      @close="_closeRecRemark()"
    >
      <el-form>
        <el-form-item><i class="el-icon-chat-line-square" style="font-size: larger;float: left;color: dimgrey;">历史消息</i>
          <el-input min-height="100px" type="textarea" readonly="readonly" v-model="financeAmount.remarkHis"></el-input>
        </el-form-item>
        <el-form-item><i class="el-icon-edit" style="font-size: larger;color: darkslategrey;">备注<span
          style="color: red;font-size: smaller;">(*必填)</span></i>
          <el-input type="textarea" v-model="financeAmount.remark"></el-input>
        </el-form-item>
        <el-form-item><i class="el-icon-chat-dot-round" style="font-size: larger;float: right;color: forestgreen;">from
          {{this.userInfo.name}}</i>
        </el-form-item>
        <div style="padding:20px 0">
          <div style="float:right;margin-bottom: 2px">
            <el-button style="background-color: gray" @click="_closeRecRemark">取 消</el-button>
            <el-button @click="_commitFinance">提 交</el-button>
          </div>
        </div>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
  import {getCusByUser} from '@/api/sys/cus';
  import {mapGetters} from "vuex";
  import form from "../../demo/form/form";
  import {getTransOrgIsAudit, searchAccountBalance} from '@/api/entrust/distributionBatch';

  export default {
    name: "detailImportFinanceTables",
    data: {
      rules: {
        remark: [{required: true, message: '请输入', trigger: 'blur'}],
      },
    },
    created() {
      this.isDisabled = false;
    },
    computed: {
      ...mapGetters([
        'dicts', 'userInfo', 'accessToken'
      ]),
      readonly: function () {
        if (!this.edit) {
          return 'readonly';
        } else {
          return false;
        }
      },
      isMoney() {
        debugger
        const val = this.accountBalance.balance;
        if (typeof val === "number") {
          return val.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        } else {
          return this.accountBalance.balance;
        }
      },
      amountConfirmationIsMoney() {
        debugger
        const val = this.financeAmount.amountConfirmation;
        if (typeof val === "number") {
          return val.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        } else {
          return this.financeAmount.amountConfirmation;
        }
      },
      count: function () {
        if (this.edit) {
          debugger;
          var paidInAmount = (this.financeAmount.paidInAmount === '' || this.financeAmount.paidInAmount === undefined) ? 0 : parseFloat(this.financeAmount.paidInAmount);
          var taxes = (this.financeAmount.taxes === '' || this.financeAmount.taxes === undefined) ? 0 : parseFloat(this.financeAmount.taxes);
          var totalSocialSecurity = (this.financeAmount.totalSocialSecurity === '' || this.financeAmount.totalSocialSecurity === undefined) ? 0 : parseFloat(this.financeAmount.totalSocialSecurity);
          var totalServiceCharge = (this.financeAmount.totalServiceCharge === '' || this.financeAmount.totalServiceCharge === undefined) ? 0 : parseFloat(this.financeAmount.totalServiceCharge);
          var totalOtherExpenses = (this.financeAmount.totalOtherExpenses === '' || this.financeAmount.totalOtherExpenses === undefined) ? 0 : parseFloat(this.financeAmount.totalOtherExpenses);
          this.financeAmount.amountConfirmation = this.getSum(paidInAmount, taxes, totalSocialSecurity, totalServiceCharge, totalOtherExpenses);
        }
      },
    },
    data() {
      return {
        // 遮罩
        loading: {
          type: Boolean,
          default: false
        },
        senBackFinanceDialogVisible: false,
        reCommitFinanceDialogVisible: false,
      }
    },
    inject: ['reload'],
    props: {
      isDisabled: {
        type: Boolean,
        default: false
      },
      isConfirmDisabled: {
        type: Boolean,
        default: false
      },
      financeAmount: {
        payableAmount: Number,
        paidInAmount: Number,
        taxes: Number,
        totalSocialSecurity: {
          type: Number,
          default: 0
        },
        totalServiceCharge: {
          type: Number,
          default: 0
        },
        totalOtherExpenses: {
          type: Number,
          default: 0
        },
        amountConfirmation: {
          type: Number,
          default: 0
        },
        financeId: {
          type: String,
          default: ''
        },
        remark: {
          type: String,
          default: ''
        },
        submitterName: {
          type: String,
          default: ''
        },
        status: {
          type: String,
          default: ''
        },
        remarkHis: {
          type: String,
          default: ''
        },
        commitUser: '',//提交人
        commitTime: '',//提交时间
        coAccountBalance: '0',//统筹账户余额
        coAccount: '',//统筹账户
      },
      accountBalances: [],
      accountBalance: {
        account: '',
        accountName: '',
        balance: {
          type: Number,
          default: 0
        }
      },
      edit: true,
      senBackFinanceDialogVisible: {
        type: Boolean,
        default: false
      },
      reCommitFinanceDialogVisible: {
        type: Boolean,
        default: false
      },
      showDetail: false,
      isSubmitter: false,
    },
    methods: {
      getSum() {
        var array = arguments;
        var lens = array.length,//获取参数的长度
          d = 0,//定义小数位的初始长度，默认为整数，即小数位为0
          sum = 0;//定义sum来接收所有数据的和
        //循环所有的参数
        for (var key in array) {//遍历所有的参数
          //把数字转为字符串
          var str = "" + array[key];
          if (str.indexOf(".") != -1) {//判断数字是否为小数
            //获取小数位的长度
            var temp = str.split(".")[1].length;
            //比较此数的小数位与原小数位的长度，取小数位较长的存储到d中
            d = d < temp ? temp : d;
          }
        }
        //计算需要乘的数值
        var m = Math.pow(10, d);
        //遍历所有参数并相加
        for (var key in array) {
          sum += array[key] * m;
        }
        //返回结果
        return sum / m;
      },
      stateFormat(cellValue) {
        if (cellValue) {
          cellValue += "";
          if (!cellValue.includes(".")) cellValue += ".";
          return cellValue
            .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
              return $1 + ",";
            })
            .replace(/\.$/, "");
        }
      },
      _close() {
        this.$emit('hiddenImp');
        this.financeAmount = '';
      },
      _senBackClose() {
        this.senBackFinanceDialogVisible = false;
      },
      _senBackOpen() {
        debugger
        this.senBackFinanceDialogVisible = true;
      },
      _commitFinance() {
        debugger
        this.loading = true;
        this.isDisabled = true;
        this.financeAmount.submitterName = this.userInfo.name;
        var len = (getStrLeng(this.financeAmount.remarkHis)) + (getStrLeng(this.financeAmount.remark) + (getStrLeng(this.financeAmount.submitterName)) + 24);
        if (len > 2000) {
          this.$message.warning('历史消息超过2000字节或超过1000汉字的限制（当前长度 ' + len + '），该操作会导致财务退回失败，请联系管理员');
          return;
        }
        this.$emit('_commitFinanceConfirm', this.financeAmount);
        this.loading = false;
        this.isDisabled = false;
        this.reCommitFinanceDialogVisible = false;
      },
      _reCommitFinance() {
        this.isDisabled = true;
        this.reCommitFinanceDialogVisible = true;
      },
      //财务确认
      async _confirmFinance() {
        this.loading = true;
        console.log('财务确认121');
        debugger;
        this.isConfirmDisabled = true;
        await this.$emit('confirmFinance', this.financeAmount);
        this.isConfirmDisabled = false;
        console.log('财务确认122');
        this.loading = false;
      },
      //财务退回
      _backFinance() {
        this.financeAmount.submitterName = this.userInfo.name;
        var len = (getStrLeng(this.financeAmount.remarkHis)) + (getStrLeng(this.financeAmount.remark) + (getStrLeng(this.financeAmount.submitterName)) + 24);
        if (len > 2000) {
          this.$message.warning('历史消息超过2000字节或超过1000汉字的限制（当前长度 ' + len + '），该操作会导致财务退回失败，请联系管理员');
          return;
        }
        this.$emit('backFinance', this.financeAmount);
        this.senBackFinanceDialogVisible = false;
      },

      _closeRemark() {
        this.senBackFinanceDialogVisible = false;
      },
      _closeRecRemark() {
        this.reCommitFinanceDialogVisible = false;
      }
    }
  }

  function getStrLeng(str) {
    if (str === null) {
      str = "";
    }
    var realLength = 0;
    var len = str.length;
    var charCode = -1;
    for (var i = 0; i < len; i++) {
      charCode = str.charCodeAt(i);
      if (charCode >= 0 && charCode <= 128) {
        realLength += 1;
      } else {
        // 如果是中文则长度加2
        realLength += 2;
      }
    }
    return realLength;
  }
</script>

<style scoped rel="stylesheet/scss">
  .uploadBox {
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .stepContent {
    padding: 20px 20px;
  }

  .noborder >>> .el-input .el-input__inner {
    border: none !important;
  }
</style>
