import request from '@/router/axios';
const urlPrefix = ''

/**
 * 获取批次信息
 * @param obj
 */
export  function findPaymentBillsPage(obj) {
  return request({
    url:urlPrefix+ '/payBillBatch/listPage',
    method: 'post',
    data: obj
  })
}

/**
 * 获取批次信息
 * @param obj
 */
export  function findCreatePaymentBillsPage(obj) {
  return request({
    url:urlPrefix+ '/payBillBatch/createBillList',
    method: 'post',
    data: obj
  })
}

/**
 *  创建账单
 * @param obj
 */
export  function createBills(obj) {
  return request({
    url:urlPrefix+ '/payBillBatch/createBills',
    method: 'post',
    data: obj
  })
}

/**
 *  创建账单
 * @param obj
 */
export  function deleteBill(obj) {
  return request({
    url:urlPrefix+ '/payBillBatch/'+obj,
    method: 'delete',
  })
}


/**
 * 查询账单明细
 * @param obj
 */
export  function findPayMentDetail(obj) {
  return request({
    url:urlPrefix+ '/payBillDetail/listPage',
    method: 'post',
    data: obj
  })
}


/**
 * 获取表头
 * @param obj
 */
export  function getHead(obj) {
  return request({
    url:urlPrefix+ '/payBillDetail/getHeadList/'+obj,
    method: 'get'
  })
}
export  function downloadBills(id) {
  return request({
    url: urlPrefix + '/payBillDetail/downBills/'+id,
    method: 'get',
    responseType: 'arraybuffer'
  })
}










