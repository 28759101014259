<template>
  <div>
    <template>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="未发送" name="1"></el-tab-pane>
        <el-tab-pane label="已发送" name="2"></el-tab-pane>
      </el-tabs>
    </template>
    <template>
      <div>
        <el-form :inline="true" :model="listQuery" class="demo-form-inline">
          <el-form-item label="客户名称" style="margin-right: 50px">
            <!-- <bsp-dicts-select
                v-model="listQuery.cusid"
                :selectValue.sync="listQuery.cusid"
                :optionsList="cusInfoList"
                @change="cusInfoChange"
            ></bsp-dicts-select> -->
            <el-select v-model="listQuery.cusId" filterable clearable placeholder="请选择客户"  @change="cusInfoChange">
              <el-option
                  v-for="item in cusInfoList"
                  :key="item.bspId"
                  :label="item.f001200"
                  :value="item.bspId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发薪时间" style="margin-right: 15%">
            <el-col :span="8">
              <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="请选择发薪开始日期" v-model="listQuery.paymentTime[0]" style="width: 100%;"></el-date-picker>
            </el-col>
            <el-col class="line center" :span="1">-</el-col>
            <el-col :span="8">
              <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="请选择发薪结束日期" v-model="listQuery.paymentTime[1]" style="width: 100%;"></el-date-picker>
            </el-col>
            <el-col :span="3" style="margin-left: 10px">
              <el-button @click="loadList" size="medium" icon="el-icon-search" >
                查找
              </el-button>
            </el-col>
          </el-form-item>

          <el-form-item label-position='right'>
            <el-button @click="_save" size="medium" icon="el-icon-plus" >
              保存
            </el-button>
          </el-form-item>
          <el-form-item label-position='right'>
            <el-button @click="forwardWage" size="medium" icon="el-icon-position" >
              转发
            </el-button>
          </el-form-item>
          <el-form-item label-position='right'  v-show="activeName == '1'">
            <el-button @click="_sendPayslips" size="medium" icon="el-icon-message">
              发送邮件
            </el-button>
          </el-form-item>
          <el-form-item label-position='right' v-show="activeName == '2'">
            <el-button @click="_sendPayslips" size="medium" icon="el-icon-message">
              重新发送
            </el-button>
          </el-form-item>
          <el-form-item label-position='right'>
            <el-button @click="_sendDetail" size="medium" icon="el-icon-message">
              查看发送详情
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template>
      <div style="">
        <bsp-table
          ref="publicTable"
          :data.sync="list"
          :tree-config="true"
          @select-change="selectChange"
          @select-all="selectChange"
          :ciic-data-filter.sync="listQuery"
          @ciic-data-refresh="loadList"
          show-header-overflow show-overflowwidth show-overflow
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          show-footer-overflow
          show-footer
          :edit-rules="validRules"
          :editorConfig="{trigger: 'dblclick',activeMethod :editorActive, mode: 'cell',showStatus:'true'}"
          @edit-actived="editorActiveFun"
          :valid-config="{autoPos:false}"
          :iKeepSource="true"
          height="88%"
        >
          <div>
            <vxe-table-column type="selection" width="60" fixed="left"/>
            <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      
            <bsp-table-column title="客户名称" field="cusName"  width="150">
              <template slot-scope="scope"><span>{{ scope.row.cusName}}</span></template>
            </bsp-table-column>
            <bsp-table-column title="雇员名称" field="empName" filter-type="input" >
              <template slot-scope="scope"><span>{{ scope.row.empName }}</span></template>
            </bsp-table-column>
            <bsp-table-column title="雇员证件号码" field="identityNum" filter-type="input"  >
              <template slot-scope="scope"><span>{{ scope.row.identityNum }}</span></template>
            </bsp-table-column>
            <bsp-table-column title="计税类型" field="taxType" filter-type="select" set-id="3077"  >
              <template slot-scope="scope"><span>{{ scope.row.taxType | dictFilter(dicts) }}</span></template>
            </bsp-table-column>
            <bsp-table-column title="邮箱" field="email" :edit-render="{name: 'input'} "  >
              <template slot-scope="scope"><span>{{ scope.row.email }}</span></template>
            </bsp-table-column>
            <bsp-table-column title="发薪时间" field="payTime" filter-type="datePicker" date-picker-type="date">
              <template slot-scope="scope"><span>{{ scope.row.payTime | subPayTime }}</span></template>
            </bsp-table-column>
            <bsp-table-column title="状态" field="sendStatus">
              <template slot-scope="scope"><span>{{ scope.row.sendStatus }}</span></template>
            </bsp-table-column>
          </div>
        </bsp-table>
      </div>
    </template>

    <!-- 邮箱地址弹框 -->
    <bsp-dialog
      dialog-ref="sendEmail"
      :dialog-visible.sync="sendEmailVisible"
      width="600px"
      :title="sendEmailTitle"
      @close="cancel"
    >
      <div slot="body">
        <el-form :model="form" :rules="emailRules"  ref="sendEmailFrom">
          <el-form-item label="邮箱地址：" :label-width="formLabelWidth" prop="EmailAddress">
            <el-input v-model="form.EmailAddress" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="_sendEmailOk">确 定</el-button>
      </div>
    </bsp-dialog>

    <!-- 邮件发送详情 -->
    <bsp-dialog
      dialog-ref="sendDetail"
      :dialog-visible.sync="sendDetailVisible"
      width="40%"
      title="邮件发送详情"
      @close="closeDialog"
    >
      <div slot="body">
        <bsp-table
          ref="sendDetailTable"
          :data.sync="detailList"
          @ciic-data-refresh="loadList"
          show-header-overflow
          show-overflow
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          :show-pagination="false"
        >
          <vxe-table-column title="序号" type="seq" width="60" fixed="left" align="center"></vxe-table-column>
          <bsp-table-column
            title="发送时间"
            field="SEND_TIME"
            min-width="120"
          >
            <template slot-scope="scope">
              <span>{{scope.row.SEND_STATUS == '1' ? scope.row.SEND_TIME : '--'}}</span>
            </template>
          </bsp-table-column>

          <bsp-table-column
            title="收件人"
            field="TO_ADDRESS"
            min-width="120"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.TO_ADDRESS  }}</span>
            </template>
          </bsp-table-column>

          <bsp-table-column
            title="发送状态"
            field="SEND_STATUS"
            width="120"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.SEND_STATUS | sendStatus}}</span>
            </template>
          </bsp-table-column>

          <bsp-table-column
            title="错误详情"
            field="ERR_MESSAGE"
            min-width="120"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.ERR_MESSAGE  }}</span>
            </template>
          </bsp-table-column>
        </bsp-table>
      </div>
    </bsp-dialog>
  </div>
</template>

<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {saveEmailBatch,forwardEmail,queryCusList,searchWageBills,sendPayslips,querySendDetail,resendPayslips} from '@/api/entrust/wageBills';
  import {mapGetters} from 'vuex';
  import BspDialog from "@/components/bsp/bsp-dialog/bsp-dialog"
  import {convertDateForm} from "../../../util/util";

  export default {
    name: "mitationLogin",
    inject: ['reload'],
    components: {
      BspTable,
      BspTableColumn,
      BspDialog,
      // bspDictsSelect
    },
    computed: {
      ...mapGetters([
        'dicts', "userInfo"
      ]),
    },
    created() {
      this._getCusInfoList(); // 加载客户名称下拉列表
      const dateStr = convertDateForm(new Date());
      this.listQuery.paymentTime[0] = dateStr;
      this.listQuery.paymentTime[1] = dateStr;
      this.listQuery.isSendEmail = "10020002";
    },
  filters: {
      sendStatus(val) {
        if (val == '1') {
          return '已发送';
        }
        if (val == '0') {
          return '发送失败';
        }
        if (val == null) {
          return '待发送';
        }
        if (val == '2') {
          return '发送中';
        }
      },
      subPayTime(val){
        return val.substring(0,10);
      }
    },
    data() {
      return {
        // 用于接收后台返回的分页数据
        activeName: '1',
        loading: false,
        sendEmailVisible:false,
        sendDetailVisible:false,//发送详情
        sendEmailTitle:"转发邮件",
        list: [],
        detailList:[],
        flag:"",// forward 转发，resend重复
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          cusId:"",//使用bsp_id
          //startTime: "",// 开始时间
          // endTime: "",//结束时间
          paymentTime:[null,null],
          isSendEmail:"",
        },
        form:{
          EmailAddress:"",
        },
        //多选数组
        multipleSelection:[],
        // 客户列表
        cusInfoList:[],
        emailRules: {
          EmailAddress: [
              { required: true, message: '请输入邮箱地址', trigger: 'blur' },
              { type: 'email', message: '邮箱格式不正确', trigger: ['blur', 'change'] },
          ],
        },
        validRules:{
          email:[
            { required: true, message: '请填写邮箱地址' , trigger :'manual'},
            { pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/ , message: '邮箱格式不正确', trigger :'manual' },
          ]
        }
      }
    },

    methods: {
      handleClick(tab, event) {
        //查询不同状态的数据
        console.log( "============>",this.listQuery.cusId !== "" && !this.dateEmpty());
    
        this.activeName == "1" ? this.listQuery.isSendEmail = "10020002" :  this.listQuery.isSendEmail = "10020001";
        // console.log(this.listQuery.isSendEmail);
        if (this.listQuery.cusId !== "" && !this.dateEmpty()) {
          this.loadList()
        }
        // this.list = []; // 切换选项卡列表置null
      },
      loadList() {
        this.multipleSelection = []
        let checkMsg = "";
        this.listQuery.cusId === "" ? checkMsg += "请选择要查询的客户!":"";
        this.dateEmpty() ? checkMsg += "未选择开始时间就必须选择结束时间!" : "";
        if (checkMsg === "") {
          this.loading = true;
          searchWageBills(this.listQuery).then(res => {
            if (res.data.success == true) {
              this.list = res.data.data.records;
              this.listQuery.total = res.data.data.total
              this.refresh = true;
              this.loading = false;
            } else {
              this.$message.error("查询失败,失败原因：" + res.data.msg);
              this.loading = false;
            }
            
          })
        }else {
          this.$message.error(checkMsg);
        }
      },
      // 点选/全选复选框
      selectChange(checked) {
        if (checked.checked) {
          const list = checked.selection;
          console.log("==============>",list);
          this.multipleSelection = [];
          for (let index = 0; index < list.length; index++) {
            const element = list[index];
            this.multipleSelection.push(element);
          }
        } else {
          if (checked.$rowIndex == undefined) {
            this.multipleSelection = [];
          } else {
            this.multipleSelection = checked.selection;
          }
        }
      },
      _save(){ //更新雇员的邮箱信息
        // 保存雇员的邮箱信息判断是否有更新
        const renameDate = this.$refs.publicTable.editorRowData();

        if (renameDate.length === 0) {
          this.$message.warning('没有需要更新的邮箱信息！');
        } else {
          let toSaveData = this.checkUpdateRow(this.multipleSelection);
          // 根据修改的数据找到勾选的数据进行保存
          if(toSaveData.length == 0){
            this.$message.warning('请选择有改动的数据进行保存！');
            return ;
          }
          this.fullValidEve(toSaveData).then(()=>{
            console.log("这里表示校验通过了");
            let empNameList = [];
            toSaveData.forEach(element => empNameList.push(element.empName));
            this.$confirm('雇员：'+empNameList.join(",")+'的邮箱将会更新, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.loading = true;
              saveEmailBatch(toSaveData).then(res => {
                console.log("接口调用结果====>",res);
                if (res.data.success == true) {
                  this.$message.success('保存成功');
                  this.loading = false;
                } else {
                  this.$message.error("保存失败," + res.data.msg);
                  this.loading = false;
                }
                this.loadList();
              })
            })
          }).catch((errMap)=>{
            // 表示校验未通过
            // console.log("一些错误的数据",err);
            let msgList = []
            Object.values(errMap).forEach((errList) => {
              errList.forEach((params) => {
                const { row, column, rules } = params
                console.log("==================>",params);
                rules.forEach((rule) => {
                  msgList.push(`雇员： ${row.empName}   ${column.title} 校验错误：${rule.message}`)
                })
              })
            })
            // console.log(msgList);
            this.$message.error( {
              dangerouslyUseHTMLString: true ,
              message:"保存失败<br>" + msgList.join("</br>")
            });
          })
        }
      },
      _getCusInfoList(){ //加载客户列表
        queryCusList().then(res => {
          this.cusInfoList = res.data.data;
        })
      },
      forwardWage(){// 转发
        if (this.multipleSelection.length === 1) {
          this.sendEmailVisible = true;
        } else {
          this.$message.warning('请选择一条要转发的雇员工资条信息！');
        }
      },
      cancel(){
        this.sendEmailVisible = false;
        this.$refs["sendEmailFrom"].resetFields()
      },
      _sendEmailOk(){//转发发送邮件
        if(this.form.EmailAddress == ""){
          this.$message.error("请填写邮箱地址信息！");
          return ;
        }else {
          this.multipleSelection[0].email = this.form.EmailAddress;
          
          forwardEmail(this.multipleSelection).then(res => {
            if (res.data.success == true) {
                this.$message.success('操作成功');
                this.cancel();
                this.loadList();
                // this.loading = false;
            } else {
                this.$message.error("操作失败" + res.data.msg);
                //this.loading = false;
            }
          })
        }
      },
      cusInfoChange(){// 选择客户信息
        // this.listQuery.cusId
        // this.loadList();
        // console.log("下拉框改变时间",this.listQuery.cusId !== "" && !this.dateEmpty());
        // if (this.listQuery.cusId !== "" && !this.dateEmpty()) {
        //   this.loadList()
        // }
      },
      _sendPayslips(){
        if (this.multipleSelection.length === 0) {
          this.$message.warning('请至少选择一条数据！');
        } else {
          let empEmailEmpty = this.multipleSelection.filter(item => item.email == "" || item.email == null)
           console.log("==============>",empEmailEmpty);
          if(empEmailEmpty.length != 0){
            let msgEmp = "";
            empEmailEmpty.forEach(item => msgEmp += item.empName+ ",");
            this.$message.warning('雇员：' + msgEmp + "的邮箱地址未空！这部分雇员不会进行邮件发送！！！" );
          }
          let empEmailNotEmpty = this.multipleSelection.filter(item => !(item.email == "" || item.email == null))
          let updateRow = this.checkUpdateRow(empEmailNotEmpty);
          if (updateRow.length != 0) {
            this.$message.error("存在更新的邮箱信息! 请先进行保存!");
            return ;
          }
          if(empEmailNotEmpty.length != 0){

          this.$confirm('确认发送邮件后，请在“已发送”列表查看工资条发送状态', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              sendPayslips(empEmailNotEmpty).then(res => {
                if (res.data.success == true) {
                  this.$message.success('操作成功');
                  this.loadList();
                } else {
                  this.$message.error("操作失败" + res.data.msg);
                }
              })
            })
          }
        }
      },
      async _resendSlips(){ //重新发送 弃用
        if (this.multipleSelection.length === 0) {
          this.$message.warning('请至少选择一条数据！');
        } else {
          let empEmailEmpty = this.multipleSelection.filter(item => item.email == "" || item.email == null)
          // console.log("==============>",empEmailEmpty);
          if(empEmailEmpty.length != 0){
            let msgEmp = "";
            empEmailEmpty.forEach(item => msgEmp += item.empName+ ",");
            this.$message.warning('雇员：' + msgEmp + "的邮箱地址未空！这部分雇员不会进行邮件发送！！！" );
          }
          let empEmailNotEmpty = this.multipleSelection.filter(item => !(item.email == "" || item.email == null))
          let updateRow = this.checkUpdateRow(empEmailNotEmpty);
          let flag = true;
          if (updateRow.length != 0) {
          // flag = false;
          await this.$confirm('选中的雇员存在修改未保存的邮箱信息，会以原邮箱地址发送邮件。是否继续！', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => flag = true ).catch(() => flag = false)
          }
          if(empEmailNotEmpty.length != 0 && flag){
            //console.log("empEmailNotEmpty",empEmailNotEmpty);
            // empEmailNotEmpty.forEach(item => item.email = item.EMAILSOURCE);
            resendPayslips(empEmailNotEmpty).then(res => {
              if (res.data.success == true) {
                this.$message.success('操作成功');
                this.loadList();
              } else {
                this.$message.error("操作失败" + res.data.msg);
              }
            })
          }
        }
      },
      dateEmpty(){// 开始时间结束时间校验
        const endTime = this.listQuery.paymentTime[0];
        const startTime = this.listQuery.paymentTime[1];
        return endTime == null && startTime == null;
      },
      async fullValidEve(renameDate) { //编辑框校验
        // console.log("要校验的数据",renameDate);
        const $table = this.$refs["publicTable"].$refs["ciicTable"]
        return await $table.fullValidate(renameDate,this._callbackValidEve());
      },
      _callbackValidEve(){
        console.log("这里是校验之后的会调函数");
      },
      checkUpdateRow(rowData){// 校验勾选数据是否存在更新
        let filterData = [];
        if (rowData.length == 0) {
          return filterData;
        }
        const editorData = this.$refs.publicTable.editorRowData();
        if(editorData.length != 0 ){
          filterData = editorData.filter(item => rowData.indexOf(item) > -1);
          return filterData;
        } else {
          return filterData;
        }
      },
      editorActive({ row, columnIndex }){
        // console.log("禁用编辑",row);
        if (this.activeName == "2" && (row.sendStatus =='待发送' || row.sendStatus =='发送中')) {
          this.$message.warning('发送中的数据不可以更改邮箱信息。');
          return false
        }
        return true
      },
      editorActiveFun({ row, rowIndex, $rowIndex, column, columnIndex, $columnIndex }){
        row.emailSource = row.email
      },
      _sendDetail(){
        console.log(this.multipleSelection);
        if (this.multipleSelection.length === 1) {
          this.sendDetailOpen();
        } else {
          this.$message.warning('请选择一个雇员查看发送详情！');
        }
      },
      sendDetailOpen(){
        this.sendDetailVisible = true;
        const selectRow = this.multipleSelection[0];
        const param = {currentTab: "inSys", email: "", salaryDetailId: selectRow.salaryDetailId, type: ""}
        querySendDetail(param).then(res => {
          // console.log(res);
          this.detailList = res.data.data;
        })
      },
      closeDialog(){
        this.sendDetailVisible = false;
      }
    }
  }
</script>

<style scoped>

</style>
