<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right" v-if="this.payClass === '1' " @click="printPaymentSlip">打印财务公司支付单</el-button>
      <el-button type="primary" size="big" fixed="right" v-if="this.payClass === '1' " @click="printRefundNotice">打印退票通知书</el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.payClass === '1' " @click="printEmployeeDetail">打印雇员明细</el-button>
      <el-button type="primary" size="big" fixed="right" v-if="this.payClass === '2' && accountType!= null && accountType!='' && accountType!='统筹' " @click="printPublicPayment">打印对公支付单</el-button>
      <el-button type="primary" size="big" fixed="right" v-if="this.payClass === '2' " @click="printPublicPaymentDetail">打印对公支付单明细</el-button>

      <el-button type="primary" size="small" fixed="right" @click="exportDetail">导出明细</el-button>
      <el-button type="primary" size="small" fixed="right" @click="back">返回</el-button>
    </div>
    <el-form :inline="true" :model="listQuery" class="editform">

      <template>
        <el-tabs v-model="listQuery.activeName" @tab-click="handleClick">
          <el-tab-pane label="客户明细" name="1001" v-if="this.show.show1001"></el-tab-pane>
          <el-tab-pane label="雇员明细" name="1002" v-if="this.show.show1002"></el-tab-pane>
          <el-tab-pane label="支出单明细" name="1003" v-if="this.show.show1003"></el-tab-pane>
          <el-tab-pane label="中籍工资" name="1004" v-if="this.show.show1004"></el-tab-pane>
          <el-tab-pane label="招行明细" name="1005" v-if="this.show.show1005"></el-tab-pane>
          <el-tab-pane label="退票信息" name="1006" v-if="this.show.show1006"></el-tab-pane>
          <el-tab-pane label="支付信息" name="1007" v-if="this.show.show1007"></el-tab-pane>

          <el-tab-pane label="工行明细" name="1008" v-if="this.show.show1008"></el-tab-pane>
          <el-tab-pane label="招行明细（工资）" name="1009" v-if="this.show.show1009"></el-tab-pane>
          <el-tab-pane label="招行明细（代报销）" name="1010" v-if="this.show.show1010"></el-tab-pane>
          <el-tab-pane label="代报销" name="1011" v-if="this.show.show1011"></el-tab-pane>
          <el-tab-pane label="中籍工资补发" name="1012" v-if="this.show.show1012"></el-tab-pane>
          <el-tab-pane label="年终奖" name="1013" v-if="this.show.show1013"></el-tab-pane>

          <el-tab-pane label="年终奖补发" name="1016" v-if="this.show.show1016"></el-tab-pane>
          <el-tab-pane label="对公明细" name="1017" v-if="this.show.show1017"></el-tab-pane>

        </el-tabs>
      </template>
    </el-form>

    <bsp-table
      v-if="refresh"
      ref="publicTable"
      :data.sync="list"
      :tree-config="true"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="searchDetail"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
    >
      <vxe-table-column type="selection" width="60" fixed="left"/>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <div v-if="listQuery.activeName === '1001' && this.show.show1001">
        <bsp-table-column title="客户名称" field="cusName" filter-type="input" width="130">
          <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="支出单编号" field="payoutId" filter-type="input" width="150">
          <template slot-scope="scope"><span>{{scope.row.payoutId}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="业务负责人" field="businessLeaderName" filter-type="input" width="130">
          <template slot-scope="scope"><span>{{scope.row.businessLeaderName}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="开票月份" field="bizMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.bizMonth}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="业务所属小组" field="businessGroupName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.businessGroupName}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="业务所属部门" field="businessDeptName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.businessDeptName}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="模式" field="operMode" filter-type="select" width="130" set-id = "3043">
          <template slot-scope="scope"><span>{{scope.row.operMode | dictFilter(dicts)}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="个税税金（借）" field="paymentProjectTaxD" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.paymentProjectTaxD | moneyFilterStr}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="工资往来" field="paymentProjectWage" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.paymentProjectWage | moneyFilterStr}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="代报销（借）" field="paymentProjectReimburse" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.paymentProjectReimburse | moneyFilterStr}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="委托费成本" field="paymentProjectTrustCost" filter-type="input"  width="100">
          <template slot-scope="scope"><span>{{scope.row.paymentProjectTrustCost | moneyFilterStr}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="注册服务成本" field="paymentProjectRegisterCost" filter-type="input"  width="100">
          <template slot-scope="scope"><span>{{scope.row.paymentProjectRegisterCost | moneyFilterStr}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="财税服务成本" field="paymentProjectFinanceCost" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.paymentProjectFinanceCost | moneyFilterStr}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="薪酬服务成本" field="paymentProjectWageCost" filter-type="input"  width="100">
          <template slot-scope="scope"><span>{{scope.row.paymentProjectWageCost | moneyFilterStr}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="残保金" field="paymentProjectDisability" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.paymentProjectDisability | moneyFilterStr}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="其他往来" field="paymentProjectOther" filter-type="input"  width="100">
          <template slot-scope="scope"><span>{{scope.row.paymentProjectOther | moneyFilterStr}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="其他成本" field="paymentProjectOtherCost" filter-type="input"  width="100">
          <template slot-scope="scope"><span>{{scope.row.paymentProjectOtherCost | moneyFilterStr}}</span></template>
        </bsp-table-column>

      </div>

      <div v-if="listQuery.activeName === '1002' && this.show.show1002">
        <bsp-table-column title="客户名称" field="cusName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204399}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员姓名" field="empName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204344}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="报税国籍" field="national" filter-type="select" set-id  = '2127' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204346 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员证件号" field="identityNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204347}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="计税类型" field="taxType" filter-type="select" set-id  = '3077' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204208 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="报税名义" field="taxNominal" filter-type="select" set-id  = '3303' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204210 | dictFilter(dicts) }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税金缴纳月份" field="taxDeclareMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204398}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="收款来源" field="e204355" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204355}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="银行类别" field="bankType"  filter-type="select" set-id  = '3084' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204265 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户名" field="accountName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204266}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户账号" field="accountNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204267}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户银行全称" field="bankFullName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204268}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="账号地区" field="bankAccountArea" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204269}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="工行地区代码" field="ibicAreNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204270}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="是否残疾人" field="isDisabled" filter-type="select" set-id = '0090' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204356 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="是否税金托收" field="isTaxCollection" filter-type="select" set-id = '0090' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204359 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="委托服务机构" field="e204214" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204214}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="收款行开户名" field="skhkhm" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.skhkhm}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="收款行开户行" field="skhkhh" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.skhkhh}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="收款行账号" field="skhzh" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.skhzh}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="薪资计税区域" field="taxArea" filter-type="select" set-id = '3202' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204215 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="工资劳务费年份" field="e204212" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204212}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="工资劳务费月份" field="e204213" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204213}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="成本工资" field="e204243" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204243 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="工资往来" field="wagesExchangesCal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204244 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前养老个人免税部分" field="endowmentIndvFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204216 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前失业个人免税部分" field="unemploymentIndvFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204217 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前医疗个人免税部分" field="medicalIndvFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204218 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前公积金个人免税部分" field="fundIndvFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204219 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="公积金单位计税部分" field="fundCompanyTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204483 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="养老个人扣款月次" field="endowmentIndvMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204220}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="失业个人扣款月次" field="unemploymentIndvMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204221}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="医疗个人扣款月次" field="medicalIndvMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204222}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="公积金个人扣款月次" field="fundIndvMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204223}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="应纳税所得额调增项" field="e204224" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204224 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前商报计税部分" field="commercialInsuranceTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204225 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前其他计税部分" field="otherTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204226 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="应纳税所得额调减项" field="e204227" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204227 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前捐赠免税部分" field="donationFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204228 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前其他免税部分" field="otherFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204229 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后五险一金减项" field="e204230" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204230 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除养老个人计税部分" field="endowmentIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204231 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除失业个人计税部分" field="unemploymentIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204232 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除医疗个人计税部分" field="medicalIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204233 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除公积金个人计税部分" field="fundIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204234 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后减项" field="e204235" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204235 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后应客户要求扣款（工资）" field="cusDeductionSalary" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204236 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除开户费" field="e204237" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204237 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后加项" field="e204238" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204238 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后增加独生子女补贴" field="e204239" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204239 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后增加煤火费补贴" field="e204240" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204240 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后增加其他免税补贴" field="e204241" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204241 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="应发金额" field="e204256" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204256 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="应税金额" field="e204261" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204261 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税率" field="e204263" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204263}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="工资速算扣除数" field="e204264" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204264 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税金" field="e204262" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204262 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="以往已交税金合计" field="e204363" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204363 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="实发金额" field="e204258" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204258 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="成本年终奖" field="e204247" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204247 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖往来" field="e204248" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{'3077821024,3077821030'.indexOf(scope.row.e204208) >= 0 ? scope.row.e204248 : null | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖年度" field="e204245" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204245}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖应发金额" field="e204246" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{ '3077821024,3077821030'.indexOf(scope.row.e204208) >= 0 ?  scope.row.e204246 : null | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖税率" field="e204252" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{ '3077821024,3077821030'.indexOf(scope.row.e204208) >= 0 ? scope.row.e204252 : null }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖税金" field="e204253" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204253 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖速算扣除数" field="e204249" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{ '3077821024,3077821030'.indexOf(scope.row.e204208) >= 0 ? scope.row.e204249 : null | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖实发金额" field="e204254" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{ '3077821024,3077821030'.indexOf(scope.row.e204208) >= 0 ? scope.row.e204254 : null | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖税前免税金额" field="e204250" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204250 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖应税金额" field="e204251" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204251 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖税前其他免税补贴" field="e204255" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204255 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖税后应客户要求扣款" field="e204386" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{'3077821024,3077821030'.indexOf(scope.row.e204208) >= 0 ? scope.row.e204386 : null| moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="代报销金额" field="e204387" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204387 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="离职年限" field="e204211" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204211}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="财务是否退回" field="e204353" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204353}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="财务退回原因" field="e204354" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204354}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="备注" field="e204260" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204260}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="姓名和开户名是否一致" field="isSameAccountnameEmpname" filter-type="select" set-id = '3084' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204420 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="邮件地址" field="e204349" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204349}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="使用对公余额" field="e204488" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204488 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="委托费成本（合计）" field="e204401" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204401 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计子女教育专项附加扣除额" field="e204602" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204602 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计继续教育专项附加扣除额" field="e204603" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204603 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计住房贷款利息专项附加扣除额" field="e204605" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204605 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计住房租金专项附加扣除额" field="e204606" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204606 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计赡养老人专项附加扣除额" field="e204607" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.e204607 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计婴幼儿照护专项附加扣除额" field="e204653" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.e204653 | moneyFilterStr}}</span></template>
      </bsp-table-column>
        <bsp-table-column title="个人养老金" field="e204660" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204660 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税金所属月份" field="e204492" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204492}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="rpa处理标志" field="e204616" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204616}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="支出单编号" field="paymentBillCode" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204201}}</span></template>
        </bsp-table-column>
      </div>

      <div v-if="listQuery.activeName === '1003' && this.show.show1003">
        <bsp-table-column title="客户名称" field="cusNames" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.cusNames}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="支出单编号" field="payoutBillsCode" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.payoutBillsCode}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="状态" field="status" filter-type="select" set-id='3500' width="100">
          <template slot-scope="scope"><span>{{scope.row.status | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="支出总额" field="total" filter-type="inputEq" width="100">
          <template slot-scope="scope"><span>{{scope.row.total | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="工行总额" field="icbcAmount" filter-type="inputEq" width="100">
          <template slot-scope="scope"><span>{{scope.row.icbcAmount | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="招行总额" field="cmbAmount" filter-type="inputEq" width="100">
          <template slot-scope="scope"><span>{{scope.row.cmbAmount | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="他行总额" field="otherAmount" filter-type="inputEq" width="100">
          <template slot-scope="scope"><span>{{scope.row.otherAmount | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="模式" field="batchMode" filter-type="select" set-id='3043' width="100">
          <template slot-scope="scope"><span>{{scope.row.batchMode | dictFilter(dicts) }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="发放方式" field="sendType" filter-type="select" set-id="3083" width="100">
          <template slot-scope="scope"><span>{{scope.row.sendType  | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="支付类别" field="payClass" filter-type="select" set-id="3301" width="100">
          <template slot-scope="scope"><span>{{scope.row.payClass  | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="是否薪资" field="ifWage" filter-type="select" set-id="0090" width="100">
          <template slot-scope="scope"><span>{{scope.row.ifWage | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="是否托收" field="collectionFlag" filter-type="select"  set-id="0090" width="100">
          <template slot-scope="scope"><span>{{scope.row.collectionFlag  | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="创建类型" field="createType" filter-type="select" set-id="3300" width="100">
          <template slot-scope="scope"><span>{{scope.row.createType | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="创建人" field="creatorId" width="100">
          <template slot-scope="scope"><span>{{scope.row.creatorId}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="审核人" field="examinerId" width="100">
          <template slot-scope="scope"><span>{{scope.row.examinerId}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="备注" field="remark" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.remark}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="是否合并报税" field="ifTaxGroup" filter-type="select" set-id="0090" width="100">
          <template slot-scope="scope"><span>{{scope.row.ifTaxGroup | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="新工行退票" field="newIcbcflag" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.newIcbcflag}}</span></template>
        </bsp-table-column>
      </div>

      <div v-if="listQuery.activeName === '1004' && this.show.show1004">
        <bsp-table-column title="客户名称" field="cusName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204399}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="发放方式" field="payWay" filter-type="select" set-id="3083" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204209 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="计税类型" field="taxType" filter-type="select" set-id="3077" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204208 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="报税名义" field="taxNominal" filter-type="select" set-id="3303" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204210 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员编号" field="empNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204343}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员姓名" field="empName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204344}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户名" field="accountName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204266}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="是否残疾人" field="isDisabled" filter-type="select"  set-id="0090" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204356 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="姓名和开户名是否一致" field="isSameAccountnameEmpname" filter-type="select"  set-id="0090" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204420 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="是否发送邮件" field="isSendEmail" filter-type="select"  set-id="0090" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204348 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="邮件地址" field="email" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204349}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员身份" field="national" filter-type="select" set-id='2127' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204345| dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="报税国籍" field="declNat" filter-type="select" set-id='3082' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204346 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="证件类型" field="identityType" filter-type="select" set-id='3046' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204350 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员证件号码" field="identityNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204347}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="银行类别" field="bankType" filter-type="select" set-id='3084' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204265 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户账号" field="accountNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204267}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="账号地区" field="bankAccountArea" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204269}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户银行全称" field="bankFullName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204268}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="工行地区代码" field="ibicAreNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204270}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="工资劳务费年份" field="wageYear" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204212}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="工资劳务费月份" field="wageMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204213}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="应发金额" field="payAmount" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204256 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="应发补贴" field="paySubsidy" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204257 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前商保计税部分" field="commercialInsuranceTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204225 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前其他计税部分" field="otherTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204226 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前捐赠免税部分" field="e204228" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204228 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前其他免税部分" field="otherFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204229 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="公积金单位计税部分" field="fundCompanyTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204483 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="成本工资" field="e204243" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204243 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="工资往来" field="wagesExchangesCal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204244 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前养老个人计税部分" field="endowmentIndvFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204216 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="养老个人扣款月次" field="endowmentIndvMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204220}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前失业个人免税部分" field="unemploymentIndvFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204217 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="失业个人扣款月次" field="unemploymentIndvMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204221}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前医疗个人免税部分" field="medicalIndvFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204218 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="医疗个人扣款月次" field="medicalIndvMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204222}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前公积金个人免税部分" field="fundIndvFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204219 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="公积金个人扣款月次" field="fundIndvMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204223}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="薪资计税区域" field="taxArea" filter-type="select" set-id='3202' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204215 |  dictFilter(dicts) }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税率" field="taxRate" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204263}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="应税金额" field="e204261" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204261 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="税金" field="thisActTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204262 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后应客户要求扣款（工资）" field="cusDeductionSalary" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204236 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="税后扣除开户费" field="e204237" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204237 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="税后扣除养老个人计税部分" field="endowmentIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204231 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除失业个人计税部分" field="medicalIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204232 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除医疗个人计税部分" field="unemploymentIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204233 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除公积金个人计税部分" field="fundIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204234 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后增加独生子女补贴" field="addOnlyChildSubsidy" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204239 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="税后增加煤火费补贴" field="e204240" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204240 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->

        <bsp-table-column title="税后增加其他免税补贴" field="addFreeSubsidy" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204241 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="财务转款时间" field="gzDate" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204368}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="核算月份" field="checkMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204272}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税金缴纳月份" field="taxDeclareMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204398}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="企业年金税前免税部分" field="enterpriseAnnuityFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204497 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="实发金额" field="actPayAmount" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204258 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="凭证号" field="voucherId" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204370}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="备注" field="remark" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204260}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="个税起征点" field="e204259" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204259 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="发工资负责人" field="businessLeaderName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204397}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="财务是否退回" field="e204353" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204353}}</span></template>-->
<!--        </bsp-table-column>-->
<!--        <bsp-table-column title="财务退回原因" field="e204354" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204354}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="累计子女教育专项附加扣除额" field="childEduAdditionalTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204602 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计继续教育专项附加扣除额" field="continuingEduAdditionalTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204603 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计住房贷款利息专项附加扣除额" field="mortgagAdditionalTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204605 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计住房租金专项附加扣除额" field="rentAdditionalTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204606 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计赡养老人专项附加扣除额" field="supportElderlyAdditionalTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204607 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计婴幼儿照护专项附加扣除额" field="e204653" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204653 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="个人养老金" field="e204660" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204660 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="rpa处理标志" field="e204616" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204616}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="支出单编号" field="payoutBillCode" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204201}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计税金" field="allTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204612 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计薪资收入额" field="allIncomeTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204593 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计应纳税所得额（不减专项附加扣除）" field="allTaxIncomeTotalNoSpecial" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204601 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="累计税前五险一金免税金额合计" field="e204600" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204600 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->
<!--        <bsp-table-column title="累计实发金额" field="e204599" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204599 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="累计专项附加扣除合计" field="allSpecialAdditionalTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204611 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计应纳税所得额" field="allTaxIncomeTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204592 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计起征点扣除" field="costReducationTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204594 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="减免税额" field="taxTaxFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204634 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计减免税额" field="allTaxFreeTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204635 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="以往累计减免税额" field="preTaxFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204636 | moneyFilterStr}}</span></template>
        </bsp-table-column>
      </div>

      <div v-if="listQuery.activeName === '1005' && this.show.show1005">
        <bsp-table-column title="客户名称" field="cusName" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.f001200}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户名" field="accountName" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206203}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户账户" field="accountNo" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206204}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="银行全称" field="bankName" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206205}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="账号地区" field="accountArea" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206206}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="实发金额" field="amount" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206209 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="证件类型" field="idType" filter-type="select" set-id='3046' >
          <template slot-scope="scope"><span>{{scope.row.e204350 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员证件号码" field="idCard" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e204347}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="交易附言" field="transacRemark" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206222}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="支出单编号" field="payoutBillCode" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e204201}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="rpa处理标志" field="e204616" filter-type="input">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204616}}</span></template>-->
<!--        </bsp-table-column>-->

      </div>

      <div v-if="listQuery.activeName === '1006' && this.show.show1006">
        <bsp-table-column title="网银退票编号" field="bankNO" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.bankNO}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户名" field="accountName" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.accountName}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户账号" field="accountNO" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.accountNO}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="银行类型" field="bankType" filter-type="select" set-id='3084'>
          <template slot-scope="scope"><span>{{scope.row.bankType | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="退票日期" field="recordTime" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.recordTime}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="金额" field="money" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.money | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="交易明细总金额" field="paymentTotal" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.paymentTotal | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="退票原因" field="feedback" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.feedback}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="退票银行" field="bankName" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.bankName}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="数据更新时间" field="modifyTime" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.modifyTime}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="状态" field="status" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.status }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="退票录入人" field="recorder" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.recorder}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="原纪录编号" field="baseSubid" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.baseSubid}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="支付单编号" field="payCode" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.payCode}}</span></template>
        </bsp-table-column>

      </div>

      <div v-if="listQuery.activeName === '1007' && this.show.show1007">
        <bsp-table-column title="支付银行" field="bank" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.bank}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="支付时间" field="payDate" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.payDate}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="支付金额" field="money" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.money | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="结算方式" field="payMode" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.payMode | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="支票号" field="checkNo" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.checkNo}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="摘要" field="remark" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.remark}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="币种" field="currency" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.currency | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="金额" field="amount" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.amount | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="备注信息" field="memo" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.memo}}</span></template>
        </bsp-table-column>
      </div>

      <div v-if="listQuery.activeName === '1008' && this.show.show1008">
        <bsp-table-column title="支出单编号" field="payoutBillCode" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e204201}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户名" field="e206203" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206203}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户账号" field="e206204" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206204}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="银行全称" field="e206205" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206205}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="账号地区" field="e206206" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206206}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="实发金额" field="e206209" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206209 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="网银序列号" field="e206211" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206211}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="rpa处理标志" field="e204616" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e204616}}</span></template>
        </bsp-table-column>
      </div>

      <div v-if="listQuery.activeName === '1009' && this.show.show1009">
        <bsp-table-column title="开户名" field="e206203" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206203}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户账号" field="e206204" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206204}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="银行全称" field="e206205" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206205}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="账号地区" field="e206206" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206206}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="实发金额" field="e206209" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206209 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="客户id" field="e204399" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e204399}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="证件类型" field="e204350" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e204350}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员证件号码" field="e204347" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e204347}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="交易附言" field="e206222" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206222}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="支出单编号" field="payoutBillCode" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e204201}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="rpa处理标志" field="e204616" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e204616}}</span></template>
        </bsp-table-column>
      </div>

      <div v-if="listQuery.activeName === '1010' && this.show.show1010">
        <bsp-table-column title="开户名" field="e206203" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206203}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户账号" field="e206204" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206204}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="银行全称" field="e206205" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206205}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="账号地区" field="e206206" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206206}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="实发金额" field="e206209" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206209 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="证件类型" field="e204350" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e204350}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员证件号码" field="e204347" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e204347}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="客户id" field="e204399" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e204399}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="交易附言" field="e206222" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206222}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="支出单编号" field="payoutBillCode" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e204201}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="rpa处理标志" field="e204616" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e204616}}</span></template>
        </bsp-table-column>
      </div>

      <div v-if="listQuery.activeName === '1011' && this.show.show1011">
        <bsp-table-column title="客户名称" field="cusId" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204399}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="发放方式" field="payWay" filter-type="select" set-id='3083' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204209}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="收款来源" field="e204355" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204355}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="雇员编号" field="empNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204343}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员姓名" field="empName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204344}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="姓名与开户名是否一致" field="isSameAccountnameEmpname" filter-type="select" set-id='0090' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204420 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="是否发送邮件" field="isSendEmail" filter-type="select" set-id='0090'   width="100">
          <template slot-scope="scope"><span>{{scope.row.e204348}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="邮件地址" field="email" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204349}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员身份" field="national" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204345 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="报税国籍" field="declNat" filter-type="select" set-id='3082'  width="100">
          <template slot-scope="scope"><span>{{scope.row.e204346 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="证件类型" field="identityType" filter-type="select" set-id='3046' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204350| dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员证件号码" field="identityNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204347}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="任职受雇日期" field="entryDate" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204608}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="银行类别" field="bankType" filter-type="select" set-id='3084' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204265 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户名" field="accountName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204266}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户账号" field="accountNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204267}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="账号地区" field="bankAccountArea" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204269}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户银行全称" field="bankFullName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204268}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="工行地区代码" field="ibicAreNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204270}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="财务转款时间" field="gzDate" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204368}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="核算月份" field="checkMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204272}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="代报销往来" field="reimburseExchangesCal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204403 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="代报销金额" field="reimbursementAmount" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204387 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="凭证号" field="voucherId" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204370}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="备注" field="remark" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204260}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="发工资负责人" field="businessLeaderName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204397}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="财务是否退回" field="e204353" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204353}}</span></template>-->
<!--        </bsp-table-column>-->
<!--        <bsp-table-column title="财务退回原因" field="e204354" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204354}}</span></template>-->
<!--        </bsp-table-column>-->
<!--        <bsp-table-column title="rpa处理标志" field="e204616" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204616}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="支出单编号" field="payoutBillCode" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204201}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="离职日期" field="leaveDate" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204618}}</span></template>
        </bsp-table-column>
      </div>

      <div v-if="listQuery.activeName === '1012' && this.show.show1012">
        <bsp-table-column title="支付单ID" field="paymentBillId" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204200}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户名" field="accountName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204266}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员编号" field="empNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204343}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员姓名" field="empName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204344}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="客户名称" field="cusName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204399}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="姓名和开户名是否一致" field="isSameAccountnameEmpname" filter-type="select" set-id='0090' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204420| dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="是否是残疾人" field="isDisabled" filter-type="select" set-id='0090' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204356 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="是否发送邮件" field="isSendEmail" filter-type="select" set-id='0090' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204348 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="邮件地址" field="email" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204349}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员证件号码" field="identityNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204347}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="银行类别" field="bankType" filter-type="select" set-id='3084' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204265 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="报税国籍" field="declNat" filter-type="select" set-id='3082' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204346 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="证件类型" field="identityType" filter-type="select" set-id='3046' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204350 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="计税类型" field="taxType" filter-type="select" set-id  = '3077' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204208 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="报税名义" field="taxNominal" filter-type="select" set-id='3303' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204210 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="收款来源" field="e204355" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204355}}</span></template>-->
<!--        </bsp-table-column>-->

        <bsp-table-column title="发放方式" field="payWay" filter-type="select" set-id='3083' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204209 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员身份" field="national" filter-type="select" set-id='2127' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204345 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户账号" field="accountNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204267}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="账号地区" field="bankAccountArea" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204269}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户银行全称" field="bankFullName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204268}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="工行地区代码" field="ibicAreNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204270}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="工资劳务费年份" field="wageYear" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204212}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="工资劳务费月份" field="wageMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204213}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="应发金额" field="payAmount" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204256}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="应发补贴" field="paySubsidy" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204257}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前商保计税部分" field="commercialInsuranceTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204225}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前其他计税部分" field="otherTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204226}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前捐赠免税部分" field="donationFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204228}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前其他免税部分" field="otherFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204229}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="公积金单位计税部分" field="fundCompanyTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204483}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="成本工资" field="e204243" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204243}}</span></template>-->
<!--        </bsp-table-column>-->

        <bsp-table-column title="工资往来" field="wagesExchangesCal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204244}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前养老个人免税部分" field="endowmentIndvFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204216}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="养老个人扣款月次" field="endowmentIndvMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204220}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前失业个人免税部分" field="unemploymentIndvFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204217}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="失业个人扣款月次" field="unemploymentIndvMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204221}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前医疗个人免税部分" field="medicalIndvFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204218}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="医疗个人扣款月次" field="medicalIndvMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204222}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前公积金个人免税部分" field="fundIndvFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204219}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="公积金个人扣款月次" field="fundIndvMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204223}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="薪资计税区域" field="taxArea" filter-type="select"  set-id='3202' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204215| dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税率" field="taxRate" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204263}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="应税金额" field="e204261" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204261}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="税金" field="thisActTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204262}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后应客户要求扣款(工资)" field="cusDeductionSalary" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204236}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="税后扣除开户费" field="e204237" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204237}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="税后扣除养老个人计税部分" field="endowmentIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204231}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="税后扣除失业个人计税部分" field="medicalIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204232}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除医疗个人计税部分" field="unemploymentIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204233}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除公积金个人计税部分" field="fundIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204234}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后增加独生子女补贴" field="addOnlyChildSubsidy" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204239}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="税后增加煤火费补贴" field="e204240" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204240}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="税后增加其他免税补贴" field="addFreeSubsidy" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204241}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="实发金额" field="actPayAmount" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204258}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="财务转款时间" field="gzDate" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204368}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="核算月份" field="checkMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204272}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税金缴纳月份" field="taxDeclareMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204398}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="凭证号" field="voucherId" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204370}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="备注" field="remark" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204260}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="个税起征点" field="e204259" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204259}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="发工资负责人" field="businessLeaderName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204397}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="财务是否退回" field="e204353" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204353}}</span></template>-->
<!--        </bsp-table-column>-->
<!--        <bsp-table-column title="财务退回原因" field="e204354" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204354}}</span></template>-->
<!--        </bsp-table-column>-->

        <bsp-table-column title="累计子女教育专项附加扣除额" field="childEduAdditionalTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204602}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计继续教育专项附加扣除额" field="continuingEduAdditionalTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204603}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计住房贷款利息专项附加扣除额" field="mortgagAdditionalTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204605}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计住房租金专项附加扣除额" field="rentAdditionalTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204606}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计赡养老人专项附加扣除额" field="supportElderlyAdditionalTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204607}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计婴幼儿照护专项附加扣除额" field="e204653" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204653 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="个人养老金" field="e204660" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204660 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="rpa处理标志" field="e204616" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204616}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="支出单编号" field="payoutBillCode" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204201}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计税金" field="allTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204612}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计专项附加扣除合计" field="allSpecialAdditionalTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204611}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计应纳税所得额（不减专项附加扣除）" field="allTaxIncomeTotalNoSpecial" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204601}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计薪资收入额" field="allIncomeTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204593}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计应纳税所得额" field="allTaxIncomeTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204592}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="累计起征点扣除" field="costReducationTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204594}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="累计实发金额" field="e204599" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204599}}</span></template>-->
<!--        </bsp-table-column>-->
<!--        <bsp-table-column title="累计税前五险一金免税金额合计 " field="e204600" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204600}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="减免税额" field="taxTaxFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204634}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="累计减免税额" field="allTaxFreeTotal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204635}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="以往累计减免税额" field="preTaxFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204636}}</span></template>
        </bsp-table-column>
      </div>

      <div v-if="listQuery.activeName === '1013' && this.show.show1013">
        <bsp-table-column title="客户名称" field="cusName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204399}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="发放方式" field="payWay" filter-type="select" set-id='3083' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204209 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="计税类型" field="taxType" filter-type="select" set-id='3077' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204208 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="报税名义" field="taxNominal" filter-type="select" set-id='3303' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204210 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="收款来源" field="e204355" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204355}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="雇员编号" field="empNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204343}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员姓名" field="empName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204344}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户名" field="accountName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204266}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="姓名和开户名是否一致" field="isSameAccountnameEmpname" filter-type="select" set-id='0090' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204420 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="是否是残疾人" field="isDisabled" filter-type="select" set-id='0090' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204356 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="是否发送邮件" field="isSendEmail" filter-type="select" set-id='0090' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204348 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="邮件地址" field="email" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204349}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员身份" field="national" filter-type="select" set-id='2127' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204345 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="报税国籍" field="declNat" filter-type="select" set-id='3082' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204346 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="证件类型" field="identityType" filter-type="select" set-id='3046' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204350 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员证件号码" field="identityNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204347}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="银行类别" field="bankType" filter-type="select" set-id='3084' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204265| dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户账号" field="accountNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204267}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="账号地区" field="bankAccountArea" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204269}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户银行全称" field="bankFullName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204268}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="工行地区代码" field="ibicAreNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204270}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖年度" field="wageMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204245}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖应发金额" field="payAmount" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204246 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="成本年终奖" field="e204247" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204247 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="年终奖往来" field="wagesExchangesCal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204248 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖税率" field="taxRate" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204252}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖应税金额" field="thisNeedTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204251 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="年终奖应税金额（报税）" field="e204481" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204481 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="年终奖税金" field="thisActTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204253 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖税后应客户要求扣款" field="cusDeductionSalary" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204386 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖税前免税金额" field="enterpriseAnnuityFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204250 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="年终奖税前其他免税补贴" field="e204255" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204255 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="税前捐赠免税部分" field="donationFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204228 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖实发金额" field="actPayAmount" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204254 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="财务转款时间" field="gzDate" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204368}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="核算月份" field="checkMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204272}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税金缴纳月份" field="taxDeclareMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204398}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="凭证号" field="voucherId" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204370}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="备注" field="remark" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204260}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="发工资负责人" field="businessLeaderName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204397}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="财务是否退回" field="e204353" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204353 | dictFilter(dicts)}}</span></template>-->
<!--        </bsp-table-column>-->
<!--        <bsp-table-column title="财务退回原因" field="e204354" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204354}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="养老个人缴纳部分" field="thisEndowmentIndv" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204430 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="医疗个人缴纳部分" field="thisMedicalIndv" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204431 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="失业个人缴纳部分" field="thisUnemploymentIndv" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204432 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="住房公积金个人缴纳部分" field="thisFundIndv" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204433 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前商保计税部分" field="commercialInsuranceTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204225 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前其他计税部分" field="otherTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204226 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前其他免税部分" field="otherFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204229 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除养老个人计税部分" field="endowmentIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204231 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除失业个人计税部分" field="medicalIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204232 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除医疗个人计税部分" field="unemploymentIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204233 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除公积金个人计税部分" field="fundIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204234 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="税后扣除开户费" field="e204237" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204237 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="税后增加独生子女补贴" field="addOnlyChildSubsidy" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204239 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="税后增加煤火费补贴" field="e204240" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204240 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="税后增加其他免税补贴" field="addFreeSubsidy" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.E204241 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="rpa处理标志" field="e204616" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204616}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="支出单编号" field="payoutBillCode" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204201}}</span></template>
        </bsp-table-column>
      </div>

       <div v-if="listQuery.activeName === '1016' && this.show.show1016">
        <bsp-table-column title="客户名称" field="cusName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204399}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="发放方式" field="payWay" filter-type="select" set-id="3083" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204209| dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="计税类型" field="taxType" filter-type="select" set-id  = '3077' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204208| dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="报税名义" field="taxNominal" filter-type="select" set-id  = '3303' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204210| dictFilter(dicts)}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="收款来源" field="e204355" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204355}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="雇员编号" field="empNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204343}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员姓名" field="empName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204344}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户名" field="accountName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204266}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="姓名和开户名是否一致" field="isSameAccountnameEmpname" filter-type="select" set-id = '3084' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204420| dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="是否是残疾人" field="isDisabled" filter-type="select" set-id = '3084' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204356 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="是否发送邮件" field="isSendEmail" filter-type="select" set-id = '3084' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204348 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="邮件地址" field="email" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204349}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员身份" field="national" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204345 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="报税国籍" field="declNat" filter-type="select" set-id='3082' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204346 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="证件类型" field="identityType" filter-type="select" set-id='3046' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204350 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="雇员证件号码" field="identityNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204347}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="银行类别" field="bankType" filter-type="select" set-id = '3084' width="100">
          <template slot-scope="scope"><span>{{scope.row.e204265 | dictFilter(dicts)}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户账号" field="accountNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204267}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="账号地区" field="bankAccountArea" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204269}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户银行全称" field="bankFullName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204268}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="工行地区代码" field="ibicAreNum" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204270}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖年度" field="wageMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204245}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖应发金额" field="payAmount" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204246 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="成本年终奖" field="e204247" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204247 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="年终奖往来" field="wagesExchangesCal" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204248 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖税率" field="taxRate" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204252}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖应税金额" field="thisNeedTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204251 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="年终奖应税金额（报税）" field="e204481" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204481 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="年终奖税金" field="thisActTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204253 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖税后应客户要求扣款" field="cusDeductionSalary" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204386 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖税前免税金额" field="enterpriseAnnuityFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204250 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="年终奖税前其他免税补贴" field="e204255" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204255 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="税前捐赠免税部分" field="donationFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204228 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="年终奖实发金额" field="actPayAmount" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204254 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="财务转款时间" field="gzDate" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204368}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="核算月份" field="checkMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204272}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税金缴纳月份" field="taxDeclareMonth" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204398}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="凭证号" field="voucherId" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204370}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="备注" field="remark" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204260}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="发工资负责人" field="businessLeaderName" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204397}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="财务是否退回" field="e204353" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204353}}</span></template>-->
<!--        </bsp-table-column>-->
<!--        <bsp-table-column title="财务退回原因" field="e204354" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204354}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="养老个人缴纳部分" field="thisEndowmentIndv" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204430 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="医疗个人缴纳部分" field="thisMedicalIndv" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204431 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="失业个人缴纳部分" field="thisUnemploymentIndv" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204432 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="住房公积金个人缴纳部分" field="thisFundIndv" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204433 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前商保计税部分" field="commercialInsuranceTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204225 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前其他计税部分" field="otherTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204226 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税前其他免税部分" field="otherFree" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204229 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除养老个人计税部分" field="endowmentIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204231 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除失业个人计税部分" field="medicalIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204232 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除医疗个人计税部分" field="unemploymentIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204233 | moneyFilterStr}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="税后扣除公积金个人计税部分" field="fundIndvTax" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204234 | moneyFilterStr}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="税后扣除开户费" field="e204237" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204237 | moneyFilterStr}}</span></template>-->
<!--        </bsp-table-column>-->
<!--        <bsp-table-column title="rpa处理标志" field="e204616" filter-type="input" width="100">-->
<!--          <template slot-scope="scope"><span>{{scope.row.e204616}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="支出单编号" field="payoutBillCode" filter-type="input" width="100">
          <template slot-scope="scope"><span>{{scope.row.e204201}}</span></template>
        </bsp-table-column>
      </div>

        <div v-if="listQuery.activeName === '1017' && this.show.show1017">
        <bsp-table-column title="开户帐号" field="accountNo" filter-type="input" >
          <template slot-scope="scope"><span>{{scope.row.e206204}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="开户名" field="accountName" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206203}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="银行全称" field="bankName" filter-type="input" >
          <template slot-scope="scope"><span>{{scope.row.e206205}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="帐号地区" field="accountArea" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.e206206}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="客户名称" field="cusName" filter-type="input"  >
          <template slot-scope="scope"><span>{{scope.row.e206215}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="交易附言" field="transacRemark" filter-type="input" >
          <template slot-scope="scope"><span>{{scope.row.e206222}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="实发金额" field="amount" filter-type="input" >
          <template slot-scope="scope"><span>{{scope.row.e206209 | moneyFilterStr}}</span></template>
        </bsp-table-column>
      </div>

    </bsp-table>

  </div>
</template>
<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {
    searchTabs,
    searchPaymentDetailInfo,
    exportDetail
  } from '@/api/payment/payment';
  import {mapGetters} from 'vuex';

  export default {
    name: 'bankDirectDetail',
    inject: ['reload'],
    components: {
      BspTable,
      BspTableColumn,
    },
    computed: {
      ...mapGetters([
        'dicts'
      ]),
    },
    // props: {
    //   currentTab: {
    //     type: String,
    //     default: ''
    //   },

    // },
    created() {
      this.searchTabs();
      console.log("报税名义==============>",this.dicts.codeMap,this.dicts.codeList);
    },
    data() {
      return {
        // 下拉选中的银行类别
        bankTypes: [],
        // 根据银行类别选取的银行信息
        bankInfos: [],
        // 用于接收后台返回的分页数据
        list: [],
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          paymentNo: '',
          paymentStatus: '',
          accountingMonth: '',
          activeName: '',
          paymentType: '0',
          paymentId: this.$route.query.paymentId,
          payWageClass: this.$route.query.payWageClass,
          createType: this.$route.query.createType,
          accountType:this.$route.query.accountType
        },
        loading: false,
        payClass:this.$route.query.payClass,
        llQuery: this.$route.query.listQuery,
        payCode: this.$route.query.payCode,
        accountType: this.$route.query.accountType,
        currentTab: this.$route.query.currentTab,
        bo: {},
        tabs: [],
        show: {
          show1001: false,
          show1002: false,
          show1003: false,
          show1004: false,
          show1005: false,
          show1006: false,
          show1007: false,
          show1008: false,
          show1009: false,
          show1010: false,
          show1011: false,
          show1012: false,
          show1013: false,
          show1016: false,
          show1017: false,
        },
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        startAndendDate: '',
        refresh:true
      }
    },
    methods: {

      back: function() {
        this.$router.push({path: '/wt/paymentDetail', query: {listQuery: this.llQuery,currentTab :this.currentTab }})
      },

      searchTabs: function() {
        this.loading  =true;
        searchTabs(this.listQuery).then(res => {
          console.log(this.listQuery)
          this.tabs = res.data;
          this.listQuery.activeName = this.tabs[0];
          for (let i in this.show) {
            for (let j in this.tabs) {
              if (i.substring(4) === this.tabs[j]) {
                this.show[i] = true;
                break;
              }
            }
          }
          this.searchDetail();
          this.loading  =false;
        })

      },

      handleClick(tab, event) {

        this.$set(this.listQuery,"filters", [])
        for(let key in this.$refs.publicTable.filtersStore){
          delete this.$refs.publicTable.filtersStore[key].value;
        }
        this.$refs.publicTable.$refs.ciicTable.clearFilter();
        this.refresh = false;
        this.searchDetail();
      },

      // 打印财务公司支出单
      printPaymentSlip: function (obj) {
        let page = this.$router.resolve({path: '/wt/printPaymentSlip', query: {paymentId: this.listQuery.paymentId}})
        window.open(page.href,'_blank');

      },
      // 打印退票通知书
      printRefundNotice: function (obj) {
        let page = this.$router.resolve({path: '/wt/printRefundNotice', query: {paymentId: this.listQuery.paymentId}})
        window.open(page.href,'_blank');
      },
      // 打印雇员明细
      printEmployeeDetail: function (obj) {
        let page = this.$router.resolve({path: '/wt/printEmployeeDetail', query: {paymentId: this.listQuery.paymentId, payWageClass: this.listQuery.payWageClass}})
        window.open(page.href,'_blank');
      },
      // 导出明细
      exportDetail: function () {
        exportDetail(this.listQuery).then(res => {
          debugger
          /*if (res.data.size === 0) {
            alert("无导出数据");
            return;
          }*/
          const url = window.URL.createObjectURL(res.data);
          const link = document.createElement('a');
          link.href = url;
          const fileName = `${res.headers['content-disposition'].replace('attachment;filename=','')}`;
          let channelUrl=decodeURIComponent(fileName);
          link.setAttribute('download', channelUrl);
          link.click()
        })
      },


      // 查看支付单明细
      searchDetail: function () {
        searchPaymentDetailInfo(this.listQuery).then(res => {
          this.bo = res.data.data;
          this.list = [];
          this.list = res.data.records;
          this.listQuery.total = res.data.total;
          this.refresh = true;
        })
      },

      // 打印对公支付单
      printPublicPayment: function (obj) {

        let page = this.$router.resolve({path: '/wt/printPublicPayment', query: {paymentId: this.listQuery.paymentId,payCode: this.payCode}})
        window.open(page.href,'_blank');
        console.log(this.payCode)

      },
       // 打印对公支付单明细
      printPublicPaymentDetail: function (obj) {

        let page = this.$router.resolve({path: '/wt/printPublicPaymentDetail', query: {paymentId: this.listQuery.paymentId,payCode: this.payCode}})
        window.open(page.href,'_blank');
        console.log(this.payCode)

      },
    }
  }
</script>
<style scoped>

  .editform {
    margin: 0;
  }
</style>
