<template>
  <div>
    <div class="divButtonRigth">

      <el-button type="primary" size="small" fixed="right"

                 @click="settingStatus(1)">启用
      </el-button>

      <el-button type="primary" size="small" fixed="right"

                 @click="settingStatus(0)">禁用
      </el-button>
    </div>


    <bsp-table
      :data.sync="list"
      :tree-config="true"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="__getTaxAreaList"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
      height="89%"
      @select-all="selectAll"
      @select-change="selectChange"
    >

      <vxe-table-column type="selection" width="60" fixed="left"/>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <bsp-table-column title="计税区域id" field="id" filter-type="input" width="100" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.id}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="机构名称" field="orgName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.orgName}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="计税区域"  field="area" width="100"
                        filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.area}}</span></template>
      </bsp-table-column>


      <!--      <bsp-table-column title="状态" field="status" fixed="left" filter-type="input">-->
      <!--        &lt;!&ndash; filter-type="select"    filter-rule="in"   set-id="3004"&ndash;&gt;-->
      <!--        <template slot-scope="scope"><span>{{scope.row.status ==1 ?'启用':'禁用'}}</span></template>-->
      <!--        &lt;!&ndash;statusName | dictFilter(dicts)&ndash;&gt;-->
      <!--      </bsp-table-column>-->

      <bsp-table-column field="status" title="状态"  filter-type="select" :optionsList="statusList" :optionProps="{value: 'value', label: 'label'}">
        <template slot-scope="scope">
          <span>{{scope.row.status ==1 ?'启用':'禁用'}}</span>
        </template>
      </bsp-table-column>

    </bsp-table>




  </div>
</template>
<script>
import {getTaxAreaList,settingStatus} from "@/api/taxArea/taxArea";
import BspTable from "@/components/bsp/bsp-table/bsp-table";
import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
import {deleteImportBatch} from "@/api/entrust/detailImport";
export default {
  name: 'taxAreaList',
  inject: ['reload'],
  components: {
    BspTable,
    BspTableColumn
  },
  computed: {

  },
  props: {
    currentTab: {
      type: String,
      default: ''
    },

  },
  created() {

    this.__getTaxAreaList();
  },

  data() {
    return {

      // 遮罩
      loading: false,
      // 用于接收后台返回的分页数据
      list: [],
      detailVisible: 'false',
      //分页查询对象
      listQuery: {
        limit: 50,
        page: 1,
        total: 0,
        transfer:this.currentTab
      },
      parameter: {
        status: 1,
        ids:'',
        transfer: this.currentTab
      },
      //多选数组
      multipleSelection: [],
      statusList: [{
        value: '1',
        label: '启用'
      }, {
        value: '0',
        label: '禁用'
      }],

    }
  },
  methods: {
    __getTaxAreaList: function () {
      getTaxAreaList(this.listQuery).then(res => {
        if (res.data.code != 0) {
          this.listQuery.total = res.data.total
          this.list = res.data.records
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },
    selectChange(value) {
      console.log(value);
      if (value.selection) {
        this.multipleSelection = [];
        for (let index = 0; index < value.selection.length; index++) {
          const element = value.selection[index];
          this.multipleSelection.push(element);
        }
      }
    },
    settingStatus: function (status) {
      if(this.multipleSelection.length != 0){
        //将选中的id组装为string
        this.parameter.ids = this.multipleSelection.map(item => `'${item.id}'`).join(',');

        this.parameter.status = status;
        settingStatus(this.parameter).then(res => {
          this.loading = false;
          this.isDisabled = false;
          if (res.status==200) {
            this.$message.success("修改成功");
          } else {
            this.$message.error("修改失败");
          }
          this.__getTaxAreaList();
          this.parameter.ids = '';
        })
      }else{
        this.$message.error("请至少选中一条数据进行操作");
      }
    },

    //复选框全选事件
    selectAll({checked, selection}) {
      if (checked) {
        this.multipleSelection = selection
      } else {
        this.multipleSelection = [];
      }
      console.log("this.multipleSelection:" + this.multipleSelection);
    },
  }
}
</script>
<style scoped>
.copy-btn {
  cursor: pointer;
}

.ciic-table-operate {
  align-items: center;
}

.editForm {
  margin: 0;
}

.editForm .el-form-item {
  margin: 0;
}
</style>
<style>
.winClass {
  width: 50% !important;
  height: 60% !important;
}

.el-message-box__content {
  overflow-y: auto !important;
  height: 80% !important;
}
</style>
