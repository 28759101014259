<template>
  <div class="outer-layer">
    <bsp-tabs :tabList="tabList" :tabIndex="tabIndex" @changeTab="changeTab">
      <component  v-if="isRouterAlive" ref="pub" :is="currentTab" :currentTab='currentTab' :batchStatus="batchStatus"></component>
    </bsp-tabs>
  </div>
</template>
<script>
  import bspTabs from "@/components/bsp/bsp-tabs/bsp-tabs";
  import detailBatchList from "@/views/cus/leads/leadsList";
  export default {
    created(){
      //TODO 此处增加对业务类型显示
    },
    data() {
      var  bizType  = this.$route.query.currentTab ? this.$route.query.currentTab:'all';
      return {
        tabList: [{
          title: '全部线索',
          name: 'all'
        },
        // },{
        //   title: '我的线索',
        //   name: '00900'
        // },{
          {
          title: '已转客户',
          name: '00901'
        }],
        tabIndex: bizType,
        currentTab:bizType,
        isRouterAlive:true,
      }
    },
    provide(){
      return{
        reload:this.reload
      }
    },
    components:{
      bspTabs,
      'all': detailBatchList,
      '00901': detailBatchList,
      //31278300703: applyList,
    },
    methods: {
      changeTab(tab, event) {
        this.currentTab = tab.name;
        this.reload();
        console.log("hello everybody~~~this.currentTab==");
        console.log(this.currentTab);
      },
      reload (){
        this.isRouterAlive = false
        this.$nextTick(function(){
          this.isRouterAlive = true
        })
      }
    }
  }
</script>
