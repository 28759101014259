<!-- 下拉选择器 -->
<template>
  <el-cascader
    ref="cascader"
    :options="options"
    v-model="cascaderValue"
    v-bind = "{...$attrs}"
    v-on="{...$listeners}"
    :props="{ checkStrictly: checkStrictly }"
    clearable
    @change="selectChange"
  ></el-cascader>
</template>

<script>
  import { mapGetters } from 'vuex';
  import {deepCopy} from "../../../util/util";
  import {getCodeTreeByCodeSetId} from "@/api/common/common";

  export default {
    name: 'bsp-dicts-cascader',
    props: {
      //当前选的值
      cascaderValue: {
        type:String,
        default:''
      },
      // 代码集setId
      dicNum: {
        type: String,
        default: ''
      },
      // 要移除的 itemId,隔开多个
      remNum: {
        type: String,
        default: ''
      },

      // 单选这选数据
      optionsList: {
          type: Array,
          default: () => (
              []
          )
      },
      checkStrictly:{
        type:Boolean,
        default:false
      }
    },
    data() {
      return {
          // 下拉选数据
          options: [],
      }
    },
    computed: {
      ...mapGetters([
        'dicts',
      ]),
    },
    watch: {

    },
    created() {
      if(typeof(this.cascaderValue)=='string'){
        if(this.cascaderValue){
          this.cascaderValue = this.cascaderValue.split(",");
        }
      }
     // console.log("级联值1：",this.cascaderValue.split(","));
      console.log("级联值：",this.cascaderValue)
      console.log("this.optionsList：",this.optionsList)
      //  从后台获取的dicts
      if(this.optionsList.length > 0){
        this.options = this.optionsList;
        // this.cascaderBoundEvent();
      }else {
        this.getCodeTree()
      }
    },
    mounted() {

    },
    methods: {
      // // 给cascader绑定鼠标 移入移除事件  实现hover无二级菜单时隐藏二级菜单
      // cascaderBoundEvent() {
      //   let cascaderNodes = document.querySelectorAll('.el-cascader-node');
      //   for (let i = 0; i < cascaderNodes.length; i++) {
      //     cascaderNodes[i].click = (e) => {
      //       this.clickChange(cascaderNodes[i]);
      //     }
      //   }
      // },
      // clickChange(node){
      //   // 获取一级菜单的三角符号 如果有 证明存在二级菜单 否则无二级菜单
      //   let isChildrenNode = node.querySelector('.el-icon-arrow-right');
      //   // 一级下拉框和二级下拉框集合
      //   let cascaderMenus = document.querySelectorAll('.el-cascader-menu');
      //   if (isChildrenNode) {
      //     // 二级下拉框出现
      //     cascaderMenus[1].style.display = 'block';
      //   } else {
      //     cascaderMenus[1].style.display = 'none';
      //   }
      // },
      getCodeTree(){
        console.log("代码集")
        console.log(this.dicNum)
        getCodeTreeByCodeSetId(this.dicNum,'cus').then(res =>{
          console.log("级联值================",res);
          this.options =res.data.data;
        })
      },
      initData(){

      },
      //  预处理下拉选的list
      handleDictc(list, remNum){
          let remNumArrary = []
          let listInnit = deepCopy(list)
          if(remNum){
              remNumArrary = remNum.split(',');
              for(let i = 0; i<remNumArrary.length; i++){
                  for (let j = 0; j < listInnit.length;j++){
                      let index = listInnit[j]['codeItemId'] == remNumArrary[i]
                      if(index){
                          listInnit.splice(j, 1);
                      }
                  }
              }
              // console.log('处理',listInnit)
              this.options = listInnit

          } else{
              this.options = list
              // console.log('ceshi',this.options)
          }
      },
      selectChange(val){
        this.$emit('update:cascaderValue',val)
        this.$emit('selectChange',val)
        // this.$refs.cascader.toggleDropDownVisible();
      },

  }
}
</script>

<style scoped>
  .el-input.el-input--suffix {
    cursor: pointer;
    overflow: hidden;
  }

  .el-input.el-input--suffix.rotate .el-input__suffix {
    transform: rotate(180deg);
  }

  .select-tree {
    max-height: 350px;
    overflow-y: scroll;
  }

  /* 菜单滚动条 */
  .select-tree::-webkit-scrollbar {
    z-index: 11;
    width: 6px;
  }

  .select-tree::-webkit-scrollbar-track,
  .select-tree::-webkit-scrollbar-corner {
    background: #fff;
  }

  .select-tree::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 6px;
    background: #b4bccc;
  }

  .select-tree::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 6px;
  }
  .el-form-item__content{
    height:28px;
  }
</style>
