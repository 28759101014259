<!-- 下拉选择器 -->
<template>
  <el-radio-group
    v-model="radioValue"
    v-bind = "{...$attrs}"
    v-on="{...$listeners}"
    ref="radio"
    @change="selectChange"
    >
    <el-radio
      v-for="item in options"
      :key="item.codeItemId"
      :label="item.codeItemId"
      > {{item.codeItemName}}
    </el-radio>
  </el-radio-group>
</template>

<script>
  import { mapGetters } from 'vuex';
  import {deepCopy} from "../../../util/util";

  export default {
    name: 'bsp-dicts-select',
    props: {
      //当前选的值
      radioValue: {
        type:String,
        default:''
      },
      // 代码集setId
      dicNum: {
        type: String,
        default: ''
      },
      // 要移除的 itemId,隔开多个
      remNum: {
        type: String,
        default: ''
      },

      // 单选这选数据
      optionsList: {
          type: Array,
          default: () => (
              []
          )
      }
    },
    data() {
      return {
          // 下拉选数据
          options: [],
      }
    },
    computed: {
      ...mapGetters([
        'dicts',
      ]),
    },
    watch: {
    },
    created() {
        this.initData()
    },
    mounted() {
    },
    methods: {
      initData(){
          //  从后台获取的dicts
          if(this.optionsList.length > 0){
              //对list进行处理
              this.handleDictc(this.optionsList, this.remNum)

          }else if(this.dicNum){
            // let storeOption = this.dicts.codeList.hasOwnProperty(this.dicNum)
             //不是从后台获取的dicts
            let storeOptionList = this.dicts.codeList[this.dicNum]
            //对list进行处理
            this.handleDictc(storeOptionList, this.remNum)
        }
      },
      //  预处理下拉选的list
      handleDictc(list, remNum){
          let remNumArrary = []
          let listInnit = deepCopy(list)
          if(remNum){
              remNumArrary = remNum.split(',');
              for(let i = 0; i<remNumArrary.length; i++){
                  for (let j = 0; j < listInnit.length;j++){
                      let index = listInnit[j]['codeItemId'] == remNumArrary[i]
                      if(index){
                          listInnit.splice(j, 1);
                      }
                  }
              }
              // console.log('处理',listInnit)
              this.options = listInnit

          } else{
              this.options = list
              // console.log('ceshi',this.options)
          }
      },
      selectChange(val){
        this.$emit('update:radioValue',val)
        this.$emit('selectChange',val)
      }

  }
}
</script>

<style scoped>
  .el-input.el-input--suffix {
    cursor: pointer;
    overflow: hidden;
  }

  .el-input.el-input--suffix.rotate .el-input__suffix {
    transform: rotate(180deg);
  }

  .select-tree {
    max-height: 350px;
    overflow-y: scroll;
  }

  /* 菜单滚动条 */
  .select-tree::-webkit-scrollbar {
    z-index: 11;
    width: 6px;
  }

  .select-tree::-webkit-scrollbar-track,
  .select-tree::-webkit-scrollbar-corner {
    background: #fff;
  }

  .select-tree::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 6px;
    background: #b4bccc;
  }

  .select-tree::-webkit-scrollbar-track-piece {
    background: #fff;
    width: 6px;
  }
  .el-form-item__content{
    height:28px;
  }
</style>
