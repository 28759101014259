<template>
  <div>
    <template>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="对公组用户" name="1"></el-tab-pane>
        <el-tab-pane label="分支用户" name="3"></el-tab-pane>
        <el-tab-pane label="分公司用户" name="2"></el-tab-pane>
      </el-tabs>
    </template>
    <!--<div class="divButtonLeft">
      <el-form :inline="true" :model="listQuery" class="editForm">
        <el-form-item label="" style="margin-right: 50px">
          <el-input
            v-model="listQuery.name"
            placeholder="请输入姓名"
            size="mini"
            clearable
          />
        </el-form-item>

        <el-form-item label-width="25px">
          <el-button type="primary" @click="searchData" size="mini">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>-->
    <bsp-table
      v-if="refresh"
      ref="publicTable"
      :data.sync="list"
      :tree-config="true"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="loadList"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
      height="91%"
    >
      <vxe-table-column type="selection" width="60" fixed="left"/>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <div>
        <bsp-table-column title="姓名" field="personName" filter-type="input">
          <template slot-scope="scope"><span>{{ scope.row.personName}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="性别" field="sex" filter-type="select" set-id='0100' width="80">
          <template slot-scope="scope"><span>{{ scope.row.sex | dictFilter(dicts) }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="出生日期" field="birth" filter-type="datePicker">
          <template slot-scope="scope"><span>{{ scope.row.birth }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="身份证号" field="idcard" filter-type="input">
          <template slot-scope="scope"><span>{{ scope.row.idcard }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="对公机构" field="orgName" filter-type="input" width="200">
          <template slot-scope="scope"><span>{{ scope.row.orgName }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="登录名" field="loginName" filter-type="input">
          <template slot-scope="scope"><span>{{ scope.row.loginName }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="状态" field="isUse" width="100">
          <template slot-scope="scope"><span>{{ scope.row.isUse === "1" ? "启用":"禁用" }}</span></template>
        </bsp-table-column>
        <bsp-table-column title="操作">
          <template slot-scope="scope">
            <el-button el-button type="text" @click.native="mitation_login_click(scope.row)">模拟登录</el-button>
          </template>
        </bsp-table-column>
      </div>
    </bsp-table>
  </div>
</template>

<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {searchUserList} from '@/api/user/user';
  import {mapGetters} from 'vuex';
  import cusMenus from '@/menus/cus/cus';
  import entrustMenus from '@/menus/entrust/entrust';
  import agreementMenus from '@/menus/agreement/agreement';
  import consigneeMenus from '@/menus/consignee/consignee';
  import productMenus from '@/menus/product/product';
  import orgMenus from '@/menus/org/org';
  import reportMenus from '@/menus/report/report';
  import settingMenus from '@/menus/setting/setting';
  import bankDirectMenus from '@/menus/bank_direct/bank_direct';
  import paymentMenus from '@/menus/payment/payment';
  import platformMenus from "@/menus/demo/demo";

  export default {
    name: "MitationLogin",
    inject: ['reload'],
    components: {
      BspTable,
      BspTableColumn
    },
    computed: {
      ...mapGetters([
        'dicts', "userInfo"
      ]),
    },
    created() {
     this.loadList();
    },

    data() {
      return {
        // 用于接收后台返回的分页数据
        activeName: '1',
        loading: false,
        list: [],
        disableData:[{'1':'启用'},{"0":"禁用"}],
        //分页查询对象
        listQuery: {
          limit: 20,
          page: 1,
          total: 0,
          name: "",
          type: ""
        },
        loginForm: {
          personId: '',
          username: '',
          password: '',
          isSwitch: 'false',
        },
        refresh: true,
        localMenu: null,
        currentUser: '',
        orgName: '',
        relOrgList: [],
      }
    },

    methods: {
      handleClick(tab, event) {
        this.refresh = false;
        this.loadList();
      },
      loadList() {
        this.loading = true;
        this.listQuery.type = this.activeName;
        searchUserList(this.listQuery).then(res => {
          // console.log(res);
          this.list = res.data.data.records;
          this.listQuery.total = res.data.data.total;
          this.refresh = true;
          this.loading = false;
        })
      },
      mitation_login_click(row) {
        this.loading = true;
        // 模拟登录
        // debugger;
        //todo  登录接口,目前直接跳转到首页(切换用户)
        this.loginForm.personId = row.personId;
        this.loginForm.username = row.loginName;
        // this.loginForm.password = '1234';
        this.loginForm.isSwitch = 'true';
        this.$store.dispatch('LoginByUsername', this.loginForm).then(
          (res) => {
            if (res.data.data.success == "false") {
              this.loginLoading = false;
              this.$message.error(res.data.data.msg);
              // 刷新一下验证码
              this.reImageCode();
              this.loginForm.isSwitch = 'false';
              this.loading = false;
            } else {
              this.$store.state.impData.cusId = '';
              this.$store.state.impData.billMonth = '';
              this.$router.push({path: '/index'});
              this.$message.success('切换用户成功');
              this.loginForm.isSwitch = 'false';
              // this.$emit('reloadSider');
              this.loadPage();
              this.reload();
              this.loading = false;
            }
          }
        ).catch(
          (error) => {
            this.$message.error('切换用户失败');
            if (error.data && error.data.data.msg) {
              this.$message.error(error.data.data.msg);
            }
            this.loginForm.validCode = '';
            this.loginForm.isSwitch = 'false';
          })
        this.loading = false;
      },
      loadPage() {
        // debugger;
        let newMenusArray = platformMenus
        newMenusArray = newMenusArray.concat(cusMenus);//委托菜单
        newMenusArray = newMenusArray.concat(entrustMenus);//委托菜单
        newMenusArray = newMenusArray.concat(agreementMenus);//委托菜单
        newMenusArray = newMenusArray.concat(consigneeMenus);//委托菜单
        newMenusArray = newMenusArray.concat(productMenus);//委托菜单
        newMenusArray = newMenusArray.concat(orgMenus);//委托菜单
        newMenusArray = newMenusArray.concat(reportMenus);//委托菜单
        newMenusArray = newMenusArray.concat(settingMenus);//委托菜单
        newMenusArray = newMenusArray.concat(bankDirectMenus);//委托菜单
        newMenusArray = newMenusArray.concat(paymentMenus);//委托菜单

        this.localMenu = this.menu;
        this.currentUser = this.userInfo.name;
        this.orgName = this.userInfo.orgName;
        this.relOrgList = this.userInfo.relOrgList;
      },
    }
  }
</script>

<style scoped>

</style>
