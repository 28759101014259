import request from '@/router/axios';

const urlPrefix = process.env.VUE_APP_LOCAL_DEVELOPMENT == 'true' ? 'sys' : '/sys'
/**
 * 记录登录次数
 * 并返回当前月用户有没有登录过
 * true 是登录过
 * false 是没有登录过
 * @param id
 */
export function recordLoginTimes() {
  return request({
    url: urlPrefix+ `/user-login/record`,
    method: 'get',

  });
}

