import request from '@/router/axios';
const urlPrefix = ''

/**
 * 获取批次信息
 * @param obj
 */
export function getList(obj) {
  return request({
    url:urlPrefix+ '/wt/expenditure/listAll',
    method: 'post',
    data: obj
  })

}
