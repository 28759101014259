<template>
  <div>
    <div class="outer-layer" style="margin-bottom:-40px">
      <bsp-tabs :tabList="tabList" :tabIndex="tabIndex" @changeTab="changeTab">
        <component v-if="isRouterAlive" ref="pub" :is="currentTab" :currentTab='currentTab'
                   :batchStatus="batchStatus"></component>
      </bsp-tabs>
    </div>
    <div class="divButtonRigth">
      <el-form :inline="true" :model="listQuery" class="editForm">
        <el-form-item label="支出单编号" style="margin-right: 50px">
          <el-input
            v-model="listQuery.batchCode"
            placeholder="请输入支出单编号"
            size="mini"
            clearable
          />
        </el-form-item>

        <el-form-item label-width="50px">
          <el-button type="primary" @click="searchData" size="mini">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <detailImportList
      v-if="this.detailVisible"
      :table-column.sync="this.tableColumn"
      :data-list.sync="this.dataList"
      :sum-item.sync="this.sumItem"
      refname="detailImportList"
      @getSelectData="getSelectData"
      v-loading="loading"
      ref="detailImportList"
    />

  </div>
</template>
<script>
  import {mapGetters} from "vuex";
  import {getList} from "@/api/entrust/expenditure";
  import detailImportList from "@/views/entrust/detailImport/detailImportList";
  import {getPayoutDynamicTable} from "@/api/entrust/detailImport";
  import bspTabs from "@/components/bsp/bsp-tabs/bsp-tabs";

  export default {
    name: "ExpenditureBatchList",
    inject: ["reload"],
    components: {
      detailImportList,
      // bspTabs,

      31278300701: '1',
      31278300702: '2',
      31278300703: '3',
      31278300704: '4',
      31278300707: '5',
    },
    computed: {
      ...mapGetters(["dicts", "userInfo"])
    },
    data() {
      return {
        list: [],
        dataList: [],
        tableColumn: [], //显示表头
        sumItem: [], //需要合计的列
        detailImportCusList: [],
        listQuery: {
          limit: 10,
          page: 1,
          total: 0,
          batchCode: ""
        },
        noChangeList: [],
        loading: true,
        detailInfo: {},
        detailVisible: true,
        tabList:[],
        tabIndex: "",
        currentTab:"",
        isRouterAlive:true,
      };
    },
    created() {
      detailVisible: true;
      console.log("当前用户 机构id：" + this.userInfo.orgId);
      //TODO 此处增加对业务类型显示 测试
      //if(this.userInfo.orgId=="1060352" || this.userInfo.orgId=="297ecd7072e4c4280172e4e2534a000b" || this.userInfo.orgId=="1060348"){
      if (this.userInfo.userType == '2') {
        this.tabList = [{
          title: '代发薪大库报税',
          name: '31278300703'
        }, {
          title: '代发薪客户名义报税',
          name: '31278300704'
        }, {
          title: '客户名义报税',
          name: '31278300702'
        }, {
          title: '代报销',
          name: '31278300707'
        }];
        this.tabIndex = this.$route.query.currentTab ? this.$route.query.currentTab : '31278300703';
        this.currentTab = this.tabIndex;
      } else {
        this.tabList = [{
          title: '大库报税',
          name: '31278300701'
        }, {
          title: '客户名义报税',
          name: '31278300702'
        }, {
          title: '代发薪大库报税',
          name: '31278300703'
        }, {
          title: '代发薪客户名义报税',
          name: '31278300704'
        }, {
          title: '代报销',
          name: '31278300707'
        }];
        this.tabIndex = this.$route.query.currentTab ? this.$route.query.currentTab : '31278300701';
        this.currentTab = this.tabIndex;
      }
      this.detailInfo = {
        bizType: this.currentTab,
        cusId: null,
        status: "3004000023"
      };
      this._getdynamicTable();
    },
    methods: {
      searchData() {
        // console.log("查询", this.listQuery.batchCode);
        if (
          this.listQuery.batchCode === null ||
          this.listQuery.batchCode === ""
        ) {
          this.$message.error("请输入支出单编号");
        } else {
          0;
          this.detailInfo.batchCode = this.listQuery.batchCode;
          this._getdynamicList();
        }
      },

      changeTab(tab, event) {
        this.currentTab = tab.name;
        this.reload();
        this.detailInfo.bizType = this.currentTab;
        this._getdynamicTable();
        console.log("hello everybody~~~this.currentTab==");
        console.log(this.currentTab);
      },
      reload() {
        this.isRouterAlive = false
        this.$nextTick(function () {
          this.isRouterAlive = true
        })
      },
      _getdynamicTable() {
        getPayoutDynamicTable(this.detailInfo).then(res => {
          if (res.data.data.success === "true") {
            for (let index = 0; index < res.data.data.tableColumn.length; index++) {
              let element = res.data.data.tableColumn[index];
              element.field = element.field.toLowerCase();
              //console.log(element);
            }
            this.tableColumn = res.data.data.tableColumn;

            this.sumItem = res.data.data.sumItem;
            this.noChangeList = res.data.data.noChangeList;
            this.loading = false;
          }
        });
      },
      _getdynamicList() {
        getList(this.detailInfo).then(res => {
          // console.log('res.data===========',res.data);
          // this.listQuery.total = res.data.total
          // console.log(res.data.records);
          this.dataList = res.data.records;
          this.loading = false;
        });
        //console.log(this.dataList);
      }
    }
  };
</script>
<style scoped>
  .copy-btn {
    cursor: pointer;
  }

  .ciic-table-operate {
    align-items: center;
  }

  .editForm {
    margin: 0;
  }

  .editForm .el-form-item {
    margin: 0;
  }
</style>
<style>
  .winClass {
    width: 50% !important;
    height: 60% !important;
  }

  .el-message-box__content {
    overflow-y: auto !important;
    height: 80% !important;
  }
</style>
