
import { exportTableForHeaders, exportTable,exportSelectTable } from  "@/api/common/common"
import { downloadFile } from '@/util/util';

/**
 * 列表导出excle
 * @param obj 列对对象
 * @param serv 要访问的服务
 * @param isDyna 是否是动态表单 不传默认为false
 */
const exportMethod = (obj,serv,isDyna) =>{
  //默认为false
  if(!isDyna){
    isDyna= false;
  }
  const groupColumns  =  obj.columns.filter((column) => (
      column.colIndex
    ));
    if(obj.filters instanceof String ){

      obj.filterStr = JSON.parse(obj.filters).map((filter) =>{
        return `${filter.key}:${filter.value}##`;
      }).join('');

    }
    if(groupColumns.length>0){ //多级表头
        obj.columns.map((column) =>{
            column.colIndex = column.colIndex?column.colIndex:1;
        });
      exportTableForHeaders(obj,serv).then(res => {
        downloadFile(res)
      })
    }else{   //单行表头
        let fieldStr = [];
        let headerStr = [];
        let translates = [];
        obj.columns.map((column,index) =>{
          // 动态表单 得到需要翻译的字段
          if(isDyna && column.dataType==='dict'){
            let traField={};
            traField.field =column.field;
            traField.type ='code';
            traField.param=column.setid;
            translates.push(traField);
          }

           fieldStr.push(column.field);
           headerStr.push(column.title);
        });
        obj.translates= Object.assign([],obj.translates,translates);
       // obj.translates= translates;
        obj.fieldStr = fieldStr.join(',');
        obj.headerStr = headerStr.join(',');
        // 如果页面勾选的数据为空则导出全部 或筛选的结果 否则导出勾选数据
      if(obj.dataList&&obj.dataList.length>0){
        exportSelectTable(obj,serv).then(res => {
          downloadFile(res)
        })
      }else {
        exportTable(obj,serv).then(res => {
          downloadFile(res)
        })
      }

    }
    console.log(obj);
  };

  export default exportMethod;
