<template>
  <div  v-loading="loading" class="stepContent">
    <div class="downloadbutton">
    <el-button type="primary" @click="downloadtxt(tipInfo,warnInfo,errorInfo,1,resultInfo)" size="mini">下载提示和错误信息</el-button>
    <el-button type="primary" @click="downloadtxt(tipInfo,warnInfo,errorInfo,2,resultInfo)" size="mini">下载提示信息</el-button>
    <el-button type="primary" @click="downloadtxt(tipInfo,warnInfo,errorInfo,3,resultInfo)" size="mini">下载错误信息</el-button>
    </div>
      <div class="checkOK"  v-if="errorInfo=='' " >


      <p class="title">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-tongguo"></use>
        </svg>
        <span class="OKtitle">
          校验通过
        </span>
      </p>
      <p class="content">{{resultInfo}}</p>
      <div class="fail" v-if="(tipInfo!='' || warnInfo!='') &&(tipInfo!=undefined || warnInfo!=undefined)  ">
        <div class="failTip">
          <p class="title">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-tishi"></use>
            </svg>
            <span class="OKtitle">
              提示
            </span>
          </p>
          <p class="content"  v-html="tipInfo"></p>
          <p class="content"  v-html="warnInfo"></p>
        </div>
      </div>
    </div>
    <div class="checkFail" v-if="errorInfo!='' ">
      <p class="title">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-jinggao"></use>
        </svg>
        <span class="OKtitle">
          校验未通过
        </span>
      </p>
      <p class="content">{{resultInfo}}</p>
      <div class="fail">
        <div class="failTip" v-if="(tipInfo!='' || warnInfo!='') &&(tipInfo!=undefined || warnInfo!=undefined) ">
          <p class="title">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-tishi"></use>
            </svg>
            <span class="OKtitle">
              提示
            </span>
          </p>
          <p class="content"  v-html="tipInfo"></p>
          <p class="content"  v-html="warnInfo"></p>
        </div>
        <div class="failError">
          <p class="title">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-guanbi"></use>
            </svg>
            <span class="OKtitle">
              错误
            </span>
          </p>
          <p class="content" v-html="errorInfo"></p>
        </div>
      </div>
    </div>
    <div v-if="next" class="next-btn">
      <el-button type="primary" @click="goback" size="mini">上一步</el-button>
      <el-button type="primary" @click="importDetail" v-if="errorInfo==''"  size="mini" :loading="loading">完成</el-button>
      <el-button type="primary" @click="returnStep1" v-if="errorInfo!=''"  size="mini">返回</el-button>
    </div>
  </div>
</template>
<script>
  import { importDetail, addMatchRecord } from '@/api/entrust/detailImport';
  export default {
    data() {
      return {
        loading: false,
        params:{
          importTime:'',
          batchImportId:'',//导入批次id
          bizType:'',
          cusId:'',
          billMonth:'',
          isSuccess:'',
          isSubcontract: '',
          importWay:'IMPORT' // 导入-完成
        },
        importTime:'',
        batchImportId:'',//导入批次id
        novalidatePass:0,//校验未通过条数
        tipInfo:'',//友情提示
        warnInfo:'',//警告提示
        validatePass:0,//校验通过条数
        errorInfo:'',//错误信息
        resultInfo:'',//返回结果
      };
    },
    created(){
      console.log("step3业务类型："+ this.$store.state.impData.bizType);
      //console.log("step3:tipInfo"+this.$store.state.impData.tipInfo+";warnInfo:"+this.$store.state.impData.warnInfo);
      //console.log("step33:tipInfo"+this.$store.state.impData.tipInfo!=''+";warnInfo:"+this.$store.state.impData.warnInfo!='');
      //console.log("step33:tipInfo"+this.$store.state.impData.tipInfo!=undefined+";warnInfo:"+this.$store.state.impData.tipInfo!='undefined');
      this.importTime=this.$store.state.impData.importTime;
      this.batchImportId=this.$store.state.impData.batchImportId;
      this.novalidatePass=this.$store.state.impData.novalidatePass;
      this.tipInfo=this.$store.state.impData.tipInfo;
      this.warnInfo=this.$store.state.impData.warnInfo;
      this.validatePass=this.$store.state.impData.validatePass;
      this.errorInfo=this.$store.state.impData.errorInfo;
      this.resultInfo=this.$store.state.impData.resultInfo;
    },
    mounted() {

    },
    methods: {
      //点击完成
      importDetail(){

        this.loading=true;
        this.params.importTime=this.$store.state.impData.importTime;
        this.params.batchImportId=this.$store.state.impData.batchImportId;
        this.params.bizType=this.$store.state.impData.bizType;
        this.params.importTime=this.$store.state.impData.importTime;
        this.params.cusId=this.$store.state.impData.cusId;
        this.params.billMonth=this.$store.state.impData.billMonth;
        this.params.isSuccess=this.$store.state.impData.isSuccess;
        this.params.isSubcontract=this.$store.state.impData.isSubcontract;
        importDetail(this.params).then(res => {
          if (res.data.data.success !== 'false') {
            this.loading=false;
            this.$message.success("导入成功！");
            const query = {   //getDynamicForm参数
              currentTab:this.$store.state.impData.bizType,//业务类型
            }
            this.$router.push({ path: '/wt/detailImportBatchTabs',query});
          } else {
            this.loading=false;
            this.$message.error(res.data.data.msg);
            const query = {   //getDynamicForm参数
              currentTab:this.$store.state.impData.bizType,//业务类型
            }
            this.$router.push({ path: '/wt/detailImportBatchTabs',query});
          }
        })
      },
      next() {
        this.$emit("next"); // 跳转下一步
      },
      goback() {
        if(this.$store.state.impData.matchFlag=="true"){
          let obj = {};
          obj.val = 2;
          obj.bizType = this.$store.state.impData.bizType;
          obj.billMonth = this.$store.state.impData.billMonth;
          obj.cusId = this.$store.state.impData.cusId;
          this.$emit("gobackTwo",obj);
          //this.$emit("gobackTwo"); // 跳转上一步
        }else{
          this.$emit("goback"); // 跳转上一步
        }
      },
      //返回
      returnStep1(){
        let obj = {};
        obj.val = 2;
        obj.bizType = this.$store.state.impData.bizType;
        obj.billMonth = this.$store.state.impData.billMonth;
        obj.cusId = this.$store.state.impData.cusId;
        this.$emit("gobackTwo",obj);
      },

      downloadtxt(tip,warn,error,type,result) {
        const element = document.createElement('a')
        if(type == 1){
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + result+"\n\r提示:\n" +encodeURIComponent(tip)+"\n警告:\n"+encodeURIComponent(warn)+"\n错误:\n"+encodeURIComponent(error))
          element.setAttribute('download', "错误或提示信息.txt")
        }
        if(type == 2){
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + result+"\n\r提示:\n" + encodeURIComponent(tip)+"\n警告:\n"+encodeURIComponent(warn))
          element.setAttribute('download', "提示信息.txt")

        }
        if(type == 3){
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' +  result+"\n\r错误:\n" + encodeURIComponent(error)+"\n")
          element.setAttribute('download', "错误信息.txt")

        }
       element.style.display = 'none'
        element.click()
      }
    }
  };
</script>
<style scoped rel="stylesheet/scss">
  .stepContent {
  }
  .checkOK {
    background-color: #f0f9eb;
    padding: 20px;
    margin-bottom: 20px;
    color:#67c23a;
  }
  .checkOK .title{
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .checkOK .title svg{
    margin-right: 10px;
  }
  .checkOK .content{
    padding-left: 30px;
    font-size: 14px;
  }
  .checkFail{
    background-color: #fef0f0;
    padding: 20px;
    margin-bottom: 20px;
  }
  .checkFail .title{
    color:#f95b47;
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .checkFail .title svg{
    margin-right: 10px;
  }
  .checkFail>.content{
    color:#f95b47;
    padding-left: 30px;
    font-size: 14px;
  }
  .failTip,.failError{
    margin-top: 20px;
    padding-left: 30px;
  }
  .failTip .title{
    color:#fd6702;
    font-size: 16px;
  }
  .failError .title{
    color:red;
    font-size: 16px;
  }
  .failTip .content,.failError .content{
    font-size: 12px;
    padding-left: 30px;
    color:#888888;
  }
  .tip{
    margin-top: 10px;
    padding-left: 30px;
    color:#666666;
    font-size: 14px;
  }
  .tip em{
    font-weight: bold;
    color:#008aff;
    font-size: 16px;
    cursor: pointer;
  }
  .next-btn{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .downloadbutton{
    line-height: 0;
    float: right;
    margin-right: 10px;
    margin-top: 10px;
  }
</style>
