<template>
  <div class="stepContent  batchBox">
    <el-form  ref="batchInfoFrom" label-width="120px">
      <div class="editForm">
        <el-row :gutter="20">
          <el-col :span="9">
            <el-form-item label="请选择客户" prop="cusId">
              <el-select v-model="listForm.cusId" filterable clearable placeholder="请选择客户">
                <el-option
                  v-for="item in this.cusList"
                  :key="item.cusId"
                  :label="item.cusName"
                  :value="item.cusId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="选择年份" prop="year">
              <el-date-picker
                v-model="listForm.payYear"
                type="year"
                format="yyyy"
                value-format="yyyy"
                placeholder="选择年份">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <div class="download">
              <p  @click="exportTemplate">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-xiazai"></use>
                </svg>
                下载模板
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <div class="uploadBox">
      <el-upload
        v-loading="loading"
        :show-file-list="true"
        :on-success="fileUploadSuccess"
        :on-error="fileUploadError"
        :before-upload="beforeFileUpload"
        :headers="headers"
        :action="uploadUrl"
        class="uploadContent"
        :limit="1"
        drag
        accept=".xls,.xlsx">
        <i class="el-icon-upload"/>
        <div class="el-upload__text">点击或将文件拖到此处上传</div>
      </el-upload>
    </div>
    <div class="uploadTip">
      <div class="uploadTip-title">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-tishi"></use>
        </svg>
        温馨提示
      </div>
      <div class="uploadTip-lists">
        <div class="uploadTip-list">
          1、在上传时必须使用下载的模板格式
        </div>
        <div class="uploadTip-list">
          2、请不要修改任何表格格式
        </div>
        <div class="uploadTip-list">
          3、第一个sheet中首行必须为表头，且不支持复合表头
        </div>
        <div class="uploadTip-list">
          4、请不要加密模板文件，将导致错误
        </div>
      </div>
    </div>
    <div v-if="next" class="next-btn">
      <el-button type="primary" @click="goback" size="mini">返回</el-button>
    </div>
  </div>
</template>

<script>
  import treeTransfer from "el-tree-transfer"; // 引入
  import { getCusList, downloadExcel } from '@/api/cus/ykyj'
  import { mapGetters } from 'vuex';
  export default {
    components: { treeTransfer }, // 注册
    data() {
      return {
        token:' Authorization: `Bearer ${this.accessToken}`',
        dialogVisible: false,
        uploadUrl: "/api/ykyj/uploadExcel",//导入地址
        loading: false,
        next: true,
        mode: "transfer", // transfer addressList
        cusList: [],
        listForm: {
          cusId: "",
          payYear: "",
          cusName:'',
        },
      }
    },
    computed: {
      headers() {
        return {
          Authorization: `Bearer ${this.accessToken}`,
        };
      },
      ...mapGetters(['accessToken'])
    },
    created() {
      this.loadCusList();
    },
    methods: {
      loadCusList() {
        getCusList(this.listForm).then(res => {
          if (res.status !== 200) {
            alert("请求失败！");
            return;
          }
          if (res.data.code !== 0) {
            alert(res.data.msg);
            return;
          }
          this.cusList = res.data.data.records;
        })
      },

      beforeFileUpload(file) {
        // 判断当前月份是否为1月份
        // let date = new Date();
        // let month = date.getMonth() + 1;
        // alert("当前月份为：" + month + " 月份");
        // if (month !== 1) {
        //   alert("当前月份非1月份，不支持导入！");
        //   return;
        // }
        this.$set(this, "loading", true);
        let isExcel = true;
        const isxls = file.type === "application/vnd.ms-excel";
        const isxlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const isLtM = file.size / 1024 / 1024 < 10;

        if (!isxls && !isxlsx) {
          isExcel = false;
          this.$message.error("请导入模板下载的文件!");
          this.$set(this, "loading", false);
        }
        if (!isLtM) {
          this.$message.error("导入文件大小不能超过 10MB!");
          this.$set(this, "loading", false);
        }
        return isExcel && isLtM;
      },
      fileUploadSuccess(res, file, fileList) {
        this.$set(this, "loading", false);
        if (res) {
          if (res.code !== 0) {
            this.$message.error(res.msg);
          } else {
            this.$message.success(res.msg);
          }
        }
      },
      fileUploadError(err, file, fileList) {
        this.$set(this, "loading", false);
        this.$message.error("导入失败!");
      },
      exportTemplate: function () {
        if (this.listForm.cusId === '' || this.listForm.cusId === null) {
          alert("所属客户不能为空！！！");
          return;
        }
        if (this.listForm.payYear === '' || this.listForm.payYear === null) {
          alert("年份不能为空！！！");
          return;
        }
        downloadExcel(this.listForm).then(res => {
          const url = window.URL.createObjectURL(res.data);
          const link = document.createElement('a');
          link.href = url;
          const fileName = `${res.headers['content-disposition'].replace('attachment;filename=','')}`;
          let channelUrl=decodeURIComponent(fileName);
          link.setAttribute('download', channelUrl);
          link.click()
        });
      },
      goback() {
        this.$router.push({path: '/ykyj/ykyjList'})
      },
    },
    name: "ykyjImport"
  }
</script>

<style scoped rel="stylesheet/scss">
  .stepContent {
  }

  .download {
    display: flex;
    justify-content: flex-end;
  }

  .download p {
    margin-left: 20px;
    line-height: 30px;
    color: #269cff;
    cursor: pointer;
  }

  .download p svg {
    margin-right: 10px;
  }

  .download p:hover {
    text-decoration: underline;
  }

  .uploadBox {
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .uploadContent {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .uploadContent .iconfont {
    font-size: 80px;
    color: #cccccc;
  }

  .uploadContent {
    height: 140px;
  }

  .uploadBox >>> .el-upload {
    width: 100%;
    height: 100%;
  }

  .uploadBox >>> .el-upload-dragger {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .uploadBox >>> .el-upload-dragger .el-icon-upload {
    margin-top: 0;
  }

  .uploadText {
    color: #aaaaaa;
  }

  .uploadTip-lists {
    padding-left: 20px;
  }

  .uploadTip-list .uploadTip-button {
    color: #269cff;
    text-decoration: underline;
    cursor: pointer;
  }

  .next-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

</style>
