<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" size="small" @click="_createBills">确定</el-button>
      <el-button type="primary" size="small" @click="_close">取消</el-button>
    </div>
    <bsp-table
      :data.sync="list"
      @select-change="selectChange"
      @select-all="selectChange"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="_findCreatePaymentBillsPage"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
    >
      <vxe-table-column type="checkbox" width="60" />
      <bsp-table-column title="导入批次编号"   field="batchCode"  filter-type="input" >
        <template slot-scope="scope"><span>{{scope.row.batchCode}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="客户名称"  width="120" field="cusName"  filter-type="input" >
        <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="业务类型"  field="bizTypeName"  filter-type="input" >
        <template slot-scope="scope"><span>{{scope.row.bizTypeName}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="账单月份"   field="billMonth"  filter-type="input"  >
        <template slot-scope="scope"><span>{{scope.row.billMonth}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="税金所属月份"  field="taxMonth"  filter-type="input"  >
        <template slot-scope="scope"><span>{{scope.row.taxMonth}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="工资往来"   >
        <template slot-scope="scope"><span>{{scope.row.wageTrans}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="个税往来"   >
        <template slot-scope="scope"><span>{{scope.row.taxTrans}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="代报销往来"  >
        <template slot-scope="scope"><span>{{scope.row.reimburseTrans}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="服务费"  >
        <template slot-scope="scope"><span>{{scope.row.serviceTotal}}</span></template>
      </bsp-table-column>
    </bsp-table>
  </div>
</template>

<script>
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import { getCusByUser } from '@/api/sys/cus'
  import {findCreatePaymentBillsPage , createBills } from '@/api/entrust/paymentBills'

  export default {
    name: 'createBill',
    components: {
      BspTable,
      BspTableColumn
    },
    data(){
      return {
        multipleSelection:[],
        loading:false,
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
        },
        list:[],
        cusList:[]
      }
    },
    methods:{
      init(){
        // 初始化
        this._getCusByUser();
        this._findCreatePaymentBillsPage();
      },
      async _createBills(){
        if(this.multipleSelection.length>0){
          this.loading = true
          let res = await createBills(this.multipleSelection);
          if(res.data.code ===0 ){
            // 创建成功
            this.$message.success("创建成功");
            this.multipleSelection = [];
            // 查询的数据
            this._findCreatePaymentBillsPage();
          }else{
            this.$message.error(res.data.msg);
            this.loading = false
          }
        }else{
          this.$message.info("请选择至少一条数据！");
        }
      },
      async _findCreatePaymentBillsPage() {
        // 查询账单列表
        this.loading = true
        let res = await findCreatePaymentBillsPage(this.listQuery);
        this.listQuery.total = res.data.total
        this.list = res.data.records
        this.loading = false
      },
      async _getCusByUser() {
        let res = await getCusByUser();
        this.cusList = res.data.data;
      },
      _close(){
        this.$emit('hidden');
        this.$emit('pageList');
      },
      selectChange(value) {
        console.log(value);
        if (value.selection) {
          this.multipleSelection = [];
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index];
            this.multipleSelection.push(element);
          }
        }
      },
    }
  }
</script>

<style scoped>

</style>
