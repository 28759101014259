<template>
    <div class="sequences">
      <div class="sequenceBox" :class="{active:process === item.seqIndex,pass:process > item.seqIndex}" v-for="item in sequenceList" :key="item">
        <div class="sequence">{{item.seqName}}</div>
        <div class="sequenceArrow" v-if="item.seqIndex !== sequenceList.length -1">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-youjiantou"></use>
          </svg>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: "paySequence",
    props:{
      process:{
        type:Number,
        default:0 //从0开始
      }
    },
    data(){
      return{
        sequenceList:[{
          seqIndex:0,
          seqName:"薪酬设置"
        },{
          seqIndex:1,
          seqName:"账单设置"
        },{
          seqIndex:2,
          seqName:"发票设置"
        },{
          seqIndex:3,
          seqName:"来款设置"
        },{
          seqIndex:4,
          seqName:"工资单设置"
        }]
      }
    }
  };
</script>

<style scoped>
  .sequences,.sequenceBox{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sequenceBox{
    width: 160px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .sequence{
    width: 120px;
    height: 40px;
    color:#ffffff;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    background: #7ea7ca;
    border-radius: 4px;
    margin-right: 10px;
    /*cursor: pointer;*/
  }
  .sequenceArrow{
    color:#bbbbbb;
    font-size: 20px;
  }
  .sequenceBox.active .sequence{
    background: #008AFF;
    font-size: 16px;
  }
  .sequenceBox.pass .sequence{
    background: #008AFF;
  }
</style>
