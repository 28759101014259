<template>
  <div>
    <div>
      <el-alert class="myAlert" v-show="checkResult == 'ERR'" title="错误" type="error" :closable="false" :description="resultInfo" show-icon></el-alert>
      <el-alert class="myAlert" style="color: #f97e00" v-show="checkResult == 'WARN'" title="警告" type="warning" :closable="false" :description="resultInfo" show-icon></el-alert>
      <el-alert class="myAlert" v-show="checkResult == 'TIP'" title="提示" type="success" :closable="false" :description="resultInfo" show-icon></el-alert>
    </div>

    <div v-loading="loading" class="stepContent">
      <div class="downloadbutton">
        <el-button type="primary" @click="downloadtxt(tipInfo,warnInfo,errorInfo,1,resultInfo)" size="mini">下载错误和警告信息</el-button>
        <el-button type="primary" @click="downloadtxt(tipInfo,warnInfo,errorInfo,2,resultInfo)" size="mini">下载提示信息</el-button>
        <el-button type="primary" @click="downloadtxt(tipInfo,warnInfo,errorInfo,3,resultInfo)" size="mini">下载全部信息</el-button>
      </div> 
      <div class="checkFail" v-if="errorInfo !== ''">
        <p class="title">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-cuowu"></use>
          </svg>
          <span class="OKtitle">
            错误信息
          </span>
        </p>
        <p class="content" v-html="errorInfo" v-infinite-scroll="load" style="overflow:auto; max-height: 240px"></p>
      </div>
      <div class="checkWarn" v-if="warnInfo !==''">
        <p class="title">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jinggao"></use>
          </svg>
          <span class="OKtitle">
            警告信息
          </span>
        </p>

        <p class="content" v-html="warnInfo" v-infinite-scroll="load" style="overflow:auto; max-height: 240px"></p>
      </div>
      <div class="checkOK" v-if="tipInfo !== ''">
        <p class="title">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-tongguo"></use>
          </svg>
          <span class="OKtitle">
            提示信息
          </span>
        </p>
        <p class="content" v-html="tipInfo" v-infinite-scroll="load" style="overflow:auto; max-height: 240px"></p>
      </div>
      <div v-if="next" class="next-btn">
        <el-button type="primary" v-if="checkResult !== 'ERR'" @click="importDetail" size="mini" :loading="loading">下一步</el-button>
        <el-button type="primary" @click="goDetail"  size="mini">前往明细页</el-button>
      </div>
    </div>
  </div>
</template>
<script>
  import { getCopyOneResult,copySalaryStepOne,copySalaryStepTwo ,checkCopyEnd} from '@/api/entrust/batchCopy';
  export default {
    data() {
      return {
        loading: false,
        type:false,//是否只查询结果
        query:this.$route.query, // 上一个页面跳转传参
        tipInfo:'', // 提示信息
        warnInfo:'', // 警告信息
        errorInfo:'',//错误信息
        resultInfo:'',//校验结果：共校验雇员数据*条，校验通过*条,校验未通过*条
        validatePass:0,
        novalidatePass:0,
        checkResult :'',// 是否通过（00900 否，00901 是）
      };
    },
    props:['oneStepResult'],
    created(){
      console.log("oneStepResult",this.oneStepResult);
      this.loading =true;
      this.getCheckResult();
    },
    mounted() {

    },
    methods: {
      //前往明细页面
      goDetail(){
        this.$router.push({
          path: '/wt/copySalaryDetail',
          query:this.query
        })
      },
      next() {
        this.$emit("next"); // 跳转下一步
      },
      goback() {
        
      },
      //返回
      returnStep1(){
        
      },
      downloadtxt(tip,warn,error,type,result) {
        const element = document.createElement('a')
         // let blob = new Blob([res.data],{type:'text/plain'})
        if(type == 1){
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(result+"\n\r错误:\n" + error.replace(/<BR>/gi , '')+"\n警告:\n " + warn.replace(/<BR>/gi , '')))
          element.setAttribute('download', "错误和警告信息.txt")
        }
        if(type == 2){
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' +  encodeURIComponent(result+"\n\r提示:\n" +  tip.replace(/[</br>]/gi , '')+"\n"))
          element.setAttribute('download', "提示信息.txt")

        }
        if(type == 3){
          // element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(result+"\n\r提示:\n" + tip.replace(/[</br>]/gi , '')+"\n警告:\n" + warn.replace(/<BR>/gi , '') + "\n错误:\n" + error.replace(/<BR>/gi , '')))
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(result+"\n\r错误:\n" + error.replace(/<BR>/gi , '')+"\n警告:\n" + warn.replace(/<BR>/gi , '') + "\n提示:\n" + tip.replace(/[</br>]/gi , '')))
          element.setAttribute('download', "全部信息.txt")

        }
       element.style.display = 'none'
        element.click()
      },
      getCheckResult(){
        // this.loading = true;
        if(!this.type){
          console.log(this.$route.query);
          const obj = {id: this.$route.query.id};

          if(this.oneStepResult != undefined){
            let result = this.oneStepResult
            this.tipInfo = result.data.data.tipInfo;
            this.warnInfo = result.data.data.warnInfo;
            this.errorInfo = result.data.data.errorInfo;
            this.resultInfo = result.data.data.resultInfo;
            this.resultInfo = this.resultInfo.split("：")[1];
            console.log( this.resultInfo.split("：")[1]);
            this.validatePass = result.data.data.validatePass;
            this.novalidatePass = result.data.data.novalidatePass;
            this.checkResult = result.data.data.checkResult;
            this.loading = false;
            return false;
          }

          copySalaryStepOne(obj).then((result) => { //校验明细
            if(result.data.code == "0"){
              this.tipInfo = result.data.data.tipInfo;
              this.warnInfo = result.data.data.warnInfo;
              this.errorInfo = result.data.data.errorInfo;
              this.resultInfo = result.data.data.resultInfo;
              this.resultInfo = this.resultInfo.split("：")[1];
              console.log( this.resultInfo.split("：")[1]);
              this.validatePass = result.data.data.validatePass;
              this.novalidatePass = result.data.data.novalidatePass;
              this.checkResult = result.data.data.checkResult;
              this.loading = false;
              // 将校验结果保存至父组件
              this.$emit("oneCheckResult", result); // 跳转下一步
            }else {
              this.$message.error(result.data.msg);
              this.loading = false;
            }
          })
        }else {
          // 只查询结果

        }
      },
      async importDetail(){ //薪资复制校验第二步
        this.loading = true;
        const obj = {tempBatchId : this.query.id}

          const copyEnd = await checkCopyEnd({tempBatchId: this.query.id});
          if(copyEnd.data.data){
            this.$confirm(copyEnd.data.msg + "已生成批次号：" + copyEnd.data.data, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              const query = {   //getDynamicForm参数
                currentTab:this.$store.state.impData.bizType,//业务类型
              }
              this.$router.push({ path: '/wt/detailImportBatchTabs',query});
            })
            this.loading = false;
            return;
          }
        copySalaryStepTwo(obj).then((res) => {
          console.log(res);
          if (res.data.data.success == 'true') {
            this.$store.state.impData.importTime = res.data.data.importTime;
            this.$store.state.impData.batchImportId = res.data.data.batchImportId;
            this.$store.state.impData.isSubcontract = res.data.data.isSubcontract;
            this.$store.state.impData.novalidatePass = res.data.data.novalidatePass;
            this.$store.state.impData.tipInfo = res.data.data.tipInfo;
            this.$store.state.impData.warnInfo = res.data.data.warnInfo;
            this.$store.state.impData.validatePass = res.data.data.validatePass;
            this.$store.state.impData.errorInfo = res.data.data.errorInfo;
            this.$store.state.impData.resultInfo = res.data.data.resultInfo;
            this.$store.state.impData.isSuccess = res.data.data.isSuccess;
            this.$store.state.impData.tempBatchId = res.data.data.tempBatchId;
            this.$store.state.impData.bizType = res.data.data.bizType;
            this.$store.state.impData.cusId = res.data.data.cusId;
            this.$store.state.impData.billMonth = res.data.data.billMonth;
            console.log("step2:tipInfo" + res.data.data.tipInfo);
            this.loading = false;
            this.$emit("onupload"); // 跳转下一步
          } else {
            this.loading = false;
            this.$message.error(res.data.data.msg);
          }
        }).catch((err) => {
          this.loading = false;
          console.log("薪资复制下一步发生错误",err);
        });
      },
      load () {
        // 滚动加载？？
        console.log("滚动加载");
        // this.count += 2
      }
    }
  };
</script>
<style scoped rel="stylesheet/scss">
  .stepContent {
  }
  .checkOK {
    background-color: #f0f9eb;
    padding: 20px;
    margin-bottom: 20px;
    color:#67c23a;
  }
  .checkOK .title{
    font-size: 15px;
    display: flex;
    align-items: center;
  }
  .checkOK .title svg{
    margin-right: 10px;
  }
  .checkOK .content{
    padding-left: 30px;
    font-size: 12px;
  }
  .checkWarn {
    background-color: #fff4e6;
    padding: 20px;
    margin-bottom: 20px;
  }
  .checkWarn .title{
    color: #ff7d0a;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .checkWarn .title svg{
    margin-right: 10px;
  }
  .checkWarn .content{
    color:#ff7d0a;
    padding-left: 30px;
    font-size: 12px;
  }
  .checkFail{
    background-color: #fef0f0;
    padding: 20px;
    margin-bottom: 20px;
  }
  .checkFail .title{
    color:#f95b47;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .checkFail .title svg{
    margin-right: 10px;
  }
  .checkFail>.content{
    color:#f95b47;
    padding-left: 30px;
    font-size: 12px;
  }
  .failTip,.failError{
    margin-top: 20px;
    padding-left: 30px;
  }
  .failTip .title{
    color:#fd6702;
    font-size: 16px;
  }
  .failError .title{
    color:red;
    font-size: 16px;
  }
  .failTip .content,.failError .content{
    font-size: 12px;
    padding-left: 30px;
    color:#888888;
  }

  .warnTip,.warnError{
    margin-top: 20px;
    padding-left: 30px;
  }
  .warnTip .title{
    color:#fd6702;
    font-size: 16px;
  }
  .warnError .title{
    color:red;
    font-size: 16px;
  }
  .warnTip .content,.warnError .content{
    font-size: 12px;
    padding-left: 30px;
    color:#888888;
  }

  .tip{
    margin-top: 10px;
    padding-left: 30px;
    color:#666666;
    font-size: 14px;
  }
  .tip em{
    font-weight: bold;
    color:#008aff;
    font-size: 16px;
    cursor: pointer;
  }
  .next-btn{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .downloadbutton{
    line-height: 0;
    float: right;
    margin-right: 10px;
    margin-top: -35px;
    margin-bottom: 10px;
  }
  .myAlert{
    margin-top:10px;
    background-color: transparent ;
    padding: 0%;
    line-height:1.3;
    font-size: 16px;
  }
  .myAlert >>> .el-alert__description {
    font-size: 16px;
  }
  .myAlert >>> .el-alert__title{
    font-size: 18px;
  }
</style>
