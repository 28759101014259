<template>
  <div class="stepContent">
    <div class="uploadBox">
      <el-upload
        v-loading="loading"
        :show-file-list="false"
        :on-success="fileUploadSuccess"
        :on-error="fileUploadError"
        :before-upload="beforeFileUpload"
        :data="typeCode"
        :action="importUrl"
        class="uploadContent"
        name="file"
        drag
        accept="application/vnd.ms-excel">
        <i class="el-icon-upload"/>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </div>
    <div class="uploadTip">
      <div class="uploadTip-title">
        温馨提示
      </div>
      <div class="uploadTip-lists">
        <div class="uploadTip-list">
          你可以在此下载<span class="uploadTip-button" @click="exportTemplate">《标准模板》</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {downloadExcleTmpl} from "@/api/demo/demo"
import { downloadFile } from '@/util/util';
export default {
  props: {
    typeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      typeCode: {
        tmplIds: ''
      },
      donwloadVale:{
        tmplIds:'DEMO_PERSON',
        fileName:'示例人员导入模板.xls',
      },
      loading: false,
      downloadUrl: process.env.VUE_APP_LOCAL_URL + '/excel/downloadtmpexcle',
      importUrl: '/api/sys/excel/importExcel'
    }
  },
  mounted() {
    this.typeCode.tmplIds = this.donwloadVale.tmplIds
  },
  methods: {
    // 下载模板
    exportTemplate() {
      downloadExcleTmpl(this.donwloadVale).then(res => {
        downloadFile(res)
      })
    },
    beforeFileUpload(file) {
      this.typeCode.tmplIds = this.donwloadVale.tmplIds
      this.$set(this, 'loading', true)
      const isExcel = file.type === 'application/vnd.ms-excel'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isExcel) {
        this.$message.error('上传文件只能是 xls 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 2MB!')
      }
      return isExcel && isLt2M
    },
    fileUploadSuccess(response, file, fileList) {
      console.log("====上传成功")
      console.log(file)
      this.$set(this, 'loading', false)
      if (response) {
        response.code;
        if (response.code !== 0) {
          this.$message({ type: 'error', message: '导入失败' })
        } else {
          //导入成功到下一页
         // if(response.data.success){
            // 赋值数据  用于下一个页面使用
           /// this.$store.state.impData.contentList = response.data.data;
            //this.$store.state.impData.noMatchTitleList = res.data.noMatc
            //this.$store.state.impData.matchTitleList = res.data.matc
            //this.$store.state.impData.fileTitleList = response.data.fileTitle
            this.$store.state.impData.success = response.data.success
            this.$store.state.impData.errorCount = response.data.errorCount
            this.$store.state.impData.tableNames = response.data.tableNames
            this.$store.state.impData.tmplIds = this.typeCode.tmplIds
            this.$store.state.impData.fileName = this.donwloadVale.fileName
            this.$emit('onupload') // 跳转下一步
         // }else {
         //   this.$message({ type: 'error', message: response.data.msg })
         // }

          // this.$message({ type: 'success', message: res.msg })
        }
      }
    },
    fileUploadError(err, file, fileList) {
      this.$set(this, 'loading', false)
      this.$message({ type: 'error', message: '导入失败!' })
    }
  }
}
</script>
<style scoped  rel="stylesheet/scss">

  .uploadBox{
    border:1px solid #eeeeee;
    border-radius: 5px;
    padding:15px;
    margin-bottom: 20px;
  }
  .uploadContent{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px #eeeeee;
    border-radius: 10px;
  }
  .uploadContent .iconfont{
    font-size: 80px;
    color:#cccccc;
  }
  .uploadContent {
    height: 140px;
  }
  .uploadBox >>> .el-upload{
    width: 100%;
    height: 100%;
  }
  .uploadBox >>> .el-upload-dragger{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .uploadBox >>> .el-upload-dragger .el-icon-upload{
    margin-top: 0;
  }
  .uploadText{
    color:#aaaaaa;
  }
  .uploadTip-lists{
    padding-left:20px;
  }
  .uploadTip-list .uploadTip-button{
    color:#269cff;
    text-decoration: underline;
    cursor: pointer;
  }
</style>
