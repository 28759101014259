<template>
  <div class="stepContent">
    <div class="download">
      <p>
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-xiazai"></use>
        </svg>
        下载精简模板
      </p>
      <p @click="dialogVisible = true">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-xiazai"></use>
        </svg>
        自定义下载模板
      </p>
    </div>
    <div class="uploadBox">
      <el-upload
        v-loading="loading"
        :show-file-list="false"
        :on-success="fileUploadSuccess"
        :on-error="fileUploadError"
        :before-upload="beforeFileUpload"
        :action="uploadUrl"
        class="uploadContent"
        drag
        accept=".xls,.xlsx">
        <i class="el-icon-upload"/>
        <div class="el-upload__text">点击或将文件拖到此处上传</div>
      </el-upload>
    </div>
    <div class="uploadTip">
      <div class="uploadTip-title">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-tishi"></use>
        </svg>
        温馨提示
      </div>
      <div class="uploadTip-lists">
        <div class="uploadTip-list">
          1、支持文件后缀 xls、xlsx文件，且不超过10M
        </div>
        <div class="uploadTip-list">
          2、请将要上传的内容放在第一个sheet中
        </div>
        <div class="uploadTip-list">
          3、第一个sheet中首行必须为表头，且不支持复合表头
        </div>
        <div class="uploadTip-list">
          4、请不要加密模板文件，将导致错误
        </div>
      </div>
    </div>
    <div v-if="next" class="next-btn">
      <el-button type="primary" @click="goback" size="mini">返回</el-button>
      <el-button type="primary" @click="nextStep" size="mini">下一步</el-button>
    </div>
    <el-dialog
      ref=""
      width="70%"
      title="自定义表头"
      :visible.sync="dialogVisible"
    >
      <tree-transfer :title="title" :from_data='fromData' :to_data='toData' :defaultProps="{label:'label'}"
                     @addBtn='add' @removeBtn='remove' :mode='mode' height='540px' filter openAll>
      </tree-transfer>
      <div
        class="marginTop30"
        style="text-align: center"
      >
        <el-button
          type="primary"
          @click=""
        >
          确认
        </el-button>
        <el-button @click="dialogVisible = false">
          取消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  /* eslint-disable handle-callback-err */
  import treeTransfer from "el-tree-transfer"; // 引入

  export default {
    components: { treeTransfer }, // 注册
    data() {
      return {
        dialogVisible: false,
        uploadUrl: "",//导入地址
        loading: false,
        next: true,
        mode: "transfer", // transfer addressList
        fromData: [
          {
            id: "1",
            pid: 0,
            label: "一级 1",
            children: [
              {
                id: "1-1",
                pid: "1",
                label: "二级 1-1",
                children: []
              },
              {
                id: "1-2",
                pid: "1",
                label: "二级 1-2",
                children: [
                  {
                    id: "1-2-1",
                    pid: "1-2",
                    children: [],
                    label: "二级 1-2-1"
                  },
                  {
                    id: "1-2-2",
                    pid: "1-2",
                    children: [],
                    label: "二级 1-2-2"
                  }
                ]
              }
            ]
          }
        ],
        toData: [
          {
            id: "zxc",
            pid: '0',
            label: "加一级 1",
            disabled:true,
            children: [
              {
                id: "asd",
                pid: "zxc",
                label: "加二级 1-1",
                children: [],
                disabled:true
              },
              {
                id: "qqq",
                pid: "zxc",
                label: "加二级 2-2",
              disabled:true,
                children: [
                  {
                    id: "2-2-1",
                    pid: "qqq",
                    children: [],
                    label: "加二级 2-2-1",
                    disabled:true
                  },
                  {
                    id: "2-2-2",
                    pid: "qqq",
                    children: [],
                    label: "加二级 2-2-2",
                    disabled:true
                  }
                ]
              }
            ]
          }]
      };
    },
    methods: {
      beforeFileUpload(file) {
        this.$set(this, "loading", true);
        let isExcel = true;
        const isxls = file.type === "application/vnd.ms-excel";
        const isxlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const isLtM = file.size / 1024 / 1024 < 10;

        if (!isxls && !isxlsx) {
          isExcel = false;
          this.$message.error("导入文件只能是 xls,xlsx 格式!");
          this.$set(this, "loading", false);
        }
        if (!isLtM) {
          this.$message.error("导入文件大小不能超过 10MB!");
          this.$set(this, "loading", false);
        }
        return isExcel && isLtM;
      },
      fileUploadSuccess(res, file, fileList) {
        this.$set(this, "loading", false);
        if (res) {
          if (res.statusCode !== 200) {
            this.$message({ type: "error", message: res.msg });
          } else {
            // 赋值数据  用于下一个页面使用
            this.$store.state.impData.contentList = res.data.contentList;
            this.$store.state.impData.noMatchTitleList = res.data.noMatchTitleList;
            this.$store.state.impData.matchTitleList = res.data.matchTitleList;
            this.$store.state.impData.fileTitleList = res.data.fileTitleList;
            this.next = true;
          }
        }
      },
      fileUploadError(err, file, fileList) {
        this.$set(this, "loading", false);
        this.$message({ type: "error", message: "导入失败!" });
      },
      nextStep() {
        debugger
        this.$emit("onupload"); // 跳转下一步
      },
      goback() {
        this.$emit("goTopage"); // 返回
      },
// 监听穿梭框组件添加
      add(fromData, toData, obj) {
// 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的        {keys,nodes,halfKeys,halfNodes}对象
// 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
        console.log("fromData:", fromData);
        console.log("toData:", toData);
        console.log("obj:", obj);
      },
// 监听穿梭框组件移除
      remove(fromData, toData, obj) {
// 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
// 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
        console.log("fromData:", fromData);
        console.log("toData:", toData);
        console.log("obj:", obj);
      }
    }
  }
</script>
<style scoped rel="stylesheet/scss">
  .stepContent {
  }

  .download {
    display: flex;
    justify-content: flex-end;
  }

  .download p {
    margin-left: 20px;
    line-height: 50px;
    color: #269cff;
    cursor: pointer;
  }

  .download p svg {
    margin-right: 10px;
  }

  .download p:hover {
    text-decoration: underline;
  }

  .uploadBox {
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .uploadContent {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .uploadContent .iconfont {
    font-size: 80px;
    color: #cccccc;
  }

  .uploadContent {
    height: 140px;
  }

  .uploadBox >>> .el-upload {
    width: 100%;
    height: 100%;
  }

  .uploadBox >>> .el-upload-dragger {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .uploadBox >>> .el-upload-dragger .el-icon-upload {
    margin-top: 0;
  }

  .uploadText {
    color: #aaaaaa;
  }



  .uploadTip-lists {
    padding-left: 20px;
  }


  .uploadTip-list .uploadTip-button {
    color: #269cff;
    text-decoration: underline;
    cursor: pointer;
  }

  .next-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
</style>
