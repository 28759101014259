import request from '@/router/axios';
const urlPrefix = '';

// 查询待处理补贴列表
export function queryPendingSubsidies(obj) {
  return request({
    url:urlPrefix + '/queryPendingSubsidies',
    method: 'post',
    data: obj
  })
}

// 根据批次id查询列表
export function querySubsidiesBatchList(obj) {
  return request({
    url:urlPrefix + '/querySubsidiesBatchList',
    method: 'post',
    data: obj
  })
}

// 点击生成补贴批次
export function generateSubsidyBatch(obj) {
  return request({
    url:urlPrefix + '/generateSubsidyBatchAsync',
    method: 'post',
    data:obj
  })
}

// 点击补贴批次列表的删除按钮
export function deleteSubsidiesBatchList(obj) {
  return request({
    url:urlPrefix + '/deleteSubsidiesBatchList',
    method: 'post',
    data:obj
  })
}

//查询委托机构的发票信息
export function getBillsInfo(obj) {
  return request({
    url: urlPrefix + '/wt/detailImport/get-bills-info',
    method: 'post',
    data: obj
  });
}

//点击提交
export function commitBatch(obj) {
  return request({
    url:urlPrefix+ '/wt/detailImport/commit-batch',
    method: 'post',
    data: obj
  })
}

//单条操作导出
export function exportDetails(obj){
  return request({
    url: urlPrefix+'/wt/detailImport/export-details',
    method: 'post',
    responseType: 'arraybuffer',
    data: obj
  })
}

//点击批次查看-获取表头
export function getdynamicTable(obj) {
  return request({
    url:urlPrefix+ '/wt/detailImport/get-dynamic-table',
    method: 'post',
    data: obj
  })
}
//点击批次查看-获取数据
export function getdynamicList(obj) {
  return request({
    url:urlPrefix+ '/wt/detailImport/get-dynamic-list',
    method: 'post',
    data: obj
  })
}
//大库/客户名义报税点击查看增加客户列表
//detail-import-batch-cus-list-page
export function getDetailImportBatchCusList(obj) {
  return request({
    url: urlPrefix + '/wt/detailImport/detail-import-batch-cus-list-page',
    method: 'post',
    data: obj
  });
}
