<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" @click="_editContract(null)">创建合同</el-button>
       <!-- <el-button type="primary" @click="downloadContract(null)">下载协议合同</el-button> -->
    </div>
    <bsp-table
      :data.sync="list"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="_getContractList"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
    >
      <vxe-table-column title="序号" type="index"  width="60"  align="center"></vxe-table-column>
      <bsp-table-column title="合同名称" field="contractName" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.contractName}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="合同编号" field="contractCode" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.contractCode}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="签约客户" field="cusName" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="经办人" field="firstPerson" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.firstPerson}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="合同类型" field="contractType" filter-type="select" set-id="3012">
        <template slot-scope="scope"><span>{{scope.row.contractType | dictFilter(dicts)  }}</span></template>
      </bsp-table-column>
      <bsp-table-column title="合同状态" field="status" filter-type="select" set-id  = '3010'>
        <template slot-scope="scope"><span>{{scope.row.status | dictFilter(dicts) }}</span></template>
      </bsp-table-column>
      <bsp-table-column title="创建时间" field="bizCreateTime" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.bizCreateTime}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="创建人" field="operName" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.operName}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="操作" fixed="right" width="150px" align="center">
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button type="text"  @click="filePreview(scope.row)">预览</el-button>
            <el-button type="text" @click="downloadCusContrtact(scope.row)" >下载</el-button>
            <el-button type="text" @click="_editContract(scope.row)"  >修改</el-button>
            <el-button type="text" @click="_deleteContract(scope.row)" >删除</el-button>
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>


    <bsp-dialog
      :dialogRef="dialogRef"
      width="1200px"
      title="创建合同"
      @opened="init()"
      @close="$refs.contractEdit._close()"
      :dialogVisible.sync="dialogVisible"
      :isUpdate.sync="isUpdate"
    >
      <div slot="body">
        <contract-edit ref="contractEdit" @hidden ="closeDialog" @loadList ="_getContractList" ></contract-edit>
      </div>
    </bsp-dialog>

    <!--预览合同-->
    <bsp-dialog
      className="hetong"
      dialogRef="dialogAdd"
      width="1000px"
      title="预览合同"
      :dialogVisible.sync="htdialogVisible"
      @close="htcancelDialog"
    >
      <div slot="body">
        <div v-loading="preview">
          <iframe id="preview" :src="url" frameborder="0" width="100%" style="text-align:center;height: calc(100vh - 50px);" @load="loadOver"></iframe>
        </div>
      </div>
    </bsp-dialog>
  </div>
</template>

<script>
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import BspDialog from "@/components/bsp/bsp-dialog/bsp-dialog"
  import ContractEdit from "@/views/cus/contract/contractEdit"
  import { getContractPage ,deleteContract,downloadCusCont,downloadCont  } from '@/api/cus/contract.js'
  import { downloadFile } from '@/util/util'
  import {mapGetters} from "vuex";
  export default {
    components: {
      BspTable,
      BspTableColumn,
      BspDialog,
      ContractEdit
    },
    computed: {
      ...mapGetters([
        'dicts'
      ]),
    },
    data() {
      return {
        loading: false,
        dialogRef: "contractEdit",
        dialogVisible: false,
        list: [],
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
        },
        id :'',
        isUpdate:false,
        url:"",//预览地址
        htdialogVisible:false,
        preview:true
      }
    },
    created() {
      this._getContractList();
    },
    methods:{
      loadOver(){
        this.preview = false;
      },
      init(){
        this.$refs.contractEdit.initData(this.id)
      },

      _editContract(row){
        this.dialogVisible = true;
        if(row){
          this.id  = row.id;
          this.isUpdate=true;
        }else{
          this.id='';
          this.isUpdate=false;
        }
      },
      closeDialog() {
        console.log("false -----------------");
        this.dialogVisible = false;
      },
      async _deleteContract(row){
        this.loading = true
        let res = await deleteContract(row.id);
        if(res.data.code ===0){
          this.$message.success("刪除成功");
          this._getContractList();
        }else{
          this.$message.error("删除失败");
          this.loading = false ;
        }
      },
      async _getContractList() {
        // 查询账单列表
        this.loading = true
        let res = await getContractPage(this.listQuery);
        this.listQuery.total = res.data.total
        this.list = res.data.records
        this.loading = false
      },

      /**
       * 在线预览word文档
       */
      filePreview(row){
        console.log("行信息：",row,row.filePath)
        var originUrl = process.env.VUE_APP_BASE_URL+process.env.VUE_APP_BASE_API+'/contract/file/download/cus/'+row.filePath; //要预览文件的访问地址
       /// var originUrl = process.env.VUE_APP_BASE_URL+process.env.VUE_APP_BASE_API+'/vx/contracttempl/file/'+1+'/test.docx'; //要预览文件的访问地址
        
        //process.env.VUE_APP_BASE_URL
        var previewUrl = originUrl + '?fullfilename='+row.filePath+'.docx';
        console.log("链接",previewUrl)
        this.url=process.env.VUE_APP_PREVIE_URL+'/onlinePreview?url='+encodeURIComponent(previewUrl);
        // window.open();
        this.htdialogVisible = true;
        this.preview = true;
      },
      /**
       * 下载客户生成的合同
       */
      downloadCusContrtact(row){
        downloadCusCont(row.filePath).then(res=>{
          downloadFile(res);
        })
      },

      downloadContract(){
        downloadCont("2").then(res=>{
          downloadFile(res);
        })
      },
      // 合同
      htcancelDialog(){
        this.htdialogVisible = false;
        this.url = "";
      },

    }
    }
</script>

<style scoped>

</style>
