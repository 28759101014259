<template>
  <div class="cusHeader">
    <div class="headerList">
      <div class="headerList-title">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-diandeng"></use>
        </svg>
        <p>要显示信息项（拖拽信息项目进行排序）</p>
      </div>
      <div class="headerList-box">
        <draggable
          class="list-group"
          :list="headerList"
          group="tableHeader"
          @change="add"
        >
          <div class="header-item" v-for="(element,index) in headerList"  :key="element.infoItemId">
            {{element.infoItemName}}<svg class="icon" aria-hidden="true" @click="deleteList(index)" style="font-size: 12px">
            <use xlink:href="#icon-shanchu"></use>
          </svg>
          </div>
        </draggable>
      </div>
    </div>
    <div class="headersList">
      <div class="headerList-title">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-diandeng"></use>
        </svg>
        <p>全部信息项（拖拽或点击信息项目到上面区域）</p>
      </div>
      <div class="headersDatas">
        <div class="headersData" v-for="(item,index) in headerData"  :key="item.id">
          <div class="headersData-title">
            <p>{{item.title}}</p>
            <div class="fold" @click="fold(index)">
              <svg v-if="item.fold" class="icon" aria-hidden="true">
                <use xlink:href="#icon-shangjiantou"></use>
              </svg>
              <svg v-if="!item.fold" class="icon" aria-hidden="true">
                <use xlink:href="#icon-xiajiantou"></use>
              </svg>
            </div>
          </div>
          <draggable v-show="item.fold"
            class="headersData-box"
            :list="item.data"
            :group="{ name: 'tableHeader', pull: 'clone', put: false}"
            draggable = ".draggable"
            :sort="false"
          >
            <div class="headersData-list" v-for="header in item.data" :key="header.infoItemId" :class="header.undraggable?'undraggable':'draggable'">
              {{header.infoItemName}}
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import {getCustomTableHeader  } from  "@/api/common/common"
  export default {
    props: {
      infoSetId: {
        type: String,
        default: ''
      },
      modelType: {
        type: String,
        default: ''
      },
      headerList:{
        type:Array,
        default: () => (
          []
        )
      }
    },
    data(){
      return{
        headerParam:{
          infoSetId:'',
          modelType:'',
        },
        /*headerList:[
          {
            "name": "英文名称",
            "id": 5,
            "parentId":1,
            "undraggable":false
          },
          {
            "name": "客户行业",
            "id": 6,
            "parentId":1,
            "undraggable":false
          },
          {
            "name": "企业规模",
            "id": 7,
            "parentId":2,
            "undraggable":false
          }
        ],*/
        headerData:[
          {
            title:"企业信息",
            id:1,
            data:[
              {
                "name": "实际名称",
                "id": 4,
                "parentId":1,//为了尽量少循环添加上层id标识
                "undraggable":false//是否已经添加，添加后不能被拖拽
              },
              {
                "name": "英文名称",
                "id": 5,
                "parentId":1,
                "undraggable":true
              },
              {
                "name": "客户行业",
                "id": 6,
                "parentId":1,
                "undraggable":true
              }
            ]
          },
          {
            title:"服务信息",
            id:2,
            data:[
              {
                "name": "企业规模",
                "id": 7,
                "parentId":2,
                "undraggable":true
              },
              {
                "name": "客户国别",
                "id": 8,
                "parentId":2,
                "undraggable":false
              },
              {
                "name": "客户关注度",
                "id": 9,
                "parentId":2,
                "undraggable":false
              }
            ]
          }
        ]
      }
    },
    components: {
      draggable,
    },
    created(){
      // 加载数据
      this._getInfoItem();

    },
    mounted(){



    },
    methods:{
      _getInfoItem(){
        this.headerParam.infoSetId = this.infoSetId;
        this.headerParam.modelType = this.modelType;
        getCustomTableHeader(this.headerParam).then(res=>{
          console.log(res,"zi ding yi biaotao ");
          this.headerData = res.data.allItmeList;
          this.headerList = res.data.custItmeList;
          for(var i = 0; i < this.headerData.length; i++){
            this.$set(this.headerData[i],"fold",true)
          }
          this.$emit('update:headerList', this.headerList);
        })
      },
      fold(inx){
        this.$set(this.headerData[inx],'fold',!this.headerData[inx].fold)
      },
      add:function(e){
        if(e.added){
          const id = e.added.element.infoItemId;
          const parentId = e.added.element.infoItemGroupOrder;
          for (var i = 0; i < this.headerData.length; i++) {
            if(this.headerData[i].id === parentId){
              for(var j = 0; j < this.headerData[i].data.length; j++){
                if(this.headerData[i].data[j].infoItemId === id){
                  this.$set(this.headerData[i].data[j],'undraggable',true)
                }
              }
            }
          }
          this.$emit('update:headerList', this.headerList);
        }
        console.log(this.headerList)
      },
      deleteList(inx){
        const parentId = this.headerList[inx].infoItemGroupOrder;
        const id = this.headerList[inx].infoItemId;
        // 当值是1 时 为固定不能删除
        const customHeaderDefault = this.headerList[inx].customHeaderDefault;
        if('1'==customHeaderDefault){
          console.log("固定不能删除")
          return ;
        }
        for (var i = 0; i < this.headerData.length; i++) {
          if(this.headerData[i].id === parentId){
            for(var j = 0; j < this.headerData[i].data.length; j++){
              if(this.headerData[i].data[j].infoItemId === id){
                this.$set(this.headerData[i].data[j],'undraggable',false)
                this.headerList.splice(inx, 1);
              }
            }
          }
        }
        this.$emit('update:headerList', this.headerList);
      }
    }
  };
</script>

<style scoped>
  .headerList{
    border-bottom:1px solid #cccccc;
    padding-bottom: 20px;
  }
.headerList-title{
  color:#FFC000;
  font-size: 16px;
  display: flex;
  line-height: 40px;
  align-items: center;
  padding-left: 20px;
}
.headerList-title p{
  color:#999999;
  font-size: 14px;
  margin-left: 6px;
}
  .headerList-box{
    background-color: #FFFBE6;
    padding:20px 20px 10px 20px;
  }
.headerList-box .list-group{
  display: flex;
  flex-wrap: wrap;
}
  .headerList-box .list-group div{
    height: 30px;
    border:1px solid #ffc000;
    background-color: #fff;
    padding:0 10px;
    line-height: 30px;
    border-radius: 2px;
    color:#000000;
    font-size: 14px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: move;
  }
.headerList-box div div svg{
  margin-left: 10px;
  color:#ff9600;
  cursor: pointer;
}
  .headersData-title{
    background-color: #F4F4F4;
    border-radius: 2px;
    padding-left: 20px;
    color:#999999;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .fold{
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .headersData-box{
    display: flex;
    padding:20px 20px 10px 20px;
    flex-wrap: wrap;
  }
  .headersData-list{
    background-color: #f4f4f4;
    font-size: 14px;
    color:#000000;
    line-height: 30px;
    padding: 0 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: move;
  }
  .headersData{
    margin-bottom: 10px;
  }
  .undraggable{
    background-color: #FFA729;
    color:#ffffff;
  }
</style>
