<template>
    <div>
      <el-upload
        class="upload-demo"
        action="/api/file"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-success="handleUploadSuccess"
        :on-error="handleUploadError"
        :before-upload="beforeUpload"
        multiple
        :limit="limit"
        :on-exceed="handleExceed"
        :file-list="fileList">
        <span ><img src="@/assets/img/form/uploadBtn.png" alt=""></span>
      </el-upload>
    </div>
</template>

<script>
    import { downLoadFile,getFileInfo} from "@/api/user/user.js"
    import { downloadFile } from '@/util/util';
    import { mapGetters } from 'vuex';
    export default {
        name: "ciic-file-upload",
        props: {
          limit: {
              type: Number,
              default: 1,
          },
          fileIds:{
            type:String,
            default:''
          },
          fileNames:{
            type:String,
            default:''
          }
        },
        computed: {
            headers() {
                return {
                    Authorization: `Bearer ${this.accessToken}`,
                };
            },
            ...mapGetters(['accessToken'])
        },
        data(){
            return{
                fileList:[],
            }
        },
      created(){
          if(this.fileIds.length>0){
             let split = this.fileIds.split(",");
            for (let i = 0; i < split.length; i++) {
              downLoadFile(split[i]).then(res=>{
              });
            }

          }
      },
        methods:{
            handlePreview(file) {
                console.log(file);
                let id = file.response.fileId
                downLoadFile(id).then(res => {
                    console.log(res)
                    downloadFile(res)
                })
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            // 上传前处理
            beforeUpload(file) {
                const isLt10M = file.size / 1024 / 1024 < 10;

                if (!isLt10M) {
                    this.$message.error('上传文件大小不能超过 10MB!');
                }
                if (isLt10M) {
                    this.$Loading.full();
                }
                return isLt10M;
            },
            // 上传成功处理
            handleUploadSuccess(res, file,fileList) {
                console.log(fileList)
              for (let i = 0,l=fileList.length; i <l; i++) {
                if(i<(l-1)){
                  this.fileIds +=fileList[i].response.fileId+",";
                  this.fileNames +=fileList[i].response.fileName+",";
                }else {
                  this.fileIds +=fileList[i].response.fileId;
                  this.fileNames +=fileList[i].response.fileName;

                }
              }
                console.log(res)
                this.$Loading.close();
                if (res.fileId) {
                  console.log(res.fileId)
                  this.$emit('update:fileIds', this.fileIds)
                  this.$emit('update:fileNames', this.fileNames)
                  this.$message.success('上传成功');
                } else{
                   this.$message.error(res.message);
                }
            },
            // 上传失败处理
            handleUploadError(res, file) {
                const { errorMsg, errorCode } = res;
                if (errorCode === 1) {
                    this.$message.error('上传失败，请检查文件格式是否正确');
                } else {
                    this.$message.error('连接服务器出错，请稍后重试');
                }
            },
        }
    }
</script>

<style scoped lang="scss">
  .el-upload__tip{
    margin-left: 20px;
  }
</style>
