<template>
    <div class="payrollSetting customerBox" ref="payrollSetting">
      <!--头部-->
      <!--收起时为头部占位-->
      <div class="customerfixed" v-show="!fold" style="height:74px;"></div>
      <!--收起时补充头部上方空白-->
      <div class="customerfixed" v-show="!fold" :class="!fold?'fixed-top-bg':''" :style="!fold?boxWidth:''"></div>
      <div class="customerInfo"  :class="!fold?'fixed-top':''">
        <div class="customerInfo-head" :style="!fold?tabWidth:''">
          <div class="customerInfo-name stateOk"><span>沃奇卫士技术有限公司</span>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-gongsibiaoshi"></use>
            </svg>
            <p class="customerModel"><span>操作模式：</span><span>自签客户、受托客户</span></p>
          </div>
          <div class="customerInfo-name stateNotfinish"><span>不同状态的样式</span>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-gongsibiaoshi"></use>
            </svg>
          </div>
          <el-button-group class="customeInfo-pageGroup">
            <el-button type="button">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-fanhui"></use>
              </svg>
              返回
            </el-button>
          </el-button-group>
        </div>
        <div class="customerInfo-body"  v-show="fold">
          <div  class="customerInfo-body-detail">
            <p><span>客户联系人：</span><span>赵小刚 - 18515063203</span></p>
            <p class="interval">/</p>
            <p><span>业务负责人：</span><span>王雅 - 薪酬部</span></p>
            <p class="interval">/</p>
            <p><span>服务项目：</span><span>薪酬服务、财税全服务、报税服务</span></p>
          </div>
        </div>
      </div>
      <div class="payrollBox">
        <paySequence :process="process"></paySequence>
        <wagesSettings :wages-from.sync="wagesFrom" :item-lists.sync="itemLists"></wagesSettings>
      </div>
    </div>
</template>

<script>
  import paySequence from "./paySequence"
  import wagesSettings from "./wagesSettings"
  export default {
    name: "payrollSetting",
    components:{paySequence,wagesSettings},
    data(){
      return{
        fold:true,//是否展开头部信息
        boxWidth:0,//折叠头的宽
        tabWidth:0,//tab的宽
        process:4,//从0开始，第几个步骤
        itemLists:[
          {
            id:1,
            itemName:"员工信息",
            itemEnName:"Employee Info",
            itemList:[
              {
                id: 1,
                name: "养老保险企业部分",
                enName: "yanglaojinbaoxian",
                money: "1"
              }, {
                id: 2,
                name: "养老保险企业部分",
                enName: "yanglaojinbaoxian",
                money: "2"
              }, {
                id: 3,
                name: "养老保险企业部分",
                enName: "yanglaojinbaoxian",
                money: "3"
              }, {
                id: 4,
                name: "养老保险企业部分",
                enName: "yanglaojinbaoxian",
                money: "4"
              }, {
                id: 5,
                name: "养老保险企业部分",
                enName: "yanglaojinbaoxian",
                money: "5"
              }, {
                id: 6,
                name: "养老保险企业部分",
                enName: "yanglaojinbaoxian",
                money: "6"
              },
              {
                id: 6,
                name: "养老保险企业部分",
                enName: "yanglaojinbaoxian",
                money: "7"
              }]},
          {
            id:2,
            itemName:"收入",
            itemEnName:"Employee Info",
            itemList:[{
              id: 1,
              name: "养老保险企业部分",
              enName: "yanglaojinbaoxian",
              money: "1"
            }, {
              id: 2,
              name: "养老保险企业部分",
              enName: "yanglaojinbaoxian",
              money: "2"
            }, {
              id: 3,
              name: "养老保险企业部分",
              enName: "yanglaojinbaoxian",
              money: "3"
            }, {
              id: 4,
              name: "养老保险企业部分",
              enName: "yanglaojinbaoxian",
              money: "4"
            }, {
              id: 5,
              name: "养老保险企业部分",
              enName: "yanglaojinbaoxian",
              money: "5"
            }, {
              id: 6,
              name: "养老保险企业部分",
              enName: "yanglaojinbaoxian",
              money: "6"
            }, {
              id: 6,
              name: "养老保险企业部分",
              enName: "yanglaojinbaoxian",
              money: "7"
            }]},
          {
            id:3,
            itemName:"社保&公积金",
            itemEnName:"Employee Info",
            itemList:[{
              id: 1,
              name: "养老保险企业部分",
              enName: "yanglaojinbaoxian",
              money: "1"
            }, {
              id: 2,
              name: "养老保险企业部分",
              enName: "yanglaojinbaoxian",
              money: "2"
            }, {
              id: 3,
              name: "养老保险企业部分",
              enName: "yanglaojinbaoxian",
              money: "3"
            }, {
              id: 4,
              name: "养老保险企业部分",
              enName: "yanglaojinbaoxian",
              money: "4"
            }, {
              id: 5,
              name: "养老保险企业部分",
              enName: "yanglaojinbaoxian",
              money: "5"
            }, {
              id: 6,
              name: "养老保险企业部分",
              enName: "yanglaojinbaoxian",
              money: "6"
            }, {
              id: 6,
              name: "养老保险企业部分",
              enName: "yanglaojinbaoxian",
              money: "7"
            }]}
        ],
        wagesFrom:{
        name:"",
          empty:1,
        sendway:"",
        encryption:"",
        lang:1,
        title:"<工资所属月份>工资单 ",
        head:"<姓名>先生/女士：<换行>您好，以下是您<工资月份>工资单",
        mask:"",
      }
      }
    },
    mounted () {
      window.addEventListener('scroll', this.handleScroll,true);
    },
    destroyed(){
      // 销毁滚动事件
      window.removeEventListener('scroll', this.handleScroll,true);
    },
    methods: {

      handleScroll(type) {
        // 获取组件距顶距离
        let scrollTop = this.$refs.payrollSetting.getBoundingClientRect().top;
        // 获取组件高度
        let height = this.$refs.payrollSetting.clientHeight;
        //获取组件父级高度
        let parentHeight = window.innerHeight - 65;

        if(type == "collapse"){
          if(this.isCollapse){
            this.boxWidth = "width:" + (this.$refs.payrollSetting.clientWidth +180 - 72 - 112 ) + 'px';
            this.tabWidth = "width:" + (this.$refs.payrollSetting.clientWidth +180 - 72 - 62 - 20) + 'px';
          }else{
            this.boxWidth = "width:" + (this.$refs.payrollSetting.clientWidth -180 + 72 - 112) + 'px';
            this.tabWidth = "width:" + (this.$refs.payrollSetting.clientWidth -180 + 72 - 62 - 20) + 'px';
          }

        }else{
          // 如果收起后组件高度小于父组件高度，不收起
          if (scrollTop === 20) {
            this.fold = true;
            return false;
          }else{
            if (scrollTop < 20) {
              if(this.fold){
                if(height - 88 + 74 > parentHeight){
                  this.fold = false;
                  this.boxWidth = "width:" + (this.$refs.payrollSetting.clientWidth - 112) + 'px';
                  this.tabWidth = "width:" + (this.$refs.payrollSetting.clientWidth - 62 - 20) + 'px';
                }
              }
            } else {
              this.fold = true;
            }
          }
        }
      },
    }
  };
</script>

<style scoped>
  .fixed-top .customerInfo-head{
    padding:10px 21px;
  }
  .customerBox .customerInfo-name span{
    font-size: 18px;
  }
  .customerBox .customerInfo{
     border: none;
     padding: 0;
     margin-bottom: 0px;
  }
.customerBox .customerInfo-name .customerModel{
  margin-right: 30px;
  margin-left: 20px;
}
.customerBox .customerInfo-name .customerModel span{
    font-size: 12px;
  }
.customerModel span{
  color:#999999;
}
.customerModel span+span{
  color:#000000;
}
.customerBox .customeInfo-pageGroup .el-button{
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 12px;
  color: #666;
}
</style>
