<template>
    <div class="tabson1" >
        我是tab1
    </div>
</template>
<script>
    export default {
      name: "tabson1",
      props:{
          currentTab:{
              type:String,
              default:''
          }
      },
      computed: {

      },
      data() {
        return {}
      },
      created(){
      },
      mounted(){

      }
    }
</script>

