<template>
<div>
    <h2>Socket连接测试</h2>
    <p>该测试使用的后台为ciic-biz，将计算服务的计算结果从rabbitmq的demo_queue中取出然后在通过websocket发送给前台，
    所以测试时要开启salary与biz两个服务，然后利用salary中的前台请求模拟发送消息</p>
    <div>
        <span>Websocket连接：</span>
        <el-button v-bind:disabled="isEnable" type="primary" @click="connect()">连接</el-button>
        <el-button v-bind:disabled="!isEnable" type="warning" @click="disConnect()">断开连接</el-button>
    </div>

    <div>
        <span ref="push">后台推送的消息:</span>
    </div>
</div>
</template>

<script>
import SockJs from 'sockjs-client'
import Stomp from 'stompjs'
export default {
    name: 'SocketTest',

    data() {
        return {
            isEnable: false,
            stompClient: null,
        }
    },
    methods: {
        connect(){
            // 使用SockJS建立websocket链接
            let socket=new SockJs('http://192.168.43.109:8084/ciic-biz')
            // 利用Stomp生成客户端
            this.stompClient=Stomp.over(socket)
            // 建立连接
            this.stompClient.connect({},frame => {
                console.log("Connected: "+frame)
                this.isEnable=true
                //所有的消息订阅都要在connect中完成
                this.stompClient.subscribe('/queue/push',msg => {
                    console.log("接收到的消息："+JSON.stringify(msg))
                    let str = msg.body
                    this.$refs['push'].append(str+' ')
                })
                
            })
        },
        disConnect(){
            if(this.stompClient!=null){
                // 关闭连接
                this.stompClient.disconnect()
                this.isEnable=false
                console.log('断开webscoket链接')
            }
        }
    },
}
</script>
