<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" @click="_importData">社保导入</el-button>
      <el-button type="primary" @click="_deleteData">删除</el-button>
    </div>
    <bsp-table
      :data.sync="list"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="_socialSecurityBatchList"
      @select-change="selectChange"
      @select-all="selectChange"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
    >

      <vxe-table-column type="checkbox" width="60" fixed="left"/>
      <bsp-table-column title="导入批次" field="id" width="120" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.id}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="客户名称" field="cusName" width="200" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="账单月份" field="billMonth" width="100" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.billMonth}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="计税区域" field="taxArea" width="120" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.taxArea}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="社保人数" field="num" width="100" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.num}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="状态" field="status" width="50">
        <template slot-scope="scope"><span>{{scope.row.status | dictListFilter(status)}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="创建人" field="operName" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.operName }}</span></template>
      </bsp-table-column>

      <bsp-table-column title="创建时间" field="bizCreateTime" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.bizCreateTime}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="操作" fixed="right" width="120px" align="center">
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button type="text" @click="showDetails(scope.row)">查看</el-button>
            <el-button type="text" @click="_tocopy(scope.row)">复制</el-button>
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>
    <!-- 社保导入 -->
    <el-dialog
      title="社保导入"
      :visible.sync="impDialogVisible"
      width="55%"
      @opened="$refs.socialImp.init()"
      @close="$refs.socialImp._close()"
    >
      <social-security-imp ref="socialImp" @hiddenImp="hiddenImp"
                           @loadList="_socialSecurityBatchList"></social-security-imp>
    </el-dialog>
    <el-dialog title="社保复制" width="30%" :visible.sync="copyDialogVisible" @close ="_closeCopy">
      <el-form :inline="true" label-width="80px" :model="form" :rules="rules" ref="form">
        <el-form-item label="账单月份" prop="billMonth">
          <el-date-picker v-model="form.billMonth"
                          type="month"
                          value-format="yyyy-MM"
                          placeholder="选择月份" >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="_closeCopy">取 消</el-button>
          <el-button type="primary" @click="_copy">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import SocialSecurityImp from "@/views/entrust/socialSecurityImport/import/socialSecurityImp";
  import {getSocialSecurityBath, deleteSocialSecurityBatch ,copySocialSecurity } from '@/api/entrust/socialSecurity'

  export default {
    name: 'socialSecurityBatch',
    components: {
      BspTable,
      BspTableColumn,
      SocialSecurityImp
    },
    data() {
      var checkMonth = (rule, value, callback) => {
        if(value === ''){
          callback(new Error('请选择账单月份'));
        }
        else if (value === this.oldMonth) {
          callback(new Error('不能复制到同一个月'));
        }else {
          callback();
        }
      };
      return {
        impDialogVisible: false,
        copyDialogVisible: false,
        status: [
          {codeItemName: '新增', codeItemId: '0'},
          {codeItemName: '已关联', codeItemId: '1'}
        ],
        rules: {
          billMonth: [
            {required: true,message: '请选择账单月份'},
            { validator: checkMonth, trigger: 'change' }
          ]
        },
        form: {
          batchId: '',
          billMonth: ''
        },
        oldMonth: '',
        loading: false,
        list: [],
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
        },
        //多选数组
        multipleSelection: [],
      }
    },
    created() {
      // 1. 加载页面
      this._socialSecurityBatchList();

    },
    methods: {
      _copy() {
        this.$refs["form"].validate( async (valid) => {
          if (valid) {
            let res  = await copySocialSecurity(this.form);
            if(res.data.code===0){
              this.$message.success('复制成功！');
              this._closeCopy();
              this._socialSecurityBatchList();
            }else{
              this.$message.error(res.data.msg);
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });

      },
      _closeCopy(){
        this.copyDialogVisible = false ;
        this.$refs["form"].resetFields();
      },
      _tocopy(row) {
        this.oldMonth = row.billMonth;
        this.form.batchId = row.id;
        this.copyDialogVisible = true;
      },
      async _socialSecurityBatchList() {
        this.loading = true
        let res = await getSocialSecurityBath(this.listQuery);
        this.listQuery.total = res.data.total
        this.list = res.data.records
        this.loading = false
      },
      showDetails(row) {
        this.$router.push({path: '/entrust/socialSecurityDetail', query: {batchId: row.id}})
      },
      async _deleteData() {
        if (this.multipleSelection.length) {
          let set = [];
          for (var i = 0; i < this.multipleSelection.length; i++) {
            if (this.multipleSelection[i].status === '1') {
              this.$message.error('存在已关联账单的批次，无法删除！');
              return;
            }
            set.push(this.multipleSelection[i].id)
          }
          // 1. 进行删除操作
          let res = await deleteSocialSecurityBatch(set);
          console.log(res.data.code === 0);
          if (res.data.code != undefined && res.data.code === 0) {
            this.$message.success('删除成功！');
            this._socialSecurityBatchList();
          } else {
            this.$message.error(res.data.msg);
          }

        } else {
          // 没有数据 提示信息
          this.$message.info('请选择需要删除社保批次！');
        }
      },
      _importData() {
        this.impDialogVisible = true;
        console.log(this.$refs.socialImp);
      },

      hiddenImp() {
        this.impDialogVisible = false;
      },
      selectChange(value) {
        console.log(value);
        if (value.selection) {
          this.multipleSelection = [];
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index];
            this.multipleSelection.push(element);
          }
        }
      },
    }
  }
</script>
<style scoped>


</style>
