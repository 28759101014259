<template>
  <div>
    <!-- <el-form :inline="true" :model="listQuery" class="editForm">
      
    </el-form> -->
    <div class="divButtonRigth" style="margin-top: -10px;  margin-bottom: 10px">
      <el-button type="primary"  fixed="right" @click="goSave" icon="el-icon-folder-add"> 保 存 
      </el-button>
      <el-button type="primary"  fixed="right"  @click="goDelete" icon="el-icon-delete">
        删 除
      </el-button>
      <el-button type="primary"  fixed="right" @click="goback" icon="el-icon-refresh-left"> 返 回 
      </el-button>
    </div>
    <bsp-table
      v-if="refresh"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      ref="publicTable"
      :data.sync="list"
      :tree-config="true"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="searchDetail"
      show-header-overflow show-overflowwidth show-overflow
      show-footer-overflow
      show-footer
      :footer-method="footerMethod"
      height="500"  
      :iKeepSource="true"
      :cell-style="cellStyle"
      :cell-class-name="cellClassName"
      :edit-rules="validRules"
      :editorConfig="{trigger: 'dblclick', mode: 'row',showStatus:true}"
      @select-change="selectChange"
      @select-all="selectChange"
    >
      <vxe-table-column type="selection" width="40" fixed="left"/>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <bsp-table-column title="客户名称" field="customerName"  width="100" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.customerName}}</span></template>
      </bsp-table-column>
      <bsp-table-column v-for="(_column,index) in noChangeCol" :key="index" :title="_column.ITEM_NAME" :field="_column.COLUMN_CODE.toLowerCase()"  width="100" :filter-type="_column.filter" :fixed="_column.ITEM_NAME == '雇员姓名'? 'left':null">
        <template slot-scope="scope"><span>{{scope.row[_column.COLUMN_CODE.toLowerCase()] | dictFilter(dicts) }}</span></template>
      </bsp-table-column>
      <bsp-table-column v-for="(_column,index) in changeCol" :key="index" :title="_column.ITEM_NAME" :field="_column.COLUMN_CODE.toLowerCase()"  width="100" :filter-type="_column.filter" :edit-render="_column.editRender">
        <template slot-scope="scope"><span>{{scope.row[_column.COLUMN_CODE.toLowerCase()] | dictFilter(dicts) }}</span></template>
      </bsp-table-column>
    </bsp-table>
  </div>
</template>

<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  // import {searchUserList} from '@/api/user/user';
  import { getTableColumn, getTableData,updateCopyDetail , delCopyDetail, checkCopyEnd} from '@/api/entrust/batchCopy';
  import {mapGetters} from 'vuex';

  export default {
    name: "CopySalaryDetail",
    inject: ['reload'],
    components: {
      BspTable,
      BspTableColumn
    },
    computed: {
      ...mapGetters([
        'dicts', "userInfo"
      ]),
    },
    created() {
     // 加载表格头信息。
     // this.loading = true;
     this.getColumns();
    },

    data() {
      return {
        // 用于接收后台返回的分页数据
        refresh:true,
        activeName: '1',
        loading: false,
        list: [],
        query:this.$route.query,
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          batchId:null,
          fields:[],// 所有项field集合
        },
        changeCol:[],// 可修改表格头
        noChangeCol:[],// 不可修改表格头
        changeField:[], // 修改项field集合
        dictCol:['E204271','E204591','E204617'], // 字典字段
        dictCOlOptions:{}, // 字典值options
        localMenu: null,
        currentUser: '',
        orgName: '',
        relOrgList: [],
        //多选数组
        multipleSelection:[],
        amountRules :[
          { type: 'number', message: '输入存在非法字符' },
          { type: 'number' ,min: -9999999999, max: 9999999999, message: '输入 [-9999999999] ~ [+9999999999]范围内值' },
          { pattern: /^[0-9]+(.[0-9]{1,2})?$/ , message: '金额字段只允许保留两位小数部分' },
        ],
        stringRules:[
          { type: 'string', min: 0, max: 100, message: '输入字符超长' },
        ],
        requiredRule:{ required: true, message: '必须填写' },
        requiredCol:['e204256'],
        validRules:{
        },
      }
    },

    methods: {
        // 点选/全选复选框
      selectChange(checked) {
        console.log("==============>",checked.checked);
        if (checked.checked) {
          const list = checked.selection;
          this.multipleSelection = [];
          for (let index = 0; index < list.length; index++) {
            const element = list[index];
            this.multipleSelection.push(element);
          }
        } else {
          if (checked.$rowIndex == undefined) {
            this.multipleSelection = [];
          } else {
            this.multipleSelection = checked.selection;
          }
        }
      },
      async getColumns(){
        this.loading = true;
        this.columns= [];
        //获取列头
        const obj = this.query;
        const tableColumn = await getTableColumn(obj);
        
        console.log(tableColumn)
        if(tableColumn.data.code == '0'){
          this.changeCol = tableColumn.data.data.changeCol;
          this.noChangeCol = tableColumn.data.data.noChangeCol;
          const rulesGourp = tableColumn.data.data.validRules;
          rulesGourp.number.forEach((column)=>{
            this.validRules[column] =  this.amountRules;
          })
          rulesGourp.string.forEach((column)=>{
            this.validRules[column] =  this.stringRules;
          })
          this.requiredCol.forEach(required=>{
            console.log(required);
            console.log(this.validRules[required]);
            const temp = [...this.validRules[required],this.requiredRule]
            this.validRules[required] = temp;
          })
          this.listQuery.fields = tableColumn.data.data.fields;
          console.log(this.validRules);
          // this.$refs.publicTable.refreshColumn()
        }
        this.loading = false;
        this.searchDetail();
        //this.refresh = true;
      },
      goSave(){
        //保存明细数据
        console.log('明细保存');
        if (this.list.length == 0 ){
          this.$message.error("明细已被删除，不支持保存操作!");
          return;
        }
        const editorData = this.$refs.publicTable.editorRowData();
        if (this.multipleSelection.length === 0) {
          // 保存全部修改信息
          if (editorData.length === 0) {
            // this.$message.warning("请修改明细数据后进行保存。");
            this.$confirm('是否前往明细校验页面？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'success'
            }).then(() => {
                const query = this.query;
                this.$router.push({ path: '/wt/copyParent',query});
            }).catch((err) =>{
              //点击取消清空勾选
              this.multipleSelection = [];
            })
          }else {
            this.saveDetail(editorData);
          }
        }else{
          // 保存勾选修改信息
          if (editorData.length === 0) {
            this.$message.warning("请修改明细数据后进行保存。");
          }else {
            const toSaveData = this.checkUpdateRow(this.multipleSelection);
            // 根据修改的数据找到勾选的数据进行保存
            if(toSaveData.length == 0){
              this.$message.warning('勾选的明细数据没有没有修改内容，不能进行保存。');
            }else{
              this.saveDetail(toSaveData);
            }
          }
        }
      },
      checkUpdateRow(rowData){// 校验勾选数据是否存在更新
        let filterData = [];
        if (rowData.length == 0) {
          return filterData;
        }
        const editorData = this.$refs.publicTable.editorRowData();
        if(editorData.length != 0 ){
          filterData = editorData.filter(item => rowData.indexOf(item) > -1);
          return filterData;
        } else {
          return filterData;
        }
      },
      async saveDetail(obj){ // 保存明细
        const copyEnd = await checkCopyEnd({tempBatchId: this.query.id});
        if(copyEnd.data.data){
          this.$confirm(copyEnd.data.msg + "已生成批次号：" + copyEnd.data.data, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            const query = {   //getDynamicForm参数
              currentTab:this.$store.state.impData.bizType,//业务类型
            }
            this.$router.push({ path: '/wt/detailImportBatchTabs',query});
          })
          return;
        }
        this.fullValidEve(obj).then(()=>{
          // 校验成功,保存数据
          this.loading = true;
          this.getChangeColumnCode();
          const _update = {data:obj,changeCol :this.changeField,batchId: this.query.id} 
          updateCopyDetail(_update).then(res => {
            console.log("保存结果====>",res);
            if (res.data.success == true) {
              this.$message.success('保存成功');
              this.loading = false;
              this.gocheck(); //询问用户是否前往校验
            } else {
              this.$message.error("保存失败：" + res.data.msg);
              this.loading = false;
            }
            this.searchDetail();
          })
        }).catch((errMap)=>{
          console.log("失败",errMap);
          // 校验失败
          
          this.$message.error("校验不通过！" );
          // let msgList = []
            // Object.values(errMap).forEach((errList) => {
            //   errList.forEach((params) => {
            //     const { row, column, rules } = params
            //     console.log("==================>",params);
            //     rules.forEach((rule) => {
            //       msgList.push(`雇员： ${row.e204344}   ${column.title} 校验错误：${rule.message}`)
            //     })
            //   })
            // })
            // // console.log(msgList);
            // this.$message.error( {
            //   dangerouslyUseHTMLString: true ,
            //   message:"保存失败<br>" + msgList.join("</br>")
            // });
        })
      },
      async fullValidEve(renameDate) { //编辑框校验
        // console.log("要校验的数据",renameDate);
        const $table = this.$refs["publicTable"].$refs["ciicTable"]
        return await $table.fullValidate(renameDate,this._callbackValidEve());
      },
      _callbackValidEve(){
        console.log("这里是校验之后的会调函数");
      },
      getChangeColumnCode(){
        this.changeField = [];
        this.changeCol.forEach(element => {
          this.changeField.push(element.COLUMN_CODE.toLowerCase()); // 获取需要用小写
        });
      },
      gocheck(){
        this.$confirm('是否开始自动生成明细，点击确认将进入检验页面，请确认', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        }).then(() => {
            const query = this.query;
            this.$router.push({ path: '/wt/copyParent',query});
        }).catch(()=>{
          // 取消清空勾选
          this.multipleSelection = [];
        })
      },
      async goDelete(){
        // 删除明细
        if (this.multipleSelection.length === 0) {
          this.$message.error("请选择要删除的明细数据。");
        } else{
        
        const copyEnd = await checkCopyEnd({tempBatchId: this.query.id});
        if(copyEnd.data.data){
          this.$confirm(copyEnd.data.msg + "已生成批次号：" + copyEnd.data.data, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            const query = {   //getDynamicForm参数
              currentTab:this.$store.state.impData.bizType,//业务类型
            }
            this.$router.push({ path: '/wt/detailImportBatchTabs',query});
          })
          return;
        }

          let delIds = []
          this.multipleSelection.forEach(element => {
            console.log();
            delIds.push(element.subid)
          });
          const fullDelFlag = delIds.length == this.listQuery.total;
          let batchId = '';
          let msg = "";
          if(fullDelFlag){
            batchId = this.multipleSelection[0].batchId
            msg = "明细数据全部被删除，临时批次会被同时删除，是否确认删除？";
          }else {
            msg = "删除后的明细数据将不可恢复，是否确认删除？"
          }
          const obj = {delIds:delIds ,fullDel: fullDelFlag, batchId:batchId}
          this.$confirm(msg, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.loading = true;
              delCopyDetail(obj).then((result) => {
                if(result.data.code == '0'){
                  this.$message.success("删除成功");
                }else{
                  this.$message.error("明细删除失败！失败原因：" + result.data.msg);
                }
                this.loading = false;
                this.searchDetail();
                this.multipleSelection = []; //
              }).catch((err) => {
                console.log("删除明细发生错误",err);
              });
            }).catch((err) => {
              console.log("取消");
            })
        }
      },
      goback(){
        //返回 bizType 未提交
        const editorData = this.$refs.publicTable.editorRowData();
        if (editorData.length > 0) {
          this.$confirm('存在修改未保存数据，是否继续返回主页面。', '返回', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              const query = {   //getDynamicForm参数
                  currentTab:this.$route.query.bizType,//业务类型
              }
              this.$router.push({ path: '/wt/detailImportBatchTabs',query});
            })
        }else {
          const query = {   //getDynamicForm参数
              currentTab:this.$route.query.bizType,//业务类型
          }
          this.$router.push({ path: '/wt/detailImportBatchTabs',query});
        }
      },
      async searchDetail(){
        this.loading = true;
        console.log("查询。", this.loading);
        this.listQuery.batchId = this.query.id;
          
         await getTableData(this.listQuery).then((result) => {
          if (result.data.code != 0) {
            this.listQuery.total = result.data.total;
            this.list = result.data.records;
            this.loading = false;
            this.refresh = true;
          } else {
            this.loading = false;
            this.refresh = true;
          }
        }).catch((err) => {
          console.log("查询列表明细错误",err);
        });

      }
    }
  }
</script>

<style scoped>

</style>
