<template>
  <div>
  <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right"   @click="commitPartBatch">提交</el-button>
      <el-button type="primary" size="small" fixed="right"   @click="cusRtnDetail">返回</el-button>
  </div>

    <bsp-table
      :data="detailImportCusList"
      :loading="loading"
      :ciic-data-filter.sync="cuslistQuery"
      @select-change="cusSelectChange"
      @select-all="cusSelectAll"
    >

      <vxe-table-column type="selection" width="60" fixed="left"/>
      <bsp-table-column title="导入批次号" field="batchCode" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.batchCode}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="客户名称" field="cusName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
      </bsp-table-column>


   <bsp-table-column  title="税金总金额"  field="businessTotalMoney"    filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.businessTotalMoney}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="服务总金额"  field="serviceTotalMoney"     filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.serviceTotalMoney}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="状态"  field="statusName"    fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.statusName}}</span>  </template>
      </bsp-table-column>
      <bsp-table-column  title="是否垫付"  field="isAdvance"  fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.isAdvanceName}}</span>  </template>
      </bsp-table-column>

     <bsp-table-column  title="操作"  fixed="right"  width="150px"   align="center" >
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button  type="text" @click="cusShowDetails(scope.row)"  >查看</el-button>
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>


  </div>
</template>
<script>
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {commitPartBatch} from '@/api/entrust/detailImport';
  import {mapGetters} from 'vuex';

  export default {
    name: 'detailImportCusList',
    inject:['reload'],
    components: {
      BspTable,
      // eslint-disable-next-line vue/no-unused-components
      BspTableColumn
    },
    computed: {
      ...mapGetters([
        'dicts'
      ]),
    },
    props: {
      currentTab: {
        type: String,
        default: ''
      },
      detailImportCusList:{
        type: Array,
        default () {
          return []
        }
      },
      cuslistQuery:{
        type: Array,
        default () {
          return []
        }
      },
    },
    created() {
      console.log("进入客户明细页面");
    },
    data() {
      return {
        // 遮罩
        loading: false,
        dialogVisible: false,
        multipleCusSelection: [],
        cusParameter: {
          batchImportId:'',
          bizType:'',
          nopackBatchIds:'',
        },
      }
    },
    methods: {
      cusSelectChange(value) {
        console.log(value);
        if (value.selection) {
          this.multipleCusSelection = [];
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index];
            this.multipleCusSelection.push(element);
          }
        }
      },
      //复选框全选事件
      cusSelectAll({checked, selection}) {
        if(checked){
          this.multipleCusSelection= selection
        }else{
          this.multipleCusSelection=[];
        }
        console.log("this.multipleCusSelection:"+this.multipleCusSelection);
      },

      cusShowDetails(row) {
          console.log("业务类型："+row.bizType+";批次id："+row.id);
          this.$emit("lookDetail",row)
      },
      cusRtnDetail(){
        this.$emit("rtnDetail")
      },
      //部分提交
      commitPartBatch(){
        if(this.multipleCusSelection.length<=0){ this.$message.error("请选择要提交的批次！");return; }
        let flag='true';
        //将选中的id组装为string
        this.multipleCusSelection.forEach(item=> {
         if(item.status!="3004000037" && item.status!="3004000041"){
           flag='false';
         }
          this.cusParameter.batchImportId=item.importBatchId;
          this.cusParameter.bizType=item.bizType;
          this.cusParameter.nopackBatchIds=this.cusParameter.nopackBatchIds+","+item.id;
        });
        console.log("batchImportIds："+this.cusParameter.batchImportId+"==="+this.cusParameter.bizType);
        if( flag=='false'){
          this.$message.error("只有对公组长退回的批次才允许再次提交！");return;
        }else{
          commitPartBatch(this.cusParameter).then(res => {
            if(res.data.data.msg=="success"){
              this.$message.success("提交成功！");
              this.$emit("submitData")
            }else{
              this.$message.error(res.data.data.msg);
            }
          })
        }
      },
    }
  }
</script>
<style scoped>
  .copy-btn{
    cursor: pointer;
  }
  .ciic-table-operate{
    align-items: center;
  }
</style>
