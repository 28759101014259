<template>
  <div class="defaultInvoice">
    <div class="invoiceDetail">
      <div class="invoiceTypeTitle">
        <div class="invoiceTypeName">{{invoiceTypeName}}</div>
        <p class="invoiceCompanyName">{{invoiceCompanyName}}</p>
      </div>
      <div class="invoiceLists" >
        <div class="invoiceList">
          <div class="invoiceCount">
            <span>403291</span>
            <span>账单编号</span>
          </div>
          <div class="invoiceCount">
            <span>4</span>
            <span>发票张数</span>
          </div>
          <div class="invoiceCount">
            <span>12,000.00</span>
            <span>工资代收代付</span>
          </div>
          <div class="invoiceCount">
            <span>1,000.00</span>
            <span>服务费</span>
          </div>
          <div class="invoiceCount">
            <span>200.00</span>
            <span>销项税额</span>
          </div>
        </div>
        <div class="invoiceListBtn" v-if="invoiceActive === 3">
          <el-button type="primary" size="small" >增加</el-button>
        </div>
      </div>
      <vxe-grid
        class="invoiceTable"
        ref="refname"
        resizable
        show-footer
        show-footer-overflow
        height="300"
        :footer-method="footerMethod"
        :columns="names"
        :data="datas"
        :edit-config="{trigger: 'click', mode: 'row', showStatus: true, icon: 'fa fa-file-text-o'}"
        :loading="loading"
        keep-source
      >
        <template v-slot:operation="{ row }" v-if="invoiceActive === 3">
          <vxe-button status="primary" @click="deleteData">
            <div class="invoiceIcon">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-shanchukehu"></use>
              </svg>
            </div>
          </vxe-button>
        </template>
      </vxe-grid>
      <div class="tancengBtn">
        <el-button type="primary" size="small" >确定</el-button>
        <el-button type="" size="small" @click="backPrev">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "defaultInvoice",
    props:{
      invoiceTypeName:{
        type: String,
        default: ''
      },
      invoiceCompanyName:{
        type: String,
        default: ''
      },
      invoiceActive:{
        type:Number,
        default:1
      },
      sumItem:{
        type: Array,
        default () {
          return []
        }
      },
      names:{
        type: Array,
        default () {
          return []
        }
      },
      datas: {
        type: Array,
        default () {
          return []
        }
      },
      refname: {
        type: String,
        default: ''
      }
    },
    data(){
      return{

      }
    },
    mounted(){
      const datas = this.datas.filter(item => item.total&&item.tax )
      datas.map((column) => {
        column.xiaotax = column.total*parseFloat(column.tax)/100
        return column
      })
    },
    methods: {
      footerMethod ({ columns, data }) {
        return [
          columns.map((column, columnIndex) => {
            if (columnIndex === 0) {
              return '合计'
            }
            if (this.sumItem.includes(column.property)) {
              return this.$utils.sum(data, column.property)
            }
            return null
          })
        ]
      },
      deleteData(){
        return ""
      },
      backPrev(){
        this.$emit('backPrev')
      }
    }
  };
</script>

<style scoped>

</style>
