import request from '@/router/axios';
const urlPrefix = ''

/**
 * 更新智能匹配次数
 * @param obj
 */
export  function updateColumnMatchNumber(obj) {
  return request({
    url:urlPrefix+ '/cus/updateColumnMatchNumber',
    method: 'get',
    params: obj,
  })
}

/**
 * 获取智能匹配次数
 * @param obj
 */
export  function getColumnMatchNumber() {
  return request({
    url:urlPrefix+ '/cus/getColumnMatchNumber',
    method: 'get',
  })
}

/**
 * 获取应发配置角色IDs
 * @param obj
 */
export function getPayableRoleList() {
  return request({
    url:urlPrefix+ '/cus/getPayableRoleList',
    method: 'get',
  })
}

/**
 * 更新应发配置角色IDs
 * @param obj
 */
export  function updatePayableRoleList(obj) {
  return request({
    url:urlPrefix+ '/cus/updatePayableRoleList',
    method: 'get',
    params: obj,
  })
}



