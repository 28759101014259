<template>
  <div class="outer-layer">
    <bsp-tabs :tabList="tabList" :tabIndex="tabIndex" @changeTab="changeTab">
      <component  v-if="isRouterAlive" ref="pub" :is="currentTab" :currentTab='currentTab' :batchStatus="batchStatus"></component>
    </bsp-tabs>
  </div>
</template>

<script>
  import bspTabs from "@/components/bsp/bsp-tabs/bsp-tabs";
  import bankDirectDetailList from "@/views/bankDirect/bankDirectDetail";
  import bankDirectOverageList from "@/views/bankDirect/bankDirectOverage";
  import {mapGetters} from "vuex";
  export default {
    computed: {
      ...mapGetters(['userInfo']),
    },
    created(){
      console.log("当前用户 机构id："+this.userInfo.orgId);
      //TODO 此处增加对业务类型显示
      this.tabList= [{
        title: '查询交易明细',
        name: '1111111111'
      },{
        title: '查询余额',
        name: '2222222222'
      }];
      this.tabIndex = this.$route.query.currentTab ? this.$route.query.currentTab : '1111111111';
      this.currentTab = this.tabIndex;
      // }
    },
    data() {

      return {
        tabList:[],
        tabIndex: "",
        currentTab:"",
        isRouterAlive:true,
      }
    },
    provide(){
      return{
        reload:this.reload
      }
    },
    components:{
      bspTabs,
      1111111111: bankDirectDetailList,
      2222222222: bankDirectOverageList,
    },
    methods: {
      changeTab(tab, event) {
        this.currentTab = tab.name;
        this.reload();
        console.log("hello everybody~~~this.currentTab==");
        console.log(this.currentTab);
      },
      reload (){
        this.isRouterAlive = false
        this.$nextTick(function(){
          this.isRouterAlive = true
        })
      }
    }
  }
</script>

<style scoped>

</style>
