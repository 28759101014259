import request from '@/router/axios';

let urlPattern = '/audit';


// 修改操作人
export function operationHistoryList(obj) {
  return request({
    url:  urlPattern +'/operationHistoryList',
    method: 'post',
    data: obj
  })
}
export function operationLoginList(obj) {
  return request({
    url:  urlPattern +'/operationLoginList',
    method: 'post',
    data: obj
  })
}
