<template>
  <div v-loading="loading" style="padding:10px 0;min-height: 400px;"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
  >
    <div class="customer-info" v-for="(ItemList,listIndex) in dynamicFormItemList" :key="ItemList.groupName">
      <div class="customer-info-item" v-show="ItemList.groupName !==undefined && ItemList.groupName" @click="changeShow(listIndex)">
        <div class="customer-info-title">
          <!--图标-->
          <i class="el-icon-caret-right" v-if="showList[listIndex]== 'false'"></i>
           <i class="el-icon-caret-bottom" v-if="showList[listIndex]== 'true'"></i>
          <span>{{ItemList.groupName}}</span>
        </div>
        <div class="customer-info-button">
          <!--编辑-->
          <el-link type="primary" @click.stop="changeEdit(listIndex)" v-show="editList[listIndex] =='false'">编辑</el-link>
          <el-link type="primary" @click.stop="cancelHandler('form2'+listIndex,listIndex)" v-show="editList[listIndex] =='true'">取消</el-link>
          <el-link type="primary" style="margin-left:20px;" @click.stop="submitHandler('form2'+listIndex,listIndex)" v-show="editList[listIndex] =='true'">保存</el-link>
        </div>
      </div>
      <div v-if="showList[listIndex] =='true'" v-show="ItemList.groupName !==undefined && ItemList.groupName"
           class="editForm">
        <el-row v-if="editList[listIndex] ==='true'">
          <el-form :ref="'form2'+listIndex" :model="validateForm" label-width="168px" :rules="rules">
            <el-col
              v-for="(list,index) in ItemList.dynamicFormVOList" :key="list.itemId"
              :span="list.itemType==4||list.itemType==10 ? 24:8"
              :class="{'hidden':list.visiable || !isShow[list.valueId+'_show'],'titleDot':list.titleNull}">
              <bspFormItem :list="list" v-if="(list.itemName == 'E001_operMode')" :showNum="showOperMode" :index="index" v-on="{...list.infoItemEventList}" :isShow ="isShow[list.valueId+'_show']" :model.sync="formData[list.valueId]"/>
              <bspFormItem :list="list"  v-else :index="index" v-on="{...list.infoItemEventList}" :isShow ="isShow[list.valueId+'_show']" :model.sync="formData[list.valueId]"/>
            </el-col>
          </el-form>
        </el-row>
        <el-row v-if="editList[listIndex] =='false'" :class="{onlyRead:editList[listIndex] =='false'}">
          <el-form label-width="168px">
            <el-col :span="8" :class="{'hidden':list.visiable || !isShow[list.valueId+'_show']}" v-for="list in ItemList.dynamicFormVOList"
                    :key="list.itemId">
              <el-form-item :label="list.titleName" v-show="isShow[list.valueId+'_show']">
                <p class="formText" v-if="list.itemType==6">{{formData[list.valueId] | bspTableFilter({'1':'dict','2':dicts},true)}}</p>
                <p class="formText" v-else-if="list.itemType==23">{{getCityName(formData[list.valueId])}}</p>
                <p class="formText" v-else-if="list.itemType==15 ">{{formData[list.valueId] | bspTableFilter({'1':'ref','3':list.refData},true)}}</p>
                <p class="formText" v-else>{{formData[list.valueId]?formData[list.valueId]:'--'}}</p>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
  // import {getQuitEmpInfoTab} from "@/api/cus/emp/quitEmp";
  // import {getDynamicEmpInfoById,checkEmpNumberByNumber,checkEmpEmail,checkEmpPhone} from "@/api/cus/emp/emp";
  // import {getDynamicForm, DynamicFormInsert, DynamicFormUpdate} from "@/api/common/common";
  import bspFormItem from '@/wage-components/bsp-form-item/index';
  import {mapGetters} from 'vuex';
  // import { init,codeList,codeToName,nameToCode,getCodeNames,getNameCodes} from "@/components/bsp/code/frontCode";
  // import {getDataByUrl } from  "@/api/common/common";
  // import {getOperationModeListByCusId} from  "@/api/biz/configCenter/salarySetting";
  // import {getOperModeListByCusId} from  "@/api/biz/configCenter/configcenter";
  import {cardid,validateEmail,validatenumber,isvalidatemobile} from '@/util/validate';

  export default {
    props: {
      currentTab: {
        type: String,
        default: ''
      },
      cusId: {
        type: Number,
        default: 0
      },
      empId: {
        type: Number,
        default: 0
      },
      tabType: {
        type: String,
        default: ''
      },
      isUpdateForm: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters([
        'dicts',
      ]),
    },
    data() {
      // 校验雇员编号
      // const validateEmpNumber = (rule,value,callback)=>{
      //     if (!this.repeatEmpNumber) {
      //         callback(new Error("雇员编号重复，请重新输入！"));
      //     } else {
      //         callback();
      //     }
      // };

      return {
        loading: false,
        editList: [],
        costList:[],
        showList: [],
        operModeList:[],
        showOperMode:'',
        repeatEmpNumber:true,
        isShow: {},
        dynamicFormItemList: '',
        ifHaveTextarea: 0,
        noVisible: 0,
        itemTypeArr: [],
        costName:"",
        parameter: {   //getDynamicForm参数
          operateType: 1,//表单是新增还是修改判断,0是新增，1是修改
          infoSetId: 'EMP_INFO',
          cusId: this.cusId,
          empInfoId: 0,
          tabType: '',
          costCentreId: 0
        },
        rules: {
          //  E001_empNumb: [
          //   {required: true, validator: validateEmpNumber, trigger: 'change'},
          // ],
        },
        formData: {},
        oldeFromData: {},
        fieldList: [],
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        },
        validateForm: {},
      }
    },
    components: {
      bspFormItem
    },
    watch: {
      formData: {
        handler(newValue, oldeValue) {
          if (oldeValue && !Object.keys(oldeValue).length == 0) {
            console.log("两个对象是否相等",newValue==oldeValue)
            if(newValue==oldeValue){
              console.log("两个对象是否相等333")
            }
            // form 对象内部的值变化时，设置 formChange
            this.isUpdateForm = true
            this.$emit("update:isUpdateForm", true);
          }
          console.log("this.isUpdateForm",this.isUpdateForm)
        },
        immediate: true,
        deep: true
      },
      rules:{
        handler(newValue, oldeValue) {
          // 得到数据后 设置身份证验证
          this.validatorIdCard(this.formData.E001_idType);
        },
        immediate: true,
        deep: true
      } ,
      dynamicFormItemList: function (newVal, oldVal) {
          this.initRefData(newVal);
      }
    },
    created() {
      this._getDynamicForm();
      init("city");
      this._getDynamicFormValue();
      this._getOperModeListByCusId()
    },
    mounted() {
    },
    provide() {
      return {
        $rules: this.rules
      }
    },
    methods: {
        /**
         *初始化列表中有引用类型的字段
         * 进行翻译时所需要的数据
         */
        async initRefData(value){
            value.forEach(i=>{
                i.dynamicFormVOList.forEach (item=>{
                    if(item.itemType=='15') {
                        //判断url中没有没有指定的获取参数
                        let url = item.refUrl;
                        let refParams = item.refParams;
                        refParams = {
                            "cusId":this.cusId
                        }
                        if(url){
                            let key, name,isAppoint;
                            if (url.indexOf("&") > 0) {
                                let split = url.split("&");
                                url = split[0];
                                key = split[1];
                                name = split[2];
                                isAppoint = true;
                            }else {
                                isAppoint = false;
                            }
                            if(!refParams){
                                getDataByUrl(url).then(res => {
                                    let dataMap = res.data.data;
                                    let retMap = new Object();
                                    // 判断是不是数组类型 如果是数组类型则转换成化为对象
                                    if (dataMap instanceof Array) {
                                        for (let m in  dataMap) {
                                            let keyV = dataMap[m][key];
                                            let nameV = dataMap[m][name];
                                            retMap[keyV] = nameV;
                                        }
                                        this.$set(item, 'refData', retMap);
                                    } else {
                                        if(isAppoint){
                                            retMap[dataMap[key]]=dataMap[name];
                                            this.$set(item, 'refData', dataMap);
                                        }else {
                                            this.$set(item, 'refData', dataMap);
                                        }
                                    }
                                })
                            }else {
                                getDataByUrl(url,refParams).then(res=>{
                                    let dataMap = res.data.data;
                                    let retMap = new Object();
                                    if (dataMap instanceof Array) {
                                        for (let m in  dataMap) {
                                            let keyV = dataMap[m][key];
                                            let nameV = dataMap[m][name];
                                            retMap[keyV] = nameV;
                                        }
                                        this.$set(item, 'refData', retMap);
                                    }else {
                                        if(isAppoint){
                                            retMap[dataMap[key]]=dataMap[name];
                                            this.$set(item, 'refData', dataMap);
                                        }else {
                                            this.$set(item, 'refData', dataMap);
                                        }
                                    }
                                })
                            }


                        }
                    }
                });
            });
        },
      getCityName(codes){
        return getCodeNames(codes);
      },
        //根据cusId查询雇员可选的操作模式
        _getOperModeListByCusId(){
            getOperModeListByCusId(this.parameter).then(res =>{
                if(res.data.data != null){
                  this.showOperMode = res.data.data.operMode;
                }
            })
        },
      // 取消
      cancelHandler(formName, index) {
        // 关闭修改
        this.changeEdit(index);
         // 并把修改后的值设置成原来的值
        this.formData = JSON.parse(JSON.stringify(this.oldeFromData));
          this.formData = this.oldeFromData;
          // 把当前页面是否修改 改成 false
          this.isUpdateForm = false;
          this.$emit("update:isUpdateForm",false);
      },
      // 提交
      submitHandler(formName,index) {
        console.log("this.$refs[formName][0]==",this.$refs[formName][0])
        this.$refs[formName][0].validate((valid) => {
          if (valid) {
              // 校验雇员编号
              if (!this.repeatEmpNumber) {
                  this.$message.warning("当前客户下雇员编号已存在，请重新输入！")
                  return;
              }
              //修改保存
              let obj = {};
              obj.infoSetId = this.parameter.infoSetId;
              obj.formData = this.formData;
              if (obj.formData.E001_workCity) {
                  obj.formData.E001_workCity = obj.formData.E001_workCity.toString();
              }
              if (obj.formData.E001_education) {
                  obj.formData.E001_education = obj.formData.E001_education.toString();
              }
              if (obj.formData.E001_regAddr) {
                  obj.formData.E001_regAddr = obj.formData.E001_regAddr.toString();
              }
              console.log("需要保存的基本信息=", obj.formData)
              DynamicFormUpdate(obj, '/cus').then(res => {
                  if (res.data.data.status) {
                      //把老的值赋值成 新保存后的值
                      this.oldeFromData = this.formData;
                      this.$message.success('修改成功');
                      this.cancelHandler(formName, index);
                  } else {
                      this.$message.error(res.data.data.msg);
                  }
              })
          }else{
              this.$message.warning("校验未通过")
          }

        })
      },
      // 提交
      allSubmitHandler() {
        //如果没有修改 则 提示没有可保存的数据
        if (!this.isUpdateForm) {
          this.$message.warning("没有可保存的数据");
          return;
        }
        let length = this.dynamicFormItemList.length;
        let isValid = false;
        for (let i = 0; i < length; i++) {
          let formName = 'form2' + i;
          //只有form 表单是 在编辑状态下才可以进行校验
          if (this.editList[i] === "true") {
            console.log(this.$refs[formName], "from :" + formName);
            this.$refs[formName][0].validate((valid) => {
              if (valid) {
                isValid = true;
              } else {
                isValid = false;
                return;
              }

            })
          }
        }

        // 表单校验 通过后才可以保存
        if (isValid) {
          //修改保存
          let obj = {};
          obj.infoSetId = this.parameter.infoSetId;
          obj.formData = this.formData;
          DynamicFormUpdate(obj, '/cus').then(res => {
            this.$message.success('修改成功');
            //把老的值赋值成 新保存后的值
            this.oldeFromData = this.formData;
          })
        }
      },
      //获取表单各项数据
      _getDynamicForm() {
        this.parameter.infoSetId = this.currentTab;
        getDynamicForm(this.parameter).then(res => {
          console.log("返回的数据表单数据：",res)
          let insertForm = {
            codeSetId: null,
            colspan: 2,
            infoItemEvent: "",
            inputType: null,
            itemId: "",
            itemLength: null,
            itemName: "",
            itemType: "1000",
            itemVType: "",
            null: true,
            read: false,
            refUrl: null,
            titleName: "",
            titleNull: true,
            valueId: "",
            visiable: false
          };
          res.data.dynamicFormItemList.forEach(item => {
            this.editList.push("false");
            this.showList.push("true");
            this.ifHaveTextarea = 0;
            this.noVisible = 0;
            item.dynamicFormVOList.forEach(i => {
              if (i.itemType == 4 || i.itemType == 10) {
                this.ifHaveTextarea += 1;
              }
              if (i.visiable == true) {
                this.noVisible += 1;
              }
              let valueId = i.valueId;
              // 添加是否显示的 属性 用来做控制显示和隐藏
              let showke=valueId+'_show';
              this.isShow[showke] = true;
              // 当前雇员状态是不是离职状态 则隐藏 离职日期
              // 由于表单的回显值和表单的数据查询都是异步不能确定那个请求先执行完成所在在这里在当表单回显值不为空时在进行一次判断
              if(showke=='E001_depaTime_show'){
                if(this.formData){
                  if(this.formData.E001_empStatus!='10350003'){
                    this.isShow['E001_depaTime_show'] = false;
                  }
                }
              }
              // 把函数名转换成函数类型
              let eventList = i.infoItemEventList;
              if(eventList){
                let evenKeys = Object.keys(eventList);
                evenKeys.forEach(k =>{
                  i.infoItemEventList[k]=this[eventList[k]];
                })
              }
            });
            if ((item.length - this.ifHaveTextarea - this.noVisible) % 2 != 0 && this.ifHaveTextarea != 0) {
              let insertIndex = this.itemTypeArr.indexOf("4");
              let insertIndex1 = this.itemTypeArr.indexOf("10");
              if (insertIndex != -1 && insertIndex1 == -1) {
                item.dynamicFormVOList.splice(insertIndex, 0, insertForm);
              } else if (insertIndex == -1 && insertIndex1 != -1) {
                item.dynamicFormVOList.splice(insertIndex1, 0, insertForm);
              } else if (insertIndex != -1 && insertIndex1 != -1) {
                if (insertIndex > insertIndex1) {
                  item.dynamicFormVOList.splice(insertIndex1, 0, insertForm);
                } else {
                  item.dynamicFormVOList.splice(insertIndex, 0, insertForm);
                }
              }
            }
            ;
          });
          this.dynamicFormItemList = res.data.dynamicFormItemList;
          if (this.parameter.operateType == 0) { //新增
            res.data.dynamicFormItemList.forEach(item => {
              item.dynamicFormVOList.map(list => {
                this.formData[list.valueId] = ''
              })
            });
          }
        })
      },
      //获取表单回显值
      _getDynamicFormValue() {
        this.loading = true;
        this.parameter.empInfoId = this.empId;
        this.parameter.cusId = this.cusId;
        this.parameter.infoSetId = 'EMP_INFO';
          //在职查询
        if(this.tabType=='incumbency'){
          getDynamicEmpInfoById(this.parameter).then(res => {
              console.log("在职雇员信息res=",res)
            let formObj = res.data.data;
            if(formObj.E001_empStatus!='10350003'){
              this.isShow['E001_depaTime_show'] = false;
            }

              this.formData = formObj;
            var oldData = JSON.parse(JSON.stringify(res.data.data));
            this.oldeFromData = oldData;
            this.loading =false;
          }).catch(()=>{
              this.loading = false;
          })
        }else {
           getQuitEmpInfoTab(this.parameter).then(res => {
            this.formData = res.data.data;
             // 得到数据后 设置身份证验证
             this.validatorIdCard(this.formData.E001_idType);
             var oldData =  JSON.parse(JSON.stringify(res.data.data));
             this.oldeFromData = oldData;
             this.loading =false;
          }).catch(()=>{
               this.loading = false;
           })
        }
        this.isUpdateForm = false;
        this.$emit("update:isUpdateForm", false);
      },
      changeEdit(index) {
        let newValue;
        this.editList[index] === "true" ? newValue = "false" : newValue = "true"
        this.$set(this.editList, index, newValue)
        // 得到数据后 设置身份证验证
       // this.validatorIdCard(this.formData.E001_idType);
        if (newValue === "false") {
          // 并把修改后的值设置成原来的值
          this.formData = this.oldeFromData;
          // 把当前页面是否修改 改成 false
          this.isUpdateForm = false;
          this.$emit("update:isUpdateForm", false);
        }
      },
      changeShow(index) {
        let newValue;
        this.showList[index] === "true" ? newValue = "false" : newValue = "true"
        this.$set(this.showList, index, newValue)
      },
      //当证件类型是身份证时 才校验身份证号
      validatorIdCard(value) {
        if ('10010001' === value) {
          if(this.rules.E001_idCard){
            this.rules.E001_idCard.forEach(i => {
              (i.validator.name === 'validateIdCard')
              {
                i.isValidate = true;
              }
            });
          }

        } else {
          if(this.rules.E001_idCard) {
            this.rules.E001_idCard.forEach(i => {
              if (i.validator.name === 'validateIdCard') {
                i.isValidate = false;
              }
            });
          }
        }
        this.$forceUpdate();
      },
      changeEducation(value){
          if(value){
              this.formData.E001_educationParent ='';
              this.formData.E001_educationChild = '';
          }else {
              value.forEach((item,index)=>{
                  if(index===0){
                      this.formData.E001_educationParent = item;
                  }else {
                      this.formData.E001_educationChild = item;
                  }
              })
          }
           this.$forceUpdate();
      },
      // 当户籍地址改时 把户籍地址选的三个参数分开
      chengeRegAddr(value){
        value.forEach((item,index)=>{
          if(index===0){
            this.formData.E001_regProvince = item;
          }else if(index===1){
            this.formData.E001_regCity = item;
          }else {
            this.formData.E001_regDistrict = item;
          }
        })
      },
      //检查雇员编号是否重复
      checkEmpNumber(){
            let empNumb = this.formData.E001_empNumb;
            let cusId = this.formData.E001_cusId;
            let empId = this.formData.E001_id;
            console.log("empNumb,cusId,empId==",empNumb,cusId,empId);
        checkEmpNumberByNumber(empNumb,cusId,empId).then(res=>{
          console.log("编号返回的数据 ",res);
            if(res.data.data.code){   //返回true表示没有重复
              this.repeatEmpNumber = true;
            }else{                   //返回false表示重复
              this.repeatEmpNumber = false;
            }
        })
      },
        //校验雇员邮箱是否重复
        checkEmpEmail(val){
          if(validateEmail(val)){
            checkEmpEmail(val).then(res=>{
                if(res.data.data.code){
                    this.$message.info("当前邮箱未被使用")
                }else {
                    this.$message.info("当前邮箱已被使用")
                }
            })
          }
        },
        //校验雇员电话是否重复
        checkEmpPhone(val){
          if (isvalidatemobile(val)[0]) {
            checkEmpPhone(val).then(res =>{
                if(res.data.data.code){
                    this.$message.info("当前手机号码未被使用")
                }else {
                    this.$message.info("当前手机号码已被使用")
                }
            })
          }
        }
    }
  }
</script>
