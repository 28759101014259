<template>
  <div class="outer-layer">
    <bsp-tabs :tabList="tabList" :tabIndex="tabIndex" @changeTab="changeTab">
      <component  v-if="isRouterAlive" ref="pub" :is="currentTab" :currentTab='currentTab' :batchStatus="batchStatus"></component>
    </bsp-tabs>
  </div>
</template>
<script>
  import bspTabs from "@/components/bsp/bsp-tabs/bsp-tabs";
  import pendingSubsidiesList from "@/views/entrust/butie/buTiePendingList";
  import subsidiesByBatchList from "@/views/entrust/butie/buTieByBatchIdList";
  import {mapGetters} from "vuex";
  export default {
    computed: {
      ...mapGetters(['userInfo']),
    },
    created(){
      console.log("当前用户 机构id："+this.userInfo.orgId);
      //TODO 此处增加对业务类型显示
        this.tabList= [{
          title: 'oms补贴明细',
          name: '31278300701'
        },{
          title: '补贴批次',
          name: '31278300703'
        }];
        this.tabIndex = this.$route.query.currentTab ? this.$route.query.currentTab : '31278300701';
        this.currentTab = this.tabIndex;
      // }
    },
    data() {

      return {
        tabList:[],
        tabIndex: "",
        currentTab:"",
        isRouterAlive:true,
      }
    },
    provide(){
      return{
        reload:this.reload
      }
    },
    components:{
      bspTabs,
      31278300701: pendingSubsidiesList,
      31278300703: subsidiesByBatchList,
      // 31278300703: applyList,
      // 31278300704: applyList,
      // 31278300707: applyList,
    },
    methods: {
      changeTab(tab, event) {
        this.currentTab = tab.name;
        this.reload();
        console.log("hello everybody~~~this.currentTab==");
        console.log(this.currentTab);
      },
      reload (){
        this.isRouterAlive = false
        this.$nextTick(function(){
          this.isRouterAlive = true
        })
      }
    }
  }
</script>
