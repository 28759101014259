<template>

  <div>

      <div>
          <h2Title title="薪酬组信息">
              <div>
                 <span>返回</span>
              </div>
          </h2Title>
      </div>
      <div id="content">
          <el-form class="form">
             <el-form-item label="薪酬组名称：" >
              <el-input v-model.trim="value1" style="width:250px;" placeholder="请选择"></el-input>
            </el-form-item>
            <el-form-item label="上级薪酬组：" >
               <el-select v-model="value" multiple  placeholder="请选择" style="width:250px;">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-form>
          <div id="box">
              <div class="boxt-top">
                  <div>
                      <div>
 适用机构范围
                      </div>

                  </div>
                  <div class="flex">
                      <div class="item"  v-for="(item,i) in orgDatas" :key="item.id" >
                          <div>
                            <img src="@/assets/img/salarySet/guanbi.png" alt="" @click="del(i)" srcset="">
                            <img v-if="item.type=='dept'" src="@/assets/img/salarySet/dept.png" alt="" srcset="">
                            <img v-if="item.type=='person'" src="@/assets/img/salarySet/person.png" alt="" srcset="">
                          </div>
                          <div>
                              {{item.name}}
                          </div>
                      </div>
                  </div>
                  <div>
                    <p>
                        <span @click="openOrg()">选取机构</span>
                    </p>
                    <p>
                      <span @click="openPerson()">选取排除人员</span>
                    </p>
                  </div>
              </div>
              <div class="box-bot">
                <div>
                    <div style="width:280px;text-align:center">
 适用人员范围
                    </div>

                  </div>
                     <div class="flex">
                      <div class="item"  v-for="(item,i) in perDatas" :key="item.id">
                          <div>
                            <img src="@/assets/img/salarySet/guanbi.png" alt="" @click="del1(i)" srcset="">
                            <img v-if="item.type=='dept'" src="@/assets/img/salarySet/dept.png" alt="" srcset="">
                            <img v-if="item.type=='person'" src="@/assets/img/salarySet/person.png" alt="" srcset="">
                          </div>
                          <div>
                              {{item.name}}
                          </div>
                      </div>
                  </div>
                  <div>
                    <p>
                      <span @click="openPerson()">选取人员</span>
                    </p>
                  </div>
              </div>
          </div>

      </div>
      <div class="warn">
          温馨提示：该薪酬组对应薪资批次的人员范围为所选机构及下级机构的所有员工 和 已选取员工；
      </div>
       <div id="save">
          <el-button type="primary" @click="save()">保存</el-button>
      </div>
      <el-dialog
      ref=""
      width="70%"
      id="selectPerson"

      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="选取人员"
      :visible.sync="dialogheaderVisible"
    >
     <selectPerson></selectPerson>
    </el-dialog>
    <el-dialog
      ref=""
      width="70%"
      id="selectOrg"

      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="选取机构"
      :visible.sync="dialogheaderVisible1"
    >

    </el-dialog>
  </div>

</template>

<script>
  import h2Title from "@/views/demo/salarySet/template/h2Title.vue";
   import selectPerson from "@/views/demo/salarySet/template/selectPerson.vue";
  export default {
    name: "step2",
    data(){
      return{
        dialogheaderVisible:false,
        dialogheaderVisible1:false,
        options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        value: '',
        value1:'',
        orgDatas:[
          {type:'dept',name:'华南区分公司',id:1},
          {type:'dept',name:'人力资源部',id:2},
          {type:'dept',name:'财务部',id:3},
          {type:'dept',name:'项目部',id:4},
          {type:'person',name:'张三',id:5},
          {type:'person',name:'李四',id:6},
        ],
        perDatas:[
          {type:'person',name:'张三',id:1},
          {type:'person',name:'李四',id:2},
          {type:'person',name:'张三',id:3},
          {type:'person',name:'李四',id:4},
          {type:'person',name:'张三',id:5},
          {type:'person',name:'李四',id:6},
          {type:'person',name:'张三',id:7},
          {type:'person',name:'李四',id:8},
          {type:'person',name:'张三',id:9},
          {type:'person',name:'李四',id:10},
           {type:'person',name:'李四',id:11},
        ]
        // dialogheaderVisible:false,
      }
    },
    components: {h2Title,selectPerson},
    methods:{
      openPerson() {
        this.dialogheaderVisible = true
      },
       openOrg() {
        this.dialogheaderVisible1 = true
      },
      del(i) {
          this.orgDatas.splice(i,1);
      },
      del1(i) {
          this.perDatas.splice(i,1);
      },
      save() {
        alert('保存事件')
      }
    }
  };
</script>

<style scoped>
  .form{
    display: flex;
    justify-content: flex-start;
    padding-top: 12px;
    padding-bottom: 10px;
    width: 100%;
  }
  .el-form-item{
    width: 400px;
  }
  #content>div{
    border:1px solid rgba(221,221,221,1);
    border-top:none;
  }
  #box>div{
    display: flex;
  }
  #box>div>div:nth-of-type(1){
    width:280px;
    border-right:1px solid rgba(221,221,221,1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:14px;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:500;
    color:rgba(0,0,0,1);
    border-top:1px solid rgba(221,221,221,1);
  }
  #box>div>div:nth-of-type(2){
    flex-grow: 1;
    min-height: 120px;
    border-top:1px solid rgba(221,221,221,1);
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
    box-sizing: border-box;
  }
  #box>div>div:nth-of-type(3){
      width: 260px;
      border-left:1px solid rgba(221,221,221,1);
      border-top:1px solid rgba(221,221,221,1);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

  }
#box>div>div:nth-of-type(3) p{
    width: 260px;
    text-align: center
}
   #box>div>div:nth-of-type(3) p span{
      font-size:12px;
      font-family:PingFangSC-Medium,PingFang SC;
      font-weight:500;
      color:rgba(45,167,224,1);
      cursor: pointer;
   }
   .item>div:nth-of-type(1){
     position: relative;
     width: 64px;
     height: 64px;
     margin: 0 auto 7px;
   }
    .item>div:nth-of-type(1) img:nth-of-type(1){
     position: absolute;
     right: 0;
     top: 4px;
     cursor: pointer;
   }
   .item>div:nth-of-type(2){
     font-size:12px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(51,51,51,1);
   }
    .item{
      width: 118px;
      text-align: center;
      margin: 20px 0
    }
    .warn{
      font-size:12px;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(232,60,44,1);
      line-height:17px;
      padding: 6px 10px
    }
    #save{
      padding: 10px 0 16px;
      text-align: center;
    }
</style>
