<template>
  <div>
    <template>
      <div>
        <el-form :inline="true" :model="listQuery" class="editForm">
          <el-form-item label="请选择导入模板类型：" style="margin-right: 50px">
            <el-select class="status-select" v-model="wageTemplateId"  @change="loadList()">
              <el-option 
                v-for="item in templateSelect" 
                :key="item.value" 
                :label="item.label" 
                :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item style="float: right;">
            <el-button @click="save" size="medium" icon="el-icon-s-claim" >
              保存
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <bsp-table
      ref="publicTable"
      :data.sync="list"
      :tree-config="true"
      :showPagination="false" 
      @ciic-data-refresh="loadList"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
      :editorConfig="{trigger: 'click', mode: 'cell'}"
      :iKeepSource="true"
      :cell-style="cellStyle"
      :cell-class-name="cellClassName"
    >
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <div>
        <bsp-table-column title="系统模板项（不可修改）" field="sysWageItemName">
          <template slot-scope="scope"><span>{{ scope.row.sysWageItemName}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="机构模板项（可修改）" :edit-render="{name: 'input'} " field="orgWageItemName">
          <template slot-scope="scope"><span>{{ scope.row.orgWageItemName }}</span></template>
        </bsp-table-column>
      </div>
    </bsp-table>
  </div>
</template>

<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {forwardImportTemplateOfOrg ,getSysTemplate ,saveTemplateRename} from '@/api/entrust/template';
  import {mapGetters} from 'vuex';

  export default {
    name: "ImportTemplateRename",
    components: {
      BspTable,
      BspTableColumn
    },
    computed: {
      ...mapGetters([
        'dicts', "userInfo"
      ]),
    },
    created() {
        // console.log(this.userInfo);
        this.initTemplatSelect()
    },

    data() {
      return {
        loading: false,
        templateSelect:[],
        wageTemplateId:"",
        list:[]
      }
    },
    methods: {
        initTemplatSelect (){
            forwardImportTemplateOfOrg().then(res => {
                console.log(res);
                if (res.data) {
                    this.templateSelect.push({value:'public_5',label:'对公平台代发薪报税模板'})
                    this.templateSelect.push({value:'public_7',label:'对公平台代报销模板'})
                } else {
                    this.templateSelect.push({value:'public_1',label:'对公平台只报税模板'})
                    this.templateSelect.push({value:'public_3',label:'对公平台代发薪报税模板'})
                    this.templateSelect.push({value:'public_7',label:'对公平台代报销模板'})
                }
                this.wageTemplateId = this.templateSelect[0].value;
                this.loadList();
                
            })
            // 
            
        },
        loadList(){
            this.loading = true
            getSysTemplate({"wageTemplateId": this.wageTemplateId}).then(res =>{
                this.list = res.data.data;
                this.loading = false;
            })
        },
        save (){
            this.loading = true
            let renameDate = this.$refs.publicTable.editorRowData();
            
            saveTemplateRename(renameDate).then(res =>{
                //console.log(res);
                if(res.data.success){
                    this.$message.success("保存成功");
                }else {
                    this.$message.error("保存失败,失败原因："+ res.data.msg);
                }
                this.loading = false;
                this.loadList();
            })
        },
        cellStyle ({ row, rowIndex, column }) {
            if(column.property == 'orgWageItemName'){
                if (row.updateFlag == '1') {
                    return {
                        backgroundColor: '#91EF2C',
                        color:'#000000'
                    }
                }
            }
        },
        cellClassName() {
          return 'reduce-height';
        }
    }
  }
</script>

<style scoped>

</style>
