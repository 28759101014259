<template>
  <div class="printBox">
  <div>
    <div>
      <span>支付单编号：{{this.datas.paymentBillsBO.payCode}}</span>
      <div style="float: right">雇员总数：{{this.datas.size}}</div>
    </div>
    <div style="float: none; text-align: center">
      <div style="font-weight: bolder">
        退票通知书
      </div>
      <div v-for="item in this.list">
        <div>支出单编号：{{item.code}}</div>
        <table border="1" style="width: 100%">
          <tr>
            <th>客户名称</th>
            <th>负责人</th>
            <th>退票银行</th>
            <th>雇员姓名</th>
            <th>开户名</th>
            <th>身份证号</th>
            <th>员工账号</th>
            <th>实发金额</th>
            <th>退单备注</th>
          </tr>
          <tr v-for="(item2,idx2) in item.bankArr" :key="idx2" style="text-align: center">
            <td>{{item2.cusId}}</td>
            <td>{{item2.fzr}}</td>
            <td>{{item2.bankName}}</td>
            <td>{{item2.name}}</td>
            <td>{{item2.kaihuming}}</td>
            <td>{{item2.id}}</td>
            <td>{{item2.zhanghao}}</td>
            <td>{{item2.realMoney | moneyFilterStr}}</td>
            <td>{{item2.backRemark}}</td>
          </tr>
          <tr style="text-align: center">
            <td>合计：</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{item.sumArr[0].realMoney | moneyFilterStr}}</td>
            <td></td>
          </tr>
        </table>
      </div>

    </div>

    <div style="text-align: center">
      [{{this.datas.paymentBillsBO.payCode}}]
    </div>
    <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right" @click="print">打印</el-button>
      <el-button type="primary" size="big" fixed="right" @click="close">关闭</el-button>
    </div>
  </div>
  </div>
</template>

<script>

  import {
    printRefundNotice
  } from '@/api/payment/payment';

  export default {
    name: "printRefundNotice",
    created() {
      this.load();
    },
    mounted(){
      const that = this;
      this.$nextTick(()=>{
        document.getElementsByTagName("body")[0].classList.add("print");
      })

      if (window.matchMedia) {
        var mediaQueryList = window.matchMedia('print');

        //为印添加事件
        mediaQueryList.addListener(function(mql) {
          if (mql.matches) {
          } else {
            that.afterPrint();
          }
        });
      }
      //打印后事件
      window.onafterprint = that.afterPrint;

    },
    data() {
      return {
        list: [],
        // 页面数据
        datas: {
          paymentBillsBO: [],
          size: '',
          // payoutCodes: [],
          // bankListMap: [],
          // sumListMap: []
        },
        //分页查询对象
        listQuery: {
          paymentId: this.$route.query.paymentId,
        },
      }
    },
    methods: {
      load() {
        printRefundNotice(this.listQuery).then(res => {
          this.datas.paymentBillsBO = res.data.paymentBillsBO;
          this.datas.size = res.data.size;
          this.list = res.data.boxes;
          console.log()
          console.log(this.list)
        })
      },
      print() {
        this.hideBtn = true;
        this.$nextTick(()=>{
          window.print();
        })
      },
      close() {
        window.close();
      },
      afterPrint(){
        this.hideBtn = false;
        console.log("打开",this.hideBtn);
      }
    },
    beforeDestroy(){
      document.getElementsByTagName("body")[0].classList.remove("print");
    }
  }
</script>

<style scoped>
  .tt{
    width: 100%;
  }
  .theader{
    text-align: center
  }
  .tbd{
    text-align: center
  }
  .divButtonRigth {
    margin-bottom: 10px;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .printBox{
    padding-bottom: 60px!important;
  }
  .hideBtn{
    display: none;
  }
  </style>
    <style>

    .print #app,.print #app .pull-height,.print #app .index,.print #app .index>div,.print .index .main{
      height:auto;
      overflow-y: auto;
      min-height: auto;
      min-width: 100%!important;
      max-width: 100%!important;
    }
  .print .index .main .router{
    padding: 0;
  }


</style>
