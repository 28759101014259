<template>
	<div>
		<el-form :inline="true" :model="listQuery" class="demo-form-inline labelWhite">
			{{this.planForm.planName}}
			<!-- todo 按钮应放右侧-->
			<!-- <el-button type="primary" fixed="right" @click="addProductDetail">新增服务项目</el-button>
			<el-button type="primary" fixed="right" @click="mergePrice">合并价</el-button>
			<el-button type="primary" fixed="right" @click="deletePlanDetailsBatch">删除</el-button> -->
			<el-button type="primary" fixed="right" @click="goBack">返回</el-button>
		</el-form>
		<bsp-table
			:data.sync="list"
			:tree-config="true"
			:ciic-data-filter.sync="listQuery"
			@ciic-data-refresh="__getPlanDetailList"
			@select-change="selectChange"
			@select-all="selectAll"
			show-header-overflow
			show-overflowwidth
			show-overflow
			v-loading="loading"
			element-loading-text="拼命加载中"
			@span-method="mergeRowMethod"
			scroll-y="{gt: -1}"
			element-loading-spinner="el-icon-loading"
			:showPagination="false"
			show-footer-overflow
			show-footer
		>
			<vxe-table-column type="selection" width="60" fixed="left" />

			<bsp-table-column title="服务项目" field="serviceItem" fixed="left">
				<template slot-scope="scope">
					<span>{{scope.row.serviceItem}}</span>
				</template>
			</bsp-table-column>
			<bsp-table-column title="服务说明" field="serviceDesc"  fixed="left">
				<template slot-scope="scope">
					<span>{{scope.row.serviceDesc}}</span>
				</template>
			</bsp-table-column>
			<bsp-table-column title="服务费用" field="serviceFee"  fixed="left">
				<template slot-scope="scope">
					<span>{{scope.row.serviceFee}}</span>
				</template>
			</bsp-table-column>
			<bsp-table-column title="服务约定" field="serviceAgreement" fixed="left">
				<template slot-scope="scope">
					<span>{{scope.row.serviceAgreement}}</span>
				</template>
			</bsp-table-column>

			<bsp-table-column title="操作" fixed="right" width="120px" align="center">
				<template slot-scope="scope">
					<div class="ciic-table-operate">
						<el-button type="text" @click="showPlanDetails(scope.row)">查看</el-button>
						<!-- <el-button type="text" @click="editPlanDetails(scope.row)">编辑</el-button>
						<el-button type="text" @click="deletePlanDetails(scope.row)">删除</el-button> -->
					</div>
				</template>
			</bsp-table-column>
		</bsp-table>

		<!-- 新增项目服务明细 开始 addProductDetailDialogVisible-->
		<el-dialog
			v-dialogDrag
			width="50%"
			:title="textMap[dialogStatus]"
			:visible.sync="productDetailDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<el-form class="editFrom" label-width="130px" :disabled="disabled">
				<el-row>
					<el-col>
						<el-form-item label="套餐名称" prop="planName">
							{{planForm.planName}}
							<!-- <el-input v-model="planForm.planName" resize="none" type="input" disabled /> -->
						</el-form-item>
						<el-form-item label="服务项目" prop="serviceItem">
							{{planForm.serviceItem}}
							<!-- <el-input v-model="planForm.serviceItem" resize="none" type="input" /> -->
						</el-form-item>
						<el-form-item label="服务说明" prop="serviceDesc">
							{{planForm.serviceDesc}}
							<!-- <el-input v-model="planForm.serviceDesc" resize="none" type="textarea" /> -->
						</el-form-item>
						<el-form-item label="服务费用" prop="serviceFee">
							{{planForm.serviceFee}}
							<!-- <el-input v-model="planForm.serviceFee" resize="none" type="input" /> -->
						</el-form-item>
						<el-form-item label="服务约定" prop="serviceAgreement">
							{{planForm.serviceAgreement}}
							<!-- <el-input v-model="planForm.serviceAgreement" resize="none" type="textarea" /> -->
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="marginTop30" style="text-align: center">
				<!-- <el-button
					type="primary"
					@click="planDetailSave"
					v-if="(this.dialogStatus !='showPlanDetailTitile')"
				>保存</el-button> -->
				<el-button @click="productDetailDialogVisible=false">取消</el-button>
			</div>
		</el-dialog>
		<!-- 新增项目服务明细 结束-->

		<!-- 合并价 开始-->
		<el-dialog
			v-dialogDrag
			width="40%"
			:title="textMap[dialogStatus]"
			:visible.sync="mergePriceDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<el-form class="editFrom" label-width="100px" :disabled="disabled">
				<el-row>
					<el-col>
						<el-form-item label="合并费用" prop="price">
							<el-input v-model="params.price" resize="none" type="input" />
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="marginTop30" style="text-align: center">
				<el-button type="primary" @click="addMergePriceSave">保存</el-button>
				<el-button @click="mergePriceDialogVisible=false">取消</el-button>
			</div>
		</el-dialog>
		<!-- 合并价 结束-->
	</div>
</template>
<script>
import BspTable from '@/components/bsp/bsp-table/bsp-table'
import BspTableColumn from '@/components/bsp/bsp-table-column/bsp-table-column'
import {
	getPlanDetailList,
	checkServiceItem,
	addMergePriceSave,
	planDetailSave,
	deleteServiceItem
} from '@/api/serviceProduct/serviceProduct'
import { mapGetters } from 'vuex'

export default {
	name: 'productPlanList',
	inject: ['reload'],
	components: { BspTable, BspTableColumn },
	computed: {
		...mapGetters(['dicts'])
	},
	props: {
		currentTab: {
			type: String,
			default: ''
		}
	},
	created() {
		this.__getPlanDetailList()
	},
	data() {
		return {
			// 遮罩
			loading: false,
			// 用于接收后台返回的分页数据
			list: [],
			productDetailDialogVisible: false,
			mergePriceDialogVisible: false,
			//分页查询对象
			listQuery: {
				limit: 50,
				page: 1,
				total: 0
			},
			planForm: {
				planName: this.$route.query.planName,
				planId: this.$route.query.planId,
				serviceItem: '',
				serviceDesc: '',
				serviceFee: '',
				serviceAgreement: '',
				planDetailId: ''
			},
			params: {
				planDetailId: '',
				price: ''
			},
			//查询参数
			selectParam: {
				planName: this.$route.query.planName,
				planId: this.$route.query.planId
			},
			//多选数组
			multipleSelection: [],
			textMap: {
				addPlanDetailTitile: '新增项目服务明细',
				editPlanDetailTitile: '编辑项目服务明细',
				showPlanDetailTitile: '查看项目服务明细',
				mergePriceTitile: '合并价格'
			},
			dialogStatus: 'addPlanDetailTitile'
		}
	},
	methods: {
		// 通用行合并函数（将相同多列数据合并为一行）
		mergeRowMethod({ row, $rowIndex, column, data }) {
			console.log('row', row)
			const fields = ['serviceFee']
			const cellValue = XEUtils.get(row, column.property)
			if (cellValue && fields.includes(column.property)) {
				const prevRow = data[$rowIndex - 1]
				let nextRow = data[$rowIndex + 1]
				if (
					prevRow &&
					XEUtils.get(prevRow, column.property) === cellValue
				) {
					return { rowspan: 0, colspan: 0 }
				} else {
					let countRowspan = 1
					while (
						nextRow &&
						XEUtils.get(nextRow, column.property) === cellValue
					) {
						nextRow = data[++countRowspan + $rowIndex]
					}
					if (countRowspan > 1) {
						return { rowspan: countRowspan, colspan: 1 }
					}
				}
			}
		},

		//查询产品套餐下服务项目明细列表
		__getPlanDetailList: function() {
			console.log('planId=====' + JSON.stringify(this.selectParam))
			getPlanDetailList(this.selectParam).then(res => {
				if (res.data.code != 0) {
					this.listQuery.total = res.data.total
					this.list = res.data.records
					this.loading = false
				} else {
					this.loading = false
				}
			})
		},

		//点击增加服务项目
		addProductDetail() {
			//需先选择一个套餐方可增加服务项目
			if (this.planForm.planId.length <= 0) {
				this.$message.error('未获取到套餐信息，请联系相关人员！')
				return
			}
			this.productDetailDialogVisible = true
			this.dialogStatus = 'addPlanDetailTitile'
		},
		//保存服务项目明细
		planDetailSave() {
			//TODO 判断必填项是否为空
			planDetailSave(this.planForm).then(res => {
				if (res.data.code != 0) {
					this.$message.error(res.data.msg)
					this.productDetailDialogVisible = true
				} else {
					this.$message.success(res.data.msg)
					this.productDetailDialogVisible = false
					this.__getPlanDetailList()
				}
			})
		},
		//点击编辑
		editPlanDetails(row) {
			this.planForm.planDetailId = row.id
			this.planForm.serviceItem = row.serviceItem
			this.planForm.serviceDesc = row.serviceDesc
			this.planForm.serviceFee = row.serviceFee
			this.planForm.serviceAgreement = row.serviceAgreement
			this.productDetailDialogVisible = true
			this.dialogStatus = 'editPlanDetailTitile'
		},

		//点击合并价格
		mergePrice() {
			//需先选择多于两个服务项目才能合并
			if (this.multipleSelection.length <= 1) {
				this.$message.error('请至少选择两项服务项目设置合并价格！')
				return
			}
			//将选中的id组装为string
			this.multipleSelection.forEach(item => {
				this.params.planDetailId =
					this.params.planDetailId + ',' + item.id
			})
			//todo 校验选中的服务项目是否存在已设置服务费用或合并费用
			checkServiceItem(this.params).then(res => {
				/*if (res.data.msg.length>0) {
            this.$message.warning(res.data.msg);
          }*/
				this.mergePriceDialogVisible = true
				this.dialogStatus = 'mergePriceTitile'
			})
		},
		//合并价格保存
		addMergePriceSave() {
			if (this.params.price.length <= 0) {
				this.$message.error('请设置合并价格！')
				return
			}
			addMergePriceSave(this.params).then(res => {
				if (res.data.code != 0) {
					this.$message.error(res.data.msg)
					this.mergePriceDialogVisible = true
				} else {
					this.$message.success(res.data.msg)
					this.mergePriceDialogVisible = false
				}
				this.__getPlanDetailList()
			})
		},

		selectChange(value) {
			console.log(value)
			if (value.selection) {
				this.multipleSelection = []
				for (let index = 0; index < value.selection.length; index++) {
					const element = value.selection[index]
					this.multipleSelection.push(element)
				}
				console.log('this.multipleSelection:' + this.multipleSelection)
			}
		},
		//复选框全选事件
		selectAll({ checked, selection }) {
			if (checked) {
				this.multipleSelection = selection
			} else {
				this.multipleSelection = []
			}
			console.log('this.multipleSelection:' + this.multipleSelection)
		},

		//可批量删除服务项目
		//TODO 需要测试多种情况下的删除
		deletePlanDetailsBatch() {
			if (this.multipleSelection.length <= 0) {
				this.$message.error('请选择要删除的服务项目！')
				return
			}
			//将选中的id组装为string
			this.multipleSelection.forEach(item => {
				this.params.planDetailId =
					this.params.planDetailId + ',' + item.id
			})
			console.log('服务项目id：' + this.params.planDetailId)
			this.$confirm(
				'您确认要删除所选中的服务项目吗？删除就无法还原请慎重选择！',
				'提示',
				{
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}
			)
				.then(() => {
					deleteServiceItem(this.params).then(res => {
						if (res.data.code != 0) {
							this.$message.error(res.data.msg)
						} else {
							this.$message.success('删除成功！')
						}
						this.__getPlanDetailList()
					})
				})
				.catch(() => {
					this.__getPlanDetailList()
				})
		},

		//单条删除
		deletePlanDetails(row) {
			this.params.planDetailId = row.id
			console.log('服务项目id：' + this.params.planDetailId)
			this.$confirm(
				'您确认要删除所选中的服务项目吗？删除就无法还原请慎重选择！',
				'提示',
				{
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}
			)
				.then(() => {
					deleteServiceItem(this.params).then(res => {
						if (res.data.code != 0) {
							this.$message.error(res.data.msg)
						} else {
							this.$message.success('删除成功！')
						}
						this.__getPlanDetailList()
					})
				})
				.catch(() => {
					this.__getPlanDetailList()
				})
		},

		//查看详情
		showPlanDetails(row) {
			this.planForm.serviceItem = row.serviceItem
			this.planForm.serviceDesc = row.serviceDesc
			this.planForm.serviceFee = row.serviceFee
			this.planForm.serviceAgreement = row.serviceAgreement
			this.productDetailDialogVisible = true
			this.dialogStatus = 'showPlanDetailTitile'
		},

		goBack() {
			this.$router.go(-1) //返回上一层
		}
	}
}
</script>
<style scoped>
.copy-btn {
	cursor: pointer;
}
.ciic-table-operate {
	align-items: center;
}
</style>
