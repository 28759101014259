<template>
    <div class="search-head">
        <el-row >
          <el-col :span="24" style="display: flex;">
            <el-input
              :placeholder="placeholder"
              @keyup.enter.native="filterEvent()"
              :disabled="showFiltersCard"
              v-model="searchAll">
              <i slot="suffix" @click="filterEvent()" class="el-input__icon el-icon-search"></i>
            </el-input>
            <el-button @click="showFiltersCardEvent" type="primary" icon="el-icon-arrow-down"></el-button>
          </el-col>
        </el-row>
        <el-row ref="filtersCard">
          <el-col :span="24" >
            <transition name="fade">
              <div class="card" ref="card"  :style="cardWidthStyle" v-show="showFiltersCard">
                <div class="hidden-content">
                  <slot v-bind:filtersQuery="queryFilters"></slot>
                </div>
                <div style="text-align: right">
                  <el-button type="primary" size="small" @click="filterEvent()" class="filters">查询</el-button>
                  <el-button type="info" size="small" @click="reset()">重置</el-button>
                </div>
              </div>
            </transition>
          </el-col>
        </el-row>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex';
    export default {
      name: "ciicFilters",
      props:{
        placeholder: String,
        filtersQuery: Object,
        searchArray: Array,
        searchContent: Array
      },
      computed: {
        ...mapGetters(['clientWidth']),
        cardWidthStyle(){
          if (this.cardWidth <= this.minWidth) {
            return { width: this.minWidth + 'px'}
          } else {
            return { width: '100%'}
          }
        }
      },
      data() {
        return {
          //显示过滤条件卡片
          showFiltersCard: false,
          //初始化query
          initQuery: {},
          //总查询input绑定值
          searchAll: '',
          //卡片里查询绑定的query
          queryFilters: {},
          resetQuery: {},
          changeCardWidth: false,
          cardWidth: '',
          minWidth: 500,
        }
      },
      created(){
        this.resetQuery = JSON.parse(JSON.stringify(this.filtersQuery))
        for (let key in this.filtersQuery){
          if (this.filtersQuery.hasOwnProperty(key) === true && this.searchContent){
            this.searchContent.forEach( value => {
              if (key === value){
                this.queryFilters[value] = this.filtersQuery[key]
                this.initQuery[value] = this.filtersQuery[key]
              }
              this.queryFilters = Object.assign({},this.queryFilters,{[value]: this.queryFilters[value]})
              this.initQuery = Object.assign({},this.queryFilters,{[value]: this.queryFilters[value]})

            })
          }
        }
      },
      mounted(){
        this.cardWidth = this.$refs.filtersCard.$el.clientWidth
        window.onresize = () =>{ // 定义窗口大小变更通知事件
          this.cardWidth = this.$refs.filtersCard.$el.clientWidth
        };
      },
      methods: {
        //展开收起过滤条件卡片事件
        showFiltersCardEvent(){
          this.searchAll = ''
          this.showFiltersCard = !this.showFiltersCard
        },
        //过滤事件
        filterEvent() {
          if(this.showFiltersCard){
            // let obj = Object.assign({}, JSON.parse(JSON.stringify(this.filtersQuery)), JSON.parse(JSON.stringify(this.queryFilters)))
            let obj = Object.assign({},this.filtersQuery, this.queryFilters)
            //单个查询input
            // this.$emit('update:filtersQuery',JSON.parse(JSON.stringify(this.queryFilters)))
            this.$emit('update:filtersQuery',obj)
            this.showFiltersCard = false
          } else {
            //清空里面的数据
            this.queryFilters =JSON.parse(JSON.stringify(this.initQuery))
            //总查询input（现在是只查一个）
            let searchAllQuery =  JSON.parse(JSON.stringify(this.filtersQuery))
            this.searchArray.forEach((value) => {
              searchAllQuery[value] = JSON.parse(JSON.stringify(this.searchAll))
            });
            this.$emit('update:filtersQuery',searchAllQuery)
          }
          this.$emit('searchSubmit')
        },

        // 重置事件
        reset(){
          let obj = {};
          // Object.assign(obj,JSON.parse(JSON.stringify(this.filtersQuery) ,JSON.parse(JSON.stringify(this.initQuery))))
          Object.assign(obj,this.filtersQuery ,this.initQuery)
          this.$emit('update:filtersQuery',obj)
          this.queryFilters = JSON.parse(JSON.stringify(this.initQuery))
        }
      }
    }
</script>

<style lang="scss" scoped>
  .main{
  }
  .search-head{
  }
  .card{
    box-sizing: border-box;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    border-radius: 4px;
    border: 1px solid #ebeef5;
    margin-top: 5px;
    padding: 18px 20px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 998;
    background: #fff;
  }
  .card-width {
    width: 470px;
  }
  .hidden-content{
    margin-bottom: 10px;
  }
  .el-input__icon{
    font-size: 20px;
    cursor: pointer;
  }
  .fade-enter-active, .fade-leave-active {
    transition: all .4s;
  }
  .fade-enter, .fade-leave-active {
    transform: scale(0);
    transform-origin: right top;
    opacity: 0;
  }
</style>
