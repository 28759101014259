import request from '@/router/axios';

let urlPattern = '/cusPayableConf';

// 获取客户列表
export function searchCusList(obj) {
  return request({
    url: urlPattern + '/listPage',
    method: 'post',
    data: obj
  })
}

export function updateCusCaclWay(obj) {
  return request({
    url: urlPattern + '/updateCusCaclWay',
    method: 'post',
    data: obj
  })
}

export function checkCusIsImported(obj) {
  return request({
    url: urlPattern + '/checkCusIsImported',
    method: 'post',
    data: obj
  })
}

export function checkEmpIsImported(obj) {
  return request({
    url: urlPattern + '/checkEmpIsImported',
    method: 'post',
    data: obj
  })
}

export function updateEmpCaclWay(obj) {
  return request({
    url: urlPattern + '/updateEmpCaclWay',
    method: 'post',
    data: obj
  })
}

export function batchUpdateCusCaclWay(obj) {
  return request({
    url: urlPattern + '/batchUpdateCusCaclWay',
    method: 'post',
    data: obj
  })
}

export function searchEmpList(obj) {
  return request({
    url: urlPattern + '/searchEmpList',
    method: 'post',
    data: obj
  })
}

// 获取薪资项列表
export function getWageItemList(obj) {
  return request({
    url: urlPattern + '/selectWageItemsByCusId',
    method: 'post',
    data: obj
  })
}

// 获取应发金额默认值
export function getPayMoneyDefault(obj) {
  return request({
    url: urlPattern + '/selectPayAmountFormula',
    method: 'post',
    data: obj
  })
}

// 请求后台校验应发金额计算公式
export function payAmouValid(obj) {
  return request({
    url: urlPattern + '/validWageItem',
    method: 'post',
    data: obj
  })
}

// 保存应发金额
export function savePayAmount(obj) {

  return request({
    url: urlPattern + '/savePayAmountFormula',
    method: 'post',
    data: obj
  })
}

// 删除自定义项
export function deleteWageItem(obj) {

  return request({
    url: urlPattern + '/deleteWageItem',
    method: 'post',
    data: obj
  })
}

// 更新薪资项的选中状态
export function updateSelect(obj) {

  return request({
    url: urlPattern + '/cancleSelectItem',
    method: 'post',
    data: obj
  })
}
//保存薪资项
export function saveWageItem(obj){
  return request({
    url: urlPattern + '/insertFormulaAndItem',
    method: 'post',
    data: obj
  })
}
//编辑薪资项
export function editWageItem(obj){
  return request({
    url: urlPattern + '/updateWageItem',
    method: 'post',
    data: obj
  })
}

//编辑薪资项
export function cancleSelectValid(obj){
  return new Promise((resolve, reject) => {
    request({
      url: urlPattern + '/cancleSelectValid',
      method: 'post',
      data: obj
    })
    .then(res => {
      resolve(res.data.data.res);
    })
    .catch((error) => {
      console.log('调用错误',error);
      reject(error);
    });
  })
}
