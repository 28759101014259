// 将一维的扁平数组转换为多层级对象
export function buildTree(array,id,parent_id) {
  // 创建临时对象
  let temp = {};
  // 创建需要返回的树形对象
  let tree = [];
  // 先遍历数组，将数组的每一项添加到temp对象中
  for(let i in array) {
    temp[array[i][id]] = array[i];
  }
  // 遍历temp对象，将当前子节点与父节点建立连接
  for(let i in temp) {
    let parentId = temp[i][parent_id]
    // 判断是否是根节点下的项
    if(parentId !== '0' && parentId !== '-1') {
      if(!temp[parentId].children) {
        temp[parentId].children = new Array();
      }
      temp[parentId].children.push(temp[i]);
    } else {
      tree.push(temp[i]);
    }
  }

  return tree;
}

//将有父子关系的树状结构转化为一维的扁平数组
export function treeToArray(tree) {
  let arr = [];
  const expanded = item => {
    if (item && item.length > 0) {
      item.forEach(e => {
        expanded(e.children);
        e.children = []
        arr.push(e);
      })
    }
  };
  expanded(tree);
  return arr;
}


//设置rem
export function setRem() {
  var desW = 1920;
  var winW = document.documentElement.clientWidth;
  var radio = winW/desW;
  document.documentElement.style.fontSize = radio * 100 +'px';
  window.onresize = ()=>{
    var desW = 1920;
    var winW = document.documentElement.clientWidth;
    var radio = winW/desW;
    document.documentElement.style.fontSize = radio * 100 +'px';
  }
}
