<template>
    <div>
      <div class="divButtonRigth">
        <el-button type="primary"  size="small" fixed="right"   @click="cusTransfer()"
                  >批量客户转移
        </el-button>
      </div>
      <bsp-table
        :data.sync="list"
        :tree-config="true"
        :ciic-data-filter.sync="listQuery"
        @select-change="selectChange"
        @select-all="selectChange"
        @ciic-data-refresh="loadList"
        show-header-overflow show-overflowwidth show-overflow
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        show-footer-overflow
        show-footer
      >
        <vxe-table-column type="selection" width="60" fixed="left"/>
        <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>


        <bsp-table-column title="客户ID" field="cusid" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.cusid}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="客户名称" field="cusname" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="操作人" field="managername" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.managerName}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="接口人" field="busirecheckname" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.busiRecheckName}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="所属对公机构" field="puborgname" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.pubOrgName}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="客户状态" field="status" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.status}}</span></template>
        </bsp-table-column>
      </bsp-table>

      <el-dialog v-dialogDrag width="30%"
                 :title="batchDialogTitle"
                 :close-on-click-modal="false"
                 :close-on-press-escape="false"
                 :visible.sync="batchDialogVisible"
                >
        <el-form ref="form2" class="editFrom" :model="validateForm" label-width="168px" :rules="rules">
          <el-row>
            <el-col :span="20">
              <el-form-item label="操作人">
                <el-select v-model="operator" filterable   placeholder="请选择" >
                  <el-option
                    v-for="item in operatorList"
                    :key="item.ciicPerId"
                    :value="item.ciicPerId"
                    :label="item.ciicPerName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="marginTop20" style="text-align: center">
          <el-button type="primary" @click="batchSave"> 保存</el-button>
          <el-button @click="batchCancel"> 取消</el-button>
        </div>
      </el-dialog>
    </div>

</template>

<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {batchUpdateCusCaclWay, checkCusIsImported, searchCusList} from '@/api/cus/payableConf';
  import {queryersonList} from "@/api/person/person";
  import {cusTransferList, updateOperatorList} from "@/api/cus/list";
  import {mapGetters} from 'vuex';

    export default {
        name: "cusList",
      inject: ['reload'],
      components: {
        BspTable,
        BspTableColumn,
      },
      computed: {
        ...mapGetters([
          'dicts'
        ]),
      },
      props: {
        currentTab: {
          type: String,
          default: ''
        },
      },

      created() {
          this.loadList();
          this._operatorList();
      },

      data() {
          return {
            // 用于接收后台返回的分页数据
            list: [],
            //分页查询对象
            listQuery: {
              limit: 50,
              page: 1,
              total: 0,
              paymentNo: '',
              paymentStatus: '',
              accountingMonth: '',
              paymentType: '0'
            },
            batchDialogTitle: '修改操作人-客户转移',
            multipleSelection: [],
            batchDialogVisible: false,
            operatorList:[],
            operator: "",
            updateoperatorList:[],
            updateoperator:{
              cusId:'',
              beforeOperator:'',
              afterOperator:''
            }
          }
      },

      methods: {


        batchCancel() {
          this.batchDialogVisible = false;
        },
        selectChange(value) {
          console.log(value)
          if (value.selection) {
            this.multipleSelection = []
            for (let index = 0; index < value.selection.length; index++) {
              const element = value.selection[index]
              this.multipleSelection.push(element)
            }
            console.log('this.multipleSelection:' + this.multipleSelection)
          }
        },
        loadList() {
          this.loading = true;
          cusTransferList(this.listQuery).then(res => {
            this.list = res.data.records;
            this.listQuery.total = res.data.total;
            this.loading = false;
            this.multipleSelection = []
          })
        },

        cusTransfer() {
          if (this.multipleSelection.length > 0) {
            this.batchDialogVisible = true;
          } else {
            this.$message.warning('请至少选择一条数据');
          }
        },

        _operatorList() {
          queryersonList({type: '00900'}).then(res=>{
            console.log("操作人数据",res);
            this.operatorList=res.data.data
          })
        },

        batchSave() {
          if (this.multipleSelection.length > 0) {
            this.multipleSelection.forEach(item => {
              item.operator = this.operator;
            })
            updateOperatorList(this.multipleSelection).then(res=>{
              console.log("updateOperatorListres",res);
              if(res.data.code!=0){
                this.$message.error('修改操作人失败！！！');
              }
              this.loadList();
              this.batchDialogVisible = false;
            })
            console.log("updateoperatorList",this.multipleSelection);
          } else {
            this.$message.warning('请至少选择一条数据');
          }
        },
      }
    }
</script>

<style scoped>

</style>
