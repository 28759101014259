<template>
  <div>
    <div class="divButtonRigth" v-if  ="listQuery.status==='0'">
      <el-button type="primary" size="small" fixed="right" @click="generateSubsidyBatch" :loading="this.loading">生成补贴批次</el-button>
    </div>
    <el-form :inline="true" :model="listQuery" class="editForm" label-width="50px" >
         <el-form-item label="状态:">
           <el-select  class="status-select" v-model="listQuery.status" @change="__queryPendingSubsidies()">
             <el-option label="未生成批次" value="0"></el-option>
             <el-option label="已生成批次" value="1"></el-option>
           </el-select>
         </el-form-item>
    </el-form>
    <bsp-table
      :data.sync="list"
      @select-change="selectChange"
      @select-all="selectAll"
      :tree-config="true"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="__queryPendingSubsidies"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
    >

      <vxe-table-column type="selection" width="60" fixed="left"/>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>

      <bsp-table-column title="客户名称" field="cusname" filter-type="input" width="100" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="税金所属月份" field="salaryM onth" filter-type="input" width="100" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.salaryMonth}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="雇员姓名" field="empName" filter-type="input" width="100" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.empName}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="证件号码" field="empCardNo" filter-type="input" width="100" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.empCardNo}}</span></template>
      </bsp-table-column>
      <bsp-table-column v-if ="listQuery.status==='0'" title="生成状态" field="generateStatus" filter-type="input" width="100" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.generateStatus}}</span></template>
      </bsp-table-column>
      <bsp-table-column v-if  ="listQuery.status==='0'" title="失败原因" field="errMsg" filter-type="input" width="100" fixed="left">
        <template slot-scope="scope"><span style="color: red">{{scope.row.errMsg}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="计税补贴" field="e204256" filter-type="input" width="100" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.e204256}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="免税补贴" field="e204241" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.e204241}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="学习补贴" field="studySubsidy" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.studySubsidy}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="体检费" field="physicalFee" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.physicalFee}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="取暖费" field="heatingFee" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.heatingFee}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="探亲费" field="visitFee" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.visitFee}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="补贴" field="subsidyPayable" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.subsidyPayable}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="特殊津贴" field="specialSubsidy" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.specialSubsidy}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="存档费" field="filingFee" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.filingFee}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="晚育津贴" field="lateBirthSubsidy" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.lateBirthSubsidy}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="生育津贴" field="birthSubsidy" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.birthSubsidy}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="丧葬补助" field="funeralSubsidy" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.funeralSubsidy}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="独生子女费" field="singletonSubsidy" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.singletonSubsidy}}</span></template>
      </bsp-table-column>
    </bsp-table>




  </div>
</template>
<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {
    queryPendingSubsidies,
    generateSubsidyBatch,
    exportDetails,
    getdynamicTable,
    getdynamicList,
    getDetailImportBatchCusList
  } from '@/api/entrust/buTie';
  import {downloadFile, downloadPdfFile} from '@/util/util';
  import {mapGetters} from 'vuex';

  export default {
    name: 'buTie',
    inject: ['reload'],
    components: {
      BspTable,
      BspTableColumn,
    },
    computed: {
      ...mapGetters([
        'dicts'
      ]),
    },
    props: {
      currentTab: {
        type: String,
        default: ''
      },

    },
    created() {

      this.__queryPendingSubsidies();

    },
    data() {
      return {

        // billDialogVisible:false,
        // // 遮罩
        loading: false,
        // 用于接收后台返回的分页数据
        list: [],
        // detailVisible: 'false',
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          bizType: '',
          status:'0'
          // batchStatus: '00900'//默认为未提交
        },
        parameter: {
          batchImportIds: '',
          bizType: this.currentTab,
          cusId: '',
          orgId: '',
          billsTitle: '',//BILLSTITLENAME
          taxNum: '',//F204204
          taxAdress: '',//F204205
          taxPhone: '',//F204206
          recipients: '',//RECEIVEDEPT
          emailAddress: '',//postcode
          subIds: '',//明细选择导出
          subIdFlag: '',//明细导出标识
        },
        //多选数组
        multipleSelection: [],
        multipleSelectionBo: [],
        textMap: {
          dkTaxTotal: '税金总金额',
          pdkTaxTotal: '工资往来总金额',
          dbxTaxTotal: '代报销总金额',
          titleInfo: '选择发票信息',
        },
        taxTotalStatus: 'dkTaxTotal',
        detailInfo: {
          batchImportId: '',//批次id
          bizType: '',//业务类型
          status: '',//提交状态，未提交/已提交
          cusId: '',
        },
        dataList: [],
        tableColumn: [], //显示表头
        sumItem: [],//需要合计的列
        detailImportCusList: [],
        cuslistQuery: {
          limit: 50,
          page: 1,
          total: 0,
          bizType: '',
          batchStatus: '00900',//默认为未提交
          batchImportId: '',
        },
      }
    },
    methods: {
      __queryPendingSubsidies: function () {
        this.loading = true
        queryPendingSubsidies(this.listQuery).then(res => {
          this.listQuery.bizType = this.currentTab;
          this.loading = true;
          if (res.status !== 0) {
            this.listQuery.total = res.data.total;
            this.list = res.data.records;
            console.log(this.list)
          }
          this.loading = false
        })
      },

      // 点选/全选复选框
      selectChange(value) {
        console.log(value);
        debugger
        if (value.selection) {
          this.multipleSelection = [];
          this.multipleSelectionBo = [];
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index].subsidyDetailId;
            this.multipleSelection.push(element);
            this.multipleSelectionBo.push(value.selection[index]);
          }
        }
      },

      //复选框全选事件
      selectAll({checked, selection}) {
        debugger
        if (checked) {
          this.multipleSelection = [];
          this.multipleSelectionBo = [];
          for (let index = 0; index < selection.length; index++) {
            const element = selection[index].subsidyDetailId;
            this.multipleSelection.push(element);
            this.multipleSelectionBo.push(selection[index]);
          }
        } else {
          this.multipleSelection = [];
          this.multipleSelectionBo = [];

        }
        console.log("this.multipleSelection:" + this.multipleSelection);
      },

      // 点击生成补贴批次
      generateSubsidyBatch: function () {
        if (this.multipleSelection.length === 0) {
          this.$message.warning('请至少选择一条数据');
        } else {
          var flag = false;

          for(let i in this.multipleSelectionBo){
            debugger
            if(this.multipleSelectionBo[i].generateStatus == '批次生成中'){
              flag = true;
              this.$message.error("批次生成中请耐心等待！！");
            }
          }
          if(flag){
            return;
          }
          // todo：增加loading
          this.loading = true;
          const data = this.multipleSelection;
          generateSubsidyBatch(data).then(res => {
            this.multipleSelection = [];
            this.multipleSelectionBo = [];
            if (res.status === 200) {
              this.$message.success("批次生成中请耐心等待 ！！");

              // console.log('响应成功');
              // if (res.data.statusCode === '1') {
              //   this.$message.success('接口返回成功！！！\n'
              //     + '结果信息：' + res.data.resultInfo
              //     + '提示信息：' + res.data.tipInfo
              //     + '错误信息：' + res.data.errorInfo
              //     );
              //   this.loading = false;
              //   // todo:跳转到补贴批次页面
              //   this.$router.push({path: '/wt/buTieByBatchIdList'})
              // } else {
              //   this.$message.error(res.data.errorInfo);
              //   this.loading = false;
              // }
              this.__queryPendingSubsidies();
            } else {
              this.$message.error(res.status);
              this.loading = false;
            }
          })
        }
      },

      //单条导出
      exportDetails(row) {
        this.loading = true;
        this.parameter.batchImportIds = "";
        if (row != null && row.id.length > 0) {
          console.log("单次操作");
          this.parameter.batchImportIds = row.id;//对应批次id
        } else {
          if (this.multipleSelection.length <= 0) {
            this.$message.error("请选择要导出的批次！");
            this.loading = false;
            return;
          }
          if (this.multipleSelection.length > 0) {
            //将选中的id组装为string
            this.multipleSelection.forEach(item => {
              this.parameter.batchImportIds = this.parameter.batchImportIds + "," + item.id;
            });
          }
        }

        console.log("批次id：" + this.parameter.batchImportIds);
        this.parameter.bizType = this.currentTab;//对应业务类型 todo 怎么从tables里面直接获取相关值
        exportDetails(this.parameter).then(res => {
          this.loading = false,
            downloadFile(res);
        })
      },


      //查看详情
      showDetails(row) {
        console.log("批次id：" + row.id + ";业务类型：" + row.bizType);
        if ((row.bizType == "31278300701" || row.bizType == "31278300702") && this.listQuery.batchStatus == "00901") {
          this.detailVisible = 'detailCus';
          this.cuslistQuery.batchImportId = row.id;
          this.cuslistQuery.bizType = row.bizType;
          this.__getDetailImportBatchCusList();
        } else {
          console.log("*********************************row****")
          console.log(row)
          this.detailVisible = 'true';
          this.detailInfo.batchImportId = row.id;
          this.detailInfo.bizType = row.bizType;
          this.detailInfo.status = row.status;
          this.detailInfo.cusId = row.cusid;
          this._getdynamicTable();
          this._getdynamicList();
        }
      },

      //动态表头
      _getdynamicTable: function () {
        console.log("查看对应的批次id：" + this.detailInfo.batchImportId);
        getdynamicTable(this.detailInfo).then(res => {
          if (res.data.data.success == "true") {
            this.tableColumn = res.data.data.tableColumn;
            this.sumItem = res.data.data.sumItem;
            this.noChangeList = res.data.data.noChangeList;
            // 处理数据 不能编辑的能编辑的
            console.log("==========================");
            if (this.listQuery.batchStatus == '00900') {
              for (let i = 0; i < this.tableColumn.length; i++) {
                if (this.noChangeList.indexOf(this.tableColumn[i].field) == -1) {
                  if (this.tableColumn[i].setId && this.dicts.codeList[this.tableColumn[i].setId]) {
                    this.tableColumn[i].editRender = {
                      name: "$select",
                      options: this.dicts.codeList[this.tableColumn[i].setId],
                      optionProps: {
                        value: "codeItemName",
                        label: "codeItemName",
                        disabled: false
                      }
                    }
                  } else {
                    this.tableColumn[i].editRender = {name: 'input'}
                  }
                }
              }
            }
          }
        })
      },
      //动态数据
      _getdynamicList() {
        getdynamicList(this.detailInfo).then(res => {
          this.listQuery.total = res.data.total
          this.dataList = res.data.records
        })
      },

    }
  }
</script>
<style scoped>
  .copy-btn {
    cursor: pointer;
  }

  .ciic-table-operate {
    align-items: center;
  }

  .editForm {
    margin: 0;
  }

  .editForm .el-form-item {
    margin: 0;
  }
</style>
