<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900'" :disabled="isDisabled"  @click="exportDetails(null)">批量导出</el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900'" :disabled="isDisabled"  @click="importDetail">导入</el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900'" :disabled="isDisabled"  @click="recalculate">重新计算</el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900' && this.coOrdinationAccountAudit" :disabled="isDisabled"  @click="commitFinance">提交财务</el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900'" :disabled="isDisabled"  @click="commitBatch">提交</el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900'" :disabled="isDisabled"  @click="deleteImportBatch">删除</el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='true' " @click="downEmpSalary">下载工资条</el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='true' && this.listQuery.batchStatus=='00900' " @click="saveEvent">保存</el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='true'"  @click="exportDetailsTwo">导出</el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='true'"  @click="goback">返回</el-button>

    </div>
    <el-form :inline="true" :model="listQuery" class="editForm" label-width="50px"  v-if="this.detailVisible=='false'">
      <el-form-item label="状态:">
        <el-select clearable class="status-select" v-model="listQuery.batchStatus" @change="__getDetailImportBatchList()">
          <el-option label="未提交" value="00900"></el-option>
          <el-option label="已提交" value="00901"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!--批次列表-->
    <bsp-table v-if="this.detailVisible=='false'"
      :data.sync="list"
      :tree-config="true"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="__getDetailImportBatchList"
      @select-change="selectChange"
      @select-all="selectAll"
      show-header-overflow show-overflowwidth  show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
      :footer-method="footerMethod"
      height="89%"
    >

      <vxe-table-column type="selection" width="60" fixed="left"/>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <bsp-table-column  title="导入批次编号" field="batchCode"  filter-type="input"  width="100"  fixed="left"  >
        <template slot-scope="scope">   <span>{{scope.row.batchCode}}</span>  </template>
      </bsp-table-column>


      <bsp-table-column v-if="this.currentTab=='31278300703' || this.currentTab=='31278300704' || this.currentTab=='31278300707'
              ||(this.userInfo.userType=='2' && this.currentTab=='31278300702') "
                        title="客户名称"  field="cusName"    filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.cusName}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="受托方"  field="orgName"    filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.orgName}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="计税区域" v-if="this.currentTab!='31278300707'" field="taxArea"   width="100" filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.taxArea}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="税金所属月份"  v-if="this.currentTab!='31278300707'"  field="taxMonth"   width="120" filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.taxMonth}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="实际操作人"  field="operatorName"     filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.operatorName}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="创建时间"  field="operateTime"   width="120" filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.operateTime}}</span>  </template>
      </bsp-table-column>


      <bsp-table-column   :title="textMap[taxTotalStatus]"  field="taxTotal"     filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.taxTotal }}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="服务总金额"  field="serviceTotal"   filter-type="input" fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.serviceTotal }}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="状态"  field="statusName"    fixed="left" filter-type="input" ><!-- filter-type="select"    filter-rule="in"   set-id="3004"-->
        <template slot-scope="scope">  <span>{{scope.row.statusName }}</span>  </template><!--statusName | dictFilter(dicts)-->
      </bsp-table-column>

      <bsp-table-column  title="操作"  fixed="right" width="150px" align="center" >
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button v-if="scope.row.status === '3004000044'" type="text" @click="showSendBackDetails(scope.row)" >退回详情</el-button>
            <el-button  type="text" @click="showDetails(scope.row)" >查看</el-button>
            <el-button type="text"     @click="exportDetails(scope.row)" >导出</el-button>
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>

    <!--提交财务-->
    <el-dialog
      title="提交财务"
      :visible.sync="detailImportFinanceDialogVisible"
      width="55%"
      @close="$refs.importFinance._close()"
    >
      <detail-import-finance-tables ref="importFinance"
                                    @hiddenImp="hiddenImp" @_commitFinanceConfirm="_commitFinanceConfirm"
                                    :financeAmount="financeAmount" :accountBalance="accountBalance"
                                    :edit="edit" :showDetail="showDetail"></detail-import-finance-tables>
    </el-dialog>

    <!--大库/客户名义报税查看增加客户列表-->
    <detailImportCusList v-if="this.detailVisible=='detailCus'"
                      :detailImportCusList.sync="this.detailImportCusList"
                      :cuslistQuery.sync="cuslistQuery"
                      refname="detailImportCusList"
                      @lookDetail="lookDetail"
                      @rtnDetail="rtnDetail"
                      @submitData="submitData"
                      v-loading="loading"
    >
    </detailImportCusList>

    <!--明细列表-->
    <detailImportList v-if="this.detailVisible=='true'"
      :tableColumn.sync="this.tableColumn"
      :dataList.sync="this.dataList"
      :sumItem.sync="this.sumItem"
      :noChangeList.sync="this.noChangeList"
      refname="detailImportList"
      @getSelectData="getSelectData"
      v-loading="loading"
      ref="detailImportList"
    >
    </detailImportList>

    <!--  提交录入发票信息开始-->
    <el-dialog v-dialogDrag  width="40%"
               :title="textMap['titleInfo']"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :visible.sync="billDialogVisible">
      <el-form  ref="form2" class="editFrom" :model="validateForm" label-width="168px" :rules="rules">
      <el-row>
        <el-col :span="20">
          <el-form-item label="发票抬头" prop="billTitle">
            <el-select clearable v-model="parameter.billsTitle" label-width="350px" placeholder="请选择" @change="billChange">
              <el-option
                v-for="item in billsInfoList"
                :key="item.BILLSTITLE"
                :label="item.BILLSTITLENAME"
                :value="item.BILLSTITLE"><!--BILLSTITLE -->
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="纳税人识别号">
              <el-input v-model.trim="parameter.taxNum"  disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="地址">
              <el-input v-model.trim="parameter.taxAdress"  disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="电话">
              <el-input v-model.trim="parameter.taxPhone"  disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="收件人" prop="billTitle">
              <el-select clearable v-model="parameter.recipients" label-width="350px" placeholder="请选择" @change="reciChange">
                <el-option
                  v-for="item in receAddrList"
                  :key="item.ID"
                  :label="item.RECEIVEDEPT"
                  :value="item.ID"><!-- ID-->
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="20">
            <el-form-item label="邮寄地址、邮编">
              <el-input v-model.trim="parameter.emailAddress"  disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="marginTop30"  style="text-align: center"  >
        <el-button type="primary"  @click="billSave" >  保存  </el-button>
        <el-button @click="billDialogVisible=false">  取消 </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import detailImportCusList from "@/views/entrust/detailImport/detailImportCusList";
  import detailImportList from "@/views/entrust/detailImport/detailImportList";
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import { getDetailImportBatchList,deleteImportBatch,exportDetails,commitBatch,getdynamicTable,getdynamicList,
    recalculate,getDetailImportBatchCusList,getBillsInfo,getFinanceConfirmInfo,commitFinanceConfirm,getEmpSalaryPath,downEmpSalary,updateDetail  } from '@/api/entrust/detailImport';
  import {getTransOrgIsAudit, searchAccountBalanceByOrgId} from '@/api/entrust/distributionBatch';
  import {downloadFile,downloadPdfFile} from '@/util/util';
  import {mapGetters} from 'vuex';
  import DetailImportFinanceTables from "@/views/entrust/detailImport/detailImportFinanceTables";

  export default {
    name: 'detailImportBatchList',
    inject:['reload'],
    components: {
      BspTable,
      BspTableColumn,
      detailImportList,
      detailImportCusList,
      DetailImportFinanceTables
    },
    computed: {
      ...mapGetters([
        'dicts','userInfo'
      ]),
    },
    props: {
      currentTab: {
        type: String,
        default: ''
      },

    },
    created() {
      getTransOrgIsAudit(this.userInfo.orgId).then(res=>{
        if(res.data.data.success === 'true' && (res.data.data.IS_FINANCIAL_AUDIT !== null && res.data.data.IS_FINANCIAL_AUDIT === '00901')){
          this.coOrdinationAccountAudit=true;
        } else {
          this.coOrdinationAccountAudit=false;
        }
      });
      this.searchAccountBalance();
      this.isDisabled = false;//初始化，按钮可用
      console.log("用户类型："+this.userInfo.userType+"currentTab**********"+this.currentTab);
      //列表显示项描述不一样
      if(this.currentTab=='31278300701' ||this.currentTab=='31278300702' ){
        this.taxTotalStatus="dkTaxTotal";
      }else if(this.currentTab=='31278300707'){
        console.log("代报销");
        this.taxTotalStatus="dbxTaxTotal";
      }else{
        this.taxTotalStatus="pdkTaxTotal";
      }
      this.__getDetailImportBatchList();
    },

    data() {
      return {
        senBackFinanceDialogVisible:false,//重新提交财务备注信息
        reCommitFinanceDialogVisible:false,
        coOrdinationAccountAudit:false,//是否统筹账号，并且需要财务审核
        detailImportFinanceDialogVisible:false,
        billDialogVisible:false,
        // 遮罩
        loading: false,
        // 用于接收后台返回的分页数据
        list: [],
        detailVisible: 'false',
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          bizType: '',
          batchStatus: '00900',//默认为未提交
          source:'0',//批次来源，区分是从补贴那里生成的还是自己导入生成的
        },
        parameter: {
          batchImportIds:'',
          bizType:this.currentTab,
          cusId:'',
          orgId:'',
          billsTitle:'',//BILLSTITLENAME
          taxNum:'',//F204204
          taxAdress:'',//F204205
          taxPhone:'',//F204206
          recipients:'',//RECEIVEDEPT
          emailAddress:'',//postcode
          subIds:'',//明细选择导出
          subIdFlag:'',//明细导出标识
          isSure:'false',//是否确认款项已到账号
          status:'',//状态（提交、未提交）
          batchMap:'',//批次ID关联状态ID
        },
          //多选数组
        multipleSelection: [],
        textMap: {
          dkTaxTotal: '税金总金额',
          pdkTaxTotal: '工资往来总金额',
          dbxTaxTotal: '代报销总金额',
          titleInfo:'选择发票信息',
        },
        taxTotalStatus:'dkTaxTotal',
        detailInfo:{
          batchImportId:'',//批次id
          bizType:'',//业务类型
          status:'',//提交状态，未提交/已提交
          cusId:'',
        },
        dataList: [],
        tableColumn: [], //显示表头
        sumItem:[],//需要合计的列
        detailImportCusList:[],
        cuslistQuery: {
          limit: 50,
          page: 1,
          total: 0,
          bizType: '',
          batchStatus: '00900',//默认为未提交
          batchImportId:'',
        },
        billsInfoList:[],//发票信息列表
        receAddrList:[],//收件人信息列表
        salaryParam:{//下载工资条
          subId:'',
          filePath:'',
          fileName:'',
        },
        financeAmount:{
          id:'',
          payableAmount:'',//应发金额
          paidInAmount:'',//实发金额
          taxes:'',//税金
          totalSocialSecurity:'0',//社保合计
          totalServiceCharge:'0',//服务费合计
          totalOtherExpenses:'0',//其他费用合计
          amountConfirmation:'0',//确认来款
          remark:'',//备注
          status:'',//批次状态
          remarkHis:'',//备注
          commitUser:'',//提交人
          commitTime:'',//提交时间
          coAccountBalance:'0',//统筹账户余额
          coAccount:'',//统筹账户
        },
        accountBalances:[],
        showDetail:false,
        multipleDetailSelection:[],
        noChangeList:[],//明细中不编辑的列
        updateDetailList:{
          updList:[],
          batchImportId:'',
          noChangeList:[],
        },//编辑后的数据
        edit:true,
        isDisabled: false,
        accountBalance: {
           account: '',
           accountName: '',
           balance: {
              type: Number,
              default: '0'
           }
        },
      }
    },
    methods: {
        //查询余额
        searchAccountBalance(){
        debugger
            this.parameter.orgId = this.userInfo.orgId;
            searchAccountBalanceByOrgId(this.parameter).then(res=>{
                if(res.data.data.success === 'true'){
                    this.accountBalance.balance = (res.data.data.data.BALANCE === null || res.data.data.data.BALANCE === undefined) ? 0 : res.data.data.data.BALANCE;
                    this.accountBalance.account = res.data.data.data.ACCOUNT;
                    this.accountBalance.accountName = res.data.data.data.NAME;
                }
            });
        },
      //查询明细导入批次信息
      __getDetailImportBatchList: function () {
        if(this.listQuery.batchStatus.length<=0){this.$message.error("请至少选择一种状态！");this.list =null; return;}
        console.log("查询批次信息");
        this.listQuery.bizType = this.currentTab;
        this.loading = true;
        getDetailImportBatchList(this.listQuery).then(res => {
          if (res.data.code != 0) {
            this.listQuery.total = res.data.total
            this.list = res.data.records
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
        /*提交财务*/
      commitFinance(){
          this.isDisabled = true;
          this.loading=true;
          this.parameter.batchImportIds='';
          this.edit=true;
          let flag='';
          if(this.multipleSelection.length<=0){
              this.loading=false;
              this.isDisabled = false;
              this.$message.error("请选择要提交的批次！");
              return;
          }
          if(this.multipleSelection.length>1){
              this.loading=false;
              this.isDisabled = false;
              this.$message.error("仅支持单批次提交！");
              return;
          }
          var status='';
          //将选中的id组装为string
          this.multipleSelection.forEach(item=> {
              status=item.status;
              // totalServiceChargeTmp+=Number(item.serviceTotal);
              debugger
              //不是统筹账号
              if(item.accountType != '600302'){
                  this.loading=false;
                  this.$message.error("非统筹账号批次不允许提交财务,请重新选择！");
                  flag='false';
                  return;
              }
              if(item.status !== '3004000023' && item.status !== '3004000044' && item.status !== '3004000035' && item.status !== '3004000058'){
                  this.loading=false;
                  this.$message.error("状态不是未提交/分支财务退回/财务公司退回/计算成功的批次不允许提交财务,请重新选择！");
                  flag='false';
                  return;
              }

              if(item.status=='3004000050' || item.status=='3004000051' ||item.status=='3004000052'){
                  this.loading=false;
                  this.$message.error("状态为专项提取中/获取专项失败/获取专项后计算失败的批次不允许提交,请重新选择！");
                  flag='false';
                  return;
              }
              if(item.stOrgid!='1060344'){
                  flag='true';
              }
              this.parameter.orgId=item.orgid;
              this.parameter.batchImportIds=this.parameter.batchImportIds+","+item.id;
              this.parameter.cusId = item.cusid;
          });
          this.parameter.bizType=this.currentTab;
          if(flag=='false'){
              this.isDisabled = false;
              this.loading=false;
              return;
          }else {
              debugger
              //把状态置为未提交
              this.parameter.status=status;
              //查询批次实发金额
              getFinanceConfirmInfo(this.parameter).then(res => {
                  this.loading=false;
                  this.isDisabled = false;
                  if(res.data.data.success=="true"){
                      if(status === '3004000044'){
                          this.financeAmount.id=res.data.data.id;//提交人
                          this.financeAmount.commitUser=res.data.data.submitter;//提交人
                          this.financeAmount.commitTime=res.data.data.submitTime;//提交人
                          this.financeAmount.amountConfirmation=res.data.data.amountConfirmation;//确认来款
                          this.financeAmount.totalOtherExpenses=res.data.data.totalOtherExpenses;//其他费用
                          this.financeAmount.totalServiceCharge=res.data.data.totalServiceCharge;//服务费合计
                          this.financeAmount.totalSocialSecurity=res.data.data.totalSocialSecurity;//社保合计
                          this.financeAmount.remarkHis=res.data.data.remark;//备注
                          this.financeAmount.remark='';
                          this.showDetail=false;
                      }
                      this.financeAmount.payableAmount=res.data.data.payableAmount;//应发
                      this.financeAmount.paidInAmount=res.data.data.paidInAmount;//实发金额
                      this.financeAmount.taxes=res.data.data.taxes;//税金
                      this.financeAmount.status=status;
                      this.detailImportFinanceDialogVisible=true;
                  }
              }).catch(error => {
                  this.loading=false;
                  this.isDisabled = false;
                  this.$message.error("获取批次数据失败，请联系管理员");
                  console.log(error);
              })
          }
      },

      selectChange(value) {
        console.log(value);
        if (value.selection) {
          this.multipleSelection = [];
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index];
            this.multipleSelection.push(element);
          }
        }
      },
       hiddenImp() {
         this.detailImportFinanceDialogVisible = false;
       },
        //业务人员确认来款金额
       _commitFinanceConfirm(financeAmount){
         this.isDisabled = true;
         if(financeAmount.status === '3004000044' && this.financeAmount.remark === '' || this.financeAmount.remark === null){
           this.$message.error('请填写备注信息');
           return;
         }
         var amountConfirmationTmp = parseFloat((this.financeAmount.totalOtherExpenses === '' ? 0 : this.financeAmount.totalOtherExpenses)) + parseFloat((this.financeAmount.taxes === '' ? 0 : this.financeAmount.taxes)) +
             parseFloat((this.financeAmount.totalSocialSecurity === '' ? 0 : this.financeAmount.totalSocialSecurity)) + parseFloat((this.financeAmount.totalServiceCharge === '' ? 0 : this.financeAmount.totalServiceCharge)) + parseFloat((this.financeAmount.paidInAmount === '' ? 0 : this.financeAmount.paidInAmount));
         if(amountConfirmationTmp !== parseFloat((this.financeAmount.amountConfirmation === '' ? 0 : this.financeAmount.amountConfirmation))){
             this.$confirm('来款确认金额 ≠ (实发金额 + 税金 + 社保 + 服务费 + 其他费用), 是否继续?', '提示', {
                 confirmButtonText: '确定',
                 cancelButtonText: '取消',
                 type: 'warning'
             }).then(() => {
                 this.commitFinanceChildMtd(financeAmount);
             }).catch(() => {
                 this.$message.info('已取消');
             });
         } else {
             this.commitFinanceChildMtd(financeAmount);
         }
         this.loading=false;
         this.isDisabled = false;
       },
        //提交来款金额子方法
        commitFinanceChildMtd(financeAmount){
            this.loading=true;
            var parameter = this.parameter;
        debugger
            commitFinanceConfirm(financeAmount, parameter).then(res=>{
            debugger
                if(res.data.data.success=="true") {
                    this.$message.success('提交成功！');
                    this.detailImportFinanceDialogVisible=false;
                    this.__getDetailImportBatchList();
                    this.parameter.batchImportIds="";
                    this.financeAmount.taxes=0;
                    this.financeAmount.totalOtherExpenses=0;
                    this.financeAmount.totalSocialSecurity=0;
                    this.financeAmount.totalServiceCharge=0;
                    this.financeAmount.paidInAmount=0;
                    this.financeAmount.amountConfirmation=0;
                    // 处理数据 不能编辑的能编辑的
                    console.log("==========================");
                    this.loading=false;
                } else {
                    this.loading=false;
                    this.$message.error('提交财务失败！');
                }
            })
        },
        /*查看财务退回详情*/
        showSendBackDetails(row){
          debugger
          var batchId = row.id;
            this.parameter.batchImportIds = ','+batchId;
            this.parameter.status=row.status;
            //查询批次实发金额
            getFinanceConfirmInfo(this.parameter).then(res => {
                if(res.data.data.success=="true"){
                    if(row.status === '3004000044'){
                        this.financeAmount.id=res.data.data.id;//提交人
                        this.financeAmount.amountConfirmation=res.data.data.amountConfirmation;//确认来款
                        this.financeAmount.totalOtherExpenses=res.data.data.totalOtherExpenses;//其他费用
                        this.financeAmount.totalServiceCharge=res.data.data.totalServiceCharge;//服务费合计
                        this.financeAmount.totalSocialSecurity=res.data.data.totalSocialSecurity;//社保合计
                        this.financeAmount.remarkHis=res.data.data.remark;//备注
                        this.financeAmount.commitTime=res.data.data.submitTime;//提交人
                        this.financeAmount.commitUser=res.data.data.submitter;//提交时间
                        this.financeAmount.coAccount=res.data.data.account;//付款账户
                        this.financeAmount.remark='';
                        this.showDetail = true;
                        this.edit = false;
                    }
                    this.financeAmount.payableAmount=res.data.data.payableAmount;//应发
                    this.financeAmount.paidInAmount=res.data.data.paidInAmount;//实发金额
                    this.financeAmount.taxes=res.data.data.taxes;//税金
                    this.financeAmount.status=row.status;
                    this.detailImportFinanceDialogVisible=true;
                }
            }).catch(error => {
                this.$message.error("获取批次数据失败，请联系管理员");
                console.log(error);
            })
        },

      //复选框全选事件
      selectAll({checked, selection}) {
        if(checked){
          this.multipleSelection= selection
        }else{
          this.multipleSelection=[];
        }
        console.log("this.multipleSelection:"+this.multipleSelection);
      },

      // 底部合计
      footerMethod({columns, data}) {
        return [
          columns.map((column, columnIndex) => {
            if (columnIndex === 0) {  return '合计' }
            if (['taxTotal','serviceTotal'].includes(column.property)) {
              return this.$options.filters['moneyFilter'](this.$utils.sum(data, column.property))
            }
            return null
          })
        ]
      },

      //点击导入
      importDetail(){
        this.isDisabled = true;
          this.$router.push({
          path: '/wt/importDetail',
          query: {
            bizType: this.currentTab,//对应业务类型
          }
        })
        this.isDisabled = false;
      },
      //单条导出
      exportDetails(row){
        this.isDisabled = true;
        this.loading=true;
        this.parameter.batchImportIds="";
        if(row!=null && row.id.length>0){
          console.log("单次操作");
          this.parameter.batchImportIds=row.id;//对应批次id
        }else{
          if(this.multipleSelection.length<=0){ this.$message.error("请选择要导出的批次！");this.loading=false;return; }
          if(this.multipleSelection.length>0){
            //将选中的id组装为string
            this.multipleSelection.forEach(item=> {
              this.parameter.batchImportIds=this.parameter.batchImportIds+","+item.id;
            });
          }
        }

        console.log("批次id："+this.parameter.batchImportIds);
        this.parameter.bizType=this.currentTab;//对应业务类型 todo 怎么从tables里面直接获取相关值
        exportDetails(this.parameter).then(res => {
          this.loading=false,
          this.isDisabled = false;
          downloadFile(res);
        })
      },

      //删除批次信息
      //TODO 需要测试多种情况下的删除
      deleteImportBatch(row) {
        this.isDisabled = true;
        this.parameter.batchImportIds="";
        if(this.multipleSelection.length<=0){ this.$message.error("请选择要删除的批次！");this.isDisabled = false;return; }
        //将选中的id组装为string
        this.multipleSelection.forEach(item=> {
          if(item.status === '3004000025' || item.status === '3004000043'){
            this.$message.error("状态是提交财务/分支财务已确认的批次不允许删除,请重新选择！");
            this.loading=false;
            this.isDisabled = false;
            flag='false';
            return;
          }
          this.parameter.batchImportIds=this.parameter.batchImportIds+","+item.id;
        });
        console.log("批次id："+this.parameter.batchImportIds);
        this.$confirm('您确认要删除所选中的批次吗？删除就无法还原请慎重选择！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading=true;
          deleteImportBatch(this.parameter).then(res => {
            this.loading=false;
            this.isDisabled = false;
            if (res.data.data.code) {
              this.$message.success(res.data.data.msg);
            }else{
              this.$message.error(res.data.data.msg);
            }
            this.__getDetailImportBatchList();
            this.parameter.batchImportIds="";
          })
        }).catch(() => {
          this.isDisabled = false;
          this.loading=false;
          this.__getDetailImportBatchList();
        });
      },

      //查看详情
      showDetails(row) {
        console.log("批次id："+row.id+";业务类型："+row.bizType);
        if((row.bizType=="31278300701" || row.bizType=="31278300702") && this.listQuery.batchStatus=="00901"
          && this.userInfo.userType!='2'){
          this.detailVisible='detailCus';
          this.cuslistQuery.batchImportId=row.id;
          this.cuslistQuery.bizType=row.bizType;
          this.__getDetailImportBatchCusList();
        }else {
            this.detailVisible='true';
            this.detailInfo.batchImportId=row.id;
            this.detailInfo.bizType=row.bizType;
            this.detailInfo.status=row.status;
            this.detailInfo.cusId=row.cusid;
            this._getdynamicTable();
            this._getdynamicList();
        }
      },

      //动态表头
      _getdynamicTable:function(){
        console.log("查看对应的批次id："+this.detailInfo.batchImportId);
        getdynamicTable(this.detailInfo).then(res => {
          if(res.data.data.success=="true"){
            this.tableColumn=res.data.data.tableColumn;
            this.sumItem=res.data.data.sumItem;
            this.noChangeList=res.data.data.noChangeList;
            // 处理数据 不能编辑的能编辑的
            console.log("==========================");
          if(this.listQuery.batchStatus=='00900'){
            for (let i = 0; i < this.tableColumn.length; i++) {
               if(this.noChangeList.indexOf(this.tableColumn[i].field)==-1){
                  if(this.tableColumn[i].setId && this.dicts.codeList[this.tableColumn[i].setId]){
                    this.tableColumn[i].editRender =  {
                        name:"$select",
                      options:this.dicts.codeList[this.tableColumn[i].setId],
                      optionProps:{
                        value:"codeItemName",
                        label:"codeItemName",
                        disabled:false
                      }
                    }
                  } else{
                    this.tableColumn[i].editRender = {name: 'input'}
                  }
               }
              }
          }
          }
        })
      },
      //动态数据
      _getdynamicList(){
        getdynamicList(this.detailInfo).then(res => {
          this.listQuery.total = res.data.total
          this.dataList = res.data.records
        })
      },
      //明细导出
      exportDetailsTwo(id){
        this.loading=true;
        this.parameter.subIds='';
        this.parameter.batchImportIds='';
        if(this.multipleDetailSelection.length>0){
          this.multipleDetailSelection.forEach(item=> {
            this.parameter.subIds=this.parameter.subIds+","+item.SUBID;
          });
          console.log("选中的subid:"+this.parameter.subIds);
        }else{
            this.parameter.batchImportIds= this.detailInfo.batchImportId;//对应批次id
        }
        console.log("批次id："+this.parameter.batchImportIds+"====");
        this.parameter.bizType=this.currentTab;//对应业务类型 todo 怎么从tables里面直接获取相关值
        this.parameter.cusId=this.detailInfo.cusId;
        exportDetails(this.parameter).then(res => {
          this.loading=false;
          downloadFile(res);
        })
      },

      //重新计算
      recalculate(){
        this.isDisabled = true;
        this.loading=true;
        //todo 添加加载
        //获取选中的批次信息
        if(this.multipleSelection.length<=0){ this.$message.error("请选择要重新计算的批次！");this.loading=false; this.isDisabled = false; return; }
        //将选中的id组装为string
        this.multipleSelection.forEach(item=> {
            if(item.status === '3004000025' || item.status === '3004000043'){
                this.$message.error("状态是提交财务/分支财务已确认的批次不允许重新计算,请联系分支财务退回后重新操作！");
                this.loading=false;
                this.isDisabled = false;
                flag='false';
                return;
            }
          if(item.status=='3004000050' || item.status=='3004000051' ||item.status=='3004000052'){
            this.$message.error("状态为专项提取中/获取专项失败/获取专项后计算失败的批次不允许重新计算,请重新选择！");
            this.isDisabled = false;
            this.loading = false;
            flag='false';
            return;
          }
          this.parameter.batchImportIds=this.parameter.batchImportIds+","+item.id;
        });
        //调用重新计算的方法
        recalculate(this.parameter).then(res => {
          this.isDisabled = false;
          this.loading=false;
          if(res.data.data.success=="true"){
            this.$message.success("重新计算完成！");
            this.__getDetailImportBatchList();
          }else{
            this.$message.error(res.data.data.msg);
          }
        })
      },

      //提交
      commitBatch(){
        this.isDisabled = true;
        this.loading=true;
        debugger
        this.parameter.batchImportIds='';
        let flag='';
        if(this.multipleSelection.length<=0){
            this.$message.error("请选择要提交的批次！");
            this.loading=false;
            this.isDisabled = false;
            return;
        }
        //创建json,用于存储批次ID的状态
        var jsonStr="[";
        //将选中的id组装为string
        this.multipleSelection.forEach(item=> {
          //是统筹账号,但是状态不是审核通过的状态，不允许提交
          if(item.accountType === '600302' && item.status!=='3004000043' && this.coOrdinationAccountAudit){
              debugger
            this.$message.error("统筹账号分支财务未确认不允许提交,请重新选择！");
            this.isDisabled = false;
            this.loading=false;
            flag='false';
            return;
          }
          if(item.status=='3004000050' || item.status=='3004000051' ||item.status=='3004000052'){
            this.$message.error("状态为专项提取中/获取专项失败/获取专项后计算失败的批次不允许提交,请重新选择！");
            flag='false';
            this.isDisabled = false;
            this.loading=false;
            return;
          }
          if(item.stOrgid!='1060344'){
            flag='true';
          }
          this.parameter.orgId=item.orgid;
          this.parameter.batchImportIds=this.parameter.batchImportIds+","+item.id;
          jsonStr+='{"'+item.id+'":"'+item.status+'"},';
        });
        jsonStr = jsonStr.substring(0, jsonStr.length-1) + "]";
        this.parameter.batchMap=eval(""+jsonStr+"");
        this.parameter.bizType=this.currentTab;
        if(flag=='false'){  this.isDisabled = false; return;  }
        //业务类型不等于大库报税并且不是客户名义报税
        if(flag=='true' && this.parameter.bizType!='31278300701' && this.parameter.bizType!='31278300702'){
            this.loading=false;
            //查询发票抬头信息 收件人信息
            getBillsInfo(this.parameter).then(res => {
              if(res.data.data.success=="true"){
                  this.billsInfoList=res.data.data.billsInfoList;//发票信息
                  this.receAddrList=res.data.data.receAddrList;//收件人信息
              }
            })
            this.billDialogVisible=true;
        }else{ this.commonCommit();}

        /*commitBatch(this.parameter).then(res => {
          if(res.data.data.msg==""){
            this.$message.success("提交成功！");
          }else{
            this.$message.error(res.data.data.msg);
          }
          this.__getDetailImportBatchList();
        })*/
      },
      //发票信息保存
      billSave(){
        console.log("billsTitle:"+this.parameter.billsTitle+";recipients:"+this.parameter.recipients);
        if(this.parameter.billsTitle==""){
          this.$message.error("请选择发票信息！");
          return;
        }
        if(this.parameter.recipients==""){
          this.$message.error("请选择收件人！");
          return;
        }
        this.commonCommit();
        this.billDialogVisible=false;
      },
      //公共提交方法
      commonCommit(){
        this.isDisabled = true;
        this.loading=true;
        commitBatch(this.parameter).then(res => {
          if(res.data.data.msg==""){
            this.isDisabled = false;
            this.loading=false;
            this.$message.success("提交成功！");
            this.__getDetailImportBatchList();
            this.parameter.batchImportIds='';
          }else if(res.data.data.msg=="0000"){
            this.loading=false;
            this.commitBatchTransfer();
          }else{
            this.isDisabled = false;
            this.loading=false;
            this.$message.error(res.data.data.msg);
            this.__getDetailImportBatchList();
            this.parameter.batchImportIds='';
          }
        })
      },

      //分支有统筹账号时，需要再确认一下才进行提交
      commitBatchTransfer(){
        //增加一个统筹账号的提示
        this.$confirm('是否确认款项已到账号？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading=true;
          this.parameter.isSure='true';
          commitBatch(this.parameter).then(res => {
            if(res.data.data.msg==""){
              this.loading=false;
              this.isDisabled = false;
              this.$message.success("提交成功！");
            }else{
              this.isDisabled = false;
              this.loading=false;
              this.$message.error(res.data.data.msg);
            }
            this.parameter.isSure='false';
            this.__getDetailImportBatchList();
            this.parameter.batchImportIds='';
          })
        }).catch(() => {
          this.parameter.isSure='false';
          this.loading=false;
          this.isDisabled = false;
          this.multipleSelection='';
          this.__getDetailImportBatchList();
          this.parameter.batchImportIds='';
        });
      },

      //大库/客户名义点击批次中查看
      __getDetailImportBatchCusList() {
        this.cuslistQuery.bizType = this.currentTab;
        getDetailImportBatchCusList(this.cuslistQuery).then(res => {
          if (res.data.code != 0) {
            this.cuslistQuery.total = res.data.total
            this.detailImportCusList = res.data.records
          } else {
          }
        })
      },
      //返回
      goback(){
          debugger
          console.log("业务类型：" + this.cuslistQuery.bizType + "，状态：" +this.listQuery.batchStatus);
          this.cuslistQuery.bizType = this.currentTab;
          if((this.cuslistQuery.bizType === '31278300701' || this.cuslistQuery.bizType === '31278300702') && this.listQuery.batchStatus === '00901'){
              this.detailVisible='detailCus';
              this.listQuery.filters=[];
              this.__getDetailImportBatchCusList();
          } else {
              this.detailVisible='false';
              this.listQuery.filters=[];
              this.__getDetailImportBatchList();
          }
      },
      //查看详情
      lookDetail(row){
        console.log(row.bizType+"==="+row.cusid)
        this.detailVisible='true';
        this.detailInfo.batchImportId=row.importBatchId;
        this.detailInfo.bizType=row.bizType;
        this.detailInfo.status=row.status;
        this.detailInfo.cusId=row.cusid;
        this._getdynamicTable();
        this._getdynamicList();
      },
      //客户列表里面返回
      rtnDetail(){
          this.detailVisible='false';
          this.listQuery.filters=[];
          this.__getDetailImportBatchList();
      },
      //提交之后重新刷新列表
      submitData(){this.__getDetailImportBatchCusList();},
      //发票抬头改变
      billChange(val){
        console.log("发票抬头改变-val=",val);
        this.billsInfoList.forEach(item=> {
          if(val==item.BILLSTITLE){
            this.parameter.billsTitle=item.BILLSTITLENAME;
            this.parameter.taxNum=item.F204204;//F204204
            this.parameter.taxAdress=item.F204205;//F204205
            this.parameter.taxPhone=item.F204206;//F204206
            return;
          }
        });
      },
      reciChange(val){
        console.log("收件人信息改变-val=",val);
        this.receAddrList.forEach(item=> {
          if(val==item.ID){
            this.parameter.recipients=item.RECEIVEDEPT;
            this.parameter.emailAddress=item.POSTCODE;
            return;
          }
        });
      },

      //明细选中的数据
      getSelectData(multipleDetailSelection){
        console.log(multipleDetailSelection)
        this.multipleDetailSelection=multipleDetailSelection;
      },
      //在线编辑保存
      saveEvent(){

        console.log(this.$refs.detailImportList);
        let  test= this.$refs.detailImportList.$refs.xGrid.getRecordset();
        this.updateDetailList.updList=test.updateRecords;
        this.updateDetailList.batchImportId=this.detailInfo.batchImportId;
        this.updateDetailList.noChangeList=this.noChangeList;
        console.log("======================"+this.updateDetailList.updList);
        console.log(test);
        if(this.updateDetailList.updList==null ||this.updateDetailList.updList.length<=0){
          this.$message.error("请修改后再进行批量保存!");
          return;
        }
        this.$confirm('您确认要保存修改数据吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading=true;
          updateDetail(this.updateDetailList).then(res => {
            console.log(res.data.data.info);
            if (res.data.data.info == "true") {
              this.$message.success("保存成功！");
              this._getdynamicList();
              this.loading = false;
              return;
            } else {
              this.$message.error({
                iconClass: "iconMessage",
                customClass: "errMessage",
                dangerouslyUseHTMLString: true,
                message: res.data.data.info,
                showClose: true,
                duration: 0
              });
              //this.$message.error(res.data.data.info);
              this.loading = false;
              return;
            }
          })
        }).catch(() => {
          this.loading = false;
          return;
        });
      } ,
      //工资单下载
      downEmpSalary(){
        this.loading=true;
        if(this.multipleDetailSelection.length<=0){ this.$message.error("请选择一条数据！"); this.loading=false;return; }
        if(this.multipleDetailSelection.length>1){ this.$message.error("只能选择一条数据！"); this.loading=false;return; }
        this.multipleDetailSelection.forEach(item=> {
          this.salaryParam.subId=item.SUBID;
        });
        getEmpSalaryPath(this.salaryParam).then(res => {
          if(res.data.data.success=="true"){
            this.salaryParam.filePath=res.data.data.path;
            this.salaryParam.fileName=res.data.data.fileName;
            downEmpSalary(this.salaryParam).then(res => {
              console.log("工资单下载");
              if(this.salaryParam.fileName.indexOf(".pdf")!=-1){downloadPdfFile(res);}else{downloadFile(res);}
              this.loading=false;
            })
          }
        })
      },

    }
  }
</script>
<style scoped>
  .copy-btn{
    cursor: pointer;
  }
  .ciic-table-operate{
    align-items: center;
  }
  .editForm{
    margin: 0;
  }
  .editForm .el-form-item{
    margin: 0;
  }
</style>
