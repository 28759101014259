<template>
  <div>
    <el-form
      class="ruleForm"
      :model="listQuery"
      size="small"
      label-width="70px"
      @submit.native.prevent
    >
      <el-row>
        <el-col :span="6" v-if="this.isVisible == 'true'">
          <el-form-item label="客户名称">
            <el-input
              v-model="listQuery.cusName"
              placeholder="客户名称"
              :disabled="loading"
              @keyup.enter.native="seacrhEnter"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="this.isVisible == 'true'">
          <el-form-item label-width="20px">
            <el-button type="primary" @click="seacrhEnter">
              查&emsp;询
            </el-button>
          </el-form-item>
        </el-col>

        <el-col :span="24" v-if="this.isVisible !== 'true'">
          <el-form-item label-width="50px" class="pull-right">
            <el-button type="primary" v-if="this.isVisible === 'others'" size="small" fixed="right"
                       @click="_empTaxationConf()">配置起征点
            </el-button>
            <el-button
              type="primary"
              @click="goback"
              style="width: 76px;margin-right: 5px"
            >
              返&emsp;回
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="divButtonRigth">
      <el-button type="primary" v-if="this.isVisible === 'true'" size="small" fixed="right"
                 @click="batchTaxationConf()">批量配置起征点
      </el-button>
      <el-button type="primary" v-if="this.isVisible === 'true'" size="small" fixed="right"
                 @click="batchEmpTaxationConf()">雇员配置起征点
      </el-button>
    </div>
    <bsp-table
      v-if="this.isVisible == 'true'"
      :data.sync="list"
      :tree-config="true"
      @select-change="selectChange"
      @select-all="selectAll"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="loadList"
      highlight-hover-row
      max-height="500"
      show-overflowwidth
      show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
    >
      <vxe-table-column
        type="selection"
        width="60"
        fixed="left"
        align="center"
      />
      <vxe-table-column
        title="序号"
        type="index"
        width="60"
        fixed="left"
        align="center"
      />
      <bsp-table-column title="客户ID" field="cusId" fixed="left" v-if="false">
        <template slot-scope="scope">
          <span>{{ scope.row.cusid }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="计算方式" field="caclWay" fixed="left" v-if="false">
        <template slot-scope="scope">
          <span>{{ scope.row.caclWay }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="客户编号" field="cusCode" fixed="left">
        <template slot-scope="scope">
          <span>{{ scope.row.cusCode }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="客户名称" field="cusName" fixed="left">
        <template slot-scope="scope">
          <span>{{ scope.row.cusName }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="操作人" field="managerName" fixed="left">
        <template slot-scope="scope">
          <span>{{ scope.row.managerName }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="接口人" field="busiRecheckName" fixed="left">
        <template slot-scope="scope">
          <span>{{ scope.row.busiRecheckName }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="所属对公机构" field="pubOrg" fixed="left">
        <template slot-scope="scope">
          <span>{{ scope.row.pubOrgName }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="客户状态" field="status" fixed="left">
        <template slot-scope="scope">
          <span>{{ scope.row.status }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="累计起征点计算方式" field="status" fixed="left">
        <template slot-scope="scope">
          <span>{{(scope.row.caclWay === null || scope.row.caclWay === 'PT') ? '薪税计算逻辑' : '税局计算逻辑'}}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="操作" fixed="right" align="center">
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button v-if="hasCusPayableRole" type="text" @click="wageItemConf(scope.row)">
              应发配置
            </el-button>
            <el-button type="text" @click="taxationConf(scope.row)">
              起征点配置
            </el-button>
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>

    <wageItemFormulaList
      v-if="this.isVisible == 'false'"
      :formula-selected.sync="this.formulaSelected"
      refname="wageItemFormulaList"
      v-loading="loading"
      ref="wageItemFormulaList"
    />

    <!--雇员明细列表-->
    <empTaxationConf v-if="this.isVisible=='others'"
                     :tableColumn.sync="this.tableColumn"
                     :empDataList.sync="this.empDataList"
                     @ciic-data-refresh="__batchEmpTaxationConf"
                     :ciic-data-filter.sync="listQuery"
                     :cusIdList="listQuery.cusIdList"
                     refname="empTaxationConf"
                     @getSelectData="getSelectData"
                     v-loading="loading"
                     ref="empTaxationConf"
    >
    </empTaxationConf>

    <el-dialog v-dialogDrag width="30%"
               :title="batchDialogTitle"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :visible.sync="batchDialogVisible">
      <el-form ref="form2" class="editFrom" :model="validateForm" label-width="168px" :rules="rules">
        <el-row>
          <el-col :span="20">
            <el-form-item label="累计起征点计算方式">
              <el-radio v-bind:disabled="isDisabled" v-model="parameter.caclWay" label="PT">薪税计算逻辑</el-radio>
              <el-radio v-bind:disabled="isDisabled" v-model="parameter.caclWay" label="TB">税局计算逻辑</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-alert title="需要注意的事项" type="warning">
        <template slot='title'>
          <div class="iconSize">温馨提示:</div>
          <div class="iconSize">1、仅支持税金所属年度内未导入薪资明细的客户修改计算方式</div>
          <div class="iconSize">2、修改为税局方式后有一次变更成薪税方式的机会，使用薪税方式则不能反向修改</div>
          <div class="iconSize">3、客户设置后，雇员默认使用客户的计算方式，也可以单独配置雇员计算方式</div>
        </template>
      </el-alert>
      <div class="marginTop20" style="text-align: center">
        <el-button type="primary" @click="batchSave"> 保存</el-button>
        <el-button @click="batchCancel"> 取消</el-button>
      </div>
    </el-dialog>


    <el-dialog v-dialogDrag width="30%"
               title="雇员配置"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :visible.sync="batchEmpDialogVisible">
      <el-form ref="form2" class="editFrom" :model="validateForm" label-width="168px" :rules="rules">
        <el-row>
          <el-col :span="20">
            <el-form-item label="累计起征点计算方式">
              <el-radio @click.native="changeCaclRadio('PT')" v-model="parameter.caclWay" label="PT">薪税计算逻辑</el-radio>
              <el-radio @click.native="changeCaclRadio('TB')" v-model="parameter.caclWay" label="TB">税局计算逻辑</el-radio>
            </el-form-item>
          </el-col>
          <el-col v-if="isTB" :span="20" >
            <el-form-item label="是否扣除减除费用">
              <el-radio v-model="parameter.isToDeductExpenses" label="00901">是</el-radio>
              <el-radio v-model="parameter.isToDeductExpenses" label="00900">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-alert title="需要注意的事项" type="warning">
        <template slot='title'>
          <div class="iconSize">温馨提示:</div>
          <div class="iconSize">1、仅支持税金所属年度内未导入薪资明细的客户修改计算方式</div>
          <div class="iconSize">2、修改为税局方式后有一次变更成薪税方式的机会，使用薪税方式则不能反向修改</div>
          <div class="iconSize">3、客户设置后，雇员默认使用客户的计算方式，也可以单独配置雇员计算方式</div>
        </template>
      </el-alert>
      <div class="marginTop20" style="text-align: center">
        <el-button type="primary" @click="batchEmpSave"> 保存</el-button>
        <el-button @click="batchEmpCancel"> 取消</el-button>
      </div>
    </el-dialog>

    <!--  修改累计起征点计算方式-->
    <el-dialog v-dialogDrag width="40%"
               :title="dialogTitle"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :visible.sync="dialogVisible">
      <el-form ref="form2" class="editFrom" :model="validateForm" label-width="168px" :rules="rules">
        <el-row>
          <el-col :span="20">
            <el-form-item label="客户编号">
              <el-input v-model.trim="parameter.cusCode" disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="客户名称">
              <el-input v-model.trim="parameter.cusName" disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="操作人">
              <el-input v-model.trim="parameter.managerName" disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="接口人">
              <el-input v-model.trim="parameter.busiRecheckName" disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="所属对公机构">
              <el-input v-model.trim="parameter.pubOrgName" disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="20">
            <el-form-item label="客户状态">
              <el-input v-model.trim="parameter.status" disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="20">
            <el-form-item label="累计起征点计算方式">
              <el-radio v-bind:disabled="isDisabled" v-model="parameter.caclWay" label="PT">薪税计算逻辑</el-radio>
              <el-radio v-bind:disabled="isDisabled" v-model="parameter.caclWay" label="TB">税局计算逻辑</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-alert title="需要注意的事项" type="warning">
        <template slot='title'>
          <div class="iconSize">温馨提示:</div>
          <div class="iconSize">1、仅支持税金所属年度内未导入薪资明细的客户修改计算方式</div>
          <div class="iconSize">2、修改为税局方式后有一次变更成薪税方式的机会，使用薪税方式则不能反向修改</div>
          <div class="iconSize">3、客户设置后，雇员默认使用客户的计算方式，也可以单独配置雇员计算方式</div>
        </template>
      </el-alert>
      <div class="marginTop20" style="text-align: center">
        <el-button type="primary" @click="save"> 保存</el-button>
        <el-button @click="cancel"> 取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import wageItemFormulaList from "@/views/cus/payableConf/wageItemFormulaList";
  import empTaxationConf from "@/views/cus/payableConf/empTaxationConf";
  import {getPayableRoleList} from '@/api/sys/sysconf';
  import {
    batchUpdateCusCaclWay,
    checkCusIsImported,
    checkEmpIsImported,
    searchCusList,
    searchEmpList,
    updateCusCaclWay,
    updateEmpCaclWay
  } from "@/api/cus/payableConf";
  import {mapGetters} from "vuex";

  export default {
    name: "CusPayableList",
    inject: ["reload"],
    components: {
      BspTable,
      BspTableColumn,
      wageItemFormulaList,
      empTaxationConf,
    },
    computed: {
      ...mapGetters(["dicts", "userInfo"])
    },

    created() {
      console.log('created');
      getPayableRoleList().then(res => {
          debugger
          console.log("结果res=====>", res.data.data);
          this.payableRoleList = res.data.data;
          this.loadList();
        }
      );
    },

    data() {
      return {
        loading: true, //是否刷新
        isVisible: "true",
        isDisabled: false,
        formulaListOpen: false,
        formulaSelected: {},
        multipleSelection: [],
        empMultipleSelection: [],
        // 用于接收后台返回的分页数据
        list: [],
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          cusIdList: [],
          cusName:"",
        },
        isTB: false,
        empDataList: [],
        cusIdList: [],
        dialogTitle: '修改累计起征点计算方式',
        batchDialogTitle: '批量配置起征点计算方式',
        hasCusPayableRole: false,
        dialogVisible: false,
        batchDialogVisible: false,
        batchEmpDialogVisible: false,
        payableRoleList: '',
        parameter: {
          cusid: '',
          cusCode: '',
          cusName: '',
          managerName: '',
          busiRecheckName: '',
          pubOrgName: '',//
          status: '',//
          caclWay: '',
          isToDeductExpenses: '',
          cusIdList: [],
          empIdList: [],
          isImported: '',
        },
        searchParamOld:""
      };
    },
    methods: {
      loadList() {
        this.multipleSelection = [];
        this.empMultipleSelection = [];
        console.log('loadList')
        this.loading = true;
        //每年1月份才可以进行修改
        this.isDisabled = false;
        /*if (new Date().getMonth() + 1 !== 1) {
          this.isDisabled = true;
          this.dialogTitle = '查看累计起征点计算方式'
        }*/
        searchCusList(this.listQuery).then(res => {
          console.log("结果res=====>", res);
          this.list = res.data.records;
          this.listQuery.total = res.data.total;
          this.loading = false;
        });

        debugger
        //判断是否包含应发角色
        console.log(this.userInfo.userRoleList);
        this.userInfo.userRoleList.forEach(role => {
          debugger
          if (this.payableRoleList.length !== 0 && this.payableRoleList.indexOf(role.roleId) !== -1) {
            this.hasCusPayableRole = true;
          }
        });
      },

      //明细选中的数据
      getSelectData(multipleDetailSelection, empDataList){
        console.log(multipleDetailSelection);
        this.empMultipleSelection=multipleDetailSelection;
        this.empDataList = empDataList;
      },

      selectChange(value) {
        console.log(value)
        if (value.selection) {
          this.multipleSelection = []
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index]
            this.multipleSelection.push(element)
          }
          console.log('this.multipleSelection:' + this.multipleSelection)
        }
      },
      //复选框全选事件
      selectAll({checked, selection}) {
        if (checked) {
          this.multipleSelection = selection
        } else {
          this.multipleSelection = []
        }
        console.log('this.multipleSelection:' + this.multipleSelection)
      },

      batchTaxationConf() {
        if (this.multipleSelection.length > 0) {
          this.batchDialogVisible = true;
          this.parameter.caclWay = 'PT';
        } else {
          this.$message.warning('请至少选择一条数据');
        }
      },

      _empTaxationConf() {
        debugger
        if (this.empMultipleSelection.length > 0) {
          this.batchEmpDialogVisible = true;
          this.isVisible = 'others';
          this.parameter.caclWay = 'PT';
        } else {
          this.$message.warning('请至少选择一条数据');
        }
        if(this.parameter.caclWay === 'TB'){
          this.isTB = true;
        } else {
          this.isTB = false;
          this.parameter.isToDeductExpenses = '00900';
        }
      },

      batchEmpTaxationConf() {
        debugger
        this.listQuery.cusIdList=[];
        if (this.multipleSelection.length > 0) {
          this.isVisible = 'others';
        } else {
          this.$message.warning('请至少选择一条数据');
          return false;
        }
        this.multipleSelection.forEach(row=>{
          this.listQuery.cusIdList.push(row.cusid)
        })
        debugger
        this.loading = false;
        this.cusIdList = this.listQuery.cusIdList;
        console.log(this.$refs.empTaxationConf);
        /*searchEmpList(this.listQuery).then(res => {
          this.listQuery.total = res.data.total;
          this.empDataList = res.data.records;
          this.loading = false;
        })*/
        this.loading = false;
      },

      __batchEmpTaxationConf() {
        this.listQuery.cusIdList=[];
        this.multipleSelection.forEach(row=>{
          this.listQuery.cusIdList.push(row.cusid)
        })
        debugger
        this.loading = false;
        console.log(this.$refs.empTaxationConf);
        searchEmpList(this.listQuery).then(res => {
          this.listQuery.total = res.data.total;
          this.empDataList = res.data.records;
          this.loading = false;
        })
        this.loading = false;
      },

      wageItemConf(row) {
        this.isVisible = "false";
        this.formulaSelected = row;
        this.$store.state.formulaSelected = row;
      },

      taxationConf(row) {
        debugger
        this.dialogVisible = true;
        this.parameter.cusCode = row.cusCode;
        this.parameter.cusName = row.cusName;
        this.parameter.managerName = row.managerName;
        this.parameter.busiRecheckName = row.busiRecheckName;
        this.parameter.pubOrgName = row.pubOrgName;
        this.parameter.status = row.status;
        this.parameter.cusid = row.cusid;
        this.parameter.caclWay = (row.caclWay === '' || row.caclWay === undefined || row.caclWay === null) ? 'PT' : row.caclWay;
      },

      changeCaclRadio(cacl){
        if(cacl === 'TB'){
          this.isTB = true;
          this.parameter.isToDeductExpenses = '00901';
        } else {
          this.isTB = false;
        }
      },

      /**
       * 批量设置累计起征点计算方式
       */
      batchSave() {
        debugger
        if (this.multipleSelection.length > 0) {
          const cacl = this.parameter.caclWay;
          console.log(cacl)
          debugger
          this.$confirm('选择客户累计起征点计算方式，将批量更新雇员计算方式?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.loading = true;
            this.multipleSelection.forEach(res => {
              this.parameter.cusIdList.push(res.cusid);
            })
            this.parameter.caclWay = cacl;
            checkCusIsImported(this.parameter).then(res=>{
              debugger
              if (res.data.code === 0) {
                this.parameter.isImported = res.data.msg;
                batchUpdateCusCaclWay(this.parameter).then(res => {
                  debugger
                  if (res.data.code === 0) {
                    this.$message.success("保存成功");
                    this.batchDialogVisible = false;
                    this.reload();
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                this.$message.error("该客户本年度已经存在导入数据，不允许修改。" + res.data.msg);
                this.loading = false;
              }
            })
          });
          this.batchDialogVisible = false;
          this.loading = false;
        } else {
          this.$message.warning('请至少选择一条数据');
        }
      },

      /**
       * 取消设置累计起征点计算方式
       */
      batchCancel() {
        this.batchDialogVisible = false;
      },

      /**
       * 取消设置累计起征点计算方式
       */
      batchEmpCancel() {
        this.batchEmpDialogVisible = false;
        this.isVisible = 'others';
      },

      /**
       * 保存设置累计起征点计算方式
       */
      batchEmpSave() {
        this.parameter.empIdList = [];
        this.batchEmpDialogVisible = false;
        this.empMultipleSelection.forEach(row=>{
          this.parameter.empIdList.push(row.ID);
        })
        checkEmpIsImported(this.parameter).then(res=>{
          if(res.data.code === 0){
            this.parameter.isImported = res.data.msg;
            updateEmpCaclWay(this.parameter).then(res=>{
              if(res.data.code === 0){
                debugger
                this.$message.success("更新成功");
                this.__batchEmpTaxationConf();
              } else {
                this.$message.error("更新失败");
              }
            })
          } else {
            this.$message.error("当前雇员本年度已存在导入批次，不允许修改。" + res.data.msg);
            this.loading = false;
          }
        })

        debugger
      },

      /**
       * 取消设置累计起征点计算方式
       */
      cancel() {
        this.dialogVisible = false;
      },

      save() {
        this.$confirm('选择客户累计起征点计算方式，将批量更新雇员计算方式?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.loading = true;
          this.parameter.cusIdList.push(this.parameter.cusid);
          checkCusIsImported(this.parameter).then(res=>{
            debugger
            if (res.data.code === 0) {
              this.parameter.isImported = res.data.msg;
              updateCusCaclWay(this.parameter).then(res => {
                debugger
                if (res.data.code === 0) {
                  this.$message.success("保存成功");
                  this.reload();
                } else {
                  this.$message.error(res.data.msg);
                  this.loading = false;
                }
              });
            } else {
              this.$message.error("当前客户本年度已经导入数据，不允许修改。" + res.data.msg);
              this.loading = false;
            }
          })
        });
        this.loading = false;
      },

      goback() {
        this.multipleSelection = [];
        this.empMultipleSelection = [];
        this.isVisible = "true";
        this.listQuery.page = 1; //点击查询从第一页开始查询数据.
        this.loadList();
      },
      seacrhEnter() {
        if(this.searchParamOld != this.listQuery.cusName){
          this.listQuery.page = 1; //点击查询从第一页开始查询数据.
          this.searchParamOld = this.listQuery.cusName;
        }
        this.loadList();
      }
    }
  };
</script>

<style scoped></style>
