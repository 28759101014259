import request from '@/router/axios';
const urlPrefix = '';

//
export function entrustPriceList(obj) {
  return request({
    url:urlPrefix + '/price/entrustPriceList',
    method: 'post',
    data: obj
  })
}
