<template>
  <div v-loading="loading" class="stepContent">
    <div class="matchBox">
      <div class="matchTitle">
      </div>
      <div class="infoForm">
      </div>
      <div class="matchList">
        <div class="showAll">
          <el-checkbox v-model="checked">显示全部表头<span style="color: red">（选择推荐字段后可匹配到系统字段）</span></el-checkbox>
        </div>
        <div class="matchTable">
          <el-table
            :data='checked?tableData:filteredTableData'
            style="width: 100%">
            <!-- TODO 调整样式-->
            <el-table-column
              type="index"
              label="序号"
            >
            </el-table-column>
            <el-table-column
              prop="excel"
              label="Excel表头字段"
            >
            </el-table-column>
            <el-table-column
              prop="recommend"
              label="推荐字段"
              v-if="paramStr.importType == 'single'"
              :class="red-recommend"
            >
              <template slot-scope="scope">
                <el-select clearable @change="recommendChange" v-if="checked"
                           :placeholder="tableData[scope.$index].placeholder"
                           v-model="tableData[scope.$index].recommend" >
                  <el-option
                    v-for="item in recommendDate[tableData[scope.$index].excel]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <el-select clearable @change="recommendChange" v-else
                           :placeholder="filteredTableData[scope.$index].placeholder"
                           v-model="filteredTableData[scope.$index].recommend" >
                  <el-option
                    v-for="item in recommendDate[filteredTableData[scope.$index].excel]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="系统字段"
            >
              <template slot-scope="scope">
                <el-select clearable @change="changeData" v-if="checked" v-model="tableData[scope.$index].sys" filterable
                           :class="{nodata:checked?!tableData[scope.$index].sys:!filteredTableData[scope.$index].sys}" placeholder="未匹配">
                  <el-option-group
                    v-for="group in options"
                    :key="group.value"
                    :label="group.label">
                    <el-option
                      v-for="item in group.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-option-group>
                </el-select>
                <el-select clearable @change="changeData" v-else v-model="filteredTableData[scope.$index].sys" filterable
                           :class="{nodata:checked?!tableData[scope.$index].sys:!filteredTableData[scope.$index].sys}" placeholder="未匹配">
                  <el-option-group
                    v-for="group in options"
                    :key="group.label"
                    :label="group.label">
                    <el-option
                      v-for="item in group.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-option-group>
                </el-select>
              </template>
            </el-table-column>


          </el-table>
        </div>
      </div>
    </div>
    <div class="uploadTip">
      <div class="uploadTip-title">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-tishi"></use>
        </svg>
        温馨提示
      </div>
      <div class="uploadTip-lists">
        <div class="uploadTip-list">
          1、当Excel导入的信息项目与系统薪资项名称完全一致时，系统自动匹配
        </div>
        <div class="uploadTip-list">
          2、未匹配的薪资项下的数据将不予导入
        </div>
        <div class="uploadTip-list">
          3、系统自动记录对应关系，如导入模板未发生变化，无需重复匹配
        </div>
      </div>
    </div>
    <div v-if="next" class="next-btn">
      <el-button type="primary" @click="goback" size="mini">上一步</el-button>
      <el-button type="primary" @click="nextStep" size="mini" :loading="loading" >下一步</el-button>
    </div>
  </div>
</template>
<script>
  import {addMatchRecord, importStepTwo} from '@/api/entrust/detailImport';

  export default {
    data() {
      return {
        sysIndexObj:{},
        loading: false,
        checked: false,
        next: true,
        filteredTableData: [], //未匹配
        recommendDate:[],//推荐项
        tableData:[],
        options:[],
        paramStr:{
          filePath:'',
          bizType:'',
          excelData:[],
          wageItemList:[],
          billMonth:'',
          importType:'',
          cusName:'',
          cusId:'',
          isSubcontract: '',
        }
      };
    },
    created(){
      console.log("step2业务类型："+this.paramStr.bizType+"===="+ this.$store.state.impData.bizType);
      this.paramStr.importType= this.$store.state.impData.importType;
      this.tableData=this.$store.state.impData.excelList;
      this.recommendDate = this.$store.state.impData.recommendMap == undefined ? {} : this.$store.state.impData.recommendMap;
      this.tableData.forEach(element => {
        let val = this.recommendDate[element.excel] === undefined ? "" : this.recommendDate[element.excel][0].value
        this.$set(element,"recommend","");
        this.$set(element,"placeholder",val);
      });
      this.options= [{label:"",options:this.$store.state.impData.sysList}];
      this.options[0].options.forEach(element => {
        this.sysIndexObj[element.label] = element.value;
      });
      this.filteredTableData = this.tableData.filter(function(data,index) {
        if (!data.sys) {
          return data;
        }
      });
    },
    mounted() {

    },
    watch: {
      checked:function(val){
        // 不选中时更新未匹配列表
        if(!val){
          this.filteredTableData = this.filteredTableData.filter(function(data,index) {
            if (!data.sys) {
              data.inx = index
              return data;
            }
          });
        }
      }
    },
    methods: {
       nextStep() {
        this.loading=true;
        //传excel表头，sys表头
        this.paramStr.bizType = this.$store.state.impData.bizType;
        this.paramStr.filePath = this.$store.state.impData.filePath;
        this.paramStr.excelData = this.tableData;
        this.paramStr.wageItemList = this.$store.state.impData.wageItemList;
        this.paramStr.billMonth = this.$store.state.impData.billMonth;
        this.paramStr.cusName=this.$store.state.impData.cusName;
        this.paramStr.cusId= this.$store.state.impData.cusId;
        this.paramStr.isSubcontract= this.$store.state.impData.isSubcontract;
        //处理添加记录数据
        console.log(this.paramStr.importType);
        if (this.paramStr.importType == 'single') { // 只是单客户时处理数据
          this.getAddData();
          // 调用接口添加本次匹配记录
          addMatchRecord(this.$store.state.impData.addMatchData).then((result) => {
            console.log("add-match-record接口返回结果=======>",result);
            if (result.data.success !== false) {
                // 保存成功进行下一步
                this.goImportStepTwo();
            }else {
                this.loading=false;
                this.$message.error(res.data.data.msg);
            }

            // return;
          }).catch((err) => {
            console.log("add-match-record接口调用失败=======>",err);
          });
        }else {
           this.goImportStepTwo();
        }
      },
      goback() {
        this.$store.state.impData.filePath="";
        this.$emit("goback"); // 返回
      },
      goImportStepTwo() {
        importStepTwo(this.paramStr).then(res => {
        //  console.log("importStepTwo{}" ,res);
          if (res.data.data !=  null && res.data.data.success == "true") {
            this.$store.state.impData.importTime = res.data.data.importTime;
            this.$store.state.impData.batchImportId = res.data.data.batchImportId;
            this.$store.state.impData.isSubcontract = res.data.data.isSubcontract;
            this.$store.state.impData.novalidatePass = res.data.data.novalidatePass;
            this.$store.state.impData.tipInfo = res.data.data.tipInfo;
            this.$store.state.impData.warnInfo = res.data.data.warnInfo;
            this.$store.state.impData.validatePass = res.data.data.validatePass;
            this.$store.state.impData.errorInfo = res.data.data.errorInfo;
            this.$store.state.impData.resultInfo = res.data.data.resultInfo;
            this.$store.state.impData.isSuccess = res.data.data.isSuccess;
            console.log("step2:tipInfo" + res.data.data.tipInfo);
            this.loading = false;
            this.$emit("next"); // 跳转下一步
          } else {
            this.loading = false;
            if(res.data.data !=  null && res.data.data.msg != null ){
              this.$message.error({duration:10000,message:res.data.data.msg});
            }else{
              if(res.data.msg != null){
                this.$message.error({duration:10000,message:res.data.msg});
              }else{
                this.$message.error({duration:10000,message:'导入失败，请联系运维人员！'});
              }
            }
          }
        })
      },
      getAddData() { // 处理添加数据
        var addData = [];
        this.paramStr.excelData.forEach(element => {
          let sysColumnName = element.sys === ''?  "" : this.paramStr.wageItemList[element.sys].ITEM_NAME;
          // console.log('sysList======>',this.paramStr.wageItemList);
          let columnCode = element.sys === ''?  "" :  this.paramStr.wageItemList[element.sys].COLUMN_CODE
          addData.push({cusColumnName:element.excel,sysColumnName:sysColumnName,cusid: this.paramStr.cusId ,sysColumn :columnCode});
        })
        this.$store.state.impData.addMatchData = addData;
        console.log(this.$store.state.impData.addMatchData );
      },
      recommendChange(val){
        // 先判断checked的状态，区分不同的匹配项列表
        if(val === ""){
          return ;
        }
        // console.log("联动效果调试====>",this.tableData,val);
        try {
          if (this.checked) {
            this.tableData.forEach(element => {
              if(element.recommend  == val ){
                  //console.log(element);
                  element.sys = this.sysIndexObj[val];
                  // throw new Error("终止循环");
              }
            })
          }else {
            this.filteredTableData.forEach(element => {
                if(element.recommend  == val ){
                  //console.log(element);
                  element.sys = this.sysIndexObj[val];
                  //throw new Error("终止循环");
              }
            })
          }
        } catch (error) {

        }
      },
      changeData(val){
        if(val === ""){
          return ;
        }
        console.log( this.tableData);
        let filterData = this.tableData.filter(function (data ,index){
          return data.sys === val;
        })
        if(filterData .length > 1){
          let repeatExcel = [];
          filterData.forEach(element => { repeatExcel.push(element.excel)})
          this.$message.error("客户列 ： "+ repeatExcel.join("， ") + "不允许对应相同的系统列！请检查");
        }
      }
    }
  };
</script>
<style scoped rel="stylesheet/scss">
  .stepContent {
  }

  .next-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .matchTable {
    margin-bottom: 20px;
  }

  .nodata >>> input::-webkit-input-placeholder {
    color: red;
  }

 /* .nodata >>> input::-moz-input-placeholder {
    color: red;
  }*/

  .nodata >>> input::-ms-input-placeholder {
    color: red;
  }

  .uploadTip-lists {
    padding-left: 20px;
  }

  .uploadTip-list .uploadTip-button {
    color: #269cff;
    text-decoration: underline;
    cursor: pointer;
  }
</style>
