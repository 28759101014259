<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.detailVisible==='batchList' || this.detailVisible==='detailCus'" @click="goback">返回
      </el-button>
    </div>

    <el-form :inline="true" :model="listQuery" class="editForm">
      <el-form-item label="状态:" v-if="this.detailVisible=='false'">
        <el-select clearable class="status-select" v-model="listQuery.batchStatus"
                   @change="__getDistributionBatchList">
          <el-option label="未确认" value="00900"></el-option>
          <el-option label="已确认" value="00901"></el-option>
          <el-option label="已退回" value="00902"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提交时间:" style="margin-left:10px">
        <div class="block">
          <el-date-picker
            @change="__getDistributionBatchList"
            style="width: 80%"
            v-model="startAndEndDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="起始日期"
            end-placeholder="截止日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:ss:mm"
            clearable
            @click="parseStartAndEndDate"
          >
          </el-date-picker>
        </div>
      </el-form-item>

      <el-form-item class="noborder" label-width="300px" label="统筹账户余额：" style="float: right"
                    v-if="this.detailVisible=='false'">
        <el-input readonly='readonly' v-model="isMoney" placeholder="统筹账户余额"></el-input>
      </el-form-item>
    </el-form>

    <!--提交财务-->
    <el-dialog
      title="财务确认"
      :visible.sync="detailImportFinanceDialogVisible"
      width="55%"
      @close="$refs.importFinance._close()"
    >
      <detail-import-finance-tables ref="importFinance" @hiddenImp="hiddenImp"
                                    @_commitFinanceConfirm="_commitFinanceConfirm"
                                    @confirmFinance="confirmFinance"
                                    @backFinance="backFinance"
                                    :financeAmount="financeAmount" :edit="edit"
                                    :accountBalance="accountBalance"
                                    :isConfirmDisabled="isConfirmDisabled"
                                    :showDetail="showDetail"></detail-import-finance-tables>
    </el-dialog>
    <!--        @select-change="selectChange"-->
    <!--        @select-all="selectAll"-->

    <div>
      <!--财务批次列表-->
      <bsp-table
        class="distribution"
        v-if="this.detailVisible=='false'"
        :data.sync="list"
        :tree-config="true"
        :ciic-data-filter.sync="listQuery"
        @ciic-data-refresh="__getDistributionBatchList"
        show-header-overflow show-overflowwidth show-overflow
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        show-footer-overflow
        show-footer
        :footer-method="footerMethod"
        height="85%"
      >

        <vxe-table-column title="序号" type="index" width="60" align="center"></vxe-table-column>

        <bsp-table-column width="150px" v-if="this.currentTab=='31278300703' || this.currentTab=='31278300704' || this.currentTab=='31278300707'
              ||(this.userInfo.userType=='2' && this.currentTab=='31278300702') "
                          title="客户名称" field="cusName" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
        </bsp-table-column>

        <bsp-table-column width="150px" title="受托方" field="orgName" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.orgName}}</span></template>
        </bsp-table-column>

        <bsp-table-column width="140px" title="来款金额" field="amountConfirmation" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.amountConfirmation }}</span></template>
        </bsp-table-column>

        <bsp-table-column width="140px" title="应发金额合计" field="payableAmount" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.payableAmount }}</span></template>
        </bsp-table-column>

        <bsp-table-column width="140px" title="实发金额合计" field="paidInAmount" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.paidInAmount }}</span></template>
        </bsp-table-column>

        <bsp-table-column width="120px" title="税金总金额" field="taxes" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.taxes }}</span></template>
        </bsp-table-column>

        <bsp-table-column width="120px" title="社保合计" field="totalSocialSecurity" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.totalSocialSecurity }}</span></template>
        </bsp-table-column>

        <bsp-table-column width="140px" title="服务费合计" field="totalServiceCharge" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.totalServiceCharge }}</span></template>
        </bsp-table-column>

        <bsp-table-column width="140px" title="其他费用合计" field="totalOtherExpenses" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.totalOtherExpenses }}</span></template>
        </bsp-table-column>

        <bsp-table-column width="120px" title="提交人" field="submitterName" filter-type="input">
          <template slot-scope="scope"><span>{{scope.row.submitterName}}</span></template>
        </bsp-table-column>

        <bsp-table-column title="提交时间" field="submitTime" width="120px" filter-type="input"
        >
          <template slot-scope="scope"><span>{{scope.row.submitTime}}</span></template>
        </bsp-table-column>

        <bsp-table-column width="100px" title="状态" field="statusName" filter-type="input">
          <!-- filter-type="select"    filter-rule="in"   set-id="3004"-->
          <template slot-scope="scope"><span>{{scope.row.statusName}}</span></template>
          <!--statusName | dictFilter(dicts)-->
        </bsp-table-column>

        <bsp-table-column title="操作" fixed="right" align="center" width="120px">
          <template slot-scope="scope">
            <div class="ciic-table-operate">
              <el-button v-if="scope.row.status === '3004000025'" type="text" @click="confirmDetail(scope.row)">确认
              </el-button>
              <el-button v-else type="text" @click="showConfirmDetail(scope.row)">详情</el-button>
              <el-button type="text" @click="showDetails(scope.row)">查看</el-button>
              <!--              <el-button type="text" @click="exportDetails(scope.row)" >导出</el-button>-->
            </div>
          </template>
        </bsp-table-column>
      </bsp-table>
    </div>

    <!--批次列表-->
    <bsp-table v-if="this.detailVisible=='batchList'"
               :data.sync="list"
               :tree-config="true"
               :ciic-data-filter.sync="batchlistQuery"
               @ciic-data-refresh="__queryFinancialRelBatchPageQuery"
               @select-change="selectChange"
               @select-all="selectAll"
               show-header-overflow show-overflowwidth show-overflow
               v-loading="loading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               show-footer-overflow
               show-footer
               :footer-method="footerMethod"
               height="89%"
    >

      <vxe-table-column type="selection" width="60" fixed="left"/>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <bsp-table-column title="导入批次编号" field="batchCode" filter-type="input" width="100" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.batchCode}}</span></template>
      </bsp-table-column>


      <bsp-table-column v-if="this.currentTab=='31278300703' || this.currentTab=='31278300704' || this.currentTab=='31278300707'
              ||(this.userInfo.userType=='2' && this.currentTab=='31278300702') "
                        title="客户名称" field="cusName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="受托方" field="orgName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.orgName}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="计税区域" v-if="this.currentTab!='31278300707'" field="taxArea" width="100"
                        filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.taxArea}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="税金所属月份" v-if="this.currentTab!='31278300707'" field="taxMonth" width="120"
                        filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.taxMonth}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="实际操作人" field="operatorName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.operatorName}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="创建时间" field="operateTime" width="120" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.operateTime}}</span></template>
      </bsp-table-column>


      <bsp-table-column :title="textMap[taxTotalStatus]" field="taxTotal" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.taxTotal }}</span></template>
      </bsp-table-column>

      <bsp-table-column title="服务总金额" field="serviceTotal" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.serviceTotal }}</span></template>
      </bsp-table-column>

      <bsp-table-column title="状态" field="statusName" fixed="left" filter-type="input">
        <!-- filter-type="select"    filter-rule="in"   set-id="3004"-->
        <template slot-scope="scope"><span>{{scope.row.statusName }}</span></template>
        <!--statusName | dictFilter(dicts)-->
      </bsp-table-column>

      <bsp-table-column title="操作" fixed="right" align="center">
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button type="text" @click="showCusDetails(scope.row)">查看</el-button>
            <!--            <el-button type="text"     @click="exportDetails(scope.row)" >导出</el-button>-->
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>

    <!--明细列表-->
    <detailImportList v-if="this.detailVisible==='detailCus'"
                      :tableColumn.sync="this.tableColumn"
                      :dataList.sync="this.dataList"
                      :sumItem.sync="this.sumItem"
                      :noChangeList.sync="this.noChangeList"
                      refname="detailImportList"
                      @getSelectData="getSelectData"
                      v-loading="loading"
                      ref="detailImportList"
                      height="89%"
    >
    </detailImportList>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import {moneyFilter, moneyFilterStr, toThousandslsFilter} from '@/filters/index';
  import DetailImportFinanceTables from "@/views/entrust/detailImport/detailImportFinanceTables";
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {
    financialConfirmation,
    financialSendBack,
    getDistributionBatchList,
    queryFinancialRelBatchPageQuery,
    rollbackFinancialBatch,
    searchAccountBalanceByOrgId
  } from '@/api/entrust/distributionBatch';
  import detailImportList from "@/views/entrust/detailImport/detailImportList";
  import {
    commitBatch,
    getBillsInfo,
    getDetailImportBatchList,
    getdynamicList,
    getdynamicTable
  } from '@/api/entrust/detailImport';
  import {getInputValue} from '@/util/util';

  export default {
    name: "distributionBatchList",
    inject: ['reload'],
    components: {
      BspTable,
      BspTableColumn,
      DetailImportFinanceTables,
      detailImportList
    },
    computed: {
      ...mapGetters([
        'dicts', 'userInfo'
      ]),
      priceFormat() {
        let result = this.accountBalance.balance;
        return result;
      },
      isMoney() {
        const val = this.accountBalance.balance;
        if (typeof val === "number") {
          return val.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        } else {
          return this.accountBalance.balance;
        }
      },
    },
    props: {
      currentTab: {
        type: String,
        default: ''
      },
      edit: false,
      showDetail: false,
    },
    created() {
      //this.initDate()

      console.log("用户类型：" + this.userInfo.userType + "currentTab**********" + this.currentTab);
      this.accountBalance.balance = 0;
      this.searchAccountBalance();
      //列表显示项描述不一样
      if (this.currentTab == '31278300701' || this.currentTab == '31278300702') {
        this.taxTotalStatus = "dkTaxTotal";
      } else if (this.currentTab == '31278300707') {
        console.log("代报销");
        this.taxTotalStatus = "dbxTaxTotal";
      } else {
        this.taxTotalStatus = "pdkTaxTotal";
      }
      this.__getDistributionBatchList();
    },
    data() {
      return {
        detailImportFinanceDialogVisible: false,
        isConfirmDisabled: false,
        billDialogVisible: false,
        // 遮罩
        loading: false,
        // 用于接收后台返回的分页数据
        list: [],
        detailVisible: 'false',
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          bizType: '',
          batchStatus: '00900',//默认为未提交
          source: '0',//批次来源，区分是从补贴那里生成的还是自己导入生成的
        },
        parameter: {
          batchImportIds: '',
          bizType: this.currentTab,
          cusId: '',
          orgId: '',
          billsTitle: '',//BILLSTITLENAME
          taxNum: '',//F204204
          taxAdress: '',//F204205
          taxPhone: '',//F204206
          recipients: '',//RECEIVEDEPT
          emailAddress: '',//postcode
          subIds: '',//明细选择导出
          subIdFlag: '',//明细导出标识
          isSure: 'false',//是否确认款项已到账号
          status: '',//状态（提交、未提交）
          batchMap: '',//批次关联状态
          commitList: []
        },
        //多选数组
        multipleSelection: [],
        textMap: {
          dkTaxTotal: '税金总金额',
          pdkTaxTotal: '工资往来总金额',
          dbxTaxTotal: '代报销总金额',
          titleInfo: '选择发票信息',
        },
        taxTotalStatus: 'dkTaxTotal',
        detailInfo: {
          batchImportId: '',//批次id
          bizType: '',//业务类型
          status: '',//提交状态，未提交/已提交
          cusId: '',
        },
        dataList: [],
        tableColumn: [], //显示表头
        sumItem: [],//需要合计的列
        detailImportCusList: [],
        cuslistQuery: {
          limit: 50,
          page: 1,
          total: 0,
          bizType: '',
          batchStatus: '00900',//默认为未提交
          batchImportId: '',
        },
        batchlistQuery: {
          limit: 50,
          page: 1,
          total: 0,
          financeId: '',
        },
        financeAmount: {
          payableAmount: '',//应发金额
          paidInAmount: '',//实发金额
          taxes: '',//税金
          totalSocialSecurity: 0,//社保合计
          totalServiceCharge: 0,//服务费合计
          totalOtherExpenses: 0,//其他费用合计
          amountConfirmation: 0,//确认来款
          financeId: '',//财务确认批次ID
          submitterName: '',
          remark: '',
          commitUser: '',//提交人
          commitTime: '',//提交时间
          coAccountBalance: '0',//统筹账户余额
          coAccount: '',//统筹账户
        },
        multipleDetailSelection: [],
        noChangeList: [],//明细中不编辑的列
        accountBalance: {
          account: '',
          accountName: '',
          balance: {
            type: Number,
            default: 0
          }
        },
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        startAndEndDate: '',
      }
    },
    methods: {

      //查询余额
      searchAccountBalance() {
        this.parameter.orgId = this.userInfo.orgId;
        searchAccountBalanceByOrgId(this.parameter).then(res => {
          if (res.data.data.success === 'true') {
            this.accountBalance.balance = (res.data.data.data.BALANCE === null || res.data.data.data.BALANCE === undefined) ? 0 : res.data.data.data.BALANCE;
            this.accountBalance.account = res.data.data.data.ACCOUNT;
            this.accountBalance.accountName = res.data.data.data.NAME;
          }
        });
      },

      //查询财务审核列表批次信息（）
      __getDistributionBatchList: function () {
        this.parseStartAndEndDate();
        if (this.listQuery.batchStatus.length <= 0) {
          this.$message.error("请至少选择一种状态！");
          this.list = null;
          return;
        }
        console.log("查询财务审核列表信息");
        this.listQuery.bizType = this.currentTab;
        this.loading = true;
        getDistributionBatchList(this.listQuery).then(res => {

          if (this.listQuery.startDate !== null && this.listQuery.endDate !== null && this.listQuery.startDate === this.listQuery.endDate) {
            this.listQuery.endDate = `${this.listQuery.endDate.replace('00:00:00', '23:59:59')}`;
          }
          if (res.data.code != 0) {
            this.listQuery.total = res.data.total
            this.list = res.data.records
            if (this.list.length > 0) {
              this.financeAmount.coAccountBalance = this.list[0].balance;
            }
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      __queryFinancialRelBatchPageQuery() {
        queryFinancialRelBatchPageQuery(this.batchlistQuery).then(res => {
          if (res.data.code != 0) {
            this.batchlistQuery.total = res.data.total;
            this.list = res.data.records;
            this.detailVisible = 'batchList';
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      //查看详情(财务审核首页，点击查看)
      showDetails(row) {
        console.log("财务批次id：" + row.financeId + ";业务类型：" + row.bizType);
        this.batchlistQuery.financeId = row.financeId;
        this.__queryFinancialRelBatchPageQuery(row);
      },
      //查看批次详情
      showCusDetails(row) {
        this.detailVisible = 'detailCus';
        this.detailInfo.batchImportId = row.batchId;
        this.detailInfo.bizType = row.bizType;
        this.detailInfo.status = row.status;
        this.detailInfo.cusId = row.cusId;
        this._getdynamicTable();
        this._getdynamicList();
      },
      //动态表头
      _getdynamicTable: function () {
        console.log("查看对应的批次id：" + this.detailInfo.batchImportId);
        getdynamicTable(this.detailInfo).then(res => {
          if (res.data.data.success == "true") {
            this.tableColumn = res.data.data.tableColumn;
            this.sumItem = res.data.data.sumItem;
            this.noChangeList = res.data.data.noChangeList;
            // 处理数据 财务人员不能编辑数据
            console.log("==========================");
          }
        })
      },
      //动态数据
      _getdynamicList() {
        getdynamicList(this.detailInfo).then(res => {
          this.listQuery.total = res.data.total
          this.dataList = res.data.records
        })
      },
      //确认，弹出表单
      confirmDetail(row) {
        this.financeAmount.payableAmount = row.payableAmount;//应发金额
        this.financeAmount.paidInAmount = row.paidInAmount;//实发金额
        this.financeAmount.taxes = row.taxes;//税金
        this.financeAmount.totalSocialSecurity = row.totalSocialSecurity;//社保合计
        this.financeAmount.totalServiceCharge = row.totalServiceCharge;//服务费合计
        this.financeAmount.totalOtherExpenses = row.totalOtherExpenses;//其他费用合计
        this.financeAmount.amountConfirmation = row.amountConfirmation;//确认来款
        this.financeAmount.financeId = row.financeId;
        this.financeAmount.commitUser = row.submitterName;
        this.financeAmount.commitTime = row.submitTime;
        this.financeAmount.remarkHis = row.remark;
        this.edit = false;
        this.showDetail = false;
        this.detailImportFinanceDialogVisible = true;
        this.isConfirmDisabled = false;
      },

      //查看详情
      showConfirmDetail(row) {
        this.financeAmount.payableAmount = row.payableAmount;//应发金额
        this.financeAmount.paidInAmount = row.paidInAmount;//实发金额
        this.financeAmount.taxes = row.taxes;//税金
        this.financeAmount.totalSocialSecurity = row.totalSocialSecurity;//社保合计
        this.financeAmount.totalServiceCharge = row.totalServiceCharge;//服务费合计
        this.financeAmount.totalOtherExpenses = row.totalOtherExpenses;//其他费用合计
        this.financeAmount.amountConfirmation = row.amountConfirmation;//确认来款
        this.financeAmount.financeId = row.financeId;
        this.financeAmount.commitUser = row.submitterName;
        this.financeAmount.commitTime = row.submitTime;
        this.financeAmount.remarkHis = row.remark;
        this.edit = false;
        this.showDetail = true;
        this.detailImportFinanceDialogVisible = true;
      },

      hiddenImp() {
        this.detailImportFinanceDialogVisible = false;
      },
      //财务确认
      async confirmFinance(financeAmount) {
        this.$confirm('点击确定后，将根据项目委托书中的协议发薪日发薪', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true;
          this.detailImportFinanceDialogVisible = false;
          this.isConfirmDisabled = true;
          console.log('财务id: ' + financeAmount.financeId);
          financialConfirmation(financeAmount.financeId).then(res => {
            if (res.data.data.success === 'true') {
              if (res.data.data.msg !== '') {
                this.$message.error(res.data.data.msg);
                this.loading = false;
                return false;
              }
              this.batchlistQuery.financeId = financeAmount.financeId;
              //获取财务批次关联的薪资批次，然后自动提交
              queryFinancialRelBatchPageQuery(this.batchlistQuery).then(async resBatch => {
                this.parameter.batchImportIds = '';
                let flag = '';
                let jsonStr = "[";
                resBatch.data.records.forEach(item => {
                  this.parameter.batchImportIds += ',' + item.batchId;
                  this.parameter.orgId = item.orgId;
                  if (item.stOrgid !== '1060344') {
                    flag = 'true';
                  }
                  jsonStr += '{"' + item.batchId + '":"' + item.status + '"},';
                })
                jsonStr = jsonStr.substring(0, jsonStr.length - 1) + "]";
                this.parameter.batchMap = eval("" + jsonStr + "");
                this.parameter.commitList = res.data.records
                console.log("commitBatch前");
                let commitRes = await this.commitBatch(flag);
                console.log('财务确认2');
                debugger;
                if (commitRes !== null && 'true' === commitRes) {
                  console.log("commitBatch后");
                  this.detailImportFinanceDialogVisible = false;
                  // this.$message.success("确认成功，随后会优化（不要提示）!");
                  console.log("财务确认成功后的detailVisible：" + this.detailVisible)
                  this.detailVisible = 'false';
                  this.__getDistributionBatchList();
                  this.loading = false;
                } else {
                  console.log("commitBatch后");
                  this.detailImportFinanceDialogVisible = false;
                  rollbackFinancialBatch(financeAmount.financeId).then(res => {
                    // this.$message.error("提交薪税失败了，状态已经重置。随后会优化（不要提示）!");
                    this.detailVisible = 'false';
                    this.__getDistributionBatchList();
                    this.loading = false;
                  });
                }
              });
            } else {
              this.loading = false;
              this.$message.error(res.data.data.msg);
            }
          }).catch({});
        }).catch(() => {
          this.isConfirmDisabled = false;
          this.$message.info('已取消');
        });
        this.isConfirmDisabled = false;
      },
      //财务退回
      backFinance(financeAmount) {
        if (this.financeAmount.remark === '' || this.financeAmount.remark === null) {
          this.$message.error('请填写退回原因');
          return;
        }

        financialSendBack(financeAmount.financeId, financeAmount.remark, financeAmount.submitterName).then(res => {
          if (res.data.data.success === 'true') {
            this.detailImportFinanceDialogVisible = false;
            this.$message.success("退回成功");
            this.__getDistributionBatchList();
          } else {
            this.$message.error(res.data.data.msg);
          }
        }).catch({});
      },
      //返回
      goback() {
        console.log("业务类型：" + this.cuslistQuery.bizType + "，状态：" + this.listQuery.batchStatus);
        this.cuslistQuery.bizType = this.currentTab;
        if (this.detailVisible === 'detailCus') {
          this.detailVisible = 'batchList';
          this.cuslistQuery.filters = [];
        } else {
          this.listQuery.bizType = this.currentTab;
          this.detailVisible = 'false';
          this.listQuery.filters = [];
          this.__getDistributionBatchList();
        }
      },

      //自动提交
      async commitBatch(flag) {
        console.log('自动提交');
        debugger;
        this.loading = true;
        this.parameter.bizType = this.currentTab;
        if (flag === 'false') {
          return 'false';
        }
        if (flag === 'true' && this.parameter.bizType !== '31278300701' && this.parameter.bizType !== '31278300702') {
          this.loading = false;
          //查询发票抬头信息 收件人信息
          getBillsInfo(this.parameter).then(res => {
            if (res.data.data.success === "true") {
              this.billsInfoList = res.data.data.billsInfoList;//发票信息
              this.receAddrList = res.data.data.receAddrList;//收件人信息
            }
          });
          this.billDialogVisible = true;
        } else {
          let rst = await this.commonCommit();
          console.log(rst);
          return rst;
        }
      },

      //公共提交方法
      async commonCommit() {
        try {
          let res = await commitBatch(this.parameter);
          console.log('公共提交方法');
          if (res.data.data.msg === "") {
            this.loading = false;
            this.$message.success("提交成功！");
            console.log("公共提交成功后的detailVisible：" + this.detailVisible)
            this.parameter.batchImportIds = '';
            return 'true';
          } else if (res.data.data.msg === "0000") {
            let rst = await this.commitBatchTransfer();
            this.loading = false;
            return rst;
          } else {
            debugger;
            this.loading = false;
            this.$message.error(res.data.data.msg);
            this.parameter.batchImportIds = '';
            this.__getDistributionBatchList();
            return 'false';
          }
        } catch (e) {
          this.$message.info('提交失败');
          return 'false';
        }
      },

      //分支有统筹账号时，需要再确认一下才进行提交
      async commitBatchTransfer() {
        console.log('分支有统筹账号时，需要再确认一下才进行提交');
        //增加一个统筹账号的提示
        this.loading = true;
        this.parameter.isSure = 'true';
        let rst = 'false';
        let res = await commitBatch(this.parameter);
        console.log('提交薪税');
        debugger
        if (res.data.data.msg === "") {
          console.log("分支提交成功后的detailVisible：" + this.detailVisible)
          this.$message.success("提交成功！");
          rst = 'true';
        } else {
          this.$message.error(res.data.data.msg);
        }
        this.loading = false;
        this.parameter.isSure = 'false';
        this.parameter.batchImportIds = '';
        this.__getDistributionBatchList();
        return rst;
      },
      // 底部合计
      footerMethod({columns, data}) {
        return [
          columns.map((column, columnIndex) => {
            if (columnIndex === 0) {
              return '合计'
            }
            if (['amountConfirmation', 'payableAmount', 'paidInAmount', 'taxes', 'totalSocialSecurity', 'totalServiceCharge', 'totalOtherExpenses', 'serviceTotal', "taxTotal"].includes(column.property)) {
              return this.$options.filters['moneyFilter'](this.$utils.sum(data, column.property))
            }
            return null
          })
        ]
      },
      parseStartAndEndDate() {
        let date1 = this.startAndEndDate;
        this.listQuery.startDate = null;
        this.listQuery.endDate = null;
        if (date1 !== null && date1.length > 0) {
          if (date1[0] !== undefined) {
            this.listQuery.startDate = date1[0];
          }
          if (date1[1] !== undefined) {
            this.listQuery.endDate = date1[1];
          }
        }
      },
      // 初始化时间选择框
      initDate() {
        let now = new Date();
        let year = now.getFullYear(); //得到年份
        let month = now.getMonth(); //得到月份
        let date = now.getDate(); //得到日期
        month = month + 1;
        month = month.toString().padStart(2, "0");
        date = date.toString().padStart(2, "0");
        let defaultDate = `${year}-${month}-${date}`;
        this.startAndEndDate = [defaultDate + ' 00:00:00', defaultDate + ' 23:59:59'];
      },
    }
  }
</script>

<style scoped>
  .ciic-table-operate {
    align-items: center;
  }

  .editForm {
    margin: 0;
  }

  .editForm .el-form-item {
    margin: 0;
  }

  .noborder >>> .el-input .el-input__inner {
    border: none !important;
  }
</style>
