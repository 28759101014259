<template>
    <div class="tabson3">
        我是tab3
    </div>
</template>
<script>
export default {
    name:'tabson3',
     data() {
        return {}
      },
        props:{
          currentTab:{
              type:String,
              default:''
          }
      },
      created(){
      },
}

</script>
