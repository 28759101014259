<template>
    <div class="sequenceFrom">
      <div class="customer-info">
        <div class="customer-info-item">
          <div class="customer-info-title">
            <!--图标-->
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-qiyexinxi"></use>
            </svg>
            <span>基础设置</span>
          </div>
          <div class="customer-info-button">
            <!--展开-->
            <svg v-if="show" class="icon" aria-hidden="true" @click="changeShow">
              <use xlink:href="#icon-zhankaizhuangtai"></use>
            </svg>
            <!--收起-->
            <svg v-else class="icon" aria-hidden="true" @click="changeShow">
              <use xlink:href="#icon-shouqizhuangtai"></use>
            </svg>
          </div>
        </div>
        <div  class="editForm" v-show="show">
          <el-row :span="20" :offset="2">
            <el-form :ref="form" :model="wagesFrom" label-width="168px" :rules="rules">
              <el-col :span="12">
                <el-form-item label="工资单名称" prop="name">
                  <el-input v-model="wagesFrom.name" placeholder="请输入内容"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="空值是否显示" prop="empty">
                  <el-radio-group v-model="wagesFrom.empty">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="2">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="发送方式" prop="sendway" >
                  <el-select v-model="wagesFrom.sendway" placeholder="选择发送方式">
                    <el-option label="发送方式一" value="way1"></el-option>
                    <el-option label="发送方式二" value="way2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="加密方式" prop="encryption" >
                  <el-select v-model="wagesFrom.encryption" placeholder="选择加密方式">
                    <el-option label="加密方式一" value="way1"></el-option>
                    <el-option label="加密方式二" value="way2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否显示双语" prop="lang">
                  <el-radio-group v-model="wagesFrom.lang">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="2">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="发送标题" prop="title" >
                  <el-input v-model="wagesFrom.title" placeholder="工资单"></el-input>
                  <el-tooltip class="item" effect="dark" content="请替换<>内的模板内容" placement="top">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-tishi"></use>
                    </svg>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="发送抬头" prop="head" >
                  <el-input v-model="wagesFrom.head" placeholder="先生"></el-input>
                  <el-tooltip class="item" effect="dark" content="请替换<>内的模板内容" placement="top">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-tishi"></use>
                    </svg>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注" prop="mask" >
                  <el-input v-model="wagesFrom.mask" ></el-input>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: "paySequence",
    props:{
      process:{
        type:Number,
        default:0 //从0开始
      },
      wagesFrom:{
        type:Object,
        default:function(){
          return {
            name:"",
            empty:1,
            sendway:"",
            encryption:"",
            lang:1,
            title:"<工资所属月份>工资单 ",
            head:"<姓名>先生/女士：<换行>您好，以下是您<工资月份>工资单",
            mask:"",
          }
        }
      }
    },
    data(){
      return{
        show:true,
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
          ],
          empty: [
            { required: true, message: '空值是否显示', trigger: 'change' }
          ],
          sendway: [
            { required: true, message: '请选择发送方式', trigger: 'change' }
          ],
          encryption: [
            { required: true, message: '加密方式', trigger: 'change' }
          ],
          lang: [
            { required: true, message: '请选择是否显示双语', trigger: 'change' }
          ],
        }
      }
    },
    methods:{
      changeShow(){
        this.show ?this.show = false:this.show = true
      },
      submitForm() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            // 修改标题
            this.$emit("changeName",this.wagesFrom.name)
            return true;
          } else {
            this.$message.error("请正确填写表单！")
            return false;
          }
        });
      },
    }
  };
</script>

<style scoped>
  .editForm{
    width: calc(100% - 100px);
  }
  .sequences,.sequenceBox{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sequenceBox{
    width: 160px;
  }
  .sequence{
    width: 120px;
    height: 40px;
    color:#ffffff;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    background: #7ea7ca;
    border-radius: 4px;
    margin-right: 10px;
    /*cursor: pointer;*/
  }
  .sequenceArrow{
    color:#bbbbbb;
    font-size: 20px;
  }
  .sequenceBox.active .sequence{
    background: #008AFF;
    font-size: 16px;
  }
  .sequenceBox.pass .sequence{
    background: #008AFF;
  }
  .sequences .el-form >>> .el-form-item  .el-radio-group{
    margin-top: 0px;
    line-height: 30px;
  }
</style>
