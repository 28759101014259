<template>
  <el-form
    ref="listData"
    :model="listData"
    :rules="listRules"
    label-width="120px"
  >

    <div class="editForm">
      <el-row>
        <el-col :span="12">
          <el-form-item label="客户名称" prop="cusName">
            <el-input v-model.trim="listData.cusName" disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="客户国别" prop="country">
             <bsp-dicts-select v-model="listData.country" dic-num="2127"  :select-value.sync="listData.country"></bsp-dicts-select>
            <!-- <el-select clearable v-model="listData.country" placeholder="请选择">
              <el-option
                v-for="item in rolesList"
                :key="item.id"
                :value="item.id"
                :label="item.roleName"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="行业分类" prop="industry">
             <bsp-dicts-cascader
                v-model="listData.industry"
                :cascader-value.sync="listData.industry"
                @change="changeIndustry"
                dic-num="3039"/>
            <!-- <el-select clearable v-model="listData.industryBig" placeholder="请选择">
              <el-option
                v-for="item in rolesList"
                :key="item.id"
                :value="item.id"
                :label="item.roleName"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="行业小类" prop="industrySmall">
            <el-select clearable v-model="listData.industrySmall" placeholder="请选择">
              <el-option
                v-for="item in rolesList"
                :key="item.id"
                :value="item.id"
                :label="item.roleName"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item label="客户性质" prop="nature">
            <bsp-dicts-select v-model="listData.nature" dic-num="3045" :select-value.sync="listData.nature"  ></bsp-dicts-select>
            <!-- <el-select clearable v-model="listData.nature" placeholder="请选择">
              <el-option
                v-for="item in rolesList"
                :key="item.id"
                :value="item.id"
                :label="item.roleName"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="客户状态" prop="state">
            <bsp-dicts-select v-model="listData.state" dic-num="3063" disabled="true" :select-value.sync="listData.state" ></bsp-dicts-select>
            <!-- <el-select clearable v-model="listData.state" disabled="true" placeholder="请选择">
              <el-option
                v-for="item in rolesList"
                :key="item.id"
                :value="item.id"
                :label="item.roleName"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="客户办公地址" prop="officeAddr">
            <el-input v-model.trim="listData.officeAddr"/>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="客户注册地址" prop="registerAddr">
            <el-input v-model.trim="listData.registerAddr"/>
            <!-- <el-select clearable v-model="listData.registerAddr" placeholder="请选择">
              <el-option
                v-for="item in rolesList"
                :key="item.id"
                :value="item.id"
                :label="item.roleName"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="接口人" prop="contactPerson">
            <el-select clearable v-model="listData.contactPerson" filterable placeholder="请选择">
              <el-option
                v-for="item in intferfecPerList"
                :key="item.ciicPerId"
                :value="item.ciicPerId"
                :label="item.ciicPerName"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="operator">
          <el-form-item label="操作人" prop="operator">
            <el-select v-model="listData.operator" filterable multiple  placeholder="请选择" >
              <el-option
                v-for="item in poeratorList"
                :key="item.ciicPerId"
                :value="item.ciicPerId"
                :label="item.ciicPerName"
              >
              </el-option>
            </el-select>
            <p class="qingkong" @click="qingkongOperator">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-qingkong"></use>
              </svg>
            </p>
          </el-form-item>
        </el-col> -->
      </el-row>
    </div>
  </el-form>
</template>

<script>
  import { isvalidatemobile, validateEmail } from '@/util/validate';
  import bspDictsSelect from "@/components/bsp/bsp-dicts-select/bsp-dicts-select";
  import bspDictsCascader from "@/components/bsp/bsp-dicts-cascader/bsp-dicts-cascader";
  import {queryInterfecPersonList} from "@/api/person/person";
  import { mapGetters } from "vuex";
  // 手机号码校验
  let validatePhone = (rule, value, callback) => {
    var phoneVal = rule.model
    // 值不能空，为空不校验
    if(phoneVal){
      if (isvalidatemobile(phoneVal)[0]) {
        callback(new Error(isvalidatemobile(phoneVal)[1]));
      } else {
        callback();
      }
    }else {
      callback();
    }
  };
  // 邮箱
  let validateEmails = (rule,value,callback)=>{
    var emailVal = rule.model;
    if(emailVal){
      if (!validateEmail(emailVal)) {
        callback(new Error("请输入正确的邮箱地址！"));
      } else {
        callback();
      }
    }else {
      callback();
    }
  };
  export default {
    components: {
      bspDictsSelect,
      bspDictsCascader,
    },
    computed: {
      ...mapGetters(['userInfo']),

    },
    name: "turnCus",
    props:{
      listData:{
        type: Object,
        default: () =>{
          return {
            leadsId:"",
            cusName: "",
            country: "",
            industry: "",
            industryBig: "",
            industrySmall: "",
            nature: "",
            state: "",
            officeAddr: "",
            registerAddr: "",
            contactPerson: "",
            operator: "",
          }
        }
      },
    },
    data() {
      return {
        // 角色列表
        rolesList: [
          { id: "1", roleName: "HR" },
          { id: "2", roleName: "采购" },
          { id: "3", roleName: "决策者" },
          { id: "4", roleName: "其他" }
        ],
        poeratorList:[],
        intferfecPerList:[],
        // 归属人员
        ascriptionList: [ ],
        // 校验规则
        listRules: {
          cusName:[
            { required: true, message: "客户名称不能为空!", trigger: "blur" }
          ],
          country:[
            { required: true, message: "客户国别不能为空!", trigger: "blur" }
          ],
          industry:[
            { required: true, message: "行业分类不能为空!", trigger: "blur" }
          ],
          industryBig:[
            { required: true, message: "行业大类不能为空!", trigger: "blur" }
          ],
          industrySmall:[
            { required: true, message: "行业小类不能为空!", trigger: "blur" }
          ],
          nature:[
            { required: true, message: "客户性质不能为空!", trigger: "blur" }
          ],
          state:[
            { required: true, message: "客户状态不能为空!", trigger: "blur" }
          ],
          contactPerson:[
            { required: true, message: "接口人不能为空!", trigger: "blur" }
          ],
          operator:[
            { required: true, message: "操作人不能为空!", trigger: "blur" }
          ],
          officeAddr:[
             { max: 50, message: '最多输入 50 个字符', trigger: 'blur' },
          ],
          registerAddr:[
             {  max: 50, message: '最多输入 50 个字符', trigger: 'blur' },
          ],
        }
      };
    },
    created(){
      //this.poeratorList.push( {id: this.userInfo.userId, roleName: this.userInfo.name});

      console.log("设置默认值开始==========");
      this.getInterfecPerson();
      this.getOperatorPerson();

    },
    mounted(){
      console.log("设置默认值结束==========");
      this.listData.country= "2127001055";
      this.listData.state= "3040830147";
    },
    methods:{
      /**
       * 行业分类值改变后
       */
      changeIndustry(value){
        value.forEach((item,index)=>{
          if(index===0){
            this.listData.industryBig = item;
          }else {
            this.listData.industrySmall = item;
          }
        })
      },
      //查询接口人信息
      getInterfecPerson(){
        queryInterfecPersonList({type: '00901'}).then(res=>{
          console.log("接口人数据",res);
          this.intferfecPerList=res.data.data
        })
      },

       //查询操作人信息
      getOperatorPerson(){
        queryInterfecPersonList({type: '00900'}).then(res=>{
          console.log("操作人数据",res);
          this.poeratorList=res.data.data
          //设置默认值
          this.listData.operator=[],
          this.listData.operator.push(this.userInfo.userId);
        })
      },
      qingkongOperator(){
        debugger
        this.$set(this.listData,"operator",[])
      }


    }
  };
</script>

<style scoped>

</style>
