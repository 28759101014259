<template>
    <div class="tabson2">
        我是tab2
    </div>
</template>
<script>
export default {
    name:'tabson2',
     data() {
        return {}
      },
        props:{
          currentTab:{
              type:String,
              default:''
          }
      },
      created(){
      },
}

</script>
