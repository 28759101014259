import { render, staticRenderFns } from "./ciic-table-column.vue?vue&type=template&id=226eb699&scoped=true&"
import script from "./ciic-table-column.vue?vue&type=script&lang=js&"
export * from "./ciic-table-column.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "226eb699",
  null
  
)

export default component.exports