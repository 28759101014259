<template>
  <div class="batchBox">
    <!--<div class="backBox">-->
      <!--<span v-if="activeIndex === 1" class="goback" @click="goback"><span class="iconfont">&#xe602;</span>返回</span>-->
      <!--<span v-if="activeIndex === 2" class="goback" @click="goTopage"><span class="iconfont">&#xe602;</span>完成</span>-->
    <!--</div>-->
    <div class="batchSteps">
      <div :class="activeIndex >= 0? 'active':''" class="batchStep">
        <div class="stepIndex">1</div>
        <p>数据有效性校验</p><br>
      </div>
      <div class="stepSplit">
        <svg class="icon" aria-hidden="true" @click="handleLock">
          <use xlink:href="#icon-you"></use>
        </svg>
      </div>
      <div :class="activeIndex >= 1? 'active':''" class="batchStep">
        <div class="stepIndex">2</div>
        <p>查看结果</p><br>
      </div>
    </div>
    <div class="back">
      <el-button type="primary" size="small"  @click="backToList">返 回</el-button>
    </div>
    <step0 v-if="activeIndex === 0" @onupload="addstep" @goback="goback" @oneCheckResult="saveOneStepResult" :oneStepResult="oneStepResult"/>
    <step1 ref="step1Ref" v-if="activeIndex === 1" @onupload="addstep" @onuploadTwo="addstepTwo" @goback="goback" />
  </div>
</template>
<script>
import step0 from './step0'
import step1 from './step1'
/* import step4 from './step4'*/
export default {
  components: { step0, step1  },
  data() {
    return {
      activeIndex: 0,
      oneStepResult:null,
    }
  },
  methods: {
    // 下一步
    addstep() {
      this.activeIndex +=1
    },
    addstepTwo() {
      this.activeIndex +=2
    },
    goback2({val,bizType,billMonth,cusId}){
      console.log("goback2的业务类型",bizType);
      console.log("goback2的业务类型",billMonth);
      console.log("goback2的业务类型",cusId);
      if(bizType){
        this.bizType = bizType
      }
      this.activeIndex = this.activeIndex -val
    },
    handleLock(){

    },
    saveOneStepResult(result){
      this.oneStepResult = result;
      console.log("第一步保存结果",this.oneStepResult);
    },
    goback() {
      this.activeIndex = this.activeIndex - 1
    },
    gobackTwo() {
      this.activeIndex = this.activeIndex -2
    },
    backToList(){
        if(this.activeIndex  === 1 ){
          this.$refs.step1Ref.go_unCopyLock(null);
        }
        const query = {   //getDynamicForm参数
            currentTab:this.$route.query.bizType,//业务类型
        }
        this.$router.push({ path: '/wt/detailImportBatchTabs',query});
    },
    goTopage() {
      // 返回数据页面
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped  rel="stylesheet/scss">
  .backBox{
    /*margin-bottom: 20px;*/
  }
  .back{
    position: absolute;
    top:20px;
    right:20px;
    height: 140px;
  }
  .back>>>.el-button{
    height: 100%;
    background: rgba(51,133,255,0.56);
  }
  .back>>>.el-button span{
    writing-mode:vertical-rl;
  }
  .goback{
    width: 70px;
    padding:8px;
    border:1px solid #cccccc;
    color:#cccccc;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    line-height: 16px;
    font-size: 14px;
    cursor: pointer;
  }
  .goback:hover{
    border:1px solid #269cff;
    color:#269cff;
  }
  .batchBoxTitle{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
  }
</style>
