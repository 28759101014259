<template>
  <div class="videos" ref="elVideo" :style="videosHeight">
    <div class="videoBox">
      <div class="video">
        <video-player class="video-player vjs-custom-skin"
                      ref="videoPlayer1"
                      :playsinline="true"
                      :options="playerOptions1"
                      @play="onPlayerPlay($event,1)"
        ></video-player>
        <div class="detail">
          <p>系统界面v2</p>
        </div>
      </div>
    </div>
    <div class="videoBox">
      <div class="video">
        <video-player class="video-player vjs-custom-skin"
                      ref="videoPlayer2"
                      :playsinline="true"
                      :options="playerOptions2"
                      @play="onPlayerPlay($event,2)"
        ></video-player>
        <div class="detail">
          <p>协议管理</p>
        </div>
      </div>
    </div>
    <div class="videoBox">
      <div class="video">
        <video-player class="video-player vjs-custom-skin"
                      ref="videoPlayer3"
                      :playsinline="true"
                      :options="playerOptions3"
                      @play="onPlayerPlay($event,3)"
        ></video-player>
        <div class="detail">
          <p>客户管理</p>
        </div>
      </div>
    </div>
    <div class="videoBox">
      <div class="video">
        <video-player class="video-player vjs-custom-skin"
                      ref="videoPlayer4"
                      :playsinline="true"
                      :options="playerOptions4"
                      @play="onPlayerPlay($event,4)"
        ></video-player>
        <div class="detail">
          <p>委托业务</p>
        </div>
      </div>
    </div>
    <div class="videoBox">
      <div class="video">
        <video-player class="video-player vjs-custom-skin"
                      ref="videoPlayer5"
                      :playsinline="true"
                      :options="playerOptions5"
                      @play="onPlayerPlay($event,5)"
        ></video-player>
        <div class="detail">
          <p>雇员管理</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import video1 from "@/assets/video/01.系统界面v2.mp4";
  // import video2 from "@/assets/video/02.协议管理.mp4";
  // import video3 from "@/assets/video/03.客户管理.mp4";
  // import video4 from "@/assets/video/04.委托业务.mp4";

  export default {
    name: "help",
    data() {
      return {
        videosHeight:{'height':''},
        // 视频播放
        playerOptions1: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "",
            src: "https://ciicfchrsaas.ciicfc.com/01.系统界面v2.mp4"//url地址
          }],
          poster: "", //你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,//当前时间和持续时间的分隔符
            durationDisplay: true,//显示持续时间
            remainingTimeDisplay: false,//是否显示剩余时间功能
            fullscreenToggle: true  //全屏按钮
          }
        },
        playerOptions2: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "",
            src: "https://ciicfchrsaas.ciicfc.com/02.协议管理.mp4"//url地址
          }],
          poster: "", //你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,//当前时间和持续时间的分隔符
            durationDisplay: true,//显示持续时间
            remainingTimeDisplay: false,//是否显示剩余时间功能
            fullscreenToggle: true  //全屏按钮
          }
        },
        playerOptions3: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "",
            src: "https://ciicfchrsaas.ciicfc.com/03.客户管理.mp4"//url地址
          }],
          poster: "", //你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,//当前时间和持续时间的分隔符
            durationDisplay: true,//显示持续时间
            remainingTimeDisplay: false,//是否显示剩余时间功能
            fullscreenToggle: true  //全屏按钮
          }
        },
        playerOptions4: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "",
            src: "https://ciicfchrsaas.ciicfc.com/04.委托业务.mp4" //url地址
          }],
          poster: "", //你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,//当前时间和持续时间的分隔符
            durationDisplay: true,//显示持续时间
            remainingTimeDisplay: false,//是否显示剩余时间功能
            fullscreenToggle: true  //全屏按钮
          }
        },
        playerOptions5: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "",
            src: "http://ciicfcbenefits.ciicfc.com/雇员管理.mp4" //url地址
          }],
          poster: "", //你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,//当前时间和持续时间的分隔符
            durationDisplay: true,//显示持续时间
            remainingTimeDisplay: false,//是否显示剩余时间功能
            fullscreenToggle: true  //全屏按钮
          }
        }
      };
    },
    mounted(){
      if( this.$refs.elVideo.offsetHeight >  (document.documentElement.clientHeight - 92)){
          this.videosHeight = {'height':''}
      }else{
        this.videosHeight ={'height':(document.documentElement.clientHeight - 92)+"px"}
      }
    },
    methods:{
      onPlayerPlay(player, index) {
        for (let i = 1; i < 6; i++) {
          if(i != index)
            this.$refs["videoPlayer"+i].player.pause()
        }
      },
    }
  };
</script>

<style scoped>
  .videos {
    padding: 20px 7%!important;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  @media(max-width:1500px){
    .videos {
      padding: 20px!important;
    }
  }
  .videoBox{
    width: 50%;
    display: flex;
    justify-content: center;
  }
  .video {
    width: 500px;
    height: 320px;
    margin-bottom: 20px;
    /*height: calc((100% - 80px)/2);*/
    box-shadow: 0px 1px 4px 0px rgba(140, 140, 140, 0.26);
    border-radius: 8px;
    overflow: hidden;
  }

  .detail {
    padding: 0 10px;
  }

  .detail p {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 10px;
    color: #4b5394;
    font-weight: 600;
  }

  .detail p + p {
    font-size: 12px;
    border-top: 1px solid #eee;
    color: #333;
    font-weight: normal;
  }
</style>
