<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" @click="_toCreateBills">创建账单</el-button>
    </div>
    <bsp-table
      :data.sync="list"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="_findPaymentBillsPage"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
    >
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <bsp-table-column title="账单编号" field="id" width="100"  fixed="left"  filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.id}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="客户名称" field="cusName" width="120" fixed="left" filter-type="input">
      <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
    </bsp-table-column>
      <bsp-table-column title="账单月份" field="billMonth" width="100"  fixed="left" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.billMonth}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="账单金额" field="billAmount"  width="100"  filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.billAmount}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="工资往来" field="wageTrans"  width="100"   filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.wageTrans}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="个税往来" field="taxTrans"  width="100"  filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.taxTrans}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="代报销往来" field="reimburseTrans" width="100"   filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.reimburseTrans}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="服务费" field="serviceTotal" width="100"   filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.serviceTotal}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="状态" field="status" width="100"   filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.status | dictListFilter(status)}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="创建人" field="bizOperId" width="100"   filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.operName}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="创建时间" field="bizCreateTime" width="100"  filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.bizCreateTime}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="操作" fixed="right" width="120px" align="center">
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button type="text" @click="_toDetail(scope.row)" >查看</el-button>
            <el-button type="text" @click="_exportBill(scope.row)" >导出</el-button>
            <el-button type="text" @click="_deleteBill(scope.row)" >删除</el-button>
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>

    <!-- 创建账单 -->
    <el-dialog
      title="创建账单"
      :visible.sync="createDialogVisible"
      width="80%"
      @opened="$refs.createBill.init()"
      @close="$refs.createBill._close()"
    >
      <create-bill ref="createBill" @hidden = "createDialogVisible = false " @pageList="_findPaymentBillsPage"></create-bill>
    </el-dialog>
  </div>
</template>

<script>
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import CreateBill from "@/views/entrust/paymentBill/createBill/createBill";
  import {findPaymentBillsPage ,deleteBill,downloadBills } from '@/api/entrust/paymentBills'
  import {downloadFile} from '@/util/util'
  export default {
    name: 'paymentBills',
    components: {
      BspTable,
      BspTableColumn,
      CreateBill
    },
    data() {
      return {
        status: [
          {codeItemName: '新增', codeItemId: '0'},
          {codeItemName: '已下載', codeItemId: '1'}
        ],
        createDialogVisible:false ,
        loading: false,
        list: [],
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
        },
      }
    },
    created() {
      this._findPaymentBillsPage();
    },
    methods: {
      _exportBill(row) {
        downloadBills(row.id).then(res => {
          downloadFile(res);
        })
      },
      async _deleteBill(row){
        this.loading = true
        let res = await deleteBill(row.id);
        if(res.data.code ===0){
          this.$message.success("刪除成功");
          this._findPaymentBillsPage();
        }else{
          this.$message.error("删除失败");
          this.loading = false ;
        }
      },
      _toCreateBills() {
        // 去创建账单的页面
        this.createDialogVisible = true ;
      },
      _toDetail(row){
        this.$router.push({path: '/entrust/paymentBillDetail', query: {batchId: row.id,cusId:row.cusId}})
      },
      async _findPaymentBillsPage() {
        // 查询账单列表
        this.loading = true
        let res = await findPaymentBillsPage(this.listQuery);
        this.listQuery.total = res.data.total
        this.list = res.data.records
        this.loading = false
      }
    }
  }
</script>

<style scoped>

</style>
