<template>
  <div>
    <el-form :model="listQuery" class="ruleForm" size="small">
      <el-form-item label="应发金额 = ">
        <el-button
          type="warning"
          @click="onEditPayAmount()"
          round
          class="warningBtn pull-right"
          size="small"
          style="width: 84px"
        >
          编&emsp;&emsp;&emsp;辑
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-input
          type="textarea"
          placeholder="#应发金额#"
          v-model="payAmountExpression"
          :disabled="true"
          maxlength="300"
          show-word-limit
          :rows="3"
        />
      </el-form-item>
      <el-form-item label="自定义薪资项">
        <el-button
          round
          type="warning"
          class="warningBtn pull-right"
          @click="onEditWageItem"
        >
          添加薪资项
        </el-button>
      </el-form-item>
    </el-form>
    <div>
      <el-table
        ref="multipleTable"
        :data.sync="wageList"
        align="center"
        :header-cell-style="{
          'background-color': '#f8f8f9',
          padding: '6px 0'
        }"
        :cell-style="{ padding: '2px 0' }"
        border
        @selection-change="handleSelectionChange"
        v-loading="loadingwage"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column type="index" label="序号" width="50" align="center" />
        <el-table-column
          label="薪资项名称"
          align="center"
          prop="ITEMNAME"
          min-width="150px"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.ITEMNAME }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="数据类型"
          align="center"
          prop="ITEMTYPE"
          min-width="150px"
          style="display: none"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.VALUETYPE == "0" ? "文本" : "数值" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="薪资项规则"
          align="center"
          prop="FORMULA"
          min-width="150px"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.FORMULA }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="editWage(scope.row)">
              编辑
            </el-button>
            <el-button type="text" @click="delWage(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <bsp-dialog
        dialog-ref="dialogAdd"
        width="750px"
        title="编辑应发金额"
        :dialog-visible.sync="adddialogVisible"
        @close="closeDialog"
      >
        <div slot="body">
          <editPayAmount ref="dialogAdd" :list-data="addListForm" />
        </div>
        <span class="dialog-footer" slot="footer" style="font-size: small">
          <el-button
            type="primary"
            @click="go_savePayAmount()"
            :disabled="savePayDis"
            >保存</el-button
          >
          <el-button @click="cancelDialog">关闭</el-button>
        </span>
      </bsp-dialog>
      <bsp-dialog
        dialog-ref="addWageItem"
        width="750px"
        :title="title"
        v-if="addWageItemDialog"
        :dialog-visible.sync="addWageItemDialog"
        @close="closeWageItem"
        :before-close="closeWageItem"
      >
        <div slot="body">
          <editWageItemDialog
            ref="addWageItem"
            :add-param-form="this.addParamForm"
            :wage-show="this.wageShow"
          />
        </div>
        <span class="dialog-footer" slot="footer" style="font-size: small">
          <el-button
            type="primary"
            @click="go_saveWageItem('listRules')"
            :disabled="saveWageDis"
            >保存</el-button
          >
          <el-button @click="cancelWageItemDialog">关闭</el-button>
        </span>
      </bsp-dialog>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BspDialog from "@/components/bsp/bsp-dialog/bsp-dialog";
import editPayAmount from "./editPayAmount.vue";
import {
  getPayMoneyDefault,
  getWageItemList,
  savePayAmount,
  deleteWageItem,
  updateSelect,
  editWageItem,
  saveWageItem,
  cancleSelectValid
} from "@/api/cus/payableConf";
import editWageItemDialog from "./editWageItem.vue";

export default {
  name: "WageItemFormulaList",
  inject: ["reload"],
  components: {
    BspDialog,
    editPayAmount,
    editWageItemDialog
  },
  computed: {
    ...mapGetters(["dicts"])
  },
  props: {
    formulaSelected: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  created() {
    // this.footerMethodDetail();
    // console.log("这里执行了吗？");
    this.loadWageList();
    this.loadPayAmountExpression();
  },
  data() {
    return {
      // 遮罩
      loadingwage: false,
      saveWageDis: false, // 保存按钮重复提交
      savePayDis: false,
      adddialogVisible: false,
      addWageItemDialog: false,
      payAmountExpression: "",
      title: "",
      detailInfo: {},
      wageList: [],
      //分页查询对象
      listQuery: {
        limit: 50,
        page: 1,
        total: 0
      },
      //多选数组
      multipleDetailSelection: [],
      addListForm: {
        searchWageParam: "",
        payMoney: "",
        formulaId: ""
      },
      selectionTemp: [],
      addParamForm: {
        cusId: "",
        itemId: "",
        itemName: "",
        valueType: "",
        orderBy: "",
        itemType: "",
        formula: ""
      },
      wageItem: false,
      allCheck: {
        isAll: ""
      },
      toggleStart: "1"
    };
  },

  methods: {
    // getRowKey(row) {
    //   return row.id;
    //   editWageItemDialog;
    // },
    loadWageList() {
      console.log("默认会调用吗？");
      this.loading = true;
      this.listQuery.cusId = this.formulaSelected.cusid;
      getWageItemList(this.listQuery).then(res => {
        console.log("自定义薪资项列表", res);
        this.wageList = res.data.data;
        this.listQuery.total = res.data.data.length;
        this.$nextTick(() => {
          this.toggleStart = "2";
          this.toggleSelection();
        });
        this.loading = false;
      });
    },
    editReloadList() {
      this.loading = true;
      this.toggleStart = "1";
      this.listQuery.cusId = this.formulaSelected.cusid;
      getWageItemList(this.listQuery).then(res => {
        console.log("新增编辑后刷新", res);
        this.wageList = res.data.data;
        this.listQuery.total = res.data.data.length;
        this.$nextTick(() => {
          this.toggleStart = "2";
          this.toggleSelection();
        });
        this.loading = false;
      });
    },
    editWage(row) {
      this.title = "编辑薪资项";
      this.addParamForm.cusId = row.CUSTOMERID;
      this.addParamForm.formula = row.FORMULA === "手工导入" ? "" : row.FORMULA;
      this.addParamForm.itemId = row.ITEMID;
      this.addParamForm.itemName = row.ITEMNAME;
      if (row.ITEMTYPE == "0") {
        this.wageShow = false;
      } else {
        this.wageShow = true;
      }
      this.addParamForm.itemType = row.ITEMTYPE;
      this.addParamForm.orderBy = row.DISPLAY;
      this.addParamForm.valueType = row.VALUETYPE;
      console.log("this.addParamForm====", this.addParamForm);
      // this.$refs.addWageItem.filterByItemName();
      this.addWageItemDialog = true;
      this.$nextTick(() => {
        this.$refs.addWageItem.filterByItemName(row.ITEMNAME);
      });
    },
    delWage(row) {
      this.$confirm("您确定要删除选中的自定义薪资项吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          row.cusId = this.formulaSelected.cusid;
          deleteWageItem(row).then(res => {
            let resData = res.data.data; //将查询结果保存
            if (resData == null) {
              this.listData.payMoney = null;
            } else {
              // this.listData.payMoney = resData.formulaName;
              // this.listData.formulaId = resData.formulaId;
              if (res.data.code == 0) {
                this.$message.success("删除成功");
                this.editReloadList();
              }
            }
          });
        })
        .catch(() => {
          this.isDisabled = false;
          this.loading = false;
          this.__getDetailImportBatchList();
        });
    },
    //编辑应发金额弹窗
    onEditPayAmount() {
      console.log("父组件传递formulaSelected", this.formulaSelected);
      this.adddialogVisible = true;
      this.$nextTick(() => {
        this.$refs.dialogAdd._getWageItemList();
      });
    },
    cancelDialog() {
      console.log("list -----------------", this.addListForm);
      // 清空表单
      this.addListForm.searchWageParam = "";
      this.$refs.dialogAdd.wageParamFilter(); // 使用ref调用刷新witemList数组
      this.adddialogVisible = false;
    },

    onEditWageItem() {
      let _order = 0;

      this.title = "添加薪资项";
      this.addParamForm.cusId = "";
      this.addParamForm.formula = "";
      this.addParamForm.itemId = "";
      this.addParamForm.itemName = "";
      this.addParamForm.itemType = "0";
      this.addParamForm.valueType = "1";
      console.log("this.addParamForm====", this.addParamForm);
      this.addWageItemDialog = true;
      this.wageShow = false;
      this.$nextTick(() => {
        this.$refs.addWageItem._getWageItemList();
        if (this.wageList.length > 0) {
          _order = this.wageList.length + 1;
        }
        this.addParamForm.orderBy = _order;
      });
    },
    // 关闭弹框
    closeDialog() {
      console.log("list -----------------", this.addListForm);
      // 清空表单
      this.addListForm.searchWageParam = "";
      this.$refs.dialogAdd.wageParamFilter(); // 使用ref调用刷新witemList数组
      this.adddialogVisible = false;
    },
    closeWageItem() {
      console.log("关闭薪资项弹框 -----------------");
      this.$nextTick(() => {
        this.$refs.addWageItem.$refs["addParamForm"].resetFields();
      });
      this.addParamForm.searchWageParam = "";
      this.$refs.addWageItem.wageParamFilter(); // 使用ref调用刷新witemList数组
      this.addWageItemDialog = false;
    },
    cancelWageItemDialog() {
      console.log("取消薪资项弹框 -----------------");
      this.addParamForm.searchWageParam = "";
      this.$refs.addWageItem.wageParamFilter(); // 使用ref调用刷新witemList数组
      this.addWageItemDialog = false;
    },
    go_savePayAmount() {
      // 应发金额保存
      // param :{cusid,}
      this.savePayDis = true;
      if (this.addListForm.payMoney == "") {
        this.$message.error("请填写应发金额公式");
        return;
      }
      let formData = {
        formulaId: this.addListForm.formulaId,
        cusId: this.formulaSelected.cusid,
        wageItem: this.addListForm.payMoney
      };

      console.log(formData);
      savePayAmount(formData).then(res => {
        // let res.data.records;
        console.log(res);
        if (res.data.data.isValid) {
          if (res.data.msg == "success") {
            this.$message.success("保存成功");
            this.adddialogVisible = false;
            // this.__getLeadsLis();
            this.loadPayAmountExpression();
          } else {
            this.$message.error("保存失败");
          }
        } else {
          this.$message.error(
            "公式校验失败！错误原因【" + res.data.data.msg + "】"
          );
        }
        this.savePayDis = false;
      });
    },
    go_saveWageItem(formName) {
      this.saveWageDis = true;
      //薪资项保存
      let numberVal = parseInt(this.addParamForm.orderBy);
      if (numberVal == 0) {
        this.addParamForm.orderBy = "0";
      }
      if (
        this.addParamForm.itemName == "" ||
        typeof this.addParamForm.itemName == "undefined"
      ) {
        this.$message.error("请填写薪资项名称");
        this.saveWageDis = false;
        return;
      }
      if (this.addParamForm.orderBy == "") {
        this.$message.error("请填写显示顺序");
        this.saveWageDis = false;
        return;
      }
      if (
        this.addParamForm.itemType == "1" &&
        this.addParamForm.formula == ""
      ) {
        this.$message.error("请填写公式");
        this.saveWageDis = false;
        return;
      }
      if (
        this.addParamForm.orderBy != "" &&
        !Number.isInteger(parseInt(this.addParamForm.orderBy))
      ) {
        this.$message.error("请填写数字值显示顺序");
        this.saveWageDis = false;
        return;
      }
      let paramsForm = {
        cusId: this.formulaSelected.cusid,
        itemId: this.addParamForm.itemId,
        itemName: this.addParamForm.itemName,
        valueType: this.addParamForm.valueType,
        orderBy:
          this.addParamForm.orderBy == "" ? "0" : this.addParamForm.orderBy,
        itemType: this.addParamForm.itemType,
        formula:
          this.addParamForm.itemType == "0"
            ? "手动导入"
            : this.addParamForm.formula
      };
      if (paramsForm.orderBy < 0) {
        this.$message.error("请填写0~999之前的显示顺序值");
        this.saveWageDis = false;
        return;
      }
      if (
        this.addParamForm.itemId != "" &&
        typeof this.addParamForm.itemId != "undefined"
      ) {
        editWageItem(paramsForm).then(res => {
          if (res.data.code == 0) {
            if (res.data.msg == "success") {
              this.$message.success("编辑保存成功");
              this.addWageItemDialog = false;
              this.editReloadList();
            } else {
              this.$message.error("保存失败");
            }
          } else {
            this.$message.error(
              "薪资项保存失败！错误原因【" + res.data.msg + "】"
            );
          }
          this.saveWageDis = false;
        });
      } else {
        saveWageItem(paramsForm).then(res => {
          console.log("新增保存res==", res);
          if (res.data.code == 0) {
            if (res.data.msg == "success") {
              this.$message.success("新增保存成功");
              this.addWageItemDialog = false;
              this.editReloadList();
            } else {
              this.$message.error("保存失败");
            }
          } else {
            this.$message.error(
              "薪资项保存失败！错误原因【" + res.data.msg + "】"
            );
          }
          this.saveWageDis = false;
        });
      }
    },
    loadPayAmountExpression() {
      console.log("加载客户的应发金额表达式");
      this.listQuery.cusId = this.formulaSelected.cusid;
      getPayMoneyDefault(this.listQuery).then(res => {
        console.log("结果res=====>", res);
        let resData = res.data.data; //将查询结果保存
        if (resData == null) {
          this.payAmountExpression = "";
        } else {
          this.payAmountExpression = resData.formulaDesc;
        }
      });
    },
    async handleSelectionChange(val) {
      console.log("默认情况。。", this.toggleStart);

      if (this.toggleStart === "3") {
        console.log("用户选中信息", val, this.selectionTemp);

        if (val.length == 0) {
          console.log("取消全选。。。", val);
          this.allCheck = {
            isAll: "cancel",
            cusId: this.formulaSelected.cusid
          };
          updateSelect(this.allCheck).then(res => {
            if (res.data.code == 0) {
              this.$message.success(res.data.data);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }

        let res = [];
        let check = 0;
        if (this.selectionTemp.length < val.length) {
          // 选中
          res = val.filter(item => this.selectionTemp.indexOf(item) == -1);
          console.log("选中结果", res);
          check = 1;
        } else {
          // 取消选中
          res = this.selectionTemp.filter(item => val.indexOf(item) == -1);
          console.log("取消选中结果", res);
          check = 0;

          let awaitres = await cancleSelectValid({
            itemName: res[0].ITEMNAME,
            cusId: res[0].CUSTOMERID
          });
          console.log(awaitres);
          if (awaitres) {
            this.$message.error(
              "薪资项【" +
                res[0].ITEMNAME +
                "】已经被其他薪资项引用,不能取消勾选"
            );

            this.$nextTick(() => {
              this.toggleStart = "2";
              this.toggleSelection();
            });
            return;
          }
        }
        this.selectionTemp = val;
        // 遍历结果更新数据
        res.forEach(wage => {
          wage.ISCHECK = check;
          wage.cusId = this.formulaSelected.cusid;
          console.log("请求参数", wage);
          updateSelect(wage).then(res => {
            console.log("选中状态更新结果res=====>", res);
            if (res.data.code == 0) {
              this.$message.success(
                "薪资模板【" + wage.ITEMNAME + "】自定义项" + res.data.data
              );
            } else {
              this.$message.error(res.data.msg);
            }
          });
        });
      } else if (this.toggleStart == "1") {
        this.$nextTick(() => {
          this.toggleStart = "2";
          this.toggleSelection();
        });
      }
    },
    wageSelectAll({ checked, selection }) {
      // console.log("全选/取消全选======>", checked, selection);
      let p = {
        cusId: this.formulaSelected.cusid,
        iISCHECK: checked ? 1 : 0,
        batch: "true"
      };
    },
    toggleSelection() {
      if (this.toggleStart == "2") {
        this.selectionTemp = [];
        if (this.wageList) {
          this.wageList.forEach(wage => {
            console.log("wage.ISCHECK == 1", wage.ISCHECK == 1);
            if (wage.ISCHECK == 1) {
              console.log("选中行信息wage", wage);
              this.$refs.multipleTable.toggleRowSelection(wage, true);
              this.selectionTemp.push(wage);
            }
          });
          console.log("toggleSelection====>", this.toggleStart);
          this.toggleStart = "3";
        }
      }
    }
  }
};
</script>
<style scoped></style>
