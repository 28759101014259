<template>
  <div class="stepContent">
    <div class="checkOK">
      <p class="title">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-tongguo"></use>
        </svg>
        <span class="OKtitle">
          校验通过
        </span>
      </p>
      <p class="content">导入共计100人，校验通过100人</p>
    </div>
    <div class="checkFail">
      <p class="title">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-jinggao"></use>
        </svg>
        <span class="OKtitle">
          校验未通过
        </span>
      </p>
      <p class="content">导入共计100人，校验通过80人，未通过20人</p>
      <div class="fail">
        <div class="failTip">
          <p class="title">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-tishi"></use>
            </svg>
            <span class="OKtitle">
              提示
            </span>
          </p>
          <p class="content">1、张三_1716未上传残疾人证书</p>
          <p class="content">2、张三_1716未上传残疾人证书</p>
        </div>
        <div class="failTip">
          <p class="title">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-cuowu"></use>
            </svg>
            <span class="OKtitle">
              警告
            </span>
          </p>
          <p class="content">1、张三_1716未上传残疾人证书</p>
          <p class="content">2、张三_1716未上传残疾人证书</p>
        </div>
        <div class="failError">
          <p class="title">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-guanbi"></use>
            </svg>
            <span class="OKtitle">
              错误
            </span>
          </p>
          <p class="content">1、张三_1716未上传残疾人证书</p>
          <p class="content">2、张三_1716未上传残疾人证书</p>
        </div>
      </div>
      <p class="tip">您可通过导出错误结果文件,修改完然后 <em>再次上传</em></p>
    </div>
    <div v-if="next" class="next-btn">
      <el-button type="primary" @click="goback" size="mini">上一步</el-button>
      <el-button type="primary" @click="next" size="mini">完成</el-button>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
      };
    },
    mounted() {
    },
    methods: {
      next() {
        this.$emit("next"); // 跳转下一步
      },
      goback() {
        this.$emit("goback"); // 跳转上一步
      }
    }
  };
</script>
<style scoped rel="stylesheet/scss">
  .stepContent {
  }
  .checkOK {
    background-color: #f0f9eb;
    padding: 20px;
    margin-bottom: 20px;
    color:#67c23a;
  }
  .checkOK .title{
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .checkOK .title svg{
    margin-right: 10px;
  }
  .checkOK .content{
    padding-left: 30px;
    font-size: 14px;
  }
  .checkFail{
    background-color: #fef0f0;
    padding: 20px;
    margin-bottom: 20px;
  }
  .checkFail .title{
    color:#f95b47;
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .checkFail .title svg{
    margin-right: 10px;
  }
  .checkFail>.content{
    color:#f95b47;
    padding-left: 30px;
    font-size: 14px;
  }
  .failTip,.failError{
    margin-top: 20px;
    padding-left: 30px;
  }
  .failTip .title{
    color:#fd6702;
    font-size: 16px;
  }
  .failError .title{
    color:red;
    font-size: 16px;
  }
  .failTip .content,.failError .content{
    font-size: 12px;
    padding-left: 30px;
    color:#888888;
  }
  .tip{
    margin-top: 10px;
    padding-left: 30px;
    color:#666666;
    font-size: 14px;
  }
  .tip em{
    font-weight: bold;
    color:#008aff;
    font-size: 16px;
    cursor: pointer;
  }
  .next-btn{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
</style>
