<template>
  <div class="caculateFormula">
    <el-form
      class="leado-add-form"
      :model="addParamForm"
      :rules="listRules"
      ref="addParamForm"
      label-width="120px"
      @submit.native.prevent
    >
      <div class="editItem">
        <el-row>
          <el-col :span="24">
            <el-form-item label="薪资项名称:" prop="itemName">
              <el-input
                max="6"
                v-model="addParamForm.itemName"
                maxlength="20"
              />
            </el-form-item>
            <el-form-item label="数据类型:" prop="valueType">
              <el-select
                v-model="addParamForm.valueType"
                @change="getOptionSelected(addParamForm.valueType)"
              >
                <el-option label="文本" value="0" />
                <el-option label="数值" value="1" />
              </el-select>
            </el-form-item>
            <el-form-item label="显示顺序:" prop="orderBy">
              <el-input
                max="6"
                v-model.number="addParamForm.orderBy"
                maxlength="3"
              />
            </el-form-item>
            <el-form-item label="薪资项规则:" prop="itemType">
              <!-- <el-radio-group v-model="addParamForm.ITEMTYPE" @change="typeChange">
              <el-radio label="通过公式" value="1" checked></el-radio>
              <el-radio label="手动导入" value="2"></el-radio>
              </el-radio-group> -->
              <el-radio
                v-model="addParamForm.itemType"
                label="1"
                @change="typeChange"
              >
                通过公式
              </el-radio>
              <el-radio
                v-model="addParamForm.itemType"
                label="0"
                @change="typeChange"
              >
                手动导入
              </el-radio>
            </el-form-item>
          </el-col>
          <div v-show="wageShow">
            <el-col :span="24">
              <el-form-item label="">
                <el-input
                  type="textarea"
                  placeholder=""
                  v-model="addParamForm.formula"
                  :autosize="{ minRows: 2, maxRows: 2 }"
                  id="formula"
                  :style="{ width: '100%' }"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24" style="margin-block: 15px;">
              <el-form-item label="快捷运输符" prop="field117">
                <el-button
                  size="small"
                  @click="makeActive('+ ')"
                  style="color: coral;background-color: white;border: 1px solid"
                >
                  +
                </el-button>
                <el-button
                  size="small"
                  @click="makeActive('- ')"
                  style="color: coral;background-color: white;border: 1px solid"
                >
                  -
                </el-button>
                <el-button
                  size="small"
                  @click="makeActive('* ')"
                  style="color: coral;background-color: white;border: 1px solid"
                >
                  ×
                </el-button>
                <el-button
                  size="small"
                  @click="makeActive('/ ')"
                  style="color: coral;background-color: white;border: 1px solid"
                >
                  ÷
                </el-button>
                <el-button
                  size="small"
                  @click="makeActive('()')"
                  style="color: coral;background-color: white;border: 1px solid"
                >
                  ()
                </el-button>
                <el-button
                  size="small"
                  type="warning"
                  @click="go_payAmouValid()"
                  style="float: right; width: 84px; background-color: #ff7d0a"
                  round
                >
                  公式校验
                </el-button>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="点击选择薪资项">
                <el-input
                  style="width: 200px"
                  id="searchInsert"
                  v-model="addParamForm.searchWageParam"
                  placeholder="搜索薪资项"
                  prefix-icon="el-icon-search"
                  @input="wageParamFilter"
                  @keyup.enter.native="seacrhEnter"
                  @change="searchChange()"
                  clearable
                />
                <!--<el-button class="pull-right" type="primary" size="small" fixed="right" @click="onEditWageItem">添加薪资项</el-button>-->
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-card>
                <el-row :gutter="10" class="wageItemCard">
                  <el-col
                    :span="6"
                    v-for="(item, index) in witemList"
                    :key="index"
                    :class="{
                      mb10:
                        Math.floor(index / 4) !=
                        Math.floor(witemList.length / 4)
                    }"
                  >
                    <el-tag
                      @click="do_wage(item)"
                      type="info"
                      effect="plain"
                      class="wageItemTag"
                      size="medium"
                    >
                      {{ item }}
                    </el-tag>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </div>
        </el-row>
      </div>
    </el-form>
  </div>
</template>
<script>
import { getWageItemList, payAmouValid } from "@/api/cus/payableConf";
import { mapGetters } from "vuex";
import { isvalidatemobile, validateEmail } from "@/util/validate";
import ContentEdit from "@/components/bsp/bsp-rich-text/ContentEdit";
export default {
  name: "EditWageItemDialog",
  components: {
    //ContentEdit
  },
  props: {
    addParamForm: {
      type: Object,
      default: () => {
        return {
          searchWageParam: "",
          formula: ""
        };
      }
    },
    wageShow: {
      type: Boolean,
      default: false
    }
  },
  created() {
    // this.footerMethodDetail();
    this.listQuery.cusId = this.$store.state.formulaSelected.cusid;
    this.addParamForm.valueType == "" ? "1" : this.addParamForm.valueType;
    // this.$refs._getWageItemList();
  },
  data() {
    var checkOrder = (rule, value, callback) => {
      let numberVal = parseInt(value);
      if (numberVal == 0) {
      } else if (!value) {
        callback(new Error("显示顺序不能为空"));
      } else if (value < 0) {
        callback(new Error("顺序只允许填入0~999之间的数值"));
      } else if (!Number.isInteger(numberVal)) {
        callback(new Error("请输入数字值"));
      }
    };
    return {
      witemList: [],
      witemListAll: [],
      // 遮罩
      loading: false,
      addWageItemDialog: false,
      itemId: "",
      //分页查询对象
      listQuery: {},
      listForm: {},
      //多选数组
      multipleDetailSelection: [],
      params: {
        batchImportId: "",
        bizType: "",
        cusId: this.$route.cusId
      },
      listRules: {
        itemName: [
          { required: true, message: "请输入薪资项名称", trigger: "blur" }
        ],
        valueType: [
          { required: true, message: "请选择数据类型", trigger: "change" }
        ],
        itemType: [
          {
            type: "date",
            required: true,
            message: "请选择薪资项规则",
            trigger: "change"
          }
        ],
        orderBy: [{ required: true, validator: checkOrder, trigger: "blur" }]
      }
    };
  },
  methods: {
    _getWageItemList() {
      // 获取薪资项列表
      console.log(this.cusid);
      this.listQuery.cusId = this.$store.state.formulaSelected.cusid;
      getWageItemList(this.listQuery).then(res => {
        console.log("获取薪资项列表=====>", res);
        this.witemList = [];
        this.witemListAll = [];
        for (var index = 0; index < res.data.data.length; index++) {
          const element = res.data.data[index];
          this.witemListAll.push(element.ITEMNAME); //将查询结果保存
        }
        this.witemList = this.witemListAll; //复制查询结果给渲染变量
        this.witemList = this.witemListAll.filter(
          item => item != this.addParamForm.itemName
        );
      });
    },
    go_payAmouValid() {
      // 公式校验
      this.listQuery.wageItem = this.addParamForm.formula;
      if (this.addParamForm.formula === "") {
        this.$message.error("请输入自定义薪资项计算公式");
        return;
      }
      payAmouValid(this.listQuery).then(res => {
        let resObj = res.data.data; //将查询结果保存
        console.log("公式校验结果", res);
        if ((resObj.code = "success") && resObj.isValid) {
          this.payAmouValidRes = true;
          this.$message.success("公式校验成功！");
        } else {
          let msg = resObj.msg;
          this.payAmouValidRes = false;
          this.$message.error("公式校验失败！" + msg);
        }
      });
    },
    //复选框全选事件
    selectDetailAll({ checked, selection }) {
      if (checked) {
        this.multipleDetailSelection = selection;
      } else {
        this.multipleDetailSelection = [];
      }
      this.$emit("getSelectData", this.multipleDetailSelection);
    },
    // 关闭弹框
    closeDialog() {
      console.log("list关闭弹框 -----------------", this.addListForm);
      // 清空表单
      this.listRules.resetFields;
      this.adddialogVisible = false;
    },
    cancelDialog() {
      console.log("list list关闭弹框-----------------", this.addListForm);
      // 清空表单
      this.listRules.listRules.resetFields();
    },
    typeChange(val) {
      console.log("val==" + val);
      this.addParamForm.searchWageParam = "";
      if (val == "1") {
        console.log("typeChange show =======>", this.addParamForm);
        this.addParamForm.formula =
          this.addParamForm.formula == "手动导入"
            ? ""
            : this.addParamForm.formula;
        console.log("显示隐藏内容");
        this.wageShow = true;
      } else {
        console.log("gunabi隐藏内容");
        this.wageShow = false;
      }
    },
    getOptionSelected(value) {
      console.log("VALUETYPE--" + value);
      this.addParamForm.valueType = value;
    },
    wageParamFilter() {
      console.log("wageParamFilter");
      // 根据搜索内容过滤薪资项
      this.witemList = this.witemListAll.filter(
        item => item.indexOf(this.addParamForm.searchWageParam) != -1
      );
      console.log("wageParamFilter1");
      this.witemList = this.witemList.filter(
        item => item != this.addParamForm.itemName
      );
      console.log("wageParamFilter2");
    },
    filterByItemName(itemName) {
      console.log("自定义薪资项编辑=====>", itemName, this.witemListAll);
      if (this.witemListAll.length == 0) {
        this._getWageItemList();
      }
      this.witemList = this.witemListAll.filter(item => item != itemName);
    },
    seacrhEnter() {
      if (this.witemList.length >= 1) {
        // 搜索薪资项回车
        let firstWitem = "#" + this.witemList[0] + "# ";
        console.log("第一个元素名称", firstWitem);
        this.makeActive(firstWitem);
        this.addParamForm.searchWageParam = "";
        this.wageParamFilter();
      }
    },
    do_wage(item) {
      // 选择薪资项
      item = "#" + item + "# ";
      this.makeActive(item);
    },
    async makeActive(value) {
      // 在光标位置点击输入字符
      const myField = document.querySelector("#formula");

      if (myField.selectionStart || myField.selectionStart === 0) {
        let startPos = myField.selectionStart;

        let endPos = myField.selectionEnd;

        this.addParamForm.formula =
          myField.value.substring(0, startPos) +
          value +
          myField.value.substring(endPos, myField.value.length);

        await this.$nextTick(); // 这句是重点, 圈起来

        myField.focus();
        if (value === "()") {
          myField.setSelectionRange(
            endPos + value.length,
            endPos + value.length - 1
          );
        } else {
          myField.setSelectionRange(
            endPos + value.length,
            endPos + value.length
          );
        }
      } else {
        this.addParamForm.formula = value;
      }
    },
    searchChange() {
      this.wageShow = true;
    }
  }
};
</script>
