var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cusHeader"},[_c('waterfall',{ref:"waterfall",attrs:{"list":_vm.headerData,"gutter":0,"width":300,"gridWidth":-1,"breakpoints":{
      1200: { //当屏幕宽度小于等于1200
      rowPerView: 4,
      },
      800: { //当屏幕宽度小于等于800
      rowPerView: 3,
      },
      500: { //当屏幕宽度小于等于500
      rowPerView: 2,
      }
  }},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('div',{staticClass:"card"},[_c('ul',{staticStyle:{"max-height":"100px"}},[_c('li',{staticClass:"title"},[_vm._v(_vm._s(props.data.title))]),_vm._l((props.data.data),function(data){return _c('li',{key:data.infoItemId,class:{'selected':_vm.isSelect(data.infoItemId)},on:{"click":function($event){return _vm.liClick(data)}}},[_vm._v(" "+_vm._s(data.infoItemName)+" ")])})],2)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }