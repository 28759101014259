<template>
  <div>
    <el-form
      :model="listForm"
      ref="listForm"
      :rules="releaseRules"
      class="editForm"
      label-width="168px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="姓名:" prop="name">
            <el-input v-model="listForm.name" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性别:" prop="gender">
            <bsp-dicts-select
              v-model="listForm.gender"
              dic-num="0100"
              :select-value.sync="listForm.gender"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="证件类型" prop="idType">
            <bsp-dicts-select
              v-model="listForm.idType"
              ref = "idTypeinfo"
              dic-num="1001"
              @change="aaaa"
              :select-value.sync="listForm.idType"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="证件号码" prop="idCard">
            <el-input v-model="listForm.idCard" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="出身日期">
            <el-date-picker
              v-model="listForm.birthday"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            />
          </el-form-item>
        </el-col>
<!--        <el-col :span="12">-->
<!--          <el-form-item label="所在机构">-->
<!--            <bsp-select-tree-->
<!--              v-model="listForm.orgId"-->
<!--              width="260"-->
<!--              super-id="-2"-->
<!--            />-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :span="12">
          <el-form-item label="所在机构">
            <!-- 调用树形下拉框组件 -->
            <ciic-tree-select
              :props="props"
              :options="treeList2"
              :value="valueId"
              @getValue="getValue($event)"
            >
            </ciic-tree-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="上传单个附件">
            <sbsp-file-upload :file-ids.sync="listForm.fileId" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="上传多个附件">
            <sbsp-file-upload :file-ids.sync="listForm.fileIds" limit="5" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="头像">
            <div>
              <ciic-img-upload
                @handleUploadSuccess="handleUploadSuccess"
                v-model="showCropAvatar"
              />
              <div
                @click="toggleShowCropAvatar"
                style="cursor: pointer;width: 60px"
              >
                <img
                  v-if="listForm.photo"
                  :src="listForm.photo | filePathFormat"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="行政区划">
              <el-cascader
                size="large"
                :options="options"
                v-model="selectedOptions"
                @change="handleChange">
              </el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import bspSelectTree from "@/components/bsp/bsp-select-tree/bsp-select-tree";
import bspDictsSelect from "@/components/bsp/bsp-dicts-select/bsp-dicts-select";
import { updateListpage, addListpage } from "@/api/demo/demo";
import { isvalidatemobile, cardid } from "@/util/validate";
import sbspFileUpload from "./sbsp-file-upload/sbsp-file-upload";
import ciicImgUpload from "../../../components/ciic/ciic-img-upload/ciic-img-upload";
import CiicTreeSelect from "../../../components/ciic/ciic-tree-select/ciic-tree-select";
//import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode  } from 'element-china-area-data'
import { init,codeList,codeToName,nameToCode,getCodeNames,getNameCodes} from "@/components/bsp/code/frontCode";
export default {
  components: {
    CiicTreeSelect,
    ciicImgUpload,
    bspDictsSelect,
    bspSelectTree,
    sbspFileUpload
  },
  name: "Dialog",
  props: {
    listForm: {
      type: Object,
      default: () => ({})
    },
    dialogFormVisible: {
      type: Boolean,
      default: false
    },
    dialogStatus: {
      type: String,
      default: ""
    }
  },
  watch: {
    listForm: {
      handler(newVal, oldVal) {
        // 在监听你使用update事件来更新listForm,而在父组件不需要调用该函数
        this.$emit("update:listForm", newVal);
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    init('city');
    /*console.log("行政区划：",cityList);
    console.log("行政区划：",codeToName);
    console.log("行政区划：",codeToName[ "120000", "120100", "120101"]);
    console.log("行政区划fanyi1：",getCodeNames("120000,120100,120101"));
    console.log("行政区划fanyi2：",getNameCodes("北京市,北京市,昌平区"));
    console.log("行政区划：",nameToCode);*/
  },
  mounted() {},
  data() {
    const validateIdcard = (rule, value, callback) => {
      if (this.listForm.idType == "00340001") {
        if (cardid(value)[0]) {
          callback(new Error(cardid(value)[1]));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      options: codeList,
      selectedOptions: [],
      disabled: true,
      showCropAvatar: false,
      photo: "",
      releaseRules: {
        name: [{ required: true, message: "名字不能为空", trigger: "blur" }],
        // 引用基本的 校验
        idCard: [
          { required: true, validator: validateIdcard, trigger: "blur" }
        ],
        idType: [
          { required: true, message: "证件类型不能为空", trigger: "blur" }
        ]
      },
      sexList: [{ value: "1", label: "女" }, { value: "2", label: "男" }],
      props: {
        // 配置项（必选）
        value: "id",
        label: "orgName",
        children: "children"
      },
      treeList2: [
        {
          branchId: "HUB001",
          cusId: "0e5adece2a9940fda2b7c65deeb74f78",
          id: "441d5d60c3fb11e9806b00163e026f59",
          orgFormation: "500",
          orgName: "交付中心",
          page: 0,
          supId: "-1",
          children: [
            {
              branchId: "HUB001",
              children: [],
              cusId: "0e5adece2a9940fda2b7c65deeb74f78",
              id: "44a79fd1c3fb11e9806b00163e026f59",
              orgName: "中智智人",
              page: 0,
              size: 0,
              supId: "441d5d60c3fb11e9806b00163e026f59"
            },
            {
                branchId: "HUB001",
                children: [],
                cusId: "0e5adece2a9940fda2b7c65deeb74f78",
                id: "44a79fd1c3fb11e9806b00163e026f19",
                orgName: "测试1",
                page: 0,
                size: 0,
                supId: "441d5d60c3fb11e9806b00163e026f59"
            }
          ]
        },
        {
          branchId: "HUB001",
          cusId: "0e5adece2a9940fda2b7c65deeb74f78",
          id: "441d5d60c3fb11e9806b00163e026f58",
          orgFormation: "500",
          orgName: "集团",
          page: 0,
          supId: "-1",
          children: [
            {
              branchId: "HUB001",
              children: [],
              cusId: "0e5adece2a9940fda2b7c65deeb74f78",
              id: "44a79fd1c3fb11e9806b00163e026f54",
              orgName: "销售部",
              page: 0,
              size: 0,
              supId: "441d5d60c3fb11e9806b00163e026f58"
            }
          ]
        }
      ],
      valueId: null,
    };
  },
  methods: {
    toggleShowCropAvatar() {
      this.showCropAvatar = !this.showCropAvatar;
    },
    handleUploadSuccess(res, field) {
      console.log(res);
      this.listForm.photo = res.fileId;
    },
    // 取值
    getValue(value){
        this.valueId = value
    },
    handleChange(v){}
  }
};
</script>

<style scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  margin-left: 30px;
  margin-bottom: 5px;
}
.avatar {
  width: 50px;
  height: 50px;
  display: block;
  margin-left: 30px;
  margin-bottom: 5px;
}
</style>
