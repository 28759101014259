<template>
  <div ref="ciicTableBox">
    <vxe-table
      v-bind = "{...tableAttrs,...$attrs}"
      v-on="$listeners"
      :remote-filter="true"
      remote-sort
      :edit-config="editorConfig"
      @filter-change="filterChange"
      @sort-change="sortChange"
      @select-change="getSelect"
      @select-all="getSelectAll"
      class="vxe-table-element"
      ref="ciicTable">
      <!--  封装动态表格的-->
      <template  v-for="(ciicColumn,i) in ciicColumns">
        <ciic-table-column :key="i"  v-bind ="{...ciicColumn}">
          <template slot-scope="scope" v-if="!ciicColumns.grounpColumns">
            <span>{{ scope.row[ciicColumn.field] | dataTypeFilter(dicts)}}</span>
          </template>
        </ciic-table-column>
      </template>

      <template>
        <slot ></slot>
      </template>

    </vxe-table>
    <div v-if="showPagination" ref="pagination" class="paginationContain">
      <slot name="pagination">
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page.sync="ciicDataFilter.page"
                       :page-sizes="pageSize" :page-size="ciicDataFilter.limit"
                       :layout="layout" :total="ciicDataFilter.total">
        </el-pagination>
      </slot>
    </div>
  </div>
</template>

<script>
    import ciicTableColumn from "@/components/ciic/ciic-table-column/ciic-table-column";

    import { mapGetters } from 'vuex';
    import {deepCopy} from "../../../util/util";
    export default {
        name: "ciicTable",
        props: {
            /**
             * 表格数据筛选对象，用于表格排序，分页
             * sortOrder：排序方式，是否升序,需配合sortField使用（desc降序，asc升序）
             * sortField： 排序字段名称
             * page：当前页码数，使用分页控件时必须有值
             * limit： 每页多少条数据，使用分页控件时必须有值
             * total： 总共有多少条数据，表格获取到数据后应该将返回数据中的total赋给该值
             */
            ciicDataFilter: {
                type: Object,
                default: () => ({
                    page: 1,
                    limit: 50,
                    total: 0,
                    filters: [],
                    orders: []
                }),
            },
            //动态表格表头
            ciicColumns:{
                type:Array,
                default: () => (
                    []
                )
            },
            // 是否展示分页
            showPagination: {
                type: Boolean,
                default: true,
            },
            // 表格高度是否自动计算占满屏幕
            heightFullScreen:{
                type: Boolean,
                default: true,
            },
            // 可编辑表格的一些配置
            editorConfig:{
                type: Object,
                default: ()=>(
                    {trigger: 'dblclick', mode: 'cell', showStatus: true}
                )
            },
            //  每页显示的条数
            pageSize: {
                type: Array,
                default: function () {
                    return [20,50,100]
                },
            },
            //  分页的展示方式
            layout: {
                type: String,
                default: 'total, sizes, prev, pager, next',
            }
        },
        data(){
            return {
                tableHeight: 0,
                // 表格属性
                tableAttrs: {
                    // 表格大小
                    size: 'medium',
                    // 是否高亮选中行
                    'highlight-current-row': true,
                },
                //表头筛选的条件
                filtersStore: {},
                //表头信息；
                tableColums:[],
                initTableFilter: {},
            }
        },
        provide() {
            return {
                $filtersStore: this.filtersStore,
                $tableColums: this.tableColums,
            }
        },
        computed: {
            ...mapGetters([
                'clientHeight',
                'workspaceRect',
                'tableSize',
                'dicts',
            ])
        },
        watch:{
            // ciicDataFilter: {
            //     handler(newVal, oldVal) {
            //         console.log('深度监听', newVal)
            //     },
            //     deep: true
            // }

        },
        created(){
        },
        components:{
            ciicTableColumn
        },
        mounted () {
            this.initAttrs()
            this.initEvent()

        },
        filters:{
            //动态表单的过滤
            dataTypeFilter(){

            }
        },
        methods:{
            /**
             *  初始化组件属性
             *
             */
            initAttrs(){
                if(this.$attrs.size) {
                    this.tableAttrs.size = this.tableSize;
                }
                // 没有指定高度，并且自动全屏，则动态计算表格高度，让工作区中表格的高度不会超出可视区域
                if (!this.$attrs.height && this.heightFullScreen) {
                    this.$set(this.tableAttrs, 'height', this.tableHeight);
                    this.tableHeight = this.clientHeight-this.$refs.ciicTableBox.getBoundingClientRect().top;
                    // 含有分页时计算分页的高度
                    if(this.$refs.pagination){
                        this.tableHeight -= this.$refs.pagination.getBoundingClientRect().height ;
                    }
                    this.tableAttrs.height = this.tableHeight;

                    this.$nextTick(function() {
                        // 计算表格实际高度
                        const workspaceHeight = this.clientHeight - this.workspaceRect.offsetTop;
                        // 如果工作区的实际高度大于可视区域中工作区的高度，计算偏移量，保证工作区不超出可视区域
                        const offsetHeight = this.workspaceRect.offsetHeight - workspaceHeight;
                        if(this.workspaceRect.offsetHeight >  workspaceHeight) {
                            this.tableHeight -= offsetHeight;
                        }
                        this.tableAttrs.height = this.tableHeight - 20;
                    })
                }
            },
            /**
             *  初始化组件事件
             *
             */
            initEvent(){

            },
            /**
             * 排序列点击事件
             * @param column
             */
            sortChange(column){
                let sortArray = []
                let obj = {}
                if(column.order){
                    obj.column = column.prop
                    obj.asc = column.order == 'asc' ? true : false
                }else {
                    obj = {}
                }
                //判断数组里是否有这个排序字段，有的话就改变asc的值，否则push进sortArray
                let result = this.ciicDataFilter.orders.some(item=>{
                    if(item.column == obj.column){
                        item.asc = obj.asc
                        return true
                    }else {
                        return false
                    }
                })
                if(!result){
                    sortArray.push(obj)
                }
                let initArray = deepCopy(this.ciicDataFilter.orders)
                //这边要用一个变量重新赋值，否则里面的filters赋值不成功
                let newCopyFilter = deepCopy(this.ciicDataFilter)
                newCopyFilter.orders = sortArray.concat(initArray)
                this.$emit('update:ciic-data-filter',newCopyFilter)
                this.$emit('ciic-data-refresh')
            },
            /**
             * 筛选事件
             * @param event
             */
            filterChange(event) {
                let filterArray = []
                this.filtersStore[event.field].value = event.datas
                for (let key in this.filtersStore){
                    let obj = {};
                    let value = this.filtersStore[key].value
                    if(value){
                        obj.operator = this.filtersStore[key].operator
                        obj.column = key
                        obj.value = this.filtersStore[key].value.join(',')
                        //判断数组里是否有这个排序字段，有的话就改变这个值，否则push进filterArray

                        let result = this.ciicDataFilter.filters.some(item=>{
                            if(item.column == obj.column){
                                item.value = obj.value
                                return true
                            }else {
                                return false
                            }
                        })
                        if(!result){
                            filterArray.push(obj)
                        }

                    }
                }
                //重置时，去吃掉value为空的数据
                let arr = this.ciicDataFilter.filters
                for(let i = 0; i < arr.length; i++){
                    if(arr[i].value == ''){
                        arr.splice(i,1);
                    }
                }
                let initArray = deepCopy(this.ciicDataFilter.filters)
                //这边要用一个变量重新赋值，否则里面的filters赋值不成功
                let newCopyFilter = deepCopy(this.ciicDataFilter)
                newCopyFilter.filters = filterArray.concat(initArray)
                this.$emit('update:ciic-data-filter',newCopyFilter)
                this.$emit('ciic-data-refresh')
            },
            /**
             * 改变表格大小
             * @param val
             */
            handleSizeChange(val) {
                this.ciicDataFilter.limit = val;
                this.ciicDataFilter.page = 1;
                this.$emit('update:ciic-data-filter',this.ciicDataFilter)
                this.$emit('ciic-data-refresh')
            },
            /**
             * 改变页码数
             * @param val
             */
            handleCurrentChange(val) {
                console.log('测试2',this.ciicDataFilter)
                this.ciicDataFilter.page = val;
                this.$emit('update:ciic-data-filter',this.ciicDataFilter)
                this.$emit('ciic-data-refresh')
            },
            //  复选框单选事件
            getSelectAll({ checked, row }){
                let selects = this.$refs.ciicTable.getSelectRecords();
                this.$emit('ciic-data-select',selects)
            },
            //  复选框全选事件
            getSelect({ checked, row }){
                var selects = this.$refs.ciicTable.getSelectRecords();
                this.$emit('ciic-data-select',selects)
            }
        }
    }
</script>

<style scoped>

</style>
