import request from '@/router/axios';

/**
 * 查询接口人信息
 * @param query
 */
export function queryInterfecPersonList(obj) {
  return request({
    url: '/person/list/orgid',
    method: 'get',
    params: obj
  });


}

export function queryersonList(obj) {
  return request({
    url: '/person/list/orgid',
    method: 'get',
    params: obj
  });


}


