<template>
  <div>
    <div class="customer-info" v-for="(ItemList,listIndex) in dynamicFormItemList" :key="ItemList.groupName">
      <div class="customer-info-item" v-show="ItemList.groupName !==undefined && ItemList.groupName">
        <div class="customer-info-title">
          <!--图标-->
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-qiyexinxi"></use>
          </svg>
          <span>{{ItemList.groupName}}</span>
        </div>
        <div class="customer-info-button">
          <!--编辑-->
          <svg class="icon" aria-hidden="true" @click="changeEdit(listIndex)">
            <use xlink:href="#icon-xiugai"></use>
          </svg>
          <!--展开-->
          <svg v-if="showList[listIndex]== 'true'" class="icon" aria-hidden="true" @click="changeShow(listIndex)">
            <use xlink:href="#icon-zhankaizhuangtai"></use>
          </svg>
          <!--收起-->
          <svg v-if="showList[listIndex]== 'false'" class="icon" aria-hidden="true" @click="changeShow(listIndex)">
            <use xlink:href="#icon-shouqizhuangtai"></use>
          </svg>
        </div>
      </div>
      <div v-if="showList[listIndex] =='true'" v-show="ItemList.groupName !==undefined && ItemList.groupName" class="editForm">
        <el-row v-if="editList[listIndex] ==='true'">
          <el-form :ref="'form2'+listIndex" :model="validateForm" label-width="168px" :rules="rules">
            <el-col
              v-for="(list,index) in ItemList.dynamicFormVOList" :key="list.itemId" :span="list.itemType==4||list.itemType==10 ? 24:8"
              :class="{'hidden':list.visiable || !isShow[list.valueId+'_show'],'titleDot':list.titleNull}">
              <bspFormItem :list="list" :index="index" :model.sync="formData[list.valueId]"/>
            </el-col>
          </el-form>
        </el-row>
        <el-row v-if="editList[listIndex] =='true'">
          <el-col :span="12" :offset="6">
            <div class="center marginTop30 marginBottom20">
              <el-button type="primary" @click="cancelHandler('form2'+listIndex)" size="mini">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-quxiao"></use>
                </svg>
                取消</el-button>
              <el-button type="primary" @click="submitHandler('form2'+listIndex)" size="mini">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-baocun"></use>
                </svg>
                提交</el-button>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="editList[listIndex] =='false'" :class="{onlyRead:editList[listIndex] =='false'}">
          <el-form label-width="168px">
            <el-col :span="8" :class="{'hidden':list.visiable}" v-for="list in ItemList.dynamicFormVOList" :key="list.itemId">
              <el-form-item :label="list.titleName">
                <p class="formText" v-if="list.itemType==6">{{formData[list.valueId] | dictFilter(dicts)}}</p>
                <p class="formText" v-else>{{formData[list.valueId]}}</p>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
  import bspDictsSelect from "@/components/bsp/bsp-dicts-select/bsp-dicts-select";
  import {isvalidatemobile, cardid} from '@/util/validate';
  import {getDynamicForm,DynamicFormUpdate,getCusInfoById} from "@/api/common/common";
  import bspFormItem from '@/wage-components/bsp-form-item/index';
  import { mapGetters } from 'vuex';


  export default {
    props: {
      currentTab: {
        type: String,
        default: ''
      },
      cusId: {
        type:Number,
        default: 0
      },
    },
    computed: {
      ...mapGetters([
        'dicts',
      ]),
    },
    data() {
      return {
        editList: [],
        showList: [],
        dynamicFormItemList: '',
        ifHaveTextarea: 0,
        noVisible: 0,
        itemTypeArr: [],
        parameter: {   //getDynamicForm参数
          operateType: 1,//表单是新增还是修改判断,0是新增，1是修改
          infoSetId: '',
          cusId:0,
        },
        rules: {
          titleName: [
            {required: true, message: '请输入用户名', trigger: 'blur'},
          ],
        },
        formData: {},
        fieldList:[],
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        },
        validateForm: {},
      }
    },
    components: {
      bspFormItem
    },
    /*watch: {
      currentTab: function (newVal, oldVal) {
      this.parameter.infoSetId = newVal;
      this._getDynamicForm();
      this._getDynamicFormValue(this.parameter);
    }
  },*/
    created() {
      this._getDynamicForm();
      console.log('客户修改');
      console.log(this.fieldList);
      this._getDynamicFormValue(this.cusId);

    },
    mounted() {
    },
    provide() {
      return {
        $rules: this.rules
      }
    },
    methods: {
      // 取消
      cancelHandler(formName) {

      },
      // 提交
      submitHandler(formName) {
        console.log(formName);
        console.log(this.$refs[formName]);
        console.log(this.formData);
        this.$refs[formName][0].validate((valid) => {
          if (valid) {
            //修改保存
            let obj = {};
            obj.infoSetId = this.parameter.infoSetId;
            obj.formData = this.formData
            DynamicFormUpdate(obj,'/cus').then(res=>{
              this.$message.success('修改成功');
            })
          }else {
            //alert("校验不通过");
          }

        })
      },
      //获取表单各项数据
      _getDynamicForm() {
        console.log("加载表单数据");
       this.parameter.infoSetId = this.currentTab;
        console.log(this.parameter);
        getDynamicForm(this.parameter).then(res => {
          console.log(res)
          let insertForm = {
            codeSetId: null,
            colspan: 2,
            infoItemEvent: "",
            inputType: null,
            itemId: "",
            itemLength: null,
            itemName: "",
            itemType: "1000",
            itemVType: "",
            null: true,
            read: false,
            refUrl: null,
            titleName: "",
            titleNull: true,
            valueId: "",
            visiable: false
          };
          res.data.dynamicFormItemList.forEach(item => {
            this.editList.push("false");
            this.showList.push("true");
            this.ifHaveTextarea = 0;
            this.noVisible = 0;
            item.dynamicFormVOList.forEach(i => {
              if (i.itemType == 4 || i.itemType == 10) {
                this.ifHaveTextarea += 1;
              }
              if (i.visiable == true) {
                this.noVisible += 1;
              }
            });
            if ((item.length - this.ifHaveTextarea - this.noVisible) % 2 != 0 && this.ifHaveTextarea != 0) {
              let insertIndex = this.itemTypeArr.indexOf("4");
              let insertIndex1 = this.itemTypeArr.indexOf("10");
              if (insertIndex != -1 && insertIndex1 == -1) {
                item.dynamicFormVOList.splice(insertIndex, 0, insertForm);
              } else if (insertIndex == -1 && insertIndex1 != -1) {
                item.dynamicFormVOList.splice(insertIndex1, 0, insertForm);
              } else if (insertIndex != -1 && insertIndex1 != -1) {
                if (insertIndex > insertIndex1) {
                  item.dynamicFormVOList.splice(insertIndex1, 0, insertForm);
                } else {
                  item.dynamicFormVOList.splice(insertIndex, 0, insertForm);
                }
              }
            }
            ;
          });;
          this.dynamicFormItemList = res.data.dynamicFormItemList;
          if (this.parameter.operateType == 0) { //新增
            res.data.dynamicFormItemList.forEach(item => {
              item.dynamicFormVOList.map(list => {
                this.formData[list.valueId] = ''
              })
            });
          }
        })
      },
      //获取表单回显值
      _getDynamicFormValue(cusId) {
        this.parameter.cusId = cusId
        getCusInfoById(this.parameter).then(res => {
          console.log("加载表单数据回显数据");
          this.formData = res.data.data;
          console.log("this.formData");
          console.log(this.formData);
        })
      },
      changeEdit(index){
        let newValue;
        this.editList[index] === "true"?newValue = "false":newValue = "true"
        this.$set(this.editList, index, newValue)
      },
      changeShow(index){
        let newValue;
        this.showList[index] === "true"?newValue = "false":newValue = "true"
        this.$set(this.showList, index, newValue)
      },
    }
  }
</script>
<style scoped>

</style>
