<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900' && this.isBranchOrg"
                 :disabled="isDisabled" @click="getSocialSecurity">拉取社保
      </el-button>
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900'" :disabled="isDisabled"
                 @click="exportDetails(null)">批量导出
      </el-button>
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900'" :disabled="isDisabled"
                 @click="importDetail">导入
      </el-button>
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900'" :disabled="isDisabled"
                 @click="recalculate">重新计算
      </el-button>
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900' && this.coOrdinationAccountAudit === 1"
                 :disabled="isDisabled" @click="commitFinance">提交财务
      </el-button>
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900' && (this.coOrdinationAccountAudit === 0)"
                 :disabled="isDisabled" @click="commitBatch">提 交
      </el-button>
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00901'" @click="downloadDtlTemplate">下载导入明细模板
      </el-button>
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900' && (this.coOrdinationAccountAudit === -1)"
                 :disabled="isDisabled">提 交
      </el-button>
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.detailVisible=='false' && this.listQuery.batchStatus=='00900'" :disabled="isDisabled"
                 @click="deleteImportBatch">删除
      </el-button>
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.detailVisible=='true'  && this.isBranchOrg && this.listQuery.batchStatus=='00900'"
                 @click="getEmpSocialSecurity">拉取社保
      </el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='true' " @click="downEmpSalary">
        下载工资条
      </el-button>
      <el-button type="primary" size="small" fixed="right"
                 v-if="this.detailVisible=='true' && this.listQuery.batchStatus=='00900'" @click="saveEvent">保存
      </el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='true'" @click="exportDetailsTwo">
        导出
      </el-button>
      <el-button type="primary" size="small" fixed="right" v-if="this.detailVisible=='true'" @click="goback">返回
      </el-button>

    </div>
    <el-form :inline="true" :model="listQuery" class="editForm" label-width="50px" v-if="this.detailVisible=='false'" size="small">
      <el-form-item label="状态:">
        <el-select clearable class="status-select" v-model="listQuery.batchStatus"
                   @change="__getDetailImportBatchList()" :disabled = "this.isDisabledBatchStatus">
          <el-option label="未提交" value="00900"></el-option>
          <el-option label="已提交" value="00901"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!--批次列表-->
    <bsp-table v-if="this.detailVisible=='false'"
               :data.sync="list"
               :tree-config="true"
               :ciic-data-filter.sync="listQuery"
               @ciic-data-refresh="__getDetailImportBatchList"
               @select-change="selectChange"
               @select-all="selectAll"
               show-header-overflow show-overflowwidth show-overflow
               v-loading="loading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               show-footer-overflow
               show-footer
               :footer-method="footerMethod"
               height="89%"
    >

      <vxe-table-column type="selection" width="60" fixed="left"/>
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <bsp-table-column title="导入批次编号" field="batchCode" filter-type="input" width="100" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.batchCode}}</span></template>
      </bsp-table-column>


      <bsp-table-column v-if="this.currentTab=='31278300703' || this.currentTab=='31278300704' || this.currentTab=='31278300707'
              ||(this.userInfo.userType=='2' && this.currentTab=='31278300702') "
                        title="客户名称" field="cusName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="受托方" field="orgName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.orgName}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="计税区域" v-if="this.currentTab!='31278300707'" field="taxArea" width="100"
                        filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.taxArea}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="税金所属月份" v-if="this.currentTab!='31278300707'" field="taxMonth" width="120"
                        filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.taxMonth}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="账单月份" v-if="this.currentTab!='31278300707' && listQuery.batchStatus == '00901'" field="billMonth" width="100"
                        filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.billMonth}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="实际操作人" field="operatorName" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.operatorName}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="创建时间" field="operateTime" width="120" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.operateTime}}</span></template>
      </bsp-table-column>


      <bsp-table-column :title="textMap[taxTotalStatus]" field="taxTotal" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.taxTotal }}</span></template>
      </bsp-table-column>

      <bsp-table-column title="服务总金额" field="serviceTotal" filter-type="input" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.serviceTotal }}</span></template>
      </bsp-table-column>

      <bsp-table-column title="委托业务类型" v-if="this.currentTab=='31278300703'" field="entrustType" fixed="left"
         filter-type="select"    filter-rule="in"   set-id="5068">
        <template slot-scope="scope"><span>{{scope.row.entrustType | dictFilter(dicts) }}</span></template>
      </bsp-table-column>

      <bsp-table-column title="状态" field="statusName" fixed="left" filter-type="input">
        <!-- filter-type="select"    filter-rule="in"   set-id="3004"-->
        <template slot-scope="scope"><span>{{scope.row.statusName }}</span></template>
        <!--statusName | dictFilter(dicts)-->
      </bsp-table-column>
      <bsp-table-column title="专项异常信息" v-if="this.currentTab=='31278300702' || this.currentTab=='31278300704'" field="specialException" fixed="left">
        <template slot-scope="scope"><span>{{scope.row.specialException }}</span></template>
      </bsp-table-column>

      <bsp-table-column title="操作" fixed="right" width="150px" align="center">
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button v-if="scope.row.status === '3004000044'" type="text" @click="showSendBackDetails(scope.row)">
              退回详情
            </el-button>
            <el-button type="text" @click="showDetails(scope.row)">查看</el-button>
            <el-button v-show="isOrg && listQuery.batchStatus == '00901' && scope.row.status =='3004000024'" type="text" @click="withdrawal(scope.row)">撤回</el-button>
            <el-button type="text" @click="exportDetails(scope.row)">导出</el-button>
            <el-button type="text" @click="generateDetail(scope.row)" v-show="listQuery.batchStatus == '00900' && scope.row.status == '3004000020'">生成明细</el-button>
            <el-button type="text" @click="copyBatch(scope.row)" v-show="listQuery.batchStatus == '00901' && scope.row.status == '3004000039'&& scope.row.stOrgid == '1060344'">复制</el-button>
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>

    <!--提交财务-->
    <el-dialog
      title="提交财务"
      :visible.sync="detailImportFinanceDialogVisible"
      width="55%"
      @close="$refs.importFinance._close()"
      v-loading="loading"
    >
      <detail-import-finance-tables ref="importFinance"
                                    @hiddenImp="hiddenImp" @_commitFinanceConfirm="_commitFinanceConfirm"
                                    :financeAmount="financeAmount" :accountBalance="accountBalance"
                                    :edit="edit" :isSubmitter="isSubmitter"
                                    :showDetail="showDetail"></detail-import-finance-tables>
    </el-dialog>

    <!--大库/客户名义报税查看增加客户列表-->
    <detailImportCusList v-if="this.detailVisible=='detailCus'"
                         :detailImportCusList.sync="this.detailImportCusList"
                         :cuslistQuery.sync="cuslistQuery"
                         refname="detailImportCusList"
                         @lookDetail="lookDetail"
                         @rtnDetail="rtnDetail"
                         @submitData="submitData"
                         v-loading="loading"
                         height="85%"
    >
    </detailImportCusList>

    <!--明细列表-->
    <detailImportList v-if="this.detailVisible=='true'"
                      :tableColumn.sync="this.tableColumn"
                      :dataList.sync="this.dataList"
                      :sumItem.sync="this.sumItem"
                      :noChangeList.sync="this.noChangeList"
                      refname="detailImportList"
                      @getSelectData="getSelectData"
                      v-loading="loading"
                      ref="detailImportList"
    >
    </detailImportList>

    <!--  提交录入发票信息开始-->
    <el-dialog v-dialogDrag width="40%"
               :title="textMap['titleInfo']"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :visible.sync="billDialogVisible">
      <el-form ref="form2" class="editFrom" :model="validateForm" label-width="168px" :rules="rules">
        <el-row>
          <el-col :span="20">
            <el-form-item label="发票抬头" prop="billTitle">
              <el-select clearable v-model="parameter.billsTitle" label-width="350px" placeholder="请选择"
                         @change="billChange">
                <el-option
                  v-for="item in billsInfoList"
                  :key="item.BILLSTITLE"
                  :label="item.BILLSTITLENAME"
                  :value="item.BILLSTITLE"><!--BILLSTITLE -->
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="纳税人识别号">
              <el-input v-model.trim="parameter.taxNum" disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="地址">
              <el-input v-model.trim="parameter.taxAdress" disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="电话">
              <el-input v-model.trim="parameter.taxPhone" disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="收件人" prop="billTitle">
              <el-select clearable v-model="parameter.recipients" label-width="350px" placeholder="请选择"
                         @change="reciChange">
                <el-option
                  v-for="item in receAddrList"
                  :key="item.ID"
                  :label="item.RECEIVEDEPT"
                  :value="item.ID"><!-- ID-->
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="20">
            <el-form-item label="邮寄地址、邮编">
              <el-input v-model.trim="parameter.emailAddress" disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="marginTop30" style="text-align: center">
        <el-button type="primary" @click="billSave"> 保存</el-button>
        <el-button @click="billCancel"> 取消</el-button>
      </div>
    </el-dialog>

    <!-- 复制弹框 -->
    <bsp-dialog
      dialog-ref="MonthPickRef"
      :dialog-visible.sync="copyBatchMonthDialog"
      width="900px"
      title="月份选择"
      @close="copyBatchMonthDialog = false"
      @open="_queryWageMonth()"
      v-loading="loading"
    >
      <div slot="body">
        <copyBatchMonthPage ref="copyPageRef" @hiddenCopyMonth="hiddenCopyMonth" @cancelLoadBtn="cancelLoadBtn" :list-form.sync="copyBatchRow" @hidden="copyBatchMonthDialog = false"/>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="copyBatchMonthDialog = false" :loading="copyBtn">取 消</el-button>
        <el-button @click="copyOk" :loading="copyBtn">确 定</el-button>
      </div>
    </bsp-dialog>

    <!-- 下载薪资导入明细模板 -->
    <bsp-dialog
      dialog-ref="MonthPickRef"
      :dialog-visible.sync="downloadBatchMonthDialog"
      width="900px"
      title="月份选择"
      @close="downloadBatchMonthDialog = false"
      @open="_queryWageMonthForDwn()"
    >
      <div slot="body">
        <copyBatchMonthPage ref="copyPageRef" :list-form.sync="copyBatchRow" @hiddenDown="hiddenDownFun"/>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="downloadBatchMonthDialog = false">取 消</el-button>
        <el-button @click="downloadOk()">确 定</el-button>
      </div>
    </bsp-dialog>

    <el-dialog
      title="提示"
      :visible.sync="downloadBatchVisible"
      width="20%"
      @close="downloadBatchVisible = false">
      <span>是否前往月份选择页面，替换本次下载模板明细中的工资月份、工资月份、发票月份内容？</span>
      <span slot="footer" class="dialog-footer">
      <el-button @click="downloadData">直接下载</el-button>
      <el-button type="primary" @click="choiceMonth">选择月份</el-button>
      </span>
    </el-dialog>


    <!--  提交录入计划支付日期-->
    <el-dialog v-dialogDrag width="60%"
               title="发薪日期"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               @close="cancelPlanDia"
               :visible.sync="planPayTimeDialogVisible">
      <el-form class="editFrom" ref="planPayForm" :model="planPayForm">
        <el-table :data="parameter.planPayTimeModels" row-key="key" style="width: 100%">
          <el-table-column type="index" :index="indexMethod" label="序号">
          </el-table-column>
          <el-table-column prop="planPayTime" style="display: table" label="计划发薪日期" width="200">
            <template slot-scope="scope">
              <div style="display: flex; align-items: center" required>
                <i class="el-icon-time"></i>
                <el-date-picker type="date" :picker-options="pickerOptions" placeholder="选择日期" v-model="scope.row.planPayTime" value-format="yyyy-MM-dd" style="margin-left: 10px">
                </el-date-picker>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="batchCode" label="批次编号" width="200"></el-table-column>
          <el-table-column prop="cusName" label="客户名称" width="280"></el-table-column>
          <el-table-column prop="taxArea" label="计税区域" width="120"></el-table-column>
          <el-table-column prop="taxMonth" label="税金月份" width="120"></el-table-column>
          <el-table-column prop="entrustType" label="委托业务类型" width="120"></el-table-column>
        </el-table>
      </el-form>
      <div class="marginTop30" style="text-align: center">
        <el-button type="primary" @click="planPayTimeSave"> 保存</el-button>
        <el-button @click="planPayTimeCancel"> 取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import detailImportCusList from "@/views/entrust/detailImport/detailImportCusList";
  import detailImportList from "@/views/entrust/detailImport/detailImportList";
  import BspDialog from "@/components/bsp/bsp-dialog/bsp-dialog";
  import copyBatchMonthPage from '@/views/entrust/detailImport/copyBatch/copyBatchMonthPage';
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {
    checkBatchCusIsRelOmsCus,
    checkBatchEmpSseIsSalary,
    checkBatchIsAllowDownload,
    checkEmpSseIsOntheWay,
    checkEmpSseIsSalary,
    checkIsExistNotBJTaxEmp,
    checkIsExistOnTheWayEmp,
    checkIsExistOnTheWayEmps,
    checkPtCusIsRelOmsCus,
    checkWageMonthIsSame,
    commitBatch,
    commitFinanceConfirm,
    deleteImportBatch,
    downEmpSalary,
    downloadSalaryTmpFile,
    exportDetails,
    getBillsInfo,
    getCusEmpSocialSecurityInfo,
    getDetailImportBatchCusList,
    getDetailImportBatchList,
    getdynamicList,
    getdynamicTable,
    getEmpSalaryPath,
    getEmpSocialSecurityInfo,
    getFinanceConfirmInfo,
    getIsCopy,
    queryWageMonth,
    recalculate,
    updateDetail,
    WithdrawalBatch,
    selectTrusteeOrgTips
  } from '@/api/entrust/detailImport';
  import {getTransOrgIsAudit, searchAccountBalanceByOrgId, getIsMaintain,getSysCalendar} from '@/api/entrust/distributionBatch';
  import {downloadFile, downloadPdfFile, monthCalcu} from '@/util/util';
  import {mapGetters} from 'vuex';
  import DetailImportFinanceTables from "@/views/entrust/detailImport/detailImportFinanceTables";
  import Vue from "vue";
  import router from "@/router/router";

  export default {
    name: 'detailImportBatchList',
    inject: ['reload'],
    components: {
      BspTable,
      BspTableColumn,
      BspDialog,
      detailImportList,
      detailImportCusList,
      DetailImportFinanceTables,
      copyBatchMonthPage
    },
    computed: {
      ...mapGetters([
        'dicts', 'userInfo'
      ]),
    },
    props: {
      currentTab: {
        type: String,
        default: ''
      },

    },
    created() {
      this.isOrg = this.userInfo.userType === "2"
      getTransOrgIsAudit(this.userInfo.orgId).then(res => {
        //大库报税还是提交按钮，其他为提交财务
        if (res.data.data.success === 'true') {
          if ((res.data.data.IS_FINANCIAL_AUDIT !== null && res.data.data.IS_FINANCIAL_AUDIT === '00901' && this.currentTab !== '31278300701')) {
            this.coOrdinationAccountAudit = 1;
          } else {
            this.coOrdinationAccountAudit = 0;
            debugger
            //分支委外，不能提交财务，所以这快代码注释掉
            /*if(this.currentTab === '31278300701' && this.userInfo.foreignFlag){
              this.coOrdinationAccountAudit = 1;
            } else{
              this.coOrdinationAccountAudit = 0;
            }*/
          }
        } else {
          this.$message.error("获取机构提交权限异常，请联系管理员！");
          this.loading = false;
          this.isDisabled = false;
        }
      });
      if (this.userInfo.userType === "2") {
        this.isBranchOrg = false;
      }
      this.searchAccountBalance();
      this.isDisabled = false;//初始化，按钮可用
      console.log("用户类型：" + this.userInfo.userType + "currentTab**********" + this.currentTab);
      //列表显示项描述不一样
      if (this.currentTab == '31278300701' || this.currentTab == '31278300702') {
        this.taxTotalStatus = "dkTaxTotal";
      } else if (this.currentTab == '31278300707') {
        console.log("代报销");
        this.taxTotalStatus = "dbxTaxTotal";
      } else {
        this.taxTotalStatus = "pdkTaxTotal";
      }
      this.__getDetailImportBatchList();
      this.__getSysCalendar();
    },

    data() {
      return {
        isOrg:true,// 分支或分公司
        senBackFinanceDialogVisible: false,//重新提交财务备注信息
        reCommitFinanceDialogVisible: false,
        coOrdinationAccountAudit: -1,//是否统筹账号，并且需要财务审核
        isBranchOrg: false,//是否统筹账号，并且需要财务审核
        detailImportFinanceDialogVisible: false,
        copyBatchMonthDialog: false,
        downloadBatchMonthDialog: false,
        downloadBatchVisible: false,
        copyBtn: false,
        copyBatchRow:{},
        billDialogVisible: false,
        planPayTimeDialogVisible: false,
        // 遮罩
        loading: false,
        // 用于接收后台返回的分页数据
        list: [],
        detailVisible: 'false',
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          bizType: '',
          batchStatus: '00900',//默认为未提交
          source: '0',//批次来源，区分是从补贴那里生成的还是自己导入生成的
        },

        planPayForm: {
          batchCode: '',
          cusName: '',
          taxArea: '',
          planPayTime: ''
        },

        parameter: {
          batchIdList: [],
          batchImportIds: '',
          bizType: this.currentTab,
          cusId: '',
          orgId: '',
          billsTitle: '',//BILLSTITLENAME
          taxNum: '',//F204204
          taxAdress: '',//F204205
          taxPhone: '',//F204206
          recipients: '',//RECEIVEDEPT
          emailAddress: '',//postcode
          subIds: '',//明细选择导出
          subIdFlag: '',//明细导出标识
          isSure: 'false',//是否确认款项已到账号
          status: '',//状态（提交、未提交）
          batchMap: '',//批次ID关联状态ID
          commitList: [],

          planPayTimeModels: [
            {
              batchCode: '',
              cusName: '',
              taxArea: '',
              planPayTime: '',
              batchId: '',
            }
          ],
        },

        rules: {
          planPayTime: [
            {required: true, message: '请选择发薪时间', trigger: 'change'}
          ],
        },
        //选中的数据map,拉取社保的参数
        batchParamMap: '',
        //社保拉取参数
        batchSocialParamMap: '',
        //多选数组
        multipleSelection: [],
        textMap: {
          dkTaxTotal: '税金总金额',
          pdkTaxTotal: '工资往来总金额',
          dbxTaxTotal: '代报销总金额',
          titleInfo: '选择发票信息',
        },
        taxTotalStatus: 'dkTaxTotal',
        detailInfo: {
          batchCode: '',//批次code
          batchImportId: '',//批次id
          bizType: '',//业务类型
          status: '',//提交状态，未提交/已提交
          cusId: '',
        },
        dataList: [],
        tableColumn: [], //显示表头
        sumItem: [],//需要合计的列
        detailImportCusList: [],
        cuslistQuery: {
          limit: 50,
          page: 1,
          total: 0,
          bizType: '',
          batchStatus: '00900',//默认为未提交
          batchImportId: '',
        },
        billsInfoList: [],//发票信息列表
        receAddrList: [],//收件人信息列表
        salaryParam: {//下载工资条
          subId: '',
          filePath: '',
          fileName: '',
        },
        empSocialSecurity: {//雇员社保拉取数据
          empSalarys: [],
        },
        empSalary: {
          batchCode: '',
          batchId: '',
          bizType: '',
          subId: '',
          cusID: '',
          cusName: '',
          salaryMonth: '',
          taxArea: '',
          salaryStore: '',
          empCardType: '',
          empCard: '',
          empName: '',
          omsCustomerId: ''
        },
        financeAmount: {
          id: '',
          payableAmount: '',//应发金额
          paidInAmount: '',//实发金额
          taxes: '',//税金
          totalSocialSecurity: '0',//社保合计
          totalServiceCharge: '0',//服务费合计
          totalOtherExpenses: '0',//其他费用合计
          amountConfirmation: '0',//确认来款
          remark: '',//备注
          status: '',//批次状态
          remarkHis: '',//备注
          commitUser: '',//提交人
          commitTime: '',//提交时间
          coAccountBalance: '0',//统筹账户余额
          coAccount: '',//统筹账户
        },
        accountBalances: [],
        showDetail: false,
        multipleDetailSelection: [],
        noChangeList: [],//明细中不编辑的列
        updateDetailList: {
          updList: [],
          batchImportId: '',
          noChangeList: [],
          batchCode: '',
        },//编辑后的数据
        edit: true,
        isDisabled: false,
        isSubmitter: true,
        accountBalance: {
          account: '',
          accountName: '',
          balance: {
            type: Number,
            default: '0'
          }
        },
        isDisabledBatchStatus:false,
        date: '',
        legalRestDayList: [
        ],//法定节假日
        restDayList: [], // 调休
        workDayList:[],//工作日
        pickerOptions: {
          disabledDate: (time) => {
            return this.setTime(time)
          }
        },
        fullYear:''

      }
    },
    methods: {
      //获取节假日日历
      __getSysCalendar: function () {
        var now  = new Date();
        this.fullYear = now.getFullYear();
        getSysCalendar(this.fullYear).then(res => {
          if(res.data.code == 0){
            this.workDayList = res.data.data.workDayList;
            this.restDayList = res.data.data.restDayList;
            this.legalRestDayList = res.data.data.legalRestDayList;
          };
          console.log("getSysCalendar",res)
        });
      },


      setTime(time){
        let date = Vue.moment(time).format('YYYY-MM-DD')
        let islegalRestDay = this.legalRestDayList.includes(date)
        let isrestDay = this.restDayList.includes(date)
        let isWeekend = (time.getDay() == 0 || time.getDay() == 6) && !this.workDayList.includes(date)
        return  isWeekend || islegalRestDay || isrestDay
      },
      //查询余额
      searchAccountBalance() {
        this.parameter.orgId = this.userInfo.orgId;
        searchAccountBalanceByOrgId(this.parameter).then(res => {
          if (res.data.data.success === 'true') {
            this.accountBalance.balance = (res.data.data.data.BALANCE === null || res.data.data.data.BALANCE === undefined) ? 0 : res.data.data.data.BALANCE;
            this.accountBalance.account = res.data.data.data.ACCOUNT;
            this.accountBalance.accountName = res.data.data.data.NAME;
          }
        });
      },

      copyOk(){
        this.copyBtn = true;
        this.$refs.copyPageRef.copyOk();
      },

      cancelLoadBtn(){
        console.log('取消加载样式');
        this.copyBtn = false;
        this.loading = false;
      },
      cancelPlanDia(){
        console.log('取消加载样式');
        this.loading = false;
        this.isDisabled = false;
      },

      //查询明细导入批次信息
      __getDetailImportBatchList: function () {
        if (this.listQuery.batchStatus.length <= 0) {
          this.$message.error("请至少选择一种状态！");
          this.list = null;
          return;
        }
        console.log("查询批次信息");
        this.listQuery.bizType = this.currentTab;
        this.loading = true;
        getDetailImportBatchList(this.listQuery).then(res => {
          if (res.data.code != 0) {
            this.listQuery.total = res.data.total
            this.list = res.data.records
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },

      //拉取社保接口
      getSocialSecurity: async function () {
        this.isDisabled = true;
        this.loading = true;
        this.batchParamMap = '';
        this.parameter.batchImportIds = '';
        if (this.multipleSelection.length <= 0) {
          this.$message.error("请选择要拉取社保的批次！");
          this.loading = false;
          this.isDisabled = false;
          return;
        }
        let flag = "";
        let errMsgs = [];
        let batchSocialStr = "[";
        let jsonStr = "[";
        //创建json,用于存储批次ID的状态
        //将选中的id组装为string
        this.multipleSelection.forEach(item => {
          if ("北京市" !== item.taxArea) {
            errMsgs.push(item.batchCode);
            flag = "false";
          }
          let id = item.id === undefined ? null : item.id;
          let bizType = this.currentTab === undefined ? null : this.currentTab;
          let cusId = item.cusId === undefined ? null : "'" + item.cusId + "'";
          let batchCode = item.batchCode === undefined ? null : item.batchCode;
          jsonStr += '{"batchImportId": "' + id + '","bizType": "' + bizType + '","cusId": ' + cusId + ',"batchCode": "' + batchCode + '"},';
        });
        if (flag === 'false') {
          let newDatas = [], h = this.$createElement;
          newDatas.push(h('b', {style: 'color: red'}, '以下批次薪资计税区域非北京市，没有社保拉取权限！'));
          for (let i in errMsgs) {
            newDatas.push(h('p', null, errMsgs[i]))
          }
          this.$msgbox({
            title: '错误提示',
            lockScroll: true,
            dangerouslyUseHTMLString: true,
            message: h('p', null, newDatas),
            showCancelButton: false,
            confirmButtonText: '确定',
          }).then(action => {
            this.isDisabled = false;
            this.loading = false;
          });
          this.isDisabled = false;
          this.loading = false;
          return;
        }
        jsonStr = jsonStr.substring(0, jsonStr.length - 1) + "]";
        this.batchParamMap = eval("" + jsonStr + "");
        //检查批次中是否存在税金月份不是“北京市”的雇员
        let taxErr = await checkIsExistNotBJTaxEmp(this.batchParamMap);
        if (taxErr.data.code === 0) {
          let dataList = taxErr.data.data;
          let errEmpMsg = [];
          for (var key in dataList) {
            errEmpMsg.push("批次：" + key);
            let data = dataList[key];
            for (let empInfo in data) {
              errEmpMsg.push("雇员：" + data[empInfo].E204344 + ", 证件号：" + data[empInfo].E204347);
            }
          }
          if (errEmpMsg.length > 0) {
            let newDatas = [], h = this.$createElement;
            newDatas.push(h('div', {style: 'color: red'}, '以下批次雇员薪资计税区域非北京市，没有社保拉取权限！'));
            newDatas.push(h('b', null, ''));
            for (let i in errEmpMsg) {
              if (errEmpMsg[i].indexOf("批次") !== -1) {
                newDatas.push(h('b', {style: 'color: red'}, errEmpMsg[i]));
              } else {
                newDatas.push(h('p', null, errEmpMsg[i]))
              }
            }
            this.$msgbox({
              title: '错误提示',
              message: h('div', null, newDatas),
              showCancelButton: false,
              confirmButtonText: '确定',
            }).then(action => {
              this.isDisabled = false;
              this.loading = false;
            });
            this.isDisabled = false;
            this.loading = false;
            return;
          }
        }

        //批次下的客户是否关联oms客户ID
        let relData = await checkBatchCusIsRelOmsCus(this.batchParamMap);
        if (relData.data.code === 0) {
          let dataList = relData.data.data;
          let errEmpMsg = [];
          for (var key in dataList) {
            let data = dataList[key];
            for (let empInfo in data) {
              errEmpMsg.push("批次：" + empInfo);
              let errCus = '';
              if (data[empInfo].length > 0) {
                for (let i in data[empInfo]) {
                  let cusName = data[empInfo][i];
                  errCus += cusName + '、'
                }
              }
              if (errCus.length > 0) {
                let finalCus = errCus.substring(0, errCus.length - 1);
                errEmpMsg.push(finalCus);
              }
            }
          }
          if (errEmpMsg.length > 0) {
            let newDatas = [], h = this.$createElement;
            newDatas.push(h('div', {style: 'color: red'}, '以下批次客户没有关联Oms客户，没有社保拉取权限！'));
            newDatas.push(h('b', null, ''));
            for (let i in errEmpMsg) {
              if (errEmpMsg[i].indexOf("批次") !== -1) {
                newDatas.push(h('b', {style: 'color: red'}, errEmpMsg[i]));
              } else {
                newDatas.push(h('p', null, errEmpMsg[i]))
              }
            }
            this.$msgbox({
              title: '错误提示',
              message: h('div', null, newDatas),
              showCancelButton: false,
              confirmButtonText: '确定',
            }).then(action => {
              this.isDisabled = false;
              this.loading = false;
            });
            this.isDisabled = false;
            this.loading = false;
            return
          }
        } else {
          this.$msgbox({
            title: '错误提示',
            message: h('div', null, '检查是否关联Oms客户异常！'),
            showCancelButton: false,
            confirmButtonText: '确定',
          }).then(action => {
            this.isDisabled = false;
            this.loading = false;
          });
          this.isDisabled = false;
          this.loading = false;
          return
        }

        //检查批次中雇员是否是“工资、补贴”
        let batchEmpRes = await checkBatchEmpSseIsSalary(this.batchParamMap);
        let beCode = batchEmpRes.data.code;
        if (beCode === 0) {
          let dataList = batchEmpRes.data.data;
          debugger
          let errEmpMsg = [];
          for (let key in dataList) {
            let data = dataList[key];
            if (data.length > 0) {
              errEmpMsg.push("批次：" + key);
            }
            for (let empInfo in data) {
              errEmpMsg.push("【雇员】：" + data[empInfo].E204344 + ", 【证件号】：" + data[empInfo].E204347 + "；");
            }
          }
          if (errEmpMsg.length > 0) {
            let newDatas = [], h = this.$createElement;
            newDatas.push(h('div', {style: 'color: red'}, '以下批次雇员计税类型非“工资、补贴”，不允许拉取！！'));
            newDatas.push(h('b', null, ''));
            for (let i in errEmpMsg) {
              if (errEmpMsg[i].indexOf("批次") !== -1) {
                newDatas.push(h('b', {style: 'color: red'}, errEmpMsg[i]));
              } else {
                newDatas.push(h('p', null, errEmpMsg[i]))
              }
            }
            this.$msgbox({
              title: '错误提示',
              message: h('div', null, newDatas),
              showCancelButton: false,
              customClass: 'winClass',
              confirmButtonText: '确定',
            }).then(action => {
              this.isDisabled = false;
              this.loading = false;
            });
            this.isDisabled = false;
            this.loading = false;
            return
          }
        }

        //检查批次中雇员是否是拉取社保在途状态
        let res = await checkIsExistOnTheWayEmp(this.batchParamMap);
        let code = res.data.code;
        if (code === 0) {
          let dataList = res.data.data;
          let errEmpMsg = [];
          for (let key in dataList) {
            errEmpMsg.push("批次：" + key);
            let data = dataList[key];
            for (let empInfo in data) {
              errEmpMsg.push("【雇员】：" + data[empInfo].E204344 + ", 【证件号】：" + data[empInfo].E204347 + "；请 " + data[empInfo].expireTime + " 秒后重试");
            }
          }
          if (errEmpMsg.length > 0) {
            let newDatas = [], h = this.$createElement;
            newDatas.push(h('div', {style: 'color: red'}, '以下批次雇员社保已经拉取，但是尚未返回结果，请稍后重试！'));
            newDatas.push(h('b', null, ''));
            for (let i in errEmpMsg) {
              if (errEmpMsg[i].indexOf("批次") !== -1) {
                newDatas.push(h('b', {style: 'color: red'}, errEmpMsg[i]));
              } else {
                newDatas.push(h('p', null, errEmpMsg[i]))
              }
            }
            this.$msgbox({
              title: '错误提示',
              message: h('div', null, newDatas),
              showCancelButton: false,
              customClass: 'winClass',
              confirmButtonText: '确定',
            }).then(action => {
              this.isDisabled = false;
              this.loading = false;
            });
            this.isDisabled = false;
            this.loading = false;
            return
          }
        }

        this.$confirm('是否拉取该批次下所有雇员社保信息？若确认拉取，则会覆盖导入的社保数据，请知悉！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //拉取客户雇员的社保信息
          getCusEmpSocialSecurityInfo(this.batchParamMap).then(res => {
            let data = res.data;
            if (data.code === 0) {
              this.$message.success("开始拉取社保，请稍后查看...");
              this.__getDetailImportBatchList();
              this.isDisabled = false;
              this.loading = false;
            } else {
              let newDatas = [], h = this.$createElement;
              newDatas.push(h('div', {style: 'color: red'}, data.msg));
              newDatas.push(h('div', {style: 'color: red'}, '以下批次雇员社保拉取失败，请联系系统管理员！'));
              newDatas.push(h('b', null, ''));
              let errEmpMsg = data.data.errBatchCode;
              if (errEmpMsg.length > 0) {
                for (let i in errEmpMsg) {
                  if (errEmpMsg[i].indexOf("批次") !== -1) {
                    newDatas.push(h('b', {style: 'color: red'}, errEmpMsg[i]));
                  } else {
                    newDatas.push(h('p', null, errEmpMsg[i]))
                  }
                }
                this.$msgbox({
                  title: '错误提示',
                  message: h('div', null, newDatas),
                  showCancelButton: false,
                  confirmButtonText: '确定',
                }).then(action => {
                  this.isDisabled = false;
                  this.loading = false;
                });
              }
              this.isDisabled = false;
              this.loading = false;
            }
          });
        }).catch(() => {
          this.isDisabled = false;
          this.loading = false;
        });
        this.isDisabled = false;
        this.loading = false;
      },

      /*提交财务*/
      async commitFinance() {
        this.isDisabled = true;
        this.loading = true;
        this.parameter.batchImportIds = '';
        this.edit = true;
        let flag = '';
        let ocRes = await getTransOrgIsAudit(this.userInfo.orgId);
        if (ocRes.data.data.success === 'true') {
          if (!(ocRes.data.data.IS_FINANCIAL_AUDIT !== null && ocRes.data.data.IS_FINANCIAL_AUDIT === '00901')) {
            this.loading = false;
            this.isDisabled = false;
            this.$message.error("该机构没有提交财务的权限！");
            return;
          }
        } else {
          this.loading = false;
          this.isDisabled = false;
          this.$message.error("获取用户薪资导入权限异常！");
          return;
        }
        debugger
        if (this.multipleSelection.length <= 0) {
          this.loading = false;
          this.isDisabled = false;
          this.$message.error("请选择要提交的批次！");
          return;
        }
        if (this.multipleSelection.length > 1) {
          this.loading = false;
          this.isDisabled = false;
          this.$message.error("仅支持单批次提交！");
          return;
        }
        var status = '';
        //将选中的id组装为string
        this.checkSelecterCopy("临时批次不允许提交财务！");
        this.multipleSelection.forEach(item => {
          status = item.status;
          // totalServiceChargeTmp+=Number(item.serviceTotal);
          debugger
          if (item.status !== '3004000023' && item.status !== '3004000044' && item.status !== '3004000035' && item.status !== '3004000058') {
            this.loading = false;
            this.$message.error("状态不是未提交/分支财务退回/财务公司退回/计算成功的批次不允许提交财务,请重新选择！");
            flag = 'false';
            return;
          }

          if (item.status === '3004000045' || item.status === '3004000047') {
            this.$message.error("状态是社保拉取中/社保拉取失败的批次不允许提交财务,请重新选择！");
            this.loading = false;
            this.isDisabled = false;
            flag = 'false';
            return;
          }

          if (item.status === '3004000056' && item.smartStatus === '3004000035') {
            this.$message.error("状态是智灵通已认单，但财务公司退回的批次不允许提交,请在智灵通进行解锁后重新提交！");
            this.loading = false;
            this.isDisabled = false;
            flag = 'false';
            return;
          }

          if (item.status == '3004000050' || item.status == '3004000051' || item.status == '3004000052') {
            this.loading = false;
            this.$message.error("状态为专项提取中/获取专项失败/获取专项后计算失败的批次不允许提交财务,请重新选择！");
            flag = 'false';
            return;
          }
          if (item.stOrgid != '1060344') {
            flag = 'true';
          }

          this.parameter.orgId = item.orgid;
          if (this.parameter.batchImportIds.indexOf(item.id) === -1) {
            this.parameter.batchImportIds = this.parameter.batchImportIds + "," + item.id;
          }
          this.parameter.cusId = item.cusid;
        });
        this.parameter.bizType = this.currentTab;
        if (flag == 'false') {
          this.isDisabled = false;
          this.loading = false;
          return;
        } else {

          //把状态置为未提交
          this.parameter.status = status;
          this.parameter.commitList = this.multipleSelection;
          //查询批次实发金额
          getFinanceConfirmInfo(this.parameter).then(res => {
            this.loading = false;
            this.isDisabled = false;
            if (res.data.data.success == "true") {
              if (res.data.data.msg != '') {
                this.$message.error(res.data.data.msg);
                return false;
                console.log(error);
              }
              //分支财务退回,读取历史数据
              if (status === '3004000044') {

                this.financeAmount.id = res.data.data.id;//提交人
                this.financeAmount.commitUser = res.data.data.submitterName;//提交人
                this.financeAmount.commitTime = res.data.data.submitTime;//提交人
                this.financeAmount.amountConfirmation = res.data.data.amountConfirmation;//确认来款
                this.financeAmount.totalOtherExpenses = res.data.data.totalOtherExpenses;//其他费用
                this.financeAmount.totalServiceCharge = res.data.data.totalServiceCharge;//服务费合计
                this.financeAmount.totalSocialSecurity = res.data.data.totalSocialSecurity;//社保合计
                this.financeAmount.remarkHis = res.data.data.remark;//备注
                this.financeAmount.remark = '';
                this.showDetail = false;
              }
              this.financeAmount.payableAmount = res.data.data.payableAmount;//应发
              this.financeAmount.paidInAmount = res.data.data.paidInAmount;//实发金额
              this.financeAmount.taxes = res.data.data.taxes;//税金
              this.financeAmount.status = status;
              this.detailImportFinanceDialogVisible = true;
            }
          }).catch(error => {
            this.loading = false;
            this.isDisabled = false;
            this.$message.error("获取批次数据失败，请联系管理员");
            console.log(error);
          })
        }
      },

      selectChange(value) {
        console.log(value);
        if (value.selection) {
          this.multipleSelection = [];
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index];
            this.multipleSelection.push(element);
          }
        }
      },
      hiddenImp() {
        this.detailImportFinanceDialogVisible = false;
      },
      //业务人员确认来款金额
      _commitFinanceConfirm(financeAmount) {
        this.isDisabled = true;
        if (financeAmount.status === '3004000044' && this.financeAmount.remark === '' || this.financeAmount.remark === null) {
          this.$message.error('请填写备注信息');
          return;
        }
        var amountConfirmationTmp = parseFloat((this.financeAmount.totalOtherExpenses === '' ? 0 : this.financeAmount.totalOtherExpenses)) + parseFloat((this.financeAmount.taxes === '' ? 0 : this.financeAmount.taxes)) +
          parseFloat((this.financeAmount.totalSocialSecurity === '' ? 0 : this.financeAmount.totalSocialSecurity)) + parseFloat((this.financeAmount.totalServiceCharge === '' ? 0 : this.financeAmount.totalServiceCharge)) + parseFloat((this.financeAmount.paidInAmount === '' ? 0 : this.financeAmount.paidInAmount));
        if (amountConfirmationTmp !== parseFloat((this.financeAmount.amountConfirmation === '' ? 0 : this.financeAmount.amountConfirmation))) {
          this.$confirm('来款确认金额 ≠ (实发金额 + 税金 + 社保 + 服务费 + 其他费用), 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.commitFinanceChildMtd(financeAmount);
          }).catch(() => {
            this.$message.info('已取消');
          });
        } else {
          this.commitFinanceChildMtd(financeAmount);
        }
        this.loading = false;
        this.isDisabled = false;
      },
      //提交来款金额子方法
      commitFinanceChildMtd(financeAmount) {
        this.$confirm('财务确认款项后，将根据项目委托书中的协议发薪日发薪，是否确认提交？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true;
          var parameter = this.parameter;
          commitFinanceConfirm(financeAmount, parameter).then(res => {
            if (res.data.data.success == "true") {
              this.$message.success('提交成功！');
              this.detailImportFinanceDialogVisible = false;
              this.__getDetailImportBatchList();
              this.parameter.batchImportIds = "";
              this.financeAmount.taxes = 0;
              this.financeAmount.totalOtherExpenses = 0;
              this.financeAmount.totalSocialSecurity = 0;
              this.financeAmount.totalServiceCharge = 0;
              this.financeAmount.paidInAmount = 0;
              this.financeAmount.amountConfirmation = 0;
              // 处理数据 不能编辑的能编辑的
              console.log("==========================");
              this.loading = false;
            } else {
              this.loading = false;
              this.$message.error(res.data.data.success);
            }
          })
        }).catch(() => {
          this.$message.info('已取消提交');
        });
      },
      /*查看财务退回详情*/
      showSendBackDetails(row) {
        debugger
        var batchId = row.id;
        this.parameter.batchImportIds = ',' + batchId;
        this.parameter.status = row.status;
        //查询批次实发金额
        getFinanceConfirmInfo(this.parameter).then(res => {
          if (res.data.data.success == "true") {
            if (row.status === '3004000044') {
              this.financeAmount.id = res.data.data.id;//提交人
              this.financeAmount.amountConfirmation = res.data.data.amountConfirmation;//确认来款
              this.financeAmount.totalOtherExpenses = res.data.data.totalOtherExpenses;//其他费用
              this.financeAmount.totalServiceCharge = res.data.data.totalServiceCharge;//服务费合计
              this.financeAmount.totalSocialSecurity = res.data.data.totalSocialSecurity;//社保合计
              this.financeAmount.remarkHis = res.data.data.remark;//备注
              this.financeAmount.commitUser = res.data.data.submitterName;//提交人
              this.financeAmount.commitTime = res.data.data.submitTime;//提交人
              this.financeAmount.coAccount = res.data.data.account;//付款账户
              this.financeAmount.remark = '';
              this.showDetail = true;
              this.edit = false;
              this.showBalance = false;
              this.isSubmitter = true;
            }
            this.financeAmount.payableAmount = res.data.data.payableAmount;//应发
            this.financeAmount.paidInAmount = res.data.data.paidInAmount;//实发金额
            this.financeAmount.taxes = res.data.data.taxes;//税金
            this.financeAmount.status = row.status;
            this.detailImportFinanceDialogVisible = true;
          }
        }).catch(error => {
          this.$message.error("获取批次数据失败，请联系管理员");
          console.log(error);
        })
      },

      //复选框全选事件
      selectAll({checked, selection}) {
        if (checked) {
          this.multipleSelection = selection
        } else {
          this.multipleSelection = [];
        }
        console.log("this.multipleSelection:" + this.multipleSelection);
      },

      // 底部合计
      footerMethod({columns, data}) {
        return [
          columns.map((column, columnIndex) => {
            if (columnIndex === 0) {
              return '合计'
            }
            if (['taxTotal', 'serviceTotal'].includes(column.property)) {
              return this.$options.filters['moneyFilter'](this.$utils.sum(data, column.property))
            }
            return null
          })
        ]
      },

      //点击导入
      importDetail() {
        this.isDisabled = true;
        this.$router.push({
          path: '/wt/importDetail',
          query: {
            bizType: this.currentTab,//对应业务类型
          }
        })
        this.isDisabled = false;
      },
      //单条导出
      exportDetails(row) {
        this.isDisabled = true;
        this.loading = true;
        this.parameter.batchImportIds = "";
        if (row != null && row.id.length > 0) {
          console.log("单次操作");
          this.parameter.batchImportIds = row.id;//对应批次id
          this.parameter.batchIdList.push(row.id);
          if(row.status == '3004000020'){
            // 如果是临时批次导入单独导出
            // 下载临时批次组织参数
            const obj = {...this.parameter,isChoiceMonth:"00900" , copyFlag: "00901"}
            downloadSalaryTmpFile(obj).then(res => {
              this.loading = false,
              this.isDisabled = false;
              downloadFile(res);
            });
            return; //
          }
          //专项提取中、未提交、临时批次、状态不可导出
          if(row.status=='3004000050'){
            this.$message.error("专项提取中状态不可导出！");
            this.loading = false;
            this.isDisabled = false;
            return;
          }

        } else {
          if (this.multipleSelection.length <= 0) {
            this.$message.error("请选择要导出的批次！");
            this.loading = false;
            this.isDisabled = false;
            return;
          }
          var msg = "";
          if (this.multipleSelection.length > 0) {
            //将选中的id组装为string
            this.multipleSelection.forEach(item => {
              this.parameter.batchImportIds = this.parameter.batchImportIds + "," + item.id;
              if(item.status=='3004000050'){
                msg += item.batchCode+",";
              }
            });
          }
          if(msg.length > 0){
            this.$message.error(msg+"专项提取中状态不可导出！");
            this.loading = false;
            this.isDisabled = false;
            return;
          }
        }

        console.log("批次id：" + this.parameter.batchImportIds);
        if (row == null){
          this.checkSelecterCopy("临时批次不可以进行批量导出。");
        }
        this.parameter.bizType = this.currentTab;//对应业务类型 todo 怎么从tables里面直接获取相关值
        exportDetails(this.parameter).then(res => {
          this.loading = false,
            this.isDisabled = false;
          downloadFile(res);
        })
      },
      checkSelecterCopy(msg){
        var flag = false;
        this.multipleSelection.forEach(item => {
          flag = item.status == '3004000020';
          if(flag){
            this.loading = false;
            this.isDisabled = false;
            this.$message.error(msg);
            throw new Error(msg);
          }
        })
      },
      //下载明细模板数据
      async downloadDtlTemplate() {
        this.isDisabled = true;
        this.parameter.batchImportIds = "";
        if (this.multipleSelection.length <= 0) {
          this.$message.error("请选择要下载的批次！");
          this.isDisabled = false;
          return;
        }
        this.parameter.batchIdList = [];
        let taxMonth = '';
        let billMonth = '';
        let mapObj = new Map();
        let list = '';
        //将选中的id组装为string
        this.multipleSelection.forEach(item => {
          // if(taxMonth !== '' && taxMonth !== item.taxMonth){
          //   this.$message.error("不同税金所属期的批次数据不允许合并下载,请重新选择！");
          //   this.loading = false;
          //   this.isDisabled = false;
          //   flag = 'false';
          //   return;
          // }
          billMonth = item.billMonth;
          if(billMonth !== '' && mapObj.get(billMonth) === undefined){
            list = [];
          } else {
            list = mapObj.get(billMonth);
          }
          list.push(item.batchCode);
          mapObj.set(billMonth, list);

          taxMonth = item.taxMonth;
          billMonth = item.billMonth;
          if (item.status !== '3004000039') {
            this.$message.error("状态不是申报结束/发放完成不允许下载明细模板数据,请重新选择！");
            this.loading = false;
            this.isDisabled = false;
            flag = 'false';
            return;
          }
          if (item.stOrgid !== '1060344') {
            this.$message.error("受托机构不是中智薪税公司不允许复制,请重新选择！");
            this.loading = false;
            this.isDisabled = false;
            flag = 'false';
            return;
          }
          this.parameter.batchIdList.push(item.id);
        });
        debugger
        //以下批次账单月份不一致，不允许批量下载！
        if(mapObj.size > 1){
          let msg = '';
          mapObj.forEach(function(value, key) {
            msg += "【"+key+"】的批次【"+mapObj.get(key).toString()+"】;"
          })
          this.$message.error("以下批次账单月份不一致，不允许批量下载！" + msg);
          this.loading = false;
          this.isDisabled = false;
          flag = 'false';
          return;
        }
        //以下批次工资劳务费月份不一致，不允许批量下载！
        let res = await checkWageMonthIsSame(this.parameter);
        if (res.data.code === 1) {
          this.$message.error({dangerouslyUseHTMLString: true , message:res.data.msg});
          this.loading = false;
          this.isDisabled = false;
          flag = 'false';
          return;
        }
        res = await checkBatchIsAllowDownload(this.parameter);
        debugger
        console.log("data.code：" + res.data.code + ",--data.msg：" + res.data.msg);
        if (res.data.code === 0) {
          //判断msg是否有值，有值则提示出来
          if(res.data.data != null && res.data.data !== ''){
            this.$confirm(res.data.data, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.loading = false;
              this.downloadBatchVisible = true;
            }).catch(() => {
              this.isDisabled = false;
              this.loading = false;
              this.multipleSelection='';
              this.__getDetailImportBatchList();
            });
          } else {
            this.loading = false;
            this.downloadBatchVisible = true;
          }
        } else {
          this.$message.error(res.data.msg);
        }
        this.loading = false;
        this.isDisabled = false;
      },

      downloadOk(){
        this.$refs.copyPageRef.downloadOk();
      },

      hiddenCopyMonth(){
        console.log('取消时隐藏月份框');
        this.copyBatchMonthDialog = false;
        this.loading = false;
        this.isDisabled = false;
      },

      hiddenDownFun(){
        this.downloadBatchMonthDialog = false;
        this.loading = false;
        this.isDisabled = false;
      },
      //复制薪资模板下载--下载
      downloadData(){
        this.downloadBatchVisible = false;
        this.isDisabled = false;
        this.loading = false;
        this.parameter.copyFlag = "00900";
        downloadSalaryTmpFile(this.parameter).then(res => {
          downloadFile(res);
        });
      },

      //复制薪资模板下载--选择月份
      choiceMonth(){
        this.downloadBatchVisible = false;
        let billMonth = '';
        let taxMonth = '';
        this.multipleSelection.forEach(item => {
          billMonth = item.billMonth;
          taxMonth = item.taxMonth;
        });
        let temp = {wageMonth: this.copyBatchRow.wageMonth, bizType: this.parameter.bizType, batchIdList: this.parameter.batchIdList,
          billMonth : monthCalcu(billMonth,1),taxMonth : monthCalcu(taxMonth,1),invoiceMonth:"", operType: '下载'};
        // debugger
        this.copyBatchRow = temp;
        this.downloadBatchMonthDialog = true;
      },

      //删除批次信息
      //TODO 需要测试多种情况下的删除
      deleteImportBatch(row) {
        this.isDisabled = true;
        this.parameter.batchImportIds = "";
        this.delIds = []
        if (this.multipleSelection.length <= 0) {
          this.$message.error("请选择要删除的批次！");
          this.isDisabled = false;
          return;
        }
        //将选中的id组装为string
        this.multipleSelection.forEach(item => {
          if (item.status === '3004000025' || item.status === '3004000043') {
            this.$message.error("状态是提交财务/分支财务已确认的批次不允许删除,请重新选择！");
            this.loading = false;
            this.isDisabled = false;
            flag = 'false';
            return;
          }
          if (item.status === '3004000045') {
            this.$message.error("状态是社保拉取中的批次不允许提交删除,请重新选择！");
            this.loading = false;
            this.isDisabled = false;
            flag = 'false';
            return;
          }
          this.delIds.push(item.id);
          // this.parameter.batchImportIds = this.parameter.batchImportIds + "," + item.id;
        });
        this.parameter.batchImportIds = this.delIds.join(",")
        console.log("批次id：" + this.parameter.batchImportIds);
        this.$confirm('批次删除后将不可恢复，请谨慎操作', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true;
          deleteImportBatch(this.parameter).then(res => {
            this.loading = false;
            this.isDisabled = false;
            if (res.data.data.code) {
              this.$message.success(res.data.data.msg);
            } else {
              this.$message.error(res.data.data.msg);
            }
            this.__getDetailImportBatchList();
            this.parameter.batchImportIds = "";
          })
        }).catch(() => {
          this.isDisabled = false;
          this.loading = false;
          this.__getDetailImportBatchList();
        });
      },

      //查看详情
      showDetails(row) {
        console.log("批次id：" + row.id + ";业务类型：" + row.bizType);
        if(row.batchStatus = '00900' && row.status == '3004000020'){
          // 临时批次查看
          this.$router.push({
            path: '/wt/copySalaryDetail',
            query:{
              bizType: this.currentTab,//对应业务类型
              id:row.id,
              cusid:row.cusid
            }
          })
        }else if ((row.bizType == "31278300701" || row.bizType == "31278300702") && this.listQuery.batchStatus == "00901"
          && this.userInfo.userType != '2') {
          this.detailVisible = 'detailCus';
          this.cuslistQuery.batchImportId = row.id;
          this.cuslistQuery.bizType = row.bizType;
          this.__getDetailImportBatchCusList();
        } else {
          this.detailVisible = 'true';
          this.detailInfo.batchImportId = row.id;
          this.detailInfo.bizType = row.bizType;
          this.detailInfo.status = row.status;
          this.detailInfo.cusId = row.cusid;
          this.detailInfo.batchCode = row.batchCode;
          this._getdynamicTable();
          this._getdynamicList();
        }
      },

      //动态表头
      _getdynamicTable: function () {
        console.log("查看对应的批次id：" + this.detailInfo.batchImportId);
        getdynamicTable(this.detailInfo).then(res => {
          if (res.data.data.success == "true") {
            this.tableColumn = res.data.data.tableColumn;
            this.sumItem = res.data.data.sumItem;
            this.noChangeList = res.data.data.noChangeList;
            // 处理数据 不能编辑的能编辑的
            console.log("==========================");
            if (this.listQuery.batchStatus == '00900') {
              for (let i = 0; i < this.tableColumn.length; i++) {
                if (this.noChangeList.indexOf(this.tableColumn[i].field) == -1) {
                  if (this.tableColumn[i].setId && this.dicts.codeList[this.tableColumn[i].setId]) {
                    this.tableColumn[i].editRender = {
                      name: "$select",
                      options: this.dicts.codeList[this.tableColumn[i].setId],
                      optionProps: {
                        value: "codeItemName",
                        label: "codeItemName",
                        disabled: false
                      }
                    }
                  } else {
                    this.tableColumn[i].editRender = {name: 'input'}
                  }
                }
              }
            }
          }
        })
      },
      //动态数据
      _getdynamicList() {
        getdynamicList(this.detailInfo).then(res => {
          this.listQuery.total = res.data.total
          this.dataList = res.data.records
        })
      },
      //明细导出
      exportDetailsTwo(id) {
        if( this.detailInfo.status=='3004000050'){
          this.$message.error("专项提取中状态不可导出！");
          return;
        }
        this.loading = true;
        this.parameter.subIds = '';
        this.parameter.batchImportIds = '';
        if (this.multipleDetailSelection.length > 0) {
          this.multipleDetailSelection.forEach(item => {
            this.parameter.subIds = this.parameter.subIds + "," + item.SUBID;
          });
          console.log("选中的subid:" + this.parameter.subIds);
        } else {
          this.parameter.batchImportIds = this.detailInfo.batchImportId;//对应批次id
        }
        console.log("批次id：" + this.parameter.batchImportIds + "====");
        this.parameter.bizType = this.currentTab;//对应业务类型 todo 怎么从tables里面直接获取相关值
        this.parameter.cusId = this.detailInfo.cusId;
        exportDetails(this.parameter).then(res => {
          this.loading = false;
          //导出后 subids置空
          this.parameter.subIds = '';
          downloadFile(res);
        })
      },

      //重新计算
      recalculate() {
        this.isDisabled = true;
        this.loading = true;
        this.parameter.batchImportIds = '';
        //todo 添加加载
        //获取选中的批次信息
        if (this.multipleSelection.length <= 0) {
          this.$message.error("请选择要重新计算的批次！");
          this.loading = false;
          this.isDisabled = false;
          return;
        }
        this.checkSelecterCopy("临时批次不能进行重新计算！");
        //将选中的id组装为string
        this.multipleSelection.forEach(item => {
          if (item.status === '3004000025' || item.status === '3004000043') {
            this.$message.error("状态是提交财务/分支财务已确认的批次不允许重新计算,请联系分支财务退回后重新操作！");
            this.loading = false;
            this.isDisabled = false;
            flag = 'false';
            return;
          }
          if (item.status === '3004000045' || item.status === '3004000047') {
            this.$message.error("状态是社保拉取中/社保拉取失败的批次不允许重新计算,请重新选择！");
            this.loading = false;
            this.isDisabled = false;
            flag = 'false';
            return;
          }

          if (item.status == '3004000050' || item.status == '3004000051' || item.status == '3004000052') {
            this.$message.error("状态为专项提取中/获取专项失败/获取专项后计算失败的批次不允许重新计算,请重新选择！");
            this.isDisabled = false;
            this.loading = false;
            flag = 'false';
            return;
          }
          this.parameter.batchImportIds = this.parameter.batchImportIds + "," + item.id;
        });
        //调用重新计算的方法
        recalculate(this.parameter).then(res => {
          this.isDisabled = false;
          this.loading = false;
          debugger;
          if (res.data.data.success === "true") {
            this.$message.success("重新计算完成！");
            this.__getDetailImportBatchList();
          } else {
            // this.$message.error('重新计算失败');
            this.$message.error(res.data.data.msg);
          }
        })
      },

      //提交
      async commitBatch() {
        this.isDisabled = true;
        this.loading = true;
        // debugger
        this.parameter.batchImportIds = '';
        let flag = '';
        let ocRes = await getTransOrgIsAudit(this.userInfo.orgId);
        if (ocRes.data.data.success === 'true') {
          if (ocRes.data.data.IS_FINANCIAL_AUDIT !== null && ocRes.data.data.IS_FINANCIAL_AUDIT === '00901' && this.currentTab !== '31278300701') {
            this.loading = false;
            this.isDisabled = false;
            this.$message.error("该机构没有提交的权限，请先提交财务！");
            return;
          }
        } else {
          this.loading = false;
          this.isDisabled = false;
          this.$message.error("获取用户薪资导入权限异常！");
          return;
        }
        if (this.multipleSelection.length <= 0) {
          this.$message.error("请选择要提交的批次！");
          this.loading = false;
          this.isDisabled = false;
          return;
        }
        var isSub = false;//是否分包、派遣加盟
        var isNormal = false;//是否一般业务
        if (this.parameter.bizType == '31278300703' && this.userInfo.userType === "2") {
          let rst = await getIsMaintain();
          debugger
          if (rst.data.code == 1) {
            this.$message.error("OMS对接改造即将升级，请节后检查雇员委托业务类型，确认无误后重新导入");
            this.loading = false;
            this.isDisabled = false;
            return;
          }
        }

        //创建json,用于存储批次ID的状态
        var jsonStr = "[";
        //将选中的id组装为string
        this.checkSelecterCopy("临时批次不允许提交。");
        this.multipleSelection.forEach(async item => {
          debugger
          //分包、派遣加盟不能与一般业务一起提交
          if(item.entrustType === '50680001'){
            isNormal = true;
          }
          if(item.entrustType === '50680002' || item.entrustType === '50680003'){
            isSub = true;
          }

          debugger
          if (this.parameter.bizType == '31278300703' && this.userInfo.userType === "2" && (item.entrustType == '' || item.entrustType == null)) {
            this.$message.error("由于分包、派遣加盟支付流程改造，当前操作的批次属于在途数据，请检查雇员委托业务类型，确认无误后重新导入");
            this.isDisabled = false;
            this.loading = false;
            flag = 'false';
            return;
          }

          //是统筹账号,但是状态不是审核通过的状态，不允许提交
          if (item.accountType === '600302' && item.status !== '3004000043' && this.coOrdinationAccountAudit === 1) {
            debugger
            this.$message.error("统筹账号分支财务未确认不允许提交,请重新选择！");
            this.isDisabled = false;
            this.loading = false;
            flag = 'false';
            return;
          }
          if (item.status === '3004000045' || item.status === '3004000047') {
            this.$message.error("状态是社保拉取中/社保拉取失败的批次不允许提交,请重新选择！");
            this.loading = false;
            this.isDisabled = false;
            flag = 'false';
            return;
          }

          if (item.status === '3004000054' || item.status === '3004000055') {
            this.$message.error("状态是智灵通解锁/智灵通退回的批次不允许提交,请重新选择！");
            this.loading = false;
            this.isDisabled = false;
            flag = 'false';
            return;
          }

          if (item.status === '3004000057') {
            this.$message.error("状态是计算失败的批次不允许提交,请重新选择！");
            this.loading = false;
            this.isDisabled = false;
            flag = 'false';
            return;
          }

          if (item.status === '3004000056' && item.smartStatus === '3004000035') {
            this.$message.error("状态是智灵通已认单且是财务公司退回的批次不允许提交,请在智灵通进行解锁后重新提交！");
            this.loading = false;
            this.isDisabled = false;
            flag = 'false';
            return;
          }

          if (item.status == '3004000050' || item.status == '3004000051' || item.status == '3004000052') {
            this.$message.error("状态为专项提取中/获取专项失败/获取专项后计算失败的批次不允许提交,请重新选择！");
            flag = 'false';
            this.isDisabled = false;
            this.loading = false;
            return;
          }
          if (item.stOrgid != '1060344') {
            flag = 'true';
          }
          this.parameter.orgId = item.orgid;
          this.parameter.batchImportIds = this.parameter.batchImportIds + "," + item.id;
          jsonStr += '{"' + item.id + '":"' + item.status + '"},';
        });
        if(isNormal && isSub){
          this.$message.error("分包、派遣加盟业务不能与一般业务类型批次一起提交,请重新选择！");
          flag = 'false';
          this.isDisabled = false;
          this.loading = false;
          return;
        }
        jsonStr = jsonStr.substring(0, jsonStr.length - 1) + "]";
        this.parameter.batchMap = eval("" + jsonStr + "");
        this.parameter.commitList = this.multipleSelection;
        this.parameter.bizType = this.currentTab;
        if (flag == 'false') {
          this.isDisabled = false;
          return;
        }

        //分包、派遣加盟，代发薪大库名义报税业务需要维护 发薪时间
        if(this.parameter.bizType === '31278300703' && isSub){
          this.parameter.planPayTimeModels=[];
          this.multipleSelection.forEach(item => {
            debugger
            var planPayTimeModel = {};
            planPayTimeModel.cusName = item.cusName;
            planPayTimeModel.taxArea = item.taxArea;
            planPayTimeModel.batchCode = item.batchCode;
            planPayTimeModel.taxMonth = item.taxMonth;
            planPayTimeModel.entrustType = item.entrustTypeName;
            planPayTimeModel.batchId = item.id;
            this.parameter.planPayTimeModels.push(planPayTimeModel);
          });
          this.planPayTimeDialogVisible = true;
          return;
        }

        //业务类型不等于大库报税并且不是客户名义报税
        if (flag == 'true' && this.parameter.bizType != '31278300701' && this.parameter.bizType != '31278300702') {
          this.loading = false;
          //查询发票抬头信息 收件人信息
          getBillsInfo(this.parameter).then(res => {
            if (res.data.data.success == "true") {
              this.billsInfoList = res.data.data.billsInfoList;//发票信息
              this.receAddrList = res.data.data.receAddrList;//收件人信息
            }
          })
          this.billDialogVisible = true;
        } else {
          this.commonCommit();
        }
      },
      //发票信息保存
      billSave() {
        console.log("billsTitle:" + this.parameter.billsTitle + ";recipients:" + this.parameter.recipients);
        if (this.parameter.billsTitle == "") {
          this.$message.error("请选择发票信息！");
          return;
        }
        if (this.parameter.recipients == "") {
          this.$message.error("请选择收件人！");
          return;
        }
        this.commonCommit();
        this.billDialogVisible = false;
      },

      //计划支付日期保存
      async planPayTimeSave() {

        this.loading = true;
        this.isDisabled = true;
        console.log(this.parameter.planPayTimeModels);
        var isPass = true;
        this.parameter.planPayTimeModels.forEach(item => {
          debugger
          if (item.planPayTime === '' || item.planPayTime == undefined) {
            this.$message.error("请选择计划支付日期！");
            isPass = false;
            return;
          }
        });
        if (isPass) {
          var tips = "";
          selectTrusteeOrgTips(this.parameter).then(async res => {
            if (res.data.code == 0) {
              tips = res.data.data
            }
            debugger
            if (tips) {
              this.$confirm(tips, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(async () => {
                this.planPayTimeDialogVisible = false;
                // this.loading = true;
                // this.isDisabled = true;
                let rst = await this.commonCommit();
                this.loading = true;
              }).catch(() => {
              });
            } else {
              this.planPayTimeDialogVisible = false;
              // this.loading = true;
              // this.isDisabled = true;
              let rst = await this.commonCommit();
              this.loading = true;
            }
          })

          // this.__getDetailImportBatchList();
          // this.planPayTimeDialogVisible = false;
          // this.loading = true;
          // // this.__getDetailImportBatchList();
        }
      },

      billCancel(){//发票信息保存窗口取消
        this.isDisabled = false;
        this.loading = false;
        this.billDialogVisible = false;
      },
      planPayTimeCancel(){//取消计划支付日期
        this.isDisabled = false;
        this.loading = false;
        this.planPayTimeDialogVisible = false;
      },
      //公共提交方法
      commonCommit() {
        this.isDisabled = true;
        this.loading = true;
        commitBatch(this.parameter).then(res => {
          if (res.data.data.msg == "") {
            this.isDisabled = false;
            this.loading = false;
            this.$message.success("提交成功！");
            this.__getDetailImportBatchList();
            this.parameter.batchImportIds = '';
            return 'true';
          } else if (res.data.data.msg == "0000") {
            this.loading = false;
            this.commitBatchTransfer();
          } else {
            if (res.data.data.code === '00901') {
              this.isDisabled = false;
              this.loading = false;
              let data = res.data.data.msg;
              this.$message.error(data);
              this.__getDetailImportBatchList();
              this.parameter.batchImportIds = '';
              return 'false';
            } else {
              this.isDisabled = false;
              this.loading = false;
              this.$message.error(res.data.data.msg);
              this.__getDetailImportBatchList();
              this.parameter.batchImportIds = '';
              return 'false';
            }
          }
        })
      },

      //分支有统筹账号时，需要再确认一下才进行提交
      commitBatchTransfer: function () {
        //增加一个统筹账号的提示
        this.$confirm('是否确认款项已到账号？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true;
          this.parameter.isSure = 'true';
          commitBatch(this.parameter).then(res => {
            let rst = 'false';
            if (res.data.data.msg === "") {
              this.loading = false;
              this.isDisabled = false;
              this.$message.success("提交成功！");
              rst = 'true';
            } else {
              this.isDisabled = false;
              this.loading = false;
              this.$message.error(res.data.data.msg);
              rst = 'false';
            }
            this.parameter.isSure = 'false';
            this.__getDetailImportBatchList();
            this.parameter.batchImportIds = '';
            return rst;
          })
        }).catch(() => {
          this.parameter.isSure = 'false';
          this.loading = false;
          this.isDisabled = false;
          this.multipleSelection = '';
          this.__getDetailImportBatchList();
          this.parameter.batchImportIds = '';
          return 'false';
        });
      },

      //大库/客户名义点击批次中查看
      __getDetailImportBatchCusList() {
        this.cuslistQuery.bizType = this.currentTab;
        getDetailImportBatchCusList(this.cuslistQuery).then(res => {
          if (res.data.code != 0) {
            this.cuslistQuery.total = res.data.total
            this.detailImportCusList = res.data.records
          } else {
          }
        })
      },
      //返回
      goback() {
        debugger
        console.log("业务类型：" + this.cuslistQuery.bizType + "，状态：" + this.listQuery.batchStatus);
        this.cuslistQuery.bizType = this.currentTab;
        this.multipleSelection = [];
        if ((this.cuslistQuery.bizType === '31278300701' || this.cuslistQuery.bizType === '31278300702') && this.listQuery.batchStatus === '00901') {
          this.detailVisible = 'detailCus';
          this.listQuery.filters = [];
          this.__getDetailImportBatchCusList();
        } else {
          this.detailVisible = 'false';
          this.listQuery.filters = [];
          this.__getDetailImportBatchList();
        }
      },
      //查看详情
      lookDetail(row) {
        console.log(row.bizType + "===" + row.cusid)
        this.detailVisible = 'true';
        this.detailInfo.batchImportId = row.importBatchId;
        this.detailInfo.bizType = row.bizType;
        this.detailInfo.status = row.status;
        this.detailInfo.cusId = row.cusid;
        this._getdynamicTable();
        this._getdynamicList();
      },
      //客户列表里面返回
      rtnDetail() {
        this.detailVisible = 'false';
        this.listQuery.filters = [];
        this.__getDetailImportBatchList();
      },
      //提交之后重新刷新列表
      submitData() {
        this.__getDetailImportBatchCusList();
      },
      //发票抬头改变
      billChange(val) {
        console.log("发票抬头改变-val=", val);
        this.billsInfoList.forEach(item => {
          if (val == item.BILLSTITLE) {
            this.parameter.billsTitle = item.BILLSTITLENAME;
            this.parameter.taxNum = item.F204204;//F204204
            this.parameter.taxAdress = item.F204205;//F204205
            this.parameter.taxPhone = item.F204206;//F204206
            return;
          }
        });
      },
      reciChange(val) {
        console.log("收件人信息改变-val=", val);
        this.receAddrList.forEach(item => {
          if (val == item.ID) {
            this.parameter.recipients = item.RECEIVEDEPT;
            this.parameter.emailAddress = item.POSTCODE;
            return;
          }
        });
      },

      //明细选中的数据
      getSelectData(multipleDetailSelection) {
        console.log(multipleDetailSelection)
        this.multipleDetailSelection = multipleDetailSelection;
      },
      //在线编辑保存
      async saveEvent() {
        debugger
        console.log(this.$refs.detailImportList);
        let test = this.$refs.detailImportList.$refs.xGrid.getRecordset();
        this.updateDetailList.updList = test.updateRecords;
        this.updateDetailList.batchImportId = this.detailInfo.batchImportId;
        this.updateDetailList.batchCode = this.detailInfo.batchCode;
        this.updateDetailList.noChangeList = this.noChangeList;
        console.log("======================" + this.updateDetailList.updList);
        console.log(test);
        if (this.updateDetailList.updList == null || this.updateDetailList.updList.length <= 0) {
          this.$message.error("请修改后再进行批量保存!");
          return;
        }
        //检查修改的明细是否社保拉取中状态
        let res = await checkEmpSseIsOntheWay(this.updateDetailList);
        debugger
        if (res.data.code === 0) {
          let errEmpMsg = res.data.data.errEmpInfos;
          if (errEmpMsg.length > 0) {
            let newDatas = [], h = this.$createElement;
            newDatas.push(h('div', {style: 'color: red'}, res.data.data.msg));
            newDatas.push(h('div', {style: 'color: red'}, '以下雇员社保拉取中，不能保存！'));
            newDatas.push(h('b', null, ''));
            for (let i in errEmpMsg) {
              newDatas.push(h('div', null, errEmpMsg[i]));
              newDatas.push(h('div', null, ''));
            }
            this.$msgbox({
              title: '错误提示',
              message: h('div', null, newDatas),
              showCancelButton: false,
              confirmButtonText: '确定',
            }).then(action => {
              this.isDisabled = false;
              this.loading = false;
            });
            return;
          }
        }

        this.$confirm('您确认要保存修改数据吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true;
          updateDetail(this.updateDetailList).then(res => {
            console.log(res.data.data.info);
            if (res.data.data.info == "true") {
              this.$message.success("保存成功！");
              this._getdynamicList();
              this.loading = false;
              return;
            } else {
              this.$message.error({
                iconClass: "iconMessage",
                customClass: "errMessage",
                dangerouslyUseHTMLString: true,
                message: res.data.data.info,
                showClose: true,
                duration: 0
              });
              //this.$message.error(res.data.data.info);
              this.loading = false;
              return;
            }
          })
        }).catch(() => {
          this.loading = false;
          return;
        });
      },
      //工资单下载
      downEmpSalary() {
        this.loading = true;
        if (this.multipleDetailSelection.length <= 0) {
          this.$message.error("请选择一条数据！");
          this.loading = false;
          return;
        }
        if (this.multipleDetailSelection.length > 1) {
          this.$message.error("只能选择一条数据！");
          this.loading = false;
          return;
        }
        this.multipleDetailSelection.forEach(item => {
          this.salaryParam.subId = item.SUBID;
        });
        getEmpSalaryPath(this.salaryParam).then(res => {
          if (res.data.data.success == "true") {
            this.salaryParam.filePath = res.data.data.path;
            this.salaryParam.fileName = res.data.data.fileName;
            downEmpSalary(this.salaryParam).then(res => {
              console.log("工资单下载");
              if (this.salaryParam.fileName.indexOf(".pdf") != -1) {
                downloadPdfFile(res);
              } else {
                downloadFile(res);
              }
              this.loading = false;
            })
          }
        })
      },
      //拉取雇员社保信息
      async getEmpSocialSecurity() {
        this.loading = true;
        if (this.multipleDetailSelection.length <= 0) {
          this.$message.error("请选择数据！");
          this.loading = false;
          return;
        }
        let empSalarys = [];
        let notBjTaxArae = [];
        this.multipleDetailSelection.forEach(item => {
          if ("北京市" !== item.E204215) {
            notBjTaxArae.push("雇员：" + item.E204344 + "，证件号：" + item.E204347);
          }
          debugger
          let localEmpSalary = {};
          localEmpSalary.batchCode = this.detailInfo.batchCode;
          localEmpSalary.bizType = this.currentTab;
          localEmpSalary.batchId = item.E204203;
          localEmpSalary.cusName = item.E204399;
          localEmpSalary.salaryMonth = item.E204358;
          localEmpSalary.empName = item.E204344;
          localEmpSalary.empCardType = item.E204350;
          localEmpSalary.empCard = item.E204347;
          localEmpSalary.salaryStore = item.E204210;
          localEmpSalary.taxArea = item.E204215;
          localEmpSalary.salaryType = item.E204208;

          this.empSocialSecurity.empSalarys.push(localEmpSalary);
        });
        //检查薪资计税区域是否是北京市
        if (notBjTaxArae.length > 0) {
          let newDatas = [], h = this.$createElement;
          newDatas.push(h('b', {style: 'color: red'}, '以下雇员薪资计税区域非北京市，没有社保拉取权限！'));
          for (let i in notBjTaxArae) {
            newDatas.push(h('p', null, notBjTaxArae[i]))
          }
          this.$msgbox({
            title: '错误提示',
            dangerouslyUseHTMLString: true,
            message: h('p', null, newDatas),
            showCancelButton: false,
            confirmButtonText: '确定',
          }).then(action => {
            this.isDisabled = false;
            this.loading = false;
          });
          this.isDisabled = false;
          this.loading = false;
          this.empSocialSecurity.empSalarys = [];
          return;
        }

        //检查客户是否关联OMS
        let relData = await checkPtCusIsRelOmsCus(this.empSocialSecurity);
        if (relData.data.code === 0) {
          let dataList = relData.data.data;
          let errEmpMsg = [];
          for (var key in dataList) {
            let data = dataList[key];
            for (let empInfo in data) {
              errEmpMsg.push("批次：" + empInfo);
              let errCus = '';
              if (data[empInfo].length > 0) {
                for (let i in data[empInfo]) {
                  let cusName = data[empInfo][i];
                  errCus += cusName + '、'
                }
              }
              if (errCus.length > 0) {
                let finalCus = errCus.substring(0, errCus.length - 1);
                errEmpMsg.push(finalCus);
              }
            }
          }
          if (errEmpMsg.length > 0) {
            let newDatas = [], h = this.$createElement;
            newDatas.push(h('div', {style: 'color: red'}, '以下客户没有关联Oms客户，没有社保拉取权限！'));
            newDatas.push(h('b', null, ''));
            for (let i in errEmpMsg) {
              if (errEmpMsg[i].indexOf("批次") !== -1) {
                newDatas.push(h('b', {style: 'color: red'}, errEmpMsg[i]));
              } else {
                newDatas.push(h('p', null, errEmpMsg[i]))
              }
            }
            this.$msgbox({
              title: '错误提示',
              message: h('div', null, newDatas),
              showCancelButton: false,
              confirmButtonText: '确定',
            }).then(action => {
              this.isDisabled = false;
              this.loading = false;
            });
            this.isDisabled = false;
            this.loading = false;
            this.batchParamMap = '';
            return
          }
        } else {
          this.$msgbox({
            title: '错误提示',
            message: h('div', null, '检查是否关联Oms客户异常！'),
            showCancelButton: false,
            confirmButtonText: '确定',
          }).then(action => {
            this.isDisabled = false;
            this.loading = false;
          });
          this.isDisabled = false;
          this.loading = false;
          this.batchParamMap = '';
          return
        }

        //检查批次中雇员是否是“工资类型”
        let isSalaryRes = await checkEmpSseIsSalary(this.empSocialSecurity);
        if (isSalaryRes.data.code === 0) {
          let errEmpMsg = [];
          debugger;
          isSalaryRes.data.data.errEmpInfos.forEach((item, i) => {
            errEmpMsg.push(item);
          })

          if (errEmpMsg.length > 0) {
            let newDatas = [], h = this.$createElement;
            newDatas.push(h('b', {style: 'color: red'}, '以下雇员计税类型非“工资、补贴”，不允许拉取！'));
            for (let i in errEmpMsg) {
              newDatas.push(h('p', null, errEmpMsg[i]))
            }
            this.$msgbox({
              title: '错误提示',
              customClass: 'winClass',
              dangerouslyUseHTMLString: true,
              message: h('p', null, newDatas),
              showCancelButton: false,
              confirmButtonText: '确定',
            }).then(action => {
              this.isDisabled = false;
              this.loading = false;
            });
            this.isDisabled = false;
            this.loading = false;
            this.empSocialSecurity.empSalarys = [];
            return;
          }
        }


        //检查批次中雇员是否是拉取社保在途状态
        let res = await checkIsExistOnTheWayEmps(this.empSocialSecurity);
        debugger
        if (res.data.code === 0) {
          let errEmpMsg = [];
          for (let key in res.data.data) {
            let data = res.data.data[key];
            for (let empInfo in data) {
              errEmpMsg.push("【雇员】：" + data[empInfo].empName + ", 【证件号】：" + data[empInfo].empCard + "；请 " + data[empInfo].expireTime + " 秒后重试");
            }
          }
          if (errEmpMsg.length > 0) {
            let newDatas = [], h = this.$createElement;
            newDatas.push(h('b', {style: 'color: red'}, '以下雇员社保状态为拉取中，请稍候！'));
            for (let i in errEmpMsg) {
              newDatas.push(h('p', null, errEmpMsg[i]))
            }
            this.$msgbox({
              title: '错误提示',
              customClass: 'winClass',
              dangerouslyUseHTMLString: true,
              message: h('p', null, newDatas),
              showCancelButton: false,
              confirmButtonText: '确定',
            }).then(action => {
              this.isDisabled = false;
              this.loading = false;
            });
            this.isDisabled = false;
            this.loading = false;
            this.empSocialSecurity.empSalarys = [];
            return;
          }
        }

        this.$confirm('是否拉取雇员的社保信息？若确认拉取，则会覆盖导入的社保数据，并无法通过批次再次拉取全部雇员社保信息，请知悉！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //拉取客户雇员的社保信息
          getEmpSocialSecurityInfo(this.empSocialSecurity.empSalarys).then(res => {
            let data = res.data;
            if (data.code === 0) {
              this.$message.success("开始拉取社保，请稍后查看...");
              this.isDisabled = false;
              this.loading = false;
            } else {
              let newDatas = [], h = this.$createElement;
              newDatas.push(h('div', {style: 'color: red'}, data.msg));
              newDatas.push(h('div', {style: 'color: red'}, '以下雇员社保拉取失败，请联系系统管理员！'));
              newDatas.push(h('b', null, ''));
              let errEmpMsg = data.data.errEmpInfos;
              if (errEmpMsg.length > 0) {
                for (let i in errEmpMsg) {
                  newDatas.push(h('div', null, errEmpMsg[i]));
                  newDatas.push(h('div', null, ''));
                }
                this.$msgbox({
                  title: '错误提示',
                  message: h('div', null, newDatas),
                  showCancelButton: false,
                  confirmButtonText: '确定',
                }).then(action => {
                  this.isDisabled = false;
                  this.loading = false;
                });
              }
            }
            this.empSocialSecurity.empSalarys = [];
          });
        }).catch(() => {
          this.empSocialSecurity.empSalarys = [];
          this.isDisabled = false;
          this.loading = false;
        });
        this.isDisabled = false;
        this.loading = false;
      },
      // 批次撤回。已提交薪税，薪税未发送oms
       withdrawal(row){
        if(row.status !== '3004000024'){
          this.$message.warning('只有状态是："已提交财务公司"的数据可以进行撤回！！');
          return ;
        }

        this.$confirm('是否要撤回批次：' + row.batchCode, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.isDisabledBatchStatus = true;
          // 成功提示。并且刷新列表
            this.loading = true;
           const res =  await WithdrawalBatch(row)
            console.log("=====================>",res);
            if ( res.data.code == "0") {
              this.loading = false;
              this.$message.success("批次撤回成功！");
              this.__getDetailImportBatchList();
            }else {  // 失败提示详细信息。不做处理
              this.loading = false;
              this.isDisabledBatchStatus = false;
              this.$message.error("批次撤回失败！失败原因：" + res.data.msg);
            }
          }).then(error => {
            //异常
            this.loading = false;
            this.isDisabledBatchStatus = false;
            console.log(error);
          })
      },
      async copyBatch(row){
        let dialogFlag = true;
        this.copyBtn = false;
        this.loading = false;
        let msg = "";
        const temp = {...row , wageMonth: "",  invoiceMonth: "" ,taxMonth : monthCalcu(row.taxMonth,1), operType: '复制'}
        this.copyBatchRow = temp;
        const isCopy =  await getIsCopy({batchCode :row.batchCode})
        const checkResult= await checkBatchIsAllowDownload({batchIdList:[row.id]});
        if(!isCopy.data.data){
          dialogFlag = false ;
          msg = "批次："+ row.batchCode + " 已经被复制，但是还没有发放完成，不允许复制；";
        }
        if (checkResult.data.code !== 0) {
          dialogFlag = false ;
          msg = msg + checkResult.data.msg;
        }

        if(!dialogFlag)
          this.$message.error(msg);
        if(checkResult.data.data && dialogFlag){
          this.$confirm(checkResult.data.data, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.loading = false;
            this.copyBatchMonthDialog = dialogFlag;
          });
        }else {
          this.copyBatchMonthDialog = dialogFlag;
        }
        this.loading = false;
      },
      _queryWageMonth(){
        const obj = {id : this.copyBatchRow.id}
        queryWageMonth(obj).then((result) => {
          console.log(result);
          this.copyBatchRow.wageMonth = result.data.data.length > 1 ?  '': monthCalcu(result.data.data[0].wagemonth,1);
          this.copyBatchRow.invoiceMonth = result.data.data.length > 1 ?  '': monthCalcu(result.data.data[0].invoicemonth,1);
        }).catch((err) => {
          // 查询工资月份出错
          console.log(err);
        });
      },

      _queryWageMonthForDwn(){
        let id = '';
        this.multipleSelection.forEach(item => {
          id = item.id;
        });
        const obj = {id: id}
        queryWageMonth(obj).then((result) => {
          console.log(result);
          this.copyBatchRow.wageMonth = result.data.data.length > 1 ?  '': monthCalcu(result.data.data[0].wagemonth,1)
          this.copyBatchRow.invoiceMonth = result.data.data.length > 1 ?  '': monthCalcu(result.data.data[0].invoicemonth,1);
        }).catch((err) => {
          // 查询工资月份出错
          console.log(err);
        });
      },
      generateDetail(row){
        console.log(row);
        this.isDisabled = true;
        this.$router.push({
          path: '/wt/copyParent',
          query:{
            bizType: this.currentTab,//对应业务类型
            id:row.id,
            cusid:row.cusid
          }
        })
        this.isDisabled = false;
      }
    }
  }
</script>
<style scoped>
  .copy-btn {
    cursor: pointer;
  }

  .ciic-table-operate {
    align-items: center;
  }

  .editForm {
    margin: 0;
  }

  .editForm .el-form-item {
    margin: 0;
  }
</style>
<style>
  .winClass {
    width: 50% !important;
    height: 60% !important;
  }

  .el-message-box__content {
    overflow-y: auto !important;
    height: 80% !important;
  }
</style>
