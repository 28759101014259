<template>
    <div>
      <bsp-table
        :data.sync="list"
        :tree-config="true"
        :ciic-data-filter.sync="listQuery"
        @ciic-data-refresh="loadList"
        show-header-overflow show-overflowwidth show-overflow
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        show-footer-overflow
        show-footer
      >
        <vxe-table-column type="selection" width="60" fixed="left"/>
        <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>


        <bsp-table-column title="客户编号" field="cusCode" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.cusCode}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="客户名称" field="cusName" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="操作人" field="managerName" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.managerName}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="接口人" field="busiRecheckName" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.busiRecheckName}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="所属对公机构" field="pubOrg" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.pubOrg}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="客户状态" field="status" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.status}}</span></template>
        </bsp-table-column>


        <bsp-table-column  title="操作" fixed="right" align="center">
          <template slot-scope="scope">
            <div class="ciic-table-operate">
              <el-button  type="text" @click="searchEmp(scope.row.id)" >查看本月未发工资雇员</el-button>
            </div>
          </template>
        </bsp-table-column>
      </bsp-table>
    </div>
</template>

<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {
    searchCusList,
  } from '@/api/cus/list';
  import {mapGetters} from 'vuex';

    export default {
        name: "cusList",
      inject: ['reload'],
      components: {
        BspTable,
        BspTableColumn,
      },
      computed: {
        ...mapGetters([
          'dicts'
        ]),
      },
      props: {
        currentTab: {
          type: String,
          default: ''
        },
      },

      created() {
          this.loadList();
      },

      data() {
          return {
            // 用于接收后台返回的分页数据
            list: [],
            //分页查询对象
            listQuery: {
              limit: 50,
              page: 1,
              total: 0,
              paymentNo: '',
              paymentStatus: '',
              accountingMonth: '',
              paymentType: '0'
            },
          }
      },

      methods: {
        loadList() {
          searchCusList(this.listQuery).then(res => {
            this.list = res.data.data;
            this.listQuery.total = res.data.total;
          })
        },

        searchEmp(cusId) {
          this.$router.push({path: '/cus/empList', query: {cusId: cusId}})
        }
      }
    }
</script>

<style scoped>

</style>
