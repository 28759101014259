<template>
  <div>
    <el-form :inline="true" :model="listQuery" class="demo-form-inline labelWhite">
      <!-- todo 按钮应放右侧-->
      <!-- <el-button type="primary" size="small" fixed="right"   @click="addProduct">新增套餐</el-button>
      <el-button type="primary" size="small" fixed="right"   @click="addProductDetail">新增服务项目</el-button> -->
    </el-form>
    <bsp-table
      :data.sync="list"
      :tree-config="true"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="__getServiceProductList"
      @select-change="selectChange"
      @select-all="selectAll"
      show-header-overflow show-overflowwidth  show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      :show-pagination="false"
      
    >

      <vxe-table-column type="selection" width="60" fixed="left"/>
      <bsp-table-column  title="创建时间" field="createTime"     fixed="left"  >
        <template slot-scope="scope">   <span>{{scope.row.createTime}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="套餐名称"  field="planName"    fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.planName}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="创建人"  field="operName"   fixed="left"  >
        <template slot-scope="scope">  <span>{{scope.row.operName}}</span>  </template>
      </bsp-table-column>

      <bsp-table-column  title="操作"  fixed="right"  width="120px"   align="center" >
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button  type="text" @click="showPlanDetails(scope.row)"  >查看</el-button>
            <!-- <el-button  type="text" @click="editProduct(scope.row)"  >编辑</el-button>
            <el-button  type="text" @click="deleteProduct(scope.row)"  >删除</el-button> -->
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>

  <!-- 新增套餐 开始-->
  <el-dialog v-dialogDrag   width="40%" :title="textMap[dialogStatus]"  :visible.sync="addProductDialogVisible"
             :close-on-click-modal="false"  :close-on-press-escape="false"  >
    <el-form  class="editFrom" label-width="100px"  :disabled="disabled"  >
      <el-row>
        <el-col>
          <el-form-item label="套餐名称" prop="planName">
            <el-input v-model="params.planName"  resize="none" type="input" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div  class="marginTop30"  style="text-align: center" >
      <el-button  type="primary"  @click="planSave" > 保存 </el-button>
      <el-button @click="addProductDialogVisible=false">  取消  </el-button>
    </div>
  </el-dialog>
  <!-- 新增套餐 结束-->

  <!-- 新增项目服务明细 开始 addProductDetailDialogVisible-->
    <el-dialog v-dialogDrag   width="40%" :title="textMap[dialogStatus]"  :visible.sync="addProductDetailDialogVisible"
               :close-on-click-modal="false"  :close-on-press-escape="false"  >
      <el-form  class="editFrom" label-width="100px"  :disabled="disabled"  >
        <el-row>
          <el-col>
            <el-form-item label="套餐名称" prop="planName">
              <el-input v-model="planForm.planName"  resize="none" type="input" disabled />
            </el-form-item>
            <el-form-item label="服务项目" prop="serviceItem">
              <el-input v-model="planForm.serviceItem"  resize="none" type="input"  />
            </el-form-item>
            <el-form-item label="服务说明" prop="serviceDesc">
              <el-input v-model="planForm.serviceDesc"  resize="none" type="textarea"   />
            </el-form-item>
            <el-form-item label="服务费用" prop="serviceFee">
              <el-input v-model="planForm.serviceFee"  resize="none" type="input"  />
            </el-form-item>
            <el-form-item label="服务约定" prop="serviceAgreement">
              <el-input v-model="planForm.serviceAgreement"  resize="none" type="textarea"  />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div  class="marginTop30"  style="text-align: center" >
        <el-button  type="primary"  @click="addPlanDetailUpdSave" > 保存   </el-button>
        <el-button @click="addProductDetailDialogVisible=false">  取消  </el-button>
      </div>
    </el-dialog>
  <!-- 新增项目服务明细 结束-->
  </div>
</template>
<script>
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import { getServiceProductList,planSave,planDetailSave,deleteProduct } from '@/api/serviceProduct/serviceProduct'
  import {mapGetters} from 'vuex';

  export default {
    name: 'productPlanList',
    inject:['reload'],
    components: {  BspTable,  BspTableColumn },
    computed: {
      ...mapGetters([
        'dicts'
      ]),
    },
    props: {
      currentTab: {
        type: String,
        default: ''
      },

    },
    created() {
      this.__getServiceProductList();
    },
    data() {
      return {
        // 遮罩
        loading: false,
        // 用于接收后台返回的分页数据
        list: [],
        addProductDialogVisible:false,
        addProductDetailDialogVisible:false,
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
        },
        planForm: {
          planName:'',
          planId:'',
          serviceItem:'',
          serviceDesc:'',
          serviceFee:'',
          serviceAgreement:'',
        },
        params:{//用于编辑套餐时传的参数，与查看等区分
          planName:'',
          planId:'',
        },
        //多选数组
        multipleSelection: [],
        textMap: {
          addPlanTitile: '新增套餐',
          editPlanTitile: '编辑套餐',
          addPlanDetailTitile: '新增项目服务明细',
        },
        dialogStatus:'addPlanTitile',
      }
    },
    methods: {
      //查询产品套餐列表
      __getServiceProductList: function () {
        getServiceProductList().then(res => {
          if (res.data.code != 0) {
            this.listQuery.total = res.data.total
            this.list = res.data.records
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },

      //点击新增套餐
      addProduct(){
        this.addProductDialogVisible = true;
      },
      //保存套餐名称
      planSave(){
        if(this.params.planName.length<=0){
          this.$message.error("请输入要添加的套餐名称！");return;
        }
        planSave(this.params).then(res => {
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            this.addProductDialogVisible=true;
          }else{
            this.$message.success(res.data.msg);
            this.addProductDialogVisible=false;
            this.__getServiceProductList();
          }
        })
      },
      //点击编辑套餐名称
      editProduct(row){
        this.params.planId=row.id,//套餐id
        this.params.planName=row.planName,//套餐名称
        this.addProductDialogVisible = true;
        this.dialogStatus='editPlanTitile';
      },

      //点击增加服务项目
      addProductDetail(){
        //需先选择一个套餐方可增加服务项目
        if(this.multipleSelection.length<=0){ this.$message.error("请选择要增加服务项目的套餐！");return; }
        if(this.multipleSelection.length>1){this.$message.error("只允许选择一个套餐增加服务项目，请核实！");return; }
        //将选中的id组装为string
        this.multipleSelection.forEach(item=> {
          this.planForm.planId=item.id;
          this.planForm.planName=item.planName;
        });
        this.addProductDetailDialogVisible = true;
        this.dialogStatus='addPlanDetailTitile';
      },
      //保存服务项目明细
      planDetailSave(){
        planDetailSave(this.planForm).then(res => {
          if (res.data.code != 0) {
            this.$message.error(res.data.msg);
            this.addProductDetailDialogVisible=true;
          }else{
            this.$message.success(res.data.msg);
            this.addProductDetailDialogVisible=false;
            this.__getServiceProductList();
          }
        })
      },

      selectChange(value) {
        console.log(value);
        if (value.selection) {
          this.multipleSelection = [];
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index];
            this.multipleSelection.push(element);
          }
        }
      },
      //复选框全选事件
      selectAll({checked, selection}) {
        if(checked){
          this.multipleSelection= selection
        }else{
          this.multipleSelection=[];
        }
        console.log("this.multipleSelection:"+this.multipleSelection);
      },

      //查看详情
      showPlanDetails(row) {
        console.log("planId："+row.id);
        const query = {   //getDynamicForm参数
          planId:row.id,//套餐id
          planName:row.planName,//套餐名称
        }
        this.$router.push({ path: '/serviceProduct/productPlanDetailList', query});
      },

      //删除套餐
      deleteProduct(row){
        this.params.planId=row.id;
        console.log("套餐id："+this.params.planId);
        this.$confirm('您确认要删除所选中的套餐吗？删除就无法还原请慎重选择！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteProduct(this.params).then(res => {
            if(res.data.code != 0){
              this.$message.error(res.data.msg);
            }else{
              this.$message.success("删除成功！");
            }
            this.__getServiceProductList();
          })
        }).catch(() => {
          this.__getServiceProductList();
        });
      },
    }
  }
</script>
<style scoped>
  .copy-btn{
    cursor: pointer;
  }
  .ciic-table-operate{
    align-items: center;
  }
</style>
