

<template>
  <div class="errorPage">
    <div class="errorContent">
      <img src="@/assets/img/errorImg/404.png" alt="">
      <div class="errorBth">
        <el-button @click="goHome" class="errorBack1" size="large" style="width: 200px;" type="text">返回首页</el-button>
        <el-button @click="backPage" class="errorBack2"  size="large" style="width: 200px;margin-left: 40px;" type="primary">返回上一页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Error404',
    methods: {
      backPage() {
        this.$router.go(-1);
      },
      goHome() {
        this.$router.push({
          path: '/',
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .errorPage{
    height:calc(100vh - 140px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .errorContent{
    position: relative;
    width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
      width: 1000px;
      margin-bottom: 30px;
    }
    .errorBth{
      .errorBack1{
        width: 180px;
        height: 40px;
        padding: 0;
        border:1px solid #399FFE;
        color:#399FFE;
        border-radius: 20px;
        background: none;
        font-size: 20px;
        font-weight: 100;
      }
      .errorBack2{
        width: 180px;
        height: 40px;
        padding: 0;
        border:1px solid #399FFE;
        border-radius: 20px;
        color:#ffffff;
        background: #399FFE;
        font-size: 20px;
        font-weight: 100;
      }
    }
  }
</style>
