<template>
  <div ref="customerBox" class="customerBox">
    <!--头部-->
    <!--收起时为头部占位-->
    <div class="customerfixed" v-show="!fold" style="height:74px;"></div>
    <!--收起时补充头部上方空白-->
    <div class="customerfixed" v-show="!fold" :class="!fold?'fixed-top-bg':''" :style="!fold?boxWidth:''"></div>
    <!--收起时tab导航-->
    <div class="customerfixed" v-show="!fold" :class="!fold?'fixed-top2':''">
      <el-tabs v-model="tabIndex" @tab-click="changeTab" :style="!fold?tabWidth:''">
        <el-tab-pane
          :key="item.name"
          v-for="item in tabList"
          :label="item.title"
          :name="item.name"
          lazy="true"
        >
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="customerInfo"  :class="!fold?'fixed-top':''">
      <div class="customerInfo-head" :style="!fold?boxWidth:''">
        <div class="customerInfo-name"><span>沃奇卫士技术有限公司</span>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-gongsibiaoshi"></use>
          </svg>
        </div>
        <div class="customerInfo-btns" v-show="!fold">
          <el-button type="primary"><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-xinzengshenhe"></use>
          </svg><span>新增审核</span></el-button>
          <el-button type="primary"><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-tijiaoshenhe"></use>
          </svg>提交审核</el-button>
          <el-button type="primary"><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-shanchukehu"></use>
          </svg>删除客户</el-button>
          <el-button class="customerInfo-btn"><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-zhongzhifuwu"></use>
          </svg>终止服务</el-button>
        </div>
        <el-button-group class="customeInfo-pageGroup">
          <el-button type="button">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-fanhui"></use>
            </svg>
            返回
          </el-button>
        </el-button-group>
      </div>
      <div class="customerInfo-body"  v-show="fold">
        <div  class="customerInfo-body-detail">
          <p><span>客户联系人：</span><span>赵小刚 - 18515063203</span></p>
          <p class="interval">/</p>
          <p><span>业务负责人：</span><span>王雅 - 薪酬部</span></p>
          <p class="interval">/</p>
          <p><span>服务项目：</span><span>薪酬服务、财税全服务、报税服务</span></p>
        </div>
        <div class="customerInfo-btns">
          <el-button type="primary"><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-quanping"></use>
          </svg><span>新增审核</span></el-button>
          <el-button type="primary"><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-quanping"></use>
          </svg>提交审核</el-button>
          <el-button type="primary"><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-quanping"></use>
          </svg>删除客户</el-button>
          <el-button class="customerInfo-btn"><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-quanping"></use>
          </svg>终止服务</el-button>
        </div>
      </div>
    </div>
    <div class="customerDetail" >

      <!--tab页-->
      <el-tabs v-model="tabIndex" @tab-click="changeTab" >
        <el-tab-pane
          :key="item.name"
          v-for="item in tabList"
          :label="item.title"
          :name="item.name"
          lazy="true"
        >
          <component :is="item.name"></component>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
  // import bspTabs from "@/components/bsp/bsp-tabs/bsp-tabs";
  import { mapGetters } from 'vuex';
  import editForm from '@/components/ciic/ciic-form/ciic-form-edit';
  import salary from '@/views/demo/template/salaryTemplate';
  import wages from '@/views/demo/template/wagesTemplate';
  import rendanDetail from '@/views/demo/template/rendanDetail';

  export default {
    data() {
      return {
        fold:true,//是否展开头部信息
        boxWidth:0,//折叠头的宽
        tabWidth:0,//tab的宽
        tabList: [{
          title: '用户管理',
          name: 'editForm'
        }, {
          title: '薪资模板',
          name: 'salary'
        }, {
          title: '工资单模板设置',
          name: 'wages'
        }, {
          title: '认单详情',
          name: 'rendanDetail'
        }],
        tabIndex: 'editForm',
        // currentTab:'editForm',
      }
    },
    computed: {
      ...mapGetters([
        'isCollapse'
      ]),
    },
    watch:{
      'isCollapse':function(){
        this.handleScroll("collapse")
      }
    },
    components: {
      // bspTabs,
      editForm,
      salary,
      rendanDetail,
    },
    mounted () {        window.addEventListener('scroll', this.handleScroll,true);

    },
    destroyed(){
      // 销毁滚动事件
      window.removeEventListener('scroll', this.handleScroll,true);
    },
    methods: {
      changeTab(tab, event) {
        this.$forceUpdate();
        this.fold = true;
        // 回到顶部
        this.$emit('toTop');
      },
      handleScroll(type) {
        // 获取组件距顶距离
        let scrollTop = this.$refs.customerBox.getBoundingClientRect().top;
        // 获取组件高度
        let height = this.$refs.customerBox.clientHeight;
        //获取组件父级高度
        let parentHeight = window.innerHeight - 65;

        if(type == "collapse"){
          if(this.isCollapse){
            this.boxWidth = "width:" + (this.$refs.customerBox.clientWidth +180 - 72 - 112 ) + 'px';
            this.tabWidth = "width:" + (this.$refs.customerBox.clientWidth +180 - 72 - 62 - 20) + 'px';
          }else{
            this.boxWidth = "width:" + (this.$refs.customerBox.clientWidth -180 + 72 - 112) + 'px';
            this.tabWidth = "width:" + (this.$refs.customerBox.clientWidth -180 + 72 - 62 - 20) + 'px';
          }

        }else{
          // 如果收起后组件高度小于父组件高度，不收起
          if (scrollTop === 85) {
            this.fold = true;
            return false;
          }else{

            if (scrollTop < 20) {
              if(this.fold){
                if(height - 114 > parentHeight){
                  this.fold = false;
                  this.boxWidth = "width:" + (this.$refs.customerBox.clientWidth - 112) + 'px';
                  this.tabWidth = "width:" + (this.$refs.customerBox.clientWidth - 62 - 20) + 'px';
                }
              }
            } else {
              this.fold = true;
            }
          }
        }
      },
    }
  }
</script>
