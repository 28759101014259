<template>
  <div class="cusHeader">
    <waterfall
     :list="headerData"
    :gutter="0"
    :width="300"
    :gridWidth="-1"
    :breakpoints="{
        1200: { //当屏幕宽度小于等于1200
        rowPerView: 4,
        },
        800: { //当屏幕宽度小于等于800
        rowPerView: 3,
        },
        500: { //当屏幕宽度小于等于500
        rowPerView: 2,
        }
    }"
    ref="waterfall"
    >

            <!-- <ul>
                <li class="title" >{{item.title}}{{item.height}}</li>
                <li v-for="data in item.data" :key="data.infoItemId">
                    {{data.infoItemName}}
                </li>
            </ul> -->
              <template slot="item" slot-scope="props">
                    <div class="card" >
                            <!-- {{props.data}} -->
                        <ul style="max-height:100px">
                                <li class="title" >{{props.data.title}}</li>
                                <li v-for="data in props.data.data" @click="liClick(data)" :class="{'selected':isSelect(data.infoItemId)}" :key="data.infoItemId">
                                    {{data.infoItemName}}
                                </li>
                        </ul>




                    </div>
                </template>
    </waterfall>
  </div>
</template>

<script>
    import Waterfall from "vue-waterfall-plugin";
  import {getCustomTableHeader  } from  "@/api/common/common"
  export default {
    props: {
      infoSetId: {
        type: String,
        default: ''
      },
      modelType: {
        type: String,
        default: ''
      },
      headerList:{
        type:Array,
        default: () => (
          []
        )
      },
    },
    data(){
      return{
        headerParam:{
          infoSetId:'',
          modelType:'',
        },
         headerData:[
             {
            title:"企业信息",
            id:1,
            data:[
              {
                "name": "实际名称",
                "id": 4,
                "parentId":1,//为了尽量少循环添加上层id标识
                "undraggable":false//是否已经添加，添加后不能被拖拽
              },
                {
                "name": "英文名称",
                "id": 5,
                "parentId":1,
                "undraggable":true
              },
              {
                "name": "客户行业",
                "id": 6,
                "parentId":1,
                "undraggable":true
              },
              {
                "name": "英文名称",
                "id": 5,
                "parentId":1,
                "undraggable":true
              },
              {
                "name": "客户行业",
                "id": 6,
                "parentId":1,
                "undraggable":true
              }
            ]
          },
          {
            title:"企业信息",
            id:1,
            data:[
              {
                "name": "实际名称",
                "id": 4,
                "parentId":1,//为了尽量少循环添加上层id标识
                "undraggable":false//是否已经添加，添加后不能被拖拽
              },
              {
                "name": "英文名称",
                "id": 5,
                "parentId":1,
                "undraggable":true
              },
              {
                "name": "客户行业",
                "id": 6,
                "parentId":1,
                "undraggable":true
              }
            ]
          },
          {
            title:"企业信息",
            id:1,
            data:[
              {
                "name": "实际名称",
                "id": 4,
                "parentId":1,//为了尽量少循环添加上层id标识
                "undraggable":false//是否已经添加，添加后不能被拖拽
              },
              {
                "name": "英文名称",
                "id": 5,
                "parentId":1,
                "undraggable":true
              },
              {
                "name": "客户行业",
                "id": 6,
                "parentId":1,
                "undraggable":true
              }
            ]
          },
          {
            title:"服务信息",
            id:2,
            data:[
              {
                "name": "企业规模",
                "id": 7,
                "parentId":2,
                "undraggable":true
              },
              {
                "name": "客户国别",
                "id": 8,
                "parentId":2,
                "undraggable":false
              },
              {
                "name": "客户关注度",
                "id": 9,
                "parentId":2,
                "undraggable":false
              }
            ]
          }
        ]
      }
    },
    components: {
        Waterfall
    },
    created (){
         // 加载数据
       this._getInfoItem();
    },
    updated(){
        this.$refs.waterfall.refresh();
    },
    methods:{
      _getInfoItem(){
        this.headerParam.infoSetId = this.infoSetId;
        this.headerParam.modelType = this.modelType;
        getCustomTableHeader(this.headerParam).then(res=>{
          console.log(res,"zi ding yi biaotao ");

          for(var i = 0; i < res.data.allItmeList.length; i++){
            this.$set(res.data.allItmeList[i],"fold",true);
          }
            this.headerData=(res.data.allItmeList);
           this.headerList = res.data.custItmeList;
          this.$emit('update:headerList', this.headerList);
        })
      },
      liClick(data){
        var id = data.infoItemId;
        var isSelect = this.isSelect(id);
        if(isSelect){
            this.headerList = this.headerList.filter((item)=>{
              return item.infoItemId != id;
          });
        }else{
            this.headerList.push(data)
        }
          this.$emit('update:headerList', this.headerList);
      },
      isSelect(id){
          if(id){
               var selected =  this.headerList.filter((item)=>{
              return item.infoItemId == id;
          });

            if(selected.length>0){
                return true
            }else{
                return false
            }
          }

      }
    }
  }
</script>
<style scoped>
.title{
    color: #5e7fa1;
    font-size: 16px;
      height: 50px;
      padding-top: 20px;
}
.card{
    padding-left: 20px;
}
ul{
    width: 200px;
}
ul li{
   text-align: left;
   padding-left: 10px;
    height: 30px;
    line-height: 28px;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    list-style: none;
    position: relative;
    cursor: pointer;
}
ul li:hover{
    color: #f6b962;
}
ul li.selected::after{
    content: '';
    position: absolute;
    background: url('../../../assets/img/setHeadList/tuding.jpg') no-repeat;
    width: 17px;
    height: 17px;
    background-size: 100;
    right: 10px;
    top: 6px;
}


</style>
