<template>
	<div
		v-loading="loading"
		style="padding:10px 0;min-height: 400px;"
		element-loading-text="拼命加载中"
		element-loading-spinner="el-icon-loading"
	>
		<div class="customer-info">
			<div class="customer-info-item">
				<div class="customer-info-title">
					<!--图标-->
					<!-- <i class="el-icon-caret-right" ></i>
					<i class="el-icon-caret-bottom" ></i>-->
					<span>线索详情</span>
				</div>
				<div class="customer-info-button">
					<!--编辑-->
					<el-link v-if="!edit" type="primary" @click.stop="changeEdit(listIndex)">编辑</el-link>
					<el-link v-if="edit" type="primary" @click.stop="cancelHandler('form2'+listIndex,listIndex)">取消</el-link>
					<el-link  v-if="edit" type="primary" style="margin-left:20px;" @click.stop="saveLeads()">保存</el-link>
				</div>
			</div>
      <div v-show="edit" class="editForm">
        <el-row>
          <el-form ref="leadsInfo" :model="leadsInfo" label-width="168px" :rules="listRules">
            <el-col :span="12">
              <el-form-item label="客户名称" prop="cusName">
                <el-input v-model.trim="leadsInfo.cusName" />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="公司规模" prop="scale">
                <el-input v-model.trim="leadsInfo.scale" />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="公司所在省市" prop="city">
                <el-input v-model.trim="leadsInfo.city" />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="详细地址" prop="detailedAddress">
                <el-input v-model.trim="leadsInfo.detailedAddress" />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="联系人" prop="contacts">
                <el-input v-model.trim="leadsInfo.contacts" />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="角色" prop="role">
                <bsp-dicts-select
                  v-model="leadsInfo.role"
                  dic-num="3009"
                  :select-value.sync="leadsInfo.role"
                ></bsp-dicts-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="职务" props="post">
                <el-input v-model.trim="leadsInfo.post" />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="手机号码" prop="phone">
                <el-input v-model.trim="leadsInfo.phone" />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="电子邮箱" prop="email">
                <el-input v-model.trim="leadsInfo.email" />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="微信账号" prop="wechat">
                <el-input v-model.trim="leadsInfo.wechat" />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="线索来源" prop="leadsSource">
                <bsp-dicts-select
                  v-model="leadsInfo.leadsSource"
                  dic-num="3011"
                  :select-value.sync="leadsInfo.leadsSource"
                ></bsp-dicts-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="线索状态" prop="leadsStatus">
                <bsp-dicts-select
                  v-model="leadsInfo.leadsStatus"
                  dic-num="3013"
                  :select-value.sync="leadsInfo.leadsStatus"
                ></bsp-dicts-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="归属人员" prop="ascriptionPer">
                <el-select clearable v-model="leadsInfo.ascriptionPer" placeholder="请选择" disabled="true">
                  <el-option
                    v-for="item in ascriptionList"
                    :key="item.id"
                    :value="item.id"
                    :label="item.ascriptionName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="所属分支" prop="orgName">
                <el-input v-model.trim="leadsInfo.orgName" disabled="true" />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="最新跟进" prop="followTime">
                <el-date-picker
                  v-model="leadsInfo.followTime"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="创建时间" prop="createTime">
                <el-date-picker
                  readonly
                  disabled="true"
                  v-model="leadsInfo.createTime"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="更新时间" prop="updateTime">
                <el-date-picker
                  v-model="leadsInfo.updateTime"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="下次跟进" prop="nextFollowTime">
                <el-date-picker
                  v-model="leadsInfo.nextFollowTime"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="备注信息" prop="remark">
                <el-input
                  type="textarea"
                  placeholder="请输入内容"
                  v-model="leadsInfo.remark"
                  maxlength="500"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <!-- <el-row v-if="editList[listIndex] =='false'" :class="{onlyRead:editList[listIndex] =='false'}">
          <el-form label-width="168px">
            <el-col :span="8" :class="{'hidden':list.visiable || !isShow[list.valueId+'_show']}" v-for="list in ItemList.dynamicFormVOList"
                    :key="list.itemId">
              <el-form-item :label="list.titleName" v-show="isShow[list.valueId+'_show']">
                <p class="formText" v-if="list.itemType==6">{{formData[list.valueId] | bspTableFilter({'1':'dict','2':dicts},true)}}</p>
                <p class="formText" v-else-if="list.itemType==23">{{getCityName(formData[list.valueId])}}</p>
                <p class="formText" v-else-if="list.itemType==15 ">{{formData[list.valueId] | bspTableFilter({'1':'ref','3':list.refData},true)}}</p>
                <p class="formText" v-else>{{formData[list.valueId]?formData[list.valueId]:'--'}}</p>
              </el-form-item>
            </el-col>
          </el-form>
                </el-row>-->
      </div>
			<div v-show="!edit" class="editForm">
				<el-row>
					<el-form ref="validateForm" :model="leadsInfo" label-width="168px" >
						<el-col :span="12">
							<el-form-item label="客户名称">
                <p class="formText">{{leadsInfo.cusName}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="公司规模">
                <p class="formText">{{leadsInfo.scale}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="公司所在省市">
                <p class="formText">{{leadsInfo.city}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="详细地址">
                <p class="formText">{{leadsInfo.detailedAddress}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="联系人">
                <p class="formText">{{leadsInfo.contacts}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="角色">
                <p class="formText">{{leadsInfo.role | dictFilter(dicts)}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="职务">
                <p class="formText">{{leadsInfo.post}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="手机号码">
                <p class="formText">{{leadsInfo.phone}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="电子邮箱">
                <p class="formText">{{leadsInfo.email}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="微信账号">
                <p class="formText">{{leadsInfo.wechat}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="线索来源">
                <p class="formText">{{leadsInfo.leadsSource | dictFilter(dicts)}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="线索状态">
                <p class="formText">{{leadsInfo.leadsStatus | dictFilter(dicts)}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="归属人员">
                <p class="formText">{{leadsInfo.ascriptionName }}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="所属分支">
                <p class="formText">{{leadsInfo.orgName}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="最新跟进">
                <p class="formText">{{leadsInfo.followTime}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="创建时间">
                <p class="formText">{{leadsInfo.createTime}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="更新时间">
                <p class="formText">{{leadsInfo.updateTime}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="下次跟进">
                <p class="formText">{{leadsInfo.nextFollowTime}}</p>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="备注信息">
                <p class="formText">{{leadsInfo.remark}}</p>
							</el-form-item>
						</el-col>
					</el-form>
				</el-row>
				<!-- <el-row v-if="editList[listIndex] =='false'" :class="{onlyRead:editList[listIndex] =='false'}">
          <el-form label-width="168px">
            <el-col :span="8" :class="{'hidden':list.visiable || !isShow[list.valueId+'_show']}" v-for="list in ItemList.dynamicFormVOList"
                    :key="list.itemId">
              <el-form-item :label="list.titleName" v-show="isShow[list.valueId+'_show']">
                <p class="formText" v-if="list.itemType==6">{{formData[list.valueId] | bspTableFilter({'1':'dict','2':dicts},true)}}</p>
                <p class="formText" v-else-if="list.itemType==23">{{getCityName(formData[list.valueId])}}</p>
                <p class="formText" v-else-if="list.itemType==15 ">{{formData[list.valueId] | bspTableFilter({'1':'ref','3':list.refData},true)}}</p>
                <p class="formText" v-else>{{formData[list.valueId]?formData[list.valueId]:'--'}}</p>
              </el-form-item>
            </el-col>
          </el-form>
				</el-row>-->
			</div>
		</div>
	</div>
</template>
<script>
import bspFormItem from '@/wage-components/bsp-form-item/index'
import { mapGetters } from 'vuex'
import {
	cardid,
	validateEmail,
	validatenumber,
	isvalidatemobile
} from '@/util/validate'
import bspDictsSelect from '@/components/bsp/bsp-dicts-select/bsp-dicts-select'
import { checkCusName, updateLeadsBo } from '@/api/cus/leads'

export default {
	props: {
		leadsInfo: {
			type: Object,
			default: () => {
				{
				}
			}
		},
    oldleadsInfo: {
      type: Object,
      default: () => {
        {
        }
      }
    }
	},
	computed: {
		...mapGetters(['dicts'])
	},
	data() {
		// 客户名称校验是否重复
		let validateCusName = (rule, value, callback) => {
			console.log('客户名称是校验', rule, value, callback)
			// 值不能空，为空不校验
			if (value) {
				checkCusName({ cusName: value, id: this.leadsInfo.id })
					.then(res => {
						console.log('客户名称是否重复2222', res)
						let isExist = res.data.data
						//isExist =true;
						if (isExist) {
							callback(new Error('该客户已存在不能重复添加！'))
						} else {
							callback()
						}
					})
					.catch(e => {
						console.log('校验出错了', e)
						callback()
					})
			} else {
				callback()
			}
		}
		// 手机号码校验
		let validatePhone = (rule, value, callback) => {
			var phoneVal = value
			console.log('手机号校验', rule, value, callback)
			// 值不能空，为空不校验
			if (phoneVal) {
				if (isvalidatemobile(phoneVal)[0]) {
					callback(new Error(isvalidatemobile(phoneVal)[1]))
				} else {
					callback()
				}
			} else {
				callback()
			}
		}
		// 邮箱
		let validateEmails = (rule, value, callback) => {
			var emailVal = value
			if (emailVal) {
				if (!validateEmail(emailVal)) {
					callback(new Error('请输入正确的邮箱地址！'))
				} else {
					callback()
				}
			} else {
				callback()
			}
		}

		return {
			loading: false,
			parameter: {
				//getDynamicForm参数
				operateType: 1, //表单是新增还是修改判断,0是新增，1是修改
				infoSetId: 'EMP_INFO',
				cusId: this.cusId,
				empInfoId: 0,
				tabType: '',
				costCentreId: 0
			},
			ascriptionList: [],
			validateForm: {},
			edit:false,
			// 校验规则
			listRules: {
				cusName: [
					{
						required: true,
						message: '客户名称不能为空!',
						trigger: 'blur'
					},
					{ validator: validateCusName, trigger: 'blur' }
				],
				contacts: [
					{
						required: true,
						message: '联系人不能为空!',
						trigger: 'blur'
					}
				],
				phone: [
					{
						required: true,
						message: '手机号码不能为空!',
						trigger: 'blur'
					},
					{ validator: validatePhone, trigger: 'blur' }
				],
				email: [{ validator: validateEmails, trigger: 'blur' }],
				leadsSource: [
					{
						required: true,
						message: '线索来源不能为空!',
						trigger: 'blur'
					}
				],
				leadsStatus: [
					{
						required: true,
						message: '线索状态不能为空!',
						trigger: 'blur'
					}
				],
				followTime: [
					{
						required: true,
						message: '最新跟进不能为空!',
						trigger: 'blur'
					}
				],
				createTime: [
					{
						required: true,
						message: '创建时间不能为空!',
						trigger: 'blur'
					}
				]
			},

		}
	},
	components: {
		bspFormItem,
		bspDictsSelect
	},
	watch: {
		leadsInfo: {
			handler(newValue, oldeValue) {
				console.log('得到传来的销售线索信息222', this.leadsInfo)
				console.log('this.isUpdateForm', this.isUpdateForm)
				this.ascriptionList.push({
					id: this.leadsInfo.ascriptionPer,
					ascriptionName: this.leadsInfo.ascriptionName
				})
				this.$forceUpdate()
			},
			immediate: true,
			deep: true
		},
    oldleadsInfo: {
      handler(newValue, oldeValue) {
        // console.log('得到传来的销售线索信息222333', this.oldleadsInfo)
        // this.ascriptionList.push({
        //   id: this.leadsInfo.ascriptionPer,
        //   ascriptionName: this.leadsInfo.ascriptionName
        // })
        // this.$forceUpdate()
      },
      immediate: true,
      deep: true
    },
	},
	created() {
		// 得到传来的销售线索信息
		console.log('得到传来的销售索信息', this.leadsInfo)
		//this.oldleadsInfo=JSON.parse(JSON.stringify(this.leadsInfo));
	},
	mounted() {},
	provide() {
		return {
			$rules: this.rules
		}
	},
	methods: {
		// 取消
		cancelHandler(formName, index) {
			// 关闭修改
			this.changeEdit(index)
			// 并把修改后的值设置成原来的值
			this.$set(this,"leadsInfo",JSON.parse(JSON.stringify(this.oldleadsInfo)));
			// 把当前页面是否修改 改成 false
			this.isUpdateForm = false
			this.$emit('update:isUpdateForm', false)
		},
		//保存线索
		saveLeads() {
			let formData = this.leadsInfo;
			//  checkCusName({cusName:formData.cusName,id:formData.id}).then(res=>{
			//   console.log("客户名称是否重复",res);
			//   this.cusIsExist = res.data.data

			//   if(this.cusIsExist){
			//     this.$message.error("该客户已存在不能重复添加！");
			//   }else{
			let childForm = this.$refs.leadsInfo
			 // childForm.validate(valid => {
				//  console.log("修改线索保存校验结果：",valid);
             //
			 // })

			childForm.validate(valid => {
				if (valid) {
					updateLeadsBo(formData).then(res => {
						if (res.data.msg == 'success') {
						  this.edit = !this.edit;
							this.$message.success('保存成功')
              this.oldleadsInfo = JSON.parse(JSON.stringify(this.leadsInfo));
						} else {
							this.$message.error('保存失败')
						}
					})
				}
			})

		},

		changeEdit(index) {
		  this.edit = !this.edit;
			// let newValue
			// // this.editList[index] === 'true'
			// // 	? (newValue = 'false')
			// // 	: (newValue = 'true')
			// // this.$set(this.editList, index, newValue)
			// // 得到数据后 设置身份证验证
			// if (newValue === 'false') {
			// 	// 并把修改后的值设置成原来的值
			// 	this.formData = this.oldeFromData
			// 	// 把当前页面是否修改 改成 false
			// 	this.isUpdateForm = false
			// 	this.$emit('update:isUpdateForm', false)
			// }
		},
		// changeShow(index) {
		// 	let newValue
		// 	this.showList[index] === 'true'
		// 		? (newValue = 'false')
		// 		: (newValue = 'true')
		// 	this.$set(this.showList, index, newValue)
		// },

		changeEducation(value) {
			if (value) {
				this.formData.E001_educationParent = ''
				this.formData.E001_educationChild = ''
			} else {
				value.forEach((item, index) => {
					if (index === 0) {
						this.formData.E001_educationParent = item
					} else {
						this.formData.E001_educationChild = item
					}
				})
			}
			this.$forceUpdate()
		}
	}
}
</script>
