/** 工资条 */
import request from '@/router/axios';
const urlPrefix = '';

// 查询所有的客户名称
export function queryCusList() {
  
  return request({
    url:urlPrefix + '/wageBills/queryAllCustomer',
    method: 'post',
    data: null
  })
}

// 查询工资单信息
export function searchWageBills(obj) {
  return request({
    url:urlPrefix + '/wageBills/selectWageBills',
    method: 'post',
    data: obj
  })
}

// 批量发送邮件信息
export function sendPayslips(obj) {
  return request({
    url:urlPrefix + '/wageBills/sendPayslips',
    method: 'post',
    data: obj
  })
}

// 转发邮件信息
export function forwardEmail(obj) {
  return request({
    url:urlPrefix + '/wageBills/forwardEmail',
    method: 'post',
    data: obj
  })
}

// 保存邮件
export function saveEmailBatch(obj) {
  return request({
    url:urlPrefix + '/wageBills/saveEmailBatch',
    method: 'post',
    data: obj
  })
}

// 查询工资条发送详情
export function querySendDetail(obj) {
  return request({
    url:urlPrefix + '/wageBills/selectSendDetail',
    method: 'post',
    data: obj
  })
}

//重新发送
export function resendPayslips(obj) {
  return request({
    url:urlPrefix + '/wageBills/resendPayslips',
    method: 'post',
    data: obj
  })
}