<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right" @click="addLead">添加</el-button>
      <el-button type="primary" size="small" fixed="right" @click="tranCus">转为客户</el-button>
    </div>
    <!--销售线索列表-->
    <bsp-table v-if="this.detailVisible=='false'"
               :data.sync="list"
               :tree-config="true"
               :ciic-data-filter.sync="listQuery"
               @ciic-data-refresh="__getLeadsLis"
               @select-change="selectChange"
               @select-all="selectAll"
               show-header-overflow show-overflowwidth show-overflow
               v-loading="loading"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               show-footer-overflow
               show-footer
               :footer-method="footerMethod"
               :cell-class-name="cellClassName"
               @cell-click="cellClickEvent"
    >

      <vxe-table-column type="selection" width="60" fixed="left"/>

      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>

      <bsp-table-column title="客户名称" field="cusName" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.cusName}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="联系人姓名" field="contacts" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.contacts}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="手机号码" field="phone" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.phone}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="归属人员"  field="ascriptionName" filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.ascriptionName}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="线索状态" field="leadsStatus" filter-type="select" set-id="3013">
        <template slot-scope="scope"><span>{{scope.row.leadsStatus | dictFilter(dicts)}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="最新跟进" field="followTime" filter-type="datePicker">
        <template slot-scope="scope"><span>{{scope.row.followTime}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="未跟进天数" field="num"  filter-type="input">
        <template slot-scope="scope"><span>{{scope.row.num}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="操作" fixed="right"  align="center">
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <!-- <el-button type="text" @click="showDetails(scope.row)">跟进</el-button> -->
            <el-button type="text" @click="editLeads(scope.row)">编辑</el-button>
            <el-button type="text" @click="deleteLeads(scope.row)">删除</el-button>
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>


    <!--添加弹框-->
    <bsp-dialog
      dialogRef="dialogAdd"
      width="700px"
      title="添加线索"
      :dialogVisible.sync="adddialogVisible"
      :cusIsExist.sync="cusIsExist"
      @close="closeDialog"
    >
      <div slot="body">
        <addLeads  ref="dialogAdd" :list-data="addListForm"></addLeads>
      </div>
      <span class="dialog-footer" slot="footer">
        <el-button type="primary" @click="saveLeads">确 定</el-button>
        <el-button @click="cancelDialog">取 消</el-button>
      </span>
    </bsp-dialog>

    <!--转为客户-->
    <bsp-dialog
      dialogRef="dialogTurn"
      width="700px"
      title="转为客户"
      :dialogVisible.sync="turndialogVisible"
      @close="closeTurnDialog"
      v-if="turndialogVisible"
    >
      <div slot="body">
        <tranCus ref="dialogTurn" :list-data="turnListForm"></tranCus>
      </div>
      <span class="dialog-footer" slot="footer">
        <el-button @click="cancelTurnDialog">取 消</el-button>
        <el-button type="primary" @click="savetranCus">确 定</el-button>
      </span>
    </bsp-dialog>


  </div>
</template>
<script>

  import addLeads from "./addLeads"
  import tranCus from "./tranCus"
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspDialog from "@/components/bsp/bsp-dialog/bsp-dialog";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {getLeadsListPagte,savetLeadsBo,checkCusName,deleteLeadsBo,saveTranCus} from "@/api/cus/leads";
  import { downloadFile } from "@/util/util";
  import { mapGetters } from "vuex";
  import {commitBatch} from "../../../api/entrust/detailImport";

  export default {
    name: "leadsList",
    inject: ["reload"],
    components: {
      BspTable,
      BspTableColumn,
      BspDialog,
      addLeads,
      tranCus
    },
    computed: {
      ...mapGetters([
        "dicts"
      ])
    },
    props: {
      currentTab: {
        type: String,
        default: ""
      }

    },

    created() {
      console.log("currentTab**********" + this.currentTab);

      this.__getLeadsLis();
    },
    data() {
      return {
        // 遮罩
        loading: false,
        // 用于接收后台返回的分页数据
        list: [],
        detailVisible: "false",
        cusIsExist:false,//客户是否存在
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          isCus: "",
        },
        listForm: {},
        parameter: {
          batchImportIds: "",
          bizType: this.currentTab
        },
        //多选数组
        multipleSelection: [],
        textMap: {
          dkTaxTotal: "税金总金额",
          pdkTaxTotal: "工资往来总金额",
          dbxTaxTotal: "代报销总金额"
        },
        taxTotalStatus: "dkTaxTotal",

        dataList: [],
        tableColumn: [], //显示表头
        sumItem: [],//需要合计的列
        // 新增
        adddialogVisible: false,
        // 表单数据
        addListForm: {
          cusName: "",
          contacts: "",
          role: "",
          post: "",
          phone: "",
          email: "",
          wechat: "",
          leadsSource: "",
          leadsStatus: "",
          ascriptionPer: "",
          followTime: new Date(),
          createTime: new Date()
        },
        // 转为客户
        turndialogVisible:false,
        turnListForm:{
          leadsId:"",
          cusName: "",
          country: "",
          industry:'',
          industryBig: "",
          industrySmall: "",
          nature: "",
          state: "",
          officeAddr: "",
          registerAddr: "",
          contactPerson: "",
          operator: "",
        },
      };
    },
    methods: {
      // 加链接
      cellClassName({ row, rowIndex, column, columnIndex }){
        if(column.property === 'cusName'){
          return  'aLink'
        }
      },
      cellClickEvent({ row, rowIndex, column, columnIndex }){
        if(column.property === 'cusName'){
          this.editLeads(row)
        }
      },
      //查询明细导入批次信息
      __getLeadsLis: function() {
       this.listQuery.isCus =this.currentTab
       console.log("列表转入的参数",this.listQuery);
        getLeadsListPagte(this.listQuery).then(res => {
          if (res.data.code != 0) {
            this.listQuery.total = res.data.total;
            this.list = res.data.records;
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
      },
      selectChange(value) {
        console.log(value);
        if (value.selection) {
          this.multipleSelection = [];
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index];
            this.multipleSelection.push(element);
          }
        }
      },
      //复选框全选事件
      selectAll({ checked, selection }) {
        if (checked) {
          this.multipleSelection = selection;
        } else {
          this.multipleSelection = [];
        }
        console.log("this.multipleSelection:" + this.multipleSelection);
      },
      //保存线索
      saveLeads(){
        if(this.cusIsExist){
          this.$message.error("该客户已存在不能重复添加！");
          return;
        }
         let formData = this.$refs.dialogAdd.listData;
         let childForm = this.$refs.dialogAdd.$refs.listData;

            childForm.validate((valid) => {
              if(valid){

                savetLeadsBo(formData).then(res=>{
                    if(res.data.msg=='success'){
                      this.$message.success("保存成功");
                      this.adddialogVisible=false;
                      this.__getLeadsLis();
                    }else{
                      this.$message.error("保存失败");
                    }
                })
              }

            });
        //  checkCusName({cusName:formData.cusName,id:''}).then(res=>{
        //   console.log("客户名称是否重复",res);
        //   this.cusIsExist = res.data.data

        //   if(this.cusIsExist){
        //     this.$message.error("该客户已存在不能重复添加！");
        //   }else{

        //   }
        // })

      },
      //删除线索
      deleteLeads(row){
        deleteLeadsBo(row.id).then(res=>{
          if(res.data.data){
            this.$message.success("删除成功");
            this.__getLeadsLis();
          }else{
            this.$message.error("删除失败");
          }
        })
      },

      //提交
      commitBatch() {
        if (this.multipleSelection.length <= 0) {
          this.$message.error("请选择要提交的批次！");
          return;
        }
        //将选中的id组装为string
        this.multipleSelection.forEach(item => {
          this.parameter.batchImportIds = this.parameter.batchImportIds + "," + item.id;
        });
        this.parameter.bizType = this.currentTab;
        commitBatch(this.parameter).then(res => {
          if (res.data.data.msg == "") {
            this.$message.success("提交成功！");
          } else {
            this.$message.error(res.data.data.msg);
          }
          this.__getDetailImportBatchList();
        });
      },

    // 新增
      addLead() {
        this.adddialogVisible = true;
      },
      // 关闭弹框
      closeDialog() {
        console.log("list -----------------",this.addListForm);
        // 清空表单
        this.$refs.dialogAdd.$refs.listData.resetFields();
        this.adddialogVisible = false;
      },
      cancelDialog(){
        console.log("list -----------------",this.addListForm);
        // 清空表单
        this.$refs.dialogAdd.$refs.listData.resetFields();
        this.adddialogVisible = false;
      },
      //转为客户
      tranCus(){

        if(this.multipleSelection.length==1){
          let row =this.multipleSelection[0];
          console.log(row);
            if(row.isCus=='00901'){
            this.$message.error("已经转为客户，不能在转为客户！");
            return;
          }
          this.turnListForm.cusName = row.cusName;
          this.turnListForm.leadsId = row.id;
          this.turndialogVisible = true;
        }else{
          this.$message.error("请选择一条数据！");
        }


      },
      closeTurnDialog(){
        console.log("list -----------------",this.turnListForm);
        this.$refs.dialogTurn.$refs.listData.resetFields();
        this.turndialogVisible = false;
      },
      // 保存转为客户
      savetranCus(){
        console.log("list -----------------",this.turnListForm);

        let childForm = this.$refs.dialogTurn.$refs.listData;
        childForm.validate(valid=>{
          if(valid){
             let formData =this.$refs.dialogTurn.listData;
              console.log("list ---转客户提交参数1：--------------",formData);
             let operator=formData.operator;
             let industry=formData.industry;
            //  let conts ="";
            //  contactPerson.forEach(item=>{
            //    conts+=item+","
            //  })
            if(industry instanceof Array){
              formData.industry=industry.join(',');
            }
             if(operator instanceof Array){
              formData.operator=operator.join(',');
            }

              console.log("list ---转客户提交参数2：--------------",formData);
            saveTranCus(formData).then(res=>{
              if(res.data.data){
                this.$message.success("保存成功")
                this.turndialogVisible = false;
                this.__getLeadsLis();
              }else{
                this.$message.error("保存失败");
              }
            })

          }
        })
        //
      },

      cancelTurnDialog(){
        console.log("list -----------------",this.turnListForm);
        // 清空表单
        this.$refs.dialogTurn.$refs.listData.resetFields();
        this.turndialogVisible = false;
      },

      // 客户详情与修改
      editLeads(row){
        const query = {   //getDynamicForm参数
          leadsId: row.id,
          orgId: row.orgId,
          currentTab:this.currentTab,
          isEdit:true
        }
        this.$router.push({path: '/cus/leadsDetailsEdit', query});
      }
    }
  };
</script>
<style scoped>
  .copy-btn {
    cursor: pointer;
  }

  .ciic-table-operate {
    align-items: center;
  }
</style>
