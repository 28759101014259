<template>
  <div class="caculateFormula">
    <el-form
      ref="listData"
      :model="listData"
      @submit.native.prevent
      size="small"
    >
      <div class="ruleForm">
        <el-row>
          <el-col :span="24">
            <el-form-item label="应发金额 = " style="margin-bottom: 10px">
              <el-button
                size="small"
                @click="recovery()"
                round
                class="pull-right"
                style="color: currentcolor;background-color: white;border: 1px solid"
              >
                恢复默认值
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="">
              <div style="position: relative;">
                <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="#应发金额#"
                  v-model="listData.payMoney"
                  id="payMoney"
                />
                <!--                <div class="ctn el-textarea">-->
                <!--                  <content-edit ref="contentEdit" :messageDescStr.sync="messageDescStr"></content-edit>-->
                <!--                  <br/>-->
                <!--                </div>-->
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="快捷运输符" prop="field117">
              <el-button
                size="small"
                @click="makeActive('+ ')"
                style="color: coral;background-color: white;border: 1px solid"
              >
                +
              </el-button>
              <el-button
                size="small"
                @click="makeActive('- ')"
                style="color: coral;background-color: white;border: 1px solid"
              >
                -
              </el-button>
              <el-button
                size="small"
                @click="makeActive('* ')"
                style="color: coral;background-color: white;border: 1px solid"
              >
                ×
              </el-button>
              <el-button
                size="small"
                @click="makeActive('/ ')"
                style="color: coral;background-color: white;border: 1px solid"
              >
                ÷
              </el-button>
              <el-button
                size="small"
                @click="makeActive('()')"
                style="color: coral;background-color: white;border: 1px solid"
              >
                ()
              </el-button>
              <el-button
                class="pull-right"
                size="small"
                @click="go_payAmouValid()"
                style="width: 84px;background-color: #ff7d0a"
                round
              >
                公式校验
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="点击选择薪资项">
              <el-input
                style="width: 200px"
                id="searchInp"
                v-model="listData.searchWageParam"
                placeholder="搜索薪资项"
                prefix-icon="el-icon-search"
                @input="wageParamFilter"
                @keyup.enter.native="seacrhEnter"
                @change="searchChange()"
                clearable
              />
              <!--<el-button class="pull-right" type="primary" size="small" fixed="right" @click="onEditWageItem">添加薪资项</el-button>-->
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-card>
              <el-row :gutter="10" class="wageItemCard">
                <el-col :span="6" v-for="(item, index) in witemList" :key="index" :class="{mb10: Math.floor(index / 4) != Math.floor(witemList.length / 4)}">
                  <el-tag @click="do_wage(item)" type="info" effect="plain" class="wageItemTag" size="medium">{{item}}</el-tag>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<script>
import { isvalidatemobile, validateEmail } from "@/util/validate";
import bspDictsSelect from "@/components/bsp/bsp-dicts-select/bsp-dicts-select";
import ContentEdit from "@/components/bsp/bsp-rich-text/ContentEdit";
import {
  getWageItemList,
  getPayMoneyDefault,
  payAmouValid
} from "@/api/cus/payableConf";
import { mapGetters } from "vuex";

export default {
  components: {
    //ContentEdit
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  name: "EditPayAmount",
  props: {
    listData: {
      type: Object,
      default: () => {
        return {
          searchWageParam: "",
          payMoney: "",
          formulaId: ""
        };
      }
    }
  },
  mounted() {
    // if (this.searchWageParam == "" && this.witemList.length == 0) {
    //   console.log("需要重新加载输入按钮");
    //   this._getWageItemList();
    // }
  },
  data() {
    return {
      listQuery: {},
      witemList: [],
      payAmouValidRes: false,
      witemListAll: [],
      cusid: "",
      messageDescStr: ""
    };
  },
  created() {
    //
    this.cusid = this.$store.state.formulaSelected.cusid;
  },
  methods: {
    _getWageItemList() {
      // 获取薪资项列表
      console.log(this.cusid);
      this.listQuery.cusId = this.$store.state.formulaSelected.cusid;
      getWageItemList(this.listQuery).then(res => {
        this.witemList = [];
        this.witemListAll = [];
        console.log("结果res=====>", res);
        for (var index = 0; index < res.data.data.length; index++) {
          const element = res.data.data[index];
          this.witemListAll.push(element.ITEMNAME); //将查询结果保存
        }
        this.witemList = this.witemListAll; //复制查询结果给渲染变量
      });
      getPayMoneyDefault(this.listQuery).then(res => {
        console.log("结果res=====>", res);
        let resData = res.data.data; //将查询结果保存
        if (resData == null) {
          this.listData.payMoney = null;
        } else {
          this.listData.payMoney = resData.formulaDesc;
          this.listData.formulaId = resData.formulaId;
        }
      });
    },
    wageParamFilter() {
      // 根据搜索内容过滤薪资项
      this.witemList = this.witemListAll.filter(
        item => item.indexOf(this.listData.searchWageParam) != -1
      );
    },
    seacrhEnter() {
      if (this.witemList.length >= 1) {
        // 搜索薪资项回车
        let firstWitem = "#" + this.witemList[0] + "# ";
        console.log("第一个元素名称", firstWitem);
        this.makeActive(firstWitem);
        this.listData.searchWageParam = "";
        this.wageParamFilter();
      }
    },
    do_wage(item) {
      // 选择薪资项
      item = "#" + item + "# ";
      this.makeActive(item);
    },
    async makeActive(value) {
      // 在光标位置点击输入字符
      const myField = document.querySelector("#payMoney");

      if (myField.selectionStart || myField.selectionStart === 0) {
        let startPos = myField.selectionStart;

        let endPos = myField.selectionEnd;

        this.listData.payMoney =
          myField.value.substring(0, startPos) +
          value +
          myField.value.substring(endPos, myField.value.length);

        await this.$nextTick(); // 这句是重点, 圈起来

        myField.focus();
        if (value === "()") {
          myField.setSelectionRange(
            endPos + value.length,
            endPos + value.length - 1
          );
        } else {
          myField.setSelectionRange(
            endPos + value.length,
            endPos + value.length
          );
        }
      } else {
        this.listData.payMoney = value;
      }
    },
    recovery() {
      // 恢复默认值
      this.$confirm("点击恢复默认值将清空历史数据，此操作不可恢复，请谨慎操作！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.listData.payMoney = null;
          // this.listQuery.cusId = this.$store.state.formulaSelected.cusid;
          // getPayMoneyDefault(this.listQuery).then(res => {
          //   console.log("结果res=====>", res);
          //   let resData = res.data.data; //将查询结果保存
          //   if (resData == null) {
          //     this.listData.payMoney = null;
          //   } else {
          //     this.listData.payMoney = resData.formulaDesc;
          //     this.listData.formulaId = resData.formulaId;
          //   }
          // });
        })
        .catch(() => {
          this.isDisabled = false;
          this.loading = false;
          this.__getDetailImportBatchList();
        });
    },
    go_payAmouValid() {
      // 公式校验
      this.listQuery.wageItem = this.listData.payMoney;
      // this.listQuery.witemList = this.witemList;
      if (this.listData.payMoney === "") {
        this.$message.error("请输入应发金额计算公式");
        return;
      }
      payAmouValid(this.listQuery).then(res => {
        let resObj = res.data.data; //将查询结果保存
        console.log("公式校验结果", res);
        if ((resObj.code = "success") && resObj.isValid) {
          this.payAmouValidRes = true;
          this.$message.success("公式校验成功！");
        } else {
          let msg = resObj.msg;
          this.payAmouValidRes = false;
          this.$message.error("公式校验失败！" + msg);
        }
      });
    },
    searchChange() {
      console.log("值改变触发");
    }
  }
};
</script>

<style scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}
.box-card {
  width: 100px;
}
</style>
