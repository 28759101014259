<template>
  <div>
    <paySettingFrom  :ref="forms" :wages-from.sync="wagesFrom"></paySettingFrom>
    <paySettingItem :item-lists.sync="itemLists"></paySettingItem>
    <div class="center marginTop30 marginBottom20">
      <el-button type="primary" size="small" @click="subWage">确定</el-button>
      <el-button type="primary" size="small" @click="">取消</el-button>
    </div>
  </div>
</template>

<script>
  import paySettingFrom from "./paySettingFrom"
  import paySettingItem from "./paySettingItem"
  export default {
    name: "wagesSettings",
    components:{paySettingFrom,paySettingItem},
    props:{
      idx:{
        type:Number,
        default:0
      },
      itemLists:{
        type:Array,
        default:function(){
          return []
        }
      },
      wagesFrom:{
        type:Object,
        default:function(){
          return {
            name:"",
            empty:1,
            org:"",
            lang:1,
          }
        }
      }
    },
    data(){
      return{
        name:""
      }
    },
    methods:{
      subWage(){
        this.$refs.forms.submitForm()
      },
    }
  };
</script>

<style scoped>

</style>
