<template>
  <div class="salary">
    <div class="customer-info">
      <div class="customer-info-item" >
        <div class="customer-info-title">
          <!--图标-->
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-qiyexinxi"></use>
          </svg>
          <span>新资模板信息</span>
        </div>
      </div>
      <div class="editForm">
        <el-row >
          <el-form :ref="salary" :model="salaryList" label-width="168px" :rules="rules">
            <el-col span="22">
              <el-form-item
                :label="salaryList[0].titleName"
                :prop="salaryList[0].required ?salaryList[0].valueId:''"
                :id="salaryList[0].valueId"
              >
                <el-input
                  v-model="salaryList[0].model"
                  placeholder="请输入内容"
                />
              </el-form-item>
            </el-col>
            <el-col span="22">
              <el-form-item
                :label="salaryList[1].titleName"
                :prop="salaryList[1].required ?salaryList[1].valueId:''"
                :id="salaryList[1].valueId"
              >
                <el-input
                  type="textarea"
                  v-model="salaryList[1].model"
                  autosize
                  placeholder="请输入内容"
                />
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
    </div>
    <div class="customer-info">
      <div class="customer-info-item">
        <div class="customer-info-title">
          <!--图标-->
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-qiyexinxi"></use>
          </svg>
          <span>薪资项目设置</span>
        </div>
        <div class="customer-info-button">
          <el-switch
            v-model="showSys"
            active-text="显示系统分类">
          </el-switch>
        </div>
      </div>
      <draggable
        tag="div"
        :gutter="40"
        :list="itemLists"
        group="salaryItems"
        forceFallback="true"
        :options="{ghostClass:'ghostClass',fallbackClass:'fallbackClass'}"
        handle=".salaryItemHeaderL"
      >
        <div class="salaryItem" v-for="(list,index) in lists" :key="list.id">
          <el-row class="salaryItemHeader">
            <el-col class="salaryItemHeaderL" :span="4">
              <el-tooltip placement="top">
                <div slot="content">{{list.title}}</div>
                <p>{{list.title}}</p>
              </el-tooltip>
            </el-col>
            <el-col class="salaryItemHeaderC" :span="16">
              <el-tooltip placement="top">
                <div slot="content">{{list.remark}}</div>
                <p>{{list.remark}}</p></el-tooltip>
            </el-col>
            <!--自定义-->
            <el-col class="salaryItemHeaderR" :span="4" >
              <!--编辑-->
              <div class="salaryCheck" v-if="list.self" @click="editType(index)">
                <!--图标-->
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-qiyexinxi"></use>
                </svg>
              </div>
              <!--可选目录-->
              <el-popover
                class="salaryCheck"
                :class="{salaryCheckActve:popVisible[index].visible}"
                popper-class="salaryCheckPop"
                placement="bottom"
                width="120"
                visible-arrow=false
                v-model="popVisible[index].visible">
                <div class="salaryCheckBox">
                  <div class="salaryChecks">
                    <el-checkbox-group v-model="checked[index]" @change="changeItem(checked,index)" >
                      <el-checkbox v-for="list in checkLists[index].checkList" :label="list.id" :key="list.id">{{list.value}}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="addCheck"  @click="addItem(index)">＋ 添加自定义项</div>
                </div>
                <svg class="icon" aria-hidden="true" slot="reference">
                  <use xlink:href="#icon-qiyexinxi"></use>
                </svg>
              </el-popover>
              <!--删除-->
              <div class="salaryCheck" v-if="list.self" @click="deleteType(index)">
                <!--图标-->
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-qiyexinxi"></use>
                </svg>
              </div>
              <!--展开-->
              <div class="salaryShow" v-if="opens[index].open" @click="changeOpen(index)">
                <!--图标-->
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-zhankaizhuangtai"></use>
                </svg>
              </div>
              <!--收起-->
              <div class="salaryShow" v-else @click="changeOpen(index)">
                <!--图标-->
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-shouqizhuangtai"></use>
                </svg>
              </div>
            </el-col>
          </el-row>
          <div class="salaryItemContent" v-show="opens[index].open" v-if="list.data">
            <draggable
              tag="div"
              :gutter="40"
              :list="itemLists"
              :group="'salaryItem'+index"
              forceFallback="true"
              :options="{ghostClass:'ghostClass',fallbackClass:'fallbackClass'}"
            >
              <el-row class="salaryLine" v-for="(item,itemIndex) in list.data" :key="item.id" v-if="item.fixed === 1&&showSys||item.fixed !== 1">
                <el-col :span="4" >
                  <div class="salaryTitle">
                    <el-tooltip content="系统项（不可编辑）" effect="dark" placement="top" v-if="item.fixed === 1">
                    <svg class="icon" aria-hidden="true" >
                      <use xlink:href="#icon-qiyexinxi"></use>
                    </svg>
                    </el-tooltip>
                    <div v-else class="zhanwei"></div>
                    <el-tooltip class="item" effect="dark" placement="top">
                      <div slot="content">{{item.name}}</div>
                      <span>{{item.name}}</span>
                    </el-tooltip>
                  </div>
                </el-col>
                <el-col :span="9">
                  <el-tooltip placement="top">
                    <div slot="content">{{item.explain}}</div>
                    <p class="salaryExplain">{{item.explain}}</p>
                  </el-tooltip>
                </el-col>
                <el-col :span="9">
                  <div class="salaryFormula hasFormula"  v-if="item.formula">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-qiyexinxi"></use>
                    </svg>
                    <span>已设置：{{item.formula}}</span>
                  </div>
                  <div class="salaryFormula"  v-else>
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-qiyexinxi"></use>
                    </svg>
                    <span>点击设置公式</span>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="salaryBtn" >
                    <!--编辑-->
                    <svg class="icon salaryBtnEdit" aria-hidden="true" v-if="item.btn===2" @click="salaryEdit(index,itemIndex)">
                      <use xlink:href="#icon-qiyexinxi"></use>
                    </svg>
                    <!--删除-->
                    <svg class="icon" aria-hidden="true" v-if="item.btn===1||item.btn===2"  @click="deleteData(index,itemIndex)">
                      <use xlink:href="#icon-qiyexinxi"></use>
                    </svg>
                  </div>
                </el-col>
              </el-row>
            </draggable>
          </div>
        </div>
      </draggable>
    </div>
    <div class="addItem" @click="addType">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-qiyexinxi"></use>
      </svg>
      <span>添加自定义分类</span>
    </div>
    <el-row>
      <el-col :span="12" :offset="6">
        <div class="center marginTop30 marginBottom20">
          <el-button type="primary"  size="mini">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-quxiao"></use>
            </svg>
            取消</el-button>
          <el-button type="primary"  size="mini">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-baocun"></use>
            </svg>
            提交</el-button>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="添加自定义项目"
      :visible.sync="dialogItem"
      width="30%"

      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose">
      <el-form
        :model="itemForm"
        ref="itemForm"
        :rules="releaseRules"
        class="editForm"
        label-width="80px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="项目名称"
              prop="name"
            >
              <el-input v-model="itemForm.name" placeholder="请输入自定义项名称" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="项目类型"
              prop="itemRadio"
            >
              <el-radio-group v-model="itemForm.itemRadio">
                <el-radio :label="1">固定项</el-radio>
                <el-radio :label="2">公式项</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="数据类型"
              prop="itemSelect"
            >
              <el-select v-model="itemForm.itemSelect" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="项目描述"
              prop="itemTextarea"
            >
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入描述信息"
                maxlength="300"
                show-word-limit
                v-model="itemForm.itemTextarea">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogItemClose">取 消</el-button>
    <el-button type="primary" @click="dialogItemSave">确 定</el-button>
  </span>
    </el-dialog>
    <el-dialog
      title="添加自定义分类"
      :visible.sync="dialogType"
      width="30%"

      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose">
      <el-form
        :model="typeForm"
        ref="typeForm"
        :rules="releaseRules"
        class="editForm"
        label-width="80px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="分类名称"
              prop="name"
            >
              <el-input v-model="typeForm.name" placeholder="请输入自定义分类名称" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="项目描述"
              prop="itemTextarea"
            >
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入描述信息"
                maxlength="300"
                show-word-limit
                v-model="typeForm.itemTextarea">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogTypeClose">取 消</el-button>
    <el-button type="primary" @click="dialogTypeSave">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  export default {
    name: "salaryTemplate",
    components: { draggable },
    data(){
      return{
        dialogItem:false,
        dialogItemIndex:[],
        dialogTypeIndex:null,
        dialogType:false,
        popVisible:[],//下拉框是否显示
        opens:[],//是否收起
        salaryList:[
          {
            titleName:'模板名称',
            required:true,
            valueId:'1234567',
            model:'一个模板'
          },
          {
            titleName:'备注',
            required:false,
            valueId:'7654321',
            model:'一个备注'
          }
        ],
        rules: {
          1234567: [
            { required: true, message: '请输入模板名称', trigger: 'blur' },
            { min: 4, max: 40, message: '长度在 4 到 40 个字符', trigger: 'blur' }
          ]
        },
        showSys:true,
        lists:[ //数据
          {
            title:'基本信息',
            id:'a',
            remark:"",
            self:false,
            data:[
              {
                id:"1",
                name:'雇员姓名',
                explain:'我是一段解释',
                formula:'考勤工资+绩效工资+餐补+交通补助',
                type:"黄金糕",
                fixed:2,
                btn:0
              },
              {
                id:"2",
                name:'雇员姓名',
                explain:'我是一段解释',
                formula:'',
                type:"双皮奶",
                fixed:2,
                btn:1
              },
              {
                id:"3",
                name:'雇员姓名',
                explain:'我是一段解释',
                formula:'考勤工资+绩效工资+餐补+交通补助',
                type:"蚵仔煎",
                fixed:1,
                btn:2
              }
            ]
          },
          {
            title:'工资标准',
            id:'b',
            remark:"",
            self:false,
            data:[
              {
                id:"1",
                name:'雇员姓名',
                explain:'我是一段解释',
                formula:'考勤工资+绩效工资+餐补+交通补助',
                type:"龙须面",
                fixed:1,
                btn:0
              },
              {
                id:"2",
                name:'雇员姓名',
                explain:'我是一段解释',
                formula:'',
                type:"北京烤鸭",
                fixed:1,
                btn:0
              }
            ]
          },
          {
            title:'自定义分类',
            id:'c',
            remark:"我是一段备注啊",
            self:true,
            data:[
              {
                id:"1",
                name:'雇员姓名',
                explain:'我是一段解释',
                formula:'考勤工资+绩效工资+餐补+交通补助',
                type:"北京烤鸭",
                fixed:2,
                btn:2
              },
              {
                id:"2",
                name:'雇员姓名',
                explain:'我是一段解释',
                type:"北京烤鸭",
                fixed:1,
                formula:'',
                btn:2
              },
              {
                id:"3",
                name:'雇员姓名',
                explain:'我是一段解释',
                formula:'考勤工资+绩效工资+餐补+交通补助',
                type:"北京烤鸭",
                fixed:1,
                btn:2
              }
            ]
          }
        ],
        checked:[
          [],
          ['b1'],
          ['c3']
        ],
        checkLists:[
          {
            id:'check1',
            checkList:[{id:'a1',value:'雇员编号1'},{id:'a2',value:'证件类型'},{id:'a3',value:'银行卡开户名'},{id:'a4',value:'银行卡账号'},{id:'a5',value:'银行类别'}]
          },
          {
            id:'check2',
            checkList:[{id:'b1',value:'雇员编号2'},{id:'b2',value:'证件类型'},{id:'b3',value:'银行卡开户名'},{id:'b4',value:'银行卡账号'},{id:'b5',value:'银行类别'}]
          },
          {
            id:'check3',
            checkList:[{id:'c1',value:'雇员编号3'},{id:'c2',value:'证件类型'},{id:'c3',value:'银行卡开户名'},{id:'c4',value:'银行卡账号'},{id:'c5',value:'银行类别'}]
          }
        ],
        typeForm: {
          id:'',
          name: "",
          typeTextarea: "",
          self:true,
          data:{}
        },
        itemForm:{
          id:'',
          name:"",
          itemSelect:'',
          itemRadio:1,
          itemTextarea:''
        },
        options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
      }
    },
    created(){
      const len = this.lists.length;
      for(var i = 0;i<len;i++){
        this.popVisible.push({visible:false})
        this.opens.push({open:true})
      }
    },
    methods:{
      changeOpen(index){
        this.$set(this.opens[index],"open",!this.opens[index].open)
      },
      changeItem(checked,index){// checked是所有的选中值，index是当前选择框组的序号,当前选择组为checked[index]
        console.log(checked[index])
      },
      // 新增行
      addItem(index){
        this.dialogItem = true
        this.dialogItemIndex = [index,this.lists[index].data.length]
      },
      // 编辑行
      salaryEdit(index,itemIndex){
        this.$set(this.itemForm,"id",this.lists[index].data[itemIndex].id)
        this.$set(this.itemForm,"name",this.lists[index].data[itemIndex].name)
        this.$set(this.itemForm,"itemTextarea",this.lists[index].data[itemIndex].explain)
        this.$set(this.itemForm,"itemSelect",this.lists[index].data[itemIndex].type)
        this.$set(this.itemForm,"itemRadio",this.lists[index].data[itemIndex].fixed)
        this.dialogItem = true
        this.dialogItemIndex = [index,itemIndex]
      },
      // 编辑类别
      editType(index){
        this.dialogType = true
        this.$set(this.typeForm,"id", this.lists[index].id);
        this.$set(this.typeForm,"name", this.lists[index].title);
        this.$set(this.typeForm,"itemTextarea" , this.lists[index].remark);
        this.$set(this.typeForm,"self" ,  this.lists[index].self);
        this.$set(this.typeForm,"data" ,  this.lists[index].data);
        this.dialogTypeIndex=index
      },
      dialogItemClose(){
          this.$refs.itemForm.resetFields();
          this.dialogItem = false
      },
      dialogTypeClose(){
          this.$refs.typeForm.resetFields();
          this.dialogType = false
      },
      // 保存一行
      dialogItemSave(){
        // 保存
        let [index,itemIndex] = this.dialogItemIndex;
        let data = {}
        data.id = this.itemForm.id;
        data.name = this.itemForm.name;
        data.type = this.itemForm.itemSelect;
        data.fixed = this.itemForm.itemRadio;
        data.explain = this.itemForm.itemTextarea;
        data.formula = "";
        data.btn = 2 ;// 可编辑
        this.$set(this.lists[index].data,itemIndex,data)

          this.$refs.itemForm.resetFields()
          this.dialogItem = false
      },
      //新增分类
      addType(){
        this.dialogType = true
        this.dialogTypeIndex = this.lists.length
      },
      // 保存分类
      dialogTypeSave(){
        // 保存
        let index = this.dialogTypeIndex;
        let data = {}
        data.id = this.typeForm.id;
        data.title = this.typeForm.name;
        data.remark = this.typeForm.itemTextarea;
        data.self = true;
        data.data = this.typeForm.data;
        this.$set(this.lists,index,data)
        this.$set(this.popVisible,index,{visible:false})
        this.$set(this.opens,index,{open:true})
        const checkList ={
            id:'check4',
            checkList:[{id:'d1',value:'雇员编号1'},{id:'d2',value:'证件类型'},{id:'d3',value:'银行卡开户名'},{id:'d4',value:'银行卡账号'},{id:'d5',value:'银行类别'}]
          }
        this.$set(this.checkLists,index,checkList)
        this.$set(this.checked,index,[])
        this.$refs.typeForm.resetFields();
        this.dialogType = false
      },
      // 删除一行
      deleteData(index,indexItem){
        this.lists[index].data.splice(indexItem, 1);
      },
      // 删除类别
      deleteType(index){
        this.lists.splice(index, 1);
      }
    }
  };
</script>

<style scoped>
  .el-switch{
    margin-top: 0;
  }
  .salaryItem{
    padding:0 20px;
  }
  .salaryItemHeader{
    display: flex;
    margin-top: 20px;
    background-color: #efefef;
    line-height: 40px;
    color:#999;
    font-size: 16px;
    padding-left: 34px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
  }
  .salaryItemHeaderL {
    padding-left: 26px;
  }
  .salaryItemHeaderL p{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .salaryItemHeaderR{
    display: flex;
    justify-content: flex-end;
    color:#bbbbbb;
  }
  .salaryCheck{
    margin-right: 25px;
    padding: 0 5px;
  }
  .salaryCheckActve{
    background-color: #fff;
  }
  .salaryLine{
    border-bottom:1px solid #efefef;
    line-height: 40px;
    padding: 0 30px 0 34px;
  }
  .salaryLine svg{
    font-size: 16px;
  }
  .salaryTitle svg{
    margin-right: 10px;
  }
  .salaryTitle .zhanwei{
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  .salaryTitle span{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
  .salaryTitle{
    color:#000000;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .salaryTitle svg{
    color:#FF9600;
  }
  .salaryExplain{
    font-size: 12px;
    color: #999999;
    padding: 10px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .salaryFormula {
    color: #008AFF;
    font-size: 12px;
    cursor: pointer;
  }
  .hasFormula{
    color:#999999;
    cursor: auto;
  }
  .salaryFormula svg{
    margin-right: 10px;
    color:#008AFF;
  }
  .hasFormula svg{
    color:#75CF00;
  }
  .salaryBtn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    color:#008AFF;
  }
  .salaryBtnEdit{
    margin-right: 30px;
  }
  .salaryBtn svg{
    cursor: pointer;
  }
  .salaryItemHeaderC {
    color: #999999;
    font-size: 12px;
  }
  .salaryItemHeaderC p{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .salaryItemHeaderR div{
    cursor: pointer;
    margin-right: 30px;
  }
  .salaryItemHeaderR div:last-child{
    margin-right: 0px;
  }

  .addItem{
    margin: 20px auto 0;
    width: 160px;
    height: 30px;
    background-color: #DDDDDD;
    border-radius: 20px;
    font-size: 12px;
    color:#008AFF;
    align:center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .addItem svg{
    margin-right: 10px;
    font-size: 16px;
  }
  .salaryChecks{
    max-height: 300px;
    overflow-y: auto;
  }
  .salaryChecks>>>.el-checkbox{
    display: flex;
    align-items: center;
    margin-right: 0;
  }
  .salaryChecks>>>.el-checkbox__label{
    line-height: 26px;
    color:#000000;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 120px;
  }
  .salaryChecks>>>.el-checkbox__input{
    margin-top: 2px;
  }
  .addCheck{
    cursor: pointer;
    width: 120px;
    height: 30px;
    font-size: 12px;
    color:#008AFF;
    background: #EFEFEF;
    border-radius: 20px;
    text-align: center;
    line-height: 30px;
    margin: 10px auto 0;
  }
  .el-radio-group{
    margin: 0;
    height: 30px;
    display: flex;
    align-items: center;
  }
</style>
<style>
  .salaryCheckPop{
    width: 160px!important;
  }
.salaryCheckPop .popper__arrow{
    display: none;
  }
</style>
