import request from '@/router/axios';
const urlPrefix = ''

/**
 * 获取套餐列表信息
 * @param obj
 */
export function getServiceProductList() {
  return request({
    url:urlPrefix+ '/product/service-product-list-page',
    method: 'post',
    //data: obj
  })
}

//保存套餐名称
export function planSave(obj) {
  return request({
    url:urlPrefix+ '/product/plan-save',
    method: 'post',
    data: obj
  })
}

//保存服务项目明细
export function planDetailSave(obj) {
  return request({
    url:urlPrefix+ '/product/plan-detail-save',
    method: 'post',
    data: obj
  })
}

//查询套餐下明细列表
export function getPlanDetailList(obj) {
  return request({
    url:urlPrefix+ '/product/plan-detail-list-page',
    method: 'post',
    data: obj
  })
} 

//查询套餐下明细列表
export function getPlanDetailListById(obj) {
  return request({
    url:urlPrefix+ '/product/server/item/'+obj,
    method: 'get',
   // data: obj
  })
} 

//校验选中的服务项目是否存在已设置服务费用或合并费用
export function checkServiceItem(obj) {
  return request({
    url:urlPrefix+ '/product/check-service-item',
    method: 'post',
    data: obj
  })
}

//设置合并价格保存
export function addMergePriceSave(obj) {
  return request({
    url:urlPrefix+ '/product/merge-price-save',
    method: 'post',
    data: obj
  })
}

//删除服务项目
export function deleteServiceItem(obj) {
  return request({
    url:urlPrefix+ '/product/delete-service-item',
    method: 'post',
    data: obj
  })
}


export function deleteProduct(obj) {
  return request({
    url:urlPrefix+ '/product/delete-product',
    method: 'post',
    data: obj
  })
}
