<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-button
        type="primary"
        size="small"
        @click="showAddList"
        >
        <!--        v-if=" pms['2'].indexOf('test_button_2') != -1 "-->
        新增
      </el-button>
      <el-button
        type="primary"
        size="small"
        @click="importExcle"
      >
        导入
      </el-button>
      <el-button
        type="primary"
        size="small"
        @click="exportTable"
      >
        导出
      </el-button>
      <el-button
        type="primary"
        size="small"
        @click="donwloadCity"
      >
        下载城市文件
      </el-button>
      <el-button
        type="primary"
        size="small"
        @click="openInvoiceType"
      >
        发票拆分
      </el-button>
      <el-button
        type="primary"
        size="small"
        @click="openBalance"
      >
        使用余额
      </el-button>
    </div>
    <bsp-table
      :data.sync="list"
      ref="accountTable"
      @select-change="selectChange"
      @select-all="selectAll"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="_getListpage"
      :footer-method="footerMethod"
      :showPagination="listQuery.page"
      show-footer
      show-footer-overflow
      fixed="left"
    >
      <vxe-table-column
        type="selection"
        width="60"
        fixed="left"
      />
      <vxe-table-column
        fixed="left"
        type="index"
        title="序号"
        width="60"
      />
      <bsp-table-column
        title="操作"
        fixed="left"
        align="center"
      >
        <template slot-scope="scope">
          <div class="ciic-table-operate" >
            <el-button type="text"   @click="showDialog(scope.row)"><i class="el-icon-search el-icon--right"></i>详情</el-button>
            <el-button type="text"   @click="deleteRow(scope.row)"><i class="el-icon-delete el-icon--right"></i>删除</el-button>
          </div>
        </template>
      </bsp-table-column>
      <bsp-table-column
        field="id"
        title="客户维护客户维护客户维护客户维护客户维维护客户维护客户维护"
        filter-type="input"
        :showPagination="false"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column
        field="name"
        title="姓名姓名姓名姓名姓名姓名姓名姓名姓名"
        set-id="3"
        filter-type="input"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column
        field="idType"
        filter-type="select"
        title="证件类型证件类型证件类型证件类型证件类型证件类型"
        set-id="1001"
        setid="1001"
        feitid="1001"
        multiple="true"
        rem-num="10010006,10010007"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.idType | dictFilter(dicts) }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column
        field="idCard"
        title="身份证"
        sortable
        filter-type="input"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.idCard }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column
        field="gender"
        filter-type="select"
        title="性别"
        :optionProps="{value:'codeId',label:'label'}"
        :optionsList="sexList"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.gender | dictFilter(dicts) }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column
        field="birthday"
        title="出身日期"
        filter-rule="between"
        filter-type="datePicker"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.birthday }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column
        field="achievement"
        title="成绩"
        sortable
        filter-type="input"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.achievement }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column
        v-if="cityList.length>0"
        field="city"
        filter-rule="in"
        title="市区"
        align="center"
        filter-type="select"
        :optionProps="{value:'codeItemId',label:'codeItemName'}"
        :optionsList="cityList"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.city | dictFilter(serverDictsMap) }}</span>
        </template>
      </bsp-table-column>
    </bsp-table>
    <el-dialog
      ref="testDialog"
      width="70%"

      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
    >
      <demo-form
        ref="testForm"
        :list-form.sync="listForm"
      />
      <div
        class="marginTop30"
        style="text-align: center"
      >
        <el-button
          type="primary"
          @click="handlerListForm"
        >
          保存
        </el-button>
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
      </div>
    </el-dialog>

    <!--打开使用余额弹层-->
    <el-dialog
      ref="useBalanced"
      width="740px"

      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="使用余额"
      :visible.sync="dialogBalanceVisible"
      v-show="dialogBalanceVisible"
    >
      <div class="useBalanceBox">
        <div class="totalRow">
          <div class="balanceMonth">
            <p class="bal-month">05</p>
            <p class="bal-year">2020</p>
          </div>
          <div class="balanceTotals">
            <div class="bal-company">瑞士安美资源有限公司</div>
            <div class="bal-datas">
              <div class="bal-data">
                <p class="bal-data-count">￥12,000.00</p>
                <p>应付总额</p>
              </div>
              <div class="bal-data">
                <p class="bal-data-count">￥11,000.00</p>
                <p>应付总额</p>
              </div>
              <div class="bal-data">
                <p class="bal-data-count">￥8,000.00</p>
                <p>财务余额</p>
              </div>
              <div class="bal-data">
                <p  class="bal-data-count">￥20.00</p>
                <p>对公余额</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bal-table">
          <vxe-table
            ref="useBalance"
            :show-header="false"
            show-footer
            show-footer-overflow
            :footer-span-method="footerColspanMethod"
            :footer-method="footerBalance"
            size="small"
            height="350"
            row-id="id"
            :data="balanceLists"
            :expand-config="{iconOpen: 'vxe-icon--arrow-top',iconClose: 'vxe-icon--arrow-bottom',toggleMethod: toggleExpand}"
            @toggle-row-expand="toggleExpandChangeEvent"
          >
            <!---->
            <vxe-table-column field="name" title="名字" width="200"></vxe-table-column>
            <vxe-table-column field="needPay" title="应付总额"></vxe-table-column>
            <vxe-table-column field="factPay" title="实付总额"></vxe-table-column>
            <vxe-table-column field="useBalance" title="财务余额">
              <template v-slot="{ row }">{{row.useBalance.total | rounding}}</template>
            </vxe-table-column>
            <vxe-table-column field="usePubBalance" title="对公余额">
              <template v-slot="{ row }">{{row.usePubBalance.total | rounding}}</template>
            </vxe-table-column>
            <vxe-table-column type="expand" class="balanceExpand" width="40">
              <template  v-slot="{ row, rowIndex }">
                <div class="expandBox">
                    <ul class="expandLeft">
                      <li>
                        <span>操作模式：</span>
                        <span>{{ row.model }}</span>
                      </li>
                      <li>
                        <span>台账项目：</span>
                        <span>{{ row.item }}</span>
                      </li>
                      <li>
                        <span>应付金额：</span>
                        <span class="money">{{ row.needPay  | rounding}}</span>
                      </li>
                      <li>
                        <span>实付金额：</span>
                        <span class="money">{{ row.factPay  | rounding}}</span>
                      </li>
                    </ul>
                  <div class="expandRight">
                  <el-form :model="row.useBalance" :key="'useBalance'+ rowIndex"  class="useBalanceMoney"  :ref="'useBalance'+ rowIndex">
                    <el-form-item label="财务余额"  class="publicBalanceTotal">
                      <span  class="money">{{row.useBalance.total | rounding}}</span>
                    </el-form-item>
                        <!--这里需要把row的数据传入到rules中，但是我暂时没想到怎么把数据动态的传给自定义校验，并且这个数据还是可变的，只能先放在行内了-->
                       <el-form-item prop="deduct" :rules="[
                  { validator: ((rule, value, callback) => {  useBalanceVal(rule, value, callback,row.needPay,row.factPay,row.useBalance.total) }),trigger: 'change'}]">

                        <div class="expandRightIcon">
                          <el-input
                            placeholder="输入抵扣金额"
                            @input="useBalanceValInput(row.useBalance.deduct,row,rowIndex,'useBalance')"
                            v-model="row.useBalance.deduct">
                          </el-input>
                          <div class="deductIcon" v-if="row.useBalance.deduct > 0" @click="backData(row,rowIndex,'useBalance')">
                            <svg class="icon" aria-hidden="true">
                              <use xlink:href="#icon-zuojiantou"></use>
                            </svg>
                          </div>
                        </div>
                      </el-form-item>
                  </el-form>
                  <el-form :model="row.usePubBalance" :key="'usePubBalance'+ rowIndex" :ref="'usePubBalance'+ rowIndex">
                      <div class="usePubBalance">
                        <p class="publicBalanceTotal mb22">对公余额 <span class="money">{{row.usePubBalance.total | rounding}}</span></p>
                        <div class="publicBalance" v-for="(item,index) in row.usePubBalance.usePubBalanceList" v-bind:key="index">
                          <el-form-item :label="item.company"  class="publicBalanceTotal">
                            <span  class="money">{{item.money | rounding}}</span>
                          </el-form-item>
                          <el-form-item  :prop="'usePubBalanceList.'+index+'.odeduct'" :rules="[
                  { validator: ((rule, value, callback) => {  usePubBalanceVal(rule, value, callback,row.needPay,row.factPay,row.useBalance.total,item.money) }),trigger: 'change'}]">
                            <div class="expandRightIcon">
                              <el-input
                                placeholder="输入抵扣金额"
                                @input="useBalanceValInput(item.odeduct,row,rowIndex,index)"
                                v-model="item.odeduct">
                              </el-input>
                              <div class="deductIcon" v-if="item.odeduct > 0" @click="backData(row,rowIndex,index)">
                                <svg class="icon" aria-hidden="true">
                                  <use xlink:href="#icon-zuojiantou"></use>
                                </svg>
                              </div>
                            </div>
                          </el-form-item>
                        </div>
                      </div>
                  </el-form>
                  </div>
                </div>
              </template>
            </vxe-table-column>
          </vxe-table>
        </div>
      </div>
      <div
        class="marginTop30"
        style="text-align: center"
      >
        <el-button
          type="primary"
          @click="submitBalance"
        >
          保存
        </el-button>
        <el-button @click="closeUse">
          取消
        </el-button>
      </div>
    </el-dialog>
    <!--打开发票拆分弹层-->
    <div class="tanceng invoiceCeng" :class="{invoiceWidth:!invoiceSelect}" v-if="invoiceOpen">
      <div class="tancengTitle"><span>发票拆分</span> <div class="backBtn" v-if="!invoiceSelect" @click="backPrev">返回</div></div>

      <defaultInvoice
        v-if="!invoiceSelect&&invoiceActive === 1"
        :invoice-type-name="invoiceData.invoiceTypeName"
        :invoice-company-name="invoiceData.invoiceCompanyName"
        :invoice-active="invoiceActive"
        :names="invoiceData.splitLists.splitName"
        :datas="invoiceData.splitLists.splitList"
        :refname="invoiceData.invoiceRef"
        :sumItem="['total','xiaotax']"
        @backPrev="backPrev"
      ></defaultInvoice>
      <defaultInvoice
        v-if="!invoiceSelect&&invoiceActive === 3"
        :invoice-type-name="invoiceData2.invoiceTypeName"
        :invoice-company-name="invoiceData2.invoiceCompanyName"
        :invoice-active="invoiceActive"
        :names="invoiceData2.splitLists.splitName"
        :datas="invoiceData2.splitLists.splitList"
        :refname="invoiceData2.invoiceRef"
        :sumItem="['total','xiaotax']"
        @backPrev="backPrev"
      ></defaultInvoice>
    </div>
    <div class="zhezhao" v-if="invoiceOpen"></div>
  </div>
</template>

<script>
    import defaultInvoice from "@/components/bsp/bsp-dicts-invoice/default";
    import bspTable from "@/components/bsp/bsp-table/bsp-table";
    import bspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
    import { getListpage,updateListpage,addListpage,deleteData,donwloadCityFile } from  "@/api/demo/demo"
    import {getServerDictsMap, getServerDictsList, getDictsListBySetId} from '@/api/sys/code'
    import demoForm from "./demo-form";
    import { mapGetters } from 'vuex';
    import exportMethod from "@/util/export";
    import { downloadFile } from '@/util/util';
    export default {
        name: "Table",
        components: {demoForm, bspTableColumn, bspTable, defaultInvoice},
        created(){
          this.getServerDictsMap();
          this.getServerDictsList();
          this._getListpage()
          this.idcardList = this.dicts.codeList['1001']

        },
        mounted() {

        },
      computed: {
        ...mapGetters([
          'dicts',
          'pms'
        ]),
      },
        data(){
            return{
                buttons: {},
              // 使用余额--------------------------- 开始---------
              isClose:[],
              dialogBalanceVisible: false,
              balanceListsCopy:[],
              balanceLists:[
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },{
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },{
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },{
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },{
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },{
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },{
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },{
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },{
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 2000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:25.00,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 1000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:99.99,usePubBalanceList:[{company:'西安中智',money:20.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                },
                {
                  id: 1,
                  checked: false,
                  name: '自签客户/工资往来',
                  needPay: 20000.00,
                  factPay: 4000.00,
                  useBalance: {total:1500.00,deduct:200.00},
                  usePubBalance: {total:500.00,usePubBalanceList:[{company:'西安中智',money:50.00,odeduct:null},{company:'广州中智',money:20.00,odeduct:10.00}]},
                  model: '自签客户',
                  item: '工资往来',
                }
              ],
              // balanceRules:{
              //   deduct:[{ required: true, message: '测试2', trigger: 'blur' },
              //     { validator: ((rule, value, callback) => {  useBalanceVal(rule, value, callback,row.needPay,row.factPay) }),trigger: 'change'}],
              //   // usePubBalance:[{validator: ((rule, value, callback) => { usePubBalanceVal(rule, value, callback,row.needPay,item.money) }),trigger: 'input'}],
              // },
              // 使用余额--------------------------- 结束---------
              //发票拆分
                dialogFormVisible: false,
                serverDictsMap: null,
                serverDictsList:null,
                cityList:[],
                list: [],
                idcardList: [],
                sexList: [{
                    codeId: '1',
                    label: '已提交'
                }, {
                    codeId: '2',
                    label: '审核中'
                },{
                    codeId: '3',
                    label: '已完成'
                }],
                listQuery:{
                    limit: 50,
                    page: 1,
                    total: 0,
                    name: '234',
                    idCard: '',

                },
                listForm: {
                  fileIds:'',
                  photo:'',
                  fileId:'',
                },
                id:'',

                textMap: {
                    add: '新增',
                    update: '编辑'
                },
                dialogStatus: 'add',

              invoiceData:{
                invoiceRef:"invoiceRef",
                invoiceTypeName:"默认发票抬头",//拆分类型名称
                invoiceCompanyName:"瑞士安美资源有限公司",//公司名称
                splitLists: {
                  splitName:[
                    { type: 'index',title:"序号", width: 50 },
                    { field: 'number',width:"100px", title: '发票号' },
                    { field: 'item',width:"150px", title: '开票项目' },
                    { field: 'time',width:"80px", title: '开票月份',editRender: { name: 'input',attrs: {type: 'month'}} },
                    { field: 'type',width:"120px", title: '开票类型' },
                    { field: 'taitou',width:"200px", title: '发票抬头'  },
                    { field: 'total',width:"100px", title: '票据总金额',formatter:this.formatEmpty },
                    { field: 'tax',width:"50px", title: '税率' ,formatter:this.formatEmpty},
                    { field: 'xiaotax',width:"50px", title: '销项税' ,formatter:this.formatEmpty },
                    { field: 'remark',width:"100px", title: '备注',formatter:this.formatEmpty }
                  ],
                  splitList:[
                    {
                      number:"201901001",
                      item:"工资代收代付",
                      time:"2019/10",
                      type:"增值税普票",
                      taitou:"瑞士安美资源有限公司",
                      total:12.5,
                      tax:"",
                      xiaotax:null,
                      remark:""
                    },
                    {
                      number:"201901001",
                      item:"工资代收代付",
                      time:"2019/10",
                      type:"增值税普票",
                      taitou:"瑞士安美资源有限公司",
                      total:20,
                      tax:"5%",
                      xiaotax:null,
                      remark:""
                    },
                    {
                      number:"201901001",
                      item:"工资代收代付",
                      time:"2019/10",
                      type:"增值税普票",
                      taitou:"瑞士安美资源有限公司",
                      total:0,
                      tax:"5%",
                      xiaotax:null,
                      remark:""
                    },
                    {
                      number:"201901001",
                      item:"工资代收代付",
                      time:"2019/10",
                      type:"增值税普票",
                      taitou:"瑞士安美资源有限公司",
                      total:null,
                      tax:"5%",
                      xiaotax:null,
                      remark:""
                    }
                  ],
                  splitTotal:[]
                }
              },
              invoiceData2:{
                invoiceRef:"invoiceRef",
                invoiceTypeName:"多抬头拆分",//拆分类型名称
                invoiceCompanyName:"瑞士安美资源有限公司",//公司名称
                splitLists: {
                    splitName:[
                      { type: 'index',title:"序号", width: 50 },
                      { field: 'number',width:"100px", title: '发票号' },
                      { field: 'item',width:"150px", title: '开票项目',editRender: { name: 'input' } },
                      { field: 'time',width:"80px", title: '开票月份',editRender: { name: 'input',attrs: {type: 'month'}} },
                      { field: 'type',width:"120px", title: '开票类型',editRender: { name: 'input' } },
                      { field: 'taitou',width:"200px", title: '发票抬头',editRender: { name: 'input' }  },
                      { field: 'total',width:"100px", title: '票据总金额',editRender: { name: 'input', immediate: true},formatter:this.formatEmpty },
                      { field: 'tax',width:"50px", title: '税率' ,formatter:this.formatEmpty},
                      { field: 'xiaotax',width:"50px", title: '销项税' ,formatter:this.formatEmpty },
                      { field: 'remark',width:"100px", title: '备注',formatter:this.formatEmpty },
                      { title: '', width: "60px", showOverflow: true, slots: { default: 'operation' } }
                    ],
                    splitList:[
                      {
                        number:"201901001",
                        item:"工资代收代付",
                        time:"2019/10",
                        type:"增值税普票",
                        taitou:"瑞士安美资源有限公司",
                        total:12.5,
                        tax:"",
                        xiaotax:null,
                        remark:""
                      },
                      {
                        number:"201901001",
                        item:"工资代收代付",
                        time:"2019/10",
                        type:"增值税普票",
                        taitou:"瑞士安美资源有限公司",
                        total:20,
                        tax:"5%",
                        xiaotax:null,
                        remark:""
                      },
                      {
                        number:"201901001",
                        item:"工资代收代付",
                        time:"2019/10",
                        type:"增值税普票",
                        taitou:"瑞士安美资源有限公司",
                        total:0,
                        tax:"5%",
                        xiaotax:null,
                        remark:""
                      },
                      {
                        number:"201901001",
                        item:"工资代收代付",
                        time:"2019/10",
                        type:"增值税普票",
                        taitou:"瑞士安美资源有限公司",
                        total:null,
                        tax:"5%",
                        xiaotax:null,
                        remark:""
                      }
                    ],
                    splitTotal:[]
                  }
              },
              invoiceOpen:false,//是否打开发票拆分
              invoiceActive:1,//选中的拆分类型，默认为默认
              invoiceSelect:true,//选择拆分类型页
            }
        },
      filters: {
        // 使用余额--------------------------- 开始---------
        rounding (value) {
          return parseFloat(value).toFixed(2)
        }
        // 使用余额--------------------------- 结束---------
      },
      mounted(){
        // 使用余额--------------------------- 开始---------
        this.copyData()
        // 使用余额--------------------------- 结束---------
      },
        methods: {
          // 使用余额--------------------------- 开始---------
          toggleExpand ({expanded,rowIndex}) {
            const that = this;
            if (!expanded) {
              setTimeout(function(){alert("能不能让他等我执行完再return") ;
              return true},1500)

              // this.$nextTick(() => {
              //   // 首先校验财务余额
              //   this.$refs["useBalance"+ rowIndex].validate((valid) => {
              //     if (valid) {
              //       // 其次校验对公余额
              //       that.$refs["usePubBalance"+ rowIndex].validate((valid) => {
              //         if (valid) {
              //           console.log("通过")
              //           return true
              //         } else {
              //           that.$message.warning(`请正确填写数据！`);
              //           return false
              //         }
              //       });
              //     } else {
              //       that.$message.warning(`请正确填写数据！`);
              //       return false
              //     }
              //   })
              // })
            }else{
              return true
            }
          },
          // toggleExpandChangeEvent ({ row,rowIndex, expanded }) {
          //   // 打开即校验
          //   if(expanded){
          //     this.$nextTick(() => {
          //       // 首先校验财务余额
          //       this.$refs["useBalance"+ rowIndex].validate((valid) => {
          //         if (valid) {
          //         } else {
          //           return false;
          //         }
          //       });
          //       // 其次校验对公余额
          //       this.$refs["usePubBalance"+ rowIndex].validate((valid) => {
          //         if (valid) {
          //         } else {
          //           return false;
          //         }
          //       });
          //     })
          //   }
          // },
          // 在值发生改变时更新表尾合计
          updateFooterEvent (params) {
            let xTable = this.$refs.useBalance
            xTable.updateFooter()
          },
          useBalanceVal (rule, value, callback,needPay,factPay,total) {
            if (value > total) {
              callback(new Error('金额已超过财务余额，请检查输入！'));
            } else if(value > (needPay - factPay)) {
              callback(new Error('金额已超过待付金额，请检查输入！'));
            }else{
              callback();
            }
          },
          usePubBalanceVal (rule, value, callback,needPay,factPay,total,money) {
            if (value > money) {
              callback(new Error('金额已超过分支对公金额，请检查输入！'));
            } else if (value > total) {
              callback(new Error('金额已超过对公总余额，请检查输入！'));
            } else if(value > (needPay - factPay)){
              callback(new Error('金额已超过待付金额，请检查输入！'));
            }else{
              callback();
            }
          },
          // 表尾合计
          footerBalance ({ columns, data }) {
            const footerData = [
              columns.map((column, columnIndex) => {
                if (columnIndex === 0) {
                  return '实付总额：'
                }
                // 合并为一列显示
                if (['needPay'].includes(column.property)) {
                  console.log(this.$utils.sum(data, 'factPay'))
                  return this.$utils.sum(data, 'factPay').toFixed(2)
                }
                return null
              })
            ]
            return footerData
          },
          footerColspanMethod ({ columnIndex }) {
            if (columnIndex === 1) {
              return {
                rowspan: 1,
                colspan: 5
              }
            } else if (columnIndex > 1) {
              return {
                rowspan: 0,
                colspan: 0
              }
            }
          },
          // 格式化输入
          useBalanceValInput(val,row,rowIndex,type){
            const temp = val;
            if(val === ""){
              val = "0"
            }
            // 限制两位小数数字
            val = val.replace(/[^\d.]/g,"");  //清除“数字”和“.”以外的字符
            val = val.replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的
            val = val.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
            val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');//只能输入两个小数
            if(val.indexOf(".")< 0){//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
              val= parseFloat(val);
            }else if(val.indexOf(".")> 1 && val[0] === "0"){//控制0X.XX
              val= parseFloat(val);
            }else if(val.indexOf(".") === 0 ){//控制.XX
              val= parseFloat("0"+val);
            }

            if( type== 'useBalance'){
              if(temp === ""){
                this.$set(this.balanceLists[rowIndex].useBalance,"deduct","")
              }else{
                this.$set(this.balanceLists[rowIndex].useBalance,"deduct",val)
              }
            }else{
              if(temp === "") {
                this.$set(this.balanceLists[rowIndex].usePubBalance.usePubBalanceList[type], "odeduct", "")
              }else{
                this.$set(this.balanceLists[rowIndex].usePubBalance.usePubBalanceList[type], "odeduct", val)
              }
            }

            let total = 0;
            // 累加所有的对公余额
            for(var item in this.balanceLists[rowIndex].usePubBalance.usePubBalanceList){
              if(this.balanceLists[rowIndex].usePubBalance.usePubBalanceList[item].odeduct){
                total += parseFloat(this.balanceLists[rowIndex].usePubBalance.usePubBalanceList[item].odeduct)
              }
            }
            let copyTotal = 0;
            // 累加所有修改前的对公余额
            for(var item in this.balanceListsCopy[rowIndex].usePubBalance.usePubBalanceList){
              if(this.balanceListsCopy[rowIndex].usePubBalance.usePubBalanceList[item].odeduct){
                copyTotal += parseFloat(this.balanceListsCopy[rowIndex].usePubBalance.usePubBalanceList[item].odeduct)
              }
            }

            const balanceActualPay = this.balanceListsCopy[rowIndex].factPay?this.balanceListsCopy[rowIndex].factPay:0;
            const useBalance = parseFloat(this.balanceLists[rowIndex].useBalance.deduct)?parseFloat(this.balanceLists[rowIndex].useBalance.deduct):0;
            const useBalanceCopy = parseFloat(this.balanceListsCopy[rowIndex].useBalance.deduct)?parseFloat(this.balanceListsCopy[rowIndex].useBalance.deduct):0;
            const factPay = balanceActualPay + total - copyTotal + useBalance - useBalanceCopy;

            this.$set(this.balanceLists[rowIndex],"factPay", factPay)
            // 计算合计
            this.updateFooterEvent();
          },
          // 为了保证计算可回退，保存一份实付和抵扣的数据,加载或更新完数据复制一份
          copyData(){
            this.balanceListsCopy = JSON.parse(JSON.stringify(this.balanceLists))
          },
          // 清空数据
          backData(row,rowIndex,type){
            if( type== 'useBalance'){
              this.$set(this.balanceLists[rowIndex].useBalance,"deduct",0);
            }else{
              this.$set(this.balanceLists[rowIndex].usePubBalance.usePubBalanceList[type],"odeduct",0)
            }
            this.useBalanceValInput("",row,rowIndex,type);
          },
          // 校验全部
          testAll(i,total){
              let flag = true;
              // 首先校验财务余额
              this.$refs["useBalance"+ i].validate((valid) => {
                if (valid) {

                } else {
                  flag = false;
                  this.isClose.push(i)
                  return false;
                }
              });
              // 其次校验对公余额
              this.$refs["usePubBalance"+ i].validate((valid) => {
                if (valid) {
                } else {
                  flag = false;
                  this.isClose.push(i)
                  return false;
                }
              });
            if(i > 0){
              this.testAll(i - 1,total)
            }
            if(i == 0){
              // 去重
              const close = [...new Set(this.isClose)];
              // 关闭没有错的行
              for (var i = 0; i < total + 1; i++) {
                if (!close.includes(i)) {
                  this.$refs.useBalance.toggleRowExpansion(this.balanceLists[i]);
                }
              }
            }
          },
          // 提交
          submitBalance() {
            const that = this;
            this.$nextTick(() => {
              that.$refs.useBalance.clearRowExpand().then(function(){

                that.$refs.useBalance.setAllRowExpansion(true).then(function(){
                  that.$nextTick(() => {
                    const rowsNo = that.balanceLists.length - 1;
                    that.isClose = [];
                    setTimeout(function(){that.testAll(rowsNo,rowsNo),1000})
                  })
                })
              })

            })
          },
          closeUse(){
            // 重置数据
            const len = this.balanceLists.length;
            for(var i=0;i < len;i++){
              this.$refs['useBalance'+i].resetFields();
              this.$refs['usePubBalance'+i].resetFields();
            }
            // 关闭所有行
            this.$refs.useBalance.clearRowExpand()
            this.dialogBalanceVisible = false
          },
          // 使用余额--------------------------- 结束---------

          // 格式化空值
          formatEmpty({ cellValue }){
            if(cellValue){
              return cellValue
            }else{
              return "--"
            }
          },
          // 格式化空值
          formatXiaotax({ cellValue, row, column }){
            if(row.total&&row.tax){
              return row.total*parseFloat(row.tax)/100
            }else{
              return "--"
            }
          },
          //回到上一层
          backPrev(){
            this.invoiceSelect = true;
          },

          footerMethod ({ columns, data }) {

            return [

              columns.map((column, columnIndex) => {
                if (columnIndex === 0) {
                  return '合计'
                }
                if (['achievement'].includes(column.property)) {
                  console.log(column.property)
                  return this.$utils.sum(data, column.property)
                }
                return null
              })
            ]
          },





          getServerDictsMap() {
            getServerDictsMap().then(res => {
              console.log("排除后的项")
              console.log(res)
              this.serverDictsMap = res.data.data
            })
          },
          getServerDictsList() {
            getServerDictsList().then(res => {
              console.log("排除后的代码集")
              console.log(res)
              this.serverDictsList = res.data.data;
              this.cityList = this.serverDictsList[1026]
            })
          },
            //复选框单选事件
            selectChange({ checked, row }){
                console.log(checked, row)
            },
            //复选框全选事件
            selectAll({checked, selection}){
                console.log(checked, selection)
            },
          //导出表格
          exportTable() {
            exportMethod({columns: this.$refs['accountTable'].tableColums, ...this.listQuery}, '/cus');
          },
            //导入excle文件
            importExcle() {
                // 去excle上传页面
                this.$router.push({
                    path: '/import/excle'
                })

            },
            //获取列表数据
            _getListpage(){
                getListpage(this.listQuery).then(res => {
                  this.listQuery.total = res.data.total
                  this.list = res.data.records
                })
            },
            //展示新增表格
            showAddList(){
                // if(this.$refs['listForm']){
                //     this.$refs['listForm'].resetFields();
                // }
                this.listForm = {}
                this.dialogFormVisible = true
                this.dialogStatus='add'
            },
            //展示编辑表单框
            showDialog(row){
                this.dialogFormVisible = true
                this.dialogStatus='update'
                this.listForm = row

            },
            //删除数据
            deleteRow(row){
                this.id = row.id;
                deleteData(this.id).then(res=>{
                  this.$message.success("删除成功")
                  this._getListpage();
                })
            },
            addList() {
                console.log(this.listForm)
                let childForm = this.$refs.testForm.$refs.listForm
                childForm.validate((valid) => {
                    if (valid) {
                        addListpage(this.listForm).then(res => {
                            this.dialogFormVisible = false
                            this.$message.success('新增成功');
                            this._getListpage();
                        })
                    }
                })
            },
            //编辑表格
            updateListForm() {
                let childForm = this.$refs.testForm.$refs.listForm
                console.log(this.listForm)
                childForm.validate((valid) => {
                    if (valid) {
                        updateListpage(this.listForm).then(res => {
                            this.dialogFormVisible = false
                            this.$message.success('修改成功');
                            this._getListpage();
                        })
                    }
                })

            },
            handlerListForm() {
                if (this.dialogStatus == 'add') {
                    this.addList()
                } else if (this.dialogStatus == 'update') {
                    this.updateListForm()
                }
            },
          donwloadCity(){
            donwloadCityFile().then(res=>{
              downloadFile(res)
            })
          },
          // 打开发票拆分
          openInvoiceType(){
            this.invoiceOpen = true;
          },
          //发票
          changeInvoiceType(n){
            this.invoiceActive !== n ? this.invoiceActive = n:null;
          },
          splitDetail(){
            this.invoiceSelect = false;

          },
          splitCancel(){
            this.invoiceOpen = false;
          },
          // 打开使用余额
          openBalance(){
            this.dialogBalanceVisible = true;
          }
        }
    }
</script>

<style scoped>
/*使用余额*/

</style>
