import request from '@/router/axios';

/**
 * 分页查询政策信息
 * @param query
 */
export function querypolicyList(obj) {
  return request({
    url: '/policy/page',
    method: 'post',
    //params: obj
    data:obj
  });
}

/**
 * 保存询政策信息
 * @param query
 */
export function savePolicyBo(obj) {
    return request({
      url: '/policy',
      method: 'post',
      data:obj
    });
}

/**
 * 删除询政策信息
 * @param query
 */
export function deletePolicyBo(id) {
    return request({
      url: '/policy/'+id,
      method: 'delete',
      //data:obj
    });
}


/**
 * 根据政策id查询政策信息
 * @param query
 */
export function queryPolicyBo(id) {
    return request({
      url: '/policy/'+id,
      method: 'get',
      //data:obj
    });
}

/**
 * 修改政策信息
 * @param query
 */
export function updatePolicyBo(obj) {
    return request({
      url: '/policy',
      method: 'put',
      data:obj
    });
}
/**
 * 修改政策状态
 * @param query
 */
export function updatePolicyStatusIds(obj) {
    return request({
      url: '/policy/stauts',
      method: 'put',
      data:obj
    });
}

/**
 * 查询政策置顶数量 
 * @param query
 */
export function queryPolicyTopCount() {
    return request({
      url: '/policy/top/count',
      method: 'get',
      //data:obj
    });
}
