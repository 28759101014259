<template>
  <el-row class="ciicFormContain ciicDynamicForm" >
   <el-row>
      <el-col>
        <el-form ref="form2" :span="12" class="editFrom" :model="validateForm" label-width="168px" :rules="rules">
           <el-row v-for="(ItemList) in dynamicFormItemList" :key="ItemList.groupName"  class="marginBottom20">
               <h3 class="marginBottom20 font18">{{ItemList.groupName}}</h3>
                <!-- 动态的 -->
                <el-row>
                  <el-col
                  v-for="(list,index) in ItemList.dynamicFormVOList" :key="list.itemId" :span="list.itemType==4||list.itemType==10 ? 24:12"
                  :class="{'hidden':list.visiable || !isShow[list.valueId+'_show'],'titleDot':list.titleNull}">
                    <bspFormItem :list="list" :index="index" :model.sync="formData[list.valueId]" :ifs ="isShow[list.valueId+'_show']" />
                  </el-col>
                </el-row>
            </el-row>
        </el-form>
      </el-col>
    </el-row>
      <el-row >
        <el-col :span="12" :offset="6">
          <div class="center marginTop30 marginBottom20">
            <el-button type="primary" @click="submitHandler('form2')" size="mini">提交</el-button>
            <el-button type="primary" @click="isShowchange()" size="mini">提交</el-button>
          </div>
        </el-col>
    </el-row>
  </el-row>

</template>
<script>
import bspDictsSelect from "@/components/bsp/bsp-dicts-select/bsp-dicts-select";
import { isvalidatemobile, cardid } from '@/util/validate';
import { getDynamicFormValue} from  "@/api/demo/demo";
import { getDynamicForm,DynamicFormInsert,DynamicFormUpdate } from  "@/api/common/common";
import bspFormItem from '@/wage-components/bsp-form-item/index';
  export default {

    data() {
      return {
        dynamicFormItemList:'',
        ifHaveTextarea:0,
        noVisible:0,
        itemTypeArr:[],
        parameter:{   //getDynamicForm参数
            operateType:this.$route.query.operateType,//表单是新增还是修改判断,0是新增，1是修改
            infoSetId:'EMP_INFO'
        },
        rules:{
            titleName: [
                { required: true, message: '请输入用户名', trigger: 'blur' },
            ],
        },
        formData:{},
        isShow:{},
        pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
        validateForm:{},
    }
    },
    components:{
        bspDictsSelect,
        bspFormItem
    },
    created(){
      this._getDynamicForm();
      if(this.parameter.operateType==1){ //修改
          this._getDynamicFormValue('90b0600296ae4d3dadbd269dcef23a3a');
      }
      console.log(this.isShow,'xianshi');

    },
    mounted(){
    },
    provide(){
      return {
            $rules: this.rules
        }
    },
    methods: {
        submitHandler(formName){
          console.log("dong tai ti jiao ")
            this.$refs[formName].validate((valid) => {
              if(valid){
                //修改保存
                let obj = {};
                obj.infoSetId = this.parameter.infoSetId;
                obj.formData = this.formData
                if(this.parameter.operateType==0){ //新增
                  DynamicFormInsert(obj,'sys').then(res =>{
                    console.log(res)
                    this.$alert(res.data.data.msg, "提示", {
                      confirmButtonText: '确定',
                    })
                  })
                }else{
                  DynamicFormUpdate(obj,'sys').then(res =>{
                    console.log(res);
                    this.$alert(res.data.data.msg, "提示", {
                      confirmButtonText: '确定',
                    })
                  })
                }
              }

            })
        },
      //获取表单各项数据
        _getDynamicForm(){
            getDynamicForm(this.parameter).then(res => {
             let insertForm = {
               codeSetId: null,
               colspan: 2,
               infoItemEvent: "",
               inputType: null,
               itemId: "",
               itemLength: null,
               itemName: "",
               itemType: "1000",
               itemVType: "",
               null: true,
               read: false,
               refUrl: null,
               titleName: "",
               titleNull: true,
               valueId: "",
               visiable: false
             };
              res.data.dynamicFormItemList.forEach(item=>{
                this.ifHaveTextarea =0;
                this.noVisible = 0;

                item.dynamicFormVOList.forEach(i=>{
                  this.itemTypeArr.push(i.itemType);
                  if(i.itemType == 4||i.itemType==10){
                    this.ifHaveTextarea+=1;
                  }
                  if(i.visiable== true){
                    this.noVisible+=1;
                  }
                  let showke=i.valueId+'_show';
                  this.isShow[showke] = true;
                });
                if((item.length-this.ifHaveTextarea-this.noVisible)% 2 != 0&&this.ifHaveTextarea!=0){
                  let insertIndex = this.itemTypeArr.indexOf("4");
                  let insertIndex1 = this.itemTypeArr.indexOf("10");
                  if(insertIndex!= -1 && insertIndex1 ==-1){
                    item.dynamicFormVOList.splice(insertIndex,0,insertForm);
                  }else if(insertIndex == -1 && insertIndex1 !=-1){
                    item.dynamicFormVOList.splice(insertIndex1,0,insertForm);
                  }else if(insertIndex != -1 && insertIndex1 !=-1){
                    if(insertIndex>insertIndex1){
                      item.dynamicFormVOList.splice(insertIndex1,0,insertForm);
                    }else {
                      item.dynamicFormVOList.splice(insertIndex,0,insertForm);
                    }
                  }
                };
              });
              console.log(this.isShow,'xianshi22');
              this.dynamicFormItemList = res.data.dynamicFormItemList;
              if(this.parameter.operateType==0){ //新增
                 res.data.dynamicFormItemList.forEach(item =>{
                  item.dynamicFormVOList.map(list =>{
                      this.formData[list.valueId] = ''
                   })
                 });
            }
          })
        },
        //获取表单回显值
        _getDynamicFormValue(empId){
            getDynamicFormValue(empId).then(res => {
            this.formData = res.data.data;
          })
        },
      isShowchange(){
        this.$forceUpdate();
        this.isShow.E001_name_show = false
      }
    }
  }
</script>
<style lang="scss">
 .hidden{
   display: none;
 }

</style>
