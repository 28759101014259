<template>
  <div>
    <bsp-table
      :data.sync="empDataList"
      :tree-config="true"
      @select-change="selectChange"
      @select-all="selectAll"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="loadEmpList"
      highlight-hover-row
      max-height="500"
      show-overflowwidth
      show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
    >
      <vxe-table-column
        type="selection"
        width="60"
        fixed="left"
        align="center"
      />
      <vxe-table-column
        title="序号"
        type="index"
        width="60"
        fixed="left"
        align="center"
      />
      <bsp-table-column title="雇员ID" field="ID" fixed="left" filter-type="input" v-if="false">
        <template slot-scope="scope">
          <span>{{ scope.row.ID }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="计算方式" field="caclWay" fixed="left" filter-type="input" v-if="false">
        <template slot-scope="scope">
          <span>{{ scope.row.caclWay }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="客户名称" field="cusname" filter-type="input" fixed="left">
        <template slot-scope="scope">
          <span>{{ scope.row.CUSNAME }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="雇员编号" field="e001208" filter-type="input" fixed="left">
        <template slot-scope="scope">
          <span>{{ scope.row.E001208 }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="雇员名称" field="e001200" filter-type="input" fixed="left">
        <template slot-scope="scope">
          <span>{{ scope.row.E001200 }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="雇员身份" field="e001213" fixed="left">
        <template slot-scope="scope">
          <span>{{ scope.row.E001213|dictFilter(dicts) }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="证件类型" field="e001202" fixed="left">
        <template slot-scope="scope">
          <span>{{ scope.row.E001202|dictFilter(dicts) }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="证件号码" field="e001203" filter-type="input" fixed="left">
        <template slot-scope="scope">
          <span>{{ scope.row.E001203 }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="雇员性别" field="e001201" fixed="left">
        <template slot-scope="scope">
          <span>{{ scope.row.E001201|dictFilter(dicts) }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="电子邮件" field="e001204" filter-type="input" fixed="left">
        <template slot-scope="scope">
          <span>{{ scope.row.E001204 }}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="累计起征点计算方式" field="e001278" fixed="left">
        <template slot-scope="scope">
          <span>{{(scope.row.E001278 === null || scope.row.E001278 === 'PT') ? '薪税计算逻辑' : '税局计算逻辑'}}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="是否扣除减除费用" field="e001279" fixed="left">
        <template slot-scope="scope">
          <span>{{(scope.row.E001279 === null || scope.row.E001279 === '00900') ? '否' : '是'}}</span>
        </template>
      </bsp-table-column>
      <bsp-table-column title="操作" fixed="right" align="center">
        <template slot-scope="scope">
          <div class="ciic-table-operate">
            <el-button type="text" @click="taxationConf(scope.row)">
              起征点配置
            </el-button>
          </div>
        </template>
      </bsp-table-column>
    </bsp-table>

    <el-dialog v-dialogDrag width="30%"
               title="设置雇员起征点"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :visible.sync="empDialogVisible">
      <el-form ref="form2" class="editFrom" :model="validateForm" label-width="168px" :rules="rules">
        <el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="客户名称">
                <el-input v-model.trim="parameter.cusName" disabled="true"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="雇员编号">
                <el-input v-model.trim="parameter.empCode" disabled="true"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="雇员名称">
                <el-input v-model.trim="parameter.empName" disabled="true"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-col :span="20">
            <el-form-item label="累计起征点计算方式">
              <el-radio @click.native="changeCaclRadio('PT')" v-bind:disabled="isDisabled" v-model="parameter.caclWay"
                        label="PT">薪税计算逻辑
              </el-radio>
              <el-radio @click.native="changeCaclRadio('TB')" v-bind:disabled="isDisabled" v-model="parameter.caclWay"
                        label="TB">税局计算逻辑
              </el-radio>
            </el-form-item>
          </el-col>
          <el-col v-if="isTB" :span="20">
            <el-form-item label="是否扣除减除费用">
              <el-radio v-model="parameter.isToDeductExpenses" label="00901">是</el-radio>
              <el-radio v-model="parameter.isToDeductExpenses" label="00900">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-alert title="需要注意的事项" type="warning">
        <template slot='title'>
          <div class="iconSize">温馨提示:</div>
          <div class="iconSize">1、仅支持税金所属年度内未导入薪资明细的客户修改计算方式</div>
          <div class="iconSize">2、修改为税局方式后有一次变更成薪税方式的机会，使用薪税方式则不能反向修改</div>
          <div class="iconSize">3、客户设置后，雇员默认使用客户的计算方式，也可以单独配置雇员计算方式</div>
        </template>
      </el-alert>
      <div class="marginTop20" style="text-align: center">
        <el-button type="primary" @click="save"> 保存</el-button>
        <el-button @click="cancel"> 取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {batchUpdateCusCaclWay, searchEmpList, updateCusCaclWay, updateEmpCaclWay, checkEmpIsImported} from "@/api/cus/payableConf";
  import {mapGetters} from "vuex";

  export default {
    name: 'empTaxationConf',
    inject: ["reload"],
    components: {
      BspTable,
      BspTableColumn,
    },
    computed: {
      ...mapGetters(["dicts", "userInfo"])
    },
    props: {
      empDataList: {
        type: Array,
        default() {
          return []
        }
      },
      cusIdList: [],
    },
    created() {
      this.loading = false;
      console.log('created')
      this.loadEmpList();
    },

    data() {
      return {
        loading: false, //是否刷新
        isVisible: "true",
        isDisabled: false,
        empMultipleSelection: [],
        // 用于接收后台返回的分页数据
        list: [],
        //分页查询对象
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          cusIdList: [],
        },
        isTB: false,
        dialogTitle: '修改累计起征点计算方式',
        batchDialogTitle: '批量配置起征点计算方式',
        hasCusPayableRole: false,
        dialogVisible: false,
        empDialogVisible: false,
        parameter: {
          id: '',
          cusName: '',
          empCode: '',
          empName: '',
          E001278: '',
          isToDeductExpenses: '',
          empIdList: [],
          isImported: '',
        },
      };
    },

    methods: {
      loadEmpList() {
        this.$emit("getSelectData", [], this.empDataList);
        console.log('loadList')
        // this.loading = true;
        //每年1月份才可以进行修改
        this.isDisabled = false;
        /*      if(new Date().getMonth() + 1 !== 1){
                this.isDisabled = true;
                this.dialogTitle = '查看累计起征点计算方式'
              }*/
        if (
          this.listQuery.cusName != "" &&
          typeof this.listQuery.cusName != "undefined"
        ) {
          console.log("this.listQuery.cusName==" + this.listQuery.cusName);
          this.listQuery.page = 1;
        }
        this.listQuery.cusIdList = this.cusIdList;
        searchEmpList(this.listQuery).then(res => {
          debugger
          console.log("结果res=====>", res);
          this.empDataList = res.data.records;
          this.listQuery.total = res.data.total;
          this.loading = false;
        });
        debugger
        this.empMultipleSelection = [];
      },

      selectChange(value) {
        console.log(value)
        if (value.selection) {
          this.empMultipleSelection = []
          for (let index = 0; index < value.selection.length; index++) {
            const element = value.selection[index]
            this.empMultipleSelection.push(element)
          }
          console.log('this.empMultipleSelection:' + this.empMultipleSelection)
        }
        this.$emit("getSelectData", this.empMultipleSelection, this.empDataList);
      },
      //复选框全选事件
      selectAll({checked, selection}) {
        if (checked) {
          this.empMultipleSelection = selection
        } else {
          this.empMultipleSelection = []
        }
        console.log('this.empMultipleSelection:' + this.empMultipleSelection)
        this.$emit("getSelectData", this.empMultipleSelection, this.empDataList);
      },

      changeCaclRadio(cacl) {
        debugger
        if (cacl === 'TB') {
          this.isTB = true;
          this.parameter.caclWay = 'TB';
          this.parameter.isToDeductExpenses = '00901';
        } else {
          this.parameter.caclWay = 'PT';
          this.isTB = false;
          this.parameter.isToDeductExpenses = '00900';
        }
      },

      batchTaxationConf() {
        if (this.empMultipleSelection.length > 0) {
          this.batchDialogVisible = true;
        } else {
          this.$message.warning('请至少选择一条数据');
        }
      },

      taxationConf(row) {
        this.empDialogVisible = true;
        this.parameter.id = row.ID;
        this.parameter.cusName = row.CUSNAME;
        this.parameter.empCode = row.E001208;
        this.parameter.empName = row.E001200;
        debugger
        this.parameter.caclWay = (row.E001278 === '' || row.E001278 === undefined || row.E001278 === null) ? 'PT' : row.E001278;
        if (this.parameter.caclWay === 'TB') {
          this.isTB = true;
          this.parameter.isToDeductExpenses = row.E001279;
        } else {
          this.isTB = false;
          this.parameter.isToDeductExpenses = '00900';
        }
        debugger
      },

      /**
       * 取消设置累计起征点计算方式
       */
      cancel() {
        this.empDialogVisible = false;
      },

      save() {
        this.loading = true;
        this.cancel();
        this.parameter.empIdList = [];
        this.parameter.empIdList.push(this.parameter.id);
        checkEmpIsImported(this.parameter).then(res=>{
          if (res.data.code === 0) {
            this.parameter.isImported = res.data.msg;
            updateEmpCaclWay(this.parameter).then(res => {
              debugger
              this.empDialogVisible = false;
              if (res.data.code === 0) {
                this.$message.success("保存成功");
                this.cancel();
                this.loadEmpList();
              } else {
                this.$message.error(res.data.msg);
              }
              this.empDialogVisible = false;
            });
          } else {
            this.$message.error("当前雇员本年度已经被导入数据，不允许修改。" + res.data.msg);
          }
        })
        this.loading = false;
      },
    }
  };
</script>

<style scoped></style>
