import { render, staticRenderFns } from "./paySlipItem.vue?vue&type=template&id=4ee8e6ea&scoped=true&"
import script from "./paySlipItem.vue?vue&type=script&lang=js&"
export * from "./paySlipItem.vue?vue&type=script&lang=js&"
import style0 from "./paySlipItem.vue?vue&type=style&index=0&id=4ee8e6ea&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ee8e6ea",
  null
  
)

export default component.exports