<template>
  <div class="printBox">
  <div>
    <div>支付单编号：{{this.data.paymentBillsBO.payCode}}</div>
    <div>
      <!-- 2021.11 历史问题：后台不会返回bankName字段，此处代码不会执行，故注释 -->
 <!--      <div v-for="(item,idx) in this.data.bankList" :key="idx" style="text-align: center">-->
<!--        <div v-if="item.bankName != null && item.bankName.indexOf('对公') === -1 && item.bankName.indexOf('对私') === -1">-->
<!--          {{item.bankName}}支付：{{item.money}}/{{item.perNum}}人-->
<!--          <span style="float: right">-->
<!--            网银批次号：_______________________-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div style="text-align: center; font-weight: bolder">对私薪资支付单</div>
    <div>
      <table border="1" style="width: 100%">
        <tr>
          <th>支出单编号</th>
          <th>客户名</th>
          <th>报税名义</th>
          <th>客户模式</th>
          <th>代报销往来</th>
          <th>工资成本</th>
          <th>工资往来</th>
          <th>税金</th>
          <th>税金托收</th>
          <th>实发金额</th>
          <th>税后扣款项</th>
          <th>备注</th>
        </tr>
        <tr v-for="(item,idx) in this.data.cusList" :key="idx" style="text-align: center">
          <td>{{item.payoutCode}}</td>
          <td>{{item.cusName}}</td>
          <td>{{item.taxName}}</td>
          <td>{{item.cusMode}}</td>
          <td>{{item.daibaoxiao | moneyFilterStr}}</td>
          <td>{{item.cost | moneyFilterStr}}</td>
          <td>{{item.wanglai | moneyFilterStr}}</td>
          <td>{{item.tax | moneyFilterStr}}</td>
          <td>{{item.taxAngent | moneyFilterStr}}</td>
          <td>{{item.finalMoney | moneyFilterStr}}</td>
          <td>{{item.koukuan | moneyFilterStr}}</td>
          <td>{{item.remark}}</td>
        </tr>
        <tr style="text-align: center">
          <td></td>
          <td>合计金额</td>
          <td></td>
          <td></td>
          <td>{{(this.sums.daibaoxiao / 100) | moneyFilter}}</td>
          <td>{{(this.sums.cost / 100) | moneyFilter}}</td>
          <td>{{(this.sums.wanglai / 100) | moneyFilter}}</td>
          <td>{{(this.sums.tax / 100) | moneyFilter}}</td>
          <td>{{(this.sums.taxAngent / 100) | moneyFilter}}</td>
          <td>{{(this.sums.finalMoney / 100) | moneyFilter}}</td>
          <td>{{(this.sums.koukuan / 100) | moneyFilter}}</td>
          <td></td>
        </tr>
      </table>
    </div>
    <div style="text-align: center">[{{this.data.paymentBillsBO.payCode}}]</div>
    <div class="divButtonRigth">
      <el-button type="primary" size="small" fixed="right" @click="print">打印</el-button>
      <el-button type="primary" size="big" fixed="right" @click="close">关闭</el-button>
    </div>
  </div>
  </div>
</template>

<script>

  import {
    printFinancialPayment
  } from '@/api/payment/payment';

  export default {
    name: "printEmployeeDetail",
    created() {
      this.load();
    },
    mounted(){
      const that = this;
      this.$nextTick(()=>{
        document.getElementsByTagName("body")[0].classList.add("print");
      })

      if (window.matchMedia) {
        var mediaQueryList = window.matchMedia('print');

        //为印添加事件
        mediaQueryList.addListener(function(mql) {
          if (mql.matches) {
          } else {
            that.afterPrint();
          }
        });
      }
      //打印后事件
      window.onafterprint = that.afterPrint;

    },
    data() {
      return {
        // 页面数据
        data: {
          paymentBillsBO: [],
          cusList: [],
          bankList: []
        },
        //分页查询对象
        listQuery: {
          paymentId: this.$route.query.paymentId,
        },
        sums: {
          daibaoxiao: 0,
          cost: 0,
          wanglai: 0,
          tax: 0,
          taxAngent: 0,
          finalMoney: 0,
          koukuan: 0
        }
      }
    },
    methods: {
      load() {
        printFinancialPayment(this.listQuery).then(res => {
          this.data = res.data;
          for (let cus in this.data.cusList) {
            this.sums.daibaoxiao += Number(this.data.cusList[cus].daibaoxiao) * 100;
            this.sums.cost += Number(this.data.cusList[cus].cost) * 100;
            this.sums.wanglai += Number(this.data.cusList[cus].wanglai) * 100;
            this.sums.tax += Number(this.data.cusList[cus].tax) * 100;
            this.sums.taxAngent += Number(this.data.cusList[cus].taxAngent) * 100;
            this.sums.finalMoney += Number(this.data.cusList[cus].finalMoney) * 100;
            this.sums.koukuan += Number(this.data.cusList[cus].koukuan) * 100;
          }
        })
      },
      print() {
        this.hideBtn = true;
        this.$nextTick(()=>{
          window.print();
        })
      },
      close() {
        window.close();
      },
      afterPrint(){
        this.hideBtn = false;
        console.log("打开",this.hideBtn);
      }
    },
    beforeDestroy(){
      document.getElementsByTagName("body")[0].classList.remove("print");
    }
  }
</script>

<style scoped>
  .tt{
    width: 100%;
  }
  .theader{
    text-align: center
  }
  .tbd{
    text-align: center
  }
  .divButtonRigth {
    margin-bottom: 10px;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .printBox{
    padding-bottom: 60px!important;
  }
  .hideBtn{
    display: none;
  }
</style>
<style>

  .print #app,.print #app .pull-height,.print #app .index,.print #app .index>div,.print .index .main{
    height:auto;
    overflow-y: auto;
    min-height: auto;
    min-width: 100%!important;
    max-width: 100%!important;
  }
  .print .index .main .router{
    padding: 0;
  }


</style>
