<template>
  <div class="rendan">
    <div class="rendanFormula">
      <!--图标-->
      <svg class="icon money" aria-hidden="true">
        <use xlink:href="#icon-jinpai"></use>
      </svg>
      <div class="formulaBox">
        <span class="formulaNum">{{totalData.laikuan}}</span>
        <span class="formulaDetail">来款金额</span>
      </div>
      <svg class="icon operator" aria-hidden="true">
        <use xlink:href="#icon-tianjiajihua"></use>
      </svg>
      <div class="formulaBox">
        <span class="formulaNum">{{totalData.chonghong}}</span>
        <span class="formulaDetail">冲红发票金额</span>
      </div>
      <svg class="icon operator" aria-hidden="true">
        <use :xlink:href="icon"></use>
      </svg>
      <div class="formulaBox">
        <span class="formulaNum">{{totalData.fapiao}}</span>
        <span class="formulaDetail">发票金额</span>
      </div>
      <svg class="icon operator" aria-hidden="true">
        <use xlink:href="#icon-tianjiajihua"></use>
      </svg>
      <div class="formulaBox">
        <span class="formulaNum">{{totalData.zhuanfu}}</span>
        <span class="formulaDetail">转付退款金额</span>
      </div>
    </div>
    <div class="rendanData">
      <div class="rendanTitle">
        <span>发票列表</span>
      </div>
      <div class="rendanBox">
          <vxe-table
            ref="fapiao"
            height="500"
            @checkbox-all="selectAllEvent1"
            @checkbox-change="selectChangeEvent1"
            size="medium"
            :data="tableData">
            <vxe-table-column type="checkbox" width="60"></vxe-table-column>
            <vxe-table-column field="name" title="Name"></vxe-table-column>
            <vxe-table-column field="count" title="Count"></vxe-table-column>
            <vxe-table-column field="date" title="Date"></vxe-table-column>
            <vxe-table-column field="address" title="Address"></vxe-table-column>
          </vxe-table>
      </div>
    </div>
    <div class="rendanData">
      <div class="rendanTitle">
        <div class="cusList">
          <span>客户来款列表</span>
          <el-checkbox v-model="deposit">显示留存款</el-checkbox>
        </div>
      </div>
      <div class="rendanBox">
        <vxe-table
          ref="laikuan"
          height="500"
          @checkbox-all="selectAllEvent2"
          @checkbox-change="selectChangeEvent2"
          size="medium"
          :data="tableData">
          <vxe-table-column type="checkbox" width="60"></vxe-table-column>
          <vxe-table-column field="name" title="Name"></vxe-table-column>
          <vxe-table-column field="count" title="Count"></vxe-table-column>
          <vxe-table-column field="date" title="Date"></vxe-table-column>
          <vxe-table-column field="address" title="Address"></vxe-table-column>
        </vxe-table>
      </div>
    </div>
    <div class="rendanData">
      <div class="rendanTitle">
        <span>退款转付单</span>
        <div class="rendanbtn">
          <el-button type="primary" size="mini">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-baocun"></use>
            </svg>
            新增
          </el-button>
          <el-button type="primary" size="mini">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-baocun"></use>
            </svg>
            修改
          </el-button>
          <el-button type="primary" size="mini">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-baocun"></use>
            </svg>
            删除
          </el-button>
          <el-button class="emptyBtnStyle" size="mini">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-quxiao"></use>
            </svg>
            自定义
          </el-button>
        </div>
      </div>
      <div class="rendanBox">
        <vxe-table
          ref="zhuanfu"
          height="500"
          @checkbox-all="selectAllEvent3"
          @checkbox-change="selectChangeEvent3"
          size="medium"
          :data="tableData">
          <vxe-table-column type="checkbox" width="60"></vxe-table-column>
          <vxe-table-column field="name" title="Name"></vxe-table-column>
          <vxe-table-column field="count" title="Count"></vxe-table-column>
          <vxe-table-column field="date" title="Date"></vxe-table-column>
          <vxe-table-column field="address" title="Address"></vxe-table-column>
        </vxe-table>
      </div>
    </div>
  </div>
</template>

<script>
  import { floatSum,floatSub } from '@/util/util';
  export default {
    name: "rendanDetail",
    data(){
      return{
        deposit:false,
        icon:"#icon-equals",
        totalData:{
          laikuan:0,
          chonghong:0,
          fapiao:0,
          zhuanfu:0,
        },
        tableData:[{
          name:"发票列表",
          count:100.01,
          date:"2019-02-20",
          address:"北京一条街"
        },{
          name:"发票列表",
          count:100.34,
          date:"2019-02-20",
          address:"北京一条街"
        },{
          name:"发票列表",
          count:100.4,
          date:"2019-02-20",
          address:"北京一条街"
        },{
          name:"发票列表",
          count:100.89,
          date:"2019-02-20",
          address:"北京一条街"
        }
        ]
      }
    },
    methods:{
      allSum(selection){
        let counts = [];
        Object.keys(selection).forEach(function(key) {
          counts.push(selection[key].count)
        })
        return floatSum(counts)
      },
      compare(){
        if(floatSum([this.totalData.laikuan,this.totalData.chonghong]) > floatSum([this.totalData.fapiao,this.totalData.zhuanfu]) ){
          this.icon = "#icon-youjiantou"
        }else if(floatSum([this.totalData.laikuan,this.totalData.chonghong]) === floatSum([this.totalData.fapiao,this.totalData.zhuanfu])){
          this.icon = "#icon-equals"
        }else{
          this.icon = "#icon-zuojiantou"
        }
      },

      selectAllEvent1 ({ selection}) {
        this.totalData.fapiao = this.allSum(selection)
        this.compare();
      },
      selectAllEvent2 ({ selection}) {
        this.totalData.laikuan = this.allSum(selection)
        this.compare();
      },
      selectAllEvent3 ({ selection}) {
        this.totalData.zhuanfu = this.allSum(selection)
        this.compare();
      },
      selectChangeEvent1 ({ checked, selection,row }) {
        this.totalData.fapiao = checked?floatSum([this.totalData.fapiao,row.count]) : floatSub(this.totalData.fapiao,[row.count])
        this.compare()
      },
      selectChangeEvent2 ({ checked, selection,row }) {
        this.totalData.laikuan = checked?floatSum([this.totalData.laikuan,row.count]) : floatSub(this.totalData.laikuan,[row.count])
        this.compare()
      },
      selectChangeEvent3 ({ checked, selection,row }) {
        this.totalData.zhuanfu = checked?floatSum([this.totalData.zhuanfu,row.count]) : floatSub(this.totalData.zhuanfu,[row.count])
        this.compare()
      }
    }
  };
</script>

<style scoped>
  .rendanFormula {
    width: fit-content;
    height: 80px;
    background-color: #fff4d8;
    border-radius: 10px;
    margin: 0 auto;
    padding: 10px 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .money {
    font-size: 62px;
    margin-right: 45px;
  }

  .formulaBox {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .formulaNum {
    color: #FF4800;
    font-size: 24px;
    line-height: 40px;
  }

  .formulaDetail {
    color: #999999;
    font-size: 12px;
    line-height: 18px;
  }

  .operator {
    margin: 0 30px;
  }

  .rendanTitle {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
  }
 .rendanTitle span{
    font-size: 16px;
   line-height: 36px;
   margin-right: 40px;
  }
.rendanBox{
  padding: 20px 0;
}
  .rendanTitle >>> .el-checkbox{
    color:#FF7200;
  }
</style>

