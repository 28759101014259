import request from '@/router/axios';

let urlPattern = '/cus';

// 获取客户列表
export function searchCusList(obj) {
  return request({
    url: urlPattern + '/cusList',
    method: 'post',
    data: obj
  })
}

// 根据客户id获取上月已发工资本月未发的雇员列表
export function searchUnpaidEmpList(obj) {
  return request({
    url: urlPattern + '/empList',
    method: 'post',
    data: obj
  })
}

// 雇员批量离职
export function batchResign(obj) {
  return request({
    url: urlPattern + '/batchResign',
    method: 'post',
    data: obj
  })
}

// 导出excel
export function exportExcel(obj) {
  return request({
    url: urlPattern + '/exportExcel',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}


// 修改操作人
export function updateOperatorList(obj) {
  return request({
    url:  '/customerManager/updateOperatorList',
    method: 'post',
    data: obj
  })
}

// 修改操作人
export function cusTransferList(obj) {
  return request({
    url:  '/customerManager/cusTransferlistPage',
    method: 'post',
    data: obj
  })
}
