<template>
  <div>
    <div class="divButtonRigth">
      <el-button type="primary" size="small"   @click="$router.go(-1)">返回</el-button>
    </div>
    <bsp-table
      :data.sync="list"
      :ciic-data-filter.sync="listQuery"
      @ciic-data-refresh="_socialSecurityList"
      show-header-overflow show-overflowwidth show-overflow
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      show-footer-overflow
      show-footer
    >
      <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>
      <bsp-table-column title="提示信息"  width="100" fixed="left" >
        <template slot-scope="scope"><span>{{scope.row.tipsMsg}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="姓名" field="empName" filter-type="input"   width="100" fixed="left" >
        <template slot-scope="scope"><span>{{scope.row.empName}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="证件号码" field="empCard" filter-type="input" width="100" fixed="left"  >
        <template slot-scope="scope"><span>{{scope.row.empCard}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="薪资计税区域" field="taxArea" filter-type="input"  width="100" >
        <template slot-scope="scope"><span>{{scope.row.taxArea}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="起始月份" field="startMonth" filter-type="input"  width="100" >
        <template slot-scope="scope"><span>{{scope.row.startMonth}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="社保缴纳地" field="socialSecurityPlace" filter-type="input" width="100" >
        <template slot-scope="scope"><span>{{scope.row.socialSecurityPlace}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="公积金缴纳地" field="accumulationFundPlace" filter-type="input" width="100" >
        <template slot-scope="scope"><span>{{scope.row.accumulationFundPlace}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="户口性质" field="accountNature" filter-type="input"  width="100">
        <template slot-scope="scope"><span>{{scope.row.accountNature}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="养老失业基数" field="endowmentUnemploymentBase" filter-type="input" width="100">
        <template slot-scope="scope"><span>{{scope.row.endowmentUnemploymentBase}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="医疗基数" field="medicalBase" filter-type="input" width="100" >
        <template slot-scope="scope"><span>{{scope.row.medicalBase}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="公积金基数" field="accumulationFundBase" filter-type="input" width="100" >
        <template slot-scope="scope"><span>{{scope.row.accumulationFundBase}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="养老保险(单位)" field="endowmentInsuranceCompany" filter-type="input" width="100"  >
        <template slot-scope="scope"><span>{{scope.row.endowmentInsuranceCompany}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="失业保险(单位)" field="unemploymentInsuranceCompany" filter-type="input" width="100" >
        <template slot-scope="scope"><span>{{scope.row.unemploymentInsuranceCompany}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="工伤保险(单位)" field="employmentInsuranceCompany" filter-type="input" width="100" >
        <template slot-scope="scope"><span>{{scope.row.employmentInsuranceCompany}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="工伤保险(单位)" field="employmentInsuranceCompany" filter-type="input" width="100" >
        <template slot-scope="scope"><span>{{scope.row.employmentInsuranceCompany}}</span></template>
      </bsp-table-column>
      <bsp-table-column title="生育保险(单位)" field="maternityInsuranceCompany" filter-type="input" width="100" >
        <template slot-scope="scope"><span>{{scope.row.maternityInsuranceCompany}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="医疗保险(单位)" field="medicalInsuranceCompany" filter-type="input"  width="100">
        <template slot-scope="scope"><span>{{scope.row.medicalInsuranceCompany}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="住房公积金(单位)" field="housingProvidentFundCompany" filter-type="input"  width="100">
        <template slot-scope="scope"><span>{{scope.row.housingProvidentFundCompany}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="养老保险(个人)" field="endowmentInsurancePer" filter-type="input"  width="100">
        <template slot-scope="scope"><span>{{scope.row.endowmentInsurancePer}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="失业保险(个人)" field="unemploymentInsurancePer" filter-type="input"  width="100">
        <template slot-scope="scope"><span>{{scope.row.unemploymentInsurancePer}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="医疗保险(个人)" field="medicalInsurancePer" filter-type="input"  width="100">
        <template slot-scope="scope"><span>{{scope.row.medicalInsurancePer}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="住房公积金(个人)" field="housingFundPer" filter-type="input"  width="100">
        <template slot-scope="scope"><span>{{scope.row.housingFundPer}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="养老保险扣款月次(个人)" field="endowmentMonthPer" filter-type="input" width="100" >
        <template slot-scope="scope"><span>{{scope.row.endowmentMonthPer}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="失业保险扣款月次(个人)" field="unemploymentMonthPer" filter-type="input"  width="100">
        <template slot-scope="scope"><span>{{scope.row.unemploymentMonthPer}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="医疗保险扣款月次(个人)" field="medicalMonthPer" filter-type="input" width="100" >
        <template slot-scope="scope"><span>{{scope.row.medicalMonthPer}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="住房公积金扣款月次(个人)" field="housingFundMonthPer" filter-type="input"  width="100">
        <template slot-scope="scope"><span>{{scope.row.housingFundMonthPer}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="管理费" field="managementFee" filter-type="input" width="100" >
        <template slot-scope="scope"><span>{{scope.row.managementFee}}</span></template>
      </bsp-table-column>

      <bsp-table-column title="备注" field="remark" filter-type="input"  width="100">
        <template slot-scope="scope"><span>{{scope.row.remark}}</span></template>
      </bsp-table-column>
    </bsp-table>
  </div>
</template>
<script>
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import { getSocialSecurity  } from '@/api/entrust/socialSecurity'
  export default {
    name: 'socialSecurity',
    components: {
      BspTable,
      BspTableColumn
    },
    data() {
      return {
        loading: false,
        list: [],
        listQuery: {
          limit: 50,
          page: 1,
          total: 0,
          batchId:this.$route.query.batchId,
        }
      }
    },
    created() {
      console.log(this.route);
      this._socialSecurityList();
    },
    methods: {
      async _socialSecurityList() {
        this.loading = true
        console.log(this.listQuery);
        let res = await getSocialSecurity(this.listQuery);
        this.listQuery.total = res.data.total
        this.list = res.data.records
        this.loading = false
      },
    }
  }
</script>
<style scoped>


</style>
