<template>
  <div>
    <el-form class="login-form" status-icon :rules="loginRules" ref="loginForm" :model="loginForm" label-width="0">
      <el-form-item prop="username">
         <span class="pwdIcon">
                 <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-xingming"></use>
                  </svg>
              </span>
        <el-input size="medium" @keyup.enter.native="handleLogin" v-model="loginForm.username" auto-complete="off" placeholder="请输入您的帐号">
         <!-- <svg slot="prefix" class="icon" style="margin-top: 19px" aria-hidden="true">
            <use xlink:href="#icon-yonghu"></use>
          </svg> -->
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
                 <span class="pwdIcon">
                 <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-mima1"></use>
                  </svg>
              </span>
        <el-input size="medium" @keyup.enter.native="handleLogin" :type="passwordType" v-model="loginForm.password" auto-complete="off" placeholder="请输入您的密码">
         <!-- <i class="el-icon-view el-input__icon" slot="suffix" @click="showPassword"></i>
          <svg slot="prefix" class="icon" style="margin-top: 19px" aria-hidden="true">
            <use xlink:href="#icon-mima"></use>
          </svg> -->
        </el-input>
      </el-form-item>
      <el-form-item prop="validCode" class="validCode">
         <span class="pwdIcon">
                 <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-yanzhengma1"></use>
                  </svg>
              </span>
        <el-input size="medium" @keyup.enter.native="handleLogin"  v-model="loginForm.validCode" auto-complete="off" placeholder="请输入您的验证码">

         <!-- <i class="el-icon-view el-input__icon" slot="suffix" @click="showPassword"></i>
          <svg slot="prefix" class="icon" style="margin-top: 19px" aria-hidden="true">
            <use xlink:href="#icon-mima"></use>
          </svg> -->
        </el-input>
         <el-image
           class="code"
          :src="imageUrl"
          @click="reImageCode()"
         >
         </el-image>
      </el-form-item>
   <!--   <div class="change-method">
        <span @click="changMethod">切换至手机号登录方式</span>
        <svg slot="prefix" style="margin: 19px 0 0 5px; color: #008cc9;" class="icon" aria-hidden="true">
          <use xlink:href="#icon-youjiantou"></use>
        </svg>
      </div> -->
      <el-form-item class="login-button-item">
        <span class="login" @click="handleLogin()">登录</span>
      </el-form-item>
    </el-form>
    <!--提交财务-->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      width="35%">
      <el-form :model="formPassword" :rules="passRules" ref="formPassword" label-width="120px">
        <el-form-item  label="用户名"  prop="oriUsername">
          <el-input  class="securityInput" type="text"  v-model="formPassword.oriUsername" placeholder="请输入用户名" ></el-input>
        </el-form-item>
        <el-form-item  label="旧密码"  prop="oriOldPassword">
          <el-input  class="securityInput" type="password"  v-model="formPassword.oriOldPassword" placeholder="请输入旧密码" ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword" >
          <el-input  class="securityInput" type="password" v-model="formPassword.newPassword" placeholder="请输入新密码" ></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="newPassword2" >
          <el-input  class="securityInput" type="password" v-model="formPassword.newPassword2" placeholder="请重新输入新密码" ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit('formPassword')">保存</el-button>
          <el-button @click="$refs['formPassword'].resetFields()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <div>
      <el-dialog
        title="温馨提示"
        :visible.sync="this.dialogMaintain"
        width="30%"
        :before-close="handleClose">
      <span>系统升级维护中，请稍后操作...</span>
        <span slot="footer" class="dialog-footer">
        <el-form ref="form" :model="form" label-width="10px">
          <el-form-item style="margin-bottom: -15px; margin-right: 20px">
            <el-button style="float: right" type="primary" @click="_save">我知道了</el-button>
          </el-form-item>
        </el-form>
      </span>
      </el-dialog>
    </div>
  </div>
  </div>
</template>

<script>
  import { randomLenNum } from '@/util/util';
  import { recordLoginTimes} from '@/api/sys/login';
  import { getCodeImage} from '@/api/user/login';
  import { commonConst } from '@/const/code.js';
  import { mapGetters } from 'vuex';

  export default {
    name: 'pwdlogin',
    data() {
      const validateCode = (rule, value, callback) => {
        if (this.code.value != value) {
          this.loginForm.code = '';
          this.refreshCode();
          callback(new Error('请输入正确的验证码'));
        } else {
          callback();
        }
      };
        //此处即表单发送之前验证
        let validateNewPassword = (rule, value, callback) => {
            if (value === this.formPassword.password) {
                callback(new Error('新密码不能与原密码相同!'))
            } else {
                callback()
            }
        }
        let validateNewPassword2 = (rule, value, callback) => {
            if (value !== this.formPassword.newPassword) {
                callback(new Error('与新密码不一致!'))
            } else {
                callback()
            }
        }
      return {
        loginForm: {
          username: '',
          password: '',
          validCode:'',
          imageUrl:'/api/image-code/code',
          code: '',
          randomStr: '',
          tenantId:'',
        },
        formPassword:{
          oriUsername: '',
          oriPassword:'',
          newPassword:'',
          newPassword2:'',
        },
        code: {
            src: '/user/code',
            value: '',
            len: 4,
            type: 'image',
        },
        loginLoading: false,
        loginRules: {
          username: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 4, message: '密码长度最少为4位', trigger: 'blur' },
          ],
          validCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' },
            { min: 4, max: 4, message: '验证码长度为4位', trigger: 'blur' },
          ],
        },
        passRules: {
            oriUsername: [
                { required: true, message: '请输入用户名', trigger: 'blur' },
            ],
            oriOldPassword: [
                { required: true, message: '请输入原始密码', trigger: 'blur' },
            ],
            newPassword: [
                { required: true, message: '请输入新密码', trigger: 'blur' },
                { validator: validateNewPassword, trigger: 'blur' },
            ],
            newPassword2: [
                { required: true, message: '请再次输入密码', trigger: 'blur' },
                { validator: validateNewPassword2, trigger: 'blur' },
            ],
        },
        passwordType: 'password',
        dialogFormVisible: false,
        dialogMaintain: false,
        maintainText: '',
      };
    },
    created() {
      let tenant = this.$route.params.tenant;

      console.log("tenant=========",tenant);
        console.log(process.env.VUE_APP_LOCAL_DEVELOPMENT)
        this.imageUrl="/api/image-code/code";
        this.refreshCode();
        this.reImageCode();
    },
    mounted() {},
    computed: {
    },
    props: ['loginMethod'],
    methods: {
        onSubmit (formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    let {newPassword, password, username} = this.formPassword;
                    updatePassword({ newPassword, password, username }).then(() => {
                        this.$message.success('密码已修改')
                    })
                } else {
                    this.$message.error('请按提示正确填写用户名密码')
                    return false
                }
            })
        },
      // 刷新验证码
      reImageCode(){
        console.log("============退出处理");
        let num =Math.floor(Math.random() * (10000 - 10 + 1)) + 10;
        console.log("num",num);


        this.imageUrl = "/api/image-code/code?num="+num;
        console.log(" this.imageUrl", this.imageUrl);
        this.$forceUpdate();

      },
      changMethod(){
        this.$emit('update:loginMethod', 'code')
      },
        refreshCode() {
            this.loginForm.code = '';
            this.loginForm.randomStr = randomLenNum(this.code.len, true);
            this.code.type == 'text'
                ? (this.code.value = randomLenNum(this.code.len))
                : (this.code.src = `${process.env.VUE_APP_BASE_API}/user/code/${this.loginForm.randomStr}`);
        },
      showPassword() {
        this.passwordType == ''
          ? (this.passwordType = 'password')
          : (this.passwordType = '');
      },
      handleLogin() {
          this.$refs.loginForm.validate((valid) => {
              if (valid) {
                  this.loginLoading = true;

                  /*console.log(/^\d+$/.test(this.loginForm.username))
                  if(/^\d+$/.test(this.loginForm.username)){
                      //手机号
                      this.loginForm.type = 2
                  }else if(this.loginForm.username.indexOf("@") != -1){
                      //邮箱
                      this.loginForm.type = 3
                  }else{
                      //用户名
                      this.loginForm.type = 1
                      this.loginForm.username = this.loginForm.username
                  }*/


									/*this.$router.push({path: '/index'});
									this.$message.success('登录成功');
                this.$store.commit('SET_ACCESS_TOKEN', '40288ab24eec02a0014eec1560980008');*/
											//todo  登录接口,目前直接跳转到首页
                  console.log("登录信息：",this.loginForm);
                  this.$store.dispatch('LoginByUsername', this.loginForm).then(
                      (res) => {
                        if(res.data.data.success=="false"){
                              this.loginLoading = false;
                              this.$message.error(res.data.data.msg);
                              // 刷新一下验证码
                              this.reImageCode();
                          } else {
                            this.$store.state.impData.cusId = '';
                            this.$store.state.impData.billMonth = '';
                              this.$router.push({path: '/index'});
                              this.$message.success('登录成功');
                              // 刷新一下验证码
                              this.reImageCode();
                          }
                      }
                  ).catch(
                      (error) => {
                          this.loginLoading = false;
                          if(error.data && error.data.data.msg){
                              this.$message.error(error.data.data.msg);
                          }
                          this.loginForm.validCode='';
                          this.refreshCode();
                           // 刷新一下验证码
                          this.reImageCode();
                      })
              }
              /*else {
                  let promise = this.$confirm('密码必须包含数字、字母、特殊字符，请前往修改密码', '提示', {
                      confirmButtonText: '修改密码',
                      cancelButtonText: '放弃修改',
                      type: 'warning'
                  }).then(() => {
                      this.dialogFormVisible = true;
                      let pwd = this.loginForm.username;
                      this.formPassword.username = pwd;
                  }).catch(() => {
                      this.loading = false;
                      this.$message.warning("放弃修改密码，系统暂不可用");
                      return false;
                  });
              }*/
          });
        },


    },
  };
  //增加密码复杂度，如果密码过于简单，需要修改密码，目前此代码不开放
  function validSafe(password) {
      /* 1) 密码控制只能输入字母、数字、特殊符号(~!@#$%^&*()_+[]{}|\;:'",./<>?)
                           2) 长度 6-16 位，必须包括字母、数字、特殊符号中的2种
      //判断密码是否包含数字：包含返回1，不包含返回0*/
      var reg=/.*\d+.*/;   /*定义验证表达式*/
      var i = reg.test(password) ? 1 : 0;

      //判断密码是否包含字母：包含返回1，不包含返回0
      var reg2=/.*[a-zA-Z]+.*/;   /*定义验证表达式*/
      var j = reg2.test(password) ? 1 : 0;

      //判断密码是否包含特殊符号(~!@#$%^&*()_+|<>,.?/:;'[]{}\)：包含返回1，不包含返回0
      var reg3=/.*[~!@#$%^&*()_+|<>,.?/:;'\[\]{}"]+.*/;   /*定义验证表达式*/
      var k = reg3.test(password) ? 1 : 0;

      //判断密码长度是否在6-16位
      var l = password.length;

      if (i + j + k < 2 || l < 6 || l > 16) {
          return false;
      } else {
          return true;
      }
  }
</script>

<style scoped>
  .pwdIcon{
    position: absolute;
    font-size: 0.24rem;
    color: #493C76;
    z-index: 99;
    top:0.2rem;
  }
  @media only screen and (max-width: 1367px) {
    .pwdIcon{
      top:0.15rem;
    }
  }
  .validCode{
     position: relative;
    margin-bottom: 0.4rem;
   }
  .code{
    position: absolute;
    bottom: 0.2rem;
    right: 0.1rem;
    height: 0.47rem;
    width: 1.34rem;
  }
  .change-method{
    margin-top: -.15rem;
    margin-bottom: .2rem !important;
  }
	.login-form{
		margin-top: .3rem;
	}
	.login-form >>> .el-input__inner{
    width: 4rem;
    height: .68rem;
    padding-left: .60rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /* background: rgba(247,244,251,1); */
    border-radius: 0;
    border: none;
    font-size: .2rem;
    border-bottom: 1px solid #D3D3D3;
  }
		.login-form >>> .el-form-item__content{
			/*margin-bottom: .36rem;*/
      display: flex;
      align-items: center;
		}
		.login{
			display: inline-block;
			cursor: pointer;
			width:2.2rem;
			height:.60rem;
			border-radius:.34rem;
			text-align: center;
			line-height: .60rem;
			font-size:.24rem;
			font-weight:400;
			color:#ffffff;
      background:rgba(58,62,106,1);
		}
		.login-form >>> .el-input__suffix{
			display: none;
		}
		.login-form >>> .el-form-item__error{
			padding-left: .6rem;
			font-size:.14rem;
			font-weight:400;
			color:rgba(235,11,11,1);
		}
		.el-message--success .el-message__content{
			font-size: 14px;
			}
			.el-message .el-message__icon {
				font-size: 16px;
			}
</style>
