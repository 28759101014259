import request from '@/router/axios';

const urlPrefix = ''

export function saveNotice(obj) {
  return request({
    url: urlPrefix+ '/notice/saveNotice',
    method: 'post',
    data:obj
  });
}


export function noticelist(obj) {
  return request({
    url: urlPrefix+ '/notice/noticelistPage',
    method: 'post',
    data:obj
  });
}

export function roleList() {
  return request({
    url: urlPrefix+ '/notice/roleList',
    method: 'post'
  });
}

export function addUploadNoticeFile(obj){
  return request({
    url: urlPrefix+ '/notice/addUploadNoticeFile',
    method: 'post',
    data:obj
  });
}



export function getDownloadFile(id) {
  return request({
    url: urlPrefix + '/notice/download/' + id,
    method: 'post',
    responseType: 'arraybuffer',
    data:{}
  });
}

export function deleteFile(id) {
  return request({
    url: urlPrefix + '/notice/' + id,
    method: 'get'
  });
}

export function noticeDelete(id,status) {
  return request({
    url: urlPrefix + '/notice/noticeDelete/' + id+'/'+status,
    method: 'get'
  });
}

export function getByNoticeId(noticeId) {
  return request({
    url: urlPrefix + '/notice/getByNoticeId/' + noticeId,
    method: 'get',
  });
}

export function selectNoticeDetail(noticeId) {
  return request({
    url: urlPrefix + '/notice/selectNoticeDetail/' + noticeId,
    method: 'get',
  });
}

export function unVisibleNotice(noticeId) {
  return request({
    url: urlPrefix + '/home/unVisibleNotice/' + noticeId,
    method: 'get',
  });
}

