<template>
  <div>
     <div class="flex">
     
          <div class="step" :class="{active:step==1}" @click="divClick(1)">
              <router-link to="/salarySet/step1">
            <div class="img">
                <img v-if="step==1" src="@/assets/img/salarySet/icon-company1.png" alt="" srcset="">
                <img v-else src="@/assets/img/salarySet/icon-company.png" alt="" srcset="">
                
            </div>
          
            <span>
              公司设置
            </span>
             </router-link>
        </div>
      
        <div class="next" >
          <img src="@/assets/img/salarySet/next.png" alt="" srcset="">
        </div>
        
        <div class="step" :class="{active:step==2}" @click="divClick(2)">
          <router-link to="/salarySet/step2">
          <div class="img">
              <img v-if="step==2" src="@/assets/img/salarySet/icon-xinchou1.png" alt="" srcset="">
              <img v-else src="@/assets/img/salarySet/icon-xinchou.png" alt="" srcset="">
          </div>
         
          <span>
             薪酬组
          </span>
          </router-link>
       </div>
      <div class="next">
        
          <img src="@/assets/img/salarySet/next.png" alt="" srcset="">
        </div>
        <div class="step" :class="{active:step==3}" @click="divClick(3)">
          <router-link to="/salarySet/step3">
          <div class="img">
                <img v-if="step==3" src="@/assets/img/salarySet/icon-muban1.png" alt="" srcset="">
              <img v-else src="@/assets/img/salarySet/icon-muban.png" alt="" srcset="">
          </div>
          
          <span>
             工资模板
          </span>
          </router-link>
       </div>
       <div class="next">
          <img src="@/assets/img/salarySet/next.png" alt="" srcset="">
        </div>
         <div class="step" :class="{active:step==4}" @click="divClick(4)">
           <router-link to="/salarySet/step4">
            <div class="img">
              <img v-if="step==4" src="@/assets/img/salarySet/icon-shenpi1.png" alt="" srcset="">
              <img v-else src="@/assets/img/salarySet/icon-shenpi.png" alt="" srcset="">
            </div>
            
            <span>
              审批流程
            </span>
           </router-link>
       </div>
        <div class="next">
          <img src="@/assets/img/salarySet/next.png" alt="" srcset="">
        </div>
        <div class="step" :class="{active:step==5}" @click="divClick(5)">
          <router-link to="/salarySet/step5">
          <div class="img">
                <img v-if="step==5" src="@/assets/img/salarySet/icon-gzd1.png" alt="" srcset="">
              <img v-else src="@/assets/img/salarySet/icon-gzd.png" alt="" srcset="">
          </div>
          <span>
             工资单
          </span>
          </router-link>
       </div>
     </div>
  </div>
 
</template>

<script>

  export default {
    name: "salaryTop",
    props:{
        step: {
            type: Number,
            default: () => (
              1
            )
        },
    },
    data(){
      return{
        // dialogheaderVisible:false,
      }
    },
    methods:{
      divClick(step){
        this.$emit('setStep', step);
      }
    }
  };
</script>

<style scoped>
    .flex{
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
    }
    .flex .step .img{
        width: 60px;
        height: 46px;
        line-height: 46px;
        text-align: center
    }
    .flex .step .img img{
      vertical-align: middle;
      position: relative;
      z-index: 10;
    }
    .flex .step{
      position: relative;
      cursor: pointer;
    }
    .flex .step.active .img::after{
      content: '';
      position: absolute;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      background: #2DA7E0;
      left: 7px;
      top: 0;
    
    }
    .flex .step.active span{
      font-weight: 600;
      color: #2DA7E0;
    }
    .flex .step span{
      font-size:12px;
      font-family:PingFangSC-Regular,PingFang SC;
      font-weight:400;
      color:rgba(51,51,51,1);
      display: inline-block;
      width: 100%;
      text-align: center
    }

    .flex .next{
      display: flex;
      align-items: center;
    }
      .flex .next img{
        width: 21px;
        height: 16px;
        margin-left: 45px;
        margin-right: 42px;
      }
    
</style>
