<template>
    <div>
      <el-upload
        :accept="accept"
        class="upload-demo"
        action="/api/file/upload"
        :on-preview="handlePreview"
        v-bind="{...$attrs}"
        v-on="{...$listeners}"
        :on-success="handleUploadSuccess"
        :on-error="handleUploadError"
        :before-upload="beforeUpload"
        multiple
        :limit="limit"
        :on-exceed="handleExceed"
        :file-list="fileList">
        <span ><img src="@/assets/img/form/uploadBtn.png" alt=""></span>
      </el-upload>
    </div>
</template>

<script>
    import { downLoadFile,getFileInfo} from "@/api/user/user.js"
    import { downloadFile } from '@/util/util';
    import { mapGetters } from 'vuex';
    export default {
        name: "bsp-file-upload",
        props: {
          limit: {
              type: Number,
              default: 1,
          },
          accept:{
            type:String,
            default:''
          },
          fileIds:{
            type:String,
            default:''
          },
          fileNames:{
            type:String,
            default:''
          },
          typeImage:{
            type:Boolean,
            default:false
          },
          otherType:{
            type:Boolean,
            default:false
          },
          specialType:{
              type:Boolean,
              default:false
          },
          excelType:{
            type:Boolean,
            default:false
          },
          typeThree:{
            type:Boolean,
            default:false
          }
        },
        computed: {
            headers() {
                return {
                    Authorization: `Bearer ${this.accessToken}`,
                };
            },
            ...mapGetters(['accessToken'])
        },
        data(){
            return{
                fileList:[],
            }
        },
      watch:{
        fileIds:function(){
          this.initFile();
        }
      },
      created(){
          //this.fileList = [{ name: "09991076e7d4fe016f0228b4c19e00b.png"}]
       // console.log(this.fileIds,'fileIdsss:111111111');
        this.initFile();
      },
        methods:{
          initFile(){
            if(this.fileIds.length>0){
              let split = this.fileIds.split(",");
              for (let i = 0; i < split.length; i++) {
                /*downLoadFile(split[i]).then(res=>{
                  console.log(res,'xia zai :33 ')
                });*/
                getFileInfo(split[i]).then(res=>{
                  //console.log(res,'wyth ');
                  // console.log("["+res.data.data+"}",'wyth 222222');
                  this.fileList=[res.data.data];
                })
              }

            }else {
              this.fileList=[];
            }
          },
            handlePreview(file) {
                console.log(file);
                let id = file.id;
                downLoadFile(id).then(res => {
                    console.log(res);
                    downloadFile(res)
                })
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            // 上传前处理
            beforeUpload(file) {

                const isLt10M = file.size / 1024 / 1024 < 10;
                const AllUpExt = ".pdf|.jpg|.png|";
                const excludeExt = '.pdf|.jpg|.jpeg|.png|.doc|.docx|.xls|.xlsx|.txt|.ppt|.pptx|.rar|.zip|.arj|.z|';
                const specialExt1 = ".doc|.docx|.xls|.xlsx|.pdf";
                const specialExt2 = ".xls|.xlsx";
                const typeThree = ".doc|.docx|.xls|.xlsx|.pdf|.jpg|.jpeg|.png|.gif|.bmp";
                const extName = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
                console.log("上传文件的大小file.size=",file.size);
                if (!isLt10M) {
                    this.$message.error('上传文件大小不能超过 10MB!');
                    // this.$Loading.close();
                    return isLt10M;
                }

                //是否只能上传图片和pdf格式
              if(this.typeImage){
                if(AllUpExt.indexOf(extName + "|") == "-1"){
                  this.$message.error("只允许上传图片和pdf格式的文件");
                  return false;
                }
              }

              //是否可以上传所有（图片和一般文档格式）文档格式
              if(this.otherType){
                  if(excludeExt.indexOf(extName + "|") == "-1"){
                      this.$message.error("只允许上传一般文档格式的文件");
                      return false;
                  }
              }

              //只可以上传 doc/docx/xls/xlsx/pdf 文档格式
              if(this.specialType){
                  if(specialExt1.indexOf(extName + "|") == "-1"){
                      this.$message.error("只允许上传word/excel/pdf格式的文件");
                      return false;
                  }
              }

              //只可以上传excel文档格式
              if(this.excelType){
                  if(specialExt2.indexOf(extName + "|") == "-1"){
                      this.$message.error("只允许上传excel格式的文件");
                      return false;
                  }
              }

              //只可以上传（word\excel\pdf）和图片文档格式
              if(this.typeThree){
                  if(typeThree.indexOf(extName + "|") == "-1"){
                      this.$message.error("只允许上传word/excel/pdf和图片格式的文件");
                      return false;
                  }
              }

              this.$Loading.full();
              return true;
            },
            // 上传成功处理
            handleUploadSuccess(res, file,fileList) {
               // console.log(fileList,'fileList44444333333333333333:')
                console.log("上传成功之后~~res~",res);
              this.fileIds='';
              for (let i = 0,l=fileList.length; i <l; i++) {
                if(i<(l-1)){
                  this.fileIds +=fileList[i].response.fileId+",";
                  this.fileNames +=fileList[i].response.fileName+",";
                }else {
                  this.fileIds +=fileList[i].response.fileId;
                  this.fileNames +=fileList[i].response.fileName;

                }
              }
                this.$Loading.close();
                if (res.fileId) {
                  console.log(res.fileId)
                  this.$emit('update:fileIds', this.fileIds)
                  this.$emit('update:fileNames', this.fileNames)
                  this.$message.success('上传成功');
                } else{
                   this.$message.error(res.message);
                }
            },
            // 上传失败处理
            handleUploadError(res, file) {
              this.$Loading.close();
                const { errorMsg, errorCode } = res;
                if (errorCode === 1) {
                    this.$message.error('上传失败，请检查文件格式是否正确');
                } else {
                    this.$message.error('连接服务器出错，请稍后重试');
                }
            },

        }
    }
</script>

<style scoped lang="scss">
  .el-upload__tip{
    margin-left: 20px;
  }
</style>
