<template>
  <h2>
     <span>{{title}}</span>
     <slot></slot>
  </h2>
 
</template>

<script>

  export default {
    name: "salaryTop",
    props:{
        title: {
            type: String,
            default: () => (
              ''
            )
        },
    },
    data(){
      return{
        // dialogheaderVisible:false,
      }
    },
    methods:{
    
    }
  };
</script>

<style scoped>
    
    h2{
        background: #F5F5F5;
        width: calc(100% + 40px);
        position: relative;
        left: -20px;
        display: flex;
        justify-content: space-between;
        padding-top: 16px;
        padding-bottom: 8px;
        height: 20px;
        align-items: center;
        line-height: 20px
    }
    h2>span{
        font-size:14px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        position: relative;
        padding-left: 20px;
    }
    h2>span::after{
        content: '';
        position: absolute;
        width:7px;
        height:12px;
        background:rgba(157,202,58,1);
        left: 0;
        top: 4px;
    }
    h2>div span{
        font-size:13px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(45,167,224,1);
    }
</style>
