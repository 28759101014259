<template>
    <div>

      <bsp-table
        :data.sync="list"
        :tree-config="true"
        :ciic-data-filter.sync="listQuery"
        @select-change="selectChange"
        @select-all="selectChange"
        @ciic-data-refresh="loadList"
        show-header-overflow show-overflowwidth show-overflow
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        show-footer-overflow
        show-footer
      >
<!--        <vxe-table-column type="selection" width="60" fixed="left"/>-->
<!--        <vxe-table-column title="序号" type="index" width="60" fixed="left" align="center"></vxe-table-column>-->


        <bsp-table-column title="计税区域" field="area" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.area}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="分支机构" field="orgname" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.orgName}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="业务类型" field="biztype" filter-type="select"
                          filter-rule="in"
                          :optionProps="{value:'codeItemId',label:'codeItemName'}"
                          :optionsList="bizTypeList" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.bizTypeName}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="服务项目" field="serviceproject" filter-type="select"
                          filter-rule="in"
                          :optionProps="{value:'codeItemId',label:'codeItemName'}"
                          :optionsList="serviceProjectNameList" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.serviceProjectName}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="社保要求" field="socialsecurityentrust" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.socialSecurityEntrust}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="残保金要求" field="payresidualinsurance" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.payResidualInsurance}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="银行卡要求" field="bankcardrequirements" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.bankCardRequirements}}</span></template>
        </bsp-table-column>
        <bsp-table-column title="款项要求" field="paymentrequirements" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.paymentRequirements}}</span></template>
        </bsp-table-column>
<!--        <bsp-table-column title="银行卡要求" field="status" filter-type="input" fixed="left">-->
<!--          <template slot-scope="scope"><span>{{scope.row.status}}</span></template>-->
<!--        </bsp-table-column>-->
<!--        <bsp-table-column title="款项要求" field="status" filter-type="input" fixed="left">-->
<!--          <template slot-scope="scope"><span>{{scope.row.status}}</span></template>-->
<!--        </bsp-table-column>-->
        <bsp-table-column title="备注" field="remarks" filter-type="input" fixed="left">
          <template slot-scope="scope"><span>{{scope.row.remarks}}</span></template>
        </bsp-table-column>
      </bsp-table>

    </div>

</template>

<script>

  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import {batchUpdateCusCaclWay, checkCusIsImported, searchCusList} from '@/api/cus/payableConf';
  import {queryersonList} from "@/api/person/person";
  import {entrustPriceList} from "@/api/entrust/price";
  import {mapGetters} from 'vuex';

    export default {
        name: "cusList",
      inject: ['reload'],
      components: {
        BspTable,
        BspTableColumn,
      },
      computed: {
        ...mapGetters([
          'dicts'
        ]),
      },
      props: {
        currentTab: {
          type: String,
          default: ''
        },
      },

      created() {
          this.loadList();
      },

      data() {
          return {
            // 用于接收后台返回的分页数据
            list: [],
            //分页查询对象
            listQuery: {
              limit: 50,
              page: 1,
              total: 0,
              paymentNo: '',
              paymentStatus: '',
              accountingMonth: '',
              paymentType: '0'
            },
            batchDialogTitle: '修改操作人-客户转移',
            multipleSelection: [],
            batchDialogVisible: false,
            operatorList:[],
            operator: "",
            updateoperatorList:[],
            updateoperator:{
              cusId:'',
              beforeOperator:'',
              afterOperator:''
            },    serviceProjectNameList:[
              {
                "codeItemName": "以客户名义补税",
                "codeItemId": "31288300726"
              },
              {
                "codeItemName": "以客户名义补税并交纳滞纳金",
                "codeItemId": "31288300727"
              },
              {
                "codeItemName": "以往月份补零申报",
                "codeItemId": "31288300728"
              },
              {
                "codeItemName": "支付外籍人外汇工资",
                "codeItemId": "31288300729"
              },
              {
                "codeItemName": "滞纳金缴纳",
                "codeItemId": "31288300730"
              },
              {
                "codeItemName": "年度12万申报",
                "codeItemId": "31288300707"
              },
              {
                "codeItemName": "申请税务管理码",
                "codeItemId": "31288300715"
              },
              {
                "codeItemName": "外币购汇",
                "codeItemId": "31288300716"
              },
              {
                "codeItemName": "外籍个人纳税登记",
                "codeItemId": "31288300717"
              },
              {
                "codeItemName": "外籍个人纳税注销",
                "codeItemId": "31288300718"
              },
              {
                "codeItemName": "外籍人士的汇算清缴",
                "codeItemId": "31288300719"
              },
              {
                "codeItemName": "外籍员工代发工资大库报税",
                "codeItemId": "31288300720"
              },
              {
                "codeItemName": "外籍员工单报税",
                "codeItemId": "31288300721"
              },
              {
                "codeItemName": "外籍员工离职清算",
                "codeItemId": "31288300722"
              },
              {
                "codeItemName": "打印完税证明",
                "codeItemId": "31288300708"
              },
              {
                "codeItemName": "外籍员工年度申报",
                "codeItemId": "31288300723"
              },
              {
                "codeItemName": "外籍员工自然人登记",
                "codeItemId": "31288300724"
              },
              {
                "codeItemName": "以个人名义补税",
                "codeItemId": "31288300725"
              },
              {
                "codeItemName": "企业数字证书办理",
                "codeItemId": "31288300731"
              },
              {
                "codeItemName": "代办残保金",
                "codeItemId": "31288300709"
              },
              {
                "codeItemName": "代办个税2%手续费返还",
                "codeItemId": "31288300710"
              },
              {
                "codeItemName": "代报销",
                "codeItemId": "31288300711"
              },
              {
                "codeItemName": "代发薪大库报税",
                "codeItemId": "31288300712"
              },
              {
                "codeItemName": "个人所得税明细申报",
                "codeItemId": "31288300713"
              },
              {
                "codeItemName": "计算补缴个人所得税及滞纳金",
                "codeItemId": "31288300714"
              }
            ],
            bizTypeList:[
              {
                "codeItemName": "代报销",
                "codeItemId": "31278300707"
              },
              {
                "codeItemName": "客户名义报税",
                "codeItemId": "31278300702"
              },
              {
                "codeItemName": "代发薪大库报税",
                "codeItemId": "31278300703"
              },
              {
                "codeItemName": "代发薪客户名义报税",
                "codeItemId": "31278300704"
              },
              {
                "codeItemName": "其他长期服务",
                "codeItemId": "31278300705"
              },
              {
                "codeItemName": "大库报税",
                "codeItemId": "31278300701"
              },
              {
                "codeItemName": "其他临时服务",
                "codeItemId": "31278300706"
              }
            ],
          }
      },

      methods: {


        loadList() {
          debugger
          this.loading = true;
          entrustPriceList(this.listQuery).then(res => {
            this.list = res.data.records;
            this.listQuery.total = res.data.total;
            this.loading = false;
            this.multipleSelection = []
          })
        },




      }
    }
</script>

<style scoped>

</style>
